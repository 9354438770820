import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  HostListener,
  Input,
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

@Directive({
  selector: '[marCardSkew]',
})
export class CardSkewDirective implements AfterViewInit {
  @Input() bgSize = 220;
  xsBreakPoint = false;
  private _onDestroy$ = new Subject<void>();

  constructor(
    private el: ElementRef,
    private breakpointObserver: BreakpointObserver,
    private cdr: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    this.breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.HandsetPortrait])
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((result: BreakpointState) => {
        if (result.matches) {
          this.xsBreakPoint = true;
        } else {
          this.xsBreakPoint = false;
          this.cdr.detectChanges();
        }
      });
  }

  @HostListener('mousemove', ['$event'])
  onMousemove(event: MouseEvent): void {
    this.el.nativeElement.style.backgroundPosition = `${event.offsetX - this.bgSize}px ${
      event.offsetY - this.bgSize
    }px`;

    if (this.xsBreakPoint) {
      return;
    }
    const skew = this.calcSkew(event);
    this.el.nativeElement.style.transform = `perspective(700px) rotateX(${skew.Y * 3}deg) rotateY(${
      skew.X * 3
    }deg)`;
  }

  @HostListener('mouseout', ['$event'])
  onMouseout(): void {
    if (this.xsBreakPoint) return;
    this.el.nativeElement.style.transform = `perspective(700px) rotateX(0deg) rotateY(0deg)`;
  }

  calcSkew(event: MouseEvent): { Y: number; X: number } {
    const target = event?.target as HTMLElement;
    const positionY = (event?.offsetY / target?.offsetHeight) * 100;
    const positionX = (event?.offsetX / target?.offsetWidth) * 100;
    const skewY = (100 - positionY * 2) / -100;
    const skewX = ((100 - positionX * 2) / -100) * -1;
    return { X: skewX, Y: skewY };
  }
}
