import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlternativeRoute, GeoZone } from '@estimator/models';
import { Observable, of } from 'rxjs';
import { BaseService } from '../base';

@Injectable({
  providedIn: 'root',
})
export class RouteService extends BaseService<GeoZone> {
  constructor(protected override readonly http: HttpClient) {
    super(http);
    this.setPath('/api/routes');
  }

  getCanals(): Observable<GeoZone[]> {
    return this.http.get<GeoZone[]>(`${this.path}/canals`);
  }

  getEcaZones(): Observable<GeoZone[]> {
    return this.http.get<GeoZone[]>(`${this.path}/zones`);
  }

  getAlternativeRoutes(
    startNodeId: number,
    endNodeId: number,
    exceptCanals: number[],
    avoidEca: boolean
  ): Observable<AlternativeRoute[]> {
    let params = new HttpParams();
    if (startNodeId) {
      params = params.set('start_node_id', startNodeId);
    }
    if (endNodeId) {
      params = params.set('end_node_id', endNodeId);
    }
    if (exceptCanals.length) {
      for (const canal of exceptCanals) {
        params = params.append('except_canals', canal);
      }
    }
    params = params.set('avoid_eca', !avoidEca);
    if (startNodeId !== endNodeId) {
      return this.http.get<AlternativeRoute[]>(`${this.path}/alternatives`, { params });
    } else {
      return of([]);
    }
  }
}
