import { EventEmitter } from '@angular/core';
import { GeoCoordinates } from './geographic';

export interface CoordinateDialogConfig {
  cancel: EventEmitter<any>;
  sendCoordinates: EventEmitter<GeoCoordinates>;
  point: GeoCoordinates;
}

export enum CoordinatesFormat {
  DMS = 0,
  DM,
  DECIMAL,
}
