import { BaseEntity } from './base-entity';
import { ReportType } from './report';

export interface CaptainReport extends BaseEntity {
  arrival_port_id?: number;
  average_rpm?: number;
  average_speed?: number;
  comment?: string;
  departure_port_id?: number;
  distance_from_last_report?: number;
  etd?: string | Date | null;
  finished_date?: string | Date | null;
  imo?: number;
  latitude?: string;
  longitude?: string;
  next_canal_eta?: string | Date | null;
  next_port_eta?: string | Date | null;
  nor_date?: string | Date | null;
  observe_date?: string | Date | null;
  pob_date?: string | Date | null;
  report_type?: ReportType;
  vessel_id?: number;
}
