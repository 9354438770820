<div
  (dragstart)="gridDragStart()"
  (dragover)="gridDragOver($event)"
  (drop)="gridDrop($event)"
  style="height: 100%"
>
  <ag-grid-angular
    [rowData]="deals"
    [gridOptions]="gridOptions"
    (gridReady)="onGridReady($event)"
    class="wh-100 deals-list ag-theme-material"
  ></ag-grid-angular>
</div>
