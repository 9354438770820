import { PAGE } from './routes';

export interface Product {
  name: string;
  short_name: string;
  description: string;
  short_description: string;
  trademark: string;
  slug: string;
  url: string[];
  steps?: { title: string; description: string }[];
  benefits?: { title: string; description: string; icon: string }[];
  faq?: { title: string; description: string }[];
  disabled?: boolean;
}

export const DRY_VOYAGE_ESTIMATOR: Product = {
  name: 'Voyage Planner',
  short_name: 'Voyage Planner',
  description: `Optimize routes, cargo, and costs with easy-to-use voyage calculation tool`,
  short_description: 'Voyage calculations in a few clicks',
  trademark: 'Route',
  slug: PAGE.PRODUCT.DRY,
  url: [PAGE.PRODUCTS, PAGE.PRODUCT.DRY],
  benefits: [
    {
      title: 'Accurate calculations',
      description:
        'Get accurate data on laytime calculations, CII assessment, TCE sensitivity, and much more',
      icon: 'calculator',
    },
    {
      title: 'Easy setup, intuitive use',
      description:
        'Start using within 30 seconds - no lengthy installations or steep learning curves',
      icon: 'settings',
    },
    {
      title: 'Full ship data',
      description: 'Essential insights, instantly accessible at your fingertips',
      icon: 'free',
    },
  ],
  faq: [
    {
      title: 'Route creation',
      description: `We use our own algorithm for route calculation and doesn't depends on third party
        services.`,
    },
    {
      title: 'Ports database',
      description: `In our list more then 20 000 most known ports. Anyway you can ask to add more.`,
    },
    {
      title: 'Privacy',
      description: `To enter the system we use SHA256 coding, and even admin couldn't see or change
        your password.`,
    },
    {
      title: 'API access',
      description: `System was created in 2023, and use modern SaaS technologies, most of functions
        can be managed by API, just write us what you need to.`,
    },
  ],
};

export const TANKER_VOYAGE_ESTIMATOR: Product = {
  name: 'Voyage Management',
  short_name: 'Voyage Management System',
  description: `Optimize routes, cargo, and costs with easy-to-use voyage comparison and integrated intake
    calculator tool`,
  short_description: `Operators' gateway to digital voyage control`,
  trademark: 'Manager',
  slug: PAGE.PRODUCT.TANKER,
  url: [PAGE.PRODUCTS, PAGE.PRODUCT.TANKER],
  benefits: DRY_VOYAGE_ESTIMATOR.benefits,
  faq: DRY_VOYAGE_ESTIMATOR.faq,
  disabled: true,
};

export const BUNKER_TRADING_PLATFORM: Product = {
  name: 'Bunker Procurement',
  short_name: 'Bunker Procurement',
  description: 'Coming soon...',
  short_description: 'Effortless bunker buying experience',
  trademark: 'Trade',
  slug: PAGE.PRODUCT.BUNKER,
  url: [PAGE.PRODUCTS, PAGE.PRODUCT.BUNKER],
  steps: [
    {
      title: 'Submit bunker enquiry',
      description: 'Provide your requirements and let XMAR do the sourcing.',
    },
    {
      title: 'Receive multiple competitive offers',
      description: 'Instant access to offers from global suppliers across 500+ ports.',
    },
    {
      title: 'Select finance terms',
      description: 'Choose payment terms that work for your business—7, 15, or 30 days.',
    },
    {
      title: 'Confirm order',
      description: 'Complete the purchase and manage everything within the platform.',
    },
  ],
  benefits: [
    {
      title: 'Built for SMEs',
      description:
        'Designed for small and medium-sized shipping companies with a bunker spend of $10-100M annually.',
      icon: 'bunker-trading-platform-light',
    },
    {
      title: 'Bunker purchasing',
      description:
        'Access over 1,500 suppliers worldwide. Each order is supported by live chat, for smooth deliveries every time.',
      icon: 'dry-voyage-estimator-light',
    },
    {
      title: 'Transparent finance options',
      description:
        '30 day payment terms on every purchase. Track every order—from offers to deliveries—through one platform.',
      icon: 'dollar',
    },
    {
      title: 'Bunker price index',
      description:
        'Get live pricing from the top 100 ports, updated daily. Make better purchasing decisions with real-time data.',
      icon: 'free',
    },
    {
      title: 'Voyage planning',
      description:
        'Maximize profits with route planning and fuel consumption tools to optimize voyages and reduce costs.',
      icon: 'map-point',
    },
    {
      title: 'Vessel watch via email integration',
      description:
        'Turn emails into real-time vessel availability lists. Stay informed and take advantage of market opportunities.',
      icon: 'email',
    },
  ],
  disabled: true,
};

export const PRODUCTS: Product[] = [
  DRY_VOYAGE_ESTIMATOR,
  TANKER_VOYAGE_ESTIMATOR,
  BUNKER_TRADING_PLATFORM,
];
