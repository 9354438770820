import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { TableModule } from 'primeng/table';
import { CopyFolderDialogModule } from '../copy-folder-dialog/copy-folder-dialog.module';
import { FixFolderComponent } from './fix-folder.component';

@NgModule({
  declarations: [FixFolderComponent],
  imports: [
    CommonModule,
    ButtonModule,
    ReactiveFormsModule,
    AutoCompleteModule,
    CheckboxModule,
    TableModule,
    CopyFolderDialogModule,
  ],
  exports: [FixFolderComponent],
})
export class FixFolderModule {}
