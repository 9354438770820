import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  ZeroNullValidator,
  convertCurrencyToServerValue,
  convertCurrencyToUiValue,
} from '@estimator/helpers';
import { ChangeValidation, Currency, DomEvents, TEXT_ZERO } from '@estimator/models';
import { isEqual, isNil } from 'lodash';
import { Subject, Subscription, filter, map, pairwise, startWith, takeUntil } from 'rxjs';

@Component({
  selector: 'estimator-price-and-currency-input',
  templateUrl: './price-and-currency-input.component.html',
  styleUrls: ['./price-and-currency-input.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriceAndCurrencyInputComponent implements OnDestroy, AfterViewChecked {
  @Input()
  set priceFormControl(control: FormControl<number | null>) {
    this._priceFormControl = control;
    if (control.disabled) {
      this.priceAndCurrencyFormGroup.controls.price.disable({ emitEvent: false });
    }
  }
  get priceFormControl(): FormControl<number | null> {
    return this._priceFormControl;
  }
  @Input()
  set currencyFormControl(control: FormControl<Currency | null>) {
    this._currencyFormControl = control;
    if (control.disabled) {
      this.priceAndCurrencyFormGroup.controls.currency.disable({ emitEvent: false });
    }
  }
  get currencyFormControl(): FormControl<Currency | null> {
    return this._currencyFormControl;
  }
  @Input()
  set max(value: number | undefined) {
    this._max = value;
  }
  get max(): number | undefined {
    return this._max;
  }
  @Input() min = 0;
  @Input()
  set invalidName(value: string) {
    this._invalidName = value;
    if (!value) {
      this.priceAndCurrencyFormGroup.controls.price.setAsyncValidators(null);
      this.priceAndCurrencyFormGroup.controls.price.updateValueAndValidity({ emitEvent: false });
    } else {
      this.handlerValidatorSet(this._priceFormControl.value);
    }
  }
  get invalidName(): string {
    return this._invalidName;
  }
  @Input()
  set price(value: number | null) {
    if (this.invalidName) {
      this.handlerValidatorSet(value);
    }
    /* this.priceAndCurrencyFormGroup.controls.price.setValue(
      this._isFirstInput
        ? convertCurrencyToUiValue(value, this._currencyFormControl.value?.fiat_multiplier) || null
        : convertCurrencyToUiValue(value, this._currencyFormControl.value?.fiat_multiplier),
      { emitEvent: false }
    ); */
    if (isNil(value)) {
      this.priceAndCurrencyFormGroup.controls.price.patchValue(null, { emitEvent: false });
    } else {
      this.priceAndCurrencyFormGroup.controls.price.setValue(
        convertCurrencyToUiValue(value, this._currencyFormControl.value?.fiat_multiplier),
        { emitEvent: false }
      );
    }
    // this._isFirstInput = false;
    if (this._priceControlSubscription) {
      this._priceControlSubscription.unsubscribe();
    }
    this._priceControlSubscription = this.priceAndCurrencyFormGroup.controls.price.valueChanges
      .pipe(
        takeUntil(this._onDestroy$),
        startWith(this.priceAndCurrencyFormGroup.value.price),
        pairwise(),
        filter(([prev, curr]) => {
          return !isEqual(prev, curr);
        }),
        map(([prev, curr]) => {
          return curr;
        })
      )
      .subscribe((price) => {
        if (isNil(price) && this.setNull) {
          this._priceFormControl.setValue(null);
        } else {
          this._priceFormControl.setValue(
            convertCurrencyToServerValue(
              price,
              this.priceAndCurrencyFormGroup.controls.currency.value?.fiat_multiplier
            )
          );
        }
        if (this._priceFormControl.value) {
          this.priceHasChangeAndNotZero.emit(this._priceFormControl.value);
        }
      });
  }
  @Input()
  set currency(value: Currency | null) {
    this.priceAndCurrencyFormGroup.controls.currency.setValue(value, { emitEvent: false });
    if (this._currencyControlSubscription) {
      this._currencyControlSubscription.unsubscribe();
    }
    this._currencyControlSubscription =
      this.priceAndCurrencyFormGroup.controls.currency.valueChanges
        .pipe(
          takeUntil(this._onDestroy$),
          startWith(this.priceAndCurrencyFormGroup.controls.currency.value),
          pairwise(),
          filter(([prev, curr]) => !isEqual(prev, curr)),
          map(([prev, curr]) => {
            return curr;
          })
        )
        .subscribe((currency) => {
          this._currencyFormControl.patchValue(currency || null);
        });
  }
  @Input()
  set disabled(value: boolean) {
    if (value) {
      this.priceAndCurrencyFormGroup.disable({ emitEvent: false });
    } else {
      this.priceAndCurrencyFormGroup.enable({ emitEvent: false });
    }
  }
  @Input() currencies: Currency[] = [];
  @Input() customTabIndex = 0;
  @Input() id = 'currency-input';
  @Input() showCurrency = true;
  @Input() borderRightNone = false;
  @Input() setNull = false;
  @Input() placeholder = '';
  @Input() priceLabel = '';
  @Input() placeholderRight = false;
  @Output() priceHasChangeAndNotZero = new EventEmitter<number>();
  @Output() changeValidationErrors = new EventEmitter<ChangeValidation>();

  priceAndCurrencyFormGroup = new FormGroup(
    {
      price: new FormControl<number | null>(null),
      currency: new FormControl<Currency | null>(null),
    },
    { updateOn: DomEvents.Blur }
  );

  private _priceFormControl = new FormControl<number | null>(null);
  private _currencyFormControl = new FormControl<Currency | null>(null);
  private _onDestroy$ = new Subject<void>();
  private _max?: number;
  private _invalidName = '';
  private _priceControlSubscription?: Subscription;
  private _currencyControlSubscription?: Subscription;
  // private _disabled = false;
  // private _isFirstInput = true;

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  handlerValidatorSet(value: number | null): void {
    const acceptZero = this.invalidName.includes(TEXT_ZERO);
    this.priceAndCurrencyFormGroup.controls.price.setAsyncValidators(ZeroNullValidator(acceptZero));
    this.priceAndCurrencyFormGroup.controls.price.updateValueAndValidity({ emitEvent: false });
    const remove = acceptZero ? !isNil(value) : !(value === 0 || isNil(value));
    this.changeValidationErrors.emit({ field: this.invalidName, remove });
  }

  ngAfterViewChecked() {
    if (this.invalidName) {
      this.handlerValidatorSet(this._priceFormControl.value);
    }
  }
}
