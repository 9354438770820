import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Fuel } from '@estimator/models';
import { BaseService } from '../base';

@Injectable({
  providedIn: 'root',
})
export class FuelService extends BaseService<Fuel> {
  constructor(protected override readonly http: HttpClient) {
    super(http);
    this.setPath('/api/fuels');
  }
}
