import { EventEmitter } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import {
  BaseModel,
  BaseModelFormGroup,
  Company,
  Currency,
  Deal,
  Port,
  SimpleObject,
  Vessel,
} from '@estimator/models';
import { Subject } from 'rxjs';
import { Bunkering, BunkeringFormGroup } from './bunkering';
import { FileResponse } from './file';
import { Transaction, TransactionPayment } from './transaction';

export interface Agreement extends BaseModel {
  agreement_type?: AgreementType;
  bunkering_payment_type?: BunkeringPaymentType;
  bunkerings?: Bunkering[];
  pay_on_delivery?: boolean;
  contract_id?: string;
  created_by_company?: Company;
  created_by_company_id?: number;
  cve_rate?: number;
  cve_type?: CVEType;
  // cve_period_minutes?: number; deprecated
  // is_cve_period_month?: boolean; deprecated
  /**
   * cp_date
   */
  date?: string | Date;
  delivery_port?: Port;
  delivery_port_id?: number;
  description?: string;
  hire_agreement_id?: number;
  hire_period_minutes?: number;
  hire_rate?: number;
  operator_ids?: number[];
  owner?: Company;
  owner_id?: number;
  parsed_vessel?: Vessel;
  parsed_vessel_id?: number;
  pay_period_minutes?: number;
  redelivery_ports?: string;
  related_deal_id?: number;
  /**
   * only for front, dropdown related_deal
   */
  related_deal?: Deal;
  related_folder_id?: number;
  status?: StatusType;
  sub_agreements?: Agreement[];
  tc_commission?: number;
  voyage_number?: string;
  file_tree?: FileTreeModel;
  /**
   * for CR
   */
  charterer_1?: Company;
  charterer_1_id?: number;
  charterer_2?: Company;
  charterer_2_id?: number;
  voyage_owner_date?: string | Date;
  voyage_owner_description?: string;
}

export enum AgreementType {
  /**
   * top level
   */
  TCIN = 'TCIN',
  /**
   * top level
   */
  TCT = 'TCT',
  /**
   * old CR(Cargo Relet)
   */
  VR = 'VR',
  OWN = 'Own',
  OPERATIONAL = 'Operational',
  /**
   * bottom level, required hire_agreement_id
   */
  TCOUT = 'TCOUT',
  /**
   * bottom level, required hire_agreement_id
   */
  VC = 'VC',
}

export enum StatusType {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
}

export enum BunkeringPaymentType {
  BunkeringPaymentTypeOnConsumption = 'OnConsumption',
  BunkeringPaymentTypeOnDelivery = 'OnDelivery',
}

export const bunkeringPaymentTypesArr: SimpleObject<string, BunkeringPaymentType>[] = [
  { name: 'For consumption', value: BunkeringPaymentType.BunkeringPaymentTypeOnConsumption },
  { name: 'Delivery/Redelivery', value: BunkeringPaymentType.BunkeringPaymentTypeOnDelivery },
];

export interface AgreementRequest {
  search: string | number;
  /**
   * default true
   */
  top_level_only?: boolean;
  searchByID?: boolean;
}

export interface HandoverModalConfig extends HandoverEmptyModalConfig {
  // agreements: Agreement[];
  selectedDeal: Deal | null;
  deals: Deal[];
  vessel: Vessel | null;
  chartererCargo: Company | null;
  saveAgreements: EventEmitter<SaveAgreements>;
}

export interface UploadModalConfig {
  uploadFile: EventEmitter<FormData>;
  agreementId: string | null;
}

export interface PaidCellModalConfig {
  save: EventEmitter<TransactionPayment[]>;
  transaction: Transaction;
  currencies: Currency[];
}

export interface HandoverEmptyModalConfig {
  loadingSubject: Subject<boolean>;
  searchVessel: EventEmitter<string>;
  foundVessels: Subject<Partial<Vessel>[]>;
  searchCompany: EventEmitter<string>;
  createCompany: EventEmitter<string>;
  foundCompanies: Subject<Company[]>;
  newCompany: Subject<Company>;
  searchAgreement: EventEmitter<AgreementRequest>;
  foundAgreements: Subject<Agreement[]>;
  saveAgreement: EventEmitter<Agreement>;
  uploadAgreementFile: EventEmitter<UploadAgreementFileRequest>;
  foundUploadedFiles: Subject<FoundUploadedFiles>;
  resetUploadedFiles: EventEmitter<void>;
  // only in this interface
  showSecondModal?: boolean;
  emptyHandover?: boolean;
}

export interface AgreementFormGroup extends BaseModelFormGroup {
  agreement_type?: FormControl<AgreementType | null>;
  date?: FormControl<Date | string | null>;
  /**
   * no raw vessels
   */
  parsed_vessel?: FormControl<Vessel | null>;
  parsed_vessel_id?: FormControl<number | null>;
  owner?: FormControl<Company | null>;
  owner_id?: FormControl<number | null>;
  /**
   * For VR(Voyage Relet, old Cargo Relet) = Cargo owner
   */
  charterer_1?: FormControl<Company | null>;
  charterer_1_id?: FormControl<number | null>;
  /**
   * For VR(Voyage Relet, old Cargo Relet) = Vessel owner, in interface charterer_1 in thirdmodal(vms-handover-modal) = charterer_2
   */
  charterer_2?: FormControl<Company | null>;
  charterer_2_id?: FormControl<number | null>;
  voyage_owner_date?: FormControl<Date | string | null>;
  voyage_owner_description?: FormControl<Date | string | null>;

  /**
   * hire_agreement => for front, not exist in model Agreement
   */
  hire_agreement?: FormControl<Agreement | null>;
  hire_agreement_id?: FormControl<number | null>;
  description?: FormControl<string | null>;
  /**
   * fields for edit agreement in vms
   */
  hire_period_minutes?: FormControl<number | null>;
  pay_period_minutes?: FormControl<number | null>;
  hire_rate?: FormControl<number | null>;
  tc_commission?: FormControl<number | null>;
  cve_rate?: FormControl<number | null>;
  cve_type?: FormControl<CVEType | null>;
  // cve_period_minutes?: FormControl<number | null>;  deprecated
  // is_cve_period_month?: FormControl<boolean | null>; deprecated
  delivery_port?: FormControl<Port | null>;
  delivery_port_id?: FormControl<number | null>;
  redelivery_ports?: FormControl<string | null>;
  bunkering_payment_type?: FormControl<BunkeringPaymentType | null>;
  status?: FormControl<StatusType | null>;
  file_tree?: FormControl<FileTreeModel | null>;
  /**
   * edit in vms-agreement-bunkerings, own fg
   */
  bunkerings?: FormArray<FormGroup<BunkeringFormGroup>>;
  /**
   * true = pay on delivery, false = deduct on redelivery
   */
  pay_on_delivery?: FormControl<boolean | null>;
  voyage_number?: FormControl<string | null>;
  /**
   * edit in estimator + edit in vms for bottom_type
   */
  related_deal_id?: FormControl<number | null>;
  /**
   * only for front, dropdown related_deal
   */
  related_deal?: FormControl<Deal | null>;
  /**
   * edit only in estimator, not edit in vms
   */
  related_folder_id?: FormControl<number | null>;
  /**
   * values from back, not edit on front
   */
  created_by_company_id?: FormControl<number | null>;
  operator_ids?: FormControl<number[] | null>;
}

export interface SaveAgreements {
  agreementFirst: Agreement;
  agreementSecond: Agreement;
}

export interface FileTreeModel {
  agreement_id?: number;
  id?: number;
  nodes: VMSTreeNode[];
}

export interface VMSTreeNode {
  children?: VMSTreeNode[] | null;
  document_id?: number | null;
  name: string;
  type: VMSNodeType;
  /**
   * only for front
   */
  system_folder_type?: SystemFolderType;
}

export enum VMSNodeType {
  USER_FILE = 'USER_FILE',
  SYSTEM_FOLDER = 'SYSTEM_FOLDER',
  USER_FOLDER = 'USER_FOLDER',
}

export enum SystemFolderTypeText {
  /*   CHARTER_PARTY = 'Charter Party',
  VESSEL_DATA = 'Vessel Data', */
  RECAPS = 'Recaps',
  CERTIFICATES = 'Certificates',
}

// top = isShowTwoNodeFiles (TCIN, TCT, TCOUT, OPERATIONAL)
export enum SystemFolderType {
  RECAPS_OR_CERTIFICATES_SUB = 0,
  RECAPS_TOP,
  CERTIFICATES_TOP,
  RECAP_OWNER,
  RECAP_CHARTERER,
}

export interface UploadAgreementFileRequest {
  file: FormData;
  type: SystemFolderType;
}

export interface FoundUploadedFiles {
  files: FileResponse[];
  type: SystemFolderType;
}

/* export interface RequestSearchAgreements {
  value: string | number;
  searchByID: boolean;
} */

export enum CVEType {
  // perDays = "PER_DAYS", no use now, may be in future
  PerCalendarMonth = 'PER_CALENDAR_MONTH',
  PerYearProRata = 'PER_YEAR_PRO_RATA',
}

export const cveTypesArr: SimpleObject<string, CVEType>[] = [
  { name: 'Per month (per 30 days)', value: CVEType.PerCalendarMonth },
  { name: 'Per month pro rata', value: CVEType.PerYearProRata },
];
