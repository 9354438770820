import { ShiplistZoneType } from '@estimator/models';

export class GetCanals {
  static readonly type = '[ROUTE] Get canals';
}

export class GetEcaZones {
  static readonly type = '[ROUTE] Get eca zones';
}

export class GetShiplistZones {
  static readonly type = '[ROUTE] Get shiplist zones';
  constructor(public typeZone?: ShiplistZoneType) {}
}
