import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MultiSelectModule } from 'primeng/multiselect';
import { AgGridHeaderMultiselectComponent } from './ag-grid-header-multiselect.component';

@NgModule({
  declarations: [AgGridHeaderMultiselectComponent],
  imports: [FormsModule, MultiSelectModule],
  exports: [AgGridHeaderMultiselectComponent],
})
export class AgGridHeaderMultiselectModule {}
