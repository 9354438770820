import { DecimalPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { getColorFromVariablesSCSS } from '@estimator/helpers';
import { BreakEvenCargo, BreakEvenData } from '@estimator/models';
import { AgCartesianSeriesTooltipRendererParams, AgChartOptions } from 'ag-grid-community';

@Component({
  selector: 'estimator-break-even-axes',
  templateUrl: './break-even-axes.component.html',
  styleUrls: ['./break-even-axes.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreakEvenAxesComponent implements OnInit {
  @Input()
  set data(data: BreakEvenData[]) {
    this._data = data;
  }
  get data(): BreakEvenData[] {
    return this._data;
  }
  private _data: BreakEvenData[] = [];

  @Input()
  set initialArrayFields(initialArrayFields: string[]) {
    this._initialArrayFields = initialArrayFields;
    if (initialArrayFields?.length) {
      this.series = this.getSeries();
      if (this.options) {
        this.options.series = this.series;
      }
    }
  }
  get initialArrayFields(): string[] {
    return this._initialArrayFields;
  }
  private _initialArrayFields: string[] = [];

  options!: AgChartOptions;
  series: any[] = [];
  colors: string[] = [];
  private readonly cargoText = 'Cargo';
  private readonly resultText = 'Result';

  constructor(private readonly decimalPipe: DecimalPipe) {}

  ngOnInit(): void {
    this.colors = [
      getColorFromVariablesSCSS('primary'),
      getColorFromVariablesSCSS('accent'),
      getColorFromVariablesSCSS('success'),
      getColorFromVariablesSCSS('notification'),
    ];
    this.initAxes();

  }

  prepareDataForGraph(data: BreakEvenData[]): BreakEvenData[] {
    return data.map((element: BreakEvenData) => {
      element.cargoes.forEach((cargo: BreakEvenCargo) => {
        element[cargo.name] = cargo.price;
      });
      return element;
    });
  }

  initAxes() {
    this.options = {
      autoSize: true,
      data: this.prepareDataForGraph(this.data),
      theme: {
        palette: {
          // from variables scss
          fills: this.colors,
          strokes: this.colors,
        },
      },
      series: this.series,
      axes: [
        {
          type: 'number',
          position: 'left',
          title: {
            text: this.resultText,
            fontSize: 12,
          },
          gridStyle: this.defaultGridStyle,
        },
        {
          type: 'number',
          position: 'bottom',
          title: {
            text: this.cargoText,
            fontSize: 12,
          },
          gridStyle: this.defaultGridStyle,
        },
      ],
      legend: {
        position: 'top',
        item: {
          marker: {
            shape: 'square',
            strokeWidth: 0,
          },
        },
      },
    };
  }
  defaultGridStyle = [
    {
      stroke: '',
    },
  ];

  getSeries() {
    let series: any[] = [];
    this.initialArrayFields.forEach((item) => {
      const value = {
        type: 'line',
        xKey: item,
        yKey: 'result',
        yName: item,
        xName: this.cargoText,
        strokeWidth: 5,
        label: {
          formatter: (params: any) => {
            return this.valueToDecimalPipe(this.decimalPipe, params.value);
          },
        },
        tooltip: this.changeTooltip(),
        visible: false,
      };
      series?.push(value);
    });
    series = [
      ...series,
      {
        type: 'line',
        xKey: series[0]?.xKey?.toString(),
        yKey: 'hire',
        yName: 'Net hire',
        strokeWidth: 5,
        label: {
          formatter: (/* params: any */) => this.formatValue(/* params.value */),
          enabled: false,
        },
        tooltip: this.changeTooltip(),
      },
      {
        type: 'line',
        xKey: series[0]?.xKey?.toString(),
        yKey: 'ntce',
        yName: 'NTCE',
        strokeWidth: 5,
        label: {
          formatter: (/* params: any */) => this.formatValue(/* params.value */),
        },
        tooltip: this.changeTooltip(),
      },
      {
        type: 'line',
        xKey: series[0]?.xKey?.toString(),
        yKey: 'result',
        yName: this.resultText,
        strokeWidth: 5,
        label: {
          formatter: (/* params: any */) => this.formatValue(/* params.value */),
        },
        tooltip: this.changeTooltip(),
      },
    ];
    return series;
  }

  changeTooltip() {
    return {
      renderer: (params: AgCartesianSeriesTooltipRendererParams) => {
        return {
          content: `${
            params.xName === this.cargoText ? `${this.resultText}: ` : ''
          } ${this.valueToDecimalPipe(this.decimalPipe, params.yValue)}</br>
          ${this.getCargo(params.datum)}`,
        };
      },
    };
  }

  valueToDecimalPipe(decimalPipe: DecimalPipe, value: any) {
    return decimalPipe?.transform(value, '0.0-2');
  }

  getCargo(data: any) {
    let string = '';
    for (const initialArrayField of this.initialArrayFields) {
      string += initialArrayField + ': ' + data[initialArrayField].toFixed(2) + '</br>';
    }
    return string;
  }

  formatValue(/* value: number */) {
    return ''; /* (
      this.valueToDecimalPipe(
        this.decimalPipe,
        Math.abs(value) > CONDITION_COUNT_FOR_FORMAT_AXES
          ? value / CONDITION_COUNT_FOR_FORMAT_AXES
          : value
      ) + (Math.abs(value) > CONDITION_COUNT_FOR_FORMAT_AXES ? 'k' : '')
    ); */
  }
}
