import { FormControl, FormGroup } from '@angular/forms';
import { BaseEntity } from './base-entity';
import { Currency } from './currency';
import { Fuel } from './fuel';

export interface UseRate {
  ballast?: number;
  idle?: number;
  laden?: number;
  work_on_loading?: number;
  work_on_discharge?: number;
}

export interface ConsumptionRate extends BaseEntity {
  aux_engine_rate?: UseRate;
  fuel?: Fuel;
  fuel_id?: number;
  price?: number;
  main_engine_rate?: UseRate;
  unique_row_id?: string;
  currency?: Currency;
  cargo_heating_rate?: number;
}

export interface ConsumptionRateFormGroup {
  aux_engine_rate?: FormGroup<UseRateFormGroup>;
  fuel: FormControl<Fuel | null>;
  fuel_id: FormControl<number | null>;
  main_engine_rate: FormGroup<UseRateFormGroup>;
  price: FormControl<number | null>;
  currency: FormControl<Currency | null>;
  cargo_heating_rate?: FormControl<number | null>;
}

export interface UseRateFormGroup {
  ballast: FormControl<number | null>;
  idle: FormControl<number | null>;
  laden: FormControl<number | null>;
  work_on_loading: FormControl<number | null>;
  work_on_discharge: FormControl<number | null>;
}

export interface FuelConsumption extends BaseEntity {
  amount?: number;
  co2_amount?: number;
  currency?: Currency;
  currency_id?: number;
  deal_id?: number;
  fuel?: Fuel;
  fuel_id?: number;
  price?: number;
}
