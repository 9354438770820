<div class="calculator-header">
  <h4>Cargo Calculator</h4>
  <div class="display-flex items-center gap-10">
    Fast <p-inputSwitch [(ngModel)]="isAdvanced"></p-inputSwitch> Advanced
  </div>

  <button mat-icon-button mat-dialog-close class="approve-for-pointer" id="close_cargo_calculator_button">
    <mat-icon>close</mat-icon>
  </button>
</div>
<form class="material-form select-row">
  <mat-form-field appearance="outline" class="select-cargo">
    <mat-label>Selected cargo</mat-label>
    <mat-select
      [formControl]="selectedCargoControl"
      [compareWith]="compareObjects"
      id="intake-calculator__cargo-select"
    >
      <mat-option *ngFor="let cargo of cargoes" [value]="cargo">
        #{{ cargo.internal_order }} - {{ cargo.freight_type?.name || NOT_APPLICABLE }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</form>
<estimator-cargo-calculator
  [isAdvanced]="isAdvanced"
  [cargoCalculator]="selectedCargoCalculator"
  [grainBaleCalculationType]="grainBaleCalculationType"
  (calculate)="onCalculate($event)"
  (updateCargoQuantity)="onUpdateCargoQuantity($event)"
  (getLastConstants)="onGetLastConstants()"
></estimator-cargo-calculator>
