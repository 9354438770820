<div class="flex-container-column">
  <!-- <div class="p-fluid mb-5">
    <p-message
      *ngIf="responseError"
      [severity]="NotificationType.Error"
      [text]="responseError"
    ></p-message>
  </div> -->
  <section class="p-fluid mt-5">
    <p-button
      class="w-100"
      size="small"
      label="Confirm"
      (click)="onConfirm()"
    ></p-button>
  </section>
</div>
