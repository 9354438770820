import { Injectable } from '@angular/core';
import { UserSubscription } from '@estimator/models';
import { ProductsService } from '@estimator/services';
import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { catchError, finalize, tap, throwError } from 'rxjs';
import { GetUserSubscriptions } from './products.actions';

export interface ProductsStateModel {
  subscriptions: UserSubscription[];
  loading: boolean;
}

@State<ProductsStateModel>({
  name: 'products',
  defaults: {
    subscriptions: [],
    loading: false,
  },
})
@Injectable()
export class ProductsState implements NgxsOnInit {
  @Selector()
  static getIsLoading({ loading }: ProductsStateModel): boolean {
    return loading;
  }
  @Selector()
  static getUserSubscriptions({ subscriptions }: ProductsStateModel): UserSubscription[] {
    return subscriptions;
  }

  constructor(private readonly productsService: ProductsService) {}

  ngxsOnInit({ dispatch }: StateContext<ProductsStateModel>): void {
    dispatch(new GetUserSubscriptions());
  }

  @Action(GetUserSubscriptions)
  getAllSubscriptionsModels({ patchState }: StateContext<ProductsStateModel>) {
    patchState({ loading: true });
    return this.productsService.getUserSubscriptionModels().pipe(
      tap(({ subscription_models }) => {
        patchState({ subscriptions: subscription_models });
      }),
      catchError((err) => {
        patchState({ loading: false });
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }
}
