import { PrimeNgIcons } from '@estimator/models';
import { FileResponse, FileUploadType } from '@vms-models';
import { PrimeIcons } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';

export const toBase64 = (file: File): Promise<string | null> => {
  return new Promise((resolve, reject) => {
    const fr = new FileReader();
    fr.onload = (ev) => {
      const result = ev.target?.result as string;
      const parts = result.split('base64,');
      resolve(parts[1]);
    };
    fr.onerror = (ev) => reject(ev);
    fr.readAsDataURL(file);
  });
};

export const getIconFile = (file: FileUpload | FileResponse): PrimeIcons => {
  if (file.name?.includes(FileUploadType.EXCEL)) {
    return PrimeIcons.FILE_EXCEL;
  }
  if (file.name?.includes(FileUploadType.PDF)) {
    return PrimeIcons.FILE_PDF;
  }
  if (file.name?.includes(FileUploadType.WORD)) {
    return PrimeIcons.FILE_WORD;
  }
  return PrimeNgIcons.FILE;
};

export const getIconColor = (file: {
  name?: string;
}): { color: string } => {
  if (file.name?.includes(FileUploadType.EXCEL)) {
    return { color: 'var(--green-400)' };
  }
  if (file.name?.includes(FileUploadType.PDF)) {
    return { color: 'var(--red-color)' };
  }
  if (file.name?.includes(FileUploadType.WORD)) {
    return { color: 'var(--blue-color)' };
  }
  return { color: 'inherit' };
};
