import { DatePipe } from '@angular/common';
import {
  AfterContentChecked,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  ZeroNullValidator,
  changedKeys,
  getDatePipeTimeZoneFormat,
  getEventTimezoneOffset,
  getLocalTimeZoneOffsetInHours,
  getRequiredCanalsDA,
  getTimeInMinutes,
  getUserOrSystemDuration,
  isCanalEvent,
  isCargoEvent,
  isLocalTimeSetting,
  isPrimaryEvent,
  isSecondaryEvent,
  isStopEvent,
  localeDateFormatPrimeNGInput,
  makeBaseEntityPartFormGroup,
  pointExisted,
  userTimeZone,
} from '@estimator/helpers';
import { defaultUserDuration } from '@estimator/mocks';
import {
  Cargo,
  CargoRules,
  ChangePreset,
  ChangeValidation,
  ColumnUI,
  Currency,
  DATE_INPUT_TIME_FORMAT,
  DEFAULT_AUTOSAVE,
  DEFAULT_FIAT_MULTIPLIER,
  DealEvent,
  DealEventType,
  DealFormHasChanges,
  DealMetaInfo,
  DomEvents,
  EVENTS_FORM_GROUP_PORT,
  EVENTS_TABLE_COLUMNS,
  EventFormGroup,
  EventMetaInfoFormGroup,
  EventTableColumnType,
  Fuel,
  FuelConsumption,
  FuelTypes,
  FuelsExtended,
  GeoCoordinates,
  GeoZone,
  ID_USER_SETTINGS_FORM_SHOW_CANALS,
  LaytimeData,
  LaytimeDataForm,
  MAX_FINANCE_VALUE,
  NOT_APPLICABLE,
  OptionalId,
  Port,
  Preset,
  PresetNames,
  PrimeNgColors,
  PrimeNgIcons,
  ROUTE_POINT_TEXT,
  SHINC_ID,
  SetPoint,
  SimpleButtonSwitch,
  SummLDRateAndETbyCargoID,
  TEXT_ZERO,
  THREE_NUMBER,
  UserTimeSettings,
  WorkType,
} from '@estimator/models';
import { cloneDeep, isEqual, isNil, isNull, isObject, isString } from 'lodash';
import moment from 'moment';
import { AutoCompleteCompleteEvent, AutoCompleteSelectEvent } from 'primeng/autocomplete';
import { Checkbox } from 'primeng/checkbox';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { TableRowReorderEvent } from 'primeng/table';
import { Subject, debounceTime, filter, map, pairwise, takeUntil } from 'rxjs';

/**
 * showCanalEvent
 * canalRequiredDA
 * startConditionForGetSummData
 * breakConditionForGetSummData
 * continueConditionForGetSummData
 * To work with these methods, pay attention to EstimationsPortWidgetComponent
 */
@Component({
  selector: 'estimator-events-form',
  templateUrl: './events-form.component.html',
  styleUrls: ['./events-form.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventsFormComponent implements OnInit, OnDestroy, AfterContentChecked {
  @ViewChild('eventsAction', { read: ElementRef }) eventsAction?: ElementRef;
  @ViewChild('robs', { read: ElementRef }) robs?: ElementRef;
  @HostListener('document:keydown', ['$event'])
  addEventHotKey(event: KeyboardEvent): void {
    const activeElement = document.activeElement as HTMLElement;
    const activeElementId = Number(activeElement?.id?.match(/\d+/)?.[0]);
    if (event.ctrlKey && event.key === 'd' && (activeElementId || activeElementId === 0)) {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
      this.onAddEvent(activeElementId);
      this.eventsFormReady.emit({ id: activeElementId + 1 });
    }
    if (event.ctrlKey && event.key === 'g' && activeElementId && activeElementId !== 0) {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
      this.onDeleteEvent(activeElementId);
      this.eventsFormReady.emit({ id: activeElementId - 1 });
    }
  }

  static makeEventFormGroup(
    event?: DealEvent,
    isInerEvent?: boolean,
    showCanals?: boolean,
    weatherFactor?: number,
    defaultCanals?: number[],
    defaultTermsId?: number,
    defaultWorkType?: WorkType,
    defaultHighPurityFuelName?: FuelTypes
  ): FormGroup<EventFormGroup> {
    const fg = new FormGroup<EventFormGroup>(
      {
        ...makeBaseEntityPartFormGroup(event).controls,
        alternative_events: new FormControl(event?.alternative_events || null),
        created_by_temporary_user: new FormControl(event?.created_by_temporary_user || false),
        deal_id: new FormControl(event?.deal_id || null),
        // end_point: new FormControl(event?.end_point || null),
        end_time: new FormControl(event?.end_time ? new Date(event.end_time) : null),
        group_id: new FormControl(event?.group_id || null),
        nearest_node_id: new FormControl(event?.nearest_node_id || null),
        order: new FormControl(event?.order || null),
        parallel_with: new FormControl(event?.parallel_with || null),
        port: new FormControl(
          (isCanalEvent(event) ? { name: event?.meta?.current_canal_name } : event?.port) || null
        ),
        port_id: new FormControl(event?.port_id || null),
        point: new FormControl(event?.point || null),
        start_time: new FormControl({
          value: event?.start_time ? new Date(event.start_time) : null,
          disabled: true,
        }),
        type: new FormControl(
          event?.type ? event.type : isInerEvent ? null : DealEventType.EventTypeStop
        ),
        unique_row_id: new FormControl(event?.unique_row_id || null),
        inner_events: new FormControl(event?.inner_events || null),
        meta: EventsFormComponent.makeEventMetaFormGroup(
          event?.meta,
          showCanals,
          weatherFactor,
          defaultCanals,
          defaultTermsId,
          defaultWorkType,
          defaultHighPurityFuelName
        ),
        updated_by_temporary_user: new FormControl(event?.updated_by_temporary_user || false),
        cargo_id: new FormControl(event?.cargo_id || null),
        time_stamp: new FormControl(new Date().getTime()),
      },
      { updateOn: DomEvents.Blur }
    );
    if (!isInerEvent) {
      fg.addControl(
        'single_inner_event',
        EventsFormComponent.makeEventFormGroup(
          event?.single_inner_event,
          true,
          showCanals,
          weatherFactor,
          defaultCanals,
          defaultTermsId,
          defaultWorkType,
          defaultHighPurityFuelName
        )
      );
    }
    if (event && isCanalEvent(event)) {
      fg.controls.port.disable();
      fg.controls.type.disable();
      fg.controls.single_inner_event?.controls?.meta?.controls?.load_discharge_rate?.disable();
      fg.controls.single_inner_event?.controls?.meta?.controls?.terms_id?.disable();
      fg.controls.single_inner_event?.controls?.cargo_id?.disable();
      fg.controls.single_inner_event?.controls.meta.controls.amount.disable();
      fg.controls.single_inner_event?.controls.meta.controls.work_amount.disable();
      fg.controls.single_inner_event?.controls.meta.controls.work_type.disable();
    }
    return fg;
  }

  static makeEventMetaFormGroup(
    meta?: DealMetaInfo,
    showCanals?: boolean,
    weatherFactor?: number,
    defaultCanals?: number[],
    defaultTermsId?: number,
    defaultWorkType?: WorkType,
    defaultHighPurityFuelName?: FuelTypes
  ): FormGroup<EventMetaInfoFormGroup> {
    const fg = new FormGroup<EventMetaInfoFormGroup>(
      {
        route_geojson: new FormControl(meta?.route_geojson || null),
        distance: new FormControl(meta?.distance || null),
        amount: new FormControl(meta?.amount || null),
        disbursement: new FormControl(
          /* convertCurrencyToUiValue(
            meta?.disbursement,
            meta?.disbursement_currency?.fiat_multiplier
          )  */ meta?.disbursement || meta?.disbursement === 0 ? meta?.disbursement : null
        ),
        disbursement_currency: new FormControl(meta?.disbursement_currency || null),
        // cargo_id: new FormControl(meta?.cargo_id || null),
        fuel_id: new FormControl(meta?.fuel_id || null),
        fuel_consumptions: new FormControl(meta?.fuel_consumptions || null),
        extra_minutes_after: new FormControl(meta?.extra_minutes_after || defaultUserDuration),
        extra_minutes_before: new FormControl(meta?.extra_minutes_before || defaultUserDuration),
        extra_working_minutes: new FormControl({
          value: meta?.extra_working_minutes || defaultUserDuration,
          disabled: true,
        }),
        idle_minutes: new FormControl(meta?.idle_minutes || null),
        load_discharge_rate: new FormControl(meta?.load_discharge_rate || null),
        order: new FormControl(meta?.order || null),
        terms_id: new FormControl(meta?.terms_id || defaultTermsId || SHINC_ID), // 9 = shinc id
        canals: new FormControl(meta?.canals || defaultCanals || []),
        current_canal_id: new FormControl(meta?.current_canal_id || null),
        current_canal_name: new FormControl(meta?.current_canal_name || null),
        internal_canal_sog: new FormControl(meta?.internal_canal_sog || null),
        internal_canal_distance: new FormControl(meta?.internal_canal_distance || null),
        internal_canal_distance_eca: new FormControl(meta?.internal_canal_distance_eca || null),
        internal_canal_route_geojson: new FormControl(meta?.internal_canal_route_geojson || null),
        internal_canal_sailing_minutes: new FormControl(
          meta?.internal_canal_sailing_minutes || null
        ),
        internal_canal_sailing_eca_minutes: new FormControl(
          meta?.internal_canal_sailing_eca_minutes || null
        ),
        use_eca: new FormControl(meta?.use_eca === false ? false : true),
        eca_distance: new FormControl(meta?.eca_distance || null),
        port_idle_time: new FormControl(meta?.port_idle_time || null),
        port_time: new FormControl(meta?.port_time || null),
        sailing_eca_minutes: new FormControl(meta?.sailing_eca_minutes || null),
        sailing_minutes: new FormControl(meta?.sailing_minutes || null),
        // sog: new FormControl(meta?.sog || null),
        timezone_offset: new FormControl(meta?.timezone_offset || null),
        timezone_name: new FormControl(meta?.timezone_name || null),
        weather_factor: new FormControl(
          meta?.weather_factor || meta?.weather_factor === 0
            ? meta?.weather_factor
            : weatherFactor || null
        ),
        notice: new FormControl(meta?.notice || null),
        total_bunker: new FormControl(meta?.total_bunker || null),
        total_discharge: new FormControl(meta?.total_discharge || null),
        total_load: new FormControl(meta?.total_load || null),
        preset: new FormControl(meta?.preset?.name ? meta?.preset : null),
        work_type: new FormControl(meta?.work_type || defaultWorkType || WorkType.AmountPerDay),
        work_amount: new FormControl(meta?.work_amount || null),
        working_minutes: new FormControl({ value: meta?.working_minutes || null, disabled: true }),
        is_laden: new FormControl(meta?.is_laden || false),
        // rob
        residual_rob: new FormControl({
          value: meta?.residual_rob || null,
          disabled: true,
        }),
        hsfo_rob: new FormControl({
          value: meta?.hsfo_rob || null,
          disabled: true,
        }),
        distillate_rob: new FormControl({
          value: meta?.distillate_rob || null,
          disabled: true,
        }),
        ulsfo_rob: new FormControl({ value: meta?.ulsfo_rob || null, disabled: true }),
        // bunker
        residual_bunker: new FormControl(meta?.residual_bunker || null),
        hsfo_bunker: new FormControl(meta?.hsfo_bunker || null),
        distillate_bunker: new FormControl(meta?.distillate_bunker || null),
        ulsfo_bunker: new FormControl(meta?.ulsfo_bunker || null),
        using_cranes_on_vessel: new FormControl(!!meta?.using_cranes_on_vessel),
        use_fuel_type: new FormControl(meta?.use_fuel_type || FuelTypes.LSMGO),
        demurrage_rate: new FormControl(meta?.demurrage_rate || null),
        despatch_rate: new FormControl(
          meta?.despatch_rate || (meta?.demurrage_rate || 0) / 2 || null
        ),
        laytime_difference_minutes: new FormControl(meta?.laytime_difference_minutes || null),
        laytime_data: EventsFormComponent.makeLaytimeDataFormGroup(
          meta?.laytime_data,
          defaultWorkType
        ),
        high_purity_fuel_name: new FormControl(
          meta?.high_purity_fuel_name || defaultHighPurityFuelName || null
        ),
        high_purity_sailing_eca_minutes: new FormControl(
          meta?.high_purity_sailing_eca_minutes || null
        ),
      },
      { updateOn: DomEvents.Blur }
    );
    fg.controls.distance.disable();
    return fg;
  }

  static makeLaytimeDataFormGroup(
    data?: LaytimeData,
    defaultWorkType?: WorkType
  ): FormGroup<LaytimeDataForm> {
    return new FormGroup<LaytimeDataForm>({
      terms_id: new FormControl(data?.terms_id || null),
      amount: new FormControl(data?.amount || null),
      use_fuel_type: new FormControl(data?.use_fuel_type || FuelTypes.LSMGO),
      working_minutes: new FormControl(data?.working_minutes || null),
      idle_minutes: new FormControl({ value: data?.idle_minutes || null, disabled: true }),
      extra_working_minutes: new FormControl({
        value: data?.extra_working_minutes || null,
        disabled: true,
      }),
      work_amount: new FormControl(data?.work_amount || null),
      work_type: new FormControl(data?.work_type || defaultWorkType || WorkType.AmountPerDay),
      /* using_cranes_on_vessel: new FormControl(
        data?.using_cranes_on_vessel === false ? false : true
      ), */
      load_discharge_rate: new FormControl(data?.load_discharge_rate || null),
    });
  }

  @Input()
  set events(data: DealEvent[]) {
    this._events = cloneDeep(data);
    this.eventsFormArray.clear();
    this.ballastTime = 0;
    this.ladenTime = 0;
    this._events.forEach((event) => {
      if (event.meta?.is_laden) {
        this.ladenTime += getUserOrSystemDuration(event.meta?.sailing_minutes) || 0;
      } else {
        this.ballastTime += getUserOrSystemDuration(event.meta?.sailing_minutes) || 0;
      }
    });
    this.setDisplayEvents();
  }
  get events(): DealEvent[] {
    return this._events;
  }
  @Input()
  set showCanals(value: boolean) {
    this._showCanals = value;
    this.showCanalsControl.patchValue(!!value, { emitEvent: false });
  }
  get showCanals(): boolean {
    return this._showCanals;
  }

  @Input()
  set timeSettings(settings: UserTimeSettings) {
    this._timeSettings = settings;
    this.updateStartTime();
  }
  get timeSettings(): UserTimeSettings {
    return this._timeSettings;
  }

  @Input()
  set useUlsfo(value: boolean) {
    this._useUlsfo = value;
    if (!value) {
      this.eventsFormArray.controls.forEach((eventFg) => {
        eventFg.controls.single_inner_event?.controls.meta.controls.use_fuel_type.patchValue(
          FuelTypes.LSMGO,
          { emitEvent: false }
        );
      });
      this.eventsFormArray.updateValueAndValidity();
    }
  }
  get useUlsfo(): boolean {
    return this._useUlsfo;
  }

  @Input()
  set isTanker(value: boolean) {
    if (value) {
      this.neededWorkType = WorkType.Hours;
    }
    this._isTanker = value;
    this.stateKey = `event_form_table-${this.isTanker}`;
  }
  get isTanker(): boolean {
    return this._isTanker;
  }

  @Input()
  set canals(value: GeoZone[]) {
    this._canals = value;
    if (value) {
      this.requiredCanalsDA = getRequiredCanalsDA(value);
    }
  }
  get canals(): GeoZone[] {
    return this._canals;
  }

  @Input()
  set cargoes(cargo: Cargo[]) {
    this._cargoes = cargo;
    if (cargo?.length) {
      this.validateEventsForm();
    }
  }
  get cargoes(): Cargo[] {
    return this._cargoes;
  }

  @Input()
  set isFixed(value: boolean) {
    this._isFixed = value;
  }
  get isFixed(): boolean {
    return this._isFixed;
  }

  @Input()
  set cargoesIdSet(value: Set<number>) {
    this._cargoesIdSet = value;
    this.getSummLDRateAndETbyCargoID();
  }
  get cargoesIdSet(): Set<number> {
    return this._cargoesIdSet;
  }

  @Input() ports: Port[] = [];
  @Input() presets: Preset[] = [];
  @Input() cargoRules: CargoRules[] = [];
  @Input() currencies: Currency[] = [];
  @Input() fuels: Fuel[] = [];
  @Input() defaultWeatherFactor = 0;
  @Input() defaultTermsId = SHINC_ID;
  @Input() defaultAutoSave = DEFAULT_AUTOSAVE;
  @Input() defaultCranes = false;
  @Input() consumptions: FuelConsumption[] = [];
  @Input() dealId = 0;
  @Input() defaultCanals: number[] = [];
  @Input() scrubber = false;
  @Input() impassableZones: GeoZone[] = [];
  @Input() vesselDwt = 0;
  @Input() generalVesselID = 0;
  @Input() isPortWidgetVisible = false;
  @Output() searchPort = new EventEmitter<string>();
  // @Output() deleteEvent = new EventEmitter<DealEvent>();
  // @Output() createEvent = new EventEmitter<number>();
  // @Output() updateEvent = new EventEmitter<DealEvent>();
  @Output() createCargo = new EventEmitter<FormGroup<EventFormGroup>>();
  @Output() showRoute = new EventEmitter<DealEvent>();
  @Output() addPoint = new EventEmitter<SetPoint>();
  @Output() replaceEvents = new EventEmitter<DealEvent[]>();
  // @Output() updateAllEvents = new EventEmitter<DealEvent[]>();
  @Output() hasChanges = new EventEmitter<DealFormHasChanges>();
  @Output() changeCargo = new EventEmitter<{ cargo: Cargo; quantity: number }>();
  @Output() eventsFormReady = new EventEmitter<OptionalId>();
  @Output() showNotificationChangeWFInCanal = new EventEmitter();
  @Output() changeValidationErrors = new EventEmitter<ChangeValidation>();
  @Output() changePreset = new EventEmitter<ChangePreset>();
  @Output() changeShowCanals = new EventEmitter<boolean>();
  @Output() setSummLDRateAndETbyCargoID = new EventEmitter<SummLDRateAndETbyCargoID[]>();
  @Output() setSummETwithoutCargoID = new EventEmitter<number>();
  @Output() openPortDisbursementsModal = new EventEmitter<DealEvent>();
  eventsFormArray: FormArray<FormGroup<EventFormGroup>> = new FormArray<FormGroup<EventFormGroup>>(
    [],
    { updateOn: DomEvents.Blur }
  );
  eventTypeOptions = [
    DealEventType.EventTypeLoad,
    DealEventType.EventTypeDischarge,
    DealEventType.EventTypeBunker,
    DealEventType.EventTypeStop,
  ];
  workTypeOptions: WorkType[] = Object.values(WorkType);
  ballastTime = 0;
  ladenTime = 0;
  // draggableIndex: null | number = null;
  prevEditedDealEventValue?: Partial<DealEvent>;
  currEditedDealEventValue?: Partial<DealEvent>;
  currEditedEventId?: number;
  editedType = false;
  changePort = false;
  stateKey = '';
  showCanalsControl = new FormControl<boolean>(false, { nonNullable: true });
  requiredCanalsDA?: string[];
  neededWorkType = WorkType.AmountPerDay;
  highPurityFuelNameFromFirstEvent = FuelTypes.LSMGO;
  dateFormat = localeDateFormatPrimeNGInput();
  readonly PrimeNgIcons = PrimeNgIcons;
  readonly PrimeNgColors = PrimeNgColors;
  readonly NOT_APPLICABLE = NOT_APPLICABLE;
  readonly ROUTE_POINT_TEXT = ROUTE_POINT_TEXT;
  readonly EVENTS_FORM_GROUP_PORT = EVENTS_FORM_GROUP_PORT;
  readonly columns = EVENTS_TABLE_COLUMNS;
  readonly EventTableColumnType = EventTableColumnType;
  readonly FuelTypes = FuelTypes;
  readonly _quantityCheck = 'quantity-check';
  readonly _eventTypeCheck = 'event-type-check';
  // also use in estimator-days-distance-indicators
  readonly ID_USER_SETTINGS_FORM_SHOW_CANALS = ID_USER_SETTINGS_FORM_SHOW_CANALS;
  readonly MAX_FINANCE_VALUE = MAX_FINANCE_VALUE;
  private _onDestroy$ = new Subject<void>();
  private _events: DealEvent[] = [];
  private _displayedEvents: DealEvent[] = [];
  private _showCanals = true;
  private _mockFormControl = new FormControl();
  private _timeSettings = UserTimeSettings.PortLocalTime;
  private _useUlsfo = false;
  private _isTanker = false;
  private _canals: GeoZone[] = [];
  private _cargoes: Cargo[] = [];
  private _isFixed = false;
  private _cargoesIdSet: Set<number> = new Set();

  get mockFormControl(): FormControl {
    this.mockFormControl.disable();
    return this._mockFormControl;
  }

  get visibleControls(): FormGroup<EventFormGroup>[] {
    const controls = this.eventsFormArray.controls.filter((control) => {
      if (this.showCanals) {
        return isPrimaryEvent(control.getRawValue() as DealEvent);
      } else {
        return this.showCanalEvent(control.getRawValue() as DealEvent);
      }
    });
    return controls;
  }

  get userTimeZone(): string {
    return userTimeZone();
  }

  /*   get residualPlaceHolder(): string {
    if (this.scrubber) {
      return FuelTypes.HSFO;
    }
    return FuelTypes.VLSFO;
  }

    get fuelTypesMock(): FuelTypes[] {
    const fuels: FuelTypes[] = [];
    if (this.scrubber) {
      fuels.push(FuelTypes.HSFO, FuelTypes.LSMGO);
    } else {
      fuels.push(FuelTypes.VLSFO, FuelTypes.LSMGO);
    }
    if (this.useUlsfo) {
      fuels.unshift(FuelTypes.ULSFO);
    }
    return fuels;
  } */

  get fuelTypesExtendedMock(): SimpleButtonSwitch[] {
    const fuels: SimpleButtonSwitch[] = [];
    if (this.scrubber) {
      fuels.push(FuelsExtended.HSFO, FuelsExtended.LSMGO);
    } else {
      fuels.push(FuelsExtended.VLSFO, FuelsExtended.LSMGO);
    }
    if (this.useUlsfo) {
      fuels.unshift(FuelsExtended.ULSFO);
    }
    if (this.highPurityFuelNameFromFirstEvent === FuelTypes.VLSFO) {
      fuels.push(FuelsExtended.VLSFO);
    }
    return fuels;
  }

  constructor(private readonly datePipe: DatePipe) {}

  ngOnInit(): void {
    this.showCanalsControl.valueChanges.pipe(takeUntil(this._onDestroy$)).subscribe((value) => {
      this.changeShowCanals.emit(value);
    });
  }

  ngAfterContentChecked(): void {
    if (this.isFixed) {
      this.eventsFormArray.disable();
    }
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  onSearchPort(event: AutoCompleteCompleteEvent): void {
    if (event.query?.length >= THREE_NUMBER) {
      this.searchPort.emit(event.query);
    }
  }

  onSelectPort(event: AutoCompleteSelectEvent, form: FormGroup<EventFormGroup>): void {
    const port = event.value as Port;
    this.changePort = true;
    this.editedTypeFalse();
    if (port.id) {
      form.controls.port_id.patchValue(port.id /* { emitEvent: false } */);
      form.controls.point.patchValue(null, { emitEvent: false });
    }
    const fuelTypeControl = this.getUseFuelTypeControl(form);
    if (port.is_eca) {
      fuelTypeControl?.patchValue(FuelTypes.LSMGO);
    } else {
      if (this.scrubber) {
        fuelTypeControl?.patchValue(FuelTypes.HSFO);
      } else {
        fuelTypeControl?.patchValue(FuelTypes.VLSFO);
      }
    }
  }

  onClearPort(event: any, form: FormGroup<EventFormGroup>) {
    this.changePort = true;
    this.editedTypeFalse();
    form.controls.port_id.patchValue(null, { emitEvent: false });
  }

  onDeleteEvent(index: number): void {
    const visibleControlsIndex = this.getVisibleControlsIndex(index);
    this.eventsFormArray.removeAt(visibleControlsIndex);
    this.hasChanges.emit({ change: true, changePort: true });
    this.validateEventsForm();
    this.changeValidationErrors.emit({ field: `amount-${visibleControlsIndex}`, remove: true });
    this.changeValidationErrors.emit({
      field: `work-amount-${visibleControlsIndex}`,
      remove: true,
    });
    this.changeValidationErrors.emit({ field: `da-${visibleControlsIndex}-zero`, remove: true });
    this.changeValidationErrors.emit({ field: `da-${visibleControlsIndex}`, remove: true });
    this.changeValidationErrors.emit({
      field: `${this._eventTypeCheck}-${visibleControlsIndex}`,
      remove: true,
    });
  }

  getVisibleControlsIndex(index: number) {
    return this.eventsFormArray.value.findIndex((event) =>
      event.id && event.id > 0
        ? event.id === this.visibleControls[index].value.id
        : event.time_stamp === this.visibleControls[index].value.time_stamp
    );
  }

  getAllControlsIndex(index: number) {
    return this.eventsFormArray.value.findIndex((event) => event.id === index);
  }

  onShowRoute(fg: FormGroup<EventFormGroup>): void {
    const dealEvent = fg.getRawValue() as DealEvent;
    /* if (dealEvent.meta?.disbursement) {
      dealEvent.meta.disbursement = convertCurrencyToServerValue(
        dealEvent.meta?.disbursement,
        dealEvent.meta?.disbursement_currency?.fiat_multiplier
      );
    } */
    this.showRoute.emit(dealEvent);
  }

  onAddPoint(fg: FormGroup<EventFormGroup>, index: number) {
    this.addPoint.emit({
      dealEvent: fg.getRawValue() as DealEvent,
      index: this.getVisibleControlsIndex(index),
    });
  }

  /** use in estimator-editor */
  onAddPointToEvent(point: GeoCoordinates, index: number) {
    const finalPoint = isNull(point.latitude) && isNull(point.latitude) ? null : point;
    this.eventsFormArray.at(index)?.get('point')?.patchValue(finalPoint, { emitEvent: false });
    this.eventsFormArray.at(index)?.get('port')?.patchValue(null, { emitEvent: false });
    this.eventsFormArray.at(index)?.get('port_id')?.patchValue(null, { emitEvent: false });
    this.hasChanges.emit({ change: true, estimate: false });
  }

  onAddEvent(index: number): void {
    const newFg = EventsFormComponent.makeEventFormGroup(
      undefined,
      false,
      this.showCanals,
      this.defaultWeatherFactor,
      this.defaultCanals,
      this.defaultTermsId,
      this.neededWorkType,
      this.highPurityFuelNameFromFirstEvent
    );
    newFg.controls?.single_inner_event?.controls?.meta.controls.using_cranes_on_vessel?.setValue(
      this.defaultCranes
    );
    this.eventsFormArray.controls.splice(this.getVisibleControlsIndex(index) + 1, 0, newFg);
    this.fgValueChanges(newFg, index);
    // For initialization of first value in pairwise
    newFg.patchValue({});
    this.hasChanges.emit({ change: true });
  }

  setDisplayEvents(): void {
    this.eventsFormArray.clear();
    this._displayedEvents = this._events.filter(isPrimaryEvent);
    if (this._displayedEvents?.length) {
      this._displayedEvents.forEach((event, index, arr) => {
        this.highPurityFuelNameFromFirstEvent =
          (arr[0]?.meta?.high_purity_fuel_name as FuelTypes) || FuelTypes.LSMGO;
        const fg = EventsFormComponent.makeEventFormGroup(
          event,
          false,
          this.showCanals,
          this.defaultWeatherFactor,
          this.defaultCanals,
          this.defaultTermsId,
          this.neededWorkType,
          this.highPurityFuelNameFromFirstEvent
        );
        this.setValidationErrorsToWorkAmount(event, fg, index);
        this.setValidationErrorsToQuantity(event, fg, index);
        this.setValidationErrorsToDA(fg, index);
        this.setValidationErrorsToEventType(fg, index);
        this.fgValueChanges(fg, index);
        if (index === 0) {
          fg.controls.start_time.enable({ emitEvent: false });
        }
        if (fg.controls.single_inner_event) {
          fg.controls.single_inner_event.controls.meta.controls.amount.addValidators(
            this.createCargoValidator(
              fg.controls.single_inner_event.controls.cargo_id.value,
              fg.controls.single_inner_event.controls.type.value
            )
          );
        }
        // For initialization of first value in pairwise
        fg.patchValue({});
        this.eventsFormArray.push(fg);
      });
      this.updateStartTime();
      this.eventsFormReady.emit();
      this.getSummLDRateAndETbyCargoID();
    }
  }

  onEventTypeChange(
    event: DropdownChangeEvent,
    eventForm: FormGroup<EventFormGroup>,
    index: number,
    cargoId: number
  ): void {
    this.changePortFalse();
    this.currEditedEventId = eventForm.value.id;
    const type = event.value as DealEventType;
    if (type === DealEventType.EventTypeStop) {
      // load/discharge/bunker = single_inner.meta
      // pass/open/redel = stop = поле meta без вложений
      if (this.indexFirstOrLast(index)) {
        // если stop, то сбрасываем в первом и последнем
        eventForm.controls.meta.controls.extra_minutes_after.patchValue(defaultUserDuration);
        eventForm.controls.meta.controls.extra_minutes_before.patchValue(defaultUserDuration);
        eventForm.controls.meta.controls.disbursement.patchValue(0);
      } else {
        // простановка из single_inner_event.meta в event.meta
        const singleExtraBeforeMinutes =
          eventForm.controls.single_inner_event?.controls.meta.controls.extra_minutes_before;
        if (singleExtraBeforeMinutes?.value?.user) {
          eventForm.controls.meta.controls.extra_minutes_before.patchValue(
            singleExtraBeforeMinutes.value,
            { emitEvent: false }
          );
          singleExtraBeforeMinutes.reset(defaultUserDuration, { emitEvent: false });
        }

        const singleExtraMinutesAfter =
          eventForm.controls.single_inner_event?.controls.meta.controls.extra_minutes_after;
        if (singleExtraMinutesAfter?.value?.user) {
          eventForm.controls.meta.controls.extra_minutes_after.patchValue(
            singleExtraMinutesAfter.value,
            { emitEvent: false }
          );
          singleExtraMinutesAfter.reset(defaultUserDuration, { emitEvent: false });
        }
      }

      // простановка use_fuel_type из single_inner_event.meta в event.meta
      const useFuelType =
        eventForm.controls.single_inner_event?.controls.meta.controls.use_fuel_type;
      if (useFuelType) {
        eventForm?.controls.meta.controls.use_fuel_type.setValue(useFuelType.value);
      }

      eventForm.controls.single_inner_event?.reset();
      // повтор 1
      if (eventForm.controls.single_inner_event) {
        eventForm.controls.single_inner_event.controls.meta.controls.amount.clearValidators();
        eventForm.controls.single_inner_event.controls.meta.controls.amount.updateValueAndValidity({
          emitEvent: false,
        });
        this.changeValidationErrors.emit({
          field: `${this._quantityCheck}-${cargoId}`,
          remove: true,
        });
      }
      //
      this.editedType = true;
    } else {
      if (type === DealEventType.EventTypeBunker) {
        // повтор 2
        if (eventForm.controls.single_inner_event) {
          eventForm.controls.single_inner_event.controls.meta.controls.amount.clearValidators();
          this.changeValidationErrors.emit({
            field: `${this._quantityCheck}-${cargoId}`,
            remove: true,
          });
        }
        //
      }
      event.originalEvent.preventDefault();
      event.originalEvent.preventDefault();
      eventForm.controls.single_inner_event?.controls.type.patchValue(type);

      // простановка из event.meta в single_inner_event.meta
      const extraMinutesBefore = eventForm.controls.meta.controls.extra_minutes_before;
      if (extraMinutesBefore.value?.user) {
        eventForm.controls.single_inner_event?.controls.meta.controls.extra_minutes_before.patchValue(
          extraMinutesBefore.value,
          { emitEvent: false }
        );
        extraMinutesBefore.reset(defaultUserDuration, { emitEvent: false });
      }
      const extraMinutesAfter = eventForm.controls.meta.controls.extra_minutes_after;
      if (extraMinutesAfter.value?.user) {
        eventForm.controls.single_inner_event?.controls.meta.controls.extra_minutes_after.patchValue(
          extraMinutesAfter.value,
          { emitEvent: false }
        );
        extraMinutesAfter.reset(defaultUserDuration, { emitEvent: false });
      }

      // простановка use_fuel_type из event.meta в single_inner_event.meta
      const useFuelType = eventForm?.controls.meta.controls.use_fuel_type;
      if (useFuelType) {
        eventForm.controls.single_inner_event?.controls.meta.controls.use_fuel_type.setValue(
          useFuelType.value
        );
      }
    }
    eventForm.controls.type.patchValue(DealEventType.EventTypeStop);
    // set default using_cranes_on_vessel, this field use only in single_inner_event
    eventForm.controls?.single_inner_event?.controls?.meta.controls.using_cranes_on_vessel?.setValue(
      this.defaultCranes
    );
    if (!this.cargoes?.length) {
      this.createCargo.emit(eventForm);
    }
    if (type === DealEventType.EventTypeDischarge && this.cargoes.length === 1) {
      const firstCargoId = this.cargoes[0].id;
      const dropdownEvent: DropdownChangeEvent = {
        originalEvent: {} as Event,
        value: firstCargoId,
      };
      eventForm.controls.single_inner_event?.controls?.cargo_id.setValue(firstCargoId as number);
      this.onChangeCargoId(dropdownEvent, eventForm);
    }
    if (!isCargoEvent(eventForm.controls.single_inner_event?.getRawValue() as DealEvent)) {
      eventForm.controls.single_inner_event?.controls?.cargo_id.reset(null);
      eventForm.controls.single_inner_event?.controls.meta.controls.amount.clearValidators();
      this.clearValidatorsWorkAmount(eventForm);
      this.clearValidatorsQuantity(eventForm);
    }
    this.validateEventsForm();
  }

  changePresetInEvent(event: DropdownChangeEvent, string: PresetNames): void {
    this.changePreset.emit({ newValue: event.value.name, oldValue: string });
  }

  onChangeCargoId(event: DropdownChangeEvent, eventForm: FormGroup<EventFormGroup>): void {
    this.editedTypeFalse();
    this.changePortFalse();
    const id = event.value;
    if (id) {
      const cargo = this.cargoes.find((car) => car.id === id);
      if (eventForm.controls.single_inner_event && cargo) {
        eventForm.controls.single_inner_event.controls?.cargo_id.patchValue(id, {
          emitEvent: false,
        });
        const type = eventForm.controls.single_inner_event.controls.type.value;
        if (type) {
          const cargoEventTypes = [DealEventType.EventTypeLoad, DealEventType.EventTypeDischarge];
          if (cargoEventTypes.includes(type)) {
            eventForm.controls.single_inner_event.controls.meta.controls.amount.clearValidators();
            eventForm.controls.single_inner_event.controls.meta.controls.amount.addValidators(
              this.createCargoValidator(id, type)
            );
            this.validateEventsForm();
          }
        }
      }
    }
  }

  onChangeAmount(eventForm: FormGroup<EventFormGroup>): void {
    this.editedTypeFalse();
    this.changePortFalse();
    const formValue = eventForm.getRawValue();
    const cargo_id = formValue.single_inner_event?.cargo_id;
    const quantity = formValue.single_inner_event?.meta.amount;
    if (cargo_id && quantity) {
      const cargo = this.cargoes.find((c) => c.id === cargo_id);
      if (cargo /* && !cargo.quantity */) {
        this.changeCargo.emit({ cargo, quantity });
      }
    }
    this.validateEventsForm();
  }

  validateEventsForm(): void {
    this.eventsFormArray.controls.forEach((eventFg) => {
      eventFg.controls.single_inner_event?.controls.meta.controls.amount.updateValueAndValidity();
      eventFg.controls.single_inner_event?.controls.meta.controls.amount.markAsDirty();
    });
    this.eventsFormArray.updateValueAndValidity();
  }

  updateEventsBeforeSave(/* multiplication = true */ estimateFinances = true): DealEvent[] {
    if (!this.showCanals) {
      this.setWFForCanals();
    }
    const events = this.eventsFormArray.getRawValue();
    if (!events.length) {
      return [];
    }
    const oldEvents = cloneDeep(this.events);
    const newEvents: DealEvent[] = [];
    events.forEach((event) => {
      if (event.start_time) {
        const date = new Date(event.start_time);
        const result = moment.utc(date.toString().split('GMT')[0]);
        if (isLocalTimeSetting(this.timeSettings)) {
          if (this.timeSettings === UserTimeSettings.PortLocalTime) {
            const offset = getEventTimezoneOffset(event as DealEvent);
            result.add(-offset, 'hours');
          } else {
            result.add(-getLocalTimeZoneOffsetInHours(), 'hours');
          }
        }
        if (result) {
          event.start_time = result.toISOString().split('.')[0] + 'Z';
        }
      }
      if (!event.port) {
        event.port = null;
        event.port_id = null;
      }
      const oldEvent = oldEvents.find((old) => old.id === event.id);
      if (!oldEvent) {
        event.deal_id = this.dealId;
        event.type = DealEventType.EventTypeStop;
      }
      /* if (multiplication) {
         if (event?.meta?.disbursement) {
          event.meta.disbursement = convertCurrencyToServerValue(
            event?.meta?.disbursement,
            event.single_inner_event?.meta.disbursement_currency?.fiat_multiplier
          );
        }
      } */
      newEvents.push(event as DealEvent);
      if (!isCanalEvent(event as DealEvent)) {
        /* if (multiplication) {
           if (event.single_inner_event?.meta?.disbursement) {
            event.single_inner_event.meta.disbursement = convertCurrencyToServerValue(
              event.single_inner_event?.meta?.disbursement,
              event.single_inner_event.meta.disbursement_currency?.fiat_multiplier
            );
          }
        } */
        if (event.single_inner_event?.id && event.single_inner_event?.type) {
          const oldInnerEvent = oldEvents.find((old) => old.id === event.single_inner_event?.id);
          if (event.port) {
            event.single_inner_event.port = event.port;
            event.single_inner_event.port_id = event.port_id;
          }
          if (oldInnerEvent) {
            newEvents.push(event.single_inner_event as DealEvent);
          }
        } else if (event.single_inner_event?.type) {
          const newEvent = event.single_inner_event;
          if (event.port) {
            newEvent.port = event.port;
            newEvent.port_id = event.port_id;
          }
          newEvent.group_id = event.group_id;
          newEvent.deal_id = this.dealId;
          newEvent.port = event.port;
          newEvents.push(newEvent as DealEvent);
        }
      }
      if (!estimateFinances) {
        delete event.inner_events;
        delete event.single_inner_event;
      }
      delete event.meta.route_geojson;
    });
    return newEvents;
  }

  updateStartTime(): void {
    this.eventsFormArray.controls.forEach((form) => {
      const event = form.getRawValue();
      const inputEvent = this.events.find((e) => e.id === event.id);
      if (inputEvent?.start_time) {
        const eventDate = moment.utc(inputEvent.start_time);
        const portOffset = getEventTimezoneOffset(inputEvent as DealEvent);
        const date = this.formatDateWithTimeZone(
          eventDate ? eventDate.utc().format() : '',
          portOffset
        );
        if (date) {
          form.controls.start_time.patchValue(new Date(date), { emitEvent: false });
        }
      }
    });
  }

  showCanalEvent(event?: DealEvent): boolean {
    return !!(
      (event && isStopEvent(event)) ||
      (event?.type === DealEventType.EventTypeCanal &&
        (event.meta?.disbursement ||
          this.canalRequiredDA(event.meta?.current_canal_name || '') ||
          event.meta?.extra_minutes_after?.system ||
          event.meta?.extra_minutes_after?.user ||
          event.meta?.extra_minutes_before?.user ||
          event.meta?.extra_minutes_before?.system ||
          event.meta?.idle_minutes))
    );
  }

  getTypeControl(fg: FormGroup<EventFormGroup>): FormControl {
    if (fg.controls.single_inner_event?.controls.type.value) {
      return fg.controls.single_inner_event?.controls.type;
    }
    return fg.controls.type;
  }

  getCanalOrCargoMainControl(
    form: FormGroup<EventFormGroup>,
    controlKey: keyof EventFormGroup
  ): FormControl {
    const event = form.getRawValue();
    const control =
      event.type === DealEventType.EventTypeStop
        ? form?.controls?.single_inner_event?.controls[controlKey]
        : form.controls[controlKey];
    return (control as FormControl) || this.mockFormControl;
  }

  getExtraTimeControl(
    form: FormGroup<EventFormGroup>,
    controlKey: 'extra_minutes_before' | 'extra_minutes_after'
  ): FormControl {
    const dealEvent = form.getRawValue() as DealEvent;
    if (
      form.controls.single_inner_event &&
      isSecondaryEvent(dealEvent.single_inner_event as DealEvent)
    ) {
      return form.controls.single_inner_event.controls.meta?.controls[controlKey];
    }
    return form.controls.meta.controls[controlKey];
  }

  getUseFuelTypeControl(form: FormGroup<EventFormGroup>): FormControl {
    if (form.controls.single_inner_event?.controls.type?.value) {
      return form.controls.single_inner_event.controls.meta?.controls.use_fuel_type;
    }
    return form.controls.meta.controls.use_fuel_type;
  }

  onFuelTypeChange(event: string, form: FormGroup<EventFormGroup>): void {
    if (form.controls.single_inner_event?.controls.type?.value) {
      form.controls.single_inner_event.controls.meta?.controls.use_fuel_type.patchValue(event);
      return;
    }
    form.controls.meta.controls.use_fuel_type.patchValue(event);
    return;
  }

  getCanalOrCargoMetaControl(
    form: FormGroup<EventFormGroup>,
    controlKey: keyof EventMetaInfoFormGroup
  ): FormControl {
    const event = form.getRawValue();
    const control =
      event.type === DealEventType.EventTypeStop
        ? form?.controls?.single_inner_event?.controls?.meta?.controls[controlKey]
        : form.controls?.meta?.controls[controlKey];
    return (control as FormControl) || this.mockFormControl;
  }

  getPresetName(preset: Preset | null): string {
    if (preset?.name) {
      const result = preset.name.split(' ');
      return `${result[0].at(0) || ''} ${result[1] || ''} `;
    }
    return '';
  }

  /*   getFuelTypeName(form: FormGroup<EventFormGroup>): string | null {
    const fuelType = form.controls.single_inner_event?.controls.meta.controls.use_fuel_type.value;
    if (fuelType) {
      return fuelType;
    }
    return null;
  } */

  getTermsCoefficient(form: FormGroup<EventFormGroup>): number {
    const termsId = form.value.single_inner_event?.meta?.terms_id;
    if (termsId) {
      const terms = this.cargoRules.find((term) => term.id === termsId);
      if (terms) {
        return terms.coefficient || 0;
      }
    }
    return 0;
  }

  /*   onDragStart(index: number): void {
    this.draggableIndex = index;
  } */

  blurCheckbox(checkbox: Checkbox): void {
    this.editedTypeFalse();
    this.changePortFalse();
    checkbox.onBlur.emit();
  }

  replacePorts({ dragIndex, dropIndex }: TableRowReorderEvent): void {
    if (
      (dragIndex || dragIndex === 0) &&
      (dropIndex || dropIndex === 0) &&
      dragIndex !== dropIndex
    ) {
      const replacedEvent = this.eventsFormArray.controls.splice(dragIndex, 1);
      if (replacedEvent?.length) {
        this.eventsFormArray.controls.splice(dropIndex, 0, replacedEvent[0]);
        this.hasChanges.emit({ change: true, changePort: true });
      }
    }
  }

  /*   onDragEnd(dropIndex: number): void {
    if (this.draggableIndex) {
      const events: DealEvent[] = this.eventsFormArray.getRawValue() as DealEvent[];
      if (events.length) {
        const draggedEvent = events.splice(this.draggableIndex, 1)[0];
        if (draggedEvent) {
          events.splice(dropIndex, 0, draggedEvent);
          events.forEach((event, index) => {
            if (event.single_inner_event && event.single_inner_event.id) {
              event.single_inner_event.order = index + 1;
              index += 1;
              events.splice(index, 0, event.single_inner_event);
            }
            event.order = index + 1;
          });
          this.replaceEvents.emit(events);
        }
      }
    }
  } */

  getEcaDistance(form: FormGroup<EventFormGroup>): string {
    return `(${form.controls.meta.controls.distance.value || 0})`;
  }

  hideEventInput(form: FormGroup<EventFormGroup>, index: number): boolean {
    return !!(
      this.indexFirstOrLast(index) && !form.controls.single_inner_event?.controls?.type.value
    );
  }

  hideCargoInput(form: FormGroup<EventFormGroup> /* index: number */): boolean {
    return !form.controls.single_inner_event?.controls?.type.value;
  }

  hideCanalInput(form: FormGroup<EventFormGroup> /* index?: number */): boolean {
    return isCanalEvent(form.getRawValue() as DealEvent);
  }

  equalBunkerInput(form: FormGroup<EventFormGroup> /* index?: number */): boolean {
    return form.controls.single_inner_event?.controls.type.value === DealEventType.EventTypeBunker;
  }

  getRobsControl(
    formGroup: FormGroup<EventFormGroup>,
    index: number,
    key: 'residual_rob' | 'distillate_rob' | 'ulsfo_rob' | 'hsfo_rob'
  ): FormControl {
    if (index === 0) {
      const control = formGroup.controls.meta.controls[key];
      if (!this.isFixed) {
        control.enable();
      }
      return control;
    } else {
      if (formGroup.controls.single_inner_event?.controls?.id.value) {
        const control = formGroup.controls.single_inner_event.controls.meta.controls[key];
        control.disable({ emitEvent: false });
        return control;
      }
      const control = formGroup.controls.meta.controls[key];
      control.disable({ emitEvent: false });
      return control;
    }
  }

  formatDateWithTimeZone(date: string, timeZoneOffset?: number): string {
    return (
      this.datePipe.transform(
        moment.utc(date).toISOString(),
        DATE_INPUT_TIME_FORMAT,
        getDatePipeTimeZoneFormat(this.timeSettings, timeZoneOffset)
      ) || ''
    );
  }

  getEventTypePlaceholder(form: FormGroup<EventFormGroup>, index: number): string {
    let placeholder = 'Oper';
    if (
      index === this.eventsFormArray.length - 1 &&
      !form.controls.single_inner_event?.controls.type?.value
    ) {
      placeholder = 'Redel';
    }
    if (index === 0 && !form.controls.single_inner_event?.controls.type?.value) {
      placeholder = 'Open';
    }
    return placeholder;
  }

  getSailingMinutes(
    form: FormGroup<EventFormGroup>,
    key: 'sailing_minutes' | 'port_time'
    /* index: number */
  ): number {
    const duration = form.controls.meta.controls[key].value;
    let result = 0;
    if (duration) {
      result += (duration.is_changed ? duration.user : duration.system) || 0;
    }
    const needIndex = this.getAllControlsIndex(form.value.id as number);
    if (this.startConditionForGetSummData(form)) {
      for (let i = needIndex; i > 0; i--) {
        const prevFg = this.eventsFormArray.at(i - 1);
        const prevEvent = prevFg?.getRawValue() as DealEvent;
        if (this.breakConditionForGetSummData(prevEvent)) {
          break;
        }
        const prevDuration = prevFg.controls.meta.controls[key].value;
        if (this.continueConditionForGetSummData(prevEvent)) {
          result += getUserOrSystemDuration(prevDuration) || 0;
        }
      }
    }
    return result;
  }

  getDistance(
    form: FormGroup<EventFormGroup>,
    key: 'eca_distance' | 'distance'
    /*  index: number */
  ): number {
    const distance = form.controls.meta.controls[key].value;
    let result = 0;
    if (distance) {
      result += distance || 0;
    }
    const needIndex = this.getAllControlsIndex(form.value.id as number);
    if (this.startConditionForGetSummData(form)) {
      for (let i = needIndex; i > 0; i--) {
        const prevFg = this.eventsFormArray.at(i - 1);
        const prevEvent = prevFg?.getRawValue() as DealEvent;
        if (this.breakConditionForGetSummData(prevEvent)) {
          break;
        }
        const prevDistance = prevFg.controls.meta.controls[key].value;
        if (this.continueConditionForGetSummData(prevEvent)) {
          result += prevDistance || 0;
        }
      }
    }
    return result;
  }

  startConditionForGetSummData(form: FormGroup<EventFormGroup>): boolean {
    return !this.showCanals && this.showCanalEvent(form.getRawValue() as DealEvent);
  }

  breakConditionForGetSummData(prevEvent: DealEvent): boolean {
    return !isCanalEvent(prevEvent) || this.showCanalEvent(prevEvent);
  }

  continueConditionForGetSummData(prevEvent: DealEvent): boolean {
    return (
      isCanalEvent(prevEvent) &&
      !getUserOrSystemDuration(prevEvent.meta?.extra_minutes_after) &&
      !getUserOrSystemDuration(prevEvent.meta?.extra_minutes_before)
    );
  }

  getEventType(type: DealEventType, index: number): string {
    if (type === DealEventType.EventTypeStop) {
      if (index === 0) {
        return 'Open';
      }
      if (index === this.visibleControls.length - 1) {
        return 'Redel';
      }
      return 'Pass';
    }
    return type;
  }

  getColumnTabIndex(column: ColumnUI<EventTableColumnType>): number {
    return this.columns.findIndex((col) => col === column) + 101;
  }

  addTimeSettings(columnnHeader: string): string {
    const userTimeZone = this.timeSettings === UserTimeSettings.LocalTime ? this.userTimeZone : '';
    return columnnHeader === 'Arrival' ? this.timeSettings + userTimeZone : '';
  }

  createCargoValidator(cargo_id?: number | null, eventType?: DealEventType | null): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (cargo_id && eventType) {
        const needCargo = this.cargoes.find((cargo) => cargo.id === cargo_id);
        if (needCargo && this.eventsFormArray.length) {
          if (!needCargo?.quantity) {
            needCargo.quantity = 0;
          }
          const amountSum = this.eventsFormArray.controls.reduce((acc, eventFg) => {
            const cargoEvent = eventFg.controls.single_inner_event?.getRawValue();
            if (cargoEvent && cargoEvent.cargo_id === cargo_id && cargoEvent.type === eventType) {
              acc += cargoEvent.meta.amount || 0;
            }
            return acc;
          }, 0);
          const finalRes = +amountSum.toFixed(2) !== +needCargo.quantity.toFixed(2);
          if (finalRes) {
            this.changeValidationErrors.emit({
              field: `${this._quantityCheck}-${cargo_id}`,
            });
          } else {
            this.changeValidationErrors.emit({
              field: `${this._quantityCheck}-${cargo_id}`,
              remove: true,
            });
          }
          return finalRes ? { cargoError: true } : null;
        }
      }
      return null;
    };
  }

  editedTypeFalse(): void {
    this.editedType = false;
  }

  changePortFalse(): void {
    this.changePort = false;
  }

  fgValueChanges(fg: FormGroup<EventFormGroup>, index: number): void {
    fg.valueChanges
      .pipe(
        debounceTime(/* this.defaultAutoSave */ 100),
        takeUntil(this._onDestroy$),
        pairwise(),
        filter(([prev, curr]) => {
          this.prevEditedDealEventValue = prev as Partial<DealEvent>;
          this.currEditedDealEventValue = curr as Partial<DealEvent>;
          return !isString(curr.port) && !isEqual(curr, prev);
        }),
        map(([prev, curr]) => {
          console.log('event form emit changes', curr, changedKeys(prev, curr));
          return curr;
        })
      )
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((changedEvent) => {
        this.setValidationErrorsToWorkAmount(changedEvent as DealEvent, fg, index);
        this.setValidationErrorsToQuantity(changedEvent as DealEvent, fg, index);
        this.setValidationErrorsToDA(fg, index);
        this.setValidationErrorsToEventType(fg, index);
        const notChangePort = this.changePort
          ? this.prevEditedDealEventValue?.port?.id === this.currEditedDealEventValue?.port?.id &&
            this.prevEditedDealEventValue?.port_id === this.currEditedDealEventValue?.port_id &&
            !!this.currEditedDealEventValue?.port_id &&
            !!this.currEditedDealEventValue?.port?.id
          : true;
        const notChangeOper =
          this.prevEditedDealEventValue?.single_inner_event?.type ===
            this.currEditedDealEventValue?.single_inner_event?.type &&
          this.prevEditedDealEventValue?.type === this.currEditedDealEventValue?.type;
        const changeCoordinates =
          this.prevEditedDealEventValue?.point?.latitude !==
            this.currEditedDealEventValue?.point?.latitude ||
          this.prevEditedDealEventValue?.point?.longitude !==
            this.currEditedDealEventValue?.point?.longitude;
        const changeDA =
          this.prevEditedDealEventValue?.meta?.disbursement !==
          this.currEditedDealEventValue?.meta?.disbursement;
        if (
          (this.editedType && this.prevEditedDealEventValue?.id === this.currEditedEventId) ||
          !this.editedType
        ) {
          this.hasChanges.emit({
            change: true,
            estimate: notChangePort && notChangeOper && !changeCoordinates && !changeDA,
            estimateFinances: changeDA,
            changePort: !notChangePort,
          });
        }
        const innerEvent = changedEvent.single_inner_event;
        if (innerEvent && (innerEvent?.cargo_id || innerEvent.type)) {
          const oldInnerEvent = cloneDeep(this.events.find((ev) => ev?.id === innerEvent?.id));
          if (!oldInnerEvent) {
            // const shinc = this.cargoRules.find((rule) => rule.name === 'SHINC');
            // const meta = {
            //   terms_id: /* shinc ? shinc.id : 0 */ null,
            //   work_type: this.neededWorkType,
            //   weather_factor: this.defaultWeatherFactor || 0,
            //   use_eca: true,
            // };

            // Meta reassign triggers endless requests sequence VPLAN-641
            // innerEvent.meta = meta;

            changedEvent.single_inner_event = innerEvent;
            /* this.visibleControls
              ?.find((control) => control?.value?.id === changedEvent?.id)
              ?.controls?.single_inner_event?.controls?.meta?.controls?.terms_id?.setValue(
                shinc?.id as number
              ); */
          } else {
            if (innerEvent.start_time) {
              innerEvent.start_time =
                moment(innerEvent.start_time).toISOString().split('.')[0] + 'Z';
            }
          }
        }
        if (innerEvent) {
          changedEvent.single_inner_event = innerEvent;
        }

        this.getSummLDRateAndETbyCargoID();
      });
  }

  showWarningSize(eventForm: FormGroup<EventFormGroup>): boolean {
    const currCanalId = eventForm?.value.meta?.current_canal_id;
    const canalExistedInImpassableZones = !!this.impassableZones?.find(
      (canal) => canal?.id === currCanalId
    );
    return canalExistedInImpassableZones;
  }

  showPoint(eventForm: FormGroup<EventFormGroup>) {
    return isCanalEvent(eventForm.getRawValue() as DealEvent) ? false : pointExisted(eventForm);
  }

  disablePoint(eventForm: FormGroup<EventFormGroup>) {
    return !!eventForm.value.port_id;
  }

  getSelectedCargoOrderValue(order?: number): string {
    return order ? `${order}` : '';
  }

  deleteCargoId(cargoId: number): void {
    const events = this.eventsFormArray;
    events.controls.forEach((event) => {
      if (event.controls.single_inner_event?.controls.cargo_id.value === cargoId) {
        event.controls.single_inner_event.controls.cargo_id.setValue(0);
        event.controls.single_inner_event.controls.meta.controls.amount.setValue(0);
        event.controls.type.setValue(DealEventType.EventTypeStop);
      }
    });
  }

  onChangeWF(control: FormControl<number | null>): void {
    if (control.value === null) {
      control.patchValue(0);
    }
  }

  setWFForCanals(): void {
    let portWFValue: number | null = null;
    const events = [...this.eventsFormArray.controls].reverse();
    events.forEach((control) => {
      const eventFg = control.getRawValue();
      if (!isCanalEvent(eventFg as DealEvent)) {
        portWFValue = eventFg.meta.weather_factor;
      } else {
        // const canalEventFg = control.getRawValue() as DealEvent;
        const canalWFControl = control.controls.meta.controls.weather_factor;
        if (
          !getUserOrSystemDuration(eventFg.meta?.extra_minutes_before) &&
          !getUserOrSystemDuration(eventFg.meta?.extra_minutes_after) &&
          !this.canalRequiredDA(eventFg.meta?.current_canal_name || '')
        ) {
          if (canalWFControl.value !== portWFValue) {
            canalWFControl.patchValue(portWFValue);
            this.showNotificationChangeWFInCanal.emit();
          }
        }
      }
    });
  }

  changeFuelTypes(): void {
    this.eventsFormArray.controls.forEach((control) => {
      const useFuelTypeControl = this.getUseFuelTypeControl(control);
      if (this.scrubber && useFuelTypeControl?.value === FuelTypes.VLSFO) {
        useFuelTypeControl.patchValue(FuelTypes.HSFO);
      } else {
        if (useFuelTypeControl?.value === FuelTypes.HSFO) {
          useFuelTypeControl.patchValue(FuelTypes.VLSFO);
        }
      }
    });
  }

  getDAInvalidName(eventForm: FormGroup<EventFormGroup>, index: number): string {
    if (isCargoEvent(eventForm.controls.single_inner_event?.getRawValue() as DealEvent)) {
      return `da-${index}-${TEXT_ZERO}`;
    } else if (
      isCanalEvent(eventForm.getRawValue() as DealEvent) &&
      this.canalRequiredDA(eventForm.value.meta?.current_canal_name || '')
    ) {
      return `da-${index}`;
    }
    return '';
  }

  onChangeValidationErrors(object: ChangeValidation): void {
    this.changeValidationErrors.emit(object);
  }

  setValidationErrorsToWorkAmount(
    event: DealEvent,
    fg: FormGroup<EventFormGroup>,
    index: number
  ): void {
    if (isCargoEvent(event.single_inner_event as DealEvent) && event.single_inner_event?.cargo_id) {
      if (
        event.single_inner_event?.meta?.work_amount &&
        event.single_inner_event?.meta?.work_amount > 0
      ) {
        this.changeValidationErrors.emit({ field: `work-amount-${index}`, remove: true });
      } else {
        this.changeValidationErrors.emit({ field: `work-amount-${index}` });
      }
    } else {
      this.changeValidationErrors.emit({ field: `work-amount-${index}`, remove: true });
    }

    if (
      isCargoEvent(event.single_inner_event as DealEvent) &&
      fg.controls.single_inner_event?.controls.cargo_id.value
    ) {
      fg.controls.single_inner_event?.controls.meta.controls.work_amount.addAsyncValidators(
        ZeroNullValidator(/* true */)
      );
      fg.controls.single_inner_event?.controls.meta.controls.work_amount.updateValueAndValidity({
        emitEvent: false,
      });
    } else {
      this.clearValidatorsWorkAmount(fg);
    }
  }

  clearValidatorsWorkAmount(fg: FormGroup<EventFormGroup>): void {
    fg.controls.single_inner_event?.controls.meta.controls.work_amount.setAsyncValidators(null);
    fg.controls.single_inner_event?.controls.meta.controls.work_amount.updateValueAndValidity({
      emitEvent: false,
    });
  }

  setValidationErrorsToQuantity(
    event: DealEvent,
    fg: FormGroup<EventFormGroup>,
    index: number
  ): void {
    if (isCargoEvent(event.single_inner_event as DealEvent) && event.single_inner_event?.cargo_id) {
      if (event.single_inner_event?.meta?.amount && event.single_inner_event?.meta?.amount > 0) {
        this.changeValidationErrors.emit({ field: `amount-${index}`, remove: true });
      } else {
        this.changeValidationErrors.emit({ field: `amount-${index}` });
      }
    } else {
      this.changeValidationErrors.emit({ field: `amount-${index}`, remove: true });
    }

    if (
      isCargoEvent(event.single_inner_event as DealEvent) &&
      fg.controls.single_inner_event?.controls.cargo_id.value
    ) {
      fg.controls.single_inner_event?.controls.meta.controls.amount.addAsyncValidators(
        ZeroNullValidator(/* true */)
      );
      fg.controls.single_inner_event?.controls.meta.controls.amount.updateValueAndValidity({
        emitEvent: false,
      });
    } else {
      this.clearValidatorsQuantity(fg);
    }
  }

  clearValidatorsQuantity(fg: FormGroup<EventFormGroup>): void {
    fg.controls.single_inner_event?.controls.meta.controls.amount.setAsyncValidators(null);
    fg.controls.single_inner_event?.controls.meta.controls.amount.updateValueAndValidity({
      emitEvent: false,
    });
  }

  setValidationErrorsToDA(fg: FormGroup<EventFormGroup>, index: number) {
    const daValue = fg.controls.meta.controls.disbursement.value;
    if (
      isCargoEvent(fg.controls.single_inner_event?.getRawValue() as DealEvent) &&
      isNil(daValue)
    ) {
      this.changeValidationErrors.emit({ field: `da-${index}-${TEXT_ZERO}`, remove: true });
      this.changeValidationErrors.emit({ field: `da-${index}-${TEXT_ZERO}` });
    } else if (
      isCanalEvent(fg.getRawValue() as DealEvent) &&
      (daValue === 0 || isNil(daValue)) &&
      this.canalRequiredDA(fg.value.meta?.current_canal_name || '')
    ) {
      this.changeValidationErrors.emit({ field: `da-${index}`, remove: true });
      this.changeValidationErrors.emit({ field: `da-${index}` });
    } else {
      this.changeValidationErrors.emit({ field: `da-${index}-${TEXT_ZERO}`, remove: true });
      this.changeValidationErrors.emit({ field: `da-${index}`, remove: true });
    }
    return '';
  }

  setValidationErrorsToEventType(fg: FormGroup<EventFormGroup>, index: number): void {
    const cargoIdValue = fg.controls.single_inner_event?.controls.cargo_id.value;
    if (isCargoEvent(fg.controls.single_inner_event?.getRawValue() as DealEvent) && !cargoIdValue) {
      this.changeValidationErrors.emit({
        field: `${this._eventTypeCheck}-${index}`,
      });
      fg.controls.single_inner_event?.controls.cargo_id.setValidators(Validators.required);
      fg.controls.single_inner_event?.controls.cargo_id.updateValueAndValidity({
        emitEvent: false,
      });
    } else {
      this.changeValidationErrors.emit({
        field: `${this._eventTypeCheck}-${index}`,
        remove: true,
      });
      fg.controls.single_inner_event?.controls.cargo_id.setValidators(null);
      fg.controls.single_inner_event?.controls.cargo_id.updateValueAndValidity({
        emitEvent: false,
      });
    }
  }

  canalRequiredDA(canalName: string): boolean {
    return !!this.requiredCanalsDA?.includes(canalName);
  }

  indexFirstOrLast(index: number): boolean {
    return (
      index === 0 ||
      index === this.eventsFormArray.length - 1 ||
      index === this.visibleControls.length - 1
    );
  }

  textForPoint(eventForm: FormGroup<EventFormGroup>): string {
    return `${ROUTE_POINT_TEXT}${eventForm.controls.point.value?.latitude?.toFixed(
      2
    )}/${eventForm.controls.point.value?.longitude?.toFixed(2)}`;
  }

  getDAPlaceholder(eventForm: FormGroup<EventFormGroup>): string {
    if (this.showPoint(eventForm)) {
      return this.textForPoint(eventForm);
    } else {
      if (isObject(eventForm?.controls?.port?.value)) {
        return eventForm?.controls?.port?.value?.name || '';
      }
    }
    return '';
  }

  changeHighPurityFuelName(fuel: string): void {
    this.highPurityFuelNameFromFirstEvent = fuel as FuelTypes;
    this.eventsFormArray.controls.forEach((control) => {
      const highPurityFuelNameControlSingle =
        control.controls.single_inner_event?.controls.meta?.controls.high_purity_fuel_name;
      highPurityFuelNameControlSingle?.patchValue(fuel as FuelTypes);

      const highPurityFuelNameControl = control.controls.meta.controls.high_purity_fuel_name;
      highPurityFuelNameControl.patchValue(fuel as FuelTypes);
    });
  }

  getSummLDRateAndETbyCargoID(): void {
    const data: SummLDRateAndETbyCargoID[] = [];
    let ldRateValueInMinutesWithoutCargoID = 0;
    this.eventsFormArray.controls.forEach((control) => {
      const cargoId = control.controls.single_inner_event?.controls.cargo_id.value || 0;
      if (this.cargoesIdSet?.has(cargoId)) {
        const ldRateValue =
          control.controls.single_inner_event?.controls.meta.controls.work_amount.value;
        const ldRateType =
          control.controls.single_inner_event?.controls.meta.controls.work_type.value;
        const quantityValue =
          control.controls.single_inner_event?.controls.meta.controls.amount.value;
        const extraTimeValue = getUserOrSystemDuration(
          control.controls.single_inner_event?.controls.meta.controls.extra_minutes_after.value
        );
        let ldRateValueInMinutes = 0;
        if (ldRateType) {
          ldRateValueInMinutes = getTimeInMinutes(ldRateValue || 0, ldRateType, quantityValue || 0);
        }
        const existCargoId = data.find((item) => item.cargo_id === cargoId);
        if (existCargoId) {
          existCargoId.value += ldRateValueInMinutes + extraTimeValue;
        } else {
          data.push({ cargo_id: cargoId, value: ldRateValueInMinutes + extraTimeValue });
        }
      } else {
        // pass
        const extraTimeValueNoCargo = getUserOrSystemDuration(
          control?.controls.meta.controls.extra_minutes_after.value
        );
        // bunker/load/disc/
        const extraTimeValueNoCargoInSingles = getUserOrSystemDuration(
          control.controls.single_inner_event?.controls.meta.controls.extra_minutes_after.value
        );
        ldRateValueInMinutesWithoutCargoID +=
          extraTimeValueNoCargo + extraTimeValueNoCargoInSingles;
      }
    });
    this.setSummLDRateAndETbyCargoID.emit(data);
    this.setSummETwithoutCargoID.emit(ldRateValueInMinutesWithoutCargoID);
  }

  onOpenPortDisbursementsModal(form: FormGroup<EventFormGroup>): void {
    this.openPortDisbursementsModal.emit(form.getRawValue() as DealEvent);
  }

  setDAFromPortDisbursement(dealEvent: DealEvent, daAmount: number): void {
    const needRow = this.eventsFormArray.controls.filter(
      (control) => control.value.id === dealEvent.id
    )[0];
    needRow.controls.meta.controls.disbursement.patchValue(daAmount * DEFAULT_FIAT_MULTIPLIER);
  }

  portDaDisabled(form: FormGroup<EventFormGroup>): boolean {
    return !form.getRawValue().port_id /* ? true : !this.vesselDwt && !this.generalVesselID */;
  }
}
