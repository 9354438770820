<form class="password-restore-form" [formGroup]="paswordRestoreForm">
  <div class="p-fluid mb-5">
    <p-message
      *ngIf="restoreSuccess"
      [severity]="NotificationType.Success"
      text="Password has been updated, you can sign in"
    ></p-message>
    <p-message
      *ngIf="responseError"
      [severity]="NotificationType.Error"
      [text]="responseError"
    ></p-message>
  </div>
  <div class="flex-container-column gap-2">
    <div class="p-inputgroup">
      <button
        pButton
        type="button"
        [icon]="passwordIcon"
        (click)="hidePassword()"
        [ngStyle]="{
          'background-color': isPasswordControlInvalid
            ? PrimeNgColors.Error
            : PrimeNgColors.Primary,
          'border-color': isPasswordControlInvalid ? PrimeNgColors.Error : PrimeNgColors.Primary
        }"
      ></button>
      <input
        pInputText
        [type]="passwordInputType"
        id="password"
        name="password"
        placeholder="Password"
        #password
        formControlName="password"
        [ngClass]="{
          'ng-invalid ng-dirty': isPasswordControlInvalid
        }"
        required
      />
    </div>
    <small id="password-error" [style]="{ color: PrimeNgColors.Error }"
      >&nbsp;
      <span *ngIf="isPasswordControlInvalid">{{ passwordControlError }}</span>
    </small>
  </div>
  <div class="flex-container-column gap-2">
    <div class="p-inputgroup">
      <button
        pButton
        type="button"
        [icon]="confirmPasswordIcon"
        (click)="hideConfirmPassword()"
        [ngStyle]="{
          'background-color': isConfirmPasswordControlInvalid
            ? PrimeNgColors.Error
            : PrimeNgColors.Primary,
          'border-color': isConfirmPasswordControlInvalid
            ? PrimeNgColors.Error
            : PrimeNgColors.Primary
        }"
      ></button>
      <input
        pInputText
        [type]="confirmPasswordInputType"
        id="passwordConfirm"
        name="passwordConfirm"
        placeholder="Confirm password"
        #password
        formControlName="passwordConfirm"
        [ngClass]="{
          'ng-invalid ng-dirty': isConfirmPasswordControlInvalid
        }"
        required
      />
    </div>
    <small id="confirm-password-error" [style]="{ color: PrimeNgColors.Error }"
      >&nbsp;
      <span *ngIf="isConfirmPasswordControlInvalid">{{ confirmPasswordControlError }}</span>
    </small>
  </div>
  <section class="p-fluid mt-5">
    <p-button
      class="w-100"
      styleClass="signin-button"
      size="small"
      [label]="buttonLabel"
      [disabled]="paswordRestoreForm.invalid || isLoading"
      [loading]="isLoading"
      (click)="onPasswordRestore()"
    ></p-button>
  </section>
</form>
