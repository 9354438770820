<div class="h-full flex gap-3">
  <estimator-route-map
    class="shiplist-map"
    [selectedShiplistZoneType]="selectedShiplistZoneType"
    [showSettings]="false"
    [shiplistZones]="shiplistZones"
    [portsPoints]="portsPoints"
    (selectZone)="onSelectZone($event)"
    (mapReady)="onMapReady()"
    (showNotification)="onShowNotification()"
    #shiplistMap
  ></estimator-route-map>
  <div class="flex flex-column gap-2">
    <estimator-shiplist-zones-ports-filter
      class="shiplist-zones-ports-filter"
      [ports]="ports"
      [shiplistZonesArray]="shiplistZonesArray"
      (changeZoneType)="onChangeZoneType($event)"
      (searchPort)="onSearchPort($event)"
      (selectZone)="onSelectZone($event)"
      (requestSaveFilter)="onRequestSaveFilter()"
    ></estimator-shiplist-zones-ports-filter>
    <estimator-selected-values-list
      class="h-full flex flex-column overflow-hidden"
      [values]="sortFromZonesToPort(selectedValues)"
      (removeValue)="onRemoveValue($event)"
      ><span class="selected-values-list-label text-xl font-bold" *ngIf="selectedValues?.length">{{
        getLabelForSelectedValuesList()
      }}</span></estimator-selected-values-list
    >
  </div>
</div>
