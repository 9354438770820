import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseModel } from '@estimator/models';
import { BaseService } from '../base';

@Injectable({
  providedIn: 'root'
})
export class DimensionsService extends BaseService<BaseModel> {
  constructor(protected override readonly http: HttpClient) {
    super(http);
    this.setPath('/api/compendiums/dimensions');
  }
}
