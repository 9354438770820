import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  FleetVesselCollection,
  PrimeNgColors,
  PropsDeleteFleetVesselAndFleetVesselCollection,
  ZERO_STRING,
} from '@estimator/models';
import {
  ColDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ICellRendererParams,
} from 'ag-grid-community';
import { AgGridColorPickerCellComponent } from '../ag-grid-shared/ag-grid-color-picker-cell.component';
import { AgGridPickerEditorEditorComponent } from '../ag-grid-shared/ag-grid-color-picker-editor.component';
import { AgGridButtonGroupComponent } from '../ag-grid-shared/button-group.component';

@Component({
  selector: 'estimator-fleet-vessel-collection',
  templateUrl: './fleet-vessel-collection.component.html',
  styleUrls: ['./fleet-vessel-collection.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FleetVesselCollectionComponent {
  @Input()
  set collections(data: FleetVesselCollection[]) {
    this._collections = data;
  }
  get collections(): FleetVesselCollection[] {
    return this._collections;
  }
  @Input() isAddCompany = false;
  @Input() userCompanyId = 0;
  @Output() addCollection = new EventEmitter<FleetVesselCollection>();
  @Output() updateCollection = new EventEmitter<FleetVesselCollection>();
  @Output() deleteCollection = new EventEmitter<PropsDeleteFleetVesselAndFleetVesselCollection>();

  columnDefs: ColDef<FleetVesselCollection>[] = [
    {
      field: 'action',
      headerComponent: 'buttonRenderer',
      headerComponentParams: (params: any) => {
        const addCollection = 'Add collection';
        return {
          ...params,
          buttons: [
            {
              title: addCollection,
              tooltip: addCollection,
              icon: 'add',
              color: PrimeNgColors.White,
              click: () => this.onAddCollection(),
            },
          ],
        };
      },
      cellRenderer: 'buttonRenderer',
      cellRendererParams: (params: ICellRendererParams) => {
        const deleteCollection = 'Delete collection';
        return {
          ...params,
          buttons: [
            {
              title: deleteCollection,
              tooltip: deleteCollection,
              icon: 'delete',
              click: () => this.onDeleteCollection(params),
            },
          ],
        };
      },
      cellClass: 'center-vertical-aligned-cell',
      minWidth: 50,
      width: 50,
    },
    {
      field: 'name',
      headerName: 'Fleet',
      flex: 1,
      editable: true,
    },
    {
      field: 'color',
      headerName: 'Color',
      editable: true,
      cellEditor: 'colorPickerEditor',
      cellEditorParams: (params: any) => {
        return {
          ...params,
          closeEditor: this.closeEditor.bind(this),
        };
      },
      cellRenderer: 'colorPickerCellComponent',
      cellRendererParams: (params: ICellRendererParams) => {
        return {
          ...params,
          color: params.value,
        };
      },
    },
  ];
  gridOptions: GridOptions<FleetVesselCollection> = {
    rowModelType: 'clientSide',
    columnDefs: this.columnDefs,
    components: {
      buttonRenderer: AgGridButtonGroupComponent,
      colorPickerEditor: AgGridPickerEditorEditorComponent,
      colorPickerCellComponent: AgGridColorPickerCellComponent,
    },
    getRowId: (params) => {
      return params.data.id ? params.data.id.toString() : ZERO_STRING;
    },
    defaultColDef: {
      editable: false,
      resizable: false,
    },
    suppressContextMenu: true,
    suppressScrollOnNewData: true,
    singleClickEdit: true,
    rowSelection: 'single',
    animateRows: true,
    rowHeight: 35,
    headerHeight: 40,
    autoSizePadding: 0,
    onCellEditingStopped: (event) => {
      if (event.data) {
        if (
          !this._gridApi?.getEditingCells()?.length &&
          event.newValue &&
          event.newValue !== event.oldValue
        ) {
          if (event.data.name && event.data.color) {
            if (event.data.id) {
              this.updateCollection.emit(event.data);
            } else {
              this.addCollection.emit(event.data);
            }
          }
        }
      }
    },
  };

  private _collections: FleetVesselCollection[] = [];
  private _gridApi?: GridApi<FleetVesselCollection>;

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this._gridApi?.stopEditing(true);
    }
    if (event.key === 'Enter') {
      this._gridApi?.stopEditing();
    }
  }

  get rowData(): FleetVesselCollection[] {
    const collections: FleetVesselCollection[] = [];
    this._gridApi?.forEachNode((node) => {
      if (node.data) {
        collections.push(node.data);
      }
    });
    return collections;
  }

  onGridReady({ api }: GridReadyEvent): void {
    this._gridApi = api;
  }

  onAddCollection(): void {
    const newCollection: FleetVesselCollection = {
      id: 0,
      color: '',
      name: '',
      for_company: this.isAddCompany,
    };
    if (this.isAddCompany) {
      newCollection.company_id = this.userCompanyId;
    }
    this._gridApi?.applyTransaction({ add: [newCollection] });
  }

  onDeleteCollection(event: ICellRendererParams<FleetVesselCollection>): void {
    const collection = event.data;
    if (collection) {
      if (collection.id) {
        this.deleteCollection.emit({
          id: collection.id,
          isDeleteCompanyItem: !!collection.company_id,
        });
      } else {
        this._gridApi?.applyTransaction({ remove: [event.data as FleetVesselCollection] });
      }
    }
  }

  closeEditor(): void {
    if (this._gridApi) {
      this._gridApi.stopEditing();
    }
  }
}
