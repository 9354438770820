import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { RippleModule } from 'primeng/ripple';
import { TooltipModule } from 'primeng/tooltip';
import { FormDateSwitcherModule } from '../form-date-switcher/form-date-switcher.module';
import { ShipListFilterComponent } from './ship-list-filter.component';

export const SHIPLIST_ZONES_SHIP_LIST_FILTER_IMPORTS = [
  CommonModule,
  ReactiveFormsModule,
  AutoCompleteModule,
  InputTextModule,
  ButtonModule,
  /*   InputSwitchModule, */
  CalendarModule,
  InputNumberModule,
  MultiSelectModule,
  TooltipModule,
  RippleModule,
  FormDateSwitcherModule,
];

@NgModule({
  declarations: [ShipListFilterComponent],
  imports: SHIPLIST_ZONES_SHIP_LIST_FILTER_IMPORTS,
  exports: [ShipListFilterComponent],
})
export class ShipListFilterModule {}
