import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Port } from '@estimator/models';
import { Observable } from 'rxjs';
import { BaseService } from '../base';

@Injectable({
  providedIn: 'root',
})
export class PortService extends BaseService<Port> {
  constructor(protected override readonly http: HttpClient) {
    super(http);
    this.setPath('/api/ports');
  }
  searchPort(value: string): Observable<Port[]> {
    return this.http.get<Port[]>(`${this.path}/find`, { params: { value } });
  }
}
