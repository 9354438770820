import { FormControl, FormGroup } from '@angular/forms';
import { BaseEntity, BaseEntityFormGroup, BaseModel } from './base-entity';
import { CargoCalculator, LoadPortDraftRestrictionUnitsType, StowageFactorUnitsType } from './cargo-calculator';
import { Company } from './company';
import { Currency } from './currency';
import { UserInputDuration, UserInputDurationType } from './duration';
import { SeasonType } from './seasons';
import { ColumnUI, GrainCalculationType, PrimeNgColors, SimpleButtonSwitch } from './ui-items';
import { WaterType } from './water';

export enum LaytimeType {
  Reversible = 'rev',
  NonReversible = 'nonrev',
  Off = 'off',
}

export interface Cargo extends BaseEntity {
  /**
   * @Deprecated AddressCommission logic integrated in VoyageCommission
   */
  address_commission?: number;
  /**
   * @Deprecated BrokerCommission logic integrated in VoyageCommission
   */
  broker_commission?: number;
  cargo_calculator?: CargoCalculator;
  cargo_calculator_id?: number;
  cargo_type?: BaseModel;
  cargo_type_id?: number;
  company?: Company | null;
  company_id?: number | null;
  currency?: Currency;
  currency_id?: number;
  deal_id?: number;
  dimension?: BaseModel;
  dimension_id?: number;
  freight_lumpsum?: number;
  freight_type?: CargoFreightType | null;
  freight_type_id?: number | null;
  quantity?: number;
  amount_type?: CargoTableFreightType;
  ws_percent?: number;
  internal_order?: number;
  lumpsum?: number;
  unique_row_id?: string;
  price?: number;
  voyage_commission?: number;
  laytime_type?: LaytimeType;
  show_intake?: boolean;
  /**
   * demurrage tanker field
   */
  allowed_time?: UserInputDuration;
  /**
   * demurrage tanker field
   */
  demurrage_rate?: number;
  /**
   * demurrage tanker field
   */
  // extra_demurrage_time?: UserInputDuration;
  description?: string;
}

export interface CargoForm extends BaseEntityFormGroup {
  cargo_calculator: FormGroup<CargoCalculatorForm>;
  cargo_type: FormControl<BaseModel | null>;
  cargo_type_id: FormControl<number | null>;
  currency: FormControl<Currency | null>;
  currency_id: FormControl<number | null>;
  company: FormControl<Company | null>;
  company_id: FormControl<number | null>;
  deal_id: FormControl<number | null>;
  dimension: FormControl<BaseModel | null>;
  dimension_id: FormControl<number | null>;
  freight_lumpsum: FormControl<number | null>;
  lumpsum: FormControl<number | null>;
  freight_type: FormControl<CargoFreightType | null>;
  freight_type_id: FormControl<number | null>;
  quantity: FormControl<number | null>;
  amount_type: FormControl<CargoTableFreightType | null>;
  ws_percent: FormControl<number | null>;
  internal_order: FormControl<number | null>;
  price: FormControl<number | null>;
  voyage_commission: FormControl<number | null>;
  laytime_type: FormControl<LaytimeType | null>;
  show_intake: FormControl<boolean | null>;
  /**
   * demurrage tanker field
   */
  allowed_time: FormControl<UserInputDuration | null>;
  /**
   * demurrage tanker field
   */
  demurrage_rate: FormControl<number | null>;
  /**
   * demurrage tanker field
   */
  // extra_demurrage_time: FormControl<UserInputDuration | null>;
  /**
   * cargo description field
   */
  description: FormControl<string | null>;
}

export interface CargoFreightType extends BaseModel {
  weight_higher?: number;
  weight_lower?: number;
  is_liquid?: boolean;
}

export interface CargoRules extends BaseModel {
  is_sh_ex?: boolean;
  is_sh_inc?: boolean;
  not_working_on_holidays?: boolean;
  not_working_time_from?: string;
  not_working_time_to?: string;
  not_working_week_day_from?: number;
  not_working_week_day_to?: number;
  coefficient?: number;
  company_id?: number;
  for_company?: boolean;
  is_default?: boolean;
  description?: string;
  order?: number;
}

export enum CargoTableColumnType {
  Order = 'internal_order',
  FreightType = 'freight_type',
  Price = 'price',
  Lumpsum = 'lumpsum',
  // BreakEven = 'break_even',
  VoyageComission = 'voyage_comission',
  Company = 'company',
  Quantity = 'quantity',
  AmountType = 'type',
  WsPercent = 'ws_percent',
  Demurrage = 'demurrage',
  Intake = 'intake',
  Description = 'description',
}

export const CARGO_TABLE_COLUMNS: ColumnUI<CargoTableColumnType>[] = [
  // { field: CargoTableColumnType.Order, header: '#' },
  { field: CargoTableColumnType.FreightType, header: 'Cargo' /* width: '4rem' */ },
  { field: CargoTableColumnType.Quantity, header: 'Quantity' /* width: '4rem' */ },
  {
    field: CargoTableColumnType.AmountType,
    header: 'Type',
    tooltip: 'WS = Worldscale, FR = Freight Rate, LS = Lumpsum',
    width: '1rem',
  },
  { field: CargoTableColumnType.Price, header: 'Frt rate' },
  /*  {
    field: CargoTableColumnType.BreakEven,
    header: 'B/E',
    tooltip: 'Break-even',
    width: '40px',
  }, */
  { field: CargoTableColumnType.WsPercent, header: 'WS, %' },
  { field: CargoTableColumnType.VoyageComission, header: 'VC %' },
  {
    field: CargoTableColumnType.Lumpsum,
    header: 'Frt LS',
    /*   width: '4rem', */
    tooltip: 'Total freight: Freight rate Х  Quantity + Lumpsum',
  },
  { field: CargoTableColumnType.Company, header: 'Charterer' },
  { field: CargoTableColumnType.Demurrage, header: 'Demurrage' },
  { field: CargoTableColumnType.Intake, header: 'O' },
  { field: CargoTableColumnType.Description, header: 'Description' },
];

export enum CargoTableFreightType {
  AmountTypeWS = 'amount_ws', //   'Worldscale'
  AmountTypeLumpSum = 'amount_lumpsum', //   'Lumpsum'
  AmountTypeRate = 'amount_rate', //   'Freight Rate'
}

export enum CargoTableFreightTypeALias {
  Worldscale = 'Worldscale',
  Lumpsum = 'Lumpsum',
  FreightRate = 'Freight Rate',
}

export enum CargoTableFreightTypeShortAlias {
  Worldscale = 'WS',
  Lumpsum = 'LS',
  FreightRate = 'FR',
}

export interface AmountType {
  value: string;
  color: string;
  short_name: string;
  name: string;
}

export const cargoesFormTypesMock: AmountType[] = [
  {
    name: CargoTableFreightTypeALias.Worldscale,
    value: CargoTableFreightType.AmountTypeWS,
    short_name: CargoTableFreightTypeShortAlias.Worldscale,
    color: 'var(--orange)',
  },
  {
    name: CargoTableFreightTypeALias.FreightRate,
    value: CargoTableFreightType.AmountTypeRate,
    short_name: CargoTableFreightTypeShortAlias.FreightRate,
    color: 'var(--blue)',
  },
  {
    name: CargoTableFreightTypeALias.Lumpsum,
    value: CargoTableFreightType.AmountTypeLumpSum,
    short_name: CargoTableFreightTypeShortAlias.Lumpsum,
    color: 'var(--lightgreen)',
  },
];

export interface CargoCalculatorForm extends BaseEntityFormGroup {
  bunker_weight: FormControl<number | null>;
  constant_weight: FormControl<number | null>;
  consumption_days: FormControl<number | null>;
  consumption_per_day: FormControl<number | null>;
  discharge_port_draft_restriction: FormControl<number | null>;
  dwt: FormControl<number | null>;
  fresh_water: FormControl<number | null>;
  grain_volume: FormControl<number | null>;
  bale_volume: FormControl<number | null>;
  grain_volume_ft: FormControl<number | null>;
  bale_volume_ft: FormControl<number | null>;
  load_port_draft_restriction: FormControl<number | null>;
  load_port_draft_restriction_ft: FormControl<number | null>;
  load_port_draft_restriction_units_type: FormControl<LoadPortDraftRestrictionUnitsType | null>;
  load_port_draft_start: FormControl<number | null>;
  max_available_tonnage_quantity: FormControl<number | null>;
  max_available_tonnage_discharge_quantity: FormControl<number | null>;
  max_discharge_port_draft_quantity: FormControl<number | null>;
  max_dwt_quantity: FormControl<number | null>;
  max_load_port_quantity: FormControl<number | null>;
  max_grain_volume_quantity: FormControl<number | null>;
  max_bale_volume_quantity: FormControl<number | null>;
  other_weight: FormControl<number | null>;
  permanent_ballast: FormControl<number | null>;
  stowage_factor: FormControl<number | null>;
  stowage_factor_ft: FormControl<number | null>;
  stowage_factor_units_type: FormControl<StowageFactorUnitsType | null>;
  total_consumption: FormControl<number | null>;
  tpc: FormControl<number | null>;
  vessel_max_draft: FormControl<number | null>;
  discharge_port_water_type: FormControl<WaterType | null>;
  load_port_water_type: FormControl<WaterType | null>;
  discharge_port_season_type: FormControl<SeasonType | null>;
  load_port_season_type: FormControl<SeasonType | null>;
  summ_max_fields: FormControl<number | null>; // 4 поля => max_dwt_quantity / max_grain_volume_quantity / max_bale_volume_quantity / max_available_tonnage_quantity
  deductibles: FormControl<number | null>;
  grain_bale_calculation_type: FormControl<GrainCalculationType | null>;
}

export const StowageFactorUnitsTypes: SimpleButtonSwitch[] = [
  {
    value: StowageFactorUnitsType.StowageFactorCBM,
    color: PrimeNgColors.BlueColor,
    short_name: StowageFactorUnitsType.StowageFactorCBM.toUpperCase(),
  },
  {
    value: StowageFactorUnitsType.StowageFactorCFT,
    color: PrimeNgColors.BlueColor,
    short_name: StowageFactorUnitsType.StowageFactorCFT.toUpperCase(),
  },
];

export interface SummLDRateAndETbyCargoID {
  cargo_id: number;
  value: number;
}

export interface ChangeAllowedTime {
  cargoId: number;
  allowTime: number;
  allowTimeType: UserInputDurationType;
}

export const LoadPortDraftRestrictionUnitsTypes: SimpleButtonSwitch[] = [
  {
    value: LoadPortDraftRestrictionUnitsType.Meters,
    color: PrimeNgColors.BlueColor,
    short_name: LoadPortDraftRestrictionUnitsType.Meters.toUpperCase(),
  },
  {
    value: LoadPortDraftRestrictionUnitsType.Feets,
    color: PrimeNgColors.BlueColor,
    short_name: LoadPortDraftRestrictionUnitsType.Feets.toUpperCase(),
  },
];
