import { Deal, Vessel } from '@estimator/models';

export class GetAllVessels {
  static readonly type = '[VESSEL] Get all';
}

export class SearchVessels {
  static readonly type = '[VESSEL] Search vessel';
  constructor(public request: string) {}
}

export class SearchRawVessels {
  static readonly type = '[VESSEL] Search raw vessel';
  constructor(public request: string) {}
}

export class SearchDeepVessels {
  static readonly type = '[VESSEL] Search deep vessel';
  constructor(public request: string) {}
}

export class UpdateVesselFromBase {
  static readonly type = '[VESSEL] Update vessel from base';
  constructor(public vesselId: number, public updDeal: Deal) {}
}

export class GetVessel {
  static readonly type = '[VESSEL] Get vessel';
  constructor(public id: number) {}
}

export class GetRawVesselOverview {
  static readonly type = '[VESSEL] Get raw vessel overview';
  constructor(public id: number) {}
}

export class CreateVessel {
  static readonly type = '[VESSEL] Create vessel';
  constructor(public vessel: Vessel, public updDeal?: Deal) {}
}

export class UpdateVessel {
  static readonly type = '[VESSEL] Update vessel';
  constructor(public vessel: Vessel, public isNeedToUpdateDeal?: boolean) {}
}

export class DeleteVessel {
  static readonly type = '[VESSEL] Delete vessel';
  constructor(public id: number) {}
}

export class AddVesselToState {
  static readonly type = '[VESSEL] Add vessel to state';
  constructor(public vessel: Vessel) {}
}

export class SearchVesselsAndPorts {
  static readonly type = '[VESSEL] Search raw vessels and ports';
  constructor(public request: string) {}
}
