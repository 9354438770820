import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TooltipModule } from 'primeng/tooltip';
import { ButtonSwitchComponent } from './button-switch.component';

export const BUTTON_SWITCH_MODULE_IMPORTS = [CommonModule, TooltipModule];
@NgModule({
  declarations: [ButtonSwitchComponent],
  imports: BUTTON_SWITCH_MODULE_IMPORTS,
  exports: [ButtonSwitchComponent],
})
export class ButtonSwitchModule {}
