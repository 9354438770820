import { AfterViewInit, Component, HostListener, ViewChild, ViewContainerRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MaterialThemeColor, Port, STRING } from '@estimator/models';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { debounceTime } from 'rxjs';

@Component({
  selector: 'estimator-port-autocomplete',
  template: `
    <input
      type="text"
      [placeholder]="placeholder"
      aria-label="Number"
      matInput
      [formControl]="formControl"
      [matAutocomplete]="portAutoComplete"
      #autoCompleteInput
      class="port-editor"
    />
    <mat-icon *ngIf="isLoading" matSuffix class="ag-grid-ac-loader">
      <mat-spinner [color]="MaterialThemeColor.Primary" [diameter]="20"></mat-spinner>
    </mat-icon>
    <mat-error>Error: Port name is required</mat-error>
    <mat-autocomplete #portAutoComplete="matAutocomplete" class="port-autocomplete">
      <mat-option
        *ngFor="let port of ports"
        [value]="port"
        (onSelectionChange)="onOptionSelect(port)"
      >
        <div class="option-wrapper">
          <div class="option-row">
            <span class="mr-2 fi fi-{{ port.alpha_2?.toLowerCase() }}"></span>
            <div class="port-name">
              <b>{{ port?.name }}</b>
            </div>
          </div>
          <p>{{ port?.code }}</p>
        </div>
      </mat-option>
      <ng-container *ngIf="ports.length < 1 && !isLoading">
        <mat-option [disabled]="true">
          <p>No ports found</p>
        </mat-option>
      </ng-container>
    </mat-autocomplete>
  `,
  styles: [
    `
      .ag-grid-ac-loader {
        margin-left: -24px;
      }

      .cdk-overlay-pane {
        min-width: 0;
      }
      .port-editor {
        width: 100%;
        height: 100%;
        border: none;
        background: none;
        .mat-mdc-option {
          font-size: 12px;
          line-height: 5px !important;
          height: 68px !important;
          padding: 0px 5px !important;
          .option-wrapper {
            width: 100%;
            height: 100%;
            .option-row {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              margin: 0;
              .port-name {
                width: 100%;
                height: auto;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }
          }
        }
      }
    `,
  ],
})
export class AgGridPortAutocompleteComponent implements AfterViewInit, ICellEditorAngularComp {
  @ViewChild('autoCompleteInput', { read: ViewContainerRef }) input!: ViewContainerRef;
  value!: any;
  ports: Port[] = [];
  visibleKeys: string[] = [];
  formControl = new FormControl();
  filter?: (req: string) => any;
  isLoading = false;
  placeholder = 'Port';
  MaterialThemeColor = MaterialThemeColor;
  private _params: any;

  @HostListener('click', ['$event'])
  stopPropagation(event: MouseEvent): void {
    event.stopImmediatePropagation();
  }

  ngAfterViewInit() {
    window.setTimeout(() => {
      this.input.element.nativeElement.select();
    });
  }

  agInit(params: any): void {
    this._params = params;
    if (params.dataArray) {
      this.ports = params.ports;
    }
    if (params.value) {
      this.value = params.value;
      this.formControl.patchValue(params.value.name);
    }
    if (params.filter) {
      this.filter = params.filter;
    }
    if (params.closeEditor) {
      this.closeEditor = params.closeEditor;
    }
    this.formControl.valueChanges.pipe(debounceTime(500)).subscribe((res) => {
      if (typeof res === STRING && res) {
        if (this.filter) {
          this.filter(res);
        }
      }
    });
  }

  onOptionSelect(value: Port): void {
    this.value = value;
    this.formControl.patchValue(value.name);
    this.closeEditor();
  }

  getValue(): any {
    return this.value;
  }

  compareObjects(o1: any, o2: any): boolean {
    return o1?.id === o2?.id;
  }

  closeEditor(): void {
    return;
  }
}
