<form class="signup-form" [formGroup]="signUpFormGroup">
  <section class="inputs-section">
    <div class="flex-container-column gap-2">
      <div class="p-inputgroup">
        <span
          class="p-inputgroup-addon"
          [ngStyle]="{
            'background-color': isFirstNameControlInvalid
              ? PrimeNgColors.Error
              : PrimeNgColors.Primary,
            'border-color': isFirstNameControlInvalid ? PrimeNgColors.Error : PrimeNgColors.Primary
          }"
        >
          <i [class]="PrimeNgIcons.USER" [style]="{ color: PrimeNgColors.White }"></i>
        </span>
        <input
          pInputText
          id="first_name"
          type="text"
          name="first_name"
          #firstname
          [placeholder]="DEFAULT_FIRST_NAME"
          formControlName="first_name"
          required
        />
      </div>
      <small
        id="first-name-error"
        [style]="{ color: PrimeNgColors.Error }"
        class="flex-container-row"
        >&nbsp;
        <span *ngIf="isFirstNameControlInvalid">{{ firstNameControlError }}</span>
      </small>
    </div>

    <div class="flex-container-column gap-2">
      <div class="p-inputgroup">
        <span
          class="p-inputgroup-addon"
          [ngStyle]="{
            'background-color': isLastNameControlInvalid
              ? PrimeNgColors.Error
              : PrimeNgColors.Primary,
            'border-color': isLastNameControlInvalid ? PrimeNgColors.Error : PrimeNgColors.Primary
          }"
        >
          <i [class]="PrimeNgIcons.USER" [style]="{ color: PrimeNgColors.White }"></i>
        </span>
        <input
          pInputText
          id="last_name"
          type="text"
          name="last_name"
          #lastname
          [placeholder]="DEFAULT_LAST_NAME"
          formControlName="last_name"
          required
        />
      </div>
      <small
        id="last-name-error"
        [style]="{ color: PrimeNgColors.Error }"
        class="flex-container-row"
        >&nbsp;
        <span *ngIf="isLastNameControlInvalid">{{ lastNameControlError }}</span>
      </small>
    </div>

    <div class="flex-container-column gap-2">
      <div class="p-inputgroup">
        <span
          class="p-inputgroup-addon"
          [ngStyle]="{
            'background-color': isEmailControlInvalid ? PrimeNgColors.Error : PrimeNgColors.Primary,
            'border-color': isEmailControlInvalid ? PrimeNgColors.Error : PrimeNgColors.Primary
          }"
        >
          <i [class]="PrimeNgIcons.AT" [style]="{ color: PrimeNgColors.White }"></i>
        </span>
        <input
          pInputText
          id="email"
          type="email"
          name="email"
          #email
          [placeholder]="DEFAULT_USER"
          formControlName="email"
          [ngClass]="{
            'ng-invalid ng-dirty': isEmailControlInvalid
          }"
          required
        />
      </div>
      <small id="email-error" [style]="{ color: PrimeNgColors.Error }" class="flex-container-row"
        >&nbsp;
        <span *ngIf="isEmailControlInvalid">{{ emailControlError }}</span>
      </small>
    </div>
    <div class="flex-container-column gap-2">
      <div class="p-inputgroup">
        <button
          pButton
          type="button"
          [icon]="passwordIcon"
          (click)="hidePassword()"
          [ngStyle]="{
            'background-color': isPasswordControlInvalid
              ? PrimeNgColors.Error
              : PrimeNgColors.Primary,
            'border-color': isPasswordControlInvalid ? PrimeNgColors.Error : PrimeNgColors.Primary
          }"
        ></button>
        <input
          pInputText
          [type]="passwordInputType"
          id="password"
          name="password"
          placeholder="Password"
          #password
          formControlName="password"
          [ngClass]="{
            'ng-invalid ng-dirty': isPasswordControlInvalid
          }"
          required
        />
      </div>
      <small id="password-error" [style]="{ color: PrimeNgColors.Error }" class="flex-container-row"
        >&nbsp;
        <span *ngIf="isPasswordControlInvalid">{{ passwordControlError }}</span>
      </small>
    </div>
    <div class="flex-container-column gap-2">
      <div class="p-inputgroup">
        <button
          pButton
          type="button"
          [icon]="confirmPasswordIcon"
          (click)="hideConfirmPassword()"
          [ngStyle]="{
            'background-color': isConfirmPasswordControlInvalid
              ? PrimeNgColors.Error
              : PrimeNgColors.Primary,
            'border-color': isConfirmPasswordControlInvalid
              ? PrimeNgColors.Error
              : PrimeNgColors.Primary
          }"
        ></button>
        <input
          pInputText
          [type]="confirmPasswordInputType"
          id="passwordConfirm"
          name="passwordConfirm"
          placeholder="Confirm password"
          #confirmpassword
          formControlName="passwordConfirm"
          [ngClass]="{
            'ng-invalid ng-dirty': isConfirmPasswordControlInvalid
          }"
          required
        />
      </div>
      <small
        id="confirm-password-error"
        [style]="{ color: PrimeNgColors.Error }"
        class="flex-container-row"
        >&nbsp;
        <span *ngIf="isConfirmPasswordControlInvalid">{{ confirmPasswordControlError }}</span>
      </small>
    </div>
    <div class="flex-container-column gap-2">
      <div class="p-inputgroup">
        <span
          class="p-inputgroup-addon"
          [ngStyle]="{
            'background-color': isCompanyControlInvalid
              ? PrimeNgColors.Error
              : PrimeNgColors.Primary,
            'border-color': isCompanyControlInvalid ? PrimeNgColors.Error : PrimeNgColors.Primary
          }"
        >
          <i class="pi pi-briefcase" [style]="{ color: PrimeNgColors.White }"></i>
        </span>
        <input
          pInputText
          type="text"
          id="company_name"
          name="company_name"
          placeholder="Company"
          #company
          formControlName="company_name"
          [ngClass]="{
            'ng-invalid ng-dirty': isCompanyControlInvalid
          }"
          required
        />
      </div>
      <small id="company-error" [style]="{ color: PrimeNgColors.Error }" class="flex-container-row"
        >&nbsp;
        <span *ngIf="isCompanyControlInvalid">{{ companyControlError }}</span>
      </small>
    </div>
    <span>Select which product you want to use:</span>
    <p-card
      *ngFor="let sub of subscriptionModels"
      (click)="setSubControl(sub)"
      styleClass="w-100"
      class="cursor-pointer"
    >
      <div class="flex-container-row gap-2">
        <p-radioButton
          [inputId]="sub.name"
          [value]="sub.id"
          formControlName="subscription_model_id"
        ></p-radioButton>
        <div class="flex-container-column justify-content-center">
          <span>{{ sub.name }}</span>
          <!-- <s
            >Price:
            {{
              convertCurrencyToUiValue(sub.price, sub.currency?.fiat_multiplier)
                | currency : sub.currency?.currency_code || 'USD'
            }}</s
          >
          <span>Now: Free</span> -->
        </div>
      </div>
    </p-card>
  </section>
  <section class="flex-container-row items-center gap-2 content-space-between margin-top-10">
    <div class="flex-container-row items-center gap-2">
      <p-checkbox
        [binary]="true"
        (onChange)="detectChanges()"
        formControlName="rulesAgreed"
        inputId="rulesAgreed"
      ></p-checkbox>
      <label for="rulesAgreed">
        I accept XMar platform's
        <a href="https://xmar.com/terms" target="_blank" [style]="{ color: PrimeNgColors.Primary }"
          >Terms and Conditions</a
        ></label
      >
    </div>
  </section>
  <section class="p-fluid margin-top-10">
    <p-button
      class="w-100"
      styleClass="signin-button"
      size="small"
      type="button"
      [label]="SIGN_UP"
      (click)="onSignUp()"
      [disabled]="!getControl('rulesAgreed').value || isLoading || signUpFormGroup.invalid"
      [loading]="isLoading"
    ></p-button>
  </section>
</form>
