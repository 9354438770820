import { Directive, HostListener, Input } from '@angular/core';
import { EVENTS_FORM_GROUP_PORT, ID_USER_SETTINGS_FORM_SHOW_CANALS } from '@estimator/models';

@Directive({
  selector: '[estimatorSwitchTabIndex]',
})
export class SwitchTabIndexDirective {
  @Input() inputId?: string;
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.code !== 'Tab') {
      return;
    }
    if (!event.shiftKey && this.inputId === ID_USER_SETTINGS_FORM_SHOW_CANALS) {
      event.preventDefault();
      const el: HTMLElement | null = document.getElementById(`${EVENTS_FORM_GROUP_PORT}0`);
      el?.focus();
    } else if (event.shiftKey && this.inputId === `${EVENTS_FORM_GROUP_PORT}0`) {
      event.preventDefault();
      const el: HTMLElement | null = document.getElementById(ID_USER_SETTINGS_FORM_SHOW_CANALS);
      el?.focus();
    }
  }
}
