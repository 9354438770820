import { Component } from '@angular/core';
import { CargoRules, DealEvent, DealEventType, Fuel } from '@estimator/models';
import { isCargoEvent } from '@estimator/helpers';
import { ITooltipAngularComp } from 'ag-grid-angular';
import { ITooltipParams } from 'ag-grid-community';

@Component({
  selector: 'estimator-event-tooltip-',
  template: `
    <div *ngIf="canalsOnRoute?.length || cargoEvents?.length" class="event-tooltip">
      <div *ngIf="canalsOnRoute?.length" class="tooltip-section">
        <b>Canals:</b>
        <p *ngFor="let canal of canalsOnRoute">{{ canal }}</p>
      </div>
      <div *ngIf="cargoEvents?.length && canalsOnRoute?.length" class="divider"></div>
      <div *ngIf="cargoEvents?.length" class="tooltip-section">
        <b>Operations:</b>
        <div *ngFor="let operation of cargoEvents" class="operation">
          <mat-icon [ngClass]="operation?.type" class="material-icons-outlined">{{
            getIcon(operation?.type)
          }}</mat-icon>
          <span *ngIf="operation?.meta?.fuel_id && !isCargoEvent(operation)">{{
            getFuel(operation.meta?.fuel_id)
          }}</span>
          {{ operation.meta?.amount | number }}
          <span *ngIf="operation?.meta?.terms_id && isCargoEvent(operation)">{{
            getTerms(operation.meta?.terms_id)
          }}</span>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./ag-grid-event-tooltip.component.scss'],
})
export class AgGridEventTooltipComponent implements ITooltipAngularComp {
  stopEvent?: DealEvent;
  cargoEvents: DealEvent[] = [];
  canalsOnRoute: string[] = [];
  terms: CargoRules[] = [];
  fuelTypes: Fuel[] = [];
  isCargoEvent = isCargoEvent;
  private _params?: ITooltipParams & {
    cargoEvents: DealEvent[];
    canalsOnRoute: string[];
    terms: CargoRules[];
    fuelTypes: Fuel[];
  };

  agInit(
    params: ITooltipParams & {
      cargoEvents: DealEvent[];
      canalsOnRoute: string[];
      terms: CargoRules[];
      fuelTypes: Fuel[];
    }
  ): void {
    this._params = params;
    this.stopEvent = params.data;
    this.cargoEvents = params.cargoEvents;
    this.canalsOnRoute = params.canalsOnRoute;
    this.fuelTypes = params.fuelTypes;
    this.terms = params.terms;
  }

  getFuel(id?: number): string {
    return this.fuelTypes.find((fuel) => fuel.id === id)?.name || '';
  }

  getTerms(id?: number): string {
    return this.terms.find((fuel) => fuel.id === id)?.name || '';
  }

  getIcon(type?: DealEventType): string {
    if (type === DealEventType.EventTypeBunker) {
      return 'local_gas_station';
    }
    if (type === DealEventType.EventTypeLoad) {
      return 'file_download';
    }
    if (type === DealEventType.EventTypeDischarge) {
      return 'file_upload';
    }
    return '';
  }
}
