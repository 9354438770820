<p-tabView (onChange)="onChangeTab($event)">
  <p-tabPanel header="DMS">
    <estimator-coordinate-input
      class="margin-bottom-7"
      [isLatitude]="true"
      [type]="CoordinatesFormat.DMS"
      [decimalValue]="getValueOrNull(point?.latitude)"
      #latitudeCoordinateInputDMS
    ></estimator-coordinate-input>
    <estimator-coordinate-input
      [isLatitude]="false"
      [type]="CoordinatesFormat.DMS"
      [decimalValue]="getValueOrNull(point?.longitude)"
      #longtitudeCoordinateInputDMS
    ></estimator-coordinate-input>
  </p-tabPanel>
  <p-tabPanel header="DM">
    <estimator-coordinate-input
      class="margin-bottom-7"
      [isLatitude]="true"
      [type]="CoordinatesFormat.DM"
      [decimalValue]="getValueOrNull(point?.latitude)"
      #latitudeCoordinateInputDM
    ></estimator-coordinate-input>
    <estimator-coordinate-input
      [isLatitude]="false"
      [type]="CoordinatesFormat.DM"
      [decimalValue]="getValueOrNull(point?.longitude)"
      #longtitudeCoordinateInputDM
    ></estimator-coordinate-input>
  </p-tabPanel>
  <p-tabPanel header="Decimal">
    <estimator-coordinate-input
      class="margin-bottom-7"
      [isLatitude]="true"
      [decimalValue]="getValueOrNull(point?.latitude)"
      #latitudeCoordinateInput
    ></estimator-coordinate-input>
    <estimator-coordinate-input
      [isLatitude]="false"
      [decimalValue]="getValueOrNull(point?.longitude)"
      #longtitudeCoordinateInput
    ></estimator-coordinate-input>
  </p-tabPanel>
</p-tabView>
<div class="flex-container-row">
  <div class="margin-right-auto" style="width: 50%"></div>
  <p-button
    class="send-invitation-button margin-left-10"
    styleClass="width-80"
    size="small"
    type="button"
    [label]="OK_TEXT"
    (click)="onSendCoordinates()"
  ></p-button>
  <p-button
    class="send-invitation-button margin-left-10"
    [text]="true"
    size="small"
    type="button"
    [label]="CANCEL_TEXT"
    (click)="onCancel()"
  ></p-button>
</div>
