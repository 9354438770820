import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { HttpStatusCode } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {
  ADDRESSES,
  Address,
  ContactForm,
  FOOTER_MENU_COMPANY,
  HEADER_MENU,
  IForm,
  PAGE,
  PAGES,
  PRODUCTS,
  Product,
  SOCIALS,
  Social,
} from '@estimator/landing-models';
import { FormService } from '@estimator/landing-services';
import { Subject, takeUntil } from 'rxjs';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'mar-footer',
  template: `
    <footer #footer>
      <div class="bg-footer"></div>
      <div class="container radius">
        <div class="footer">
          <mar-logo [width]="xsBreakPoint ? 84 : 115"></mar-logo>
          <div class="footer-content">
            <div class="footer-item-container">
              <div>
                <nav>
                  <a
                    *ngFor="let item of HEADER_MENU"
                    [routerLink]="item.slug"
                    class="nav-link opacity"
                    >{{ item.name }}</a
                  >
                </nav>
              </div>
              <div class="right-section">
                <div class="sub-container">
                  Follow us
                  <div class="social-list">
                    <a
                      *ngFor="let social of SOCIALS"
                      href="{{ social.link }}"
                      title="{{ social.name }}"
                      target="_blank"
                      class="icon light opacity {{ social.icon }}"
                    ></a>
                  </div>
                </div>
                <div class="sub-container contact-us">
                  Have a question?
                  <div class="address-list">
                    <mar-button color="transparent" type="anchor" [routerLink]="[PAGE.CONTACTS]"
                      >Contact us</mar-button
                    >
                  </div>
                </div>
              </div>
              <!-- <div class="footer-item">
                <div class="footer-nav" #footerNav>
                  <div class="f-header t-uppercase">Products</div>
                  <nav class="column">
                    <a
                      *ngFor="let product of PRODUCTS"
                      [routerLink]="product.disabled ? null : product.url"
                      class="nav-link opacity"
                      >{{ product.name }}</a
                    >
                  </nav>
                </div>
                <div class="footer-nav" #footerNav>
                  <div class="f-header t-uppercase">Company</div>
                  <nav class="column">
                    <a
                      *ngFor="let item of FOOTER_MENU_COMPANY"
                      [routerLink]="item.slug"
                      class="nav-link opacity"
                      >{{ item.name }}</a
                    >
                  </nav>
                </div>
              </div>
              <div class="addresses">
                <div class="f-header t-uppercase">Addresses</div>
                <div class="address-list">
                  <p *ngFor="let address of ADDRESSES">
                    {{ address.address }}, {{ address.city }},
                    {{ address.country }}
                  </p>
                </div>
              </div> -->
            </div>
            <!-- <div class="footer-item">
              <div class="sub-container">
                Follow us
                <div class="social-list">
                  <a
                    *ngFor="let social of SOCIALS"
                    href="{{ social.link }}"
                    title="{{ social.name }}"
                    target="_blank"
                    class="icon light opacity {{ social.icon }}"
                  ></a>
                </div>
              </div>
              <div class="sub-container contact-us">
                Have a question?
                <div class="address-list">
                  <mar-button color="transparent" (click)="onShowContactUs()"
                    >Contact us</mar-button
                  >
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
      <div class="container">
        <div class="copyright">
          <span>XMAR &copy; {{ currentYear }}</span>
          <div class="copyright-terms">
            <a [routerLink]="[PAGE.TERMS_AND_CONDITIONS]">{{
              PAGES_DETAILED.TERMS_AND_CONDITIONS.name
            }}</a>
            <a [routerLink]="[PAGE.PRIVACY_POLICY]">{{ PAGES_DETAILED.PRIVACY_POLICY.name }}</a>
          </div>
        </div>
      </div>
    </footer>
    <mar-sidebar [show]="showContactUs" (onCloseSidebar)="toggleSidebar.emit()">
      <div class="sidebar-content column">
        <div class="sidebar-content-header">
          <h2 class="secondary">Book a demo</h2>
          <p class="description">Leave your details and we'll get back to you soon.</p>
        </div>
        <form class="sidebar-contacts-form column" [formGroup]="contactForm">
          <mar-input
            formControlName="first_name"
            color="light"
            placeholder="Your full name"
            [required]="true"
          ></mar-input>
          <mar-input
            formControlName="email"
            color="light"
            placeholder="Your work email"
            [required]="true"
          ></mar-input>
          <mar-input
            formControlName="phone"
            color="light"
            placeholder="Phone number"
            [required]="true"
          ></mar-input>
          <mar-input
            formControlName="company_name"
            color="light"
            placeholder="Company name"
            [required]="true"
          ></mar-input>
          <mar-input
            formControlName="time_for_demo"
            color="light"
            placeholder="Preferred time for a demo"
            [required]="true"
          ></mar-input>
        </form>
        <div class="button-list column">
          <mar-button
            (click)="onSubmitContactForm()"
            [loading]="loading"
            [disabled]="isSubmitContactFormDisabled"
            color="blue"
            size="large"
            class="w-100"
            >{{ contactButtonLabel }}</mar-button
          >
          <p class="terms">
            <span class="opacity">By submitting the form, you accept our&nbsp;</span
            ><a
              [routerLink]="[PAGE.PRIVACY_POLICY]"
              (click)="onShowContactUs()"
              class="t-underline"
              >{{ PAGES_DETAILED.PRIVACY_POLICY.name | lowercase }}</a
            >.
          </p>
        </div>
      </div>
      <p></p
    ></mar-sidebar>
    <mar-cookies></mar-cookies>
  `,
  styles: [
    `
      @import '_variables';
      :host {
        .footer {
          display: grid;
          grid-template-columns: 4fr 8fr;
          padding: $container-padding-xs 0 $container-padding-l;
          gap: $gap-l;
          .footer-item,
          .footer-item-container {
            display: grid;
            grid-template-columns: 1fr 1fr;
          }
          nav {
            display: flex;
            gap: $gap-l;
          }
          .footer-nav {
            display: flex;
            flex-direction: column;
            gap: $gap-l;
            flex-basis: 50%;
            .column {
              gap: $gap-xxs;
            }
          }
          .addresses {
            display: flex;
            flex-direction: column;
            gap: $gap-l;
            .address-list {
              display: flex;
              flex-direction: column;
              gap: $gap-s;
              line-height: 150%;
              max-width: 35rem;
            }
          }
          .sub-container {
            display: flex;
            flex-direction: column;
            gap: $gap-m;
          }
          .footer-content {
            display: grid;
            gap: $gap-xxl;
          }
          .social-list {
            display: flex;
            align-items: center;
            gap: $gap-l;
            height: 4rem;
          }
          .nav-link {
            line-height: 150%;
            color: $white;
            width: fit-content;
            &:before,
            &:hover:before {
              background: $white;
            }
          }
          .right-section {
            display: flex;
            gap: $gap-3xl;
          }
          @media (max-width: $desktop-width) {
            .footer-item-container {
              grid-template-columns: 1fr;
              gap: $gap-xxl;
            }
          }
          @media (max-width: $tablet-width) {
            grid-template-columns: 1fr;
            gap: $gap-l;
            .footer-item,
            .footer-item-container,
            .footer-content {
              display: grid;
              grid-template-columns: 1fr;
              gap: $gap-xl;
            }
            .footer-nav,
            .addresses {
              gap: $gap-xs;
              .column {
                flex-direction: row;
                gap: $gap-m;
              }
            }
            .social-list {
              gap: $gap-xl;
              height: unset;
            }
            .sub-container {
              gap: $gap-xs;
            }
          }
          @media (max-width: $mobile-width) {
            padding: 3.2rem 0 $container-padding-s;
          }
        }
        .copyright {
          display: flex;
          background-color: $primary;
          padding-bottom: $padding-xl;
          span {
            margin-right: auto;
          }
          a {
            color: $white;
            opacity: 0.5;
          }
          .copyright-terms {
            display: flex;
            gap: $gap-xl;
          }
          @media (max-width: $desktop-width) {
            flex-direction: column;
            gap: $gap-xs;
            padding-bottom: $padding-m;
          }
          @media (max-width: $mobile-width) {
            .copyright-terms {
              gap: $gap-m;
            }
          }
        }
        footer {
          z-index: 1;
          position: relative;
          color: $white;
          .container {
            background-color: $primary;
            &.radius {
              border-radius: $radius-l $radius-l 0 0;
            }
          }
          @media (max-width: $mobile-width) {
            .container {
              &.radius {
                border-radius: $radius-m $radius-m 0 0;
              }
            }
          }
        }
      }
    `,
  ],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent implements AfterViewInit {
  @Input() currentDirectory = '';
  @Input() showContactUs = false;
  @Output() toggleSidebar = new EventEmitter<void>();
  @Output() contactFormSubmit = new EventEmitter<ContactForm>();
  currentYear = new Date().getFullYear();
  xsBreakPoint = false;
  contactForm = this.fb.group<IForm<ContactForm>>({
    first_name: [null, [Validators.required]],
    email: [null, [Validators.required]],
    company_name: [null, [Validators.required]],
    phone: [null, [Validators.required]],
    time_for_demo: [null, [Validators.required]],
  });
  loading = false;
  isContactFormSubmitted = false;

  readonly PAGE = PAGE;
  readonly PAGES_DETAILED = PAGES;
  readonly ADDRESSES: Address[] = ADDRESSES;
  readonly SOCIALS: Social[] = SOCIALS;
  readonly PRODUCTS: Product[] = PRODUCTS;
  readonly FOOTER_MENU_COMPANY: { name: string; slug: string }[] = FOOTER_MENU_COMPANY;
  readonly HEADER_MENU: { name: string; slug: string }[] = HEADER_MENU;
  private _onDestroy$ = new Subject<void>();

  get contactButtonLabel(): string {
    if (this.loading) {
      return '...Submitting';
    }
    return this.isContactFormSubmitted ? 'Request sent' : 'Book a demo';
  }

  get isSubmitContactFormDisabled(): boolean {
    return this.isContactFormSubmitted || this.loading || this.contactForm.invalid;
  }

  constructor(
    private readonly breakpointObserver: BreakpointObserver,
    private readonly cdr: ChangeDetectorRef,
    private readonly fb: FormBuilder,
    private readonly formSrv: FormService
  ) {}

  ngAfterViewInit() {
    this.breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.HandsetPortrait])
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((result: BreakpointState) => {
        this.xsBreakPoint = result.matches;
        this.cdr.detectChanges();
      });
  }

  onShowContactUs(): void {
    this.toggleSidebar.emit();
  }

  onSubmitContactForm() {
    if (this.isSubmitContactFormDisabled) return;
    this.loading = !this.loading;
    this.formSrv
      .sendMail(this.contactForm.value as ContactForm)
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((res) => {
        if (res.status === HttpStatusCode.Ok) {
          this.loading = false;
          this.isContactFormSubmitted = true;
          Object.keys(this.contactForm.controls).forEach((key) => {
            const control = this.contactForm.get(key);
            if (control) {
              control.disable();
              control.markAsDirty();
              this.cdr.detectChanges();
            }
          });
          this.contactForm.disable();
          this.contactForm.markAsDirty();
          this.cdr.detectChanges();
          document.querySelectorAll('input').forEach((elem) => (elem.disabled = true));
          this.cdr.detectChanges();
        }
      });
  }
}
