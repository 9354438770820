import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { Fuel, Vessel } from '@estimator/models';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'estimator-ag-grid-vessel-details',
  templateUrl: './ag-grid-vessel-details.component.html',
  styleUrls: ['./ag-grid-vessel-details.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgGridVesselDetailsComponent implements ICellRendererAngularComp {
  vessel: Vessel | null = null;
  fuels: Fuel[] = [];
  agInit(params: any): void {
    if (params.data) {
      this.vessel = params.data;
    }
    if (params.onSaveVessel) {
      this.onSaveVessel = params.onSaveVessel;
    }
    if (params.fuels) {
      this.fuels = params.fuels;
    }
  }
  refresh(/* params: ICellRendererParams<any, any> */): boolean {
    return false;
  }

  onSaveVessel(vessel: Vessel) {
    return;
  }
}
