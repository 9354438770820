import { Component } from '@angular/core';

@Component({
  selector: 'estimator-aggrid-header',
  template: `<p (click)="click()" class="cursor-pointer">{{ title }}</p>`,
})
export class AgGridHeaderComponent {
  params: any;
  title?: string;

  agInit(params: any) {
    this.params = params;
    this.title = this.params.title;
  }

  click() {
    this.params.click();
  }
}
