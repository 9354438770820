import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';
import { NotificationType } from '@estimator/models';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private _snackBarRef?: MatSnackBarRef<TextOnlySnackBar>;

  constructor(private _snackBar: MatSnackBar) {}

  showNotification(text: string, panelClass?: NotificationType): void {
    this._snackBarRef = this._snackBar.open(text, 'OK', { panelClass });
  }
}
