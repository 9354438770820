import { FormGroup } from '@angular/forms';
import { DealEvent, DealEventType, EventFormGroup, GeoZone, NOT_APPLICABLE } from '@estimator/models';
import { omitBy } from 'lodash';
import { getLocalTimeZoneOffsetInHours, getUserOrSystemDuration } from './date-time-helpers';
import { isNullOrZero } from './ui-helpers';

export const isCargoEvent = (event?: DealEvent): boolean => {
  return (
    event?.type === DealEventType.EventTypeLoad || event?.type === DealEventType.EventTypeDischarge
  );
};

export const isLoadEvent = (event?: DealEvent): boolean => {
  return event?.type === DealEventType.EventTypeLoad;
};

export const isDischargeEvent = (event?: DealEvent): boolean => {
  return event?.type === DealEventType.EventTypeDischarge;
};

export const isPrimaryEvent = (event: DealEvent): boolean => {
  return event.type === DealEventType.EventTypeCanal || event.type === DealEventType.EventTypeStop;
};

export const isBunkerEvent = (event?: DealEvent): boolean => {
  return event?.type === DealEventType.EventTypeBunker;
};

export const isSecondaryEvent = (event: DealEvent): boolean => {
  return isCargoEvent(event) || event.type === DealEventType.EventTypeBunker;
};

export const isCanalEvent = (event?: DealEvent): boolean => {
  return event?.type === DealEventType.EventTypeCanal;
};

export const isStopEvent = (event: DealEvent): boolean => {
  return event.type === DealEventType.EventTypeStop;
};

export const getEventTimezoneOffset = (event?: DealEvent): number => {
  let offset = 0;
  if (event) {
    if (
      (isCanalEvent(event) && event.meta?.timezone_offset) ||
      (isStopEvent(event) && event.meta?.timezone_offset)
    ) {
      offset = event.meta.timezone_offset;
    } else if (isStopEvent(event) && event.port?.time_zone) {
      offset = event.port.time_zone;
    }
  }
  return offset;
};

export const getTimeZoneTooltipValue = (event?: DealEvent): string => {
  let result = NOT_APPLICABLE;
  if (event) {
    if (isStopEvent(event) && event.port?.time_zone) {
      result = `${event.port.time_zone_name} UTC${event.port.time_zone >= 0 ? '+' : ''}${
        event.port.time_zone
      }`;
    } else if (
      (isStopEvent(event) && event.meta?.timezone_offset) ||
      (isCanalEvent(event) && event.meta?.timezone_offset)
    ) {
      result = `${event.meta.timezone_name || NOT_APPLICABLE} UTC${
        event.meta.timezone_offset >= 0 ? '+' : ''
      }${event.meta.timezone_offset}`;
    }
  }
  return result;
};

export const omitDealEvent = (event?: DealEvent): DealEvent => {
  delete event?.single_inner_event;
  delete event?.inner_events;
  delete event?.meta?.preset?.consumption_rates;
  delete event?.meta?.fuel_consumptions;
  delete event?.meta?.distance;
  delete event?.meta?.eca_distance;
  delete event?.meta?.distance_with_canals;
  delete event?.meta?.eca_distance_with_canals;
  delete event?.meta?.sailing_minutes_with_canals;
  delete event?.meta?.sailing_minutes;
  delete event?.meta?.port_time;
  delete event?.meta?.port_time_with_canals;
  event = omitBy(event, isNullOrZero);
  event.meta = omitBy(event.meta, isNullOrZero);
  return event;
};

export const userTimeZone = (): string => {
  return `(UTC${
    getLocalTimeZoneOffsetInHours() >= 0 ? '+' : ''
  }${getLocalTimeZoneOffsetInHours()})`;
};

export const pointExisted = (eventForm: FormGroup<EventFormGroup>): boolean => {
  return !!(
    (eventForm.controls.point.value?.latitude || eventForm.controls.point.value?.latitude === 0) &&
    (eventForm.controls.point.value?.longitude || eventForm.controls.point.value?.longitude === 0)
  );
};

export const pointExistedForEvent = (event: DealEvent): boolean => {
  return !!(
    (event.point?.latitude || event.point?.latitude === 0) &&
    (event.point?.longitude || event.point?.longitude === 0)
  );
};

export const getRequiredCanalsDA = (canals: GeoZone[]): string[] => {
  return canals.reduce((acc: string[], canal) => {
    if (canal.is_da_required) {
      acc.push(canal.name);
    }
    return acc;
  }, []);
}

const showCanalEvent = (event: DealEvent, requiredCanalsDA: string[]): boolean => {
  return !!(
    (event && isStopEvent(event)) ||
    (event?.type === DealEventType.EventTypeCanal &&
      (event.meta?.disbursement ||
        canalRequiredDA(event.meta?.current_canal_name || '', requiredCanalsDA) ||
        event.meta?.extra_minutes_after?.system ||
        event.meta?.extra_minutes_after?.user ||
        event.meta?.extra_minutes_before?.user ||
        event.meta?.extra_minutes_before?.system ||
        event.meta?.idle_minutes))
  );
};

const canalRequiredDA = (canalName: string, requiredCanalsDA: string[]): boolean => {
  return !!requiredCanalsDA?.includes(canalName);
};

export const startConditionForGetSumData = (
  event: DealEvent,
  showCanals: boolean,
  requiredCanalsDA: string[]
): boolean => {
  return !showCanals && showCanalEvent(event, requiredCanalsDA);
};

export const breakConditionForGetSumData = (
  prevEvent: DealEvent,
  requiredCanalsDA: string[],
): boolean => {
  return !isCanalEvent(prevEvent) || showCanalEvent(prevEvent, requiredCanalsDA!);
};

export const continueConditionForGetSumData = (prevEvent: DealEvent): boolean => {
  return (
    isCanalEvent(prevEvent) &&
    !getUserOrSystemDuration(prevEvent.meta?.extra_minutes_after) &&
    !getUserOrSystemDuration(prevEvent.meta?.extra_minutes_before)
  );
}
