import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { confirmPasswordValidator, convertCurrencyToUiValue } from '@estimator/helpers';
import {
  DEFAULT_FIRST_NAME,
  DEFAULT_LAST_NAME,
  DEFAULT_USER,
  FormValidationErrors,
  InputTypes,
  PrimeNgColors,
  PrimeNgIcons,
  ProductType,
  RegisterForm,
  RegisterProps,
  SIGN_UP,
  UserSubscription,
} from '@estimator/models';

@Component({
  selector: 'estimator-sign-up-form',
  templateUrl: './sign-up-form.component.html',
  styleUrls: ['./sign-up-form.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignUpFormComponent {
  @Input()
  set isLoading(state: boolean) {
    if (state) {
      this.signUpFormGroup.disable();
    } else {
      this.signUpFormGroup.enable();
    }
    this._isLoading = state;
    this.detectChanges();
  }
  get isLoading(): boolean {
    return this._isLoading;
  }
  @Input()
  set subscriptionModels(models: UserSubscription[]) {
    this._subscriptionModels = models;
    if (this._presetProduct) {
      this.setActiveProduct(this._presetProduct);
    }
    this.detectChanges();
  }
  get subscriptionModels(): UserSubscription[] {
    return this._subscriptionModels;
  }
  @Input() set presetProduct(product: ProductType | null) {
    if (product) {
      this._presetProduct = product;
      this.setActiveProduct(product);
    }
  }
  @Output() signUp = new EventEmitter<RegisterProps>();
  isPasswordHidden = true;
  isConfirmPasswordHidden = true;
  signUpFormGroup: FormGroup<RegisterForm> = new FormGroup<RegisterForm>(
    {
      first_name: new FormControl('', {
        nonNullable: true,
        validators: [Validators.required],
      }),
      last_name: new FormControl('', {
        nonNullable: true,
        validators: [Validators.required],
      }),
      email: new FormControl('', {
        nonNullable: true,
        validators: [Validators.required, Validators.email],
      }),
      password: new FormControl('', {
        nonNullable: true,
        validators: [
          Validators.required,
          Validators.minLength(6),
          Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,}$/),
        ],
      }),
      passwordConfirm: new FormControl('', {
        nonNullable: true,
        validators: [Validators.required, Validators.minLength(6)],
      }),
      company_name: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
      rulesAgreed: new FormControl(false, {
        nonNullable: true,
        validators: [Validators.requiredTrue],
      }),
      subscription_model_id: new FormControl(null, { validators: [Validators.required] }),
    },
    { validators: confirmPasswordValidator() }
  );
  readonly SIGN_UP = SIGN_UP;
  readonly DEFAULT_USER = DEFAULT_USER;
  readonly DEFAULT_FIRST_NAME = DEFAULT_FIRST_NAME;
  readonly DEFAULT_LAST_NAME = DEFAULT_LAST_NAME;
  readonly PrimeNgColors = PrimeNgColors;
  readonly PrimeNgIcons = PrimeNgIcons;
  readonly convertCurrencyToUiValue = convertCurrencyToUiValue;
  private _isLoading = false;
  private _presetProduct?: ProductType;
  private _subscriptionModels: UserSubscription[] = [];

  get emailControlError(): string | null {
    const errors = this.signUpFormGroup.controls.email.errors;
    if (errors) {
      if (errors['email']) {
        return FormValidationErrors.EmailPattern;
      }
      if (errors['required']) {
        return FormValidationErrors.EmailRequired;
      }
    }
    return null;
  }

  get isEmailControlInvalid(): boolean {
    return (
      !!this.emailControlError &&
      this.signUpFormGroup.controls.email.dirty &&
      this.signUpFormGroup.controls.email.touched
    );
  }

  get passwordControlError(): string | null {
    const errors = this.signUpFormGroup.controls.password.errors;
    if (errors) {
      if (errors['required']) {
        return FormValidationErrors.PasswordRequired;
      }
      if (errors['minLength'] || errors['pattern']) {
        return FormValidationErrors.PasswordPattern;
      }
    }
    return null;
  }

  get isPasswordControlInvalid(): boolean {
    return (
      !!this.passwordControlError &&
      this.signUpFormGroup.controls.password.dirty &&
      this.signUpFormGroup.controls.password.touched
    );
  }

  get confirmPasswordControlError(): string | null {
    this.signUpFormGroup.controls.passwordConfirm.updateValueAndValidity();
    const errors = this.signUpFormGroup.controls.passwordConfirm.errors;
    if (errors) {
      if (errors['required']) {
        return FormValidationErrors.PasswordRequired;
      }
      if (this.isPasswordMismatch) {
        return FormValidationErrors.PasswordMismatch;
      }
    }
    return null;
  }

  get isConfirmPasswordControlInvalid(): boolean {
    return (
      !!this.confirmPasswordControlError &&
      this.signUpFormGroup.controls.passwordConfirm.dirty &&
      this.signUpFormGroup.controls.passwordConfirm.touched
    );
  }

  get companyControlError(): string | null {
    const errors = this.signUpFormGroup.controls.company_name.errors;
    if (errors) {
      if (errors['required']) {
        return 'Company is required';
      }
    }
    return null;
  }

  get firstNameControlError(): string | null {
    const errors = this.signUpFormGroup.controls.first_name.errors;
    if (errors) {
      if (errors['required']) {
        return 'First name is required';
      }
    }
    return null;
  }

  get lastNameControlError(): string | null {
    const errors = this.signUpFormGroup.controls.last_name.errors;
    if (errors) {
      if (errors['required']) {
        return 'Last name is required';
      }
    }
    return null;
  }

  get isCompanyControlInvalid(): boolean {
    return (
      !!this.companyControlError &&
      this.signUpFormGroup.controls.company_name.dirty &&
      this.signUpFormGroup.controls.company_name.touched
    );
  }

  get isFirstNameControlInvalid(): boolean {
    return (
      !!this.firstNameControlError &&
      this.signUpFormGroup.controls.first_name.dirty &&
      this.signUpFormGroup.controls.first_name.touched
    );
  }

  get isLastNameControlInvalid(): boolean {
    return (
      !!this.lastNameControlError &&
      this.signUpFormGroup.controls.last_name.dirty &&
      this.signUpFormGroup.controls.last_name.touched
    );
  }

  get isPasswordMismatch(): boolean {
    return this.signUpFormGroup.get('passwordConfirm')?.errors?.['passwordMismatch'];
  }

  get passwordInputType(): string {
    return this.isPasswordHidden ? InputTypes.Password : InputTypes.Text;
  }

  get passwordIcon(): string {
    return this.isPasswordHidden ? PrimeNgIcons.EYE : PrimeNgIcons.EYE_SLASH;
  }

  get confirmPasswordInputType(): string {
    return this.isConfirmPasswordHidden ? InputTypes.Password : InputTypes.Text;
  }

  get confirmPasswordIcon(): string {
    return this.isConfirmPasswordHidden ? PrimeNgIcons.EYE : PrimeNgIcons.EYE_SLASH;
  }

  constructor(private readonly cdr: ChangeDetectorRef) {}

  getControl(key: string): FormControl {
    return this.signUpFormGroup.get(key) as FormControl;
  }

  onSignUp(): void {
    if (this.signUpFormGroup.invalid) {
      this.signUpFormGroup.markAllAsTouched();
      Object.keys(this.signUpFormGroup.controls).forEach((key) => {
        this.signUpFormGroup.controls[key as keyof RegisterForm]?.markAsDirty();
      });
      this.signUpFormGroup.updateValueAndValidity();
      this.detectChanges();
      return;
    }
    const { first_name, last_name, email, password, company_name, subscription_model_id } =
      this.signUpFormGroup.getRawValue();
    const registerProps: RegisterProps = {
      first_name,
      last_name,
      email,
      password,
      company_name,
      subscription_model_id: subscription_model_id || 0,
    };
    this.signUp.emit(registerProps);
  }

  hidePassword(): void {
    this.isPasswordHidden = !this.isPasswordHidden;
    this.detectChanges();
  }

  hideConfirmPassword(): void {
    this.isConfirmPasswordHidden = !this.isConfirmPasswordHidden;
    this.detectChanges();
  }

  detectChanges(): void {
    this.cdr.detectChanges();
  }

  setSubControl(subscription: UserSubscription): void {
    this.signUpFormGroup.patchValue({ subscription_model_id: subscription.id || null });
  }

  setActiveProduct(product: ProductType): void {
    const sub = this.subscriptionModels.find((sub) => {
      return sub.name === product;
    });
    if (sub?.id) {
      this.signUpFormGroup.controls.subscription_model_id.patchValue(sub.id);
    }
  }
}
