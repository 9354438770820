import { Location } from '@angular/common';
import { EventEmitter, Injectable, NgZone, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { extractError } from '@estimator/helpers';
import {
  ACCESS_TOKEN_KEY,
  AuthFormDialogConfig,
  ChangeUserBlocked,
  ChangeUserRole,
  ChangeUserServiceTypes,
  CompanyInformation,
  CompanySetting,
  DASHBOARD_ROUTE,
  ErrorNotifications,
  FILTER_SIDEBAR_SEARCH_KEY,
  FILTER_SIDEBAR_USERS_KEY,
  HttpStatus,
  LoginProps,
  LoginReminderDialogConfig,
  Member,
  MemberRole,
  NotificationType,
  PasswordForgotAndRestoreDialogConfig,
  ProductType,
  REFRESH_TOKEN_KEY,
  RegisterProps,
  RegistrationReminderDialogConfig,
  RegistrationSuccessDialogConfig,
  SuccessNotifications,
  SystemTag,
  TokenEntity,
  UNDEFINED,
  UiSettingsStateModel,
  User,
  UserConfirmDialogConfig,
  UserConfirmInvitationDialogConfig,
  UserSubscription,
  VoyageType,
} from '@estimator/models';
import { AuthenticationService, NotificationService, XAuthService } from '@estimator/services';
import {
  AuthorizationFormComponent,
  LoginReminderComponent,
  PasswordForgotComponent,
  PasswordRestoreComponent,
  RegistrationReminderComponent,
  RegistrationSuccessComponent,
  UserConfirmComponent,
  UserConfirmInvitationComponent,
} from '@estimator/ui';
import {
  Action,
  NgxsOnInit,
  Selector,
  State,
  StateContext,
  Store,
  createSelector,
} from '@ngxs/store';
import jwtDecode from 'jwt-decode'; // TODO: change library to 'https://github.com/auth0/node-jsonwebtoken'
import moment from 'moment';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Observable, Subject, catchError, finalize, of, takeUntil, tap, throwError } from 'rxjs';
import { GetUsersByCompanyId } from '../company';
import { ProductsState } from '../products';
import { ChangeTheme } from '../ui-settings';
import {
  XAuthCancelInviteUser,
  XAuthChangeCompanyAdmin,
  XAuthChangeForbidWindowOpen,
  XAuthChangeUserBlocked,
  XAuthChangeUserRole,
  XAuthChangeUserServiceTypes,
  XAuthConfirmEmail,
  XAuthConfirmUser,
  XAuthConfirmUserByCode,
  XAuthGetCompanyAvailableServices,
  XAuthGetCompanyDeductibles,
  XAuthGetCompanyInformation,
  XAuthGetMe,
  XAuthInviteUser,
  XAuthIsRedirectAfterLogin,
  XAuthLogin,
  XAuthLoginRemind,
  XAuthLogout,
  XAuthOpenAuthForm,
  XAuthOpenConfirmEmailForm,
  XAuthOpenConfirmInvitation,
  XAuthOpenResetPasswordForm,
  XAuthRefreshToken,
  XAuthRegister,
  XAuthRegistrationRemind,
  XAuthResetPassword,
  XAuthRestoreToken,
  XAuthRetryEmail,
  XAuthSetSettings,
  XAuthSetTemporaryUserToken,
  XAuthUpdateCompany,
  XAuthUpdatePassword,
  XAuthUpdateUserInfo,
} from './x-auth.actions';

export interface XAuthStateModel {
  access_token?: string;
  refresh_token?: string;
  wait_confirmation_token?: string;
  user?: Partial<User>;
  loading?: boolean;
  signInModalOpen?: boolean;
  remindLoginModalOpen?: boolean;
  forbidDialogOpen?: boolean;
  companyInformation?: CompanyInformation;
  members?: Member[];
  invited_users?: Member[];
  companyAvailableServices?: string[];
  settings?: UiSettingsStateModel;
  companyDeductibles: CompanySetting[];
  needRedirectAfterLogin?: boolean;
}
@State<XAuthStateModel>({
  name: 'xauth',
  defaults: {
    user: {} as User,
    access_token: '',
    refresh_token: '',
    wait_confirmation_token: '',
    loading: false,
    signInModalOpen: false,
    remindLoginModalOpen: false,
    forbidDialogOpen: false,
    companyInformation: {} as CompanyInformation,
    members: [],
    invited_users: [],
    companyAvailableServices: [],
    // now we use default settings from back-end
    /*     settings: {
      default_port_time_type: UserTimeSettings.PortLocalTime,
      default_time_charter_commission: DEFAULT_TC_COMM,
      default_voyage_commission: DEFAULT_VC_COMM,
      default_grain_bale_calculation_type: GrainCalculationType.GrainCalculationType,
      autoSave: DEFAULT_AUTOSAVE,
      // background_color: DEFAULT_BACKGROUND,
      default_rule_id: SHINC_ID,
      laden_route_color: DEFAULT_LADEN_ROUTE,
      ballast_route_color: DEFAULT_BALLAST_ROUTE,
      color_scheme: Themes.LIGHT,
    }, */
    companyDeductibles: [],
    needRedirectAfterLogin: false,
  },
})
@Injectable()
export class XAuthState implements NgxsOnInit, OnDestroy {
  private readonly refreshTokenKey = REFRESH_TOKEN_KEY;
  private readonly accessTokenKey = ACCESS_TOKEN_KEY;
  private _onDestroy$ = new Subject<void>();
  private _stateName = 'Auth';

  @Selector()
  static isLogged({ user }: XAuthStateModel): boolean {
    return !!user?.id && !user?.temporary;
  }
  @Selector()
  static getToken({ access_token }: XAuthStateModel): string {
    return access_token || '';
  }
  @Selector()
  static getRefreshToken({ refresh_token }: XAuthStateModel): string {
    return refresh_token || '';
  }
  @Selector()
  static getUserEmail({ user }: XAuthStateModel): string {
    return user?.email || '';
  }
  @Selector()
  static getUserCompanyId({ user }: XAuthStateModel): number {
    return user?.company_id || 0;
  }
  @Selector()
  static getUserId({ user }: XAuthStateModel): number {
    return user?.id || 0;
  }
  @Selector()
  static getUserRole({ user }: XAuthStateModel): MemberRole | null {
    return user?.user_role || null;
  }
  @Selector()
  static getFuelConsParser({ user }: XAuthStateModel): MemberRole | boolean {
    return user?.with_fuel_consumptions_parser || false;
  }
  @Selector()
  static getXBPIFuelAccess({ user }: XAuthStateModel): MemberRole | boolean {
    return user?.with_xbpi || false;
  }
  @Selector()
  static getIsLoading({ loading }: XAuthStateModel): boolean {
    return loading || false;
  }
  @Selector()
  static getIsSignInModalOpen({ signInModalOpen }: XAuthStateModel): boolean {
    return signInModalOpen || false;
  }
  @Selector()
  static getUserAvailableProducts({ user }: XAuthStateModel): ProductType[] {
    return user?.service_types || [];
  }
  @Selector()
  static getForbidDialogOpen({ forbidDialogOpen }: XAuthStateModel): boolean {
    return forbidDialogOpen || false;
  }
  @Selector()
  static getCompanyInformation({ companyInformation }: XAuthStateModel): CompanyInformation {
    return companyInformation as CompanyInformation;
  }

  @Selector()
  static getCompanyDeductibles({ companyDeductibles }: XAuthStateModel): CompanySetting[] {
    return companyDeductibles;
  }
  @Selector()
  static getCompanyAdministratorId({ companyInformation }: XAuthStateModel): number {
    return companyInformation?.administrator_id || 0;
  }
  @Selector()
  static getCompanyAvailableServices({ companyAvailableServices }: XAuthStateModel): string[] {
    return companyAvailableServices as string[];
  }
  @Selector()
  static getAllCompanyUsers({ members, invited_users }: XAuthStateModel): Member[] | [] {
    const transformInvitedUsers = invited_users?.map((user) => {
      user.invite_user = true;
      return user;
    }) as Member[];
    return members?.concat(invited_users ? transformInvitedUsers : []) as Member[];
  }
  @Selector()
  static getCompanyUsers({ members }: XAuthStateModel): Member[] | [] {
    return members as Member[];
  }

  static checkEqualRole(memberRole: MemberRole) {
    return createSelector([XAuthState], ({ user }) => {
      return user.user_role === memberRole;
    });
  }
  @Selector()
  static getSettings({ settings }: XAuthStateModel): UiSettingsStateModel {
    return settings as UiSettingsStateModel;
  }
  @Selector()
  static getNeedRedirectAfterLogin({ needRedirectAfterLogin }: XAuthStateModel): boolean {
    return needRedirectAfterLogin || false;
  }

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly xAuthService: XAuthService,
    private readonly dialogService: DialogService,
    private readonly store: Store,
    private readonly ngZone: NgZone,
    private readonly router: Router,
    private readonly notificationService: NotificationService,
    private readonly location: Location
  ) {}

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  ngxsOnInit({ dispatch, getState }: StateContext<XAuthStateModel>) {
    dispatch(new XAuthRestoreToken());
    const userCompanyId = getState().user?.company_id;
    if (userCompanyId && !getState().user?.temporary) {
      dispatch(new GetUsersByCompanyId(userCompanyId as number));
    }
  }

  @Action(XAuthLogin)
  login({ patchState, dispatch }: StateContext<XAuthStateModel>, { props }: XAuthLogin) {
    patchState({ loading: true });
    delete props.rememberLogin;
    return this.xAuthService.login(props).pipe(
      tap((res) => {
        const { access_token, refresh_token } = res;
        const decodedToken: TokenEntity = jwtDecode<TokenEntity>(access_token);
        const user: Partial<User> = this.getUserFromToken(decodedToken);
        localStorage.setItem(this.accessTokenKey, access_token);
        localStorage.setItem(this.refreshTokenKey, refresh_token);
        patchState({
          access_token,
          refresh_token,
          user,
        });
        dispatch(new GetUsersByCompanyId(user.company_id as number));
      }),
      catchError((err) => {
        patchState({ loading: false });
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }
  @Action(XAuthConfirmUser)
  confirmUser({ patchState }: StateContext<XAuthStateModel>, { token }: XAuthConfirmUser) {
    patchState({ loading: true });
    return this.xAuthService.confirmUser(token).pipe(
      tap((res) => {
        const { access_token, refresh_token } = res;
        const decodedToken: TokenEntity = jwtDecode<TokenEntity>(access_token);
        const user: Partial<User> = this.getUserFromToken(decodedToken);
        localStorage.setItem(this.accessTokenKey, access_token);
        localStorage.setItem(this.refreshTokenKey, refresh_token);
        patchState({
          access_token,
          refresh_token,
          user,
        });
      }),
      catchError((err) => {
        patchState({ loading: false });
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }
  @Action(XAuthConfirmUserByCode)
  confirmUserByCode(
    { patchState }: StateContext<XAuthStateModel>,
    { token, code }: XAuthConfirmUserByCode
  ) {
    patchState({ loading: true });
    return this.xAuthService.confirmUserByCode(token, code).pipe(
      catchError((err) => {
        patchState({ loading: false });
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }
  @Action(XAuthRetryEmail)
  retryEmail({ patchState }: StateContext<XAuthStateModel>, { token }: XAuthRetryEmail) {
    patchState({ loading: true });
    const decodedToken = jwtDecode<TokenEntity>(token);
    return this.xAuthService.retryEmail(decodedToken.email).pipe(
      catchError((err) => {
        patchState({ loading: false });
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }
  @Action(XAuthLogout)
  logout({ patchState, dispatch }: StateContext<XAuthStateModel>) {
    patchState({ loading: true });
    localStorage.removeItem(this.accessTokenKey);
    localStorage.removeItem(this.refreshTokenKey);
    localStorage.removeItem(`${FILTER_SIDEBAR_USERS_KEY}-${VoyageType.Tanker}`);
    localStorage.removeItem(`${FILTER_SIDEBAR_SEARCH_KEY}-${VoyageType.Tanker}`);
    localStorage.removeItem(`${FILTER_SIDEBAR_USERS_KEY}-${VoyageType.Bulk}`);
    localStorage.removeItem(`${FILTER_SIDEBAR_SEARCH_KEY}-${VoyageType.Bulk}`);
    // localStorage.removeItem(SETTINGS_LOCAL_STORAGE_KEY);
    this.ngZone.run(() => {
      this.router.navigate(['/']);
    });
    patchState({ loading: false, access_token: '', refresh_token: '', user: {} as User });
    return dispatch(new XAuthSetTemporaryUserToken());
  }
  @Action(XAuthRegister, { cancelUncompleted: true })
  register({ getState, patchState }: StateContext<XAuthStateModel>, { props }: XAuthRegister) {
    patchState({ loading: true });
    const { access_token } = getState();
    if (access_token) {
      const decodedToken: TokenEntity = jwtDecode<TokenEntity>(access_token);
      const temporary_user_id = decodedToken.id;
      props.temporary_user_id = temporary_user_id;
    }
    return this.xAuthService.register(props).pipe(
      tap(({ wait_confirmation_token }) => {
        if (wait_confirmation_token) {
          patchState({ wait_confirmation_token });
        }
      }),
      catchError((err) => {
        patchState({ loading: false });
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }
  @Action(XAuthConfirmEmail)
  confirmEmail(
    { dispatch, patchState }: StateContext<XAuthStateModel>,
    { token }: XAuthConfirmEmail
  ) {
    patchState({ loading: true });
    return this.xAuthService.confirmEmail(token).pipe(
      catchError((err) => {
        const error = extractError(err);
        patchState({ loading: false });
        dispatch(new XAuthOpenConfirmEmailForm(error));
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
        setTimeout(() => {
          dispatch(new XAuthRestoreToken());
          dispatch(new XAuthOpenConfirmEmailForm());
        }, 3000);
      })
    );
  }
  @Action(XAuthRefreshToken, { cancelUncompleted: false })
  refreshToken(
    { dispatch, patchState }: StateContext<XAuthStateModel>,
    { props }: XAuthRefreshToken
  ) {
    patchState({ loading: true });
    return this.xAuthService.refreshToken(props).pipe(
      tap(({ access_token, refresh_token }) => {
        if (access_token) {
          localStorage.setItem(this.accessTokenKey, access_token);
          patchState({ access_token });
        }
        if (refresh_token) {
          localStorage.setItem(this.refreshTokenKey, refresh_token);
          patchState({ refresh_token });
        }
        dispatch(new XAuthRestoreToken());
      }),
      catchError((err) => {
        patchState({ loading: false });
        if (err?.status === HttpStatus.Unauthorized) {
          dispatch([new XAuthLogout(), new XAuthLoginRemind()]);
        }
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }
  @Action(XAuthRestoreToken)
  restore({ dispatch, getState, setState, patchState }: StateContext<XAuthStateModel>) {
    patchState({ loading: true });
    const access_token = localStorage.getItem(this.accessTokenKey);
    const refresh_token = localStorage.getItem(this.refreshTokenKey);
    if (
      !!access_token &&
      !!refresh_token &&
      access_token !== UNDEFINED &&
      refresh_token !== UNDEFINED
    ) {
      const state = getState();
      const { exp: exp1 } = jwtDecode<TokenEntity>(access_token);
      const { exp: exp2 } = jwtDecode<TokenEntity>(refresh_token);
      const decodedToken: TokenEntity = jwtDecode<TokenEntity>(access_token);
      const user: Partial<User> = this.getUserFromToken(decodedToken, true);
      if (moment.unix(exp1).isAfter(moment())) {
        setState({
          ...state,
          access_token,
          refresh_token,
          user,
        });
      } else if (moment.unix(exp2).isAfter(moment()) && !decodedToken.temporary) {
        return dispatch(new XAuthRefreshToken(refresh_token));
      } else {
        return dispatch(new XAuthSetTemporaryUserToken());
      }
      return patchState({ loading: false });
    } else if (!!access_token && !refresh_token) {
      const { exp: exp1 } = jwtDecode<TokenEntity>(access_token);
      if (moment.unix(exp1).isAfter(moment())) {
        const decodedToken: TokenEntity = jwtDecode<TokenEntity>(access_token);
        const user: Partial<User> = this.getUserFromToken(decodedToken, true);
        return patchState({
          access_token,
          user,
          loading: false,
        });
      }
    }
    return dispatch(new XAuthSetTemporaryUserToken());
  }

  @Action(XAuthResetPassword, { cancelUncompleted: true })
  resetPassword({ patchState }: StateContext<XAuthStateModel>, { email }: XAuthResetPassword) {
    patchState({ loading: true });
    return this.xAuthService.resetPassword(email, SystemTag.Estimator).pipe(
      catchError((err) => {
        patchState({ loading: false });
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }

  @Action(XAuthUpdatePassword, { cancelUncompleted: true })
  updatePassword(
    { patchState }: StateContext<XAuthStateModel>,
    { password, resetToken }: XAuthUpdatePassword
  ) {
    patchState({ loading: true });
    return this.xAuthService.updatePassword(password, resetToken).pipe(
      catchError((err) => {
        patchState({ loading: false });
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }
  @Action(XAuthOpenAuthForm)
  openAuthForm(
    { dispatch, patchState }: StateContext<XAuthStateModel>,
    { actionsAfter, isSignUp, presetProduct }: XAuthOpenAuthForm
  ) {
    patchState({ signInModalOpen: true });
    const subscriptionModels: Observable<UserSubscription[]> =
      this.store.select(ProductsState.getUserSubscriptions) || of([]);
    const signInEmitter = new EventEmitter<LoginProps>();
    const signUpEmitter = new EventEmitter<RegisterProps>();
    const forgotPasswordEmmiter = new EventEmitter<void>();
    const signInErrorSubject = new Subject<string>();
    const signUpErrorSubject = new Subject<string>();
    const loadingSubject = new Subject<boolean>();
    const config: DynamicDialogConfig<AuthFormDialogConfig> = {
      data: {
        signInEmitter,
        signInErrorSubject,
        signUpEmitter,
        signUpErrorSubject,
        forgotPasswordEmmiter,
        loadingSubject,
        subscriptionModels,
        isSignUp,
        presetProduct,
      },
      closable: true,
      width: '460px',
      styleClass: 'auth-dialog',
    };
    const authFormDialogRef = this.dialogService.open(AuthorizationFormComponent, config);
    /**
     * Sign In
     */
    signInEmitter.pipe(takeUntil(this._onDestroy$)).subscribe((props) => {
      loadingSubject.next(true);
      dispatch(new XAuthLogin(props))
        .pipe(
          takeUntil(this._onDestroy$),
          catchError((err) => {
            const message = extractError(err);
            if (message) {
              signInErrorSubject.next(message);
            }
            loadingSubject.next(false);
            return throwError(err);
          }),
          finalize(() => {
            loadingSubject.next(false);
          })
        )
        .subscribe((state: any) => {
          if (state && state.xauth) {
            patchState({ signInModalOpen: false });
            authFormDialogRef.close();
            const needRedirectAfterLogin = this.store.selectSnapshot(
              XAuthState.getNeedRedirectAfterLogin
            );
            if (needRedirectAfterLogin) {
              this.location.back();
              this.store.dispatch(new XAuthIsRedirectAfterLogin(false));
            }
            if (actionsAfter?.length) {
              dispatch(actionsAfter);
            }
          }
        });
    });
    /**
     * Sign Up
     */
    signUpEmitter.pipe(takeUntil(this._onDestroy$)).subscribe((props) => {
      loadingSubject.next(true);
      dispatch(new XAuthRegister(props))
        .pipe(
          takeUntil(this._onDestroy$),
          catchError((err) => {
            const message = extractError(err);
            if (message) {
              signUpErrorSubject.next(message);
            }
            loadingSubject.next(false);
            return throwError(err);
          }),
          finalize(() => {
            loadingSubject.next(false);
          })
        )
        .subscribe((state: any) => {
          if (state && state.xauth) {
            const authState: XAuthStateModel = state.xauth;
            const { wait_confirmation_token } = authState;
            patchState({ signInModalOpen: false });
            authFormDialogRef.close();
            if (wait_confirmation_token) {
              const retryEmail = new EventEmitter<void>();
              const retryEmailErrorSubject = new Subject<string>();
              const sendConfirmCode = new EventEmitter<string>();
              const config: DynamicDialogConfig<RegistrationSuccessDialogConfig> = {
                data: { email: props.email, retryEmail, retryEmailErrorSubject, sendConfirmCode },
              };
              const confirmEmailRef = this.dialogService.open(RegistrationSuccessComponent, config);
              dispatch(new XAuthConfirmUser(wait_confirmation_token))
                .pipe(takeUntil(this._onDestroy$))
                .subscribe((state: any) => {
                  if (state && state.xauth) {
                    const authState: XAuthStateModel = state.xauth;
                    if (authState.user?.id) {
                      confirmEmailRef.close();
                      if (actionsAfter?.length) {
                        dispatch(actionsAfter);
                      }
                    }
                  }
                });
              retryEmail.pipe(takeUntil(this._onDestroy$)).subscribe(() => {
                dispatch(new XAuthRetryEmail(wait_confirmation_token)).pipe(
                  catchError((err) => {
                    const message = extractError(err);
                    if (message) {
                      retryEmailErrorSubject.next(message);
                    }
                    return throwError(err);
                  })
                );
              });
              sendConfirmCode.pipe(takeUntil(this._onDestroy$)).subscribe((code) => {
                dispatch(new XAuthConfirmUserByCode(wait_confirmation_token, code))
                  .pipe(
                    takeUntil(this._onDestroy$),
                    catchError((err) => {
                      const message = extractError(err);
                      if (message) {
                        retryEmailErrorSubject.next(message);
                      }
                      return throwError(err);
                    }),
                    finalize(() => confirmEmailRef.close())
                  )
                  .subscribe();
              });
            }
          }
        });
    });
    /**
     * Forgot password
     */
    forgotPasswordEmmiter.pipe(takeUntil(this._onDestroy$)).subscribe(() => {
      patchState({ signInModalOpen: false });
      authFormDialogRef.close();
      const isLoadingSubject = new Subject<boolean>();
      const responseErrorSubject = new Subject<string>();
      const restoreSuccessSubject = new Subject<boolean>();
      const restorePassword = new EventEmitter<string>();
      const config: DynamicDialogConfig<PasswordForgotAndRestoreDialogConfig> = {
        closable: true,
        header: 'Provide your email for restore password',
        data: {
          isLoadingSubject,
          responseErrorSubject,
          restoreSuccessSubject,
          restorePassword,
        },
      };
      const passwordForgotDialogRef = this.dialogService.open(PasswordForgotComponent, config);
      /**
       * Restore password
       */
      restorePassword.pipe(takeUntil(this._onDestroy$)).subscribe((email) => {
        dispatch(new XAuthResetPassword(email))
          .pipe(
            takeUntil(this._onDestroy$),
            tap(() => {
              isLoadingSubject.next(true);
            }),
            finalize(() => {
              isLoadingSubject.next(false);
            }),
            catchError((err) => {
              const message = extractError(err);
              if (message) {
                responseErrorSubject.next(message);
              }
              return throwError(err);
            })
          )
          .subscribe(() => {
            restoreSuccessSubject.next(true);
          });
      });
    });
  }
  @Action(XAuthOpenResetPasswordForm)
  openResetPasswordForm(
    { dispatch }: StateContext<XAuthStateModel>,
    { resetToken }: XAuthOpenResetPasswordForm
  ) {
    const isLoadingSubject = new Subject<boolean>();
    const responseErrorSubject = new Subject<string>();
    const restoreSuccessSubject = new Subject<boolean>();
    const restorePassword = new EventEmitter<string>();
    const signIn = new EventEmitter<void>();
    const config: DynamicDialogConfig<PasswordForgotAndRestoreDialogConfig> = {
      header: 'Provide your new password and confirm it',
      closable: false,
      data: {
        isLoadingSubject,
        responseErrorSubject,
        restoreSuccessSubject,
        restorePassword,
        signIn,
      },
    };
    const resetPasswordDialogRef = this.dialogService.open(PasswordRestoreComponent, config);
    restorePassword.pipe(takeUntil(this._onDestroy$)).subscribe((password) => {
      dispatch(new XAuthUpdatePassword(password, resetToken))
        .pipe(
          takeUntil(this._onDestroy$),
          tap(() => {
            isLoadingSubject.next(true);
          }),
          finalize(() => {
            isLoadingSubject.next(false);
          }),
          catchError((err) => {
            const message = extractError(err);
            if (message) {
              responseErrorSubject.next(message);
            }
            return throwError(err);
          })
        )
        .subscribe(() => {
          restoreSuccessSubject.next(true);
        });
    });
    signIn.pipe(takeUntil(this._onDestroy$)).subscribe(() => {
      resetPasswordDialogRef.close();
      dispatch(new XAuthOpenAuthForm());
    });
  }
  @Action(XAuthOpenConfirmInvitation)
  openConfirmInvitation(
    { patchState }: StateContext<XAuthStateModel>,
    { token }: XAuthOpenConfirmInvitation
  ) {
    patchState({ loading: true });
    const confirm = new EventEmitter<void>();
    const config: DynamicDialogConfig<UserConfirmInvitationDialogConfig> = {
      header: 'Confirm invitation',
      data: {
        confirm,
      },
    };
    const userConfirmInvitationDialogRef = this.dialogService.open(
      UserConfirmInvitationComponent,
      config
    );
    confirm.pipe(takeUntil(this._onDestroy$)).subscribe(() => {
      this.xAuthService
        .confirmInvitation(token)
        .pipe(
          tap(({ access_token, refresh_token }) => {
            userConfirmInvitationDialogRef.close();
            if (access_token) {
              const decodedToken: TokenEntity = jwtDecode<TokenEntity>(access_token);
              const user: Partial<User> = this.getUserFromToken(decodedToken);
              localStorage.setItem(this.accessTokenKey, access_token);
              patchState({ access_token, user });
            }
            if (refresh_token) {
              localStorage.setItem(this.refreshTokenKey, refresh_token);
              patchState({ refresh_token });
            }
          }),
          catchError((err) => {
            patchState({ loading: false });
            return throwError(err);
          }),
          finalize(() => {
            patchState({ loading: false });
          })
        )
        .subscribe(
          () => {
            this.notificationService.showNotification(
              SuccessNotifications.AcceptedInvitation,
              NotificationType.Success
            );
          },
          (err) => {
            if (err.status === HttpStatus.Unauthorized) {
              this.notificationService.showNotification(
                ErrorNotifications.InvitationExpired,
                NotificationType.Error
              );
            } else {
              this.notificationService.showNotification(
                `Error when confirm invitation`,
                NotificationType.Warning
              );
            }
          }
        );
    });
  }
  @Action(XAuthOpenConfirmEmailForm)
  openConfirmEmailForm(
    { dispatch, getState }: StateContext<XAuthStateModel>,
    { error }: XAuthOpenConfirmEmailForm
  ) {
    const begin = new EventEmitter<void>();
    const config: DynamicDialogConfig<UserConfirmDialogConfig> = {
      header: 'Registration success',
      data: {
        confirmSuccess: !error,
        responseError: error || '',
        begin,
      },
    };
    const emailConfirmDialogRef = this.dialogService.open(UserConfirmComponent, config);
    begin.pipe(takeUntil(this._onDestroy$)).subscribe(() => {
      const { user } = getState();
      const isAuth = !!user?.id && !user.temporary;
      emailConfirmDialogRef.close();
      if (!isAuth) {
        dispatch(new XAuthOpenAuthForm());
      }
    });
  }
  @Action(XAuthRegistrationRemind)
  registrationRemind({ getState, dispatch }: StateContext<XAuthStateModel>) {
    const { access_token } = getState();
    if (access_token) {
      const { exp } = jwtDecode<TokenEntity>(access_token);
      const momentExp = moment.unix(exp);
      const restTime = moment.duration(momentExp.diff(moment()));
      const signUp = new EventEmitter<void>();
      const config: DynamicDialogConfig<RegistrationReminderDialogConfig> = {
        header: 'You will soon lose your fixtures',
        data: {
          restTime,
          signUp,
        },
      };
      const registrationReminderDialogRef = this.dialogService.open(
        RegistrationReminderComponent,
        config
      );
      signUp.pipe(takeUntil(this._onDestroy$)).subscribe(() => {
        registrationReminderDialogRef.close();
        const needRedirectAfterLogin = this.store.selectSnapshot(
          XAuthState.getNeedRedirectAfterLogin
        );
        if (needRedirectAfterLogin) {
          this.router.navigate([DASHBOARD_ROUTE]);
        }
        dispatch(new XAuthOpenAuthForm());
      });
    }
  }
  @Action(XAuthLoginRemind)
  loginRemind({ getState, patchState, dispatch }: StateContext<XAuthStateModel>) {
    const { remindLoginModalOpen } = getState();
    if (!remindLoginModalOpen) {
      const signIn = new EventEmitter<void>();
      const config: DynamicDialogConfig<LoginReminderDialogConfig> = {
        header: 'Perhaps, you are logged in from other device.',
        data: {
          signIn,
        },
      };
      const registrationReminderDialogRef = this.dialogService.open(LoginReminderComponent, config);
      patchState({ remindLoginModalOpen: true });
      signIn.pipe(takeUntil(this._onDestroy$)).subscribe(() => {
        patchState({ remindLoginModalOpen: false });
        registrationReminderDialogRef.close();
        dispatch(new XAuthOpenAuthForm());
      });
      registrationReminderDialogRef.onClose.subscribe((res) => {
        patchState({ remindLoginModalOpen: false });
      });
    }
  }
  @Action(XAuthSetTemporaryUserToken, { cancelUncompleted: true })
  setTemporaryUserToken({ patchState }: StateContext<XAuthStateModel>) {
    return this.authenticationService.getTemporaryToken().pipe(
      tap(({ token }) => {
        const decodedToken: TokenEntity = jwtDecode<TokenEntity>(token);
        const user: Partial<User> = this.getUserFromToken(decodedToken, true);
        localStorage.setItem(this.accessTokenKey, token);
        patchState({
          access_token: token,
          user,
        });
      }),
      catchError((err) => {
        patchState({ loading: false });
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }

  @Action(XAuthChangeForbidWindowOpen)
  xAuthChangeForbidWindowOpen(
    { patchState }: StateContext<XAuthStateModel>,
    { open }: XAuthChangeForbidWindowOpen
  ) {
    patchState({ forbidDialogOpen: open });
  }

  @Action(XAuthGetCompanyInformation)
  getCompanyInformation({ patchState, getState }: StateContext<XAuthStateModel>) {
    patchState({ loading: true });
    const { user } = getState();
    return this.xAuthService.getFullCompanyInformation().pipe(
      tap((res) => {
        const membersWithAdmin = res.members?.map((member) => {
          if (member.user_id === res.administrator_id) {
            member.is_admin = true;
          }
          if (member.user_id === user?.id) {
            member.is_me = true;
          }
          return member;
        });
        patchState({
          companyInformation: {
            company_name: res.company_name,
            administrator_id: res.administrator_id,
            company_payment_address: res.company_payment_address,
            company_legal_address: res.company_legal_address,
            company_settings: res.company_settings,
          },
          members: membersWithAdmin,
          invited_users: res.invited_users,
        });
      }),
      catchError((err) => {
        patchState({ loading: false });
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }

  @Action(XAuthGetCompanyAvailableServices)
  getCompanyAvailableServices({ patchState }: StateContext<XAuthStateModel>) {
    patchState({ loading: true });
    return this.xAuthService.getCompanyAvailableServices().pipe(
      tap((res) => {
        patchState({
          companyAvailableServices: res.service_types,
        });
      }),
      catchError((err) => {
        patchState({ loading: false });
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }

  @Action(XAuthChangeUserBlocked)
  changeUserBlocked(
    { patchState, getState }: StateContext<XAuthStateModel>,
    { userEmail, blocked }: XAuthChangeUserBlocked
  ) {
    patchState({ loading: true });
    const objectChangeUserBlocked: ChangeUserBlocked = {
      userEmail: userEmail,
      blocked: blocked,
    };
    return this.xAuthService.changeUserBlocked(objectChangeUserBlocked).pipe(
      tap(() => {
        const members = getState().members;
        if (members?.length) {
          const changedUserIndex = this.getUser(members, userEmail);
          if (changedUserIndex >= 0) {
            members[changedUserIndex].blocked = blocked;
            patchState({ members });
          }
        }
      }),
      catchError((err) => {
        patchState({ loading: false });
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }

  @Action(XAuthChangeUserRole)
  changeUserRole(
    { patchState, getState }: StateContext<XAuthStateModel>,
    { userEmail, userRole }: XAuthChangeUserRole
  ) {
    patchState({ loading: true });
    const objectChangeUserRole: ChangeUserRole = {
      userEmail: userEmail,
      userRole: userRole,
    };
    return this.xAuthService.changeUserRole(objectChangeUserRole).pipe(
      tap((res) => {
        const members = getState().members;
        if (members?.length) {
          const changedUserIndex = this.getUser(members, userEmail);
          if (res.member) {
            if (changedUserIndex >= 0) {
              members?.splice(changedUserIndex, 1, res.member as Member);
              patchState({ members });
            }
          }
        }
      }),
      catchError((err) => {
        patchState({ loading: false });
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }

  @Action(XAuthChangeUserServiceTypes)
  changeUserServiceTypes(
    { patchState, getState }: StateContext<XAuthStateModel>,
    { userEmail, serviceTypes }: XAuthChangeUserServiceTypes
  ) {
    patchState({ loading: true });
    const objectChangeUserRole: ChangeUserServiceTypes = {
      userEmail: userEmail,
      serviceTypes: serviceTypes,
    };
    return this.xAuthService.changeUserServiceTypes(objectChangeUserRole).pipe(
      tap((res) => {
        const members = getState().members;
        if (members?.length) {
          const changedUserIndex = this.getUser(members, userEmail);
          if (res.member) {
            if (changedUserIndex >= 0) {
              members?.splice(changedUserIndex, 1, res.member as Member);
              patchState({ members });
            }
          }
        }
      }),
      catchError((err) => {
        patchState({ loading: false });
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }

  @Action(XAuthChangeCompanyAdmin)
  changeCompanyAdmin(
    { patchState }: StateContext<XAuthStateModel>,
    { administratorId }: XAuthChangeCompanyAdmin
  ) {
    patchState({ loading: true });
    return this.xAuthService.changeCompanyAdmin(administratorId).pipe(
      catchError((err) => {
        patchState({ loading: false });
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }

  @Action(XAuthInviteUser)
  inviteUser({ patchState }: StateContext<XAuthStateModel>, { userEmail }: XAuthInviteUser) {
    patchState({ loading: true });
    return this.xAuthService.inviteUser(userEmail).pipe(
      catchError((err) => {
        patchState({ loading: false });
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }

  @Action(XAuthCancelInviteUser)
  cancelInviteUser(
    { patchState }: StateContext<XAuthStateModel>,
    { userEmail }: XAuthCancelInviteUser
  ) {
    patchState({ loading: true });
    return this.xAuthService.cancelInviteUser(userEmail).pipe(
      catchError((err) => {
        patchState({ loading: false });
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }

  @Action(XAuthUpdateCompany)
  updateCompany(
    { patchState }: StateContext<XAuthStateModel>,
    { companyInformation }: XAuthUpdateCompany
  ) {
    patchState({ loading: true });
    return this.xAuthService.updateCompany(companyInformation).pipe(
      tap((companyInfo) => {
        patchState({ companyInformation: companyInfo });
      }),
      catchError((err) => {
        patchState({ loading: false });
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }

  @Action(XAuthUpdateUserInfo)
  xAuthUpdateUserInfo(
    { patchState, getState }: StateContext<XAuthStateModel>,
    { userInformation }: XAuthUpdateUserInfo
  ) {
    patchState({ loading: true });
    return this.xAuthService.updateUserInfo(userInformation).pipe(
      tap((res) => {
        const members = getState().members;
        if (members?.length) {
          const changedUserIndex = this.getUser(members, userInformation.user_email);
          if (res.member) {
            if (changedUserIndex >= 0) {
              members?.splice(changedUserIndex, 1, res.member as Member);
              patchState({ members });
            }
          }
        }
      }),
      catchError((err) => {
        patchState({ loading: false });
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }

  @Action(XAuthGetMe)
  xAuthGetMe({ patchState, dispatch }: StateContext<XAuthStateModel>) {
    patchState({ loading: true });
    return this.authenticationService.getMe().pipe(
      tap((res) => {
        patchState({ settings: res.settings });
        if (res?.settings?.color_scheme) {
          dispatch(new ChangeTheme(res?.settings?.color_scheme));
        }
      }),
      catchError((err) => {
        patchState({ loading: false });
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }

  @Action(XAuthSetSettings)
  xAuthSetSettings(
    { getState, patchState, dispatch }: StateContext<XAuthStateModel>,
    { settings }: XAuthSetSettings
  ) {
    patchState({ loading: true });
    return this.xAuthService.setSettings(settings).pipe(
      tap((res) => {
        const { settings } = getState();
        if (res?.color_scheme !== settings?.color_scheme && res?.color_scheme) {
          dispatch(new ChangeTheme(res.color_scheme));
        }
        patchState({ settings: res });
      }),
      catchError((err) => {
        patchState({ loading: false });
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }

  @Action(XAuthGetCompanyDeductibles)
  xAuthGetCompanyDeductibles({ patchState }: StateContext<XAuthStateModel>) {
    patchState({ loading: true });
    return this.xAuthService.getCompanyDeductibles().pipe(
      tap((res) => {
        patchState({ companyDeductibles: res.settings });
      }),
      catchError((err) => {
        patchState({ loading: false });
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }

  @Action(XAuthIsRedirectAfterLogin)
  xAuthIsRedirectAfterLogin(
    { patchState }: StateContext<XAuthStateModel>,
    { needRedirectAfterLogin }: XAuthIsRedirectAfterLogin
  ) {
    patchState({ needRedirectAfterLogin });
  }

  getUserFromToken(decodedToken: TokenEntity, temporary = false): Partial<User> {
    const user: Partial<User> = {
      id: decodedToken.id,
      email: decodedToken.email,
      company_id: decodedToken.company_id,
      service_types: decodedToken.service_types || [],
      user_role: decodedToken.user_role,
      with_fuel_consumptions_parser: decodedToken.with_fuel_consumptions_parser,
      with_xbpi: decodedToken.with_xbpi,
    };
    if (temporary) {
      user.temporary = decodedToken.temporary;
    }
    return user;
  }

  getUser(members: Member[], userEmail: string): number {
    return members?.findIndex((user) => user.user_email === userEmail);
  }
}
