import { NgModule } from '@angular/core';
import { BlogPreviewModule } from './blog-preview';
import { FooterModule } from './footer';
import { HeaderModule } from './header';
import { SubscribeFormModule } from './subscribe-form';

const LANDING_UI_MODULES = [HeaderModule, FooterModule, BlogPreviewModule, SubscribeFormModule];
@NgModule({
  imports: LANDING_UI_MODULES,
  exports: LANDING_UI_MODULES,
})
export class LandingUiModule {}
