export interface PositionTopLeft {
  top: number;
  left: number;
}

export enum UserGuideType {
  DialogWelcome0 = 'dialogWelcome',
  Vessel1 = 'vessel1',
  Fuel2 = 'fuel2',
  Deal3 = 'deal3',
  EventList4 = 'eventList4',
  Cargo5 = 'cargo5',
  FastBunkeringList6 = 'fastBunkeringList6',
  MiscList7 = 'miscList7',
  HireInput8 = 'hireInput8',
  Result9 = 'result9',
  CargoCalculator10 = 'cargoCalculator10',
  DialogMap11 = 'dialogMap11',
}
