import { AfterViewInit, Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[marTextScroll]',
})
export class TextScrollDirective implements AfterViewInit {
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit() {
    const element = this.el.nativeElement;
    const currentText = element.innerText;
    element.innerText = '';
    currentText.split(' ').map((item: string) => {
      const div = this.renderer.createElement('div');
      const text = this.renderer.createText(item);
      this.renderer.appendChild(div, text);
      this.renderer.appendChild(element, div);
    });
    this.observeDescription();
  }

  observeDescription() {
    const treshold = [];
    for (let i = 0; i <= 1; i += 0.01) {
      treshold.push(i);
    }
    const offsetTop = this.el.nativeElement.clientHeight;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const prcVisible = Math.floor(entry.intersectionRatio * 100);
          const wordsToShow = Math.round(
            (prcVisible * this.el.nativeElement.childNodes.length) / 100
          );
          this.el.nativeElement.childNodes.forEach((elem: HTMLDivElement, i: number) => {
            i >= wordsToShow ? elem.classList.remove('show') : elem.classList.add('show');
          });
        });
      },
      { threshold: treshold, rootMargin: `${offsetTop}px 0px -250px` }
    );
    return observer.observe(this.el.nativeElement);
  }
}
