import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { compareObjects } from '@estimator/helpers';
import { Cargo, CargoCalculator, GrainCalculationType, NOT_APPLICABLE } from '@estimator/models';

@Component({
  selector: 'estimator-cargo-calculator-tabs',
  templateUrl: './cargo-calculator-tabs.component.html',
  styleUrls: ['./cargo-calculator-tabs.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CargoCalculatorTabsComponent {
  @Input()
  set cargoes(cargoes: Cargo[]) {
    this._cargoes = cargoes;
    if (this._cargoes.length && !this.selectedCargoControl.value) {
      this.selectedCargoControl.patchValue(this._cargoes[0]);
    }
    this.cdr.detectChanges();
  }
  get cargoes(): Cargo[] {
    return this._cargoes;
  }
  @Input() grainBaleCalculationType: GrainCalculationType =
    GrainCalculationType.GrainCalculationType;
  @Output() calculate = new EventEmitter<Cargo>();
  @Output() getLastConstants = new EventEmitter<Cargo>();
  @Output() updateCargoQuantity = new EventEmitter<Cargo>();
  selectedCargoControl = new FormControl<Cargo | null>(null);
  readonly NOT_APPLICABLE = NOT_APPLICABLE;
  readonly compareObjects = compareObjects;
  private _cargoes: Cargo[] = [];
  public isAdvanced = false;

  get selectedCargoCalculator(): CargoCalculator | null {
    if (this.selectedCargoControl.value?.cargo_calculator) {
      return this.selectedCargoControl.value.cargo_calculator;
    }
    return null;
  }

  constructor(private readonly cdr: ChangeDetectorRef) {}

  onCalculate(calculator: CargoCalculator): void {
    const cargo = this.selectedCargoControl.value;
    if (cargo) {
      cargo.cargo_calculator = calculator;
      this.calculate.emit(cargo);
    }
  }

  onUpdateCargoQuantity(quantity: number): void {
    const cargo = this.selectedCargoControl.value;
    if (cargo) {
      cargo.quantity = quantity;
      this.updateCargoQuantity.emit(cargo);
    }
  }

  onGetLastConstants(): void {
    const cargo = this.selectedCargoControl.value;
    if (cargo) {
      this.getLastConstants.emit(cargo);
    }
  }

  detectChanges(): void {
    this.cdr.detectChanges();
  }
}
