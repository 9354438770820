import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  DEFAULT_USER,
  FeedbackMessage,
  FeedbackMessageDialogConfig,
  FeedbackMessageForm,
  FormValidationErrors,
  PrimeNgColors,
  PrimeNgIcons,
} from '@estimator/models';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'estimator-feedback-form',
  templateUrl: './feedback-form.component.html',
  styleUrls: ['./feedback-form.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeedbackFormComponent {
  @Output() send = new EventEmitter<FeedbackMessage>();
  email?: string;

  get emailControlError(): string | null {
    const errors = this.feedbackFormGroup.controls['email'].errors;
    if (errors) {
      if (errors['email']) {
        return FormValidationErrors.EmailPattern;
      }
      if (errors['required']) {
        return FormValidationErrors.EmailRequired;
      }
    }
    return null;
  }

  get isEmailControlInvalid(): boolean {
    return (
      !!this.emailControlError &&
      this.feedbackFormGroup.controls['email'].dirty &&
      this.feedbackFormGroup.controls['email'].touched
    );
  }

  feedbackFormGroup: FormGroup<FeedbackMessageForm> = new FormGroup<FeedbackMessageForm>({
    email: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required, Validators.email],
    }),
    message: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
  });
  readonly DEFAULT_USER = DEFAULT_USER;
  readonly PrimeNgColors = PrimeNgColors;
  readonly PrimeNgIcons = PrimeNgIcons;

  constructor(private readonly dialogConfig: DynamicDialogConfig<FeedbackMessageDialogConfig>) {
    if (this.dialogConfig.data) {
      const { send, email } = this.dialogConfig.data;
      if (send) {
        this.send = send;
      }
      if (email) {
        this.email = email;
        this.feedbackFormGroup.controls.email.patchValue(email);
        this.feedbackFormGroup.controls.email.disable();
      }
    }
  }

  onSendMessage() {
    this.send.emit(this.feedbackFormGroup.getRawValue() as FeedbackMessage);
  }
}
