import { HttpParams } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { BlogPost, PAGE } from '@estimator/landing-models';
import { PostService } from '@estimator/landing-services';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'estimator-blog-preview',
  template: `<div class="container container-post column">
    <div class="container-post-header">
      <h2>From the blog</h2>
      <mar-button [routerLink]="[PAGE.BLOG]" type="anchor" color="transparent" class="mobile-hide"
        >See all</mar-button
      >
    </div>
    <div class="post-list">
      <ng-container *ngFor="let post of blogPosts; let isLast = last">
        <a
          [routerLink]="['/', PAGE.BLOG, post.slug]"
          class="post-card"
          [ngClass]="{ 'tablet-hide': isLast }"
        >
          <div class="post-image">
            <img
              src="{{ post.cover.formats?.medium?.url || post.cover.url }}"
              alt="{{ post.title }}"
            />
          </div>
          <div class="post-description">
            <span class="f-header t-uppercase opacity">{{
              formatDate(post?.publish_date || post.createdAt)
            }}</span>
            <h3 class="secondary">{{ post.title }}</h3>
          </div>
        </a>
      </ng-container>
    </div>
    <mar-button [routerLink]="[PAGE.BLOG]" type="anchor" color="transparent" class="mobile-show"
      >See all</mar-button
    >
  </div>`,
  styles: [
    `
      @import '_variables';

      :host {
        display: block;
      }
      .container-post {
        gap: $gap-xxl;
        .container-post-header {
          display: flex;
          justify-content: space-between;
        }
      }
    `,
  ],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlogPreviewComponent implements OnInit, OnDestroy {
  blogPosts: BlogPost[] = [];
  private _onDestroy$ = new Subject<void>();

  constructor(private readonly cdr: ChangeDetectorRef, private readonly postSrv: PostService) {}

  ngOnInit() {
    const params = new HttpParams().set('limit', 3);
    this.postSrv
      .getAll(params)
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((res) => {
        this.blogPosts = res.data;
        this.cdr.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  PAGE = PAGE;

  formatDate(date: Date) {
    const newDate = new Date(date);
    return new Intl.DateTimeFormat(undefined, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }).format(newDate);
  }
}
