import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { OpenCloseFolderDialogConfig } from '@estimator/models';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'estimator-dialog-open-close-folder',
  templateUrl: './dialog-open-close-folder.component.html',
  styleUrls: ['./dialog-open-close-folder.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogOpenCloseFolderComponent {
  @Output() saveExist = new EventEmitter();
  @Output() dontSaveExist = new EventEmitter();
  oldFolder?: string;
  newFolder?: string;
  saveFolderOrDeleteDeal = false;
  notShowNewFolder = false;
  copyAlienFolder = false;

  constructor(private readonly dialogConfig: DynamicDialogConfig<OpenCloseFolderDialogConfig>) {
    if (this.dialogConfig.data) {
      const {
        saveExist,
        dontSaveExist,
        oldFolder,
        newFolder,
        saveFolderOrDeleteDeal,
        notShowNewFolder,
        copyAlienFolder,
        skipDialog,
      } = this.dialogConfig.data;
      if (saveExist) {
        this.saveExist = saveExist;
      }
      if (dontSaveExist) {
        this.dontSaveExist = dontSaveExist;
      }
      if (oldFolder) {
        this.oldFolder = oldFolder;
      }
      if (newFolder) {
        this.newFolder = newFolder;
      }
      if (saveFolderOrDeleteDeal) {
        this.saveFolderOrDeleteDeal = true;
      }
      if (notShowNewFolder) {
        this.notShowNewFolder = true;
      }
      if (copyAlienFolder) {
        this.copyAlienFolder = true;
      }
      if (skipDialog) {
        this.dontSaveExist.emit();
      }
    }
  }

  onSaveExist(): void {
    this.saveExist.emit();
  }

  onDontSaveExist(): void {
    this.dontSaveExist.emit();
  }

  getLabel1(): string {
    return this.saveFolderOrDeleteDeal || this.copyAlienFolder
      ? 'Save as folder'
      : `SAVE changes in current folder`;
  }

  getLabel2(): string {
    const addWord = this.copyAlienFolder ? 's' : '';
    return this.saveFolderOrDeleteDeal
      ? `Delete deal${addWord}`
      : `DON'T SAVE changes in current folder`;
  }

  getPieceOfLabel(): string {
    return this.notShowNewFolder ? 'creating' : 'opening';
  }
}
