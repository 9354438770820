import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { confirmPasswordValidator } from '@estimator/helpers';
import {
  ERROR_BASE_TEMPLATE,
  FormValidationErrors,
  InputTypes,
  NotificationType,
  PasswordForgotAndRestoreDialogConfig,
  PrimeNgColors,
  PrimeNgIcons,
  RESTORE_PASSWORD,
  SIGN_IN,
} from '@estimator/models';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'estimator-password-restore',
  templateUrl: './password-restore.component.html',
  styleUrls: ['./password-restore.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordRestoreComponent implements OnDestroy {
  @Input() isLoading = false;
  @Input() responseError = '';
  @Input() restoreSuccess = false;
  @Output() restorePassword = new EventEmitter<string>();
  @Output() signIn = new EventEmitter<void>();
  isPasswordHidden = true;
  isConfirmPasswordHidden = true;
  paswordRestoreForm: FormGroup = new FormGroup(
    {
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(6),
        Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,}$/),
      ]),
      passwordConfirm: new FormControl('', [Validators.required, Validators.minLength(6)]),
    },
    { validators: confirmPasswordValidator() }
  );
  readonly PrimeNgColors = PrimeNgColors;
  readonly NotificationType = NotificationType;
  private _onDestroy$ = new Subject<void>();

  get isPasswordControlInvalid(): boolean {
    return !!(
      this.passwordControlError &&
      this.paswordRestoreForm.get('password')?.dirty &&
      this.paswordRestoreForm.get('password')?.touched
    );
  }

  get passwordControlError(): string | null {
    const errors = this.paswordRestoreForm.get('password')?.errors;
    if (errors) {
      if (errors['required']) {
        return FormValidationErrors.PasswordRequired;
      }
      if (errors['minLength'] || errors['pattern']) {
        return FormValidationErrors.PasswordPattern;
      }
    }
    return null;
  }

  get confirmPasswordControlError(): string | null {
    const errors = this.paswordRestoreForm.get('passwordConfirm')?.errors;
    if (errors) {
      if (errors['required']) {
        return FormValidationErrors.PasswordRequired;
      }
      if (this.isPasswordMismatch) {
        return FormValidationErrors.PasswordMismatch;
      }
    }
    return null;
  }

  get isConfirmPasswordControlInvalid(): boolean {
    return !!(
      this.confirmPasswordControlError &&
      this.paswordRestoreForm.get('passwordConfirm')?.dirty &&
      this.paswordRestoreForm.get('passwordConfirm')?.touched
    );
  }

  get isPasswordMismatch(): boolean {
    return this.paswordRestoreForm.get('passwordConfirm')?.errors?.['passwordMismatch'];
  }

  get passwordInputType(): string {
    return this.isPasswordHidden ? InputTypes.Password : InputTypes.Text;
  }

  get passwordIcon(): string {
    return this.isPasswordHidden ? PrimeNgIcons.EYE : PrimeNgIcons.EYE_SLASH;
  }

  get confirmPasswordInputType(): string {
    return this.isConfirmPasswordHidden ? InputTypes.Password : InputTypes.Text;
  }

  get confirmPasswordIcon(): string {
    return this.isConfirmPasswordHidden ? PrimeNgIcons.EYE : PrimeNgIcons.EYE_SLASH;
  }

  get buttonLabel(): string {
    return this.restoreSuccess ? SIGN_IN : RESTORE_PASSWORD;
  }

  constructor(
    private readonly dialogConfig: DynamicDialogConfig<PasswordForgotAndRestoreDialogConfig>,
    private cdr: ChangeDetectorRef
  ) {
    if (this.dialogConfig.data) {
      const {
        isLoadingSubject,
        responseErrorSubject,
        restoreSuccessSubject,
        restorePassword,
        signIn,
      } = this.dialogConfig.data;
      if (restorePassword) {
        this.restorePassword = restorePassword;
      }
      if (signIn) {
        this.signIn = signIn;
      }
      isLoadingSubject?.pipe(takeUntil(this._onDestroy$)).subscribe((loading) => {
        this.isLoading = loading;
        this.paswordRestoreForm.disable();
        this.cdr.detectChanges();
      });
      responseErrorSubject?.pipe(takeUntil(this._onDestroy$)).subscribe((err) => {
        this.responseError = `${ERROR_BASE_TEMPLATE} ${err}`;
        this.paswordRestoreForm.enable();
        this.cdr.detectChanges();
      });
      restoreSuccessSubject?.pipe(takeUntil(this._onDestroy$)).subscribe((res) => {
        this.restoreSuccess = res;
        this.dialogConfig.closable = true;
        this.paswordRestoreForm.disable();
        this.cdr.detectChanges();
      });
    }
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  getControl(key: string): FormControl | null {
    const control: FormControl = this.paswordRestoreForm.get(key) as FormControl;
    return control || null;
  }

  onPasswordRestore(): void {
    if (this.restoreSuccess) {
      this.signIn.emit();
      return;
    }
    const password = this.getControl('password')?.value;
    if (password) {
      this.restorePassword.emit(password);
    }
  }

  hidePassword(): void {
    this.isPasswordHidden = !this.isPasswordHidden;
  }

  hideConfirmPassword(): void {
    this.isConfirmPasswordHidden = !this.isConfirmPasswordHidden;
  }
}
