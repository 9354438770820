export interface Address {
  country: string;
  city: string;
  address: string;
  coordinates: [number, number];
}

export const CYPRUS: Address = {
  country: 'Cyprus',
  city: 'Limassol',
  address: 'Amathountos ave. 180, 4533, Agios Tychonas',
  coordinates: [33.159285300520736, 34.71495680841276],
};

export const UAE: Address = {
  country: 'UAE',
  city: 'Dubai',
  address: 'Office C2, I-Rise Tower, Barsha Heights',
  coordinates: [55.1805209555098, 25.096871045889383],
};

export const ADDRESSES: Address[] = [CYPRUS];

export const EMAIL = 'info@xmar.com';
export const WEB_SITE = 'https://xmar.com';
