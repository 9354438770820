import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  getDatePipeTimeZoneFormat,
  getEventTimezoneOffset,
  getTimeZoneTooltipValue,
  isPrimaryEvent,
} from '@estimator/helpers';
import {
  DealEvent,
  DealEventType,
  OPACITY_5,
  STANDART_TIME_FORMAT,
  STOP_EVENT_ROW_HEIGHT,
  UserTimeSettings,
  ZERO_STRING,
} from '@estimator/models';
import {
  ColDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ICellEditorParams,
  ITooltipParams,
} from 'ag-grid-community';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { AgGridBasicTooltipComponent } from '../ag-grid-shared/ag-grid-basic-tooltip.component';
import { AgGridDateTimeEditorComponent } from '../ag-grid-shared/ag-grid-datetime-editor.component';
import { AgGridPortRendererComponent } from '../ag-grid-shared/ag-grid-port-renderer.component';

@Component({
  selector: 'estimator-deal-date-planner',
  templateUrl: './deal-date-planner.component.html',
  styleUrls: ['./deal-date-planner.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DealDatePlannerComponent {
  @Input()
  set events(data: DealEvent[]) {
    if (data) {
      this._events = cloneDeep(data);
      this.displayedEvents = this._events.filter(isPrimaryEvent);
      this._gridApi?.setRowData(this.displayedEvents);
      this._gridApi?.redrawRows();
    }
  }
  get events(): DealEvent[] {
    return this._events;
  }
  @Input()
  set timeSettings(settings: UserTimeSettings) {
    this._timeSettings = settings;
    this._gridApi?.refreshCells();
  }
  get timeSettings(): UserTimeSettings {
    return this._timeSettings;
  }
  @Output() updateEvent = new EventEmitter<DealEvent>();
  displayedEvents: DealEvent[] = [];
  columnDefs: ColDef<DealEvent>[] = [
    {
      field: 'port',
      headerName: 'Port',
      flex: 1,
      cellRendererSelector: (params) => {
        const event: DealEvent | undefined = params.data;
        if (event?.type === DealEventType.EventTypeStop) {
          return { component: 'portRenderer' };
        }
        return {};
      },
      valueFormatter: (params) => {
        const event: DealEvent | undefined = params.data;
        return event?.meta?.current_canal_name || '';
      },
      tooltipField: 'start_time',
      tooltipComponent: 'simpleTooltip',
      tooltipComponentParams: (params: { textValue: string } & ITooltipParams<DealEvent>) => {
        const dealEvent = params.data;
        return {
          ...params,
          textValue: getTimeZoneTooltipValue(dealEvent),
        };
      },
      minWidth: 120,
      width: 120,
    },
    {
      field: 'start_time',
      headerName: 'Arrival',
      cellEditor: 'dateEditor',
      cellEditorParams: (params: ICellEditorParams<DealEvent>) => {
        const dealEvent = params.data;
        return {
          ...params,
          timeSettings: this.timeSettings,
          timeZoneOffset: getEventTimezoneOffset(dealEvent),
        };
      },
      valueFormatter: (params) => {
        if (params.value) {
          const dealEvent = params.data;
          return this.dateCellFormatter(params.value, getEventTimezoneOffset(dealEvent));
        }
        return '';
      },
      flex: 1,
      editable: (params) => {
        return params.node.rowIndex === 0;
      },
      cellClass: (params) => {
        return params.node.rowIndex === 0 ? '' : OPACITY_5;
      },
      onCellValueChanged: (event) => {
        if (event.newValue && event.newValue !== event.oldValue) {
          this.updateEvent.emit(event.data);
        }
      },
      width: 125,
      minWidth: 125,
    },
    {
      field: 'end_time',
      headerName: 'Departure',
      cellEditor: 'dateEditor',
      valueFormatter: (params) => {
        if (params.value) {
          const dealEvent = params.data;
          return this.dateCellFormatter(params.value, getEventTimezoneOffset(dealEvent));
        }
        return '';
      },
      flex: 1,
      editable: false,
      cellClass: OPACITY_5,
      width: 125,
      minWidth: 125,
    },
  ];
  gridOptions: GridOptions<DealEvent> = {
    rowModelType: 'clientSide',
    columnDefs: this.columnDefs,
    components: {
      dateEditor: AgGridDateTimeEditorComponent,
      portRenderer: AgGridPortRendererComponent,
      simpleTooltip: AgGridBasicTooltipComponent,
    },
    getRowId: (params) => {
      return params.data.id ? params.data.id.toString() : params.data.unique_row_id || ZERO_STRING;
    },
    defaultColDef: {
      editable: false,
      resizable: true,
    },
    domLayout: 'autoHeight',
    suppressContextMenu: true,
    suppressScrollOnNewData: true,
    singleClickEdit: true,
    rowSelection: 'single',
    animateRows: false,
    suppressAnimationFrame: true,
    suppressColumnMoveAnimation: true,
    rowHeight: STOP_EVENT_ROW_HEIGHT,
    headerHeight: 40,
    autoSizePadding: 0,
    tooltipShowDelay: 0,
    tooltipMouseTrack: true,
  };
  private _events: DealEvent[] = [];
  private _gridApi?: GridApi<DealEvent>;
  private _timeSettings: UserTimeSettings = UserTimeSettings.PortLocalTime;

  constructor(private readonly datePipe: DatePipe) {}

  onGridReady({ api }: GridReadyEvent): void {
    this._gridApi = api;
  }

  dateCellFormatter(date: string, timeZoneOffset?: number): string {
    return (
      this.datePipe.transform(
        moment(date).toISOString(),
        STANDART_TIME_FORMAT,
        getDatePipeTimeZoneFormat(this.timeSettings, timeZoneOffset)
      ) || ''
    );
  }
}
