import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BunkerFinanceListComponent } from './bunker-finance-list.component';
import { AgGridModule } from 'ag-grid-angular';

@NgModule({
  declarations: [BunkerFinanceListComponent],
  imports: [CommonModule, AgGridModule],
  exports: [BunkerFinanceListComponent],
})
export class BunkerFinanceListModule {}
