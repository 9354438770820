import { FormControl } from '@angular/forms';

export enum UserInputDurationType {
  Minutes = 'M',
  Hours = 'H',
  Days = 'D',
}

export interface UserInputDuration {
  system?: number;
  user?: number;
  is_changed?: boolean;
  units?: UserInputDurationType;
}

export interface UserInputDurationFormGroup {
  system: FormControl<number | null>;
  user: FormControl<number | null>;
  is_changed: FormControl<boolean | null>;
  units: FormControl<UserInputDurationType | null>;
}
