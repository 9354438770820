import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  BaseModel,
  Cargo,
  CargoFreightType,
  CargoRules,
  Currency,
  DEFAULT_FIAT_MULTIPLIER,
  NOT_APPLICABLE,
  PriceDto,
  PrimeNgColors,
  UserGuideType,
  ZERO_STRING,
} from '@estimator/models';
import {
  ColDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ICellRendererParams,
  ValueFormatterParams,
} from 'ag-grid-community';
import { cloneDeep, isEqual } from 'lodash';
import { AgGridAutocompleteComponent } from '../ag-grid-shared/ag-grid-autocomplete-editor.component';
import { AgGridHeaderComponent } from '../ag-grid-shared/ag-grid-header.component';
import { AgGridNumericCellEditorComponent } from '../ag-grid-shared/ag-grid-numeric-editor.component';
import { AgGridPriceEditorComponent } from '../ag-grid-shared/ag-grid-price-with-currency-editor.component';
import { AgGridButtonGroupComponent } from '../ag-grid-shared/button-group.component';

@Component({
  selector: 'estimator-cargo-list',
  templateUrl: './cargo-list.component.html',
  styleUrls: ['./cargo-list.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CargoListComponent {
  @Input()
  set cargoes(data: Cargo[]) {
    if (data && data.length) {
      this._cargoes = data;
      this._inputCargoes = cloneDeep(data);
    } else {
      this._cargoes = [];
      this._inputCargoes = [];
    }
  }
  get cargoes(): Cargo[] {
    return this._cargoes;
  }
  @Input()
  set isLoading(bool: boolean) {
    this._isLoading = bool;
    if (this._gridApi && bool) {
      this._gridApi.showLoadingOverlay();
    } else if (this._gridApi && !bool) {
      this._gridApi.hideOverlay();
    }
  }
  get isLoading(): boolean {
    return this._isLoading;
  }
  @Input() cargoRules: CargoRules[] = [];
  @Input() cargoTypes: BaseModel[] = [];
  @Input() cargoFreightType: CargoFreightType[] = [];
  @Input() currencies: Currency[] = [];
  @Input() dimensions: BaseModel[] = [];
  @Output() addCargo = new EventEmitter<void>();
  @Output() updateCargo = new EventEmitter<Cargo>();
  @Output() deleteCargo = new EventEmitter<Cargo>();
  @Output() showTooltip = new EventEmitter<string>();

  columnDefs: ColDef<Cargo>[] = [
    {
      field: 'action',
      headerComponent: 'buttonRenderer',
      headerComponentParams: (params: any) => {
        return {
          ...params,
          buttons: [
            {
              title: 'Add cargo',
              tooltip: 'Add cargo',
              icon: 'add',
              color: PrimeNgColors.White,
              click: () => this.onAddCargo(),
            },
          ],
        };
      },
      cellRenderer: 'buttonRenderer',
      cellRendererParams: (params: ICellRendererParams) => {
        return {
          ...params,
          buttons: [
            {
              title: 'Delete cargo',
              tooltip: 'Delete cargo',
              icon: 'delete',
              click: () => this.onDeleteCargo(params),
            },
          ],
        };
      },
      cellClass: 'center-vertical-aligned-cell',
      minWidth: 40,
      width: 40,
    },
    {
      field: 'freight_type',
      headerComponent: 'header',
      headerComponentParams: () => {
        return {
          title: 'Cargo',
          click: () => this.headerCargoClick(),
        };
      },
      flex: 1,
      editable: true,
      cellEditor: 'autoCompleteEditor',
      cellEditorParams: (params: any) => {
        return {
          ...params,
          dataArray: this.cargoFreightType,
          placeholder: 'Cargo name',
          emptyDataArray: 'No cargo found',
          visibleKeys: ['name'],
          closeEditor: this.closeEditor.bind(this),
        };
      },
      valueFormatter: (params: ValueFormatterParams<Cargo>) => {
        if (params.data?.freight_type?.name) {
          return params.data.freight_type.name;
        } else if (params.data?.cargo_type_id) {
          const type = this.cargoFreightType.find(
            (elem) => elem.id === params.data?.freight_type_id
          );
          if (type) {
            return type.name;
          }
        }
        return NOT_APPLICABLE;
      },
    },
    {
      field: 'internal_order',
      headerName: '#',
      width: 50,
      maxWidth: 50,
      flex: 1,
    },
    {
      field: 'cargo_type',
      headerName: 'Freight type',
      editable: true,
      cellEditor: 'autoCompleteEditor',
      cellEditorParams: (params: any) => {
        return {
          ...params,
          dataArray: this.cargoTypes,
          placeholder: 'Freight type',
          emptyDataArray: 'No type found',
          visibleKeys: ['name'],
          closeEditor: this.closeEditor.bind(this),
        };
      },
      valueFormatter: (params: ValueFormatterParams<Cargo>) => {
        if (params.data?.cargo_type?.name) {
          return params.data.cargo_type.name;
        } else if (params.data?.cargo_type_id) {
          const type = this.cargoTypes.find((elem) => elem.id === params.data?.cargo_type_id);
          if (type) {
            return type.name;
          }
        }
        return NOT_APPLICABLE;
      },
      flex: 1,
    },
    {
      field: 'quantity',
      headerName: 'Quantity',
      editable: true,
      cellEditor: 'numericEditor',
      flex: 1,
    },
    {
      field: 'freight_lumpsum',
      headerName: 'Freight/Lumpsum',
      editable: true,
      cellEditor: 'numericEditor',
      flex: 1,
    },
    {
      field: 'dimension',
      headerName: 'Dimension',
      editable: true,
      cellEditor: 'autoCompleteEditor',
      cellEditorParams: (params: any) => {
        return {
          ...params,
          dataArray: this.dimensions,
          placeholder: 'Dimension',
          emptyDataArray: 'No dimension found',
          visibleKeys: ['name'],
          closeEditor: this.closeEditor.bind(this),
        };
      },
      valueFormatter: (params: ValueFormatterParams<Cargo>) => {
        if (params.data?.dimension?.name) {
          return params.data.dimension.name;
        } else if (params.data?.dimension_id) {
          const type = this.cargoTypes.find((elem) => elem.id === params.data?.dimension_id);
          if (type) {
            return type.name;
          }
        }
        return NOT_APPLICABLE;
      },
      flex: 1,
    },
    {
      field: 'price',
      headerName: 'Price',
      editable: true,
      cellEditor: 'priceEditor',
      cellEditorParams: (params: any) => {
        const cargo: Cargo = params.data;
        params.value = { price: cargo.price, currency: cargo.currency };
        return {
          ...params,
          currencies: this.currencies,
          placeholder: 'Currency',
          emptyDataArray: 'No currency found',
        };
      },
      valueFormatter: (params: ValueFormatterParams<Cargo>) => {
        if (params.data) {
          const cargo: Cargo = params.data;
          if (cargo.currency && typeof cargo.price === 'number') {
            return `${
              (cargo.price || 0) / (cargo.currency?.fiat_multiplier || DEFAULT_FIAT_MULTIPLIER)
            } ${cargo.currency.currency_code}`;
          }
        }
        return NOT_APPLICABLE;
      },
      onCellValueChanged: (event) => {
        const cargo: Cargo = event.data;
        if (event.newValue) {
          const priceDto: PriceDto = event.newValue;
          cargo.price = priceDto.price;
          if (priceDto.currency) {
            cargo.currency = priceDto.currency;
          }
          if (cargo?.currency?.id) {
            cargo.currency_id = cargo.currency.id;
          }
          this._gridApi?.refreshCells();
        }
      },
      flex: 1,
    },
    {
      field: 'voyage_commission',
      headerName: 'VC %',
      editable: true,
      cellEditor: 'numericEditor',
      flex: 1,
    },
  ];
  gridOptions: GridOptions<Cargo> = {
    rowModelType: 'clientSide',
    columnDefs: this.columnDefs,
    components: {
      buttonRenderer: AgGridButtonGroupComponent,
      header: AgGridHeaderComponent,
      autoCompleteEditor: AgGridAutocompleteComponent,
      numericEditor: AgGridNumericCellEditorComponent,
      priceEditor: AgGridPriceEditorComponent,
    },
    getRowId: (params) => {
      return params.data.id ? params.data.id.toString() : params.data.unique_row_id || ZERO_STRING;
    },
    defaultColDef: {
      editable: false,
      resizable: true,
    },
    suppressContextMenu: true,
    suppressScrollOnNewData: true,
    singleClickEdit: true,
    rowSelection: 'single',
    animateRows: true,
    rowHeight: 40,
    headerHeight: 40,
    autoSizePadding: 0,
    domLayout: 'autoHeight',
    onCellEditingStopped: (event) => {
      const cargo = event.data;
      const oldCargo = this._inputCargoes.find((el) => el.id === cargo.id);
      if (cargo.cargo_type) {
        cargo.cargo_type_id = cargo.cargo_type.id;
      }
      if (cargo.freight_type) {
        cargo.freight_type_id = cargo.freight_type.id;
      }
      if (cargo.dimension) {
        cargo.dimension_id = cargo.dimension.id;
      }
      if (cargo.currency) {
        cargo.currency_id = cargo.currency.id;
      }
      if (
        !this._gridApi?.getEditingCells().length &&
        ((event.newValue && event.newValue !== event.oldValue) || !isEqual(cargo, oldCargo))
      ) {
        this.updateCargo.emit(cargo);
      }
    },
  };

  private _cargoes: Cargo[] = [];
  private _inputCargoes: Cargo[] = [];
  private _gridApi?: GridApi<Cargo>;
  private _isLoading = false;

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this._gridApi?.stopEditing(true);
    }
    if (event.key === 'Enter') {
      this._gridApi?.stopEditing();
    }
  }

  onGridReady({ api }: GridReadyEvent): void {
    this._gridApi = api;
  }

  onAddCargo(): void {
    this.addCargo.emit();
  }

  onDeleteCargo(event: ICellRendererParams<Cargo>): void {
    const cargo = event.data;
    if (cargo) {
      if (cargo.id) {
        this.deleteCargo.emit(cargo);
        return;
      }
    }
  }

  closeEditor(): void {
    if (this._gridApi) {
      this._gridApi.stopEditing();
    }
  }

  headerCargoClick() {
    this.showTooltip.emit(UserGuideType.Cargo5);
  }
}
