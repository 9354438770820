<div class="toolbar-container">
  <div class="folder-row">
    <div>
      <p-button
        [icon]="PrimeNgIcons.BARS"
        (click)="onOpenSideBar()"
        pTooltip="Show folders"
        tooltipPosition="bottom"
        id="toolbar_open_sidebar_button"
      />
      <p-button
        [icon]="PrimeNgIcons.PLUS"
        (click)="onAddNewFolder()"
        pTooltip="Add new folder"
        tooltipPosition="bottom"
        id="toolbar_add_new_folder_button"
      />
    </div>
    <div class="ml-2 flex align-items-center" *ngIf="!hideDealsAndFolderMenu">
      <input
        pInputText
        [formControl]="folderNameControl"
        [ngClass]="{ hidden: !isEditingFolderName }"
        [placeholder]="folderNameControl.value"
        [maxlength]="FOLDER_NAME_MAX_LENGTH"
        (focusout)="onChangeFolderName()"
        id="toolbar_folder_name_input"
        #folderNameInput
      />
      <div
        [ngClass]="{ hidden: isEditingFolderName }"
        (dblclick)="onChangeFolderName()"
        class="folder-name"
        pTooltip="Double click to edit"
        tooltipPosition="bottom"
        [positionTop]="-10"
        id="toolbar_folder_name_container"
        #folderName
      >
        {{ folderNameControl.value }}
      </div>
    </div>
    <div class="folder-actions" *ngIf="!hideDealsAndFolderMenu">
      <div class="divider position-relative" *ngIf="showFolderActions && !isFixed">
        <p-button
          class="save-button"
          [icon]="isSaving ? PrimeNgIcons.AnimatedSpinner : PrimeNgIcons.SAVE"
          (click)="onSaveFolder()"
          pTooltip="Folder saved"
          [positionTop]="-8"
          tooltipPosition="bottom"
          [disabled]="true"
          id="toolbar_save_folder_button"
        />
        <span *ngIf="isSaving" class="saved-information position-absolute">Saving folder</span>
      </div>
      <p-button
        *ngIf="showFolderActions && isFixed"
        [icon]="PrimeNgIcons.COPY"
        (click)="onCopyFolder()"
        pTooltip="Copy folder"
        tooltipPosition="bottom"
        [positionTop]="-8"
        id="copy_fixed_folder_button"
      />
      <div class="divider" *ngIf="showFolderActions && openFolderNotDraft">
        <p-button
          [icon]="PrimeNgIcons.LOCK"
          (click)="onFixFolder()"
          pTooltip="Fix folder"
          [positionTop]="-8"
          tooltipPosition="bottom"
          [disabled]="isDisabled"
          id="toolbar_fix_folder_button"
        />
      </div>
      <div class="divider" *ngIf="showFolderActions && isFixed && !isTanker">
        <p-button
          [icon]="PrimeNgIcons.CALENDAR_TIMES"
          (click)="onFixDeal()"
          pTooltip="Fix deal"
          [positionTop]="-8"
          tooltipPosition="bottom"
          id="toolbar_fix_deal_button"
        />
      </div>
      <div class="divider" *ngIf="showFolderActions && isFixed">
        <p-button
          [icon]="PrimeNgIcons.FILE_EDIT"
          (click)="onShowRemarks()"
          pTooltip="Show remarks"
          tooltipPosition="bottom"
          [positionTop]="-8"
          id="toolbar_show_remarks_button"
        />
      </div>
      <p-button
        *ngIf="showFolderActions && !isFixed"
        [icon]="PrimeNgIcons.ELLIPSIS_H"
        (click)="onShowMenu($event)"
        pTooltip="Folder menu"
        tooltipPosition="bottom"
        [positionTop]="-8"
        id="toolbar_folder_menu_button"
        #folderMenuContainer
      />
      <p-button
        *ngIf="showFolderActions && isFixed"
        [icon]="PrimeNgIcons.TIMES"
        (click)="closeFolderInCurrentEstimation.emit()"
        pTooltip="Close folder"
        tooltipPosition="bottom"
        [positionTop]="-8"
        id="toolbar_folder_close_folder_button"
      />

      <p-menu
        [model]="folderMenuItems"
        [popup]="true"
        (onHide)="onBlurMenu()"
        styleClass="toolbar-menu"
        #folderMenu
      />
    </div>
  </div>
  <div class="deal-row" *ngIf="!hideDealsAndFolderMenu">
    <div class="deal-list" (scroll)="dropdownDealsMenu?.hide()" id="toolbar_deal_list" #dealList>
      <ng-container *ngFor="let deal of estimationsList; let i = index">
        <div *ngIf="deal.id" (onDrop)="onDragEnd(i)" pDroppable>
          <a
            *ngIf="editingDeal?.id !== deal.id; else editDealName"
            [ngClass]="{ active: isDealSelected(deal) }"
            (click)="onSelectDeal(DealAction.NAVIGATE, deal, $event)"
            (dblclick)="onChangeDealName(deal)"
            (onDragStart)="onDragStart(i)"
            class="deal-item"
            dragEffect="copy"
            pDraggable
            id="deal_navigation_link_{{ i }}"
          >
            <div class="deal-info">
              <h2 class="deal-title">
                {{ deal.name }}
              </h2>
              <span class="deal-subtitle" *ngIf="isVesselSetted(deal)">
                <i [class]="PrimeNgIcons.Vessel"></i>
                {{ deal.vesselName }}
              </span>
            </div>
            <div class="deal-actions">
              <i
                [class]="PrimeNgIcons.COPY"
                [ngClass]="{ disabled: isDisabled }"
                (click)="onSelectDeal(DealAction.COPY, deal)"
                id="copy_in_current_folder_{{ i }}"
              ></i>
              <i
                [class]="PrimeNgIcons.TRASH"
                [ngClass]="{ disabled: isDisabled }"
                (click)="onSelectDeal(DealAction.DELETE, deal)"
                id="delete_deal_{{ i }}"
              ></i>
              <i
                [class]="PrimeNgIcons.ELLIPSIS_H"
                [ngClass]="{ disabled: isDisabled }"
                (click)="onSelectDeal(DealAction.SHOW_MENU, deal, $event)"
                id="toolbar_deal_menu_{{ i }}"
              ></i>
            </div>
          </a>
          <p-menu
            [model]="dealMenuItems"
            [popup]="true"
            (onHide)="onBlurMenu()"
            styleClass="toolbar-menu"
            #dealMenu
          />
        </div>
      </ng-container>
      <ng-template #editDealName>
        <div class="deal-name-editing">
          <input
            pInputText
            [formControl]="dealNameControl"
            (focusout)="onChangeDealName(null)"
            placeholder="Deal name"
            estimatorAutosizeInputFont
            #dealNameInput
            id="deal_name_editing_input"
          />
        </div>
      </ng-template>
    </div>
    <p-button
      [icon]="PrimeNgIcons.PLUS"
      (click)="onAddNewDeal()"
      class="mr-auto"
      pTooltip="Add new deal"
      tooltipPosition="bottom"
      [positionTop]="-8"
      styleClass="border-right-none"
      id="toolbar_add_new_deal_button"
    />
    <div class="deals-dropdown" *ngIf="isShowDropdownDeals">
      <p-button
        styleClass="deals-dropdown-button"
        [icon]="PrimeNgIcons.ANGLE_DOWN"
        (click)="onShowAllDeals($event)"
      />
      <p-menu
        [model]="unvisibleDealsList"
        [popup]="true"
        styleClass="toolbar-menu deals-dropdown-toolbar-menu left-auto right-0"
        #dropdownDealsMenu
      >
        <ng-template pTemplate="item" let-deal>
          <div class="deal-list deals-dropdown">
            <a class="deal-item" (click)="onSelectDealInDropDown(deal, $event)">
              <div class="deal-info">
                <h2 class="deal-title">
                  {{ getField(deal, 'name') }}
                </h2>
                <span class="deal-subtitle" *ngIf="isVesselSetted(deal)">
                  <i [class]="PrimeNgIcons.Vessel"></i>
                  {{ getField(deal, 'vesselName') }}
                </span>
              </div>
              <div class="deal-actions">
                <i
                  [class]="PrimeNgIcons.COPY"
                  [ngClass]="{ disabled: isDisabled || eventsFormInvalid }"
                  (click)="onSelectDeal(DealAction.COPY, deal)"
                ></i>
                <i
                  [class]="PrimeNgIcons.TRASH"
                  [ngClass]="{ disabled: isDisabled }"
                  (click)="onSelectDeal(DealAction.DELETE, deal)"
                ></i>
              </div>
            </a>
          </div>
        </ng-template>
      </p-menu>
    </div>
  </div>
</div>
