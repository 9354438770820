import { FEET_COEFFICIENT, FEET_TO_METERS_CONVERSION_FACTOR } from '@estimator/models';
import { divide, multiply } from 'lodash';

export const cubicMetersToFeet = (mt: number): number | null => {
  if (mt) {
    return multiply(mt, FEET_COEFFICIENT);
  }
  return null;
};

export const feetToCubicMeters = (ft: number): number | null => {
  if (ft) {
    return divide(ft, FEET_COEFFICIENT);
  }
  return null;
};


/** Convert feet to meters */
export function feetToMeters(feet: number): number {
  return multiply(feet, FEET_TO_METERS_CONVERSION_FACTOR);
}

/** Convert meters to feet */
export function metersToFeet(meters: number): number {
  return divide(meters, FEET_TO_METERS_CONVERSION_FACTOR);
}
