import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CargoCalculator } from '@estimator/models';
import { BaseService } from '../base';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CargoCalculatorService extends BaseService<CargoCalculator> {
  constructor(protected override readonly http: HttpClient) {
    super(http);
    this.setPath('/api/cargo-calculator');
  }

  getConstants(vessel_id: number, current_calculator_id: number): Observable<CargoCalculator> {
    let params = new HttpParams();
    params = params.set('vessel_id', vessel_id);
    params = params.set('current_calculator_id', current_calculator_id);
    return this.http.get(`${this.path}/last-constants`, { params });
  }

  updateGroup(cargoes: CargoCalculator[]): Observable<CargoCalculator[]> {
    return this.http.put<CargoCalculator[]>(`${this.path}`, cargoes);
  }
}
