import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'mar-logo',
  template: `
    <a [routerLink]="link" [ngClass]="{ 'no-link': link === '#' }">
      <svg
        [attr.width]="width"
        viewBox="0 0 115 32"
        [attr.fill]="color"
        xmlns="http://www.w3.org/2000/svg"
        #logoImg
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.48687 15.03L0 5.54307L5.54306 0L15.0299 9.48689C18.627 13.084 18.627 18.916 15.0299 22.5131L5.54306 32L0 26.4569L9.48687 16.97C10.0226 16.4343 10.0226 15.5657 9.48687 15.03Z"
        />
        <path
          d="M28.9345 0L34.4775 5.54307L28.8432 11.1774V11.1774C27.0378 12.9828 24.5952 14.0063 22.0421 14.0271L20.4373 14.0402L20.4504 12.4355C20.4713 9.88236 21.4948 7.43973 23.3001 5.63435V5.63435L28.9345 0Z"
        />
        <path
          d="M28.9345 31.9443L34.4775 26.4013L28.8432 20.7669V20.7669C27.0378 18.9615 24.5952 17.9381 22.0421 17.9172L20.4373 17.9041L20.4504 19.5089C20.4713 22.062 21.4948 24.5046 23.3001 26.31V26.31L28.9345 31.9443Z"
        />
        <path
          d="M42.8359 26.1501H47.3504V15.8183L47.1989 12.6369H47.5019L48.7138 15.8183L52.7738 24.9381H58.2881L62.3481 15.8183L63.56 12.6369H63.8933L63.7115 15.8183V26.1501H68.2563V5.8501H62.1057L57.6518 16.0304L55.8036 20.9387H55.4704L53.5616 16.0304L48.9865 5.8501H42.8359V26.1501Z"
        />
        <path
          d="M88.3242 26.1501H93.5961L84.9005 5.8501H78.3258L69.6604 26.1501H74.8111L76.6593 21.6659H86.5063L88.3242 26.1501ZM78.3561 17.5756L80.2951 12.849L81.4465 10.001H81.7798L82.9008 12.849L84.8399 17.5756H78.3561Z"
        />
        <path
          d="M95.0609 26.1501H99.6056V18.6057H102.908C105.059 18.6057 105.696 19.3329 106.574 21.0599L109.21 26.1501H114.391L111.755 20.969C110.968 19.3329 110.18 18.2422 108.241 17.6665V17.3635C111.937 17.0302 113.694 15.1214 113.694 11.9098C113.694 8.03158 110.877 5.8501 105.999 5.8501H95.0609V26.1501ZM99.6056 15.182V9.94039H106.241C108.362 9.94039 109.089 10.607 109.089 12.5461C109.089 14.6063 108.362 15.182 106.241 15.182H99.6056Z"
        />
      </svg>
    </a>
  `,
  styles: [
    `
      @import 'variables';
      :host {
        display: inline-block;
        .no-link {
          pointer-events: none;
          cursor: default;
        }
        .theme-light {
          fill: $primary;
        }
      }
    `,
  ],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoComponent {
  @ViewChild('logoImg') logoImg!: ElementRef;
  @Input() color = '#fff';
  @Input() link = '#';
  @Input() width = 115;
  @Input() set currentTheme(currentTheme: string) {
    if (this.logoImg) {
      this.logoImg.nativeElement.classList = '';
      this.logoImg.nativeElement.classList.add(currentTheme);
    }
  }
}
