import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  Cargo,
  DEFAULT_FIAT_MULTIPLIER,
  DaysDistanceIndicators,
  DealEvent,
  DealFinance,
  DealFormHasChanges,
  FuelConsumption,
  TEXT_EUA_CO2_24,
  THOUSAND,
} from '@estimator/models';
import { Subject } from 'rxjs';

@Component({
  selector: 'estimator-co2-costs',
  templateUrl: './co2-costs.component.html',
  styleUrls: ['./co2-costs.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Co2CostsComponent implements /* OnInit */ OnDestroy {
  @Input() set events(events: DealEvent[]) {
    if (events?.length) {
      this._events = events;
      this.co2Costs = this.calculateCo2Costs();
    }
  }
  get events(): DealEvent[] {
    return this._events;
  }
  @Input() set finances(finances: DealFinance) {
    this._finances = finances;
  }

  get finances(): DealFinance {
    return this._finances || {};
  }

  @Input()
  set daysDistanceIndicators(daysDistanceIndicators: DaysDistanceIndicators) {
    if (daysDistanceIndicators) {
      this._daysDistanceIndicators = daysDistanceIndicators;
    }
  }
  get daysDistanceIndicators(): DaysDistanceIndicators {
    return this._daysDistanceIndicators;
  }

  @Input() set cargoes(cargoes: Cargo[]) {
    if (cargoes.length) {
      let totalMT = 0;
      cargoes.forEach((cargo) => (totalMT += cargo?.quantity || 0));
      this.totalCargoesMT = totalMT;
    }
  }

  @Output() hasChanges = new EventEmitter<DealFormHasChanges>();
  totalCargoesMT = 0;
  // fg: FormGroup = this.makeCO2FormGroup();
  co2Costs: { ladden: number; ballast: number } = { ladden: 0, ballast: 0 };
  readonly DEFAULT_FIAT_MULTIPLIER = DEFAULT_FIAT_MULTIPLIER;
  readonly THOUSAND = THOUSAND;
  readonly TEXT_EUA_CO2_24 = TEXT_EUA_CO2_24;
  private _events: DealEvent[] = [];
  private _finances?: DealFinance;
  private _onDestroy$ = new Subject<void>();
  private _daysDistanceIndicators = {};

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  /*   ngOnInit(): void {
    this.fg.valueChanges.pipe(takeUntil(this._onDestroy$), debounceTime(500)).subscribe((res) => {
      console.log('co2 fg emit changes', res);
      this.hasChanges.emit({ change: true, estimate: true });
    });
  } */

  calculateCo2Costs(): { ladden: number; ballast: number } {
    let ladden = 0,
      ballast = 0;
    this.events.forEach((event: DealEvent) => {
      if (event?.meta?.fuel_consumptions?.length) {
        event?.meta?.fuel_consumptions?.forEach((fuelConsumption: FuelConsumption) => {
          event?.meta?.is_laden
            ? (ladden += fuelConsumption?.co2_amount || 0)
            : (ballast += fuelConsumption?.co2_amount || 0);
        });
      }
    });
    return { ladden, ballast };
  }

  /*   makeCO2FormGroup(): FormGroup {
    const fg = new FormGroup({
      co2_price: new FormControl(this.finances.co2_price || null),
    });
    return fg;
  } */

  /*   prepareCO2ToSave() {
    return this.fg.value.co2_price * DEFAULT_FIAT_MULTIPLIER;
  } */
}
