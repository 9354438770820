import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[estimatorZeroDecimals]',
})
export class ZeroDecimalDirective {
  private _regex = new RegExp(/^\d/g);
  private specialKeys: Array<string> = [
    'Backspace',
    'Tab',
    'End',
    'Home',
    '-',
    'ArrowLeft',
    'ArrowRight',
    'Del',
    'Delete',
  ];

  constructor(private el: ElementRef) {}
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    const current = this.el.nativeElement.value;
    const position = this.el.nativeElement.selectionStart;
    const next: string = [
      current.slice(0, position || String(current).length),
      current.slice(position || String(current).length),
    ].join('');
    if (next && !String(next).match(this._regex)) {
      event.preventDefault();
    }
  }
}
