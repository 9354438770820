import { HttpErrorResponse } from '@angular/common/http';
import { FormControl, FormGroup } from '@angular/forms';
import {
  BaseEntity,
  BaseEntityFormGroup,
  CASCADE_NODE,
  Cargo,
  CargoCalculator,
  CargoCalculatorForm,
  CascadeNavigation,
  DataFolderOrDeal,
  Deal,
  DealNavigationLink,
  DomEvents,
  EMPTY_LIST_TITLE,
  ESTIMATOR_TANKER_ROUTE,
  FilterType,
  Folder,
  FolderFilterModel,
  FolderFilterType,
  GrainCalculationType,
  HttpStatus,
  IS_COPY_ALIEN_DEAL_KEY,
  IS_COPY_ALIEN_FOLDER_KEY,
  LIST_TITLE,
  LoadPortDraftRestrictionUnitsType,
  MINUTES_IN_DAY,
  MINUTES_IN_HOUR,
  MemberRole,
  NOT_APPLICABLE,
  STARTED_NUMBER_OF_NAV_LINKS,
  StartFilterModel,
  StowageFactorUnitsType,
  THOUSAND,
  User,
  UserInputDurationType,
  Vessel,
  VesselForm,
  VoyageType,
  WorkType,
  defaultNavigations,
  objectFolderFilterType,
} from '@estimator/models';
import {
  AgreementType,
  FileTreeModel,
  SystemFolderType,
  SystemFolderTypeText,
  VMSNodeType,
} from '@vms-models';
import { ISimpleFilterModelType } from 'ag-grid-community/dist/lib/filter/provided/simpleFilter';
import { cloneDeep, filter, isEqual, isNil, isNumber, isObject, keys, omitBy, union } from 'lodash';
import moment from 'moment';
import {
  textCompanyIdField,
  textCreatedBy,
  textFolderName,
  textNameDeal,
  textVessel,
  textVesselName,
  textVoyageType,
} from './ag-grid-helpers';
import { cubicMetersToFeet } from './units-helper';
import { deductiblesZeroNullValidator } from './validators';
import { getSystemFolderTypeForSub, isShowTwoNodeFiles } from './vms-helpers';

export const compareObjects = (o1: any, o2: any): boolean => {
  if (o1?.id || o2?.id) {
    return o1?.id === o2?.id;
  }
  return o1 == o2;
};

export const extractError = (err: any): string => {
  let message = '';
  if (err?.status == HttpStatus.Unauthorized) {
    message = 'Invalid credentials';
  } else if (err?.error?.error) {
    message = err.error.error;
  } else if (err?.message) {
    message = err.message;
  }
  return message;
};

export const vesselWasUpdated = (vesselName: string) => {
  return `Vessel ${vesselName} was updated`;
};

/* export const isFirstElement = (i: number): boolean => {
  const firstSecondThirdArr = [0];
  return firstSecondThirdArr.includes(i);
};

export const isFirstOrSecondOrThirdElement = (i: number): boolean => {
  const firstSecondThirdArr = [0, 1, 2];
  return firstSecondThirdArr.includes(i);
}; */

export const fromMillisecondsToSeconds = (milliseconds: number): number => {
  return milliseconds / THOUSAND;
};

export const makeBaseEntityPartFormGroup = (
  entity?: BaseEntity
): FormGroup<BaseEntityFormGroup> => {
  return new FormGroup<BaseEntityFormGroup>({
    id: new FormControl<number>(entity?.id || 0, { nonNullable: true }),
    created_at: new FormControl(entity?.created_at || null),
    created_by: new FormControl(entity?.created_by || null),
    updated_at: new FormControl(entity?.updated_at || null),
    updated_by: new FormControl(entity?.updated_by || null),
    deleted_at: new FormControl(entity?.deleted_at || null),
    deleted_by: new FormControl(entity?.deleted_by || null),
  });
};

export const isNullOrZero = (value: any): boolean => {
  return isNil(value) || value === '' || (isObject(value) && !Object.keys(value).length);
};

export const isFalseOrNole = (value: any): boolean => {
  return value === 0 || value === false;
};

/* export const isNullOrZeroNumber = (value: number): boolean => {
  return isNil(value) || value === 0;
}; */

export const changedKeys = (o1: any, o2: any): string[] => {
  const k = union(keys(o1), keys(o2));
  return filter(k, function (key: any) {
    const desO1 = o1?.[key];
    const desO2 = o2?.[key];
    return !!(desO1 && desO2 && isEqual(desO1, desO2));
  });
};

export const fillObjectIfFieldNotExist = (target: any, source: any): void => {
  if (target && source) {
    const keys = Object.keys(source);
    keys.forEach((key) => {
      if (target) {
        const oldKeyValue = source[key];
        if (typeof oldKeyValue === 'object' && typeof target[key] === 'object') {
          fillObjectIfFieldNotExist(target[key], oldKeyValue);
        }
        if (
          !target[key] &&
          !isFalseOrNole(target[key]) &&
          (oldKeyValue || isFalseOrNole(oldKeyValue))
        ) {
          target[key] = oldKeyValue;
        }
      }
    });
  }
};

export const omitObject = (object: any): any => {
  object = omitBy(object, isNullOrZero);
  if (object && typeof object === 'object') {
    const keys = Object.keys(object);
    keys.forEach((key) => {
      const prop = object[key];
      if (prop && typeof prop === 'object') {
        if (Object.keys(object[key]).length) {
          omitObject(prop);
        } else {
          delete object[key];
        }
      } else if (prop && Array.isArray(prop)) {
        prop.forEach((p) => omitObject(p));
        if (!prop.length) {
          delete object[key];
        }
      } else if (!prop && !isFalseOrNole(prop)) {
        delete object[key];
      }
    });
  }
  object = omitBy(object, isNullOrZero);
  return object;
};

export const mapUsers = (item: {
  id?: number;
  email?: string;
}): [number | undefined, string | undefined] => [item.id, item.email];

export const checkLinkTanker = (link: string) => {
  return link.includes(ESTIMATOR_TANKER_ROUTE); // link === ESTIMATOR_TANKER_ROUTE;
};

/* export const prepareDealBeforeSave = (deal: Deal): Deal => {
   if (deal?.finances?.hire_finance?.hire_per_day) {
    deal.finances.hire_finance.hire_per_day *= DEFAULT_FIAT_MULTIPLIER;
  }
  if (deal?.finances?.hire_finance?.ballast_bonus) {
    deal.finances.hire_finance.ballast_bonus *= DEFAULT_FIAT_MULTIPLIER;
  }

    deal?.events?.forEach((event) => {
    if (event?.meta?.disbursement) {
      event.meta.disbursement = convertCurrencyToServerValue(
        event?.meta?.disbursement,
        event.meta.disbursement_currency?.fiat_multiplier || DEFAULT_FIAT_MULTIPLIER
      );
    }
  });
  deal?.sundries?.forEach((sundry) => {
    sundry.amount = convertCurrencyToServerValue(
      sundry.amount,
      sundry.currency?.fiat_multiplier || DEFAULT_FIAT_MULTIPLIER
    );
  });
  return deal;
}; */

export const prepareFilterModel = (
  filterModel: any,
  forFoldersGrid = false
): FolderFilterModel[] => {
  const filterArray: FolderFilterModel[] = [];
  for (const key in filterModel) {
    const value = filterModel[key];
    if (value.filterType === FilterType.SET) {
      value.values.forEach((value: any) => {
        filterArray.push({
          field:
            key === textCreatedBy && forFoldersGrid ? /* 'folder_created_by' */ textCreatedBy : key,
          value: value,
          filter_type: FolderFilterType.EQUALS,
        });
      });
    } else {
      filterArray.push({
        field:
          key === textVessel
            ? textVesselName
            : key === textNameDeal
            ? 'name'
            : key === textCompanyIdField
            ? 'company_name'
            : key === 'name'
            ? textFolderName
            : key,
        value: value.filter,
        filter_type: objectFolderFilterType[value.type as ISimpleFilterModelType],
      });
    }
  }
  return filterArray;
};

export const initVoyageTypeFilter = (activeLink: string, startFilterModel: StartFilterModel) => {
  if (checkLinkTanker(activeLink)) {
    startFilterModel[textVoyageType] = {
      values: [VoyageType.Tanker],
      filterType: FilterType.SET,
    };
  } else {
    startFilterModel[textVoyageType] = {
      values: [VoyageType.Bulk],
      filterType: FilterType.SET,
    };
  }
};

export const initCascadeNavigation = (
  navigation: CascadeNavigation[],
  countForDropdownCascades: number
) => {
  // делаем проверку на list и navigation items (по длине линков)
  const countDropdownCascades = Math.ceil(countForDropdownCascades / CASCADE_NODE);
  for (let i = 0; i < countDropdownCascades; i++) {
    navigation.push(cloneDeep(defaultNavigations[i])); // cloneDeep нужен, чтобы обнулялись внутренние массивы
  }
};

export const prepareCascadeNavigation = (
  index: number,
  dealItem: Deal,
  neededLink: string,
  navigation: CascadeNavigation[]
) => {
  const needIndex = Math.floor(index / CASCADE_NODE);
  const deal: Deal = dealItem;
  const link = `${neededLink}/${deal.id}`;
  const name = deal.name;
  const newLink: DealNavigationLink = generateDealNavigationLink(link, name, deal);
  navigation[needIndex].subnavs.push(newLink);
};

export const getStartedEstimationsNavigationLinks = (neededLink: string): DealNavigationLink[] => {
  return [
    /* { link: neededLink, name: LIST_TITLE }, */
    { link: neededLink, name: EMPTY_LIST_TITLE },
  ];
};

export const emptyFileTreeModel = (): FileTreeModel => {
  return {
    nodes: [],
  };
};

// memo
// TCIN, TCT, TCOUT, Operational(isShowTwoNodeFiles) => show 2: recaps + certificates
// CR => show 2: Recap charterer, Recap owner (2 components vms-handover-modal)
// VC => show 1: recaps
// Own => show 1: certificates
export const prefilledFileTreeModel = (
  agreementType: AgreementType,
  addCR = false
): FileTreeModel => {
  if (isShowTwoNodeFiles(agreementType)) {
    return {
      nodes: [
        {
          system_folder_type: SystemFolderType.RECAPS_TOP,
          name: `${SystemFolderTypeText.RECAPS}`,
          type: VMSNodeType.SYSTEM_FOLDER,
          children: [],
        },
        {
          system_folder_type: SystemFolderType.CERTIFICATES_TOP,
          name: `${SystemFolderTypeText.CERTIFICATES}`,
          type: VMSNodeType.SYSTEM_FOLDER,
          children: [],
        },
      ],
    };
  } else {
    let neededName: string;
    if (agreementType === AgreementType.VC) {
      neededName = SystemFolderTypeText.RECAPS;
    } else if (agreementType === AgreementType.VR && addCR) {
      neededName = 'Recap charterer';
    } else if (agreementType === AgreementType.VR) {
      neededName = 'Recap owner';
    } else {
      neededName = SystemFolderTypeText.CERTIFICATES;
    }

    const neededType: SystemFolderType = getSystemFolderTypeForSub(agreementType, addCR);
    return {
      nodes: [
        {
          system_folder_type: neededType,
          name: `${neededName}`,
          type: VMSNodeType.SYSTEM_FOLDER,
          children: [],
        },
      ],
    };
  }
};

export const generateDealNavigationLink = (
  link: string,
  name: string,
  deal: Deal
): DealNavigationLink => {
  return {
    link: link,
    name: name,
    id: deal.id,
    vesselName: deal.vessel?.name || NOT_APPLICABLE,
    vesselId: deal.vessel?.id || 0,
    folderId: deal.folder_id,
    is_archived: !!deal?.is_archived,
  };
};

export const getNavigationFromEstimationsNavigationLinks = (
  estimationsNavigationLinks: DealNavigationLink[]
): CascadeNavigation[] => {
  const navigation: CascadeNavigation[] = [];
  // 2 аргумент - условие для по сути пустой генерации, при удалении линка
  initCascadeNavigation(
    navigation,
    estimationsNavigationLinks.length > STARTED_NUMBER_OF_NAV_LINKS
      ? getFilterEstimationsNavigationLinks(estimationsNavigationLinks).length
      : 0
  );
  // убираем list и navigation items = getFilterEstimationsNavigationLinks
  getFilterEstimationsNavigationLinks(estimationsNavigationLinks).forEach((link, index) => {
    const needIndex = Math.floor(index / CASCADE_NODE);
    navigation[needIndex].subnavs.push(link);
  });
  return navigation;
};

export const getFilterEstimationsNavigationLinks = (
  estimationsNavigationLinks: DealNavigationLink[]
): DealNavigationLink[] => {
  return estimationsNavigationLinks.filter((link) => linkNotEqualListOrEmptyList(link));
};

export const linkNotEqualListOrEmptyList = (link: DealNavigationLink): boolean => {
  return link.name !== EMPTY_LIST_TITLE && link.name !== LIST_TITLE;
};

export const canOpenOrCreateDeal = (
  estimationsNavigationLinks: DealNavigationLink[],
  folderId: number
): boolean => {
  return estimationsNavigationLinks?.length > STARTED_NUMBER_OF_NAV_LINKS
    ? !!estimationsNavigationLinks?.find((link) => link?.folderId === folderId)
    : true;
};

export const getValueOrDefault = (value: number | undefined) => {
  return value ? value : 0;
};

export const getValueOrNull = (number: number | undefined) => {
  return number || number === 0 ? number : null;
};

export const stayOnlyMyFolders = (folders: Folder[], userId: number): Folder[] => {
  return folders.filter((folder) => folder.created_by === userId);
};

export const getDealsIds = (estimationsNavigationLinks: DealNavigationLink[]): number[] => {
  return estimationsNavigationLinks.reduce((acc: number[], item) => {
    const id = item.id;
    if (id) {
      acc.push(id);
    }
    return acc;
  }, []);
};

export const getTimeNowString = (): string => {
  return moment().format('DD/MM HH:mm:ss');
};

export const clearCopyAlienLocalStorageKeys = (): void => {
  localStorage.removeItem(IS_COPY_ALIEN_DEAL_KEY);
  localStorage.removeItem(IS_COPY_ALIEN_FOLDER_KEY);
};

export const makeCargoCalculationFormGroup = (
  data?: CargoCalculator,
  grainBaleCalculationType: GrainCalculationType = GrainCalculationType.GrainCalculationType,
  noConvertStowageFactorFt = false
): FormGroup<CargoCalculatorForm> => {
  return new FormGroup(
    {
      ...makeBaseEntityPartFormGroup(data).controls,
      bunker_weight: new FormControl(data?.bunker_weight || 0),
      constant_weight: new FormControl(data?.constant_weight || 0),
      consumption_days: new FormControl(data?.consumption_days || 0),
      consumption_per_day: new FormControl(data?.consumption_per_day || 0),
      discharge_port_draft_restriction: new FormControl(
        data?.discharge_port_draft_restriction || 0
      ),
      dwt: new FormControl(data?.dwt || 0),
      fresh_water: new FormControl(data?.fresh_water || 0),
      grain_volume: new FormControl(data?.grain_volume || 0),
      bale_volume: new FormControl(data?.bale_volume || 0),
      grain_volume_ft: new FormControl(cubicMetersToFeet(data?.grain_volume || 0)),
      bale_volume_ft: new FormControl(cubicMetersToFeet(data?.bale_volume || 0)),
      load_port_draft_restriction: new FormControl(data?.load_port_draft_restriction || 0),
      load_port_draft_restriction_ft: new FormControl(data?.load_port_draft_restriction_ft || 0),
      load_port_draft_restriction_units_type: new FormControl(
        data?.load_port_draft_restriction_units_type || LoadPortDraftRestrictionUnitsType.Meters
      ),
      load_port_draft_start: new FormControl(data?.load_port_draft_start || 0),
      max_available_tonnage_quantity: new FormControl(data?.max_available_tonnage_quantity || 0),
      max_available_tonnage_discharge_quantity: new FormControl(
        data?.max_available_tonnage_discharge_quantity || 0
      ),
      max_discharge_port_draft_quantity: new FormControl(
        data?.max_discharge_port_draft_quantity || 0
      ),
      max_dwt_quantity: new FormControl(data?.max_dwt_quantity || 0),
      max_load_port_quantity: new FormControl(data?.max_load_port_quantity || 0),
      max_grain_volume_quantity: new FormControl(data?.max_grain_volume_quantity || 0),
      max_bale_volume_quantity: new FormControl(data?.max_bale_volume_quantity || 0),
      other_weight: new FormControl(data?.other_weight || 0),
      permanent_ballast: new FormControl(data?.permanent_ballast || 0),
      stowage_factor: new FormControl(data?.stowage_factor || 0),
      stowage_factor_ft: new FormControl(
        noConvertStowageFactorFt
          ? data?.stowage_factor_ft || 0
          : cubicMetersToFeet(data?.stowage_factor || 0)
      ),
      stowage_factor_units_type: new FormControl(
        data?.stowage_factor_units_type || StowageFactorUnitsType.StowageFactorCBM
      ),
      total_consumption: new FormControl(data?.total_consumption || 0),
      tpc: new FormControl(data?.tpc || 0),
      vessel_max_draft: new FormControl(data?.vessel_max_draft || 0),
      discharge_port_water_type: new FormControl(data?.discharge_port_water_type || null),
      load_port_water_type: new FormControl(data?.load_port_water_type || null),
      discharge_port_season_type: new FormControl(data?.discharge_port_season_type || null),
      load_port_season_type: new FormControl(data?.load_port_season_type || null),
      // id: new FormControl(data?.id || 0),
      summ_max_fields: new FormControl({ value: getMinValueFromFields(data), disabled: true }),
      deductibles: new FormControl(data?.deductibles || 0),
      grain_bale_calculation_type: new FormControl(
        data?.grain_bale_calculation_type ||
          grainBaleCalculationType ||
          GrainCalculationType.GrainCalculationType
      ),
    },
    {
      updateOn: DomEvents.Blur,
      validators: deductiblesZeroNullValidator('deductibles', [
        'bunker_weight',
        'permanent_ballast',
        'fresh_water',
        'constant_weight',
        'other_weight',
      ]),
    }
  );
};

export const getMinValueFromFields = (cargoCalc?: CargoCalculator): number => {
  const startedValues: number[] = [cargoCalc?.max_dwt_quantity || 0];
  switch (cargoCalc?.grain_bale_calculation_type) {
    case GrainCalculationType.GrainCalculationType:
      startedValues.push(cargoCalc?.max_grain_volume_quantity || 0);
      break;
    case GrainCalculationType.BaleCalculationType:
      startedValues.push(cargoCalc?.max_bale_volume_quantity || 0);
      break;
    case GrainCalculationType.BothCalculationType:
      startedValues.push(cargoCalc?.max_grain_volume_quantity || 0);
      startedValues.push(cargoCalc?.max_bale_volume_quantity || 0);
      break;
  }
  const values = startedValues.reduce((acc: number[], value) => {
    if (isNumber(value) && value) {
      acc.push(value);
    }
    return acc;
  }, []);
  // делаем исключение для 1 поля из 4 - Max quantity (summer draft) в cargo-calc - его 0 показываем
  values.push(cargoCalc?.max_available_tonnage_quantity || 0);
  return values?.length > 0 ? Math.min(...values) : 0;
};

export const mapStringNumber = (item: {
  name?: string;
  id?: number;
}): [string | undefined, number | undefined] => [item.name, item.id];

export const getTimeInMinutes = (
  ldRate: number,
  type?: WorkType,
  cargoQuantity?: number
): number => {
  switch (type) {
    case WorkType.AmountPerDay: {
      if (cargoQuantity && ldRate) {
        return (cargoQuantity / ldRate) * MINUTES_IN_DAY;
      }
      return 0;
    }
    case WorkType.AmountPerHour: {
      if (cargoQuantity && ldRate) {
        return (cargoQuantity / ldRate) * MINUTES_IN_HOUR;
      }
      return 0;
    }
    case WorkType.Days: {
      return (ldRate || 0) * MINUTES_IN_DAY;
    }
    case WorkType.Hours: {
      return (ldRate || 0) * MINUTES_IN_HOUR;
    }
    default: {
      return 0;
    }
  }
};

export const transformFromUserInputDurationTypeToWorkType = (
  userInputDurationType: UserInputDurationType
): WorkType => {
  switch (userInputDurationType) {
    case UserInputDurationType.Days: {
      return WorkType.Days;
    }
    case UserInputDurationType.Hours: {
      return WorkType.Hours;
    }
    case UserInputDurationType.Minutes: {
      return WorkType.Hours;
    }
  }
};

export const transformValueToUserInputDurationType = (
  value: number,
  units: UserInputDurationType,
  needTransformValueFromMinutesToHours = false
) => {
  switch (units) {
    case UserInputDurationType.Days: {
      return value / MINUTES_IN_DAY;
    }
    case UserInputDurationType.Hours: {
      return value / MINUTES_IN_HOUR;
    }
    default: {
      // if needTransformValueFromMinutesToHours = true, then minutes=>hours, because WorkType doesn't have Minutes option
      const finalValue = needTransformValueFromMinutesToHours ? value / MINUTES_IN_HOUR : value;
      return finalValue;
    }
  }
};

// after use this function always must add dwt
export const prepareDataBeforeUpdateCargoCalc = (
  cargo: Cargo,
  grainBaleCalculationType?: GrainCalculationType,
  vessel?: Vessel,
  vesselFormGroup?: FormGroup<VesselForm>
) => {
  const vesselSummerDraft = vesselFormGroup?.controls?.draught.value || vessel?.draught;
  if (
    cargo?.cargo_calculator &&
    /* !cargo.cargo_calculator?.vessel_max_draft && */
    vesselSummerDraft
  ) {
    cargo.cargo_calculator.vessel_max_draft = vesselSummerDraft;
  }
  const vesselTPC = vesselFormGroup?.controls?.tpc.value || vessel?.tpc;
  if (cargo?.cargo_calculator /* && !cargo.cargo_calculator?.tpc */ && vesselTPC) {
    cargo.cargo_calculator.tpc = vesselTPC;
  }

  const bale = vesselFormGroup?.controls?.bale.value || vessel?.bale;
  if (cargo?.cargo_calculator /*  && !cargo.cargo_calculator?.bale_volume */ && bale) {
    cargo.cargo_calculator.bale_volume = bale;
  }

  const grain = vesselFormGroup?.controls?.grain.value || vessel?.grain;
  if (cargo?.cargo_calculator /* && !cargo.cargo_calculator?.grain_volume */ && grain) {
    cargo.cargo_calculator.grain_volume = grain;
  }

  const deductibles = vesselFormGroup?.controls?.constants?.controls?.deductibles?.value;
  if (cargo?.cargo_calculator && deductibles) {
    cargo.cargo_calculator.deductibles = deductibles;
  }

  /* const grainBaleCalculationType =
    userSettingsFormGroup.value.default_grain_bale_calculation_type; */
  if (
    cargo?.cargo_calculator &&
    !cargo.cargo_calculator.grain_bale_calculation_type &&
    grainBaleCalculationType
  ) {
    cargo.cargo_calculator.grain_bale_calculation_type = grainBaleCalculationType;
  }
};

export const getDataFolderOrDeal = (
  link: DealNavigationLink | null,
  folder: Folder | null | undefined
): DataFolderOrDeal | null => {
  if (link && folder) {
    return {
      dealId: link.id,
      dealName: link.name,
      deal: link?.id ? true : false,
      folderId: link.folderId,
      folderName: folder.name,
      is_folder_fixed: folder.is_fixed,
    };
  } else {
    return null;
  }
};

export const removeUnderlining = (string: string | null | MemberRole): string => {
  const newStr = string ? string?.replace(/_/g, ' ') : '';
  return newStr;
};

export const getUserByEmail = (users: User[], userEmail: string): User | null => {
  return users.find((user) => user.email === userEmail) || null;
};

export const filterArray = <T extends { name?: string }>(array: T[], query: string): T[] => {
  return array
    .filter((element) => element?.name?.trim().toLowerCase().includes(query?.trim().toLowerCase()))
    .sort((a, b) =>
      a?.name && b?.name
        ? a?.name?.indexOf(query?.trim().toLowerCase()) -
          b?.name?.indexOf(query?.trim().toLowerCase())
        : 0
    );
};

export const validateError = (err: HttpErrorResponse): string => {
  return err?.error?.error || err?.message || '';
};
