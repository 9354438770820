import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TableModule } from 'primeng/table';
import { PortDisbursementTableComponent } from './port-disbursement-table.component';

export const PortDisbursementTableModuleImports = [CommonModule, TableModule];
@NgModule({
  declarations: [PortDisbursementTableComponent],
  imports: PortDisbursementTableModuleImports,
  exports: [PortDisbursementTableComponent],
})
export class PortDisbursementTableModule {}
