import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { filterArray } from '@estimator/helpers';
import {
  GeoZone,
  Port,
  PrimeNgIcons,
  ShiplistSelectedZoneOrPortEmitter,
  ShiplistZoneType,
  THREE_NUMBER,
  zoneTypesArr,
} from '@estimator/models';
import { cloneDeep } from 'lodash';
import {
  AutoComplete,
  AutoCompleteCompleteEvent,
  AutoCompleteSelectEvent,
} from 'primeng/autocomplete';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'estimator-shiplist-zones-ports-filter',
  templateUrl: './shiplist-zones-ports-filter.component.html',
  styleUrls: ['./shiplist-zones-ports-filter.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShiplistZonesPortsFilterComponent implements OnInit, OnDestroy {
  @ViewChild('autoCompleteZone') autoCompleteZone?: AutoComplete;
  @Input() ports: Port[] = [];
  @Input()
  set shiplistZonesArray(data: GeoZone[]) {
    this._shiplistZonesArray = cloneDeep(data);
    this.filteredShiplistZonesArray = cloneDeep(data);
  }
  get shiplistZonesArray(): GeoZone[] {
    return this._shiplistZonesArray;
  }
  @Output() changeZoneType = new EventEmitter<ShiplistZoneType>();
  @Output() searchPort = new EventEmitter<string>();
  @Output() selectZone = new EventEmitter<ShiplistSelectedZoneOrPortEmitter>();
  @Output() requestSaveFilter = new EventEmitter();
  // shiplistZones: FeatureCollection<Polygon> = emptyFeatureCollection();
  filteredShiplistZonesArray: GeoZone[] = [];
  zoneTypeControl = new FormControl<ShiplistZoneType>(ShiplistZoneType.ZONE);
  portControl = new FormControl<Port | string | null>(null);
  zoneControl = new FormControl<Port | string | null>(null);
  readonly zoneTypesArr = zoneTypesArr;
  readonly PrimeNgIcons = PrimeNgIcons;
  readonly THREE_NUMBER = THREE_NUMBER;
  private _shiplistZonesArray: GeoZone[] = [];
  private _onDestroy$ = new Subject<void>();

  constructor(private readonly cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.zoneTypeControl.valueChanges.pipe(takeUntil(this._onDestroy$)).subscribe((type) => {
      if (type) {
        this.changeZoneType.emit(type);
      }
    });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  onSearchPort(event: AutoCompleteCompleteEvent): void {
    if (event.query?.length >= THREE_NUMBER) {
      this.searchPort.emit(event.query);
    }
  }

  onSelectPort(event: AutoCompleteSelectEvent): void {
    const port = event.value;
    this.selectZone.emit({
      value: {
        id: port.id,
        name: port.name,
        is_zone: false,
        checked: true,
        loc_id: 0,
        zone_ids: [],
      },
      port: event.value,
      add: true,
    });
  }

  onSearchZone(event: AutoCompleteCompleteEvent): void {
    if (event?.query) {
      this.filteredShiplistZonesArray = filterArray(this.shiplistZonesArray, event?.query);
    } else {
      this.filteredShiplistZonesArray = this.shiplistZonesArray;
      if (this.autoCompleteZone) {
        this.autoCompleteZone.loading = false;
        this.autoCompleteZone?.show();
      }
      this.cdr.detectChanges();
    }
  }

  onSelectZone(event: AutoCompleteSelectEvent): void {
    const zone = event.value;
    this.selectZone.emit({
      value: {
        id: zone.id,
        name: zone.name,
        type: zone.type,
        is_zone: true,
        checked: true,
        loc_id: zone.loc_id,
        zone_ids: zone.zone_ids,
      },
      add: true,
    });
  }

  saveFilter(): void {
    this.requestSaveFilter.emit();
  }
}
