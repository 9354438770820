import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { BaseEntity, BaseEntityFormGroup } from './base-entity';
import { Currency } from './currency';
import { UserInputDuration } from './duration';

export interface Sundry extends BaseEntity {
  amount?: number;
  currency?: Currency;
  currency_id?: number;
  deal_id?: number;
  description?: string;
}

export interface SundryFormGroup extends BaseEntityFormGroup {
  amount: FormControl<number | null>;
  currency: FormControl<Currency | null>;
  currency_id: FormControl<number | null>;
  deal_id: FormControl<number | null>;
  description: FormControl<string | null>;
}

export interface MiscFormGroup {
  [SundryType.ExtraInsurance]: FormGroup<SundryFormGroup>;
  [SundryType.Misc]: FormGroup<SundryFormGroup>;
  extras: FormArray<FormGroup<SundryFormGroup>>;
  extra_idle_minutes: FormControl<UserInputDuration | null>;
  extra_sailing_laden_minutes: FormControl<UserInputDuration | null>;
}

export interface MiscFormGroupValue {
  [SundryType.ExtraInsurance]: Sundry;
  [SundryType.Misc]: Sundry;
  extras: Sundry[];
  extra_idle_minutes: UserInputDuration | null;
  extra_sailing_laden_minutes: UserInputDuration | null;
}

export enum SundryType {
  ExtraInsurance = 'Extra Insurance',
  Misc = 'Miscellaneous',
}
