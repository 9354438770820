import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { EXTERNAL_URLS } from '@estimator/landing-models';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'mar-button',
  template: `
    @switch (type) { @case ('anchor') {
    <a
      class="{{ color }} {{ size }} {{ border }}"
      [ngClass]="{ active, disabled, completed }"
      href="{{ url }}"
      target="{{ target }}"
      (click)="preventInnerNavigation($event)"
    >
      <span class="button-loader" [ngClass]="{ show: loading }"></span>
      <ng-container [ngTemplateOutlet]="content"></ng-container>
    </a>
    } @case('dropdown') {
    <div class="dropdown" #buttonDropDownContainer>
      <button
        class="{{ color }} {{ size }} {{ border }}"
        [ngClass]="{ active, completed }"
        [disabled]="disabled"
        (click)="showDropdowMenu(buttonDropDownContainer)"
      >
        <span class="button-loader" [ngClass]="{ show: loading }"></span>
        <ng-container [ngTemplateOutlet]="content"></ng-container>
      </button>
      <ul class="submenu" (mouseleave)="hideDropdowMenu(buttonDropDownContainer)">
        @for(item of dropDownMenu; track item;) {
        <li>
          <a
            class="primary"
            [href]="generateUrl(item)"
            (click)="preventInnerNavigation($event); hideDropdowMenu(buttonDropDownContainer)"
            target="{{ item?.target || '_blank' }}"
            >{{ item.label }}</a
          >
        </li>
        }
      </ul>
    </div>
    } @default {
    <button
      class="{{ color }} {{ size }} {{ border }}"
      [ngClass]="{ active, completed, disabled }"
      [disabled]="disabled"
      (click)="navigateTo()"
    >
      <span class="button-loader" [ngClass]="{ show: loading }"></span>
      <ng-container [ngTemplateOutlet]="content"></ng-container>
    </button>
    }}
    <ng-template #content><ng-content></ng-content></ng-template>
  `,
  styles: [
    `
      @import 'variables';
      :host {
        button,
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-grow: 1;
          font-family: 'Clash Grotesk';
          font-weight: 600;
          font-size: 1.2rem;
          color: $white !important;
          border: none;
          padding: 1.2rem 1.6rem;
          height: 4rem;
          border-radius: 2rem;
          text-transform: uppercase;
          cursor: pointer;
          letter-spacing: 0.02em;
          white-space: nowrap;
          transition: 0.2s;
          &:disabled {
            pointer-events: none;
            opacity: 0.4;
          }
        }
        .blue {
          background-color: $blue;
          &:hover {
            background-color: #658df5;
          }
        }
        .light {
          background-color: #f0f1f4;
          color: $primary !important;
          border: 1px solid #f0f1f4;
          &:hover {
            background-color: rgba(255, 255, 255, 0.85);
            border: 1px solid rgba(255, 255, 255, 0.1);
          }
          &.white-light {
            &:hover {
              border: 1px solid rgba(0, 0, 0, 0.1);
            }
          }
        }
        .transparent {
          background-color: rgba(255, 255, 255, 0.1);
          border: 1px solid rgba(255, 255, 255, 0.1);
          &:hover {
            background-color: rgba(255, 255, 255, 0.15);
            border: 1px solid rgba(255, 255, 255, 0.15);
          }
          &:disabled {
            opacity: 1;
          }
        }
        .completed {
          background-color: $green;
          pointer-events: none;
        }
        .small {
          padding: 0.6rem 1.2rem;
          height: 2.8rem;
        }
        .large {
          padding: 1.6rem 2.4rem;
          height: 5.6rem;
          font-size: 1.6rem;
          border-radius: 2.8rem;
        }
        .border {
          border: 1px solid #ccc;
        }
        .primary {
          background-color: #f0f1f4;
          color: $primary !important;
          &:hover,
          &.active {
            background-color: $primary;
            color: $white !important;
          }
        }
        .button-loader {
          width: 0;
          height: 2.4rem;
          mask-image: url(/assets/loader.svg);
          mask-repeat: no-repeat;
          mask-size: 2.4rem 2.4rem;
          background-color: $white;
          transition: 0.2s;
          &.show {
            width: 30px;
            background-size: 20px 20px;
          }
        }
        .dropdown {
          position: relative;
          .submenu {
            visibility: hidden;
            position: absolute;
            margin-top: 1rem;
            padding: 1rem;
            display: flex;
            flex-direction: column;
            gap: $gap-xxs;
            background-color: $white;
            transform-origin: top left;
            transform: scale3d(0, 0, 1);
            transition: 0.3s;
            border-radius: $radius-s;
            border: 1px solid #dee1e7;
            a {
              color: $primary;
              border-radius: $radius-s;
              justify-content: start;
            }
          }
          &.active {
            .submenu {
              transform: scale3d(1, 1, 1);
              visibility: visible;
            }
          }
        }
      }
    `,
  ],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent implements OnInit {
  @Input() set active(isActive: boolean) {
    this._active = isActive;
    this.cdr.detectChanges();
  }
  get active() {
    return this._active;
  }
  @Input() set completed(isCompleted: boolean) {
    this._completed = isCompleted;
    this.cdr.detectChanges();
  }
  get completed() {
    return this._completed;
  }
  @Input() loading = false;
  @Input() routerLink: string[] = [];
  @Input() queryParams: { [key: string]: string } = {};
  @Input() color: 'blue' | 'light' | 'transparent' | 'primary' | string = 'light';
  @Input() size: 'small' | 'normal' | 'large' = 'normal';
  @Input() disabled = false;
  @Input() border = false;
  @Input() type: 'button' | 'dropdown' | 'anchor' = 'button';
  @Input() target: '_blank' | '_self' = '_self';
  @Input() dropDownMenu: MenuItem[] | null = null;

  url = '#';

  readonly EXTERNAL_URLS = EXTERNAL_URLS;
  private _active = false;
  private _completed = false;

  constructor(private readonly cdr: ChangeDetectorRef, private readonly router: Router) {}

  ngOnInit(): void {
    this.url = this.generateUrl();
  }

  generateUrl(item?: MenuItem): string {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(item?.routerLink || this.routerLink, {
        queryParams: item?.queryParams || this.queryParams,
      })
    );
    return url;
  }

  navigateTo(): void {
    if (!this.routerLink.length || this.type !== 'button') {
      return;
    }
    this.router.navigate(this.routerLink, { queryParams: this.queryParams });
  }

  preventInnerNavigation(event: MouseEvent): void {
    event.stopImmediatePropagation();
  }

  showDropdowMenu(el: Element): void {
    el.classList.add('active');
  }

  hideDropdowMenu(el: Element): void {
    el.classList.remove('active');
  }
}
