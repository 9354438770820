import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  textCompanyIdField,
  textFilterParams,
  textVessel,
  threeMenuTabs,
} from '@estimator/helpers';
import {
  ButtonParams,
  DETAIL_ID_START_DRAG,
  DRAG_DROP_IN_DEALS_LIST,
  DROP_EFFECT_COPY,
  Deal,
  OPACITY_5,
  PrimeNgColors,
  RequestChangeFolder,
  RequestDataSourceDeals,
  RequestDeleteDeal,
  STANDART_TIME_FORMAT,
  ZERO_STRING,
  trueText,
} from '@estimator/models';
import { Store } from '@ngxs/store';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import {
  ColDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
  IServerSideDatasource,
  RowSelectedEvent,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community';
import { AgGridButtonGroupComponent } from '../ag-grid-shared/button-group.component';

@Component({
  selector: 'estimator-deals-list',
  templateUrl: './deals-list.component.html',
  styleUrls: ['./deals-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DealsListComponent implements ICellRendererAngularComp {
  @Input() getDataSource?: IServerSideDatasource;
  @Input() serverSide = false;
  @Input()
  set deals(data: Deal[]) {
    if (data && data.length) {
      this._deals = data;
    }
  }
  get deals(): Deal[] {
    return this._deals;
  }
  @Input()
  set isLoading(bool: boolean) {
    this._isLoading = bool;
    if (this._gridApi && bool) {
      this._gridApi.showLoadingOverlay();
    } else if (this._gridApi && !bool) {
      this._gridApi.hideOverlay();
    }
  }
  get isLoading(): boolean {
    return this._isLoading;
  }
  @Output() addDeal = new EventEmitter<number>();
  @Output() openDeal = new EventEmitter<Deal>();
  @Output() deleteDeal = new EventEmitter<RequestDeleteDeal>();
  requestFolderId?: number;
  columnDefs: ColDef<Deal>[] = [
    {
      headerComponent: 'buttonRenderer',
      headerComponentParams: (params: any) => {
        const gridHeaderActionButtons: ButtonParams[] = [
          {
            title: 'Add deal',
            tooltip: 'Add deal',
            icon: 'add',
            color: PrimeNgColors.White,
            click: () => this.onAddDeal(this.folderId as number),
          },
        ];
        return {
          ...params,
          buttons: gridHeaderActionButtons,
        };
      },
      cellRenderer: 'buttonRenderer',
      cellRendererParams: (params: any) => {
        const deal: Deal = params.data;
        const requestDeleteDeal: RequestDeleteDeal = {
          deleteId: deal.id as number,
          requestFolderId: this.requestFolderId as number,
          rowId: this.rowId as string,
          isArchived: /* deal.is_archived as boolean */ false,
        };
        const titleTooltipDelete =
          /* deal.is_archived ?  */ 'Delete fixture'; /* : 'Archive fixture' */
        return {
          ...params,
          buttons: [
            {
              title: titleTooltipDelete,
              tooltip: titleTooltipDelete,
              icon: 'delete',
              click: () => this.onDeleteDeal(requestDeleteDeal),
            },
          ],
        };
      },
      cellClass: 'center-aligned-cell',
      minWidth: 36,
      width: 36,
    },
    {
      field: 'name',
      headerName: 'File',
      flex: 1,
      filter: 'agTextColumnFilter',
      filterParams: textFilterParams,
      sortable: true,
      dndSource: true,
      cellClass: (params) => {
        const deal = params.data as Deal;
        return deal.is_archived ? OPACITY_5 : '';
      },
    },
    {
      field: textVessel,
      headerName: 'Vessel',
      flex: 1,
      valueFormatter: (params: ValueFormatterParams<Deal>) => {
        return params.data?.vessel?.name || '';
      },
      filter: 'agTextColumnFilter',
      filterParams: textFilterParams,
      sortable: false,
      valueGetter: (params: ValueGetterParams) => {
        return params.data?.vessel?.name || '';
      },
    },
    {
      field: textCompanyIdField,
      headerName: 'Client',
      flex: 1,
      /*  filter: 'companyFilterComponent',
      filterParams: {
        onSearchCompany: this.onSearchCompany.bind(this),
      }, */
      filter: 'agTextColumnFilter',
      filterParams: textFilterParams,
      valueFormatter: (params) => {
        return params.data?.company?.name || '';
      },
      sortable: true,
    },
    {
      field: 'created_at',
      headerName: 'Created at',
      flex: 1,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value) {
          return this.datePipe.transform(params.value, STANDART_TIME_FORMAT) || '';
        }
        return '';
      },
      /* filter: 'agDateColumnFilter',
      filterParams: {
        comparator: agGridMomentDateComparator,
      }, */
      filter: false,
      sortable: true,
      suppressMenu: true,
    },
    {
      field: 'updated_at',
      headerName: 'Updated at',
      flex: 1,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value) {
          return this.datePipe.transform(params.value, STANDART_TIME_FORMAT) || '';
        }
        return '';
      },
      /*  filter: 'agDateColumnFilter',
      filterParams: {
        comparator: agGridMomentDateComparator,
      }, */
      filter: false,
      sortable: true,
      suppressMenu: true,
    },
  ];
  gridOptions: GridOptions<Deal> = {
    rowModelType: 'clientSide',
    columnDefs: this.columnDefs,
    components: {
      buttonRenderer: AgGridButtonGroupComponent,
    },
    getRowId: (params) => params.data.id?.toString() || ZERO_STRING,
    defaultColDef: {
      editable: false,
      resizable: true,
      menuTabs: threeMenuTabs,
    },
    onRowDoubleClicked: (event: RowSelectedEvent<Deal>) => {
      if (event.data) {
        this.onOpenDeal(event.data);
      }
    },
    suppressContextMenu: true,
    suppressScrollOnNewData: true,
    singleClickEdit: true,
    rowSelection: 'single',
    animateRows: true,
    rowHeight: 40,
    headerHeight: 40,
    autoSizePadding: 0,
  };
  masterGridApi?: GridApi;
  rowId?: string;
  folderId?: number;
  private _isLoading = false;
  private _gridApi?: GridApi<Deal>;
  private _deals: Deal[] = [];

  constructor(private readonly datePipe: DatePipe, private readonly store: Store) {}

  onGridReady({ api, columnApi }: GridReadyEvent): void {
    this._gridApi = api;
    if (this.serverSide) {
      const gridInfo = {
        id: this.rowId as string,
        api: api,
        columnApi: columnApi,
      };
      this.masterGridApi?.addDetailGridInfo(this.rowId as string, gridInfo);
      this.onRequestDataSource({
        requestFolderId: this.requestFolderId as number,
        rowId: this.rowId as string,
      });
    }
  }

  onAddDeal(folderId: number): void {
    this.addDeal.emit(folderId);
  }

  onOpenDeal(deal: Deal): void {
    this.openDeal.emit(deal);
  }

  onDeleteDeal(requestDeleteDeal: RequestDeleteDeal): void {
    if (requestDeleteDeal?.deleteId) {
      this.deleteDeal.emit(requestDeleteDeal);
    }
  }

  agInit(params: any): void {
    this.masterGridApi = params.api;
    this.rowId = params.node.id;
    this.folderId = params.node.data.id;
    if (params.serverSide) {
      this.serverSide = params.serverSide;
      this.gridOptions.rowModelType = 'serverSide';
      this.gridOptions.serverSideInfiniteScroll = true;
    }
    if (params.onAddDeal) {
      this.onAddDeal = params.onAddDeal;
    }
    if (params.onOpenDeal) {
      this.onOpenDeal = params.onOpenDeal;
    }
    if (params.onDeleteDeal) {
      this.onDeleteDeal = params.onDeleteDeal;
    }
    if (params.onRequestDataSource) {
      this.onRequestDataSource = params.onRequestDataSource;
    }
    if (params.onChangeFolder) {
      this.onChangeFolder = params.onChangeFolder;
    }
    /* if (params.onSearchCompanyUp) {
      this.onSearchCompanyUp = params.onSearchCompanyUp;
    } */
    if (params.requestFolderId) {
      this.requestFolderId = params.requestFolderId;
    }
  }

  refresh(/* params: ICellRendererParams<any, any> */): boolean {
    return false;
  }

  // заглушка под метод
  onRequestDataSource(object: RequestDataSourceDeals) {
    return;
  }

  // заглушка под метод
  onChangeFolder(object: RequestChangeFolder) {
    return;
  }

  // заглушка под метод
  /*   onSearchCompanyUp(value: string, rowId: string) {
    return;
  } */

  /* onSearchCompany(value: string, rowId: string): void {
    this.onSearchCompanyUp(value, this.rowId as string);
  } */

  gridDragStart(/* event: DragEvent */) {
    localStorage.setItem(DETAIL_ID_START_DRAG, this.rowId as string);
  }

  gridDragOver(event: DragEvent) {
    const dragSupported = event.dataTransfer?.types?.length;
    if (dragSupported) {
      event.dataTransfer.dropEffect = DROP_EFFECT_COPY;
      event.preventDefault();
    }
  }

  gridDrop(event: DragEvent) {
    event.preventDefault();
    const jsonData = event.dataTransfer?.getData('application/json') as string;
    const data = JSON.parse(jsonData);
    // if data missing or data has no it, do nothing
    if (!data || data.id == null) {
      return;
    }

    const object: RequestChangeFolder = {
      deal: data,
      requestFolderId: this.requestFolderId as number,
      requestRowId: this.rowId as string,
      previousFolderId: data.folder_id,
      previousRowId: localStorage.getItem(DETAIL_ID_START_DRAG) as string,
      requestFolderExpanded: true,
    };
    if (object.previousFolderId !== object.requestFolderId) {
      localStorage.setItem(DRAG_DROP_IN_DEALS_LIST, trueText);
      this.onChangeFolder(object);
    }
  }
}
