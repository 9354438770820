import { DecimalPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  Currency,
  DEFAULT_FIAT_MULTIPLIER,
  FuelConsumption,
  NOT_APPLICABLE,
  PriceDto,
  UserGuideType,
  ZERO_STRING,
} from '@estimator/models';
import {
  ColDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ValueFormatterParams,
} from 'ag-grid-community';
import { AgGridHeaderComponent } from '../ag-grid-shared/ag-grid-header.component';
import { AgGridNumericCellEditorComponent } from '../ag-grid-shared/ag-grid-numeric-editor.component';
import { AgGridPriceEditorComponent } from '../ag-grid-shared/ag-grid-price-with-currency-editor.component';

@Component({
  selector: 'estimator-fast-bunkering-list',
  templateUrl: './fast-bunkering-list.component.html',
  styleUrls: ['./fast-bunkering-list.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FastBunkeringListComponent {
  @Input()
  set consumptions(data: FuelConsumption[]) {
    this._consumptions = data;
  }
  get consumptions(): FuelConsumption[] {
    return this._consumptions;
  }
  @Input() currencies: Currency[] = [];
  @Output() updateConsumptions = new EventEmitter<FuelConsumption[]>();
  @Output() showTooltip = new EventEmitter<string>();
  columnDefs: ColDef<FuelConsumption>[] = [
    {
      field: 'fuel.name',
      headerComponent: 'header',
      headerComponentParams: () => {
        return {
          title: 'Fuel',
          click: () => this.headerFuelClick(),
        };
      },
      flex: 1,
    },
    {
      field: 'amount',
      headerName: 'Quantity',
      flex: 1,
      valueFormatter: (params) => {
        return this.decimalPipe.transform(params.value, '.0-2')?.toString() || ZERO_STRING;
      },
    },
    {
      field: 'price',
      headerName: 'Price',
      editable: true,
      cellEditor: 'priceEditor',
      cellEditorParams: (params: any) => {
        const consumption: FuelConsumption = params.data;
        params.value = { price: consumption.price, currency: consumption.currency };
        return {
          ...params,
          currencies: this.currencies,
          placeholder: 'Currency',
          emptyDataArray: 'No currency found',
        };
      },
      valueFormatter: (params: ValueFormatterParams<FuelConsumption>) => {
        if (params.data) {
          const consumption: FuelConsumption = params.data;
          if (consumption.currency) {
            return `${
              (consumption.price || 0) /
              (consumption.currency?.fiat_multiplier || DEFAULT_FIAT_MULTIPLIER)
            } ${consumption.currency.currency_code}`;
          }
        }
        return NOT_APPLICABLE;
      },
      onCellValueChanged: (event) => {
        const consumption: FuelConsumption = event.data;
        if (event.newValue) {
          const priceDto: PriceDto = event.newValue;
          consumption.price = priceDto.price;
          if (priceDto.currency) {
            consumption.currency = priceDto.currency;
          }
          if (consumption.currency?.id) {
            consumption.currency_id = consumption.currency?.id;
          }
          this._gridApi?.refreshCells();
        }
      },
      flex: 1,
    },
  ];
  gridOptions: GridOptions<FuelConsumption> = {
    rowModelType: 'clientSide',
    columnDefs: this.columnDefs,
    components: {
      header: AgGridHeaderComponent,
      numericEditor: AgGridNumericCellEditorComponent,
      priceEditor: AgGridPriceEditorComponent,
    },
    getRowId: (params) =>
      params.data.id ? params.data?.id?.toString() :
      params.data?.fuel_id?.toString() ||
      params.data?.fuel?.id?.toString() ||
      ZERO_STRING,
    defaultColDef: {
      editable: false,
      resizable: true,
    },
    suppressContextMenu: true,
    suppressScrollOnNewData: true,
    singleClickEdit: true,
    rowSelection: 'single',
    animateRows: true,
    rowHeight: 40,
    headerHeight: 40,
    autoSizePadding: 0,
    domLayout: 'autoHeight',
    onCellEditingStopped: (event) => {
      if (event.data) {
        if (
          !this._gridApi?.getEditingCells().length &&
          event.newValue &&
          event.newValue !== event.oldValue
        ) {
          this.updateConsumptions.emit(this.rowData);
        }
      }
    },
  };
  private _gridApi?: GridApi<FuelConsumption>;
  private _consumptions: FuelConsumption[] = [];

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this._gridApi?.stopEditing(true);
    }
    if (event.key === 'Enter') {
      this._gridApi?.stopEditing();
    }
  }

  get rowData(): FuelConsumption[] {
    const consumptions: FuelConsumption[] = [];
    this._gridApi?.forEachNode((node) => {
      if (node.data) {
        consumptions.push(node.data);
      }
    });
    return consumptions;
  }

  constructor(private readonly decimalPipe: DecimalPipe) {}

  onGridReady({ api }: GridReadyEvent): void {
    this._gridApi = api;
  }

  headerFuelClick() {
    this.showTooltip.emit(UserGuideType.FastBunkeringList6);
  }
}
