import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AgGridModule } from 'ag-grid-angular';
import { AgGridSharedModule } from '../ag-grid-shared/ag-grid-shared.module';
import { FleetVesselComponent } from './fleet-vessel.component';

@NgModule({
  declarations: [FleetVesselComponent],
  imports: [AgGridModule, CommonModule, AgGridSharedModule],
  exports: [FleetVesselComponent],
})
export class FleetVesselModule {}
