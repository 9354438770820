import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { Currency, NOT_APPLICABLE, PriceDto, PrimeNgColors, Sundry, UserGuideType, ZERO_STRING } from '@estimator/models';
import {
  ColDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ICellRendererParams,
  ValueFormatterParams,
} from 'ag-grid-community';
import { AgGridHeaderComponent } from '../ag-grid-shared/ag-grid-header.component';
import { AgGridNumericCellEditorComponent } from '../ag-grid-shared/ag-grid-numeric-editor.component';
import { AgGridPriceEditorComponent } from '../ag-grid-shared/ag-grid-price-with-currency-editor.component';
import { AgGridButtonGroupComponent } from '../ag-grid-shared/button-group.component';

@Component({
  selector: 'estimator-misc-list',
  templateUrl: './misc-list.component.html',
  styleUrls: ['./misc-list.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MiscListComponent {
  @Input() sundries: Sundry[] = [];
  @Input() currencies: Currency[] = [];
  @Input() currentDealId = 0;
  @Output() updateSundries = new EventEmitter<Sundry[]>();
  @Output() showTooltip = new EventEmitter<string>();
  columnDefs: ColDef<Sundry>[] = [
    {
      field: 'action',
      headerComponent: 'buttonRenderer',
      headerComponentParams: (params: any) => {
        return {
          ...params,
          buttons: [
            {
              title: 'Add cargo',
              tooltip: 'Add cargo',
              icon: 'add',
              color: PrimeNgColors.White,
              click: () => this.onAddMisc(),
            },
          ],
        };
      },
      cellRenderer: 'buttonRenderer',
      cellRendererParams: (params: ICellRendererParams<Sundry>) => {
        return {
          ...params,
          buttons: [
            {
              title: 'Delete cargo',
              tooltip: 'Delete cargo',
              icon: 'delete',
              click: () => this.onDeleteMisc(params),
            },
          ],
        };
      },
      cellClass: 'center-vertical-aligned-cell',
      minWidth: 40,
      width: 40,
    },
    {
      field: 'description',
      headerComponent: 'header',
      headerComponentParams: () => {
        return {
          title: 'MISC',
          click: () => this.headerMiscClick(),
        };
      },
      flex: 1,
      editable: true,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      editable: true,
      cellEditor: 'priceEditor',
      cellEditorParams: (params: any) => {
        const sundry: Sundry = params.data;
        params.value = { price: sundry.amount, currency: sundry.currency };
        return {
          ...params,
          currencies: this.currencies,
          placeholder: 'Currency',
          emptyDataArray: 'No currency found',
        };
      },
      valueFormatter: (params: ValueFormatterParams<Sundry>) => {
        if (params.data) {
          const sundry: Sundry = params.data;
          if (sundry.currency) {
            return `${(sundry.amount || 0) / (sundry.currency?.fiat_multiplier || 100)} ${
              sundry.currency.currency_code
            }`;
          }
        }
        return NOT_APPLICABLE;
      },
      onCellValueChanged: (event) => {
        const sundry: Sundry = event.data;
        if (event.newValue) {
          const priceDto: PriceDto = event.newValue;
          sundry.amount = priceDto.price;
          if (priceDto.currency) {
            sundry.currency = priceDto.currency;
          }
          if (sundry.currency?.id) {
            sundry.currency_id = sundry.currency.id;
          }
          this._gridApi?.refreshCells();
        }
      },
      flex: 1,
    },
  ];
  gridOptions: GridOptions<Sundry> = {
    rowModelType: 'clientSide',
    columnDefs: this.columnDefs,
    components: {
      buttonRenderer: AgGridButtonGroupComponent,
      header: AgGridHeaderComponent,
      numericEditor: AgGridNumericCellEditorComponent,
      priceEditor: AgGridPriceEditorComponent,
    },
    getRowId: (params) => params.data?.id?.toString() || ZERO_STRING,
    defaultColDef: {
      editable: false,
      resizable: true,
    },
    suppressContextMenu: true,
    suppressScrollOnNewData: true,
    singleClickEdit: true,
    rowSelection: 'single',
    animateRows: true,
    rowHeight: 40,
    headerHeight: 40,
    autoSizePadding: 0,
    domLayout: 'autoHeight',
    onCellEditingStopped: (event) => {
      if (event.data) {
        if (
          !this._gridApi?.getEditingCells().length &&
          event.newValue &&
          event.newValue !== event.oldValue
        ) {
          this.updateSundries.emit(this.rowData);
        }
      }
    },
  };
  private _gridApi?: GridApi<Sundry>;

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this._gridApi?.stopEditing(true);
    }
    if (event.key === 'Enter') {
      this._gridApi?.stopEditing();
    }
  }

  get rowData(): Sundry[] {
    const sundries: Sundry[] = [];
    this._gridApi?.forEachNode((node) => {
      if (node.data) {
        sundries.push(node.data);
      }
    });
    return sundries;
  }

  onGridReady({ api }: GridReadyEvent): void {
    this._gridApi = api;
  }

  onAddMisc(): void {
    const newMisc: Sundry = {
      id: 0,
      description: '',
      deal_id: this.currentDealId,
    };
    this._gridApi?.applyTransaction({ add: [newMisc] });
    this.updateSundries.emit(this.rowData);
  }

  onDeleteMisc(params: ICellRendererParams<Sundry>): void {
    if (params.data) {
      this._gridApi?.applyTransaction({ remove: [params?.data] });
      this.updateSundries.emit(this.rowData);
    }
  }

  headerMiscClick() {
    this.showTooltip.emit(UserGuideType.MiscList7);
  }
}
