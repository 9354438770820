import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { CoordinateInputComponent } from './coordinate-input.component';

@NgModule({
  declarations: [CoordinateInputComponent],
  imports: [CommonModule, DropdownModule, ReactiveFormsModule, IMaskModule, InputTextModule],
  exports: [CoordinateInputComponent],
})
export class CoordinateInputModule {}
