import {
  ShiplistSelectedZoneOrPort,
  ShiplistTableResponse,
  ShiplistZoneType,
  ZERO_STRING,
  ZoneTypes,
} from '@estimator/models';
import { XlistFilter } from '@xlist-models';
import { MenuItem } from 'primeng/api';

export const emptyShipListTableData: ShiplistTableResponse = {
  shiplist_items: [],
  total_amount_of_shiplist_items: 0,
};

export const NEW_FILTER_TEXT = 'New Filter';
export const emptyShiplistFilter: XlistFilter = {
  id: 0,
  name: '',
  message_date_from_seconds: 0,
  message_date_to_seconds: 0,
  // message_date_last_n_days: 0,
  open_dates_from_seconds: 0,
  open_dates_to_seconds: 0,
  // open_dates_last_n_days: 0,
  vessel_built_year_from: 2003,
  vessel_built_year_to: 2024,
};
export const emptyShiplistFilterMenuItem: MenuItem = { id: ZERO_STRING, label: NEW_FILTER_TEXT };

/**
 * value maybe like ShiplistSelectedZoneOrSelectedPortOrPort, but we need only for zones-values-type
 */
export const getIconZone = (value: ShiplistSelectedZoneOrPort): string => {
  switch (value?.type) {
    case ShiplistZoneType.ZONE:
      return 'crop_landscape';
    case ShiplistZoneType.COUNTRY_ZONE:
      return 'flag';
    case ShiplistZoneType.RANGE:
      return 'layers';
    default:
      return '';
  }
};

/**
 * value maybe like ShiplistSelectedZoneOrSelectedPortOrPort, but we need only for zones-values-type
 */
export const getIconTooltip = (value: ShiplistSelectedZoneOrPort): ZoneTypes | '' => {
  switch (value?.type) {
    case ShiplistZoneType.ZONE:
      return ZoneTypes.ZONES;
    case ShiplistZoneType.COUNTRY_ZONE:
      return ZoneTypes.COUNTRIES;
    case ShiplistZoneType.RANGE:
      return ZoneTypes.RANGES;
    default:
      return '';
  }
};

export const capitalizeFirstLetter = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
