export const platformProductMock = [
  {
    id: 'a95e7307-4148-48d5-8866-cd2e60cba734',
    shortName: '8217:2017 DMX 2d0375fb-5ad5-4a13-8379-7537add0632f',
    fullName: '8217:2017 Distillate DMX 0.10 % DMX 0.10% 8217:2017',
    additional: {
      iso: '8217:2017',
      type: 'Distillate',
      class: 'DMX',
      grade: 'DMX 0.10% 8217:2017',
      maxSulfur: '0.10',
      abbreviation: 'LSMDO',
      measurementUnit: '%',
    },
    active: true,
    __typename: 'product_meta',
  },
  {
    id: '4eee7ca0-79cd-4e15-ae8c-76c8c550dba3',
    shortName: '8217:2017 DMX 71a2f843-3f15-418a-a895-cb8fdef89d0e',
    fullName: '8217:2017 Distillate DMX 0.50 % DMX 0.50% 8217:2017',
    additional: {
      iso: '8217:2017',
      type: 'Distillate',
      class: 'DMX',
      grade: 'DMX 0.50% 8217:2017',
      maxSulfur: '0.50',
      abbreviation: 'MDO',
      measurementUnit: '%',
    },
    active: true,
    __typename: 'product_meta',
  },
  {
    id: '23dcdd1b-907f-497a-ad3b-7637b85fbfe7',
    shortName: '8217:2017 DMX b6bec9cd-486f-48ee-bff5-a8a6a4c27a42',
    fullName: '8217:2017 Distillate DMX 1.00 % DMX 1.00% 8217:2017',
    additional: {
      iso: '8217:2017',
      type: 'Distillate',
      class: 'DMX',
      grade: 'DMX 1.00% 8217:2017',
      maxSulfur: '1.00',
      abbreviation: 'MDO',
      measurementUnit: '%',
    },
    active: true,
    __typename: 'product_meta',
  },
  {
    id: 'f640304a-be4e-4420-ac25-12058ed1717d',
    shortName: 'max dens 860 DMA b2ead6e8-7e39-4e67-9d5b-9b34b0a6044c',
    fullName: 'max dens 860 Distillate DMA 10 ppm DMA 10ppm, density at 15°C  860 kg/m3',
    additional: {
      iso: 'max dens 860',
      type: 'Distillate',
      class: 'DMA',
      grade: 'DMA 10ppm, density at 15°C  860 kg/m3',
      maxSulfur: '10',
      abbreviation: 'LSMGO',
      measurementUnit: 'ppm',
    },
    active: true,
    __typename: 'product_meta',
  },
  {
    id: '862a4739-d184-405a-af94-d35eafcd8f15',
    shortName: '8217:2017 DMA 21138973-b475-4e3c-acf6-65b070c9c06b',
    fullName: '8217:2017 Distillate DMA 10 ppm DMA 10% 8217:2017',
    additional: {
      iso: '8217:2017',
      type: 'Distillate',
      class: 'DMA',
      grade: 'DMA 10% 8217:2017',
      maxSulfur: '10',
      abbreviation: 'LSMGO',
      measurementUnit: 'ppm',
    },
    active: true,
    __typename: 'product_meta',
  },
  {
    id: '61ac3ace-7979-4fd6-bbc2-00a00f9441e6',
    shortName: 'max dens 860 DMA eb1e8238-e026-474e-ba12-f448993d197b',
    fullName: 'max dens 860 Distillate DMA 0.10 % DMA 0.1%, density at 15°C  860 kg/m3',
    additional: {
      iso: 'max dens 860',
      type: 'Distillate',
      class: 'DMA',
      grade: 'DMA 0.1%, density at 15°C  860 kg/m3',
      maxSulfur: '0.10',
      abbreviation: 'LSMGO',
      measurementUnit: '%',
    },
    active: true,
    __typename: 'product_meta',
  },
  {
    id: 'da31111c-57a3-4072-b4e0-dad841babe8c',
    shortName: '8217:2017 DMA dafecbbc-39dd-46c2-a2c5-02fcf5b84d2c',
    fullName: '8217:2017 Distillate DMA 0.10 % DMA 0.10% 8217:2017',
    additional: {
      iso: '8217:2017',
      type: 'Distillate',
      class: 'DMA',
      grade: 'DMA 0.10% 8217:2017',
      maxSulfur: '0.10',
      abbreviation: 'LSMGO',
      measurementUnit: '%',
    },
    active: true,
    __typename: 'product_meta',
  },
  {
    id: 'e410e13f-bce0-430e-821e-056808de5a58',
    shortName: '8217:2017 DMA 39596b67-a879-4d2d-afcc-2bc134551a9a',
    fullName: '8217:2017 Distillate DMA 0.50 % DMA 0.50% 8217:2017',
    additional: {
      iso: '8217:2017',
      type: 'Distillate',
      class: 'DMA',
      grade: 'DMA 0.50% 8217:2017',
      maxSulfur: '0.50',
      abbreviation: 'MGO',
      measurementUnit: '%',
    },
    active: true,
    __typename: 'product_meta',
  },
  {
    id: '45819e14-dd67-4176-81f5-faa28a434655',
    shortName: '8217:2017 DMA 318e927f-d6a8-40b7-a9d6-fcc98e22675d',
    fullName: '8217:2017 Distillate DMA 1.00 % DMA 1.00% 8217:2017',
    additional: {
      iso: '8217:2017',
      type: 'Distillate',
      class: 'DMA',
      grade: 'DMA 1.00% 8217:2017',
      maxSulfur: '1.00',
      abbreviation: 'MGO',
      measurementUnit: '%',
    },
    active: true,
    __typename: 'product_meta',
  },
];
