import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CalendarModule } from 'primeng/calendar';
import { ItineraryDateComponent } from './itinerary-date.component';
import { TableModule } from 'primeng/table';

export const itineraryDateModuleImports = [CommonModule, CalendarModule, TableModule, ReactiveFormsModule];

@NgModule({
  declarations: [ItineraryDateComponent],
  imports: itineraryDateModuleImports,
  exports: [ItineraryDateComponent],
})
export class ItineraryDateModule {}
