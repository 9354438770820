import { Injectable } from '@angular/core';
import { BaseModel } from '@estimator/models';
import { CargoTypeService } from '@estimator/services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { catchError, finalize, tap, throwError } from 'rxjs';
import { GetAllCargoTypes } from './cargo-types.actions';

export interface CargoTypeStateModel {
  cargoTypes: BaseModel[];
  loading: boolean;
}

@State<CargoTypeStateModel>({
  name: 'cargoTypes',
  defaults: {
    cargoTypes: [],
    loading: false,
  },
})
@Injectable()
export class CargoTypeState {
  @Selector()
  static getIsLoading({ loading }: CargoTypeStateModel): boolean {
    return loading;
  }
  @Selector()
  static getCargoTypes({ cargoTypes }: CargoTypeStateModel): BaseModel[] {
    return cargoTypes;
  }

  constructor(private readonly CargoTypeService: CargoTypeService) {}

  @Action(GetAllCargoTypes)
  getAllCargoTypes({ patchState }: StateContext<CargoTypeStateModel>) {
    patchState({ loading: true });
    return this.CargoTypeService.getAll().pipe(
      tap((cargoTypes) => {
        patchState({ cargoTypes });
      }),
      catchError((err) => {
        patchState({ loading: false });
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }
}
