<div class="header-row flex flex-row flex-wrap content-space-between w-full h-full p-1">
  <div class="event-actions flex-container-row gap-2">
    <div class="flex-container-row items-center gap-2">
      <p-inputSwitch
        [formControl]="showCanalsControl"
        class="p-inputswitch-sm"
        [inputId]="ID_USER_SETTINGS_FORM_SHOW_CANALS"
        estimatorSwitchTabIndex
      ></p-inputSwitch>
      <label
        class="cursor-pointer"
        [for]="ID_USER_SETTINGS_FORM_SHOW_CANALS"
        pTooltip="Canals with data are not hidden"
        >Show canals</label
      >
    </div>
    <div class="flex-container-row items-center gap-2 primeng-form" style="margin-left: 20px">
      <span [class]="PrimeNgIcons.Propeller" style="padding-right: 17px"></span>
      <p-dropdown
        [options]="presets"
        [formControl]="presetsControl"
        appendTo="body"
        placeholder="Speed"
        optionLabel="name"
        dataKey="name"
        class="p-inputtext-sm hide-dropdown-button w-100"
        styleClass="w-100"
        inputStyleClass="w-100"
      >
      </p-dropdown>
    </div>
  </div>
  <div class="flex-container-row gap-2">
    <span class="display-flex items-center">
      <b>Days:</b>
      {{ daysDistanceIndicators.hireMinutes || 0 | duration : 'd' : 2 : true }} ({{
        daysDistanceIndicators.sailingMinutes || 0 | duration : 'd' : 2 : true
      }}
      Sea, {{ daysDistanceIndicators.loadDischargeMinutes || 0 | duration : 'd' : 2 : true }} W,
      {{ daysDistanceIndicators.idleMinutes || 0 | duration : 'd' : 2 : true }} Idl,
      {{ daysDistanceIndicators.reserveMinutes || 0 | duration : 'd' : 2 : true }} Res)
    </span>
    <span class="display-flex items-center">
      <b>Dist:</b>
      {{ daysDistanceIndicators.distance?.toFixed(2) || 0 }}
      ({{ daysDistanceIndicators.ecaDistance?.toFixed(2) || 0 }} Eca,
      {{ daysDistanceIndicators.balDistance?.toFixed(2) || 0 }} Bal,
      {{ daysDistanceIndicators.ladDistance?.toFixed(2) || 0 }} Lad)
    </span>
    <span class="display-flex items-center">
      <b>EEOI: {{ daysDistanceIndicators.EEOI?.toFixed(2) || 0 }} x 10<sup>-6</sup></b>
    </span>
  </div>
</div>
