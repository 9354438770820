import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
  forwardRef,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';

@Component({
  selector: 'mar-textarea',
  template: `
    <div class="textarea-container">
      <textarea
        [ngClass]="{
          light: color === 'light'
        }"
        [(ngModel)]="value"
        (input)="onChange(value)"
        (blur)="onTouched()"
        [required]="required"
        [disabled]="disabled"
        rows="3"
        placeholder="Your message"
      ></textarea>
    </div>
  `,
  styles: [
    `
      @import 'variables';
      :host {
        width: 100%;
        .textarea-container {
          position: relative;
          display: flex;
          align-items: center;
          height: 100%;
          &:hover input {
            background-color: rgba(255, 255, 255, 0.15);
          }
        }
        textarea {
          font-family: 'Aeonik Pro';
          border: none;
          outline: none;
          background-color: rgba(255, 255, 255, 0.1);
          border: 1px solid rgba(255, 255, 255, 0.15);
          border-radius: 1.6rem;
          color: $white;
          padding: 1rem 2rem;
          font-size: 1.6rem;
          width: 100%;
          transition: 0.2s;
          resize: none;
          line-height: 150%;
          &.light {
            border: 1px solid rgba(0, 0, 0, 0.15);
            color: $primary;
            &::placeholder {
              color: $primary;
              opacity: 0.4;
            }
            &:placeholder-shown {
              background-color: rgba(0, 0, 0, 0.03);
            }
          }
          &:focus {
            border-color: $blue;
          }
          &:invalid {
            border-color: $red;
          }
          &:disabled {
            pointer-events: none;
            opacity: 0.4;
          }
          &::placeholder {
            color: $white;
            opacity: 0.6;
          }
        }
      }
      estimator-button {
        padding: 1rem;
      }
    `,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextareaComponent),
      multi: true,
    },
  ],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextareaComponent implements ControlValueAccessor, Validator {
  @Input() type?: 'button' | '' = '';
  @Input() validator?: string;
  @Input() placeholder?: string;
  @Input() value?: string | number;
  @Input() required = false;
  @Input() disabled = false;
  @Input() color?: 'light' | 'dark' | '' = '';

  onChange: any = (value: any) => {};
  onTouched: any = () => {};

  writeValue(obj: any): void {
    this.value = obj;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  validate(control: AbstractControl<any, any>): ValidationErrors | null {
    throw new Error('Method not implemented.');
  }
  registerOnValidatorChange?(fn: () => void): void {
    throw new Error('Method not implemented.');
  }
}
