import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  DEFAULT_USER,
  ERROR_BASE_TEMPLATE,
  FormValidationErrors,
  NotificationType,
  PasswordForgotAndRestoreDialogConfig,
  PrimeNgColors,
  PrimeNgIcons,
} from '@estimator/models';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'estimator-password-forgot',
  templateUrl: './password-forgot.component.html',
  styleUrls: ['./password-forgot.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordForgotComponent implements OnDestroy {
  @Input() isLoading = false;
  @Input() responseError = '';
  @Input() restoreSuccess = false;
  @Output() restorePassword = new EventEmitter<string>();
  resetPasswordFormGroup = new FormGroup({
    email: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, Validators.email],
    }),
  });
  readonly DEFAULT_USER = DEFAULT_USER;
  readonly PrimeNgColors = PrimeNgColors;
  readonly PrimeNgIcons = PrimeNgIcons;
  readonly FormValidationErrors = FormValidationErrors;
  readonly NotificationType = NotificationType;
  private _onDestroy$ = new Subject<void>();

  constructor(
    private readonly dialogConfig: DynamicDialogConfig<PasswordForgotAndRestoreDialogConfig>,
    private readonly cdr: ChangeDetectorRef
  ) {
    if (this.dialogConfig.data) {
      const { isLoadingSubject, responseErrorSubject, restoreSuccessSubject, restorePassword } =
        this.dialogConfig.data;
      if (restorePassword) {
        this.restorePassword = restorePassword;
      }
      isLoadingSubject?.pipe(takeUntil(this._onDestroy$)).subscribe((loading) => {
        this.isLoading = loading;
        this.resetPasswordFormGroup.disable();
        this.cdr.detectChanges();
      });
      responseErrorSubject?.pipe(takeUntil(this._onDestroy$)).subscribe((err) => {
        this.responseError = `${ERROR_BASE_TEMPLATE} ${err}`;
        this.resetPasswordFormGroup.enable();
        this.cdr.detectChanges();
      });
      restoreSuccessSubject?.pipe(takeUntil(this._onDestroy$)).subscribe((res) => {
        this.restoreSuccess = res;
        this.resetPasswordFormGroup.disable();
        this.cdr.detectChanges();
      });
    }
  }
  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  get isEmailControlInvalid(): boolean {
    return (
      !!this.resetPasswordFormGroup.invalid &&
      this.resetPasswordFormGroup.controls.email.dirty &&
      this.resetPasswordFormGroup.controls.email.touched
    );
  }

  onEmailSubmit(): void {
    this.restorePassword.emit(this.resetPasswordFormGroup.value.email);
  }
}
