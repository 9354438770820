import { Injectable } from '@angular/core';
import emailjs, { EmailJSResponseStatus } from '@emailjs/browser';
import { ContactForm } from '@estimator/landing-models';
import { from, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  private readonly apiKey = 'rpembWmSarRkOU1Hu';
  private readonly serviceId = 'service_p6odxvo';
  private readonly templateId = 'template_6slno17';

  constructor() {
    emailjs.init(this.apiKey);
  }

  sendMail(form: ContactForm): Observable<EmailJSResponseStatus> {
    return from(
      emailjs.send(this.serviceId, this.templateId, {
        ...form,
        reply_to: form.email,
      })
    );
  }
}
