import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CargoFreightType } from '@estimator/models';
import { BaseService } from '../base';

@Injectable({
  providedIn: 'root'
})
export class FreightTypesService extends BaseService<CargoFreightType> {
  constructor(protected override readonly http: HttpClient) {
    super(http);
    this.setPath('/api/freight-types');
  }
}
