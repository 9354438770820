<div class="flex-container-column gap-1 primeng-form" id="dem_des_form">
  <p-table
    *ngFor="let demDesForm of demurrageDespatchFormArray; let i = index"
    [columns]="columns"
    [value]="demDesForm?.events || []"
    [resizableColumns]="true"
    class="primeng-form"
    styleClass="p-datatable-sm font-size-12"
    stateStorage="local"
    [stateKey]="stateKey"
    [id]="'dem_des_form_' + i"
  >
    <ng-template pTemplate="header">
      <tr>
        <th
          pResizableColumn
          *ngFor="let col of columns"
          class="text-center"
          [ngStyle]="{ width: col?.width }"
        >
          <ng-container *ngIf="col.icon; else simpleHeader">
            <span [class]="col.icon" style="width: 0.75rem; height: 0.75rem"></span>
          </ng-container>
          <ng-template #simpleHeader>
            <ng-container [ngSwitch]="col.field">
              <ng-container *ngSwitchCase="DemurrageDespatchColumnType.Type">
                {{ getCargoIndex(i) }}
              </ng-container>
              <ng-container *ngSwitchCase="DemurrageDespatchColumnType.Port">
                <div class="flex align-items-center w-full gap-1">
                  <label [for]="'dem_des_form-price' + i">Rate</label>
                  <estimator-price-and-currency-input
                    [disabled]="isFixed"
                    [showCurrency]="false"
                    [priceFormControl]="demDesForm.cargo.controls.price"
                    [price]="demDesForm.cargo.controls.price.value"
                    [id]="'dem_des_form_cargo_price_' + i"
                  ></estimator-price-and-currency-input>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="DemurrageDespatchColumnType.Demurrage">
                <div class="w-full flex align-items-center gap-1">
                  <label [for]="'dem_des_form-cargo-lumpsum' + i">Lumpsum</label>
                  <estimator-price-and-currency-input
                    [disabled]="isFixed"
                    [showCurrency]="false"
                    [priceFormControl]="demDesForm.cargo.controls.lumpsum"
                    [price]="demDesForm.cargo.controls.lumpsum.value"
                    [id]="'dem_des_form_cargo_lumpsum_' + i"
                  ></estimator-price-and-currency-input>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="DemurrageDespatchColumnType.Despatch">
                <div class="w-full flex align-items-center gap-1">
                  <label [for]="'dem_des_form-cargo-voyage_commission' + i">Voy Comm</label>
                  <p-inputNumber
                    [useGrouping]="false"
                    [minFractionDigits]="0"
                    [maxFractionDigits]="2"
                    [min]="0"
                    [step]="0"
                    [formControl]="demDesForm.cargo.controls.voyage_commission"
                    class="p-inputtext-sm w-full"
                    styleClass="w-full"
                    inputStyleClass="w-full"
                    [inputId]="'dem_des_form_cargo_voyage_commission_' + i"
                    estimatorDecimals
                  ></p-inputNumber>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="DemurrageDespatchColumnType.Quantity">
                <p-dropdown
                  [formControl]="demDesForm.cargo.controls.laytime_type"
                  [inputId]="'dem_des_form_cargo_laytime_is_reversible_' + i"
                  [options]="laytimeTypeOptions"
                  appendTo="body"
                  class="p-inputtext-sm w-full"
                >
                </p-dropdown>
              </ng-container>
              <ng-container *ngSwitchCase="DemurrageDespatchColumnType.LDRate">
                <div class="flex align-items-center gap-1">
                  <p-button
                    styleClass="primary-color"
                    size="small"
                    [icon]="PrimeNgIcons.WINDOW_MAXIMIZE"
                    [disabled]="isFixed"
                    (onClick)="onPushDemDesCalculationToEvent(demDesForm)"
                  ></p-button>
                  <p-button
                    size="small"
                    [icon]="PrimeNgIcons.TRASH"
                    severity="danger"
                    [disabled]="isFixed"
                    (onClick)="onDeleteDemDes(demDesForm)"
                  ></p-button>
                </div>
              </ng-container>
              <ng-container *ngSwitchDefault>
                {{ col.header }}
              </ng-container>
            </ng-container>
          </ng-template>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-eventForm let-eventIndex="rowIndex">
      <tr class="primeng-form">
        <td *ngFor="let col of columns">
          <ng-container [ngSwitch]="col.field">
            <ng-container *ngSwitchCase="DemurrageDespatchColumnType.Type">
              {{ getEventTypeLabel(eventForm) }}
            </ng-container>
            <ng-container *ngSwitchCase="DemurrageDespatchColumnType.Port">
              <div class="w-full text-left">
                {{ getEventFormValue(eventForm).port?.name || NOT_APPLICABLE }}
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="DemurrageDespatchColumnType.Demurrage">
              <div class="w-full flex align-items-center gap-1">
                <label [for]="'dem_des_form-event-demurrage_rate' + i" class="text-xxs">{{
                  LaytimeLabel.Demurrage
                }}</label>
                <estimator-price-and-currency-input
                  [showCurrency]="false"
                  [priceFormControl]="getEventForm(eventForm).controls.meta.controls.demurrage_rate"
                  [disabled]="disableDemDesRates(demDesForm, eventIndex) || isFixed"
                  [price]="getEventForm(eventForm).controls.meta.controls.demurrage_rate.value"
                  [id]="'dem_des_form_cargo_demurrage_rate_' + i + '_' + eventIndex"
                  (priceHasChangeAndNotZero)="onChangeDemmurage($event, demDesForm)"
                ></estimator-price-and-currency-input>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="DemurrageDespatchColumnType.Despatch">
              <div class="w-full flex align-items-center gap-1">
                <label
                  [for]="'dem_des_form-event-despatch_rate' + i + '-' + eventIndex"
                  class="text-xxs"
                  >{{ LaytimeLabel.Despatch }}</label
                >
                <estimator-price-and-currency-input
                  [showCurrency]="false"
                  [priceFormControl]="getEventForm(eventForm).controls.meta.controls.despatch_rate"
                  [disabled]="disableDemDesRates(demDesForm, eventIndex) || isFixed"
                  [price]="getEventForm(eventForm).controls.meta.controls.despatch_rate.value"
                  [id]="'dem_des_form_cargo_despatch_rate_' + i + '_' + eventIndex"
                ></estimator-price-and-currency-input>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="DemurrageDespatchColumnType.Quantity">
              <span
                [ngClass]="{
                  'show-invalid': true
                }"
              >
                <p-inputNumber
                  [useGrouping]="false"
                  [minFractionDigits]="0"
                  [maxFractionDigits]="2"
                  [min]="0"
                  [max]="MAX_FINANCE_VALUE"
                  [step]="0"
                  [formControl]="
                    getEventForm(eventForm).controls.meta.controls.laytime_data.controls.amount
                  "
                  class="p-inputtext-sm w-full"
                  styleClass="w-full"
                  inputStyleClass="w-full"
                  [inputId]="'dem_des_form_event_amount_' + i + '_' + eventIndex"
                  estimatorDecimals
                ></p-inputNumber>
              </span>
            </ng-container>
            <ng-container *ngSwitchCase="DemurrageDespatchColumnType.LDRate">
              <div class="flex w-full">
                <p-inputNumber
                  [useGrouping]="false"
                  [minFractionDigits]="0"
                  [maxFractionDigits]="2"
                  [min]="0"
                  [max]="MAX_FINANCE_VALUE"
                  [step]="0"
                  [formControl]="
                    getEventForm(eventForm).controls.meta.controls.laytime_data.controls.work_amount
                  "
                  class="p-inputtext-sm w-8"
                  styleClass="w-full"
                  inputStyleClass="w-full"
                  [inputId]="'dem_des_form_event_work_amount_' + i + '_' + eventIndex"
                  estimatorDecimals
                ></p-inputNumber>
                <p-dropdown
                  [options]="workTypeOptions"
                  [formControl]="
                    getEventForm(eventForm).controls.meta.controls.laytime_data.controls.work_type
                  "
                  appendTo="body"
                  class="p-inputtext-sm hide-dropdown-button w-4"
                  styleClass="w-full"
                  inputStyleClass="w-full"
                  [inputId]="'dem_des_form_event_work_type_' + i + '_' + eventIndex"
                >
                </p-dropdown>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="DemurrageDespatchColumnType.Terms">
              <div class="flex w-full">
                <p-dropdown
                  [options]="cargoRules"
                  [formControl]="
                    getEventForm(eventForm).controls.meta.controls.laytime_data.controls.terms_id
                  "
                  appendTo="body"
                  placeholder="Terms"
                  class="p-inputtext-sm hide-dropdown-button w-8"
                  styleClass="w-full"
                  inputStyleClass="w-full"
                  optionLabel="name"
                  optionValue="id"
                  [inputId]="'dem_des_form_event_terms_id_' + i + '_' + eventIndex"
                >
                  <ng-template let-rule pTemplate="item">
                    {{ rule.name }}
                    {{ rule.coefficient ? (rule.coefficient | number : '.0-2') : '' }}
                  </ng-template>
                </p-dropdown>
                <div class="w-4 flex align-items-center justify-content-center">
                  <span
                    class="text-overflow-ellipsis text-xxs"
                    [ngClass]="{ hidden: getTermsCoefficient(eventForm) === 0 }"
                    >{{ getTermsCoefficient(eventForm) | number : '.0-2' }}</span
                  >
                </div>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="DemurrageDespatchColumnType.Allowed">
              <estimator-user-duration-input
                [userInputDuration]="calculateAllowedOrActual(eventForm, true)"
                [disabled]="true"
                [hideDurationType]="true"
                [id]="'dem_des_form_event_idle_minutes' + i + '_' + eventIndex"
              ></estimator-user-duration-input>
            </ng-container>
            <ng-container *ngSwitchCase="DemurrageDespatchColumnType.Actual">
              <estimator-user-duration-input
                [userInputDuration]="calculateAllowedOrActual(eventForm)"
                [disabled]="true"
                [hideDurationType]="true"
                [id]="'dem_des_form_event_extra_working_minutes_' + i + '_' + eventIndex"
              ></estimator-user-duration-input>
            </ng-container>
            <ng-container *ngSwitchCase="DemurrageDespatchColumnType.Difference">
              <estimator-user-duration-input
                [userInputDuration]="
                  getEventForm(eventForm).controls.meta.controls.laytime_difference_minutes.value ||
                  emptyDuration
                "
                [control]="
                  getEventForm(eventForm).controls.meta.controls.laytime_difference_minutes
                "
                [disabledDuration]="true"
                [ignoreChange]="true"
                [id]="'dem_des_form_event_idle_minutes_' + i + '_' + eventIndex"
              ></estimator-user-duration-input>
            </ng-container>
            <ng-container *ngSwitchCase="DemurrageDespatchColumnType.Result">
              <span
                [class]="getResultTextColor(demDesForm, eventForm, eventIndex)"
                [id]="'dem_des_column_type_result_' + i + '_' + eventIndex"
                >{{ getResult(demDesForm, eventForm, eventIndex) }}</span
              >
            </ng-container>
            <ng-container *ngSwitchDefault>
              {{ col.field }}
            </ng-container>
          </ng-container>
        </td>
      </tr></ng-template
    >
  </p-table>
</div>
