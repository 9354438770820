import { MapFeatureProperties, MapTypes } from '@estimator/models';
import { Feature, GeoJsonProperties, Geometry, Point, Position } from 'geojson';
import { MapLayerMouseEvent, MapboxGeoJSONFeature } from 'mapbox-gl';

export const onMouseEnter = (evt: MapLayerMouseEvent): void => {
  evt.target.getCanvas().style.cursor = 'pointer';
};

export const onMouseLeave = (evt: MapLayerMouseEvent): void => {
  evt.target.getCanvas().style.cursor = '';
};

export const offsetFlyToVessel = [400, -200] as [number, number];

export const addPlusTimeZone = (timeZoneNumber: number) => {
  return timeZoneNumber > 0 ? '+' + timeZoneNumber : timeZoneNumber;
};

export const getPointForMap = (
  feature: Feature<Geometry, GeoJsonProperties>[] | MapboxGeoJSONFeature[]
) => {
  const geometry = feature[0]?.geometry as GeoJsonProperties;
  const point: Feature<Point, GeoJsonProperties> = {
    type: MapTypes.Feature,
    properties: feature[0]?.properties as GeoJsonProperties,
    geometry: {
      type: MapTypes.Point,
      coordinates: geometry?.[MapFeatureProperties.Coordinates],
    },
  };
  return point;
};

export const errorZoomCluster = `Error when zooming a cluster`;

export interface OurGeometry {
  type: MapTypes.Point;
  coordinates: Position | Position[];
}
