import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  emptyFeatureCollection,
  makeFeaturePort,
  setImageFieldToFeatures,
} from '@estimator/helpers';
import {
  GeoZone,
  NotificationType,
  Port,
  ShiplistMapDialogConfig,
  ShiplistSelectedZoneOrPort,
  ShiplistSelectedZoneOrPortEmitter,
  ShiplistZoneType,
  ZoneTypes,
} from '@estimator/models';
import { NotificationService } from '@estimator/services';
import { FeatureCollection, Polygon } from 'geojson';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Subject, takeUntil } from 'rxjs';
import { RouteMapComponent } from '../route-map/route-map.component';

@Component({
  selector: 'estimator-shiplist-map-dialog',
  templateUrl: './shiplist-map-dialog.component.html',
  styleUrls: ['./shiplist-map-dialog.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShiplistMapDialogComponent implements OnDestroy {
  @ViewChild('shiplistMap') shiplistMap?: RouteMapComponent;
  @Output() requestShiplistZones = new EventEmitter<ShiplistZoneType>();
  @Output() searchPort = new EventEmitter<string>();
  @Output() saveFilterZonesPorts = new EventEmitter<ShiplistSelectedZoneOrPort[]>();
  shiplistZones: FeatureCollection<Polygon> = emptyFeatureCollection();
  shiplistZonesArray: GeoZone[] = [];
  ports: Port[] = [];
  selectedValues: ShiplistSelectedZoneOrPort[] = [];
  portsPoints: FeatureCollection = emptyFeatureCollection();
  selectedShiplistZoneType = ShiplistZoneType.ZONE;
  private _onDestroy$ = new Subject<void>();

  constructor(
    private readonly dialogConfig: DynamicDialogConfig<ShiplistMapDialogConfig>,
    private readonly cdr: ChangeDetectorRef,
    private readonly notificationService: NotificationService
  ) {
    if (this.dialogConfig.data) {
      const {
        shiplistZones,
        shiplistZonesArray,
        requestShiplistZones,
        searchPort,
        saveFilterZonesPorts,
        foundShiplistZones,
        foundShiplistZonesArray,
        foundPorts,
        selectedValues,
        selectedPorts,
      } = this.dialogConfig.data;
      if (shiplistZones) {
        this.shiplistZones = shiplistZones;
      }
      if (shiplistZonesArray) {
        this.shiplistZonesArray = shiplistZonesArray;
      }
      if (requestShiplistZones) {
        this.requestShiplistZones = requestShiplistZones;
      }
      if (searchPort) {
        this.searchPort = searchPort;
      }
      if (saveFilterZonesPorts) {
        this.saveFilterZonesPorts = saveFilterZonesPorts;
      }
      if (selectedValues) {
        this.selectedValues = selectedValues;
      }
      if (selectedPorts) {
        for (let index = 0; index < selectedPorts?.length; index++) {
          const port = selectedPorts[index];
          this.addPortPointAndPreparePortsPoints(port, index === selectedPorts?.length - 1);
        }
      }
      foundShiplistZones?.pipe(takeUntil(this._onDestroy$)).subscribe((shiplistZones) => {
        this.shiplistZones = shiplistZones;
        this.cdr.detectChanges();
        this.highlightZones();
      });
      foundShiplistZonesArray?.pipe(takeUntil(this._onDestroy$)).subscribe((shiplistZones) => {
        this.shiplistZonesArray = shiplistZones;
        this.cdr.detectChanges();
      });
      foundPorts?.pipe(takeUntil(this._onDestroy$)).subscribe((ports) => {
        this.ports = ports;
        this.cdr.detectChanges();
      });
    }
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  onChangeZoneType(type: ShiplistZoneType): void {
    this.selectedShiplistZoneType = type;
    this.requestShiplistZones.emit(type);
  }

  onSearchPort(portValue: string): void {
    this.searchPort.emit(portValue);
  }

  onSelectZone(object: ShiplistSelectedZoneOrPortEmitter): void {
    if (object.add) {
      const existedValue = this.selectedValues.find(
        (value) => value.id === object.value.id && value.name === object.value.name
      );
      if (!existedValue) {
        this.selectedValues.push(object.value);
        this.shiplistMap?.changeSelectedZone(object.value, true);

        // add portPoint to array portsPoints
        if (!object.value.is_zone) {
          this.addPortPointAndPreparePortsPoints(object.port as Port);
        }
      }
    } else {
      this.selectedValues = this.selectedValues.filter((value) => value.id !== object.value.id);
    }
  }

  onRemoveValue(option: ShiplistSelectedZoneOrPort): void {
    // remove selected zone from map highlight
    this.shiplistMap?.changeSelectedZone(option, false);
    // remove unselected value from selectedValues
    this.selectedValues = this.selectedValues.filter((value) => value.id !== option.id);

    // remove unselected port from portsPoints
    this.portsPoints.features = this.portsPoints.features.filter(
      (feature) => feature?.properties && feature?.properties['id'] !== option?.id
    );
    this.preparePortsPoints();
  }

  preparePortsPoints(): void {
    setImageFieldToFeatures(this.portsPoints);
    if (this.shiplistMap) {
      this.shiplistMap?.setPortPoints(this.portsPoints);
    }
  }

  sortFromZonesToPort(selectedValues: ShiplistSelectedZoneOrPort[]): ShiplistSelectedZoneOrPort[] {
    return selectedValues.sort((a, b) => {
      if (a.is_zone !== b.is_zone) {
        return +b.is_zone - +a.is_zone;
      }
      if (a.type && b.type) {
        return a.type.localeCompare(b.type);
      }
      return 0;
    });
  }

  getLabelForSelectedValuesList(): string {
    const zonesQuantity = this.selectedValues.filter((value) => value.is_zone).length;
    const portsQuantity = this.selectedValues.filter((value) => !value.is_zone).length;
    return `Selected zones (${zonesQuantity}) and ports (${portsQuantity}):`;
  }

  onRequestSaveFilter(): void {
    if (this.selectedValues?.length) {
      this.saveFilterZonesPorts.emit(this.selectedValues);
    }
  }

  highlightZones(): void {
    // highlight previous selected zones
    // find IDS
    const selectedValuesInChosenZone = this.selectedValues.reduce((acc: number[], curr) => {
      if (/* curr.type === this.selectedShiplistZoneType */ curr.is_zone) {
        acc.push(curr?.id || 0);
        return acc;
      }
      return acc;
    }, []);
    // find zone_IDS
    const selectedValuesInChosenZone2 = this.selectedValues.reduce((acc: number[], curr) => {
      if (curr.is_zone && curr.zone_ids) {
        acc.push(...curr.zone_ids);
        return acc;
      }
      return acc;
    }, []);
    // if (selectedValuesInChosenZone?.length) {
    this.shiplistMap?.multipleHighlightZones(
      selectedValuesInChosenZone,
      selectedValuesInChosenZone2
    );
    // }
  }

  onMapReady(): void {
    this.highlightZones();
  }

  onShowNotification(): void {
    this.notificationService.showNotification(
      `Zone selection via the map is not available in mode ${ZoneTypes.RANGES}`,
      NotificationType.Warning
    );
  }

  addPortPointAndPreparePortsPoints(port: Port, prepare = true): void {
    this.portsPoints.features.push(makeFeaturePort(port));
    if (prepare) {
      this.preparePortsPoints();
    }
  }
}
