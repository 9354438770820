import { DecimalPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  DurationPipe,
  isCargoEvent,
  isDealEventTypeCellChanged,
  isSecondaryEvent,
} from '@estimator/helpers';
import {
  BaseModel,
  CARGO_EVENT_ROW_HEIGHT,
  Cargo,
  CargoRules,
  DealEvent,
  DealEventType,
  Fuel,
  NOT_APPLICABLE,
  OPACITY_5,
  PrimeNgColors,
  WorkType,
  ZERO_STRING,
} from '@estimator/models';
import {
  CellClassParams,
  ColDef,
  ColumnApi,
  ColumnMovedEvent,
  ColumnResizedEvent,
  ColumnState,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ICellRendererParams,
  ITooltipParams,
  ValueFormatterParams,
} from 'ag-grid-community';
import { cloneDeep, isEqual } from 'lodash';
import { AgGridAutocompleteComponent } from '../ag-grid-shared/ag-grid-autocomplete-editor.component';
import { AgGridBasicTooltipComponent } from '../ag-grid-shared/ag-grid-basic-tooltip.component';
import { AgGridCargoTypeComponent } from '../ag-grid-shared/ag-grid-cargo-type-editor.component';
import { AgGridCheckboxCellComponent } from '../ag-grid-shared/ag-grid-checkbox-editor.component';
import { AgGridNumericCellEditorComponent } from '../ag-grid-shared/ag-grid-numeric-editor.component';
import { AgGridSelectCellEditorComponent } from '../ag-grid-shared/ag-grid-select-editor.component';
import { AgGridTimeDurationEditorComponent } from '../ag-grid-shared/ag-grid-time-duration-editor.component';
import { AgGridButtonGroupComponent } from '../ag-grid-shared/button-group.component';

@Component({
  selector: 'estimator-event-cargo-list',
  templateUrl: './event-cargo-list.component.html',
  styleUrls: ['./event-cargo-list.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventCargoListComponent {
  @Input()
  set events(data: DealEvent[]) {
    if (data && data.length) {
      this._events = data;
      this._inputEvents = cloneDeep(data);
    }
  }
  get events(): DealEvent[] {
    return this._events;
  }
  @Input()
  set cargoes(data: Cargo[]) {
    if (data && data.length) {
      this._cargoes = data;
    } else {
      this._cargoes = [];
    }
  }
  get cargoes(): Cargo[] {
    return this._cargoes;
  }
  @Input()
  set isLoading(bool: boolean) {
    this._isLoading = bool;
    if (this._gridApi && bool) {
      this._gridApi.showLoadingOverlay();
    } else if (this._gridApi && !bool) {
      this._gridApi.hideOverlay();
    }
  }
  get isLoading(): boolean {
    return this._isLoading;
  }

  @Input() cargoRules: CargoRules[] = [];
  @Input() baseEvent?: DealEvent;
  @Input() fuels: Fuel[] = [];
  @Input() tableState: ColumnState[] = [];
  @Output() addCargoEvent = new EventEmitter<{ stopEvent: DealEvent }>();
  @Output() addCargo = new EventEmitter<DealEvent>();
  @Output() deleteEvent = new EventEmitter<DealEvent>();
  @Output() updateEvent = new EventEmitter<DealEvent>();
  @Output() saveTableState = new EventEmitter<ColumnState[]>();

  columnDefs: ColDef<DealEvent>[] = [
    {
      field: 'action',
      headerComponent: 'buttonRenderer',
      headerComponentParams: (params: any) => {
        return {
          ...params,
          buttons: [
            {
              title: 'Add cargo event',
              tooltip: 'Add cargo event',
              icon: 'add',
              color: PrimeNgColors.White,
              click: () => this.onAddCargoEvent(),
            },
          ],
        };
      },
      cellRenderer: 'buttonRenderer',
      cellRendererParams: (params: ICellRendererParams) => {
        return {
          ...params,
          buttons: [
            {
              title: 'Delete cargo event',
              tooltip: 'Delete cargo event',
              icon: 'close',
              click: () => this.onDeleteEvent(params),
            },
          ],
        };
      },
      cellClass: 'center-vertical-aligned-cell',
      minWidth: 50,
      width: 50,
    },
    {
      field: 'type',
      headerName: 'Type',
      flex: 1,
      editable: true,
      cellEditor: 'selectEditor',
      cellEditorParams: (params: any) => {
        return {
          ...params,
          dataArray: [
            DealEventType.EventTypeLoad,
            DealEventType.EventTypeDischarge,
            DealEventType.EventTypeBunker,
          ],
          closeEditor: this.closeEditor.bind(this),
        };
      },
      onCellValueChanged: (params) => {
        if (isDealEventTypeCellChanged(params)) {
          if (params.data.meta) {
            delete params.data.meta.terms_id;
            delete params.data.meta.amount;
            delete params.data.meta.load_discharge_rate;
          }
        }
      },
    },
    {
      field: 'cargo_id',
      headerName: 'Cargo',
      flex: 1,
      editable: true,
      cellEditorSelector: (params: any) => {
        const event: DealEvent | undefined = params.data;
        if (event && isCargoEvent(event)) {
          return {
            component: 'cargoEditor',
            params: {
              ...params,
              dataArray: this.cargoes,
              placeholder: 'Cargo order',
              emptyDataArray: 'No cargo found',
              onAddNewCargo: this.onAddCargo.bind(this, event),
              closeEditor: this.closeEditor.bind(this),
            },
          };
        }
        if (event?.meta?.fuel_id) {
          params.value = event.meta.fuel_id;
        }
        return {
          component: 'selectEditor',
          params: {
            ...params,
            dataArray: this.fuels,
            visibleKeys: ['name'],
            closeEditor: this.closeEditor.bind(this),
          },
        };
      },
      valueFormatter: (params: ValueFormatterParams<DealEvent>) => {
        const dealEvent: DealEvent | undefined = params.data;
        let result = NOT_APPLICABLE;
        if (dealEvent && isCargoEvent(dealEvent)) {
          const cargo = this._cargoes.find((cargo) => {
            return cargo.id === dealEvent?.cargo_id;
          });
          if (cargo) {
            result = `#${cargo.internal_order?.toString() || NOT_APPLICABLE}`;
          }
        } else if (dealEvent?.type === DealEventType.EventTypeBunker) {
          const fuel = this.fuels.find((fuel) => {
            return fuel.id === dealEvent.meta?.fuel_id;
          });
          if (fuel?.id) {
            result = `${fuel.name || NOT_APPLICABLE}`;
          }
        }
        return result;
      },
    },
    {
      field: 'meta.amount',
      headerName: 'Quantity',
      editable: true,
      cellEditor: 'numericEditor',
      valueFormatter: (params) => {
        return this.numberPipe.transform(params.value) || ZERO_STRING;
      },
      flex: 1,
    },
    /* {
      field: 'meta.load_discharge_rate',
      headerName: 'L/D rate',
      editable: (params) => {
        const event: DealEvent | undefined = params.data;
        if (event) {
          return isCargoEvent(event);
        }
        return false;
      },
      cellClass: (params) => this.getCargoBaseCellClass(params),
      cellEditor: 'numericEditor',
      valueFormatter: (params) => {
        return this.numberPipe.transform(params.value) || ZERO_STRING;
      },
      flex: 1,
    }, */
    {
      field: 'meta.extra_minutes_before',
      headerName: 'Extra time before',
      editable: true,
      cellEditor: 'durationEditor',
      cellEditorParams: { format: 'd' },
      valueFormatter: (params) => {
        if (params.value) {
          return this.durationPipe.transform(params.value, 'd', 2);
        }
        return '0 d';
      },
      flex: 1,
    },
    {
      field: 'single_inner_event.meta.work_amount',
      headerName: 'WA',
      editable: (params) => {
        const dealEvent: DealEvent | undefined = params.data?.single_inner_event;
        if (dealEvent) {
          return isSecondaryEvent(dealEvent);
        }
        return false;
      },
      cellEditor: 'numericEditor',
      valueFormatter: (params) => {
        const dealEvent: DealEvent | undefined = params.data?.single_inner_event;
        if (dealEvent && isSecondaryEvent(dealEvent)) {
          return this.numberPipe.transform(params.value) || ZERO_STRING;
        }
        return '';
      },
      flex: 1,
    },
    {
      field: 'single_inner_event.meta.work_type',
      headerName: 'WT',
      flex: 1,
      editable: (params) => {
        const event: DealEvent | undefined = params.data;
        if (event) {
          return isCargoEvent(event);
        }
        return false;
      },
      cellClass: (params) => this.getCargoBaseCellClass(params),
      cellEditor: 'selectEditor',
      cellEditorParams: (params: any) => {
        return {
          ...params,
          dataArray: this.workTypeOptions,
          closeEditor: this.closeEditor.bind(this),
        };
      },
      valueFormatter: (params: ValueFormatterParams<DealEvent>) => {
        const dealEvent = params.data?.single_inner_event;
        if (dealEvent) {
          return dealEvent.meta?.work_type || NOT_APPLICABLE;
        }
        return '';
      },
    },
    {
      field: 'meta.terms_id',
      headerName: 'Terms',
      flex: 1,
      editable: (params) => {
        const event: DealEvent | undefined = params.data;
        if (event) {
          return isCargoEvent(event);
        }
        return false;
      },
      cellClass: (params) => this.getCargoBaseCellClass(params),
      cellEditor: 'autoCompleteEditor',
      cellEditorParams: (params: any) => {
        return {
          ...params,
          dataArray: this.cargoRules,
          placeholder: 'Terms',
          emptyDataArray: 'No terms found',
          visibleKeys: ['name'],
          resultKey: 'id',
          closeEditor: this.closeEditor.bind(this),
        };
      },
      valueFormatter: (params: ValueFormatterParams<DealEvent>) => {
        const dealEvent = params.data;
        if (dealEvent) {
          const term = this.cargoRules.find((term) => term.id === dealEvent.meta?.terms_id);
          if (term?.name) {
            return term.name;
          }
        }
        return NOT_APPLICABLE;
      },
    },
    {
      field: 'meta.working_minutes.user',
      headerName: 'Working time',
      editable: true,
      cellEditor: 'durationEditor',
      cellClass: (params) => {
        const event: DealEvent | undefined = params.data;
        if (event?.meta?.working_minutes?.is_changed) {
          return 'user-changed-cell';
        }
        return '';
      },
      tooltipField: 'meta.working_minutes.is_changed',
      tooltipComponent: 'simpleTooltip',
      tooltipComponentParams: (params: { textValue: string } & ITooltipParams) => {
        return {
          ...params,
          textValue: params.data?.meta?.working_minutes?.is_changed ? 'Manual changed' : '',
        };
      },
      cellEditorParams: (params: any) => {
        const event: DealEvent = params.data;
        params.value =
          (event.meta?.working_minutes?.is_changed
            ? event.meta.working_minutes.user
            : event.meta?.working_minutes?.system) || 0;
        return {
          ...params,
          format: 'd',
        };
      },
      valueFormatter: (params) => {
        if (params.data) {
          const event: DealEvent = params.data;
          return this.durationPipe.transform(
            (event.meta?.working_minutes?.is_changed
              ? event.meta.working_minutes.user
              : event.meta?.working_minutes?.system) || 0,
            'd',
            2
          );
        }
        return '0 d';
      },
      flex: 1,
      onCellValueChanged: (event) => {
        const dealEvent: DealEvent = event.data;
        if (
          event.newValue !== event.oldValue &&
          event.newValue !== dealEvent.meta?.working_minutes?.system
        ) {
          if (dealEvent.meta?.working_minutes) {
            if (event.newValue || event.newValue === 0) {
              dealEvent.meta.working_minutes.user = event.newValue;
              dealEvent.meta.working_minutes.is_changed = true;
            } else {
              dealEvent.meta.working_minutes.user = 0;
              dealEvent.meta.working_minutes.is_changed = false;
            }
          }
        }
      },
    },
    {
      field: 'meta.idle_minutes',
      headerName: 'Idle time',
      editable: (params) => {
        const event: DealEvent | undefined = params.data;
        if (event) {
          return isCargoEvent(event) && !event.meta?.working_minutes?.is_changed;
        }
        return false;
      },
      cellClass: (params) => {
        const event: DealEvent | undefined = params.data;
        if (event) {
          return isCargoEvent(event) && !event.meta?.working_minutes?.is_changed ? '' : OPACITY_5;
        }
        return OPACITY_5;
      },
      cellEditor: 'durationEditor',
      cellEditorParams: { format: 'd' },
      valueFormatter: (params) => {
        if (params.value) {
          return this.durationPipe.transform(params.value, 'd', 2);
        }
        return '0 d';
      },
      flex: 1,
    },
    {
      field: 'meta.extra_minutes_after',
      headerName: 'Extra time after',
      editable: true,
      cellEditor: 'durationEditor',
      cellEditorParams: { format: 'd' },
      valueFormatter: (params) => {
        if (params.value) {
          return this.durationPipe.transform(params.value, 'd', 2);
        }
        return '0 d';
      },
      flex: 1,
    },
    {
      field: 'parallel_with',
      headerName: 'Parallel with',
      editable: (params) => {
        const currentEvent: DealEvent | undefined = params.data;
        if (currentEvent) {
          return !this.events.some((event) => event.parallel_with === currentEvent.id);
        }
        return true;
      },
      cellClass: (params) => {
        const currentEvent: DealEvent | undefined = params.data;
        if (currentEvent) {
          return this.events.some((event) => event.parallel_with === currentEvent.id)
            ? OPACITY_5
            : '';
        }
        return '';
      },
      cellEditor: 'selectEditor',
      cellEditorParams: (params: any) => {
        const currentEvent: DealEvent = params.data;
        let dataArray: DealEvent[] = [];
        if (currentEvent) {
          dataArray = this.events
            .filter((event) => event.id !== currentEvent.id && !event.parallel_with)
            .map((event) => this.eventToBaseModel(event));
        }
        return {
          ...params,
          dataArray,
          visibleKeys: ['name'],
          closeEditor: this.closeEditor.bind(this),
        };
      },
      onCellValueChanged: (event) => {
        const dealEvent = event.data;
        if (dealEvent && dealEvent.parallel_with) {
          if (isNaN(+dealEvent.parallel_with)) {
            const base = dealEvent.parallel_with as BaseModel;
            dealEvent.parallel_with = base.id;
          }
        }
      },
      valueFormatter: (params) => {
        const currentEvent: DealEvent | undefined = params.data;
        if (currentEvent?.parallel_with) {
          const parallelEvent = this.events.find((elem) => elem.id === currentEvent.parallel_with);
          if (parallelEvent) {
            return this.eventToBaseModel(parallelEvent).name;
          }
        }
        return 'No';
      },
      flex: 1,
    },
  ];
  gridOptions: GridOptions<DealEvent> = {
    rowModelType: 'clientSide',
    columnDefs: this.columnDefs,
    components: {
      buttonRenderer: AgGridButtonGroupComponent,
      autoCompleteEditor: AgGridAutocompleteComponent,
      cargoEditor: AgGridCargoTypeComponent,
      numericEditor: AgGridNumericCellEditorComponent,
      checkboxRenderer: AgGridCheckboxCellComponent,
      durationEditor: AgGridTimeDurationEditorComponent,
      selectEditor: AgGridSelectCellEditorComponent,
      simpleTooltip: AgGridBasicTooltipComponent,
    },
    getRowId: (params) => {
      return params.data.id ? params.data.id.toString() : params.data.unique_row_id || ZERO_STRING;
    },
    defaultColDef: {
      editable: false,
      resizable: true,
    },
    suppressContextMenu: true,
    singleClickEdit: true,
    animateRows: false,
    suppressAnimationFrame: true,
    suppressColumnMoveAnimation: true,
    rowHeight: CARGO_EVENT_ROW_HEIGHT,
    headerHeight: CARGO_EVENT_ROW_HEIGHT,
    autoSizePadding: 0,
    suppressNoRowsOverlay: true,
    tooltipShowDelay: 0,
    tooltipMouseTrack: true,
    onCellEditingStopped: (event) => {
      const column = event.column.getColId();
      const dealEvent: DealEvent | undefined = event.data;
      const oldEvent = this._inputEvents.find((el) => el.id === dealEvent.id);
      switch (column) {
        case 'cargo_id': {
          if (dealEvent) {
            if (!dealEvent.meta) {
              dealEvent.meta = {};
            }
            if (dealEvent.type === DealEventType.EventTypeBunker) {
              const fuel: Fuel = event.newValue;
              if (fuel && fuel.id) {
                dealEvent.meta.fuel_id = fuel.id;
              }
            }
            if (isCargoEvent(dealEvent)) {
              const cargo = this.cargoes.find((el) => el.id === dealEvent?.cargo_id);
              if (cargo && cargo.quantity) {
                dealEvent.meta.amount = cargo.quantity;
              }
            }
          }
          break;
        }
        default:
          break;
      }
      if (
        dealEvent &&
        !this._gridApi?.getEditingCells().length &&
        ((event.newValue && event.newValue !== event.oldValue) ||
          !isEqual(dealEvent, oldEvent) ||
          !isEqual(dealEvent.meta, oldEvent?.meta))
      ) {
        this.updateEvent.emit(dealEvent);
      }
    },
    onColumnResized: (event) => this.changeTableState(event),
    onColumnMoved: (event) => this.changeTableState(event),
  };
  readonly workTypeOptions = Object.values(WorkType);
  private _events: DealEvent[] = [];
  private _inputEvents: DealEvent[] = [];
  private _cargoes: Cargo[] = [];
  private _gridApi?: GridApi<Cargo>;
  private _gridColumnApi?: ColumnApi;
  private _isLoading = false;

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this._gridApi?.stopEditing(true);
    }
    if (event.key === 'Enter') {
      this._gridApi?.stopEditing();
    }
  }

  constructor(
    private readonly durationPipe: DurationPipe,
    private readonly numberPipe: DecimalPipe
  ) {}

  onGridReady({ api, columnApi }: GridReadyEvent): void {
    this._gridApi = api;
    this._gridColumnApi = columnApi;
    if (this.tableState.length) {
      this._gridColumnApi.applyColumnState({ state: this.tableState });
    }
  }

  onAddCargoEvent(): void {
    if (this.baseEvent) {
      this.addCargoEvent.emit({ stopEvent: this.baseEvent });
    }
  }

  onDeleteEvent(event: ICellRendererParams<DealEvent>): void {
    if (event.data) {
      this.deleteEvent.emit(event.data);
    }
  }

  onAddCargo(event: DealEvent): void {
    this.addCargo.emit(event);
    if (this._gridApi) {
      this._gridApi.stopEditing();
    }
  }

  closeEditor(): void {
    if (this._gridApi) {
      this._gridApi.stopEditing();
    }
  }

  eventToBaseModel(event: DealEvent): BaseModel {
    if (isCargoEvent(event)) {
      const cargo = this.cargoes.find((elem) => elem.id === event?.cargo_id);
      return {
        id: event.id,
        name: `${event.type} #${cargo?.internal_order || NOT_APPLICABLE}`,
      };
    }
    const fuel = this.fuels.find((elem) => elem.id === event.meta?.fuel_id);
    return {
      id: event.id,
      name: `${event.type} #${fuel?.name || NOT_APPLICABLE}`,
    };
  }

  getCargoBaseCellClass(params: CellClassParams): string {
    const event: DealEvent | undefined = params.data;
    if (event) {
      return isCargoEvent(event) ? '' : OPACITY_5;
    }
    return OPACITY_5;
  }

  changeTableState(event: ColumnResizedEvent<DealEvent> | ColumnMovedEvent<DealEvent>): void {
    const state = event.columnApi.getColumnState();
    if (!isEqual(state, this.tableState)) {
      this.saveTableState.emit(state);
    }
  }
}
