import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { XmarBunkerPriceIndexTableComponent } from './xbpi-table.component';

export const XBPITableModuleImports = [
  CommonModule,
  ButtonModule,
  ReactiveFormsModule,
  AutoCompleteModule,
  TableModule,
];
@NgModule({
  declarations: [XmarBunkerPriceIndexTableComponent],
  imports: XBPITableModuleImports,
  exports: [XmarBunkerPriceIndexTableComponent],
})
export class XmarBunkerPriceIndexTableModule {}
