import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DealDatePlannerComponent } from './deal-date-planner.component';
import { AgGridModule } from 'ag-grid-angular';

@NgModule({
  declarations: [DealDatePlannerComponent],
  imports: [CommonModule, AgGridModule],
  exports: [DealDatePlannerComponent],
})
export class DealDatePlannerModule {}
