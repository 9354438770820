import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { PhoneNumberInputComponent } from './phone-number-input.component';

@NgModule({
  declarations: [PhoneNumberInputComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatAutocompleteModule,
  ],
  exports: [PhoneNumberInputComponent],
})
export class PhoneNumberInputModule {}
