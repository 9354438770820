<div>
  <p-table
    [value]="estimationsNavigationLinks"
    [(selection)]="selectedDeals"
    dataKey="id"
    class="primeng-form"
    styleClass="p-datatable-sm font-size-12"
  >
    <ng-template pTemplate="header">
      <tr>
        <th class="text-center">
          <!--  <p-tableHeaderCheckbox></p-tableHeaderCheckbox> -->
        </th>
        <th>Vessel</th>
        <th>Deal name</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-deal>
      <tr>
        <td>
          <p-tableCheckbox
            [ngStyle]="{
              visibility: !(showInitialCheckbox(deal) && showSelectedVesselCheckbox(deal))
                ? 'hidden'
                : 'inherit'
            }"
            [value]="deal"
          ></p-tableCheckbox>
        </td>
        <td class="text-left">{{ deal.vesselName }}</td>
        <td class="text-left">{{ deal.name }}</td>
      </tr>
    </ng-template>
  </p-table>
  <estimator-copy-folder-dialog
    class="pt-3"
    label="FIX FOLDER"
    [filteredFolders]="filteredFolders"
    [disabledBtn]="selectedDeals.length === 0"
    [folderName]="folderName || ''"
    (searchFolder)="onSearchFolder($event)"
    (copyFolder)="onCopyFolder($event)"
  ></estimator-copy-folder-dialog>
</div>
