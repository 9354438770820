import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { BunkerSelectDialogComponent } from './bunker-select-dialog.component';

export const BUNKER_SELECT_DIALOG_IMPORTS = [
  CommonModule,
  ButtonModule,
  DropdownModule,
  ReactiveFormsModule,
];

@NgModule({
  declarations: [BunkerSelectDialogComponent],
  imports: BUNKER_SELECT_DIALOG_IMPORTS,
  exports: [BunkerSelectDialogComponent],
})
export class BunkerSelectDialogModule {}
