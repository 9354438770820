<form
  [formGroup]="sundryFormGroup"
  class="grid grid-nogutter primeng-form p-2 border-round-md estimator-section"
  id="syndry_form"
>
  <div class="col-4 flex flex-column gap-1">
    <span
      *ngFor="let control of iterableControls; let i = index"
      class="flex flex-row align-items-center gap-1"
    >
      <label class="small-label w-full">{{
        sundryFormGroup.controls[control].controls.description.value
      }}</label>
      <span class="p-inputgroup" #miscControls>
        <estimator-price-and-currency-input
          [id]="'syndry_form-iterable-control-amount' + i"
          [price]="sundryFormGroup.controls[control].controls.amount.value"
          [priceFormControl]="sundryFormGroup.controls[control].controls.amount"
          [customTabIndex]="tabIndex"
          [showCurrency]="false"
          [max]="MAX_FINANCE_VALUE"
          [disabled]="isFixed"
        ></estimator-price-and-currency-input>
      </span>
    </span>
  </div>
  <div class="col-4 flex flex-column gap-1">
    <span
      *ngFor="let extraControl of sundryFormGroup.controls.extras.controls; let i = index"
      class="flex flex-row gap-1 align-items-center position-relative"
    >
      <p-button
        [icon]="i > 0 ? PrimeNgIcons.MINUS : PrimeNgIcons.PLUS"
        id="sundry_form-add"
        (onClick)="i > 0 ? onDeleteExtraSundry(i) : onAddExtraSundry()"
        class="add-sundry-button"
        [rounded]="true"
        [text]="true"
        size="small"
        pTooltip="Add cargo"
        tooltipPosition="top"
        [disabled]="isFixed"
      ></p-button>
      <label class="small-label w-full">{{ extraControl.controls.description.value }}</label>
      <span class="p-inputgroup">
        <estimator-price-and-currency-input
          [id]="'syndry_form-extra-control-amount' + i"
          [price]="extraControl.controls.amount.value"
          [priceFormControl]="extraControl.controls.amount"
          [customTabIndex]="tabIndex"
          [showCurrency]="false"
          [max]="MAX_FINANCE_VALUE"
          [disabled]="isFixed"
        ></estimator-price-and-currency-input>
      </span>
    </span>
  </div>
  <div class="col-4 flex flex-column gap-1">
    <span class="flex flex-row align-items-center gap-1">
      <label class="small-label w-full">Extra idle time</label>
      <estimator-user-duration-input
        [control]="sundryFormGroup.controls.extra_idle_minutes"
        [id]="'syndry_form-extra_idle_minutes'"
        [customTabIndex]="tabIndex"
        [disabled]="isFixed"
        #extraIdle
      ></estimator-user-duration-input>
    </span>
    <span class="flex flex-row align-items-center gap-1">
      <label class="small-label w-full">Extra sailing time</label>
      <estimator-user-duration-input
        [control]="sundryFormGroup.controls.extra_sailing_laden_minutes"
        [id]="'syndry_form-extra_sailing_laden_minutes'"
        [customTabIndex]="tabIndex"
        [disabled]="isFixed"
        #extraSailing
      ></estimator-user-duration-input>
    </span>
  </div>
</form>
