<!-- мои коллекции -->
<p-tabView class="fleet-vessel-collections-tab-view sticky">
  <p-tabPanel>
    <ng-template pTemplate="header">
      <div>
        <span>My fleets</span>
      </div>
    </ng-template>
    <estimator-fleet-vessel-collection
      [collections]="myCollections"
      (addCollection)="onAddCollection($event)"
      (updateCollection)="onUpdateCollection($event)"
      (deleteCollection)="onDeleteCollection($event)"
    >
    </estimator-fleet-vessel-collection>
  </p-tabPanel>
  <p-tabPanel>
    <ng-template pTemplate="header">
      <div>
        <span>My company fleets</span>
      </div>
    </ng-template>
    <!-- коллекции моих компаний -->
    <estimator-fleet-vessel-collection
      [collections]="myCompanyCollections"
      [isAddCompany]="true"
      [userCompanyId]="userCompanyId"
      (addCollection)="onAddCollection($event)"
      (updateCollection)="onUpdateCollection($event)"
      (deleteCollection)="onDeleteCollection($event)"
    >
    </estimator-fleet-vessel-collection>
  </p-tabPanel>
</p-tabView>
