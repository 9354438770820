import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from '@estimator/directives';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { ButtonSwitchModule } from '../button-switch/button-switch.module';
import { PriceAndCurrencyInputModule } from '../price-and-currency-input/price-and-currency-input.module';
import { UserDurationInputModule } from '../user-duration-input/user-duration-input.module';
import { CargoesFormComponent } from './cargoes-form.component';

export const CARGOES_FORM_IMPORTS = [
  CommonModule,
  AutoCompleteModule,
  ButtonModule,
  DropdownModule,
  InputNumberModule,
  InputTextModule,
  ReactiveFormsModule,
  TableModule,
  TooltipModule,
  DirectivesModule,
  PriceAndCurrencyInputModule,
  ButtonSwitchModule,
  UserDurationInputModule,
  OverlayPanelModule,
  InputTextareaModule,
];

@NgModule({
  declarations: [CargoesFormComponent],
  imports: CARGOES_FORM_IMPORTS,
  exports: [CargoesFormComponent],
})
export class CargoesFormModule {}
