import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
  forwardRef,
} from '@angular/core';
import { AbstractControl, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';

@Component({
  selector: 'mar-checkbox',
  template: `<label class="container-checkbox">
    <input
      type="checkbox"
      [(ngModel)]="value"
      (change)="onChange(value)"
      (blur)="onTouched()"
      [required]="required"
      [disabled]="disabled"
    />
    <span class="checkmark" [ngClass]="{ opacity: disabled, required: required }"></span>
    <ng-content></ng-content>
  </label>`,
  styles: [
    `
      @import 'variables';
      .container-checkbox {
        display: flex;
        align-items: center;
        line-height: 150%;
        position: relative;
        padding-left: 3ch;
        input {
          height: 0;
          width: 0;
          -webkit-appearance: none;
        }
        .checkmark {
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          height: 24px;
          width: 24px;
          border-radius: 4px;
          background-color: #f0f1f4;
          &.required {
            border: 1px solid $red;
          }
        }
        &:hover input ~ .checkmark {
          background-color: #dee1e7;
        }
        input:checked ~ .checkmark {
          background-color: #83a3f8;
          background-image: url(/assets/icon-check.svg);
          background-repeat: no-repeat;
          background-position: 2px 2px;
          &.required {
            border: none;
          }
        }
      }
    `,
  ],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
})
export class CheckboxComponent {
  @Input() validator?: string;
  @Input() placeholder = '';
  @Input() value?: string | number;
  @Input() required = false;
  @Input() disabled = false;

  onChange: any = (value: any) => {};
  onTouched: any = () => {};

  writeValue(obj: any): void {
    this.value = obj;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  validate(control: AbstractControl<any, any>): ValidationErrors | null {
    throw new Error('Method not implemented.');
  }
  registerOnValidatorChange?(fn: () => void): void {
    throw new Error('Method not implemented.');
  }
}
