import { FormArray, FormControl } from '@angular/forms';

export interface ProductFormGroup {
  admin_adjusted_volume: FormControl<number | null>;
  final_qty: FormControl<number | null>;
  measure: FormControl<string | null>;
  price: FormControl<number | null>;
  volume_from: FormControl<number | null>;
  volume_to: FormControl<number | null>;
  offers?: FormArray<any>;
  id: FormControl<string | null>;
  meta: {
    shortName: FormControl<string | null>;
    fullName: FormControl<string | null>;
    additional: {
      iso: FormControl<string | null>;
      type: FormControl<string | null>;
      class: FormControl<string | null>;
      grade: FormControl<string | null>;
      maxSulfur: FormControl<number | null>;
      abbreviation: FormControl<string | null>;
      measurementUnit: FormControl<string | null>;
    };
  };
}

export interface OfferFormGroup {
  id: FormControl<string | null>;
  meta: {
    shortName: FormControl<string | null>;
    fullName: FormControl<string | null>;
    additional: {
      iso: FormControl<string | null>;
      type: FormControl<string | null>;
      class: FormControl<string | null>;
      grade: FormControl<string | null>;
      maxSulfur: FormControl<number | null>;
      abbreviation: FormControl<string | null>;
      measurementUnit: FormControl<string | null>;
    };
  };
  price: FormControl<number | null>;
}
