import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { DirectivesModule } from '@estimator/directives';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { ButtonSwitchModule } from '../button-switch/button-switch.module';
import { CargoCalculatorComponent } from './cargo-calculator.component';

@NgModule({
  declarations: [CargoCalculatorComponent],
  imports: [
    CommonModule,
    DirectivesModule,
    MatButtonModule,
    /*  MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatDialogModule, */
    ReactiveFormsModule,
    DropdownModule,
    InputNumberModule,
    ButtonSwitchModule,
  ],
  exports: [CargoCalculatorComponent],
})
export class CargoCalculatorModule {}
