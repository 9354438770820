import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  DEFAULT_USER,
  ERROR_BASE_TEMPLATE,
  NotificationType,
  PrimeNgColors,
  PrimeNgIcons,
  RegistrationSuccessDialogConfig,
} from '@estimator/models';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'estimator-registration-success',
  templateUrl: './registration-success.component.html',
  styleUrls: ['./registration-success.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegistrationSuccessComponent {
  @Input() email = DEFAULT_USER;
  @Output() sendConfirmCode = new EventEmitter<string>();
  @Output() retryEmail = new EventEmitter<void>();
  invalidCodeError = '';
  readonly registrationSuccessText =
    'We send you code and link. Please, insert #code, or click the link from your email.';
  readonly PrimeNgColors = PrimeNgColors;
  readonly PrimeNgIcons = PrimeNgIcons;
  readonly NotificationType = NotificationType;

  constructor(private readonly dialogConfig: DynamicDialogConfig<RegistrationSuccessDialogConfig>) {
    if (this.dialogConfig.data) {
      this.email = this.dialogConfig.data.email;
      this.retryEmail = this.dialogConfig.data.retryEmail;
      this.sendConfirmCode = this.dialogConfig.data.sendConfirmCode;
      this.dialogConfig.data.retryEmailErrorSubject.pipe().subscribe((err) => {
        this.invalidCodeError = `${ERROR_BASE_TEMPLATE} ${err}`;
      });
    }
  }

  onSendConfirmCode(code: string): void {
    this.sendConfirmCode.emit(code);
  }

  onRetryEmail(): void {
    this.retryEmail.emit();
  }
}
