import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { fromMillisecondsToSeconds } from '@estimator/helpers';
import {
  AddVesselToMyOrCompanyFleetProps,
  FleetVessel,
  FleetVesselActiveRequest,
  FleetVesselActiveResponse,
  FleetVesselCollection,
  FleetVesselPosition,
  FleetVesselPositions,
  GetMyFleetAndFleetCompany,
  MILLISECONDS_IN_DAY,
} from '@estimator/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FleetVesselService {
  readonly path = '/api/fleet-vessels';
  readonly collectionSegmentURL = 'collection';
  constructor(protected readonly http: HttpClient) {}

  addVesselToMyFleetOrCompanyFleet(
    props: AddVesselToMyOrCompanyFleetProps
  ): Observable<FleetVessel> {
    return this.http.post<FleetVessel>(`${this.path}/add`, props);
  }

  updateVessel(vessel: FleetVessel): Observable<FleetVessel> {
    return this.http.put<FleetVessel>(`${this.path}`, vessel);
  }

  updateFleetVesselActive(
    activeRequest: FleetVesselActiveRequest,
    vesselId: number
  ): Observable<FleetVesselActiveResponse> {
    return this.http.put<FleetVesselActiveResponse>(
      `${this.path}/${vesselId}/active`,
      activeRequest
    );
  }

  deleteFleetVessel(id: number): Observable<void> {
    return this.http.put<void>(`${this.path}/${id}/removed`, { removed: true });
  }

  getMyFleetAndCollections(): Observable<GetMyFleetAndFleetCompany> {
    return this.http.get<GetMyFleetAndFleetCompany>(`${this.path}/my`);
  }

  getMyFleetCompanyAndCollections(): Observable<GetMyFleetAndFleetCompany> {
    return this.http.get<GetMyFleetAndFleetCompany>(`${this.path}/company`);
  }

  getFleetPositions(props: FleetVesselPositions): Observable<FleetVesselPosition[]> {
    props.time_from = fromMillisecondsToSeconds(props?.time_from as number);
    props.time_to = fromMillisecondsToSeconds(
      (props?.time_to ? props?.time_to : 0) + MILLISECONDS_IN_DAY
    ); // добавляем +1 день, чтобы при поиске from 12 число to 12 число получать 1 сутки
    return this.http.post<FleetVesselPosition[]>(`${this.path}/positions`, props);
  }

  // коллекции
  createFleetVesselCollection(
    collection: FleetVesselCollection
  ): Observable<FleetVesselCollection> {
    return this.http.post<FleetVesselCollection>(
      `${this.path}/${this.collectionSegmentURL}`,
      collection
    );
  }

  updateFleetVesselCollection(
    collection: FleetVesselCollection
  ): Observable<FleetVesselCollection> {
    return this.http.put<FleetVesselCollection>(
      `${this.path}/${this.collectionSegmentURL}`,
      collection
    );
  }

  deleteFleetVesselCollection(id: number): Observable<void> {
    return this.http.delete<void>(`${this.path}/${this.collectionSegmentURL}/${id}`);
  }
}
