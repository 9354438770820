import { Injectable } from '@angular/core';
import {
  DEFAULT_AUTOSAVE,
  DEFAULT_BALLAST_ROUTE,
  DEFAULT_LADEN_ROUTE,
  DEFAULT_TC_COMM,
  DEFAULT_VC_COMM,
  GrainCalculationType,
  SHINC_ID,
  Themes,
  UiSettingsStateModel,
  UserTimeSettings,
} from '@estimator/models';
import { ThemeService } from '@estimator/services';
import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { ChangeTheme } from './ui-settings.actions';

@State<UiSettingsStateModel>({
  name: 'uiSettings',
  defaults: {
    default_port_time_type: UserTimeSettings.PortLocalTime,
    default_time_charter_commission: DEFAULT_TC_COMM,
    default_voyage_commission: DEFAULT_VC_COMM,
    autoSave: DEFAULT_AUTOSAVE,
    default_grain_bale_calculation_type: GrainCalculationType.GrainCalculationType,
    // background_color: DEFAULT_BACKGROUND,
    default_rule_id: SHINC_ID,
    laden_route_color: DEFAULT_LADEN_ROUTE,
    ballast_route_color: DEFAULT_BALLAST_ROUTE,
    color_scheme: Themes.LIGHT,
  },
})
@Injectable()
export class UiSettingsState implements NgxsOnInit {
  @Selector()
  static getSettings(state: UiSettingsStateModel): UiSettingsStateModel {
    return state;
  }

  constructor(private readonly themeSrv: ThemeService) {}

  ngxsOnInit({ dispatch }: StateContext<UiSettingsStateModel>) {
    // dispatch(new RestoreSettings());
  }

  /*   @Action(RestoreSettings)
  restoreSettings({ setState }: StateContext<UiSettingsStateModel>) {
    const settings = localStorage.getItem(SETTINGS_LOCAL_STORAGE_KEY);
    if (settings && settings !== UNDEFINED) {
      const userSettings = JSON.parse(settings);
      setState({ ...userSettings });
    }
  }

  @Action(SetUiSettigns)
  setUiSettings(
    { getState, patchState, dispatch }: StateContext<UiSettingsStateModel>,
    { settings }: SetUiSettigns
  ) {
    const { color_scheme } = getState();
    patchState({ ...settings });
    const settingsState = getState();
    localStorage.setItem(SETTINGS_LOCAL_STORAGE_KEY, JSON.stringify(settingsState));
    if (color_scheme !== settings.color_scheme && settings.color_scheme) {
      dispatch(new ChangeTheme(settings.color_scheme));
    }
  } */

  @Action(ChangeTheme)
  changeTheme(
    { getState, patchState }: StateContext<UiSettingsStateModel>,
    { theme }: ChangeTheme
  ) {
    this.themeSrv.switchTheme(theme);
  }
}
