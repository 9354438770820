import { AfterViewInit, Component, ViewChild, ViewContainerRef } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';

// const KEY_BACKSPACE = 'Backspace';
// const KEY_DELETE = 'Delete';
const KEY_ENTER = 'Enter';
const KEY_TAB = 'Tab';

@Component({
  selector: 'estimator-numeric-editor',
  template: `
    <p-inputNumber
      #input
      type="number"
      class="aggrid-numeric-editor"
      [(ngModel)]="value"
      [format]="false"
      estimatorDecimals
    >
    </p-inputNumber>
  `,
  styles: [
    `
      .aggrid-numeric-editor {
        width: 100%;
        height: 100%;
        border: none;
        background: none;
      }
    `,
  ],
})
export class AgGridNumericCellEditorComponent implements ICellEditorAngularComp, AfterViewInit {
  @ViewChild('input', { read: ViewContainerRef }) input!: ViewContainerRef;
  value!: number;
  private params: any;
  private cancelBeforeStart = false;

  agInit(params: ICellEditorParams): void {
    this.params = params;
    this.value = params.value;
  }

  getValue(): number {
    return this.value ? +this.value : 0;
  }

  isCancelBeforeStart(): boolean {
    return this.cancelBeforeStart;
  }

  ngAfterViewInit() {
    window.setTimeout(() => {
      this.input.element.nativeElement // p-inputnumber
        .firstChild // span
        ?.firstChild // actual input
        ?.focus();
    });
  }

  private finishedEditingPressed(event: any) {
    const key = event.key;
    return key === KEY_ENTER || key === KEY_TAB;
  }
}
