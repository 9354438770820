import { XBPIRequest } from '@estimator/models';

export class GetHistoryData {
  static readonly type = '[XBPI] Get history data';
  constructor(public data: XBPIRequest) {}
}

export class GetHistoryPorts {
  static readonly type = '[XBPI] Get history ports';
}
