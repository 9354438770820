import { Injectable } from '@angular/core';
import { PortDisbursementAccount } from '@estimator/models';
import { PortDisbursementService } from '@estimator/services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { catchError, finalize, tap, throwError } from 'rxjs';
import { GetPortDisbursements } from './port-disbursement.actions';

export interface PortDisbursementStateModel {
  portDisbursements: PortDisbursementAccount[];
  loading: boolean;
}

@State<PortDisbursementStateModel>({
  name: 'portDisbursement',
  defaults: {
    portDisbursements: [],
    loading: false,
  },
})
@Injectable()
export class PortDisbursementState {
  @Selector()
  static getPortDisbursements({
    portDisbursements,
  }: PortDisbursementStateModel): PortDisbursementAccount[] {
    return portDisbursements;
  }
  @Selector()
  static getIsLoading({ loading }: PortDisbursementStateModel): boolean {
    return loading;
  }

  constructor(private readonly portDASrv: PortDisbursementService) {}

  @Action(GetPortDisbursements)
  getPortDisbursements(
    { patchState }: StateContext<PortDisbursementStateModel>,
    { request }: GetPortDisbursements
  ) {
    patchState({ loading: true });
    return this.portDASrv.getPortDisbursements(request).pipe(
      tap((res) => {
        patchState({ portDisbursements: res.port_disbursement_accounts || [] });
      }),
      catchError((err) => {
        patchState({ loading: false });
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }
}
