export class GetAllPorts {
  static readonly type = '[PORT] Get all';
}

export class GetPort {
  static readonly type = '[PORT] Get port';
  constructor(public id: number) {}
}

export class SearchPort {
  static readonly type = '[PORT] Search port';
  constructor(public request: string) {}
}
