import {
  AbstractControl,
  AsyncValidatorFn,
  FormArray,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { CargoCalculator, CargoCalculatorForm } from '@estimator/models';
import { isNil, isString } from 'lodash';
import { Observable, catchError, map, of } from 'rxjs';

export const confirmPasswordValidator = (): ValidatorFn => {
  return function (ctrl: AbstractControl): ValidationErrors | null {
    const formGroup = ctrl as FormGroup;
    const passwordControl = formGroup.controls['password'];
    const confirmPasswordControl = formGroup.controls['passwordConfirm'];
    if (!passwordControl || !confirmPasswordControl) {
      return null;
    }
    if (passwordControl.value !== confirmPasswordControl.value) {
      confirmPasswordControl.setErrors({ passwordMismatch: true });
      return { passwordMismatch: true };
    } else {
      return null;
    }
  };
};

export function ZeroNullValidator(acceptZero = false): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    const value = control.value;

    return of(value).pipe(
      map((val) => {
        const condition = acceptZero ? isNil(val) : val === 0 || isNil(val);
        return condition ? { invalid: true } : null;
      }),
      catchError((err) => of({ invalid: true }))
    );
  };
}

export function deductiblesZeroNullValidator(
  fieldDeductibles: keyof CargoCalculator,
  fieldNames: (keyof CargoCalculator)[] // 'bunker_weight', 'permanent_ballast', 'fresh_water', 'constant_weight', 'other_weight',
): ValidatorFn {
  return (control: AbstractControl<FormGroup<CargoCalculatorForm>>): ValidationErrors | null => {
    const deductiblesValue = control.get(fieldDeductibles)?.value;
    const isValidDeductibles = deductiblesValue !== 0 && !isNil(deductiblesValue);

    const isValidSplitDeductibles = fieldNames.some((fieldName) => {
      const fieldValue = control.get(fieldName)?.value;
      return fieldValue !== 0 && !isNil(fieldValue);
    });

    if (!(isValidSplitDeductibles || isValidDeductibles)) {
      control.get(fieldDeductibles)?.setErrors({ invalid: true });
    }

    return null;
  };
}

export function notStringValidator(): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    const value = control.value;
    return of(value).pipe(
      map((val) => {
        const condition = isString(val);
        return condition ? of({ invalid: true }) : null;
      }),
      catchError((err) => of({ invalid: true }))
    );
  };
}

export function maxAllowedDateValidator(maxDate: Date): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    const controlDate = control.value ? new Date(control.value) : null;

    return of(controlDate).pipe(
      map((val) => {
        const condition = (val && maxDate && val > maxDate)
        return condition ? { maxDateError: { maxDate } } : null;
      }),
      catchError((err) => of({ invalid: true }))
    );
  };
}

export function minAllowedDateValidator(minDate: Date): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    const controlDate = control.value ? new Date(control.value) : null;

    return of(controlDate).pipe(
      map((val) => {
        const condition = (val && minDate && val < minDate)
        return condition ? { maxDateError: { minDate } } : null;
      }),
      catchError((err) => of({ invalid: true }))
    );
  };
}

export function validateSequentialDates(formArray: AbstractControl): ValidationErrors | null {
  const formGroups = (formArray as FormArray).controls;
  for (let i = 0; i < formGroups.length - 1; i++) {
    const currentGroup = formGroups[i];
    const nextGroup = formGroups[i + 1];

    const currentEndDate = currentGroup.get('end')?.value;
    const nextStartDate = nextGroup.get('start')?.value;
    if (currentEndDate && nextStartDate && new Date(nextStartDate) <= new Date(currentEndDate)) {
      return { datesNotSequential: true };
    }
  }
  
  return null;
}