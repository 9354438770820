import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CalendarModule } from 'primeng/calendar';
import { AgGridDateEditorComponent } from './ag-grid-date-editor.component';

@NgModule({
  declarations: [AgGridDateEditorComponent],
  imports: [FormsModule, CalendarModule],
  exports: [AgGridDateEditorComponent],
})
export class DateEditorModule {}
