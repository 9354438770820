import { FormControl } from '@angular/forms';
import { BaseEntity, FuelTypes } from '@estimator/models';

export interface Bunkering extends BaseEntity {
  agreement_id?: number;
  fuel_id?: number;
  fuel_name?: FuelTypes;
  quantity_on_delivery?: number;
  price_on_delivery?: number;
  quantity_on_redelivery?: number;
  price_on_redelivery?: number;
}

export interface HSOABunkering {
  amount?: number;
  fuel_id?: number;
  fuel_name?: FuelTypes;
  price?: number;
  quantity?: number;
}

export interface BunkeringFormGroup {
  agreement_id?: FormControl<number | null>;
  fuel_id?: FormControl<number | null>;
  fuel_name?: FormControl<FuelTypes | null>;
  quantity_on_delivery?: FormControl<number | null>;
  price_on_delivery?: FormControl<number | null>;
  quantity_on_redelivery?: FormControl<number | null>;
  price_on_redelivery?: FormControl<number | null>;
}

export interface HSOABunkeringFormGroup {
  fuel_id?: FormControl<number | null>;
  fuel_name?: FormControl<FuelTypes | null>;
  amount?: FormControl<number | null>;
  quantity?: FormControl<number | null>;
  price?: FormControl<number | null>;
}
