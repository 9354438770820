import { Component } from '@angular/core';
import { ButtonParams } from '@estimator/models';

@Component({
  selector: 'estimator-aggrid-button-group',
  template: `
    <div class="button-group-wrapper">
      <mat-icon
        *ngFor="let button of buttons"
        class="aggrid-action-button"
        [color]="button?.color || color"
        [matTooltip]="button.tooltip"
        (click)="button.click()"
      >
        {{ button.icon }}
      </mat-icon>
    </div>
  `,
  styles: [
    `
      :host {
        display: contents;
        .button-group-wrapper {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          .aggrid-action-button {
            cursor: pointer;
            font-size: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    `,
  ],
})
export class AgGridButtonGroupComponent {
  params: any;
  buttons: ButtonParams[] = [];
  color = 'primary';

  agInit(params: any) {
    this.params = params;
    this.buttons = params.buttons;
  }
}
