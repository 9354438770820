import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnDestroy,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { NotificationType, UserConfirmInvitationDialogConfig } from '@estimator/models';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Subject } from 'rxjs';

@Component({
  selector: 'estimator-user-confirm-invitation',
  templateUrl: './user-confirm-invitation.component.html',
  styleUrls: ['./user-confirm-invitation.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserConfirmInvitationComponent implements OnDestroy {
  // @Input() responseError = '';
  @Output() confirm = new EventEmitter<void>();
  readonly NotificationType = NotificationType;
  private _onDestroy$ = new Subject<void>();

  constructor(
    private readonly dialogConfig: DynamicDialogConfig<UserConfirmInvitationDialogConfig>
  ) {
    if (this.dialogConfig.data) {
      const { confirm } = this.dialogConfig.data;
      if (confirm) {
        this.confirm = confirm;
      }
      /* if (responseError) {
          this.responseError = `${ERROR_BASE_TEMPLATE} ${responseError}`;
        } */
    }
  }
  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  onConfirm(): void {
    this.confirm.emit();
  }
}
