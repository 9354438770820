import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { localeDateFormatPrimeNGInput } from '@estimator/helpers';
import { FormDateSwitcherFormGroup, THOUSAND } from '@estimator/models';
import { isNumber } from 'lodash';
import { InputSwitchChangeEvent } from 'primeng/inputswitch';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'estimator-form-date-switcher',
  templateUrl: './form-date-switcher.component.html',
  styleUrls: ['./form-date-switcher.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormDateSwitcherComponent implements OnInit, OnDestroy {
  @Input() switcher = true;
  @Input() set controlFrom(fc: FormControl<number | Date | null> | undefined) {
    if (fc?.value) {
      const value = this.transformValue(fc?.value);
      this.dateSwitcherForm.controls.dates_from?.patchValue(value, {
        emitEvent: false,
      });
      /* this._controlFrom = fc;
      this._controlFrom.patchValue(fc.value); */
    } else {
      this.dateSwitcherForm.controls.dates_from?.patchValue(new Date(), { emitEvent: false });
    }
  }
  @Input() set controlTo(fc: FormControl<number | Date | null> | undefined) {
    if (fc?.value) {
      const value = this.transformValue(fc?.value);
      this.dateSwitcherForm.controls.dates_to?.patchValue(value, {
        emitEvent: false,
      });
      /* this._controlTo = fc;
      this._controlTo.patchValue(fc.value); */
    } else {
      this.dateSwitcherForm.controls.dates_to?.patchValue(new Date(), { emitEvent: false });
    }
  }
  @Input() set controlDays(fc: FormControl<number | null> | undefined) {
    if (fc?.value) {
      this.dateSwitcherForm.controls.updates_from_days?.patchValue(fc.value, { emitEvent: false });
      this.dateSwitcherForm.controls.switcher?.patchValue(false, { emitEvent: false });
      this.switcher = false;
      /* this._controlDays = fc;
      this._controlDays.patchValue(fc.value); */
    }
  }

  /*   @Input() set shiplistFormGroup(shiplistFormGroup: FormGroup<ShiplistFormGroup>) {
    /* this._seconds = value; /
    this.form.patchValue({
      updates_from: shiplistFormGroup.controls.,
      dates_from: moment.unix(value[1]).toDate(),
      dates_to: moment.unix(value[2]).toDate(),
    });
  } */
  /*   get seconds() {
    return this._seconds;
  } */
  // private _seconds: number[] = [];
  @Output() changeValue: EventEmitter<FormGroup<FormDateSwitcherFormGroup>['value']> =
    new EventEmitter<FormGroup<FormDateSwitcherFormGroup>['value']>();
  dateSwitcherForm = new FormGroup<FormDateSwitcherFormGroup>({
    switcher: new FormControl<boolean>(this.switcher),
    updates_from_days: new FormControl<number>(0, { nonNullable: true }),
    dates_from: new FormControl<Date>(new Date(), { nonNullable: true }),
    dates_to: new FormControl<Date>(new Date(), { nonNullable: true }),
  });
  // controls = this.form.controls;
  dateFormat = localeDateFormatPrimeNGInput();
  /*   private _controlFrom = new FormControl<number | Date | null>(null);
  private _controlTo = new FormControl<number | Date | null>(null);
  private _controlDays = new FormControl<number | Date | null>(0); */
  private _mockFormControl = new FormControl();
  private _onDestroy$ = new Subject<void>();

  /*   get controlFrom(): FormControl<number | Date | null> | undefined {
    return this._controlFrom;
  }

  get controlTo(): FormControl<number | Date | null> | undefined {
    return this._controlTo;
  }

  get controlDays(): FormControl<number | Date | null> | undefined {
    return this._controlDays;
  } */

  get mockFormControl(): FormControl {
    this.mockFormControl.disable();
    return this._mockFormControl;
  }

  ngOnInit(): void {
    this.dateSwitcherForm?.valueChanges?.pipe(takeUntil(this._onDestroy$)).subscribe((fgValue) => {
      this.changeValue.emit(fgValue);
    });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  onChangeSwitcher(event: InputSwitchChangeEvent): void {
    if (event.checked) {
      this.dateSwitcherForm?.controls.updates_from_days?.reset();
    } else {
      this.dateSwitcherForm?.controls.dates_from?.reset();
      this.dateSwitcherForm?.controls.dates_to?.reset();
    }
  }

  getFg(): FormGroup<FormDateSwitcherFormGroup>['value'] {
    return this.dateSwitcherForm.getRawValue();
  }

  transformValue(value: number | Date): Date {
    return isNumber(value) ? new Date(+value * THOUSAND) : value;
  }

  resetFg(): void {
    this.dateSwitcherForm.reset();
    this.dateSwitcherForm.controls.switcher?.patchValue(true, { emitEvent: false });
    this.switcher = true;
  }
}
