<div class="ag-grid-wrapper-border">
  <ag-grid-angular
    class="ag-theme-material break-even-grid"
    [columnDefs]="columnDefs"
    [rowData]="data"
    [animateRows]="true"
    [gridOptions]="gridOptions"
    (gridReady)="onGridReady($event)"
  >
  </ag-grid-angular>
</div>
