import {
  BaseModel,
  Cargo,
  CascadeNavigation,
  ChangeValidation,
  Deal,
  DealEvent,
  DealNavigationLink,
  Folder,
  HireFinance,
  SummLDRateAndETbyCargoID,
  Vessel,
} from '@estimator/models';
import { FeatureCollection } from 'geojson';

export class GetAllDeals {
  static readonly type = '[DEAL] Get all';
  constructor(public limit?: number, public offset?: number) {}
}

export class GetDeal {
  static readonly type = '[DEAL] Get deal';
  constructor(public id: number, public force?: boolean, public copyId?: number) {}
}

export class CreateDeal {
  static readonly type = '[DEAL] Create deal';
  constructor(public deal: Deal) {}
}

export class UpdateFolderAtDeal {
  static readonly type = '[DEAL] Update folder at deal';
  constructor(public deal: Partial<Deal>) {}
}

// the method uses the same as the UpdateFolderAtDeal method, only there is an option to pass separate fields to update
export class UpdateFieldDeal {
  static readonly type = '[DEAL] Update field deal';
  constructor(public deal: Partial<Deal>, public params: Partial<Deal>) {}
}

export class UpdateDeal {
  static readonly type = '[DEAL] Update deal';
  constructor(
    public deal: Deal,
    /*    public newSpeed?: number | null, */
    public revertUpdate?: boolean,
    public updateStartVersion?: boolean,
    public cancelNavigation?: boolean,
    public changePort?: boolean
  ) {}
}

export class UpdateDealCancelUncompletedFalse {
  static readonly type = '[DEAL] Update deal cancel uncompleted false';
  constructor(
    public deal: Deal,
    /* public newSpeed?: number | null, */
    public revertUpdate?: boolean
  ) {}
}

export class DeleteDeal {
  static readonly type = '[DEAL] Delete deal';
  constructor(public id: number, public isDeleteCopy?: boolean) {}
}

export class RestoreDeal {
  static readonly type = '[DEAL] Restore deal';
  constructor(public id: number) {}
}

export class ResetSelectedDeal {
  static readonly type = '[DEAL] Reset selected deal';
}

export class ResetNavigatedDeal {
  static readonly type = '[DEAL] Reset navigated deal';
}

export class ResetCascadeNavigation {
  static readonly type = '[DEAL] Reset сascade navigation';
}

export class RefreshColorsRoute {
  static readonly type = '[DEAL] Refresh colors route';
}

export class CreateCargo {
  static readonly type = '[DEAL] Create cargo';
  constructor(public cargo: Cargo, public actualCargoes: Cargo[]) {}
}

export class UpdateCargo {
  static readonly type = '[DEAL] Update cargo';
  constructor(public cargo: Cargo) {}
}

export class UpdateCargoInSelectedDeal {
  static readonly type = '[DEAL] Update cargo in selected deal';
  constructor(public cargo: Cargo, public actualCargoes: Cargo[]) {}
}

export class GetLastConstantsForCargo {
  static readonly type = '[DEAL] Get last constants for cargo';
  constructor(public cargo: Cargo, public vessel_id: number) {}
}

export class DeleteCargo {
  static readonly type = '[DEAL] Delete cargo';
  constructor(public cargoId: number, public actualCargoes: Cargo[]) {}
}

export class CalculateCargo {
  static readonly type = '[DEAL] Calculate cargo';
  constructor(public cargo: Cargo) {}
}

export class CalculateCargoGroup {
  static readonly type = '[DEAL] Calculate cargo group';
  constructor(public cargoes: Cargo[]) {}
}

export class CreateEvent {
  static readonly type = '[DEAL] Create event';
  constructor(public event: DealEvent) {}
}

export class UpdateEvent {
  static readonly type = '[DEAL] Update event';
  constructor(public event: DealEvent, public updateOnlyCanals: boolean) {}
}

export class DeleteEvent {
  static readonly type = '[DEAL] Delete event';
  constructor(public event: DealEvent) {}
}

export class ReplaceEvent {
  static readonly type = '[DEAL] Replace event';
  constructor(public events: DealEvent[]) {}
}

export class NavigateToDeal {
  static readonly type = '[DEAL] Navigate to deal';
  constructor(public deal: BaseModel, public closeTabName?: string) {}
}

export class ClearCloseTabName {
  static readonly type = '[DEAL] Clear close tabname';
}

export class CopyDeal {
  static readonly type = '[DEAL] Copy deal';
  constructor(
    public id: number,
    public getDeal?: boolean,
    public folder_id?: number,
    public setOrder?: boolean
  ) {}
}

export class UpdateVesselInDeal {
  static readonly type = '[DEAL] Update vessel in deal';
  constructor(public vessel: Vessel) {}
}

export class SetChanges {
  static readonly type = '[DEAL] Set changes in deal';
  constructor(public changes: boolean) {}
}

export class UpdateHireFinanceInDeal {
  static readonly type = '[DEAL] Update hire finance in deal';
  constructor(public hireFinance: HireFinance) {}
}

export class SetCascadeNavigation {
  static readonly type = '[DEAL] Set cascade navigation';
  constructor(public navigation: CascadeNavigation[]) {}
}

export class CreateStartVersionDeal {
  static readonly type = '[DEAL] Create start version deal';
  constructor(public id: number) {}
}

export class RevertDeal {
  static readonly type = '[DEAL] Revert deal';
  constructor(public id: number) {}
}

export class CloseFolderInCurrEstimation {
  static readonly type = '[DEAL] Close folder in curr estimation';
  constructor(
    public linkToNavigate: string,
    public navigate: boolean,
    public revertUpdate: boolean,
    public folderId?: number
  ) {}
}

export class CloseFolderInLastEstimation {
  static readonly type = '[DEAL] Close folder in last estimation';
  constructor(
    public linkToNavigate: string,
    public navigate: boolean,
    public revertUpdate: boolean,
    public dontUpdateDeals?: boolean,
    public folderId?: number
  ) {}
}

export class OpenSaveFolderModal {
  static readonly type = '[DEAL] Open save folder modal';
  constructor(
    public linkToNavigate: string,
    public currDealId: number,
    public navigate: boolean /* public folderId?: number */,
    public copyAlienFolder?: Folder, // иногда служит как isCopyAlienFolder
    public isCopyAlienDeal?: boolean,
    public isCopyAlienFolder?: boolean
  ) {}
}

export class CopyDealToOtherFolder {
  static readonly type = '[DEAL] Copy deal to other folder';
  constructor(
    public linkToNavigate: string,
    public id: number,
    public openFolder?: Folder,
    public notCopyToCurrentFolder?: boolean,
    public copyDealName?: string
  ) {}
}

export class SetEstimationsNavigationLinks {
  static readonly type = '[DEAL] Set estimations navigation links';
  constructor(
    public estimationsNavigationLinks: DealNavigationLink[],
    public onlyCascade: boolean
  ) {}
}

export class SetCurrentEditedCopy {
  static readonly type = '[DEAL] Set current edited copy';
  constructor(public deal: Deal) {}
}

export class SetEventsFormInvalidStatus {
  static readonly type = '[DEAL] Set events form invalid status';
  constructor(public eventsFormInvalid: boolean) {}
}

export class FilterStartCopiedDeals {
  static readonly type = '[DEAL] Filter start copied deals';
  constructor(public folderId: number) {}
}

export class EstimateDealFinances {
  static readonly type = '[DEAL] Estimate deal finances';
  constructor(public deal: Partial<Deal>) {}
}

/* export class OpenDialogOpenCloseFolder {
  static readonly type = '[DEAL] Open dialog open close folder';
  constructor(
    public linkToNavigate: string,
    public navigate: boolean,
    public revertUpdate: boolean,
    public folderId?: number
  ) {}
} */

export class ChangeValidationErrors {
  static readonly type = '[DEAL] Change validation errors';
  constructor(public change: ChangeValidation) {}
}

export class ResetValidationErrors {
  static readonly type = '[DEAL] Reset validation errors';
}

export class OpenFixFolderModal {
  static readonly type = '[DEAL] Open fix folder modal';
  constructor(
    /* public linkToNavigate: string,
    public currDealId: number,
    public navigate: boolean,
    public copyAlienFolder?: Folder */
    public linkToNavigate: string,
    public estimationsNavigationLinks: DealNavigationLink[],
    public folderName: string
  ) {}
}

export class SetSummLDRateAndETbyCargoID {
  static readonly type = '[DEAL] Set summ ldrate and et by cargo id';
  constructor(public summLDRateAndETbyCargoID: SummLDRateAndETbyCargoID[]) {}
}

export class SetSummETwithoutCargoID {
  static readonly type = '[DEAL] Set summ et without cargo id';
  constructor(public summETwithoutCargoID: number) {}
}

export class GetBreakEvenData {
  static readonly type = '[DEAL] Get break even data';
  constructor(public dealId: number, public rise?: number) {}
}

export class GetAllAlternativeRoutes {
  static readonly type = '[DEAL] Get all alternative routes';
  constructor(public events: DealEvent[], public routes: FeatureCollection[]) {}
}
