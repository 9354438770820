<form class="confirm-code-form w-100" [formGroup]="confrimCodeFormGroup">
  <div class="flex-container-column gap-2 mb-5">
    <div class="p-inputgroup">
      <span
        class="p-inputgroup-addon"
        [ngStyle]="{
          'background-color': isConfirmCodeControlInvalid
            ? PrimeNgColors.Error
            : PrimeNgColors.Primary,
          'border-color': isConfirmCodeControlInvalid ? PrimeNgColors.Error : PrimeNgColors.Primary
        }"
      >
        <i [class]="PrimeNgIcons.HASHTAG" [style]="{ color: PrimeNgColors.White }"></i>
      </span>
      <input
        pInputText
        id="confirmCode"
        type="confirmCode"
        name="confirmCode"
        placeholder="Enter #code from email"
        formControlName="confirmCode"
        [minLength]="6"
        [maxLength]="6"
        required
      />
    </div>
    <small id="confirm-code-error" [style]="{ color: PrimeNgColors.Error }"
      >&nbsp;<span *ngIf="isConfirmCodeControlInvalid"
        >Error: Confirm code is required and should contain 6 digit</span
      ></small
    >
  </div>
  <div class="flex-container-row p-fluid gap-2">
    <p-button
      class="w-100"
      size="small"
      label="Activate account"
      type="button"
      [disabled]="confrimCodeFormGroup.invalid || isLoading"
      (click)="onSendConfirmCode()"
      [loading]="isLoading"
    ></p-button>
    <p-button
      class="w-100"
      size="small"
      type="button"
      [disabled]="isTimerRunning"
      (click)="onRetryEmail()"
      [loading]="isLoading"
    >
      <div class="w-100 flex-container-row content-center p-button-label">
        Send new email &nbsp;<span *ngIf="isTimerRunning">{{ timer }}</span>
      </div>
    </p-button>
  </div>
</form>
