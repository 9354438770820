import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'estimator-checkbox-renderer',
  template: `
    <input type="checkbox" (click)="checkedHandler($event)" [checked]="params?.value" />
  `,
})
export class AgGridCheckboxCellComponent implements ICellRendererAngularComp {
  params: any;

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  checkedHandler(event: any) {
    const checked = event.target.checked;
    const colId = this.params.column.colId;
    this.params.node.setDataValue(colId, checked);
  }

  refresh(/* params: ICellRendererParams */): boolean {
    return true;
  }
}
