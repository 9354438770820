<div
  class="w-full flex flex-column justify-content-center align-items-center gap-2"
  id="bunker_select_dialog"
>
  <p-dropdown
    [formControl]="selectedEventControl"
    styleClass="w-100"
    appendTo="body"
    [options]="bunkerEvents"
    id="bunker_select_dropdown"
  >
    <ng-template let-item pTemplate="item">
      <div
        class="flex justify-content-between align-items-center"
        [id]="'bunker_select_item_' + item?.port?.name"
      >
        <div>{{ item?.port?.name }}</div>
        &nbsp;
        <div>{{ localDateTimeFormat(item?.start_time, false) }}</div>
      </div>
    </ng-template>
    <ng-template let-item pTemplate="selectedItem">
      <div class="flex justify-content-between align-items-center">
        <div>{{ item?.port?.name }}</div>
        &nbsp;
        <div>{{ localDateTimeFormat(item?.start_time, false) }}</div>
      </div>
    </ng-template>
  </p-dropdown>
  <div class="flex justify-content-end gap-2">
    <p-button
      label="Select"
      (click)="onSelect()"
      id="bunker_select_dialog_select_button"
    ></p-button>
    <p-button
      label="Cancel"
      (click)="onCancel()"
      id="bunker_select_dialog_cancel_button"
    ></p-button>
  </div>
</div>
