import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { BaseModel, Cargo, CargoRules, DealEvent, Fuel } from '@estimator/models';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ColumnState } from 'ag-grid-community';

@Component({
  selector: 'estimator-ag-grid-cargo-details',
  template: `
    <estimator-event-cargo-list
      class="wh-100 details-ports-events"
      [cargoes]="cargoes"
      [cargoRules]="cargoRules"
      [events]="events"
      [baseEvent]="baseEvent"
      [fuels]="fuels"
      [tableState]="tableState"
      (addCargoEvent)="onAddCargoEvent($event)"
      (deleteEvent)="onDeleteEvent($event)"
      (updateEvent)="onUpdateEvent($event)"
      (addCargo)="onAddCargo($event)"
      (saveTableState)="onSaveTableState($event)"
      #eventCargoList
    ></estimator-event-cargo-list>
  `,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
        height: 100%;
      }
      .details-ports-events {
        .ag-theme-material .ag-header {
          background-color: rgba(240, 242, 255, 1) !important;
        }
      }
    `,
  ],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgGridCargoDetailsComponent implements ICellRendererAngularComp {
  events: DealEvent[] = [];
  cargoes: Cargo[] = [];
  cargoRules: CargoRules[] = [];
  terms: BaseModel[] = [];
  fuels: Fuel[] = [];
  baseEvent?: DealEvent;
  tableState: ColumnState[] = [];
  onAddCargo = (params: any) => {
    return;
  };
  onAddCargoEvent = (params: any) => {
    return;
  };
  onDeleteEvent = (params: any) => {
    return;
  };
  onUpdateEvent = (params: any) => {
    return;
  };
  onSaveTableState = (params: any) => {
    return;
  };
  agInit(params: any): void {
    if (params.cargoes) {
      this.cargoes = params.cargoes;
    }
    if (params.cargoEvents) {
      this.events = params.cargoEvents;
    }
    if (params.cargoRules) {
      this.cargoRules = params.cargoRules;
    }
    if (params.terms) {
      this.terms = params.terms;
    }
    if (params.fuels) {
      this.fuels = params.fuels;
    }
    if (params.onAddCargo) {
      this.onAddCargo = params.onAddCargo;
    }
    if (params.onAddCargoEvent) {
      this.onAddCargoEvent = params.onAddCargoEvent;
    }
    if (params.onDeleteEvent) {
      this.onDeleteEvent = params.onDeleteEvent;
    }
    if (params.onUpdateEvent) {
      this.onUpdateEvent = params.onUpdateEvent;
    }
    if (params.onSaveTableState) {
      this.onSaveTableState = params.onSaveTableState;
    }
    if (params.tableState) {
      this.tableState = params.tableState;
    }
    if (params.data) {
      this.baseEvent = params.data;
    }
  }
  refresh(/* params: ICellRendererParams<any, any> */): boolean {
    return false;
  }
}
