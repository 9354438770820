import { BaseEntity } from './base-entity';
import { User } from './user';

export enum CompanyAddressType {
  Legal = 'legal',
  Actual = 'actual', // payment
}

export enum CompanyType {
  Owner = 'Owner',
}

export interface CompanyIntAccount extends BaseEntity {
  company_id?: number;
  swift?: string;
  account?: string;
  primary?: boolean;
  correspondent_swift?: string;
  correspondent_bik?: string;
  broker_account?: string;
  currency?: string;
  beneficiary_id?: number;
  beneficiary_details?: string;
  currency_id: number;
  // TODO: add Currency interface
  currency_entity?: any;
  bank_id: number;
  bank_entity?: Company;
  correspondent_bank_id: number;
  correspondent_bank_entity: Company;
}

export interface CompanyAccount extends BaseEntity {
  company_id?: number;
  primary?: boolean;
  bik?: string;
  correspondent_account?: string;
  account?: string;
  currency?: string;
  beneficiary_id?: number;
  beneficiary_details?: string;
  currency_id?: number;
  // TODO: add Currency interface
  currency_entity?: any;
  bank_id?: number;
  bank_entity?: Company;
}

export interface CompanyAddress extends BaseEntity {
  company_id?: number;
  type?: CompanyAddressType;
  country?: string;
  postal_code?: string;
  area?: string;
  city?: string;
  district?: string;
  street?: string;
  building?: string;
  room?: string;
}

export interface CompanyContact extends BaseEntity {
  company_id?: number;
  send_email?: boolean;
  fio?: string;
  phone?: string;
  email?: string;
  description?: string;
}

export interface Company extends BaseEntity {
  a2?: string;
  tax_id?: string;
  inn?: string;
  kpp?: string;
  name?: string;
  short_name?: string;
  type?: string;
  group?: string;
  reg_num?: string;
  international_accounts?: CompanyIntAccount[];
  accounts?: CompanyAccount[];
  addresses?: CompanyAddress[];
  contacts?: CompanyContact[];
  company_types?: CompanyType[];
}

export interface CompanySearchRequest {
  value: string;
  limit?: number;
  offset?: number;
}

export interface CompanySearchResponse {
  companies: Partial<Company>[];
  total_amount_of_companies: number;
}

export interface AllUsersByCompanyIDResponse {
  users: User[];
}
