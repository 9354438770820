import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AgGridModule } from 'ag-grid-angular';
import { ButtonModule } from 'primeng/button';
import { AgGridSharedModule } from '../ag-grid-shared/ag-grid-shared.module';
import { ShipListTableComponent } from './ship-list-table.component';

export const SHIPLIST_ZONES_SHIP_LIST_TABLE_IMPORTS = [
  CommonModule,
  /*   ReactiveFormsModule,
  NgOptimizedImage, */
  ButtonModule,
  /*  TableModule,
  SkeletonModule,
  TooltipModule, */

  AgGridModule,
  AgGridSharedModule,
];

@NgModule({
  declarations: [ShipListTableComponent],
  imports: SHIPLIST_ZONES_SHIP_LIST_TABLE_IMPORTS,
  exports: [ShipListTableComponent],
})
export class ShipListTableModule {}
