import { TreeNode } from "primeng/api";
import { FileTreeModel } from "./agreement";

export enum FileUploadType {
  EXCEL = '.xlsx',
  PDF = '.pdf',
  WORD = '.docx',
}

export enum InvoiceType {
  FREIGHT = 'freight',
  VSOA = 'vsoa',
  LAYTIME = 'laytime',
}

export interface FileResponse {
  agreement_id?: number;
  transaction_id?: number;
  created_at?: string | Date | null;
  created_by?: number;
  id?: number;
  mime_type?: string;
  original_name?: string;
  name?: string;
  path?: string;
  size?: number;
  uuid?: string;
}

export interface FileInfo {
  name: string,
  id: number;
}

export interface FileSaveRequest {
  tree: FileTreeModel | null;
  subTree: FileTreeModel | null;
}

export interface FileUploadHandler {
  node: TreeNode;
  isSub: boolean;
}
