import { EventEmitter } from '@angular/core';
import { ISimpleFilterModelType } from 'ag-grid-community/dist/lib/filter/provided/simpleFilter';
import { Subject } from 'rxjs';
import { BaseModel } from './base-entity';
import { Company } from './company';
import { Deal, DealNavigationLink, VoyageType } from './deal';

export interface Folder extends BaseModel {
  company?: Company;
  company_id?: number;
  total_deals_amount?: number;
  voyage_type?: VoyageType;
  found_by?: FolderFoundByField[];
  is_temporary?: boolean;
  is_fixed?: boolean;
  remarks?: string;
  /**
   * имя создателя папки
   */
  created_by_first_name?: string;
  /**
   * фамилия создателя папки
   */
  created_by_last_name?: string;
  /**
   * время пина
   */
  pinned_at?: Date;
  last_deal_updated_at?: string
}

export interface GetAllFolders {
  folders: Folder[];
}

export interface GetAllFoldersWithParams extends GetAllFolders {
  total_amount_of_folders: number;
}

export interface RequestDataSourceDeals {
  requestFolderId: number;
  rowId: string;
}

export interface RequestChangeFolder {
  deal: Deal;
  previousFolderId: number;
  previousRowId: string; // detail_id
  requestFolderId: number;
  requestRowId: string; // detail_id
  requestFolderExpanded: boolean;
}

/* export interface RequestCompanyGrid {
  value: string;
  rowId: string;
  parentGrid?: boolean;
} */

export interface FolderFilterModel {
  field: string;
  value: string;
  filter_type: string;
}

export interface RequestDeleteDeal {
  deleteId: number;
  requestFolderId: number;
  rowId: string;
  isArchived: boolean;
}

export interface RequestUpdateFolder {
  folder: Folder;
  oldValue: string;
}

export enum FolderFilterType {
  EMPTY = 'EMPTY',
  EQUALS = 'EQUALS',
  NOT_EQUAL = 'NOT_EQUAL',
  LESS_THAN = 'LESS_THAN',
  LESS_THAN_OR_EQUAL = 'LESS_THAN_OR_EQUAL',
  GREATER_THAN = 'GREATER_THAN',
  GREATER_THAN_OR_EQUAL = 'GREATER_THAN_OR_EQUAL',
  IN_RANGE = 'IN_RANGE',
  CONTAINS = 'CONTAINS',
  NOT_CONTAINS = 'NOT_CONTAINS',
  STARTS_WITH = 'STARTS_WITH',
  ENDS_WITH = 'ENDS_WITH',
  BLANK = 'BLANK',
  NOT_BLANK = 'NOT_BLANK',
}

export enum FilterType {
  TEXT = 'text',
  SET = 'set',
}

export enum FolderFoundByField {
  FolderName = 'folder_name',
  DealName = 'deal_name',
  VesselName = 'vessel_name',
  CompanyName = 'company_name',
  Port = 'port',
  CargoCompany = 'cargo_company',
}

export const objectFolderFilterType: { [key in ISimpleFilterModelType]: FolderFilterType } = {
  empty: FolderFilterType.EMPTY,
  equals: FolderFilterType.EQUALS,
  notEqual: FolderFilterType.NOT_EQUAL,
  lessThan: FolderFilterType.LESS_THAN,
  lessThanOrEqual: FolderFilterType.LESS_THAN_OR_EQUAL,
  greaterThan: FolderFilterType.GREATER_THAN,
  greaterThanOrEqual: FolderFilterType.GREATER_THAN_OR_EQUAL,
  inRange: FolderFilterType.IN_RANGE,
  contains: FolderFilterType.CONTAINS,
  notContains: FolderFilterType.NOT_CONTAINS,
  startsWith: FolderFilterType.STARTS_WITH,
  endsWith: FolderFilterType.ENDS_WITH,
  blank: FolderFilterType.BLANK,
  notBlank: FolderFilterType.NOT_BLANK,
};

export interface CloseFolderDialogConfig {
  saveCurrentEstimations: EventEmitter<void>;
  revertToLastEstimations: EventEmitter<void>;
}

export interface SaveFolderDialogConfig {
  saveFolder: EventEmitter<SaveFolderEmitter>;
  searchFolder: EventEmitter<string>;
  foundFolders: Subject<Folder[]>;
  // folders: Folder[];
  label: string;
  // notCopyToCurrentFolder?: boolean;
  openFolder?: Folder | null;
}

export interface SaveFixDialogConfig {
  saveFolder: EventEmitter<FixFolderEmitter>;
  searchFolder: EventEmitter<string>;
  foundFolders: Subject<Folder[]>;
  estimationsNavigationLinks: DealNavigationLink[];
  folderName: string;
}

export interface CopyFolderDialogConfig {
  oldFolderName: string;
  copyFolder: EventEmitter<string>;
  searchFolder: EventEmitter<string>;
  foundFolders: Subject<Folder[]>;
}

export interface SaveFolderEmitter {
  folder: Folder & string;
  newFolder: boolean;
}

export interface FixFolderEmitter {
  folderId: number;
  folderName: string;
  ids: number[];
}

export interface OpenAllDeals {
  rowId: number;
  totalDealsAmount: number;
  folder?: Folder;
}

export interface DataFolderOrDeal {
  dealId?: number;
  dealName?: string;
  folder?: boolean;
  deal?: boolean;
  folderId?: number;
  folderName?: string;
  userId?: number;
  is_folder_fixed?: boolean;
  pinned_at?: Date;
}

export enum FolderDealOptions {
  OPEN_FOLDER = 0,
  COPY_FOLDER,
  DELETE_FOLDER,
  UNFIX_FOLDER,
  COPY_DEAL,
  DELETE_DEAL,
  PIN_FOLDER,
  UNPIN_FOLDER,
  LINK_FOLDER,
  LINK_DEAL,
  FAST_COPY_FOLDER,
  COPY_DEAL_TO_NEW_FOLDER,
  FOLDER_NEW_TAB,
}

export enum FolderDealOptionLabels {
  OPEN = 'Open',
  COPY = 'Copy',
  DELETE = 'Delete',
  UNFIX = 'Unfix',
  PIN = 'Pin',
  UNPIN = 'Unpin',
  LINK = 'Link',
  NEW_TAB = 'Open new tab',
}

export interface OpenCloseFolderDialogConfig {
  saveExist: EventEmitter<void>;
  dontSaveExist: EventEmitter<void>;
  oldFolder: string;
  newFolder: string;
  saveFolderOrDeleteDeal?: boolean;
  notShowNewFolder?: boolean;
  copyAlienFolder?: boolean;
  skipDialog?: boolean;
}

export interface OpenEstimationsDirectoryDialogConfig {
  showList: boolean;
  closeDialog: EventEmitter<void>;
}

export interface FolderDealActionOptions {
  action: FolderDealOptions;
  options?: DataFolderOrDeal | null;
}

export enum SortingType {
  ASC = 'asc',
  DESC = 'desc',
}
