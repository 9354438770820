import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'mar-ghost-button',
  template: ` <ng-container *ngIf="button; then Button; else Anchor"> </ng-container>
    <ng-template #Button>
      <button class="{{ iconPosition }} {{ icon }}" [ngClass]="{ disabled: disabled }">
        <ng-container [ngTemplateOutlet]="content"></ng-container>
      </button>
    </ng-template>
    <ng-template #Anchor>
      <a
        class="{{ iconPosition }} {{ icon }}"
        [ngClass]="{ disabled: disabled }"
        [routerLink]="routerLink"
      >
        <ng-container [ngTemplateOutlet]="content"></ng-container>
      </a>
    </ng-template>
    <ng-template #content><ng-content></ng-content></ng-template>`,
  styles: [
    `
      @import '_variables';
      :host {
        a,
        button {
          background: none;
          border: none;
          display: inline-flex;
          font-family: 'Clash Grotesk';
          font-weight: 500;
          color: $primary;
          line-height: 3.2rem;
          font-size: 1.6rem;
          text-transform: uppercase;
          cursor: pointer;
          letter-spacing: 0.02em;
          white-space: nowrap;
          transition: 0.2s;
          &.disabled {
            pointer-events: none;
            opacity: 0.4;
            filter: grayscale(1);
          }
          &.left {
            &:before {
              margin-right: $padding-m;
              content: ' ';
              width: 3.2rem;
              height: 3.2rem;
              background-color: $blue;
              border-radius: 50%;
              background-repeat: no-repeat;
              background-position: center;
              transition: 0.2s;
            }
          }
          &.right {
            &:after {
              margin-left: $padding-m;
              content: ' ';
              width: 3.2rem;
              height: 3.2rem;
              background-color: $blue;
              border-radius: 50%;
              background-repeat: no-repeat;
              background-position: center;
              transition: 0.2s;
            }
          }
          &:hover {
            &:before,
            &:after {
              background-color: #658df5;
            }
          }
          &.arrow {
            &:before,
            &:after {
              background-image: url(/assets/icon-arrow-right.svg);
            }
          }
          &.close {
            &:before,
            &:after {
              background-image: url(/assets/icon-close.svg);
            }
          }
          &.flash {
            &:before,
            &:after {
              background-image: url(/assets/icon-flash.svg);
            }
          }
        }
      }
    `,
  ],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GhostButtonComponent {
  @Input() button = true;
  @Input() icon: 'arrow' | 'flash' | 'close' = 'arrow';
  @Input() iconPosition: 'left' | 'right' = 'left';
  @Input() disabled = false;
  @Input() routerLink: string[] = [];

  preventInnerNavigation(event: MouseEvent): void {
    event.stopImmediatePropagation();
  }
}
