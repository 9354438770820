import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { MessageModule } from 'primeng/message';
import { UserConfirmInvitationComponent } from './user-confirm-invitation.component';

@NgModule({
  declarations: [UserConfirmInvitationComponent],
  imports: [CommonModule, ButtonModule, MessageModule],
  exports: [UserConfirmInvitationComponent],
})
export class UserConfirmInvitationModule {}
