import { MapFeatureProperties, MapTypes } from '@estimator/models';
import { FeatureCollection, GeoJsonProperties, Geometry, Polygon } from 'geojson';

export const emptyPolygon: Polygon = {
  type: MapTypes.Polygon,
  coordinates: [],
};

export const emptyFeatureCollection = (): FeatureCollection<Polygon> => {
  return { type: MapTypes.FeatureCollection, features: [] };
};

/* export const emptyFeature: Feature<Polygon> = {
    type: MapTypes.Feature,
    geometry: emptyPolygon,
    properties : {
      name: 'ECA Zones'
    }
} */

export const setImageFieldToFeatures = (
  portsPoints?: FeatureCollection<Geometry, GeoJsonProperties>
) => {
  portsPoints?.features.forEach((feature, index) => {
    if (feature.properties) {
      const indexCount = index + 1;
      feature.properties[MapFeatureProperties.IconImage] = indexCount;
    }
  });
};
