import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContactForm } from '@estimator/landing-models';
import {
  ASANA,
  ASANA_CONTACTS_PROJECT_ID,
  ASANA_PROJECT_ACCESS_TOKEN,
  ASANA_PROJECT_API_URL,
  FeedbackMessageRequest,
} from '@estimator/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AsanaService {
  private readonly apiUrl = ASANA_PROJECT_API_URL;
  private readonly accessToken = ASANA_PROJECT_ACCESS_TOKEN;

  constructor(protected readonly http: HttpClient) {}

  createTaskFromContactForm(props: ContactForm): Observable<ContactForm> {
    let headers = new HttpHeaders();
    let body = JSON.stringify(props) || ' ';
    body = body.slice(1, body.length - 1);
    body = body.replace(/['"]+/g, '');
    body = body.replaceAll(',', '\n');
    const request: FeedbackMessageRequest = {
      data: {
        projects: [ASANA_CONTACTS_PROJECT_ID],
        name: props.email,
        notes: body,
      },
    };
    headers = headers.set('authorization', `Bearer ${this.accessToken}`);
    return this.http.post<ContactForm>(`${this.apiUrl}/${ASANA.TASKS}/`, request, {
      headers: headers,
    });
  }

  createTaskFromFeedbackForm(props: FeedbackMessageRequest): Observable<FeedbackMessageRequest> {
    return this.http.post<FeedbackMessageRequest>(`${this.apiUrl}/${ASANA.TASKS}/`, props);
  }
}
