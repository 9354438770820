import { Component } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';

@Component({
  template: ` <p-colorPicker [(ngModel)]="value" (onChange)="onChange()"></p-colorPicker> `,
  styles: [
    `
      ::ng-deep .p-colorpicker-preview {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem #eeeeee;
        border-color: #eeeeee;
      }
    `,
  ],
})
export class AgGridPickerEditorEditorComponent implements ICellEditorAngularComp {
  value?: string;
  private params: any;

  agInit(params: any): void {
    this.params = params;
    this.value = params.value;

    if (params.closeEditor) {
      this.closeEditor = params.closeEditor;
    }
  }

  getValue(): string {
    return this.value || '';
  }

  isPopup(): boolean {
    return true;
  }

  onChange() {
    this.closeEditor();
  }

  closeEditor(): void {
    return;
  }
}
