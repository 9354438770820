<p-table
  [value]="cargoFormArray.controls"
  [columns]="columns"
  [resizableColumns]="true"
  [reorderableColumns]="true"
  class="primeng-form border-round-md"
  styleClass="p-datatable-sm font-size-12"
  stateStorage="local"
  [stateKey]="stateKey"
  id="cargo_form"
>
  <!--   <ng-template pTemplate="header">
    <tr>
      <th pResizableColumn class="text-center">
        <p-button
          [disabled]="loading || isFixed"
          [ngClass]="{ 'pointer-events-none': loading }"
          [icon]="PrimeNgIcons.PLUS"
          id="cargo_form_group_add"
          (onClick)="onAddCargo()"
          [rounded]="true"
          [text]="true"
          size="small"
          pTooltip="Add cargo"
          tooltipPosition="top"
          #cargoesAction
        ></p-button>
      </th>
      <th
        pResizableColumn
        pReorderableColumn
        *ngFor="let col of columns"
        [ngStyle]="{ width: col?.width }"
        class="text-center"
        [ngClass]="{
          'display-none':
            (col.field === CargoTableColumnType.AmountType && !isTanker) ||
            (col.field === CargoTableColumnType.WsPercent && !isTanker)
        }"
        [pTooltip]="col.tooltip"
        tooltipPosition="top"
      >
        {{ col.header }}
      </th>
    </tr>
  </ng-template> -->
  <ng-template pTemplate="body" let-cargoForm let-i="rowIndex">
    <div
      id="cargo_form_wrapper_{{ i }}"
      class="cargo-row-wrapper primeng-form border-noround relative mt-2 mx-1 mb-2 pt-2 px-1 pb-1"
      #cargoesAction
    >
      <div class="cargo-buttons absolute px-1">
        <p-button
          *ngIf="showAddCargoIcon(i)"
          id="cargo_form_group_add"
          [ngClass]="{ 'pointer-events-none': loading }"
          size="small"
          [icon]="PrimeNgIcons.PLUS"
          [disabled]="isFixed"
          styleClass="p-button-small primary-color border-round-sm"
          (onClick)="onAddCargo()"
          pTooltip="Add cargo"
          tooltipPosition="top"
        ></p-button>
        <p-button
          *ngIf="!showAddCargoIcon(i)"
          [id]="'cargo_form_group_delete_' + i"
          [ngClass]="{ 'pointer-events-none': loading }"
          size="small"
          [icon]="PrimeNgIcons.TRASH"
          severity="danger"
          [disabled]="isFixed"
          styleClass="p-button-small primary-color border-round-sm"
          (onClick)="onDeleteCargo(i, cargoForm)"
          pTooltip="Delete cargo"
          tooltipPosition="top"
        ></p-button>
      </div>
      <!--  <tr class="primeng-form"> -->
      <!-- <td>
        <p-button
          [disabled]="loading || isFixed"
          [ngClass]="{ 'pointer-events-none': loading }"
          [icon]="PrimeNgIcons.MINUS"
          [id]="'cargo_form_group_delete_' + i"
          (onClick)="onDeleteCargo(i, cargoForm)"
          [rounded]="true"
          [text]="true"
          size="small"
          pTooltip="Delete event"
          tooltipPosition="top"
        ></p-button>
      </td> -->
      <div>
        <td
          *ngFor="let col of columns"
          [ngClass]="{
            'display-none':
              (col.field === CargoTableColumnType.AmountType && !isTanker) ||
              (col.field === CargoTableColumnType.WsPercent && !isTanker) ||
              (col.field === CargoTableColumnType.Lumpsum &&
                !showLumpsum(cargoForm?.controls?.amount_type?.value))
          }"
          [ngStyle]="{ width: col?.width }"
        >
          <!--  <ng-container *ngIf="col.field === CargoTableColumnType.Order">
          <td class="p-tooltip-text" [id]="'cargo_form_group-internal_order_' + i" field>
            #{{ cargoForm?.controls?.internal_order.value }}
          </td>
        </ng-container> -->
          <ng-container *ngIf="col.field === CargoTableColumnType.FreightType">
            <div class="flex items-center gap-1">
              <label class="small-label"
                >{{ col.header }} {{ cargoForm?.controls?.internal_order.value }}
              </label>
              <div class="description-button-wrapper">
                <p-button
                  [icon]="getDescriptionIcon(cargoForm)"
                  [ngClass]="{ 'description-filled': getDescriptionColor(cargoForm) }"
                  [disabled]="isFixed"
                  styleClass="p-button-small"
                  class="border-round-sm description-button"
                  [id]="'cargo_form_group_description_' + i"
                  size="small"
                  [text]="true"
                  (click)="openDescriptionModal($event, cargoForm)"
                >
                </p-button>
              </div>
            </div>

            <p-overlayPanel #overlay styleClass="primeng-form" [style]="{ width: '300px' }">
              <div class="flex flex-column justify-content-between gap-3">
                <span class="flex flex-column">
                  <label>Cargo description</label>
                  <textarea
                    pInputTextarea
                    class="w-full mt-2 description-area"
                    placeholder="Add cargo description (64max)"
                    [maxlength]="64"
                    [formControl]="descriptionControl"
                  ></textarea>
                </span>
              </div>
            </p-overlayPanel>

            <p-autoComplete
              [formControl]="cargoForm.controls.freight_type"
              [suggestions]="filteredFreightTypes"
              [showEmptyMessage]="true"
              [forceSelection]="true"
              (completeMethod)="onSearchFreightType($event)"
              emptyMessage="No cargo found"
              appendTo="body"
              field="name"
              dataKey="id"
              class="p-inputtext-sm w-full"
              styleClass="w-full"
              inputStyleClass="w-full"
              [inputId]="'cargo_form_group_freight_type_' + i"
              [tabindex]="tabIndex"
            >
            </p-autoComplete>
          </ng-container>
          <ng-container *ngIf="col.field === CargoTableColumnType.Quantity">
            <label class="small-label">{{ col.header }} </label>
            <span [ngClass]="{ 'show-invalid': true }">
              <p-inputNumber
                [max]="MAX_FINANCE_VALUE"
                [useGrouping]="false"
                [minFractionDigits]="0"
                [maxFractionDigits]="2"
                [min]="0"
                [step]="0"
                [formControl]="cargoForm.controls.quantity"
                class="p-inputtext-sm w-full"
                styleClass="w-full"
                inputStyleClass="w-full"
                [inputId]="'cargo_form_group_quantity_' + i"
                estimatorDecimals
                [tabindex]="tabIndex"
                (onBlur)="onChangeQuantity($event, cargoForm)"
              ></p-inputNumber>
            </span>
          </ng-container>
          <ng-container *ngIf="col.field === CargoTableColumnType.AmountType">
            <!-- тултип есть в CargoTableColumnType.AmountType -->
            <label class="small-label" [pTooltip]="col.tooltip" tooltipPosition="top"
              >{{ col.header }}
            </label>
            <!--  <p-dropdown
              [options]="cargoesFormTypesMock"
              [formControl]="cargoForm.controls.amount_type"
              appendTo="body"
              placeholder="Type"
              class="p-inputtext-sm hide-dropdown-button w-full"
              styleClass="w-full"
              inputStyleClass="w-full"
              optionLabel="name"
              optionValue="value"
              [inputId]="'cargo_form_group_amount_type_' + i"
              [tabindex]="tabIndex"
              (onChange)="onTypeChange($event, cargoForm)"
            >
            </p-dropdown> -->
            <estimator-button-switch
              [disabled]="isFixed"
              [options]="cargoesFormTypesMock"
              [tabindex]="tabIndex"
              [value]="cargoForm.controls.amount_type?.value"
              [id]="'cargo_form_group_amount_type_' + i"
              (onChange)="onTypeChange($event, cargoForm)"
              optionLabel="short_name"
              optionValue="value"
            >
            </estimator-button-switch>
          </ng-container>
          <ng-container *ngIf="col.field === CargoTableColumnType.Price">
            <label class="small-label">{{ col.header }} </label>
            <span class="p-inputgroup">
              <estimator-price-and-currency-input
                [max]="MAX_FINANCE_VALUE"
                [disabled]="isFixed"
                [id]="'cargo_form_group_price_' + i"
                [currencies]="currencies"
                [price]="cargoForm.controls.price.value"
                [currency]="cargoForm.controls.currency.value"
                [priceFormControl]="cargoForm.controls.price"
                [currencyFormControl]="cargoForm.controls.currency"
                [customTabIndex]="tabIndex"
                [showCurrency]="false"
                [placeholder]="
                  cargoForm?.controls?.amount_type?.value === CargoTableFreightType.AmountTypeWS
                    ? 'WS rate'
                    : ''
                "
                (priceHasChangeAndNotZero)="onChangePrice(cargoForm, 'lumpsum')"
              ></estimator-price-and-currency-input>
              <!-- <p-inputNumber
              [useGrouping]="false"
              [minFractionDigits]="0"
              [maxFractionDigits]="2"
              [min]="0"
              [formControl]="cargoForm.controls.price"
              class="p-inputtext-sm w-70"
              estimatorDecimals
              [tabindex]="tabIndex"
            ></p-inputNumber>
            <p-dropdown
              [options]="currencies"
              [formControl]="cargoForm.controls.currency"
              optionLabel="currency_code"
              styleClass="w-full none-border-radius-left flex-container-row border-left-0"
              class="p-inputtext-sm hide-dropdown-button w-30"
              appendTo="body"
              [inputId]="'cargo_form_group-currency' + i"
            >
            </p-dropdown> -->
            </span>
          </ng-container>
          <ng-container
            *ngIf="
              col.field === CargoTableColumnType.WsPercent &&
              (cargoForm?.controls?.amount_type?.value === CargoTableFreightType.AmountTypeWS ||
                cargoForm?.controls?.amount_type?.value === CargoTableFreightType.AmountTypeLumpSum)
            "
          >
            <label class="small-label">{{ col.header }} </label>
            <p-inputNumber
              [useGrouping]="false"
              [minFractionDigits]="0"
              [maxFractionDigits]="2"
              [min]="0"
              [step]="0"
              [formControl]="cargoForm.controls.ws_percent"
              class="p-inputtext-sm w-full"
              styleClass="w-full"
              inputStyleClass="w-full"
              [inputId]="'cargo_form_group_ws_percent_' + i"
              [tabindex]="tabIndex"
              estimatorDecimals
              (onBlur)="onChangeWsPercent($event, cargoForm)"
            ></p-inputNumber>
          </ng-container>
          <ng-container *ngIf="col.field === CargoTableColumnType.VoyageComission">
            <label class="small-label">{{ col.header }} </label>
            <span [ngClass]="{ 'show-invalid': true }">
              <p-inputNumber
                [useGrouping]="false"
                [minFractionDigits]="0"
                [maxFractionDigits]="2"
                [min]="0"
                [max]="100"
                [step]="0"
                [formControl]="cargoForm.controls.voyage_commission"
                class="p-inputtext-sm w-full"
                styleClass="w-full"
                inputStyleClass="w-full"
                [inputId]="'cargo_form_group_voyage_commission_' + i"
                estimatorDecimals
                [tabindex]="tabIndex"
              ></p-inputNumber>
            </span>
          </ng-container>
          <ng-container
            *ngIf="
              col.field === CargoTableColumnType.Lumpsum &&
              showLumpsum(cargoForm?.controls?.amount_type?.value)
            "
          >
            <!-- тултип есть в CargoTableColumnType.Lumpsum -->
            <label class="small-label" [pTooltip]="col.tooltip" tooltipPosition="top"
              >{{ col.header }}
            </label>
            <estimator-price-and-currency-input
              [max]="MAX_FINANCE_VALUE"
              [disabled]="isFixed"
              [id]="'cargo_form_group_freight_lumpsum_' + i"
              [currencies]="currencies"
              [price]="cargoForm.controls.lumpsum.value"
              [currency]="cargoForm.controls.currency.value"
              [priceFormControl]="cargoForm.controls.lumpsum"
              [currencyFormControl]="cargoForm.controls.currency"
              [customTabIndex]="tabIndex"
              [showCurrency]="false"
              (priceHasChangeAndNotZero)="onChangePrice(cargoForm, 'price')"
            ></estimator-price-and-currency-input>
          </ng-container>
          <!--    <ng-container *ngIf="col.field === CargoTableColumnType.BreakEven">
          <span
            *ngFor="let item of breakEvenArr[i] | keyvalue"
            (click)="setBreakEven(+item.value, i)"
            [ngClass]="{ 'cursor-pointer': item.value !== 0 }"
            >{{ item.value }}</span
          >
        </ng-container> -->
          <ng-container *ngIf="col.field === CargoTableColumnType.Company">
            <label class="small-label">{{ col.header }} </label>
            <!-- also use in handover-modal -->
            <p-autoComplete
              [formControl]="cargoForm.controls.company"
              [suggestions]="companies"
              [minLength]="minValueLength"
              (completeMethod)="onSearchCompany($event)"
              (onSelect)="onSelectCompany($event, cargoForm)"
              (onBlur)="onBlurCompany($event, cargoForm)"
              [showEmptyMessage]="true"
              [maxlength]="40"
              emptyMessage="Not found"
              appendTo="body"
              field="name"
              dataKey="id"
              class="p-inputtext-sm w-full"
              styleClass="w-full"
              inputStyleClass="w-full"
              [inputId]="'cargo_form_group-company_id' + i"
              [tabindex]="tabIndex"
            >
              <ng-template pTemplate="footer">
                <p-button
                  *ngIf="!companies.length"
                  [icon]="PrimeNgIcons.PLUS"
                  [outlined]="true"
                  label="Add"
                  styleClass="w-full p-1 shadow-none"
                  (click)="createCompany(cargoForm)"
              /></ng-template>
            </p-autoComplete>
          </ng-container>
          <ng-container *ngIf="col.field === CargoTableColumnType.Intake && !isTanker">
            <label class="small-label visibility-hidden">{{ col.header }}</label>
            <div class="flex justify-content-end pr-1">
              <p-button
                size="small"
                [disabled]="isFixed"
                [icon]="getOpenHideIntakeIcon(cargoForm)"
                [id]="'cargo_form_group_open_or_hide_intake_' + i"
                styleClass="p-button-small primary-color border-round-sm"
                [tabindex]="tabIndex"
                (onClick)="openHideIntake(cargoForm)"
              ></p-button>
            </div>
          </ng-container>
        </td>
        <!--   </tr> -->
      </div>
      <form class="primeng-form" id="cc-cargoes-f">
        <!--  intake row -->
        <div *ngIf="cargoForm.controls.show_intake.value && !isTanker">
          <td>
            <label class="small-label">Draft</label>
            <div class="flex button-addon">
              <p-inputNumber
                [max]="MAX_FINANCE_VALUE"
                [useGrouping]="false"
                [minFractionDigits]="0"
                [maxFractionDigits]="2"
                [min]="0"
                [step]="0"
                [formControl]="getDraftRestrictionControl(cargoForm)"
                class="p-inputtext-sm w-full"
                styleClass="w-full"
                inputStyleClass="w-full"
                [inputId]="'cargo_form_group_load_port_draft_restriction_' + i"
                estimatorDecimals
                [tabindex]="tabIndex"
              ></p-inputNumber>
              <estimator-button-switch
                [disabled]="isFixed"
                [options]="loadPortDraftRestrictionUnitsTypes"
                [tabindex]="tabIndex"
                [value]="
                  cargoForm.controls.cargo_calculator.controls
                    .load_port_draft_restriction_units_type.value
                "
                [id]="'cargo_form_group_load_port_draft_restriction_units_type_' + i"
                (onChange)="onLoadPortDraftRestrictionUnitsTypeChange(cargoForm, $event)"
                optionLabel="short_name"
                optionValue="value"
              >
              </estimator-button-switch>
            </div>
          </td>
          <td>
            <label class="small-label">Density</label>
            <div class="flex button-addon">
              <p-dropdown
                [options]="portWaterTypes"
                [appendTo]="'body'"
                optionLabel="name"
                optionValue="value"
                [formControl]="cargoForm.controls.cargo_calculator.controls.load_port_water_type"
                class="p-inputtext-sm hide-dropdown-button w-full"
                styleClass="w-full"
              >
                <ng-template let-type pTemplate="selectedItem"
                  >{{ type?.information }}
                </ng-template>
              </p-dropdown>
              <estimator-button-switch
                [disabled]="isFixed"
                [options]="portSeasonTypes"
                [tabindex]="tabIndex"
                [value]="cargoForm.controls.cargo_calculator.controls.load_port_season_type.value"
                [id]="'cargo_form_group_load_port_season_type_' + i"
                (onChange)="onLoadPortSeasonTypeChange($event, cargoForm)"
                optionLabel="short_name"
                optionValue="name"
              >
              </estimator-button-switch>
            </div>
          </td>
          <td>
            <label class="small-label"
              >Stow factor
              <!-- ({{ getStowFactorText(cargoForm) }}) --></label
            >
            <div class="flex button-addon">
              <p-inputNumber
                [max]="MAX_FINANCE_VALUE"
                [useGrouping]="false"
                [minFractionDigits]="0"
                [maxFractionDigits]="2"
                [min]="0"
                [step]="0"
                [formControl]="getStowFactorControl(cargoForm)"
                class="p-inputtext-sm w-full"
                styleClass="w-full"
                inputStyleClass="w-full"
                [inputId]="'cargo_form_group_stowage_factor_' + i"
                estimatorDecimals
                [tabindex]="tabIndex"
              ></p-inputNumber>
              <estimator-button-switch
                [disabled]="isFixed"
                [options]="stowageFactorUnitsTypes"
                [tabindex]="tabIndex"
                [value]="
                  cargoForm.controls.cargo_calculator.controls.stowage_factor_units_type.value
                "
                [id]="'cargo_form_group_stowage_factor_units_type_' + i"
                (onChange)="onStowageFactorUnitsTypeChange(cargoForm, $event)"
                optionLabel="short_name"
                optionValue="value"
              >
              </estimator-button-switch>
            </div>
          </td>
          <td class="relative w-3">
            <label class="small-label"
              ><a
                class="text-primary"
                [ngClass]="{ 'pointer-events-none': isFixed }"
                (click)="onOpenCargoCalculator(cargoForm)"
                >Intake</a
              ></label
            >
            <p-inputNumber
              [max]="MAX_FINANCE_VALUE"
              [useGrouping]="false"
              [minFractionDigits]="0"
              [maxFractionDigits]="2"
              [min]="0"
              [step]="0"
              [formControl]="cargoForm.controls.cargo_calculator.controls.summ_max_fields"
              class="p-inputtext-sm w-full"
              styleClass="w-full"
              inputStyleClass="w-full"
              [inputId]="'cargo_form_group_summ_max_fields_' + i"
              estimatorDecimals
              [tabindex]="tabIndex"
            ></p-inputNumber>
            <span
              [ngClass]="{ 'pointer-events-none': isFixed }"
              class="material-icons absolute cargo-intake-material cursor-pointer"
              (click)="onCopyQuantity(cargoForm)"
              [id]="'cargo_intake_insert_button_' + i"
              pTooltip="Ctrl + Q set all quantities to events form"
              >input</span
            >
          </td>
          <td class="w-3rem">
            <!-- <div class="flex flex-column"> -->
            <label class="small-label">{{ LaytimeLabel.Demurrage }}</label>
            <!-- <p-inputNumber
        [useGrouping]="false"
        [minFractionDigits]="0"
        [maxFractionDigits]="2"
        [min]="0"
        [step]="0"
        [formControl]="cargoForm.controls."
        class="p-inputtext-sm w-full"
        styleClass="w-full"
        inputStyleClass="w-full"
        [inputId]="'cargo_form_group-ws_percent' + i"
        [tabindex]="tabIndex"
        estimatorDecimals
      ></p-inputNumber> -->
            <p-button
              size="small"
              [disabled]="isFixed"
              [label]="getDemDesButtonLabel(cargoForm)"
              [id]="'cargo_form_group_add_or_delete_demurrage_' + i"
              styleClass="p-button-small w-full primary-color"
              [tabindex]="tabIndex"
              (onClick)="onAddOrDeleteDemurrage(cargoForm)"
            ></p-button>
            <!--   </div> -->
          </td>
        </div>
        <!-- tanker demurrage row -->
        <div *ngIf="isTanker">
          <td>
            <label class="small-label">{{ LaytimeLabel.Demurrage }}</label>
            <estimator-price-and-currency-input
              [max]="MAX_FINANCE_VALUE"
              [disabled]="isFixed"
              [id]="'cargo_form_group_demurrage_rate_' + i"
              [currencies]="currencies"
              [price]="cargoForm.controls.demurrage_rate.value"
              [priceFormControl]="cargoForm.controls.demurrage_rate"
              [customTabIndex]="tabIndex"
              [showCurrency]="false"
            ></estimator-price-and-currency-input>
            <!--   <p-inputNumber
              [max]="MAX_FINANCE_VALUE"
              [useGrouping]="false"
              [minFractionDigits]="0"
              [maxFractionDigits]="2"
              [min]="0"
              [step]="0"
              [formControl]="cargoForm.controls.demurrage_rate"
              class="p-inputtext-sm w-full"
              styleClass="w-full"
              inputStyleClass="w-full"
              [inputId]="'cargo_form_group_demurrage_rate_' + i"
              estimatorDecimals
              [tabindex]="tabIndex"
            ></p-inputNumber> -->
          </td>
          <td>
            <label class="small-label">Allowed</label>
            <estimator-user-duration-input
              [userInputDuration]="cargoForm.controls.allowed_time.value || emptyDuration"
              [control]="cargoForm.controls.allowed_time"
              [id]="'cargo_form_group_allowed_time_' + i"
              [showZero]="true"
              [disabled]="isFixed"
            ></estimator-user-duration-input>
          </td>
          <td>
            <label class="small-label">Plan</label>
            <estimator-user-duration-input
              [userInputDuration]="calculatePlanned(cargoForm, i)"
              [disabled]="true"
              [hideDurationType]="true"
              [id]="'cargo_form_group_planned_time_' + i"
              [showZero]="true"
            ></estimator-user-duration-input>
          </td>
          <td>
            <label class="small-label">Diff</label>
            <estimator-user-duration-input
              [userInputDuration]="calculateDifference(cargoForm, i)"
              [disabled]="true"
              [hideDurationType]="true"
              [id]="'cargo_form_group_difference_time_' + i"
              [showZero]="true"
            ></estimator-user-duration-input>
          </td>
          <!--           <td>
            <label
              class="small-label cursor-pointer"
              pTooltip="Extra demurrage time"
              tooltipPosition="top"
              >EDT</label
            >
            <estimator-user-duration-input
              [userInputDuration]="cargoForm.controls.extra_demurrage_time.value || emptyDuration"
              [control]="cargoForm.controls.extra_demurrage_time"
              [hideDurationType]="true"
              [id]="'cargo_form_group_extra_demurrage_time_' + i"
            ></estimator-user-duration-input>
          </td> -->
          <td>
            <label class="small-label">Result</label>
            <p class="m-0 mt-1 small-label">{{ getDem(cargoForm) }}</p>
          </td>
        </div>
      </form>
    </div>
  </ng-template>
</p-table>
