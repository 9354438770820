export interface PortSeason {
  name: string;
  value: SeasonType;
  color?: string;
  short_name?: string;
}

export enum SeasonType {
  WINTER = 'winter',
  SUMMER = 'summer',
}
