import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CargoRules } from '@estimator/models';
import { BaseService } from '../base';

@Injectable({
  providedIn: 'root',
})
export class CargoRulesService extends BaseService<CargoRules> {
  constructor(protected override readonly http: HttpClient) {
    super(http);
    this.setPath('/api/sh-inc-sh-ex/rules');
  }
}
