import { AfterViewInit, Component, HostListener, ViewChild, ViewContainerRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Currency, DEFAULT_FIAT_MULTIPLIER, PriceDto, STRING } from '@estimator/models';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { debounceTime } from 'rxjs';
const KEY_ENTER = 'Enter';
const KEY_TAB = 'Tab';

@Component({
  selector: 'estimator-numeric-editor',
  template: `
    <form [formGroup]="priceForm" class="price-form">
      <div class="price-form-input">
        <input
          #input
          type="number"
          class="aggrid-numeric-editor"
          formControlName="price"
          estimatorZeroDecimals
        />
      </div>
      <div class="price-form-input">
        <input
          type="text"
          placeholder=""
          aria-label="Number"
          matInput
          formControlName="textValue"
          [matAutocomplete]="currencyAutocomplete"
          class="aggrid-input"
          #autoCompleteInput
        />
        <mat-autocomplete
          #auto="matAutocomplete"
          panelClass="ag-grid-select-panel"
          #currencyAutocomplete
          name="portSearch"
          class="ag-grid-autocomplete cargo-autocomplete"
          (click)="stopPropagation($event)"
        >
          <mat-option
            *ngFor="let currency of filteredCurrencies"
            [value]="currency"
            (onSelectionChange)="onOptionSelect(currency)"
            class="low-height"
          >
            <p>{{ currency.currency_code }}</p>
          </mat-option>
          <ng-container *ngIf="currencies.length < 1">
            <mat-option [disabled]="true">
              <p>No currencies found</p>
            </mat-option>
          </ng-container>
        </mat-autocomplete>
      </div>
    </form>
  `,
  styles: [
    `
      .price-form {
        width: 100%;
        height: 100%;
        border: none;
        background: none;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 2px;
        .aggrid-numeric-editor {
          width: 100%;
          height: 100%;
          border: none;
          background: none;
        }
        .price-form-input {
          input {
            width: 100%;
            height: 100%;
            border: none;
            background: none;
          }
          width: 100%;
          height: 100%;
          border: none;
          background: none;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          input {
            text-align: right;
          }
        }
      }
    `,
  ],
})
export class AgGridPriceEditorComponent implements ICellEditorAngularComp, AfterViewInit {
  @ViewChild('input', { read: ViewContainerRef }) input!: ViewContainerRef;
  value: PriceDto = { price: 0 };
  currencies: Currency[] = [];
  filteredCurrencies: Currency[] = [];
  priceForm = new FormGroup({
    price: new FormControl<number>(0, { nonNullable: true }),
    currency: new FormControl<Currency | null>(null),
    textValue: new FormControl(''),
  });
  private params: any;
  private cancelBeforeStart = false;

  @HostListener('click', ['$event'])
  stopPropagation(event: MouseEvent): void {
    event.stopImmediatePropagation();
  }

  agInit(params: any): void {
    this.params = params;
    if (params.value) {
      const inputValue: PriceDto = params.value;
      if (inputValue.currency && inputValue.price) {
        this.value.price =
          inputValue.price / (inputValue.currency?.fiat_multiplier || DEFAULT_FIAT_MULTIPLIER);
        this.value.currency = inputValue.currency;
      } else if (inputValue.price && !inputValue.currency) {
        this.value.price = inputValue.price / DEFAULT_FIAT_MULTIPLIER;
      } else if (!inputValue.price && inputValue.currency) {
        this.value.currency = inputValue.currency;
      }
      if (inputValue.currency?.currency_code) {
        this.value.textValue = inputValue.currency.currency_code;
      }
      this.priceForm.patchValue(this.value);
    }
    if (params.currencies) {
      this.currencies = params.currencies;
      this.filteredCurrencies = params.currencies;
    }
    this.params = params;
    this.priceForm.controls.textValue.valueChanges.pipe(debounceTime(500)).subscribe((res) => {
      if (typeof res === STRING && res) {
        this.filteredCurrencies = this.currencies.filter((value) => {
          return value.currency_code?.includes(res);
        });
      }
    });
  }

  getValue(): PriceDto {
    const result = this.priceForm.value as PriceDto;
    if (!result.price) {
      result.price = 0;
    }
    if (result.currency) {
      result.price = Math.round(
        result.price * (result.currency?.fiat_multiplier || DEFAULT_FIAT_MULTIPLIER)
      );
    }
    return result;
  }

  isCancelBeforeStart(): boolean {
    return this.cancelBeforeStart;
  }

  ngAfterViewInit() {
    window.setTimeout(() => {
      this.input?.element.nativeElement.select();
    });
  }

  onOptionSelect(currency: Currency): void {
    this.priceForm.patchValue({ textValue: currency.currency_code, currency });
  }

  private finishedEditingPressed(event: any) {
    const key = event.key;
    return key === KEY_ENTER || key === KEY_TAB;
  }
}
