import { HttpParams } from '@angular/common/http';
import { FormControl } from '@angular/forms';
import { SortModelItem } from 'ag-grid-community';
import { LngLatLike } from 'mapbox-gl';
import { PrimeIcons, SelectItem } from 'primeng/api';
import { Currency } from './currency';
import { CascadeNavigation, FilterStatusDeal } from './deal';
import { DealEvent } from './deal-event';
import { FinanceTotals } from './deal-finances';
import { FilterType } from './folder';
import { ShiplistZoneType } from './geographic';
import { ResultBarPosition } from './ui-settings';
import { PresetNames, SearchRawVesselsAndPortsResponse } from './vessel';

export const STOP_EVENT_ROW_HEIGHT = 21;
export const CARGO_EVENT_ROW_HEIGHT = 20;
export const DEFAULT_BREAK_EVEN_STEP = 0.5;
export const COUNT_FOR_CYCLE_BREAK_EVEN = 11;
export const PLUS_TO_COEFFICIENT = 1;
export const DIVISOR_FOR_PERCENT = 100;
export const DEFAULT_FIAT_MULTIPLIER = 100;
export const COUNT_FOR_UNIQUE_ROW_ID = 100;
export const USD_ID = 3;
export const FULL_PERCENTS = 100;
export const CONDITION_COUNT_FOR_FORMAT_AXES = 1000;
export const FEET_COEFFICIENT = 35.3147;
export const MILLION = 1000000;
export const MILLISECONDS_IN_DAY = 86400000;
export const STARTED_NUMBER_OF_NAV_LINKS = 1;
export const DEFAULT_MAP_ZOOM = 4;
export const COASTER_RESTR_CANAL_ID = 20;
export const ZERO_STRING = '0';
export const ESTIMATOR_ROUTE = '/estimator';
export const ESTIMATOR_DRY_TEXT = 'XRoute Dry';
export const ESTIMATOR_TANKER_ROUTE = '/estimator-tanker';
export const ESTIMATOR_TANKER_TEXT = 'XRoute Tanker';
export const ESTIMATOR_VESSEL_ROUTE = '/vessels';
export const ESTIMATOR_VESSEL_ROUTE_TEXT = 'Vessel DB';
export const ADMIN_TEXT = 'Admin panel';
export const MAP_TEXT = 'Map';
export const PLATFORM_ROUTE_LANDING = '/products/bunker-trading-platform';
export const PLATFORM_ROUTE = '/platform';
export const PLATFORM_TEXT = 'Trading';
export const HELP_ROUTE = '/pages/help';
export const SHIPLIST_ROUTE = '/xlist';
export const SHIPLIST_TEXT = 'XList';
export const VMS_ROUTE = '/xmen';
export const VMS_TEXT = 'VMS';
export const ADMIN_ROUTE = '/admin';
export const FEEDBACK_TEXT = 'Feedback';
export const OK_TEXT = 'OK';
export const CANCEL_TEXT = 'Cancel';
export const TABLES_STATE_KEY = 'tables-state';
export const FOLDERS_FILTER_MODEL_KEY = 'folders-filterModel';
export const textTanker = 'TANKER';
export const textBulk = 'BULK';
export const FOLDERS_FILTER_MODEL_KEY_TANKER = `${FOLDERS_FILTER_MODEL_KEY}-${textTanker}`;
export const FOLDERS_FILTER_MODEL_KEY_BULK = `${FOLDERS_FILTER_MODEL_KEY}-${textBulk}`;
export const REQUEST_FOLDER_ID_KEY = 'requestFolderId';
export const REQUEST_FOLDER_ID_KEY_TANKER = `${REQUEST_FOLDER_ID_KEY}-${textTanker}`;
export const REQUEST_FOLDER_ID_KEY_BULK = `${REQUEST_FOLDER_ID_KEY}-${textBulk}`;
export const FILTER_SIDEBAR_SEARCH_KEY = 'filter-sidebar-search'; // важно, что ключ ещё добавляет к себе VoyageType
export const FILTER_SIDEBAR_USERS_KEY = 'filter-sidebar-users'; // важно, что ключ ещё добавляет к себе VoyageType
export const IS_COPY_ALIEN_DEAL_KEY = 'isCopyAlienDeal';
export const IS_COPY_ALIEN_FOLDER_KEY = 'isCopyAlienFolder';
// export const SETTINGS_LOCAL_STORAGE_KEY = 'userSettings';
export const DETAIL_ID_START_DRAG = 'detailIdStartDrag';
export const DRAG_DROP_IN_DEALS_LIST = 'drag&DropInDealsList';
export const DROP_EFFECT_COPY = 'copy';
export const OPACITY_5 = 'opacity-5';
export const NOT_APPLICABLE = 'N/A';
export const mglMapStyleGlobe = 'mapbox://styles/uwe11/cl9fblhjs000l15phnd4ak10h';
export const mglMapStyleFlat = 'mapbox://styles/uwe11/clh6innu300sd01qybmub7f9n';
export const mglMapStyleFlatDark = 'mapbox://styles/uwe11/clu10rdw8001401qr2zpedtb5';
export const mglMapCenter = [-74.5, 40] as LngLatLike;
export const mglMapAccessToken =
  'pk.eyJ1IjoidXdlMTEiLCJhIjoiY2w5ZmI2YTh3MDVpcjNwcng0eGt3d253YyJ9.XfIHkh48RwAth_7CZNfxSw';
export const STANDART_TIME_FORMAT = 'dd.MM.YYYY HH:mm';
export const STANDART_DATE_FORMAT = 'dd.MM.YYYY';
export const DATE_INPUT_TIME_FORMAT = 'yyyy-MM-ddTHH:mm';
export const UTC_TIME_FORMAT = 'UTC';
export const UNDEFINED = 'undefined';
export const STRING = 'string';
export const SIGN_IN = 'Sign In';
export const SIGN_OUT = 'Sign Out';
export const SIGN_UP = 'Sign Up';
export const RESTORE_PASSWORD = 'Restore password';
export const DEFAULT_USER = 'user@xmar.com';
export const DEFAULT_FIRST_NAME = 'First name';
export const DEFAULT_LAST_NAME = 'Last name';
export const CREATE_STRING = 'create';
export const ERROR_BASE_TEMPLATE = 'Error:';
export const FOR_COMPANY_FIELD = 'for_company';
export const LIST_TITLE = 'List';
export const EMPTY_LIST_TITLE = '';
export const REMARKS_LIST_TITLE = 'Remarks';
export const DRAFT_TITLE = 'Draft';
export const VERSION_TITLE = 'Version';
export const TEN = 10;
export const THOUSAND = 1000;
export const DEFAULT_AUTOSAVE = 2000;
export const DEFAULT_AUTOSAVE_DELAY = 60000;
export const LIMIT_SERVER_SIDE = 100;
export const CASCADE_NODE = 10;
export const DEFAULT_BACKGROUND = 'rgba(6, 11, 24, 0.3)';
export const DEFAULT_LADEN_ROUTE = '#D39230';
export const DEFAULT_BALLAST_ROUTE = '#017EFA';
export const EVENTS_FORM_GROUP_PORT = 'events_form_group-port-';
export const DRAFT_FOLDER_ID = 1;
export const MAX_FINANCE_VALUE = 9999999;
export const MAX_VALUE = 999;
export const ID_USER_SETTINGS_FORM_SHOW_CANALS = 'user_settings_form-show_canals';
export const ID_USER_SETTINGS_FORM_CHANGE_THEME = 'user_settings_form-change-theme';
export const ID_USER_SETTINGS_FORM_CHANGE_PORT_WIDGET = 'user_settings_form-change-port-widget';
export const ROUTE_POINT_TEXT = 'RP:';
export const TEXT_COMPANY_INVITATION = 'company-invitation';
export const TEXT_EUA_CO2_24 = 'EUA CO2 ‘24';
export const SHINC_ID = 9;
export const TEXT_REMARKS = 'remarks';
export const DEFAULT_TC_COMM = 3.75;
export const DEFAULT_VC_COMM = 1.25;
export const TEXT_ZERO = 'zero';
export const THREE_NUMBER = 3;
export const FOURTY_NUMBER = 40;
export const EIGHTY_NUMBER = 80;
export const TEXT_CBM = 'CBM';
export const TEXT_CFT = 'CFT';
export const TEXT_MT = 'MT';
export const FOLDER_PARAMS = 'folder';
export const DEAL_PARAMS = 'deal';
export const LOCALHOST_TEXT = 'localhost';
export const GREY_MAP_ZONE_COLOR = '#bbbfc2';
export const BLUE_MAP_ZONE_COLOR = '#7c9fd7';
export const FOLDER_NAME_MAX_LENGTH = 84;
export const MILLISECONDS_IN_SECONDS = 1000;
export const MAX_FILE_SIZE = 10 * 1024 * 1024;
export const DEFAULT_VESSEL_SPEED = 12.5;
export const FEET_TO_METERS_CONVERSION_FACTOR = 0.3048;
export const SAVE_LABEL = 'Save';
export const ESTIMATE_LABEL = 'Estimate';

const HFO_COEF = 3.1144;
const LFO_COEF = 3.15104;
const DSO_COEF = 3.206;
const LNG_COEF = 2.75;
export const FuelTypeCoef: { [key: number]: number } = {
  1: HFO_COEF, // IFO-180 - Heavy Fuel Oil (HFO)
  2: HFO_COEF, // IFO-380 - Heavy Fuel Oil (HFO)
  3: LFO_COEF, // VLSFO - Light Fuel Oil (LFO)
  4: LFO_COEF, // ULSFO - Light Fuel Oil (LFO)
  5: DSO_COEF, // MDO - Diesel/Gas Oil
  6: DSO_COEF, // MGO - Diesel/Gas Oil
  7: LNG_COEF, // LNG - Liquified Natural Gas (LNG)
};

export const emptySearchRawVesselsAndPortsResponse: SearchRawVesselsAndPortsResponse = {
  ports: [],
  raw_vessels: [],
};

export interface NavigationLink {
  icon?: typeof PrimeNgIcons;
  link: string;
  title?: string;
  id?: number;
}

export interface ButtonParams {
  title: string;
  tooltip: string;
  icon: string;
  color?: string;
  click: () => void;
}

export enum NotificationType {
  Error = 'error',
  Warning = 'warning',
  Success = 'success',
}

export interface PriceDto {
  price?: number;
  currency?: Currency;
  textValue?: string;
}

export interface DragEventDto {
  dealEvent: DealEvent;
  oldIndex: number;
  newIndex: number;
}

export enum MaterialThemeColor {
  Primary = 'primary',
  Accent = 'accent',
  Warn = 'warn',
}

export enum PrimeNgColors {
  Error = 'red',
  Primary = 'var(--primary-color)',
  White = 'white',
  Black = 'black',
  LightGrey = '#80808060',
  RedColor = 'var(--red-color)',
  BlueColor = 'var(--blue-color)',
  Transparent = 'var(--input-background-color)',
}

export const PrimeNgIcons = {
  ...PrimeIcons,
  Anchor: 'pi pi-anchor pi-custom',
  AnchorWhite: 'pi pi-anchor-white pi-custom',
  AnimatedSpinner: 'pi pi-spin pi-spinner',
  Fuel: 'pi pi-fuel pi-custom',
  XMar: 'pi pi-xmar pi-custom',
  XMarWhite: 'pi pi-xmar-white pi-custom',
  Propeller: 'pi pi-proppeler pi-custom',
  Wind: 'pi pi-wind pi-custom',
  Vessel: 'pi pi-vessel pi-custom',
  VesselGrey: 'pi pi-vessel-grey pi-custom',
  VesselWhite: 'pi pi-vessel-white pi-custom',
  SetPreset: 'pi pi-set-preset pi-big',
  SetPresetSelected: 'pi pi-set-preset-selected pi-custom',
};

export enum TableStateKeys {
  EventList = 'eventsList',
  EventCargoList = 'eventsCargoList',
}

export enum UserTimeSettings {
  UTC = 'UTC',
  PortLocalTime = 'Port LT',
  LocalTime = 'LT',
}

export enum GrainCalculationType {
  GrainCalculationType = 'grain',
  BaleCalculationType = 'bale',
  BothCalculationType = 'both',
}

export enum GrainCalculationTypesAliasShort {
  GRAIN = 'GR',
  BALE = 'BA',
  BOTH = 'BT',
}

export enum GrainCalculationTypesTooltip {
  GRAIN = 'Grain',
  BALE = 'Bale',
  BOTH = 'Both',
}

export const GrainCalculationTypes: SimpleButtonSwitch[] = [
  {
    value: GrainCalculationType.GrainCalculationType,
    color: PrimeNgColors.BlueColor,
    short_name: GrainCalculationTypesAliasShort.GRAIN,
    tooltip: GrainCalculationTypesTooltip.GRAIN,
  },
  {
    value: GrainCalculationType.BaleCalculationType,
    color: PrimeNgColors.BlueColor,
    short_name: GrainCalculationTypesAliasShort.BALE,
    tooltip: GrainCalculationTypesTooltip.BALE,
  },
  {
    value: GrainCalculationType.BothCalculationType,
    color: PrimeNgColors.BlueColor,
    short_name: GrainCalculationTypesAliasShort.BOTH,
    tooltip: GrainCalculationTypesTooltip.BOTH,
  },
];

export enum InputTypes {
  Password = 'password',
  Text = 'text',
}

export enum FormValidationErrors {
  PasswordRequired = 'Password is required',
  PasswordPattern = 'The password must contain digit, upper or lowercase letter. Minimum length - 6 characters.',
  PasswordMismatch = 'Password mismatch',
  EmailRequired = 'Email is required',
  EmailPattern = 'Value should be a valid email adress',
}

export enum DomEvents {
  Blur = 'blur',
  Focus = 'focus',
  Change = 'change',
}

export enum ErrorNotifications {
  ErrorUpdatingVessel = 'Error when updating a vessel',
  ErrorDeletingDeal = 'Error when deleting a deal',
  ErrorRequestLightDeals = 'Error when request deals',
  ErrorCreatingDeal = 'Error when create a deal',
  ErrorUpdatingDeal = 'Error when update a deal',
  InvitationExpired = 'The invitation has expired',
  ErrorSearchFolders = 'Error when search folders',
  ErrorChangingStatus = 'Error when changing status',
}

export enum WarningNotifications {
  CloseCurrFolder = 'Please, close current folder before',
  UnsavedChanges = 'You have unsaved changes',
  SentInvitation = 'You have successfully sent the invitation',
  TryOpenNotOnlyYourFolder = "You are trying to open a folder with someone else's transactions",
  MaxLimitSearchReach = 'You have exceeded the maximum allowed number of characters to search for',
}

export enum SuccessNotifications {
  CopyCreated = 'A copy has been created',
  AcceptedInvitation = 'You successfully have accepted the invitation',
}

export enum MapTypes {
  FeatureCollection = 'FeatureCollection',
  Feature = 'Feature',
  Point = 'Point',
  Polygon = 'Polygon',
  LineString = 'LineString',
}

export enum MapFeatureProperties {
  Coordinates = 'coordinates',
  Cluster = 'cluster',
  Name = 'name',
  IconImage = 'iconImage',
  RouteColor = 'routeColor',
  IconColor = 'iconColor',
  Alpha2 = 'alpha_2',
  TimeZoneName = 'time_zone_name',
  TimeZone = 'time_zone',
  CanalsZones = 'canals_zones',
  Edge = 'edge',
  Cost = 'cost',
  StartPoint = 'start_point',
  EndPoint = 'end_point',
  Seq = 'seq',

  // for alternative routes
  Index = 'index',
  EventId = 'eventId',
  Start = 'start',
  End = 'end',
  ExceptedCanals = 'excepted_canals',
  CrossedCanals = 'crossed_canals',
  Distance = 'distance',
  Alternative = 'alternative',
}

export enum SidesWorld {
  N = 'N',
  S = 'S',
  W = 'W',
  E = 'E',
}

export const MAX_NUMBERS_AFTER_DOT = 6;

export const setLimitAndOffsetToParams = (params: HttpParams, limit: number, offset: number) => {
  params = params.set('limit', limit.toString());
  params = params.set('offset', offset.toString());
  return params;
};

export const setTableParams = (
  params: HttpParams,
  limit: number,
  offset: number,
  sort_order?: /* ShipListSortingType */ 'asc' | 'desc',
  sort_field?: string
) => {
  params = params.set('limit', limit.toString());
  params = params.set('offset', offset.toString());
  if (sort_order) {
    params = params.set('sort_order', sort_order);
  }
  if (sort_field) {
    params = params.set('sort_field', sort_field);
  }
  return params;
};

export const setSortModelToParams = (params: HttpParams, sortModelItem: SortModelItem) => {
  params = params.set('sort_field', sortModelItem.colId);
  params = params.set('sort_order', sortModelItem.sort);
  return params;
};

export const defaultNavigations: CascadeNavigation[] = [
  { name: '1-10', subnavs: [] },
  { name: '11-20', subnavs: [] },
  { name: '21-30', subnavs: [] },
  { name: '31-40', subnavs: [] },
  { name: '41-50', subnavs: [] },
  { name: '51-60', subnavs: [] },
  { name: '61-70', subnavs: [] },
  { name: '71-80', subnavs: [] },
  { name: '81-90', subnavs: [] },
  { name: '91-100', subnavs: [] },
];

export const falseText = 'false';
export const trueText = 'true';
export const DEFAULT_WIDTH_FOR_NOTIFICATION = '50vw';

export const activeStatusName = 'active';
export const archivedStatusName = 'archived';
export const activeStatus: FilterStatusDeal = { name: activeStatusName };
export const archivedStatus: FilterStatusDeal = { name: 'archived' };
export const statusesDeals: FilterStatusDeal[] = [activeStatus, archivedStatus];

export enum FuelTypes {
  VLSFO = 'VLSFO',
  ULSFO = 'ULSFO',
  LSMGO = 'LSMGO',
  HSFO = 'HSFO',
}

/* export interface FuelExtended {
  name: FuelTypes;
  short_name: string;
  color: string;
} */

export type FuelTypesExtended = {
  [key in FuelTypes]: SimpleButtonSwitch;
};

export const FuelsExtended: FuelTypesExtended = {
  [FuelTypes.VLSFO]: { value: FuelTypes.VLSFO, short_name: 'VL', color: 'var(--color-vlsfo)' },
  [FuelTypes.ULSFO]: { value: FuelTypes.ULSFO, short_name: 'UL', color: 'var(--color-ulsfo)' },
  [FuelTypes.LSMGO]: { value: FuelTypes.LSMGO, short_name: 'LS', color: 'var(--color-lsmgo)' },
  [FuelTypes.HSFO]: { value: FuelTypes.HSFO, short_name: 'HS', color: 'var(--color-hsfo)' },
};

export const scrubberFuels: SimpleButtonSwitch[] = [
  FuelsExtended.LSMGO,
  FuelsExtended.VLSFO,
  FuelsExtended.HSFO,
];

export const startFilterModelFalseIsArchived = {
  values: [falseText],
  filterType: FilterType.SET,
};

export interface ColumnUI<T> {
  field: T;
  header: string;
  icon?: string;
  tooltip?: string;
  width?: string;
  minWidth?: string;
  maxWidth?: string;
  resizable?: boolean;
}

export interface VersionInfo {
  version?: string;
  buildDate?: string;
  revision?: string;
  branch?: string;
}

export enum HttpStatus {
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  InternalServerError = 500,
  UnderMaintenance = 503,
}

export const emptyFinanceTotals: FinanceTotals = {
  freight: 0,
  hire: 0,
  bunker: 0,
  da: 0,
  sundry_misc: 0,
  co2_total_cost: 0,
  ballast_bonus: 0,
  time_charter_commission: 0,
};

export interface ChangeValidation {
  field: string;
  remove?: boolean;
  removeField?: string;
}

export interface MaintanenceError {
  error: string;
  start_time: string;
  end_time: string;
}

export interface ChangePreset {
  newValue: PresetNames;
  oldValue: PresetNames;
}

export interface SimpleButtonSwitch {
  value: string;
  short_name: string;
  color: string;
  tooltip?: string;
}

export interface SimpleObject<Y, T> {
  name: Y;
  value: T;
}

/**
 * enum for text show on front
 */
export enum ZoneTypes {
  ZONES = 'Zones',
  COUNTRIES = 'Countries',
  RANGES = 'Ranges',
}

export const zoneTypesArr: SimpleObject<ZoneTypes, ShiplistZoneType>[] = [
  { value: ShiplistZoneType.ZONE, name: ZoneTypes.ZONES },
  { value: ShiplistZoneType.COUNTRY_ZONE, name: ZoneTypes.COUNTRIES },
  { value: ShiplistZoneType.RANGE, name: ZoneTypes.RANGES },
];

export enum MultiSelectDisplayType {
  COMMA = 'comma',
  CHIP = 'chip',
}

export interface FormDateSwitcherFormGroup {
  switcher?: FormControl<boolean | null>;
  updates_from_days?: FormControl<number | null>;
  dates_from?: FormControl<number | Date | null>;
  dates_to?: FormControl<number | Date | null>;
}

export const ResultBarOptions = [
  { label: 'Bottom', value: ResultBarPosition.BOTTOM },
  { label: 'Sticky (under data)', value: ResultBarPosition.STICKY },
  { label: 'Top', value: ResultBarPosition.TOP },
  { label: 'Map Right', value: ResultBarPosition.MAP_RIGHT },
  { label: 'Map Left', value: ResultBarPosition.MAP_LEFT },
];

export const ColumnOrderOptions: SelectItem[] = [
  { label: 'Left / Right', value: 'left-right' },
  { label: 'Right / Left', value: 'right-left' },
];

export interface AddToPlackListPayload {
  companyName: string;
  comment?: string;
}

export enum CommonStatusLabel {
  DRAFT = 'Draft',
  PENDING = 'Pending',
  FIXED = 'Fixed',
}

export enum ThemeStatusLabel {
  DARK = 'Dark',
  LIGHT = 'Light',
}
