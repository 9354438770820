import { FeatureCollection, Polygon } from 'geojson';
import { BaseModel } from './base-entity';

export interface GeoCoordinates {
  latitude: number;
  longitude: number;
}

export interface GeoZone extends BaseModel {
  included_by_default?: boolean;
  is_canal?: boolean;
  is_da_required?: boolean;
  is_eca_canal?: boolean;
  is_high_purity_zone?: boolean;
  short_name?: string;
  selected?: boolean; // чисто на фронте
  geo_json?: { type: string; features: Polygon };
  create_enter_stop_event?: boolean;
  max_vessel_beam_m?: number;
  max_vessel_draught_m?: number;
  max_vessel_length_m?: number;
  type?: ShiplistZoneType;
  /**
   * for front only, use in shiplist filter zone/port array
   */
  is_zone?: boolean;
  /**
   * uses also in ShiplistSelectedZoneOrPort, ShiplistGeoZoneProperties, Port
   */
  loc_id?: number;
  zone_ids?: number[];
}

/**
 * enum for send to back
 */
export enum ShiplistZoneType {
  /**
   * default value
   */
  ZONE = 'zone',
  COUNTRY_ZONE = 'country_zone',
  RANGE = 'range',
}

export interface AlternativeRoute {
  excepted_canals: number[];
  crossed_canals: number[];
  distance: number;
  routes: FeatureCollection;
}

export interface AlternativeRouteItem {
  index: number;
  eventId: number;
  start: string;
  end: string;
  excepted_canals: number[];
  crossed_canals: number[];
  distance: number;
  alternative: boolean;
}

export interface AlternativeRouteItemSave {
  id: number;
  excepted_canals: number[];
}
