import { Injectable } from '@angular/core';
import { BaseModel } from '@estimator/models';
import { DimensionsService } from '@estimator/services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { catchError, finalize, tap, throwError } from 'rxjs';
import { GetAllDimensions } from './dimension.actions';

export interface DimensionStateModel {
  dimensions: BaseModel[];
  loading: boolean;
}

@State<DimensionStateModel>({
  name: 'dimension',
  defaults: {
    dimensions: [],
    loading: false,
  },
})
@Injectable()
export class DimensionState {
  @Selector()
  static getIsLoading({ loading }: DimensionStateModel): boolean {
    return loading;
  }
  @Selector()
  static getDimensions({ dimensions }: DimensionStateModel): BaseModel[] {
    return dimensions;
  }

  constructor(private readonly dimensionService: DimensionsService) {}

  @Action(GetAllDimensions)
  getAllDimensions({ patchState }: StateContext<DimensionStateModel>) {
    patchState({ loading: true });
    return this.dimensionService.getAll().pipe(
      tap((dimensions) => {
        patchState({ dimensions });
      }),
      catchError((err) => {
        patchState({ loading: false });
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }
}
