import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { getUserOrSystemDuration } from '@estimator/helpers';
import {
  DomEvents,
  MINUTES_IN_DAY,
  MINUTES_IN_HOUR,
  UserInputDuration,
  UserInputDurationFormGroup,
  UserInputDurationType,
} from '@estimator/models';
import { cloneDeep, isEqual } from 'lodash';
import { Subject, filter, map, pairwise, startWith, takeUntil } from 'rxjs';

@Component({
  selector: 'estimator-user-duration-input',
  templateUrl: './user-duration-input.component.html',
  styleUrls: ['./user-duration-input.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserDurationInputComponent implements OnInit, OnDestroy {
  @Input()
  set userInputDuration(data: UserInputDuration) {
    this._userInputDuration = cloneDeep(data);
    this.userDurationFormGroup.patchValue(data, { emitEvent: false });
    this._control.patchValue(data, { emitEvent: false });
    this.calculateServerDuration();
  }
  get userInputDuration(): UserInputDuration {
    return this._userInputDuration;
  }
  // @Input() formLength = 0;
  @Input() set control(fc: FormControl<UserInputDuration | null>) {
    this._control = fc;
    this.calculateServerDuration();
    if (fc.value) {
      this.userDurationFormGroup.patchValue(fc.value, { emitEvent: false });
    }
    if (fc.disabled) {
      this.userDurationFormGroup.disable({ emitEvent: false });
      this.durationInputControl.disable({ emitEvent: false });
    }
  }
  get control(): FormControl<UserInputDuration | null> {
    return this._control;
  }
  @Input()
  set disabled(value: boolean) {
    if (value) {
      this.userDurationFormGroup.disable({ emitEvent: false });
      this.durationInputControl.disable({ emitEvent: false });
    }
  }
  @Input()
  set disabledDuration(value: boolean) {
    if (value) {
      this.durationInputControl.disable({ emitEvent: false });
    }
  }
  @Input()
  set disabledDurationType(value: boolean) {
    if (value) {
      this.userDurationFormGroup.disable({ emitEvent: false });
    }
  }
  @Input() id = 'duration-input';
  @Input() customTabIndex = 0;
  @Input() hideDurationType = false;
  @Input() ignoreChange = false;
  @Input() showZero = false;
  @Output() changeValue = new EventEmitter();
  userDurationFormGroup = this.makeDurationFormGroup();
  durationInputControl = new FormControl<number | null>(null, { updateOn: DomEvents.Blur });
  durationOptions = Object.values(UserInputDurationType);
  private _userInputDuration: UserInputDuration = {};
  private _control = new FormControl<UserInputDuration | null>(null);
  private _onDestroy$ = new Subject<void>();

  constructor(private readonly cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.durationInputControl.valueChanges
      .pipe(
        takeUntil(this._onDestroy$),
        startWith(this.durationInputControl.value),
        pairwise(),
        filter(([prev, curr]) => {
          return true /* !isEqual(curr, prev) */;
        }),
        map(([prev, curr]) => {
          return curr;
        })
      )
      .subscribe((value) => {
        this.userDurationFormGroup.patchValue({ user: value, is_changed: !this.ignoreChange });
      });
    this.userDurationFormGroup.valueChanges
      .pipe(
        takeUntil(this._onDestroy$),
        startWith(this.userDurationFormGroup.value),
        pairwise(),
        filter(([prev, curr]) => {
          return !isEqual(curr, prev);
        }),
        map(([prev, curr]) => {
          return curr;
        })
      )
      .subscribe((value) => {
        if (value.units) {
          const dur = this.durationInputControl.value || 0;
          value.is_changed = !this.ignoreChange;
          switch (value.units) {
            case UserInputDurationType.Days: {
              value.user = (dur || 0) * MINUTES_IN_DAY;
              break;
            }
            case UserInputDurationType.Hours: {
              value.user = (dur || 0) * MINUTES_IN_HOUR;
              break;
            }
            default: {
              value.user = dur;
              break;
            }
          }
        }
        this.control.patchValue(value as UserInputDuration);
        this.changeValue.emit();
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  makeDurationFormGroup(data?: UserInputDuration): FormGroup<UserInputDurationFormGroup> {
    const fg = new FormGroup<UserInputDurationFormGroup>(
      {
        system: new FormControl<number | null>(data?.system || null),
        user: new FormControl<number | null>(data?.user || null),
        is_changed: new FormControl<boolean | null>(data?.is_changed || null),
        units: new FormControl<UserInputDurationType | null>(
          data?.units || UserInputDurationType.Hours
        ),
      },
      { updateOn: DomEvents.Blur }
    );
    return fg;
  }

  calculateServerDuration(): void {
    let dur = 0;
    const value = getUserOrSystemDuration(this._control?.getRawValue());
    const units = this._control?.getRawValue()?.units;
    switch (units) {
      case UserInputDurationType.Days: {
        dur = value / MINUTES_IN_DAY;
        break;
      }
      case UserInputDurationType.Hours: {
        dur = value / MINUTES_IN_HOUR;
        break;
      }
      default: {
        dur = value;
        break;
      }
    }
    const finalValue = this.showZero ? dur : dur || null;
    this.durationInputControl.patchValue(finalValue, { emitEvent: false });
    this.userDurationFormGroup.patchValue({
      is_changed: !this.ignoreChange,
      user: this._control?.value?.user,
      units: this._control?.value?.units,
      system: this._control?.value?.system,
    });
    this.durationInputControl.updateValueAndValidity({ emitEvent: false });
    this.cdr.detectChanges();
  }
}
