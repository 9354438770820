import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { MaterialThemeColor, SuccessOrErrorCardType } from '@estimator/models';

@Component({
  selector: 'estimator-success-or-error-card',
  templateUrl: './success-or-error-card.component.html',
  styleUrls: ['./success-or-error-card.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuccessOrErrorCardComponent {
  @Input() cardText = '';
  @Input() cardType: SuccessOrErrorCardType = SuccessOrErrorCardType.Success;
  @Input() cardLink = '';
  @Input() cardLinkText = '';
  @Input() isContainContent = false;
  MaterialThemeColor = MaterialThemeColor;

  get isSuccess(): boolean {
    return this.cardType === SuccessOrErrorCardType.Success;
  }

  get isError(): boolean {
    return this.cardType === SuccessOrErrorCardType.Error;
  }

  get isLoading(): boolean {
    return this.cardType === SuccessOrErrorCardType.Loading;
  }
}
