import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GeoZone, ShiplistZoneType } from '@estimator/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ShiplistService {
  readonly path = '/api/shiplist';
  constructor(protected readonly http: HttpClient) {}

  /**
   * no arg zone = all zones from back
   */
  getShiplistZones(zone?: ShiplistZoneType): Observable<GeoZone[]> {
    let params = new HttpParams();
    if (zone) {
      params = params.set('type', zone);
    }
    return this.http.get<GeoZone[]>(`${this.path}/zones`, { params });
  }
}
