import {
  PortSeason,
  PortWater,
  PrimeNgColors,
  SeasonType,
  TestPort,
  WaterType,
} from '@estimator/models';

export const PortWaterTypes: PortWater[] = [
  {
    short_name: 'Salt Water',
    name: 'Salt Water (SW: 1.025)',
    value: WaterType.SALT,
    information: 'SW 1.025',
  },
  {
    short_name: 'Brackish Water',
    name: 'Brackish Water (BW: 1.012) ',
    value: WaterType.BRACKISH,
    information: 'BW 1.012',
  },
  {
    short_name: 'Fresh Water ',
    name: 'Fresh Water (FW: 1.000)',
    value: WaterType.FRESH,
    information: 'FW 1.000',
  },
  {
    short_name: 'Tropical Fresh Water',
    name: 'Tropical Fresh Water (TFW: 0.996)',
    value: WaterType.TROPICAL,
    information: 'TFW 0.996',
  },
];

export const platformPortsMock = [
  {
    id: 'e88051b5-281e-4eee-b235-562b283a00dc',
    name: 'Riga',
    countryCode: 'lv',
    active: true,
    __typename: 'ship_port',
  },
  {
    id: '7e58184e-ecca-414c-9dd7-8124f9dc6243',
    name: 'Rio De Janeiro',
    countryCode: 'br',
    active: true,
    __typename: 'ship_port',
  },
  {
    id: '90a55a3a-147c-4d35-ba3d-87cf147bb2a1',
    name: 'Aberdeen',
    countryCode: 'us',
    active: true,
    __typename: 'ship_port',
  },
  {
    id: 'fbad7760-f4d5-4db9-a755-c00c1123867d',
    name: 'Abidjan',
    countryCode: 'ci',
    active: true,
    __typename: 'ship_port',
  },
];
export const PortSeasonTypes: PortSeason[] = [
  { name: 'Summer', value: SeasonType.SUMMER, color: PrimeNgColors.Transparent, short_name: 'S' },
  { name: 'Winter', value: SeasonType.WINTER, color: PrimeNgColors.BlueColor, short_name: 'W' },
];

export const EstimatorPortsMock: TestPort[] = [
  {
    name: 'Riga',
  },
  {
    name: 'Mersin',
  },
  {
    name: 'Iskenderun',
  },
  {
    name: 'Jebel Ali',
  },
  {
    name: 'Riga',
    distance1: '(4,092.79)',
    distance2: '(1,701.52)',
  },
  {
    name: 'Houston',
  },
  {
    name: 'Philadelphia',
  },
  {
    name: 'New York',
  },
  {
    name: 'SINGAPORE',
  },
  {
    name: 'Vladivostok',
  },
  {
    name: 'Alexandria',
  },
  {
    name: 'Athens',
  },
  {
    name: 'Hamburg',
  },
  {
    name: 'Dubai',
  },
  {
    name: 'Riga',
  },
  {
    name: 'SINGAPORE',
  },
  {
    name: 'DILISKELESI ANCH',
  },
  {
    name: 'Novorossiysk',
  },
  {
    name: 'CHITTAGONG ANCH',
  },
  {
    name: 'MONGLA ANCH',
  },
  {
    name: 'Barcelona',
  },
];
