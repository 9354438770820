import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PAGE } from '@estimator/landing-models';

const appRoutes: Routes = [
  {
    path: '',
    loadChildren: () => import('@estimator/landing-pages').then((m) => m.MainPageBunkerModule),
    // pathMatch: 'full',
  },
  {
    path: PAGE.BLOG,
    loadChildren: () => import('@estimator/landing-pages').then((m) => m.PostModule),
  },
  {
    path: PAGE.PRODUCTS,
    loadChildren: () => import('@estimator/landing-pages').then((m) => m.ProductsModule),
  },
  {
    path: PAGE.SUSTAINABILITY,
    loadChildren: () => import('@estimator/landing-pages').then((m) => m.SustainabilityModule),
  },
  {
    path: PAGE.CONTACTS,
    loadChildren: () => import('@estimator/landing-pages').then((m) => m.ContactsModule),
  },
  {
    path: PAGE.ABOUT,
    loadChildren: () => import('@estimator/landing-pages').then((m) => m.AboutModule),
  },
  {
    path: PAGE.PRIVACY_POLICY,
    loadChildren: () => import('@estimator/landing-pages').then((m) => m.PrivacyPolicyModule),
  },
  {
    path: PAGE.TERMS_AND_CONDITIONS,
    loadChildren: () => import('@estimator/landing-pages').then((m) => m.TermsAndConditionsModule),
  },
  {
    path: PAGE.FEATURES,
    loadChildren: () => import('@estimator/landing-pages').then((m) => m.FeaturesModule),
  },
  {
    path: '**',
    redirectTo: PAGE.MAINPAGE,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
