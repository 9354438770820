import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { EventCargoListComponent } from './event-cargo-list.component';
import { AgGridModule } from 'ag-grid-angular';
import { DurationPipe } from '@estimator/helpers';

@NgModule({
  declarations: [EventCargoListComponent],
  imports: [AgGridModule, CommonModule],
  exports: [EventCargoListComponent],
  providers: [DecimalPipe, DurationPipe],
})
export class EventCargoListModule {}
