import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { getValueOrNull } from '@estimator/helpers';
import {
  CANCEL_TEXT,
  CoordinateDialogConfig,
  CoordinatesFormat,
  GeoCoordinates,
  OK_TEXT,
} from '@estimator/models';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { TabViewChangeEvent } from 'primeng/tabview';
import { CoordinateInputComponent } from '../coordinate-input/coordinate-input.component';

@Component({
  selector: 'estimator-coordinate-dialog',
  templateUrl: './coordinate-dialog.component.html',
  styleUrls: ['./coordinate-dialog.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoordinateDialogComponent {
  @ViewChild('latitudeCoordinateInputDMS') latitudeCoordinateInputDMS?: CoordinateInputComponent;
  @ViewChild('longtitudeCoordinateInputDMS')
  longtitudeCoordinateInputDMS?: CoordinateInputComponent;
  @ViewChild('latitudeCoordinateInputDM') latitudeCoordinateInputDM?: CoordinateInputComponent;
  @ViewChild('longtitudeCoordinateInputDM') longtitudeCoordinateInputDM?: CoordinateInputComponent;
  @ViewChild('latitudeCoordinateInput') latitudeCoordinateInput?: CoordinateInputComponent;
  @ViewChild('longtitudeCoordinateInput') longtitudeCoordinateInput?: CoordinateInputComponent;
  @Output() cancel = new EventEmitter();
  @Output() sendCoordinates = new EventEmitter<GeoCoordinates>();
  point?: GeoCoordinates;
  activeTab = CoordinatesFormat.DMS;
  readonly OK_TEXT = OK_TEXT;
  readonly CANCEL_TEXT = CANCEL_TEXT;
  readonly CoordinatesFormat = CoordinatesFormat;
  readonly getValueOrNull = getValueOrNull;

  constructor(private readonly dialogConfig: DynamicDialogConfig<CoordinateDialogConfig>) {
    if (this.dialogConfig.data) {
      const { cancel, sendCoordinates, point } = this.dialogConfig.data;
      if (sendCoordinates) {
        this.sendCoordinates = sendCoordinates;
      }
      if (cancel) {
        this.cancel = cancel;
      }
      if (point) {
        this.point = point;
      }
    }
  }

  onSendCoordinates() {
    const point: GeoCoordinates = {
      latitude: this.getNeedCoordinateComp()?.getValue() as number,
      longitude: this.getNeedCoordinateComp(false)?.getValue() as number,
    };
    this.sendCoordinates.emit(point);
  }

  onCancel() {
    this.cancel.emit();
  }

  onChangeTab(event: TabViewChangeEvent) {
    switch (event.index) {
      case CoordinatesFormat.DMS:
        this.activeTab = CoordinatesFormat.DMS;
        break;
      case CoordinatesFormat.DM:
        this.activeTab = CoordinatesFormat.DM;
        break;
      case CoordinatesFormat.DECIMAL:
        this.activeTab = CoordinatesFormat.DECIMAL;
        break;
    }
  }

  getNeedCoordinateComp(isLatitude = true) {
    if (this.activeTab === CoordinatesFormat.DMS) {
      return isLatitude ? this.latitudeCoordinateInputDMS : this.longtitudeCoordinateInputDMS;
    }
    if (this.activeTab === CoordinatesFormat.DM) {
      return isLatitude ? this.latitudeCoordinateInputDM : this.longtitudeCoordinateInputDM;
    }
    if (this.activeTab === CoordinatesFormat.DECIMAL) {
      return isLatitude ? this.latitudeCoordinateInput : this.longtitudeCoordinateInput;
    }
    return;
  }
}
