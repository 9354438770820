import { Injectable } from '@angular/core';
import { CargoFreightType } from '@estimator/models';
import { FreightTypesService } from '@estimator/services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { catchError, finalize, tap, throwError } from 'rxjs';
import { GetAllFreightTypes } from './freight-type.actions';

export interface FreightTypeStateModel {
  freightTypes: CargoFreightType[];
  loading: boolean;
}

@State<FreightTypeStateModel>({
  name: 'freightType',
  defaults: {
    freightTypes: [],
    loading: false,
  },
})
@Injectable()
export class FreightTypeState {
  @Selector()
  static getIsLoading({ loading }: FreightTypeStateModel): boolean {
    return loading;
  }
  @Selector()
  static getFreightTypes({ freightTypes }: FreightTypeStateModel): CargoFreightType[] {
    return freightTypes;
  }

  constructor(private readonly freightTypeService: FreightTypesService) {}

  @Action(GetAllFreightTypes)
  getAllFreightTypes({ patchState }: StateContext<FreightTypeStateModel>) {
    patchState({ loading: true });
    return this.freightTypeService.getAll().pipe(
      tap((freightTypes) => {
        patchState({ freightTypes });
      }),
      catchError((err) => {
        patchState({ loading: false });
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }
}
