import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  ADMIN_ROUTE,
  ADMIN_TEXT,
  DASHBOARD_ROUTE,
  ESTIMATOR_DRY_TEXT,
  ESTIMATOR_ROUTE,
  ESTIMATOR_TANKER_ROUTE,
  ESTIMATOR_TANKER_TEXT,
  ESTIMATOR_VESSEL_ROUTE,
  ESTIMATOR_VESSEL_ROUTE_TEXT,
  FEEDBACK_TEXT,
  HELP_ROUTE,
  MAP_TEXT,
  NOT_APPLICABLE,
  PLATFORM_ROUTE_LANDING,
  PLATFORM_TEXT,
  PrimeNgColors,
  PrimeNgIcons,
  ProductType,
  SHIPLIST_ROUTE,
  SHIPLIST_TEXT,
  SIGN_IN,
  SIGN_OUT,
  VERSION_TITLE,
  VersionInfo,
  VMS_ROUTE,
  VMS_TEXT,
} from '@estimator/models';
import { xlistRoutes } from '@xlist-models';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'estimator-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DrawerComponent {
  @Input()
  set isLogged(value: boolean) {
    this._isLogged = value;
    if (this._isLogged) {
      const index = this.navigationList.findIndex((link) => link.title === VERSION_TITLE);
      this.addOrDeleteNavLink(this._signOutLink, index >= 0 ? index : this.navigationList.length);
      this.addOrDeleteNavLink(this._signInLink);

      this.makeNavListEnable([SHIPLIST_TEXT, VMS_TEXT, PLATFORM_TEXT, FEEDBACK_TEXT]);
    } else {
      this.addOrDeleteNavLink(this._signInLink, 0);
      this.addOrDeleteNavLink(this._signOutLink);
      this.addOrDeleteNavLink(this._userEmailLink);
    }
  }
  get isLogged(): boolean {
    return this._isLogged;
  }
  @Input()
  set userEmail(email: string) {
    if (email) {
      this._userEmail = email;
      this._userEmailLink.label = `<div class="user-name">${this._userEmail}</div>`;
      this.addOrDeleteNavLink(this._userEmailLink, 0);
    }
  }
  get userEmail(): string {
    return this._userEmail;
  }
  @Input()
  set appVersion(version: VersionInfo) {
    this._appVersion = version;
    this._appVersionLink.label = `
      <div class="user-name">${VERSION_TITLE}: ${this._appVersion.version}</div>
      <div class="user-name">#${this._appVersion.revision} ${this._appVersion.buildDate}</div>
    `;
    this.addOrDeleteNavLink(this._appVersionLink, this.navigationList.length);
  }
  get appVersion(): VersionInfo {
    return this._appVersion;
  }
  // @Input() backgroundColor = DEFAULT_BACKGROUND;
  @Input() set isCompanySuperUser(isCompanySuperUser: boolean) {
    if (isCompanySuperUser) {
      const adminRoute = this.navigationList.find((list) => list.title === ADMIN_TEXT) as MenuItem;
      adminRoute.visible = true;
    }
    this._isCompanySuperUser = isCompanySuperUser;
  }
  get isCompanySuperUser(): boolean {
    return this._isCompanySuperUser;
  }
  @Input()
  set availableProducts(products: ProductType[]) {
    this._availableProducts = products;
    if (this._availableProducts.length >= 0) {
      if (!this._availableProducts.includes(ProductType.Bulk)) {
        this.addOrDeleteNavLink(this._estimatorLink);
      } else {
        this.addOrDeleteNavLink(this._estimatorLink, 3);
      }
      if (!this._availableProducts.includes(ProductType.Tanker)) {
        this.addOrDeleteNavLink(this._estimatorTankerLink);
      } else {
        this.addOrDeleteNavLink(this._estimatorTankerLink, 4);
      }
    }
  }

  get availableProducts(): ProductType[] {
    return this._availableProducts;
  }

  @Output() signOut = new EventEmitter<void>();
  @Output() signIn = new EventEmitter<void>();
  @Output() openFeedbackForm = new EventEmitter();
  PrimeNgColors = PrimeNgColors;
  PrimeNgIcons = PrimeNgIcons;
  SIGN_IN = SIGN_IN;
  SIGN_OUT = SIGN_OUT;
  private _isCompanySuperUser = false;
  private _isLogged = false;
  private _userEmail = '';
  private _appVersion: VersionInfo = { version: NOT_APPLICABLE };
  private _availableProducts: ProductType[] = [];
  private readonly _signOutLink: MenuItem = {
    title: SIGN_OUT,
    label: SIGN_OUT,
    icon: PrimeNgIcons.SIGN_OUT,
    command: () => this.onSignOut(),
  };
  private readonly _signInLink: MenuItem = {
    title: SIGN_IN,
    label: SIGN_IN,
    icon: PrimeNgIcons.SIGN_IN,
    command: () => this.onSignIn(),
  };
  private readonly _userEmailLink: MenuItem = {
    label: this.userEmail,
    icon: PrimeNgIcons.AT,
    disabled: true,
    escape: false,
  };
  private readonly _appVersionLink: MenuItem = {
    label: this.appVersion?.version || NOT_APPLICABLE,
    title: VERSION_TITLE,
    icon: PrimeNgIcons.WRENCH,
    disabled: true,
    escape: false,
  };
  private readonly _estimatorLink: MenuItem = {
    url: this.getUrl(ESTIMATOR_ROUTE + ESTIMATOR_ROUTE),
    target: '_self',
    title: ESTIMATOR_DRY_TEXT,
    label: ESTIMATOR_DRY_TEXT,
    icon: PrimeNgIcons.CALCULATOR,
    id: 'drawer_menu_xroute_dry',
  };
  private readonly _estimatorTankerLink: MenuItem = {
    url: this.getUrl(ESTIMATOR_ROUTE + ESTIMATOR_TANKER_ROUTE),
    target: '_self',
    title: ESTIMATOR_TANKER_TEXT,
    label: ESTIMATOR_TANKER_TEXT,
    icon: PrimeNgIcons.CALCULATOR,
    id: 'drawer_menu_xroute_tanker',
  };

  private readonly _estimatorVesselLink: MenuItem = {
    url: this.getUrl(ESTIMATOR_ROUTE + ESTIMATOR_VESSEL_ROUTE),
    target: '_self',
    title: ESTIMATOR_VESSEL_ROUTE_TEXT,
    label: ESTIMATOR_VESSEL_ROUTE_TEXT,
    icon: PrimeNgIcons.DATABASE,
    id: 'drawer_menu_xroute_vessel',
  };

  navigationList: MenuItem[] = [
    {
      url: this.getUrl(ESTIMATOR_ROUTE + ADMIN_ROUTE),
      target: '_self',
      title: ADMIN_TEXT,
      label: ADMIN_TEXT,
      icon: PrimeNgIcons.ENVELOPE,
      visible: false,
    },
    {
      url: this.getUrl(ESTIMATOR_ROUTE + DASHBOARD_ROUTE),
      target: '_self',
      title: MAP_TEXT,
      label: MAP_TEXT,
      icon: PrimeNgIcons.MAP,
    },
    this._estimatorLink,
    this._estimatorTankerLink,
    this._estimatorVesselLink,
    {
      url: this.getUrl(SHIPLIST_ROUTE + `/${xlistRoutes.FILTERS}`),
      title: SHIPLIST_TEXT,
      target: '_blank',
      label: SHIPLIST_TEXT,
      icon: PrimeNgIcons.LIST,
      id: 'drawer_menu_shiplist',
      disabled: true,
    },
    {
      url: this.getUrl(VMS_ROUTE),
      title: VMS_TEXT,
      target: '_blank',
      label: VMS_TEXT,
      icon: PrimeNgIcons.BRIEFCASE,
      id: 'drawer_menu_vms',
      disabled: true,
    },
    {
      url: this.getUrl(PLATFORM_ROUTE_LANDING),
      title: /* 'Trading' */ 'Coming soon',
      label: PLATFORM_TEXT,
      icon: PrimeNgIcons.CHART_LINE,
      styleClass: 'opacity-5',
      disabled: true,
    },
    {
      command: (/* event: MenuItemCommandEvent */) => {
        this.openFeedbackForm.emit();
      },
      title: FEEDBACK_TEXT,
      label: FEEDBACK_TEXT,
      icon: PrimeNgIcons.ENVELOPE,
      disabled: true,
    },
    {
      url: this.getUrl(HELP_ROUTE),
      target: '_self',
      title: 'Help',
      label: 'Help',
      icon: PrimeNgIcons.QUESTION_CIRCLE,
    },
  ];

  onSignOut(): void {
    this.signOut.emit();
  }

  onSignIn(): void {
    this.signIn.emit();
  }

  addOrDeleteNavLink(newLink: MenuItem, addIndex?: number): void {
    if (addIndex !== undefined && addIndex >= 0) {
      const isIncluded = this.navigationList.some((link) => link.label === newLink.label);
      if (!isIncluded) {
        this.navigationList.splice(addIndex, 0, newLink);
        return;
      }
    } else {
      const linkIndex = this.navigationList.findIndex((link) => link.title === newLink.title);
      if (linkIndex >= 0) {
        this.navigationList.splice(linkIndex, 1);
      }
    }
  }

  getUrl(url: string): string {
    return location.origin + url;
  }

  makeNavListEnable(labels: string[]): void {
    for (const label of labels) {
      const item = this.navigationList.find((nav) => nav.label === label);
      if (item) {
        item.disabled = false;
      }
    }
  }
}
