import { BaseEntity } from './base-entity';
import { SeasonType } from './seasons';
import { GrainCalculationType } from './ui-items';
import { WaterType } from './water';

export interface CargoCalculator extends BaseEntity {
  /**
   * Вес топлива на судне
   */
  bunker_weight?: number;
  /**
   * Постоянный вес на судне, включающий команду, запчасти и т.п.
   */
  constant_weight?: number;
  /**
   * Дни потребления топлива
   */
  consumption_days?: number;
  /**
   * Потребление топлива в сутки в тоннах
   */
  consumption_per_day?: number;
  /**
   * Ограничение по осадке в порту выгрузки
   */
  discharge_port_draft_restriction?: number;
  /**
   * Максимальная вместимость судна в тоннах
   */
  dwt?: number;
  /**
   * Вес чистой воды на судне
   */
  fresh_water?: number;
  /**
   * Объём помещений (bale)
   */
  bale_volume?: number;
  /**
   * Объём помещений (grain)
   */
  grain_volume?: number;
  /**
   * Объем помещений в футах (grain), используется только на UI
   */
  grain_volume_ft?: number;
  /**
   * Объем помещений в футах (bail), используется только на UI
   */
  bale_volume_ft?: number;
  /**
   * Ограничение по осадке в порту погрузки
   */
  load_port_draft_restriction?: number;
  /**
   * Ограничение по осадке в футах в порту погрузки
   */
  load_port_draft_restriction_ft?: number;
  /**
   * Футы или метры
   */
  load_port_draft_restriction_units_type?: LoadPortDraftRestrictionUnitsType
  /**
   * Осадка судна до погрузки
   */
  load_port_draft_start?: number;
  /**
   * Доступный для погрузки вес с учётом DWT
   */
  max_available_tonnage_quantity?: number;
  /**
   * Максимальный вес полезной нагрузки в порту выгрузки
   */
  max_discharge_port_draft_quantity?: number;
  /**
   * Доступный для погрузки вес
   */
  max_dwt_quantity?: number;
  /**
   * Доступный для порта выгрузки вес с учётом DWT
   */
  max_available_tonnage_discharge_quantity?: number;
  /**
   * Максимальный вес полезной нагрузки в порту погрузки
   */
  max_load_port_quantity?: number;
  /**
   * Максимально доступный объём груза (grain)
   */
  max_grain_volume_quantity?: number;
  /**
   * Максимально доступный объём груза (bale)
   */
  max_bale_volume_quantity?: number;
  /**
   * Максимально доступный объём груза
   */
  max_volume_quantity?: number;
  /**
   * Прочий вес
   */
  other_weight?: number;
  /**
   * Постоянный балласт на судне для устойчивости
   */
  permanent_ballast?: number;
  /**
   * Объём, занимаемый тонной груза
   */
  stowage_factor?: number;
  /**
   * Объём, занимаемый футом груза, используется только на UI
   */
  stowage_factor_ft?: number;
  /**
   * Футы или метры
   */
  stowage_factor_units_type?: StowageFactorUnitsType;
  /**
   * Сумма тонн потребленного топлива
   */
  total_consumption?: number;
  /**
   * Вес, необходимый для погружения на 1 сантиметр
   */
  tpc?: number;
  /**
   * Максимальная осадка судна при полном DWT
   */
  vessel_max_draft?: number;
  /**
   * Тип воды в порту выгрузки
   */
  discharge_port_water_type?: WaterType;
  /**
   * Тип воды в порту погрузки
   */
  load_port_water_type?: WaterType;
  /**
   * Сезон в порту выгрузки
   */
  discharge_port_season_type?: SeasonType;
  /**
   * Сезон воды в порту погрузки
   */
  load_port_season_type?: SeasonType;
  /**
   * Расходы
   */
  deductibles?: number | null;
  /**
   * grain/bale/both тип
   */
  grain_bale_calculation_type?: GrainCalculationType | null;
}

export enum StowageFactorUnitsType {
  StowageFactorCFT = 'cft',
  StowageFactorCBM = 'cbm',
}

export enum LoadPortDraftRestrictionUnitsType {
  Meters = 'm',
  Feets = 'ft',
}
