<mat-dialog-content class="map-wrapper">
  <mgl-map [zoom]="[4]" [center]="center" [style]="style">
    <mgl-geojson-source id="route" [data]="route"></mgl-geojson-source>
    <mgl-layer
      id="route"
      type="line"
      source="route"
      [layout]="{
        'line-join': 'round',
        'line-cap': 'round'
      }"
      [paint]="{
        'line-width': 3,
        'line-opacity': 0.666
      }"
    >
      <!--   <mgl-popup *ngIf="selectedEdge" [feature]="selectedEdge" className="swgt">
        <p *ngIf="selectedEdge.properties">
          Edge: {{ selectedEdge.properties[MapFeatureProperties.Edge] || NOT_APPLICABLE }}
        </p>
        <p *ngIf="selectedEdge.properties">
          Cost: {{ this.selectedEdge.properties[MapFeatureProperties.Cost] || NOT_APPLICABLE }}
        </p>
        <p *ngIf="this.selectedEdge.properties">
          Start:
          {{ this.selectedEdge.properties[MapFeatureProperties.StartPoint] || NOT_APPLICABLE }}
        </p>
        <p *ngIf="this.selectedEdge.properties">
          End: {{ this.selectedEdge.properties[MapFeatureProperties.EndPoint] || NOT_APPLICABLE }}
        </p>
        <p *ngIf="this.selectedEdge.properties">
          Seq: {{ this.selectedEdge.properties[MapFeatureProperties.Seq] || NOT_APPLICABLE }}
        </p>
      </mgl-popup> -->
    </mgl-layer>
  </mgl-map>
  <div class="dialog-panel">
    <div class="dialog-panel-row">
      <div class="approve-for-pointer pl-10">
        <mat-checkbox [checked]="useEca" (change)="onChangeEcaAvoid($event.checked)">
          Use ECA
        </mat-checkbox>
      </div>
      <button mat-icon-button mat-dialog-close class="approve-for-pointer">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="dialog-panel-row p-5 display-block">
      <mat-icon
        (click)="showTooltipMap()"
        class="approve-for-pointer cursor-pointer"
        [bdcWalkTriggerFor]="dialogMap11"
      >
        {{ 'question_mark' }}
      </mat-icon>
      <mat-chip-listbox selectable multiple class="approve-for-pointer chips-wrapper">
        <mat-chip
          *ngFor="let canal of canals"
          [value]="canal"
          #chip="matChip"
          [ngClass]="{
            'on-route': isCanalOnRoute(canal.id || 0),
            'on-forced': isCanalForcedOnRoute(canal),
            'on-restricted': isCanalRestricted(canal) && !isCanalForcedOnRoute(canal)
          }"
          (click)="onSelectCanal(chip)"
        >
          {{ canal.name }}
        </mat-chip>
      </mat-chip-listbox>
    </div>
  </div>
</mat-dialog-content>

<!-- user guide  -->
<bdc-walk-popup
  #dialogMap11
  name="dialogMap11"
  [mustCompleted]="{ dialogWelcome: true }"
  [showButton]="true"
  [buttonText]="OK_TEXT"
  [showCloseButton]="false"
  [arrow]="false"
  class="width-400"
>
  <div>
    <img src="assets/dialog-map-canals.png" alt="dialog-map-canals" class="dialog-map-canals" />
  </div>
</bdc-walk-popup>
