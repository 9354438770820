import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ChildActivationStart, Router } from '@angular/router';
import { BehaviorSubject, filter, Observable } from 'rxjs';

export const THEME_LIGHT_PAGES: string[] = [
  'contacts',
  'blog',
  'privacy-policy',
  'terms-and-conditions',
];
@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private currentTheme = new BehaviorSubject<string>('theme-dark');
  private sidebarVisible = new BehaviorSubject<boolean>(false);
  data = this.currentTheme.asObservable();

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly router: Router
  ) {
    this.router.events
      .pipe(filter((event) => event instanceof ChildActivationStart))
      .subscribe((res: any) => {
        if (res?.snapshot && THEME_LIGHT_PAGES.includes(res?.snapshot?.routeConfig?.path)) {
          this.updatedTheme('theme-light');
          this.document.body.classList.add('theme-light');
          return;
        }
        this.updatedTheme('theme-dark');
        this.document.body.classList.remove('theme-light');
      });
  }

  getTheme() {
    return this.data;
  }

  updatedTheme(theme: string) {
    this.currentTheme.next(theme);
  }

  toggleScroll() {
    return this.document.body.classList.toggle('disable-scroll');
  }

  toggleSidebar(): void {
    this.sidebarVisible.next(!this.sidebarVisible.getValue());
  }

  isSidebarVisible(): Observable<boolean> {
    return this.sidebarVisible;
  }
}
