import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from '@estimator/directives';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { UserDurationInputComponent } from './user-duration-input.component';

export const userDurationInputModuleImports = [
  CommonModule,
  DirectivesModule,
  DropdownModule,
  InputNumberModule,
  ReactiveFormsModule,
];

@NgModule({
  declarations: [UserDurationInputComponent],
  imports: userDurationInputModuleImports,
  exports: [UserDurationInputComponent],
})
export class UserDurationInputModule {}
