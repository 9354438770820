import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import {
  getDatePipeTimeZoneFormat,
  getEventTimezoneOffset,
  getLocalTimeZoneOffsetInHours,
  isLocalTimeSetting,
  isPrimaryEvent,
  localeDateFormatPrimeNGInput,
  pointExisted,
  userTimeZone,
} from '@estimator/helpers';
import {
  DATE_INPUT_TIME_FORMAT,
  DealEvent,
  EventFormGroup,
  NOT_APPLICABLE,
  Port,
  ROUTE_POINT_TEXT,
  UserTimeSettings,
} from '@estimator/models';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { Subject, takeUntil } from 'rxjs';
import { EventsFormComponent } from '../events-form/events-form.component';

@Component({
  selector: 'estimator-itinerary-date',
  templateUrl: './itinerary-date.component.html',
  styleUrls: ['./itinerary-date.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItineraryDateComponent implements OnDestroy {
  @Input()
  set events(data: DealEvent[]) {
    this.eventsFormArray.clear({ emitEvent: false });
    if (data.length) {
      data.forEach((event, index) => {
        if (isPrimaryEvent(event)) {
          const fg = EventsFormComponent.makeEventFormGroup(event);
          fg.disable();
          if (index === 0) {
            fg.controls.start_time.enable();
            fg.controls.start_time.valueChanges
              .pipe(takeUntil(this._onDestroy$))
              .subscribe((start) => {
                const result = moment.utc((start as Date).toString().split('GMT')[0]);
                if (isLocalTimeSetting(this.timeSettings)) {
                  if (this.timeSettings === UserTimeSettings.PortLocalTime) {
                    const offset = getEventTimezoneOffset(event);
                    result.add(-offset, 'hours');
                  } else {
                    result.add(-getLocalTimeZoneOffsetInHours(), 'hours');
                  }
                }
                if (result) {
                  this.updateDealStart.emit(result.toISOString().split('.')[0] + 'Z');
                }
              });
          }
          this.eventsFormArray.push(fg);
        }
      });
      this._events = cloneDeep(data);
      this.updateStartTime();
    }
  }
  get events(): DealEvent[] {
    return this._events;
  }
  @Input()
  set timeSettings(settings: UserTimeSettings) {
    this._timeSettings = settings;
    this.updateStartTime();
  }
  get timeSettings(): UserTimeSettings {
    return this._timeSettings;
  }

  @Output() updateDealStart = new EventEmitter<string>();

  eventsFormArray: FormArray<FormGroup<EventFormGroup>> = new FormArray<FormGroup<EventFormGroup>>(
    []
  );
  tabIndex = 300;
  dateFormat = localeDateFormatPrimeNGInput();
  readonly NOT_APPLICABLE = NOT_APPLICABLE;
  private _events: DealEvent[] = [];
  private _timeSettings = UserTimeSettings.PortLocalTime;
  private _onDestroy$ = new Subject<void>();

  get userTimeZone(): string {
    return userTimeZone();
  }

  constructor(private readonly datePipe: DatePipe) {}

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  updateStartTime(): void {
    this.eventsFormArray.controls.forEach((form) => {
      const event = form.getRawValue();
      const inputEvent = this.events.find((e) => e.id === event.id);
      if (inputEvent?.start_time) {
        const eventDate = moment.utc(inputEvent.start_time);
        const portOffset = getEventTimezoneOffset(inputEvent as DealEvent);
        const date = this.formatDateWithTimeZone(
          eventDate ? eventDate.utc().format() : '',
          portOffset
        );
        if (date) {
          form.controls.start_time.patchValue(new Date(date), { emitEvent: false });
        }
      }
      if (inputEvent?.end_time) {
        const eventDate = moment.utc(inputEvent.end_time);
        const portOffset = getEventTimezoneOffset(inputEvent as DealEvent);
        const date = this.formatDateWithTimeZone(
          eventDate ? eventDate.utc().format() : '',
          portOffset
        );
        if (date) {
          form.controls.end_time.patchValue(new Date(date), { emitEvent: false });
        }
      }
    });
  }

  formatDateWithTimeZone(date: string, timeZoneOffset?: number): string {
    return (
      this.datePipe.transform(
        moment.utc(date).toISOString(),
        DATE_INPUT_TIME_FORMAT,
        getDatePipeTimeZoneFormat(this.timeSettings, timeZoneOffset)
      ) || ''
    );
  }

  addTimeSettings() {
    const userTimeZone = this.timeSettings === UserTimeSettings.LocalTime ? this.userTimeZone : '';
    return this.timeSettings + userTimeZone;
  }

  getPortTitle(eventForm: FormGroup<EventFormGroup>) {
    const portValue = eventForm.controls?.port?.value as Port;
    return portValue?.name
      ? portValue?.name
      : pointExisted(eventForm)
      ? ROUTE_POINT_TEXT +
        eventForm?.controls?.point?.value?.latitude?.toFixed(2) +
        '/' +
        eventForm?.controls?.point?.value?.longitude?.toFixed(2)
      : NOT_APPLICABLE;
  }
}
