import { ColumnState } from 'ag-grid-community';

export class GetAllTablesState {
  static readonly type = '[TABLES] Get all tables state';
}

export class SetTableState {
  static readonly type = '[TABLES] Set table state';
  constructor(public tableKey: string, public tableState: ColumnState[]) {}
}

export class ResetTableState {
  static readonly type = '[TABLES] Reset table state';
  constructor(public tableKey: string) {}
}
