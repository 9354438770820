import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { DateEditorModule } from '../ag-grid-shared/ag-grid-date-editor.module';
import { VesselWidgetComponent } from './vessel-widget.component';

@NgModule({
  declarations: [VesselWidgetComponent],
  imports: [CommonModule, MatIconModule, MatButtonModule, MatDialogModule, DateEditorModule],
  exports: [VesselWidgetComponent],
})
export class VesselWidgetModule {}
