import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TabViewModule } from 'primeng/tabview';
import { FleetVesselCollectionModule } from '../fleet-vessel-collection/fleet-vessel-collection.module';
import { FleetVesselCollectionsComponent } from './fleet-vessel-collections.component';

@NgModule({
  declarations: [FleetVesselCollectionsComponent],
  imports: [CommonModule, TabViewModule, FleetVesselCollectionModule],
  exports: [FleetVesselCollectionsComponent],
})
export class FleetVesselCollectionsModule {}
