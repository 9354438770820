import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  CopyFolderDialogConfig,
  EIGHTY_NUMBER,
  Folder,
  NotificationType,
  THREE_NUMBER,
  WarningNotifications,
} from '@estimator/models';
import { NotificationService } from '@estimator/services';
import { isEqual } from 'lodash';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Subject, filter, map, pairwise, startWith, takeUntil } from 'rxjs';

@Component({
  selector: 'estimator-copy-folder-dialog',
  templateUrl: './copy-folder-dialog.component.html',
  styleUrls: ['./copy-folder-dialog.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CopyFolderDialogComponent implements OnDestroy {
  @Output() copyFolder = new EventEmitter<string>();
  @Output() searchFolder = new EventEmitter<string>();
  folderControl = new FormControl();
  @Input() filteredFolders: Folder[] = [];
  @Input() label = 'Copy';
  /**
   * use in estimator-fix-folder
   */
  @Input() disabledBtn = false;
  @Input()
  set folderName(value: string) {
    this._folderName = value;
    if (value) {
      this.folderControl.patchValue(value);
    }
  }
  get folderName(): string {
    return this._folderName;
  }
  searchValue = '';
  isLoadingSearch = false;
  private _onDestroy$ = new Subject<void>();
  private _minValueLength = THREE_NUMBER;
  private _maxValueLength = EIGHTY_NUMBER;
  private _folderName = '';

  get placeholderFolder(): string {
    return `Folder name must be at least ${this._minValueLength} characters`;
  }

  constructor(
    private readonly dialogConfig: DynamicDialogConfig<CopyFolderDialogConfig>,
    private readonly cdr: ChangeDetectorRef,
    private readonly notificationService: NotificationService
  ) {
    if (this.dialogConfig.data) {
      const { copyFolder, searchFolder, foundFolders, oldFolderName } = this.dialogConfig.data;
      if (copyFolder) {
        this.copyFolder = copyFolder;
      }
      if (searchFolder) {
        this.searchFolder = searchFolder;
      }
      if (oldFolderName) {
        this.folderName = oldFolderName.replace(/^(fix|Fix)\s*/, '');
      }
      foundFolders?.pipe(takeUntil(this._onDestroy$)).subscribe((folders) => {
        this.isLoadingSearch = false;
        this.filteredFolders = folders.filter(
          (folder) => folder.name.toLowerCase() === this.searchValue.toLowerCase()
        );
        this.cdr.detectChanges();
      });

      this.folderControl.valueChanges
        .pipe(
          takeUntil(this._onDestroy$),
          startWith(this.folderControl.value),
          pairwise(),
          filter(([prev, curr]) => {
            return !isEqual(curr, prev);
          }),
          map(([prev, curr]) => curr)
        )
        .subscribe((value: string) => {
          if (
            value &&
            value?.length >= this._minValueLength &&
            value?.length <= this._maxValueLength
          ) {
            this.searchValue = value;
            this.isLoadingSearch = true;
            this.searchFolder.emit(value);
          } else {
            this.filteredFolders = [];
          }
          if (value?.length > this._maxValueLength) {
            this.notificationService.showNotification(
              WarningNotifications.MaxLimitSearchReach,
              NotificationType.Warning
            );
          }
        });
    }
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
    this.searchValue = '';
  }

  /*   onDealSearchFolder(event: AutoCompleteCompleteEvent): void {
    if (event.query) {
      this.searchValue = event.query;
      this.searchFolder.emit(event.query);
    }
  } */

  onCopyFolder(): void {
    this.copyFolder.emit(this.folderControl.value);
  }

  disabledButton(): boolean {
    return this.folderControl?.value?.length
      ? this.folderControl?.value?.length < this._minValueLength || this.filteredFolders.length > 0
      : true;
  }
}
