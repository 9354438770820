import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { InputNumberModule } from 'primeng/inputnumber';
import { TableModule } from 'primeng/table';
import { DeductiblesListComponent } from './deductibles-list.component';

export const DeductiblesListModuleImports = [
  CommonModule,
  TableModule,
  ReactiveFormsModule,
  InputNumberModule,
];

@NgModule({
  declarations: [DeductiblesListComponent],
  imports: DeductiblesListModuleImports,
  exports: [DeductiblesListComponent],
})
export class DeductiblesListModule {}
