import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ZeroNullValidator, changedKeys } from '@estimator/helpers';
import {
  consumptionRatesMock,
  ecoPreset1Mock,
  ecoPreset2Mock,
  ecoPreset3Mock,
  fuelsMock,
  fullPresetMock,
} from '@estimator/mocks';
import {
  BallastBonusTypes,
  CargoHeatingTypes,
  ChangePreset,
  ChangeValidation,
  Company,
  CompanySetting,
  ConsumptionRate,
  ConsumptionRateFormGroup,
  Currency,
  DEFAULT_AUTOSAVE,
  DEFAULT_TC_COMM,
  DealFormHasChanges,
  FuelConsumption,
  FuelTypes,
  HireFinance,
  MAX_FINANCE_VALUE,
  MAX_VALUE,
  NOT_APPLICABLE,
  Preset,
  PresetFormGroup,
  PresetNames,
  PrimeNgColors,
  PrimeNgIcons,
  SaveXBPIData,
  TEXT_ZERO,
  THREE_NUMBER,
  UseRate,
  UseRateFormGroup,
  Vessel,
  VesselConstantsFormGroup,
  VesselForm,
  VesselFormUpdateDto,
  falseText,
  scrubberFuels,
  TonnageMetrics,
  TonnageMetric,
  TonnageMetricsTypes,
  TonnageControlTypes,
} from '@estimator/models';
import { cloneDeep, isEqual, isObjectLike, size } from 'lodash';
import {
  AutoComplete,
  AutoCompleteCompleteEvent,
  AutoCompleteSelectEvent,
} from 'primeng/autocomplete';
import { CheckboxChangeEvent } from 'primeng/checkbox';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { OverlayPanel } from 'primeng/overlaypanel';
import {
  Subject,
  Subscription,
  debounceTime,
  filter,
  map,
  pairwise,
  startWith,
  takeUntil,
} from 'rxjs';

@Component({
  selector: 'estimator-vessel-and-consumptions-form',
  templateUrl: './vessel-and-consumptions-form.component.html',
  styleUrls: ['./vessel-and-consumptions-form.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VesselAndConsumptionsFormComponent implements OnInit, OnDestroy {
  @ViewChild('vesselAutoComplete', { read: ElementRef }) vesselAutoComplete?: ElementRef;
  @ViewChild('vesselAutoComplete')
  vesselAutoCompleteComp?: AutoComplete;
  @ViewChild('vesselFuelForm') vesselFuelForm?: ElementRef;
  @ViewChild('hireRate', { read: ElementRef }) hireRate?: ElementRef;
  @ViewChild('overlay') overlay?: OverlayPanel;

  @Input()
  set vessels(data: Partial<Vessel>[]) {
    this._vessels = data;
  }

  get vessels(): Partial<Vessel>[] {
    return this._vessels;
  }

  @Input()
  set currentVessel(vessel: Vessel | null) {
    if (vessel && size(vessel)) {
      const oldId = this._currentVessel?.id;
      this._currentVessel = cloneDeep(vessel);
      this._inputVessel = cloneDeep(vessel);
      this.setDefaultPresets(oldId !== vessel.id);
      if (!vessel.is_raw) {
        this.vesselFormGroup.controls.is_raw.patchValue(false, { emitEvent: false });
      }
      this.vesselFormGroup.patchValue(this._currentVessel, { emitEvent: false });
      if (this.vesselFormGroup.value.scrubber) {
        this.changeValidationErrors.emit({
          field: `${FuelTypes.VLSFO}-${this.dealId}`,
          remove: true,
        });
      }
      this._defaultVesselValue = this.vesselFormGroup.value;
    } else {
      this.isFormHasChanges = false;
      this._currentVessel = {};
      this.vesselFormGroup.reset({}, { emitEvent: false });
      this.hireFinanceFormGroup.reset({}, { emitEvent: false });
      this.setValidatorsToTCComm();
      this.fullPresetFormGroup.controls.ballast_speed?.reset(null, { emitEvent: false });
      this.fullPresetFormGroup.controls.laden_speed?.reset(null, { emitEvent: false });
      const consumptionRates = this.fullPresetFormGroup.controls.consumption_rates;
      if (consumptionRates) {
        consumptionRates?.controls?.forEach((control) => {
          control.controls.main_engine_rate?.reset(this.generateUseRateFormGroup().value, {
            emitEvent: false,
          });
          control.controls.aux_engine_rate?.reset(this.generateUseRateFormGroup().value, {
            emitEvent: false,
          });
          control?.controls.price?.reset(null, { emitEvent: false });
        });
      }
      this.ecoPresetFormGroup.controls.ballast_speed?.reset(null, { emitEvent: false });
      this.ecoPresetFormGroup.controls.laden_speed?.reset(null, { emitEvent: false });
      const ecoConsumptionRates = this.ecoPresetFormGroup.controls.consumption_rates;
      if (ecoConsumptionRates) {
        ecoConsumptionRates?.controls?.forEach((control) => {
          control.controls.main_engine_rate?.reset(this.generateUseRateFormGroup().value, {
            emitEvent: false,
          });
          control.controls.aux_engine_rate?.reset(this.generateUseRateFormGroup().value, {
            emitEvent: false,
          });
          control?.controls.price?.reset(null, { emitEvent: false });
        });
      }
      this.setDefaultPresets(true);
    }
  }

  get currentVessel(): Vessel | null {
    return (this._currentVessel as Vessel) || null;
  }

  @Input() set hireFinances(finances: HireFinance) {
    if (finances && size(finances)) {
      this._hireFinances = cloneDeep(finances);
      /* if (this._hireFinances.hire_per_day) {
        this._hireFinances.hire_per_day /=
          this.financeCurrency?.fiat_multiplier || DEFAULT_FIAT_MULTIPLIER;
      } */
      /* if (this._hireFinances.ballast_bonus) {
        this._hireFinances.ballast_bonus /=
          this.financeCurrency?.fiat_multiplier || DEFAULT_FIAT_MULTIPLIER;
      } */
      /* if (!this._hireFinances.time_charter_commission) {
        this._hireFinances.time_charter_commission = 3.75;
      } */
      this.hireFinanceFormGroup.reset({}, { emitEvent: false });
      this.hireFinanceFormGroup.reset(this._hireFinances, { emitEvent: false });
      this.setValidatorsToTCComm();
      if (!this._hireFinances.commission_affects_bb) {
        this.hireFinanceFormGroup.controls.commission_affects_bb.patchValue(false, {
          emitEvent: false,
        });
      }
    } else {
      this.hireFinanceFormGroup.reset({}, { emitEvent: false });
      this.setValidatorsToTCComm();
    }
  }

  get hireFinances(): HireFinance {
    return this._hireFinances;
  }

  @Input() set currencies(data: Currency[]) {
    this._currencies = data;
  }

  get currencies(): Currency[] {
    return this._currencies;
  }

  @Input() set fuelConsumptions(data: FuelConsumption[]) {
    if (data && data?.length) {
      this._fuelConsumptions = data;
      this.fuelConsumptions?.forEach((rate) => {
        const fuelName = rate.fuel?.name;
        if (fuelName) {
          switch (fuelName) {
            case FuelTypes.ULSFO:
              this.fuelPriceFormGroup.controls[FuelTypes.ULSFO].patchValue(rate.price || null, {
                emitEvent: false,
              });
              break;
            case FuelTypes.VLSFO:
              this.fuelPriceFormGroup.controls[FuelTypes.VLSFO].patchValue(rate.price || null, {
                emitEvent: false,
              });
              break;
            case FuelTypes.LSMGO:
              this.fuelPriceFormGroup.controls[FuelTypes.LSMGO].patchValue(rate.price || null, {
                emitEvent: false,
              });
              break;
            case FuelTypes.HSFO:
              this.fuelPriceFormGroup.controls[FuelTypes.HSFO].patchValue(rate.price || null, {
                emitEvent: false,
              });
              break;
            default:
              break;
          }
        }
      });
    } else {
      this.fuelPriceFormGroup.reset(
        {
          [FuelTypes.VLSFO]: null,
          [FuelTypes.ULSFO]: null,
          [FuelTypes.LSMGO]: null,
          [FuelTypes.HSFO]: null,
        },
        { emitEvent: false }
      );
    }
  }

  get fuelConsumptions(): FuelConsumption[] {
    return this._fuelConsumptions;
  }

  @Input() set selectedPresetNamesInEvents(data: PresetNames[]) {
    this._selectedPresetNamesInEvents = data;
    this.workWithValidationErrors(this.fullPresetFormGroup, PresetNames.Full);
    this.workWithValidationErrors(
      this.ecoPresetFormGroup,
      this.ecoPresetFormGroup.value.name as PresetNames
    );
  }

  get selectedPresetNamesInEvents(): PresetNames[] {
    return this._selectedPresetNamesInEvents;
  }

  @Input() set co2Price(co2Price: number) {
    this._co2Price = co2Price;
    this.fgCO2 = this.makeCO2FormGroup();
    this.fgCO2.valueChanges
      .pipe(takeUntil(this._onDestroy$), debounceTime(500))
      .subscribe((res) => {
        this.hasChanges.emit({ change: true, estimate: true });
      });
  }

  get co2Price(): number {
    return this._co2Price;
  }

  @Input()
  set isFixed(value: boolean) {
    this._isFixed = value;
    if (value) {
      const formsToDisable = [
        this.fullPresetFormGroup,
        this.ecoPresetFormGroup,
        this.vesselFormGroup,
        this.hireFinanceFormGroup,
        this.fuelPriceFormGroup,
        this.fgCO2,
      ];
      formsToDisable.forEach((form) => {
        form.disable();
      });
    }
  }

  get isFixed(): boolean {
    return this._isFixed;
  }

  @Input()
  set cargoHeating(value: boolean) {
    this._cargoHeating = value;
  }

  get cargoHeating(): boolean {
    return this._cargoHeating;
  }

  get mockFormControl(): FormControl {
    this.mockFormControl.disable();
    return this._mockFormControl;
  }

  @Input() isLoading = false;
  // @Input() financeCurrency?: Currency;
  @Input() dealName = '';
  @Input() useUlsfo = false;
  @Input() isTanker = false;
  @Input() defaultAutoSave = DEFAULT_AUTOSAVE;
  @Input() isFuelConsParser = false;
  @Input() isXBPIFuelAccess = false;
  @Input() dealId = 0;
  @Input() presets: Preset[] = [];
  // @Input() selectedPresetNamesInEvents: PresetNames[] = [];
  @Input() deductibles: CompanySetting[] = [];
  @Input() highPurityFuelNameFromFirstEvent = FuelTypes.LSMGO;

  @Input()
  set selectedTonnage(data: TonnageMetricsTypes) {
    if (data) {
      this.selectedTonnageType = data;
      switch (data) {
        case TonnageMetricsTypes.BL:
          this.selectedTonnageControlKey = TonnageControlTypes.BL;
          break;
        case TonnageMetricsTypes.GT:
          this.selectedTonnageControlKey = TonnageControlTypes.GT;
          break;
        default:
          this.selectedTonnageControlKey = TonnageControlTypes.GR;
      }
    }
  }

  @Output() searchVessel = new EventEmitter<string>();
  @Output() searchDeepVessel = new EventEmitter<string>();
  @Output() requestUpdateVesselFromBase = new EventEmitter<number>();
  @Output() saveVesselToBase = new EventEmitter<Partial<Vessel>>();
  @Output() hasChanges = new EventEmitter<DealFormHasChanges>();
  @Output() changePreset = new EventEmitter<Preset[]>();
  @Output() setPresetToEvents = new EventEmitter<Preset>();
  @Output() changeUseULSFO = new EventEmitter<boolean>();
  @Output() changeScrubber = new EventEmitter<boolean>();
  @Output() changeValidationErrors = new EventEmitter<ChangeValidation>();
  @Output() changecargoHeatingRateSwitcher = new EventEmitter<boolean>();
  @Output() openXBPIModal = new EventEmitter();
  @Output() highPurityFuelNameChange = new EventEmitter<string>();
  @Output() tonnageMetricsChange = new EventEmitter<TonnageMetricsTypes>();
  consumptionRatesMock = consumptionRatesMock;
  fullPreset = fullPresetMock;
  ecoPreset1 = ecoPreset1Mock;
  ecoPreset2 = ecoPreset2Mock;
  ecoPreset3 = ecoPreset3Mock;
  fullPresetFormGroup = this.generatePresetFormGroup(this.fullPreset);
  ecoPresetFormGroup = this.generatePresetFormGroup(this.ecoPreset1);
  vesselFormGroup: FormGroup<VesselForm> = new FormGroup<VesselForm>({
    alpha_2: new FormControl<string>('', { nonNullable: true }),
    id: new FormControl<number>(0, { nonNullable: true }),
    created_at: new FormControl<string | Date | null>(null),
    created_by: new FormControl<number | null>(null),
    updated_at: new FormControl<string | Date | null>(null),
    updated_by: new FormControl<number | null>(null),
    deleted_at: new FormControl<string | Date | null>(null),
    deleted_by: new FormControl<number | null>(null),
    // ballast_speed: new FormControl<number | null>(null),
    beam: new FormControl<number | null>(null),
    build_year: new FormControl<number | null>(null),
    builder: new FormControl<string>(''),
    call_sign: new FormControl<string>(''),
    classification_society: new FormControl<string>(''),
    company: new FormControl<Company | null>({}),
    company_id: new FormControl<number | null>(null),
    consumption_rates: new FormControl<ConsumptionRate[]>([] as ConsumptionRate[], {
      nonNullable: true,
    }),
    dead_weight: new FormControl<number | null>(null),
    draught: new FormControl<number | null>(null),
    flag: new FormControl<string>(''),
    general_vessel_id: new FormControl<number | null>(null),
    // gross_registered_tonnage: new FormControl<number | null>(null),
    bale: new FormControl<number>(0, { nonNullable: true }),
    grain: new FormControl<number>(0, { nonNullable: true }),
    gross_tonnage: new FormControl<number | null>(null),
    imo: new FormControl<number | null>(null),
    is_raw: new FormControl<boolean>(false),
    // laden_speed: new FormControl<number | null>(null),
    length: new FormControl<number | null>(null),
    // low_speed: new FormControl<number | null>(null),
    manager: new FormControl<string>(''),
    mmsi: new FormControl<number | null>(null),
    name: new FormControl<string>('', { nonNullable: true }),
    owner: new FormControl<string>(''),
    place_of_built: new FormControl<string>(''),
    teu: new FormControl<number | null>(null),
    tpc: new FormControl<number | null>(null),
    gross_registered_tonnage: new FormControl<number | null>(null),
    type: new FormControl<string>(''),
    yard: new FormControl<string>(''),
    scrubber: new FormControl(false, { nonNullable: true }),
    constants: new FormGroup({
      deductibles: new FormControl<number | null>(null),
      bunker_weight: new FormControl<number | null>(null),
      constant_weight: new FormControl<number | null>(null),
      fresh_water: new FormControl<number | null>(null),
      other_weight: new FormControl<number | null>(null),
      permanent_ballast: new FormControl<number | null>(null),
    }),
  });
  hireFinanceFormGroup = new FormGroup({
    id: new FormControl<number>(0, { nonNullable: true, validators: [Validators.required] }),
    created_at: new FormControl<string | Date | null>(null),
    created_by: new FormControl<number | null>(null),
    updated_at: new FormControl<string | Date | null>(null),
    updated_by: new FormControl<number | null>(null),
    deleted_at: new FormControl<string | Date | null>(null),
    deleted_by: new FormControl<number | null>(null),
    address_commission: new FormControl(),
    // additional_minutes: new FormControl(),
    broker_commission: new FormControl(),
    hire_minutes: new FormControl(),
    hire_per_day: new FormControl(),
    extra_idle_minutes: new FormControl(),
    extra_sailing_laden_minutes: new FormControl(),
    time_charter_commission: new FormControl(DEFAULT_TC_COMM), // с бэка приходит дефолтное, сюда не заходит, так как patchValue есть
    ballast_bonus: new FormControl(),
    commission_affects_bb: new FormControl(true),
    currency: new FormControl(),
  });
  fuelPriceFormGroup = new FormGroup({
    [FuelTypes.VLSFO]: new FormControl<number | null>(null),
    [FuelTypes.ULSFO]: new FormControl<number | null>(null),
    [FuelTypes.LSMGO]: new FormControl<number | null>(null),
    [FuelTypes.HSFO]: new FormControl<number | null>(null),
  });
  fgCO2: FormGroup<{ co2_price: FormControl<number> }> = this.makeCO2FormGroup();
  isFormHasChanges = false;
  prevEditedVesselValue?: Partial<Vessel>;
  currEditedVesselValue?: Partial<Vessel>;
  valueForDeepSearch = '';
  visibleFuels: string[] = [];
  isSaveDeductiblesClicked = false;
  currentDeductibles = new Map<keyof VesselConstantsFormGroup, number>();
  totalDeductiblesValue = 0;
  selectedTonnageType = TonnageMetricsTypes.GR;
  selectedTonnageControlKey: keyof VesselForm = TonnageControlTypes.GR;
  readonly tonnageMetrics = TonnageMetrics;
  /*   isFirstElement = isFirstElement;
  isFirstOrSecondOrThirdElement = isFirstOrSecondOrThirdElement; */
  readonly PrimeNgColors = PrimeNgColors;
  readonly PrimeNgIcons = PrimeNgIcons;
  readonly NOT_APPLICABLE = NOT_APPLICABLE;
  readonly FuelTypes = FuelTypes;
  readonly ballastBonusTypes = BallastBonusTypes; /* [
    { value: true, label: BallastBonusTypes.GROSS },
    { value: false, label: BallastBonusTypes.NET },
  ]; */
  readonly scrubberFuels = scrubberFuels;
  readonly tabIndex = 50;
  readonly tabIndexIncrease = 51;
  readonly MAX_VALUE = MAX_VALUE;
  readonly MAX_FINANCE_VALUE = MAX_FINANCE_VALUE;
  readonly cargoHeatingTypes = CargoHeatingTypes;
  private _vessels: Partial<Vessel>[] = [];
  private _currentVessel?: Partial<Vessel>;
  private _hireFinances: HireFinance = {};
  private _currencies: Currency[] = [];
  private _selectedPresetNamesInEvents: PresetNames[] = [];
  private _inputVessel?: Vessel;
  private _onDestroy$ = new Subject<void>();
  private _defaultVesselValue = this.vesselFormGroup.value;
  private _defaultEcoPresetValue = this.ecoPresetFormGroup.value;
  private _fuelConsumptions: FuelConsumption[] = [];
  private _fullPresetSubscription?: Subscription;
  private _co2Price = 0;
  private _isFixed = false;
  private _cargoHeating = false;
  private _mockFormControl = new FormControl();

  get ecoPresets(): Preset[] {
    if (this.currentVessel?.presets?.length) {
      return this.currentVessel.presets.filter((preset) => preset.name !== PresetNames.Full);
    }
    return [this.ecoPreset1, this.ecoPreset2, this.ecoPreset3];
  }

  get fuelPriceControls(): string[] {
    return Object.keys(this.fuelPriceFormGroup.controls);
  }

  get isSaveVesselDisabled(): boolean {
    return (
      this.vesselFormGroup.invalid ||
      this.isLoading ||
      !this.isFormHasChanges ||
      !this.vesselFormGroup.controls.name.value ||
      !this.vesselFormGroup.controls.id.value ||
      this.isFixed
    );
  }

  constructor(public readonly cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.vesselFormGroup.valueChanges
      .pipe(
        debounceTime(/* this.defaultAutoSave */ 100),
        takeUntil(this._onDestroy$),
        startWith(this.vesselFormGroup.value),
        pairwise(),
        filter(([prev, curr]) => {
          this.prevEditedVesselValue = prev as Partial<Vessel>;
          this.currEditedVesselValue = curr as Partial<Vessel>;
          return !isEqual(curr, prev) && ((curr.id && !!curr.name) || isObjectLike(curr.name));
        }),
        map(([prev, curr]) => {
          console.log('vesselFormGroup emit changes', changedKeys(prev, curr), curr);
          return curr;
        })
      )
      .subscribe((res) => {
        if (res) {
          /*  if (res.is_raw) {
            this.vesselFormGroup?.controls?.is_raw?.setValue(false, { emitEvent: false });
          } */
          this.isFormHasChanges = true;
          const vesselIsSelected = isObjectLike(this.currEditedVesselValue?.name);
          const changeDeductibles =
            this.prevEditedVesselValue?.constants?.deductibles !==
            this.currEditedVesselValue?.constants?.deductibles;
          // !this.overlay?.overlayVisible = панель с разбивкой deductibles не открыта
          if (!this.overlay?.overlayVisible) {
            this.hasChanges.emit({
              change: true,
              estimate: vesselIsSelected || changeDeductibles,
              changeDeductibles: this.currEditedVesselValue?.constants,
            });
          } else {
            this.hasChanges.emit({
              change: true,
              estimate: vesselIsSelected || changeDeductibles,
            });
          }
        }
      });
    this.ecoPresetFormGroup.valueChanges
      .pipe(
        debounceTime(/* this.defaultAutoSave */ 100),
        takeUntil(this._onDestroy$),
        startWith(this.ecoPreset1),
        pairwise(),
        filter(([prev, curr]) => {
          return !isEqual(curr, prev) && prev?.name === curr?.name;
        }),
        map(([prev, curr]) => {
          console.log('eco preset emit change', changedKeys(prev, curr), curr);
          return curr;
        })
      )
      .subscribe((res) => {
        if (res) {
          this.isFormHasChanges = true;
          this.onChangePreset();
          this.hasChanges.emit({ change: true, estimate: true });
        }
      });
    this.hireFinanceFormGroup.valueChanges
      .pipe(
        debounceTime(/* this.defaultAutoSave */ 100),
        takeUntil(this._onDestroy$),
        startWith(this.hireFinanceFormGroup.value),
        pairwise(),
        filter(([prev, curr]) => {
          return !isEqual(curr, prev);
        }),
        map(([prev, curr]) => {
          console.log('hire finance in vessel emit change', changedKeys(prev, curr), curr);
          return curr;
        })
      )
      .subscribe((res) => {
        if (
          res &&
          (this.hireFinances?.hire_per_day !== res?.hire_per_day ||
            this.hireFinances?.time_charter_commission !== res?.time_charter_commission ||
            this.hireFinances?.ballast_bonus !== res?.ballast_bonus ||
            this.hireFinances?.commission_affects_bb !== res?.commission_affects_bb)
        ) {
          this.hasChanges.emit({ change: true, estimateFinances: true });
        }
      });
    this.fuelPriceFormGroup.valueChanges
      .pipe(
        debounceTime(/* this.defaultAutoSave */ 100),
        takeUntil(this._onDestroy$),
        startWith(this.fuelPriceFormGroup.value),
        pairwise(),
        filter(([prev, curr]) => {
          console.log('fuel price emit change', changedKeys(prev, curr), curr);
          return !isEqual(curr, prev);
        }),
        map(([prev, curr]) => {
          return curr;
        })
      )
      .subscribe((res) => {
        if (res) {
          this.hasChanges.emit({ change: true, estimateFinances: true });
        }
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  selectTonnageMetric(metric: TonnageMetric): void {
    this.selectedTonnageType = metric.value;
    this.selectedTonnageControlKey = metric.control;
    this.tonnageMetricsChange.emit(metric.value);
  }

  getTonnageControl(): FormControl {
    return this.vesselFormGroup.controls[this.selectedTonnageControlKey] as FormControl;
  }

  getConsumptionsRatesArray(
    formGroup: FormGroup<PresetFormGroup>
  ): FormArray<FormGroup<ConsumptionRateFormGroup>> {
    return formGroup.controls.consumption_rates;
  }

  generateUseRateFormGroup(useRate?: UseRate): FormGroup<UseRateFormGroup> {
    return new FormGroup({
      ballast: new FormControl(useRate?.ballast || null),
      idle: new FormControl(useRate?.idle || null),
      laden: new FormControl(useRate?.laden || null),
      work_on_loading: new FormControl(useRate?.work_on_loading || null),
      work_on_discharge: new FormControl(useRate?.work_on_discharge || null),
    });
  }

  generateConsumptionRateFormGroup(
    consumptionRate?: ConsumptionRate
  ): FormGroup<ConsumptionRateFormGroup> {
    return new FormGroup<ConsumptionRateFormGroup>({
      aux_engine_rate: this.generateUseRateFormGroup(consumptionRate?.aux_engine_rate),
      fuel: new FormControl(consumptionRate?.fuel || null),
      fuel_id: new FormControl(consumptionRate?.fuel_id || 0),
      main_engine_rate: this.generateUseRateFormGroup(consumptionRate?.main_engine_rate),
      price: new FormControl(consumptionRate?.price || null),
      currency: new FormControl(consumptionRate?.currency || null),
      cargo_heating_rate: new FormControl(consumptionRate?.cargo_heating_rate || null),
    });
  }

  generateConsumptionsFormArray(
    rates?: ConsumptionRate[]
  ): FormArray<FormGroup<ConsumptionRateFormGroup>> {
    const formArray = new FormArray<FormGroup<ConsumptionRateFormGroup>>([]);
    rates?.forEach((rate) => {
      formArray.push(this.generateConsumptionRateFormGroup(rate));
    });
    return formArray;
  }

  generatePresetFormGroup(preset?: Preset): FormGroup<PresetFormGroup> {
    const fg = new FormGroup<PresetFormGroup>({
      name: new FormControl<string>(preset?.name || '', { nonNullable: true }),
      laden_speed: new FormControl<number | null>(preset?.laden_speed || null),
      ballast_speed: new FormControl<number | null>(preset?.ballast_speed || null),
      consumption_rates: this.generateConsumptionsFormArray(preset?.consumption_rates),
    });
    if (preset?.name === PresetNames.Full) {
      fg.controls.name.disable({ emitEvent: false });
    }
    return fg;
  }

  onSearchVessel(value: AutoCompleteCompleteEvent): void {
    if (value.query?.length >= THREE_NUMBER) {
      this.vesselFormGroup.controls.is_raw.patchValue(true, { emitEvent: false });
      this.searchVessel.emit(value.query);
    }
  }

  onSelectVessel(event: AutoCompleteSelectEvent): void {
    const vessel = event.value as Vessel;
    vessel.presets?.forEach((preset) => {
      preset.consumption_rates?.forEach((rate) => {
        delete rate.price;
        delete rate.currency;
      });
    });
    this._currentVessel = vessel;
    this.vesselFormGroup.patchValue(vessel, { emitEvent: false });
    this.setDefaultDeductibles();
    if (!vessel.is_raw) {
      this.vesselFormGroup.controls.is_raw.patchValue(false, { emitEvent: false });
    }
    this._defaultVesselValue = this.vesselFormGroup.value;
    this.isFormHasChanges = false;
    this.setDefaultPresets(true);
    /* if (this.fastConsumptions && this.fullPresetFormGroup?.value?.consumption_rates) {
      this.fullPresetFormGroup?.controls?.consumption_rates?.controls?.forEach((rate) => {
        const needfastConsumption = this.fastConsumptions.find(
          (cons) => cons?.fuel?.id === rate?.value.fuel?.id
        );
        if (rate?.value.fuel?.id === needfastConsumption?.fuel?.id) {
          rate.controls.currency.setValue(needfastConsumption?.currency as Currency);
          rate.controls.price.setValue(needfastConsumption?.price as number);
        }
      });
      this.cdr.detectChanges();
    } */
    const scrubberValue = this.vesselFormGroup.controls.scrubber?.value;
    this.changeScrubber.emit(scrubberValue);
    this.hasChanges.emit({
      change: true,
      estimate: true,
      selectVessel: true /* changeDealName: vessel.name */,
    });
  }

  onBlurVessel(event: Event): void {
    const target = event?.target as HTMLInputElement;
    // таймаут для поиска deepSearchVessel, чтобы значение не изменилось сразу же
    setTimeout(() => {
      this.resetVesselControl();
    }, 100);
  }

  onSaveVesselToBase(): void {
    this.resetVesselControl();
    if (this.isFormHasChanges) {
      const vessel = this.vesselFormGroup.value as Vessel;
      if (vessel.is_raw) {
        this.vesselFormGroup.controls.is_raw.patchValue(false, { emitEvent: false });
      }
      this.updatePresetsBeforeSave();
      vessel.presets = this._currentVessel?.presets;
      this.isFormHasChanges = false;
      this.saveVesselToBase.emit(vessel);
    }
  }

  onEstimateDeal(/* multiplication = true */): VesselFormUpdateDto {
    const vessel = cloneDeep(this.vesselFormGroup.value) as Vessel;
    this.updatePresetsBeforeSave();
    vessel.presets = this._currentVessel?.presets;
    const hireFinances = this.hireFinanceFormGroup.value as HireFinance;
    const co2PriceValue = this.fgCO2.value.co2_price;
    /* if (multiplication) {
      if (hireFinances.hire_per_day) {
        hireFinances.hire_per_day *=
          this.financeCurrency?.fiat_multiplier || DEFAULT_FIAT_MULTIPLIER;
      }
      if (hireFinances.ballast_bonus) {
        hireFinances.ballast_bonus *=
          this.financeCurrency?.fiat_multiplier || DEFAULT_FIAT_MULTIPLIER;
      }
    } */
    return { vessel, hireFinances, co2PriceValue };
  }

  setDefaultPresets(isVesselChanged?: boolean): void {
    const defaultPresets = cloneDeep([
      this.fullPreset,
      this.ecoPreset1,
      this.ecoPreset2,
      this.ecoPreset3,
    ]);
    if (
      this._currentVessel &&
      (!this._currentVessel.presets || !this._currentVessel.presets.length)
    ) {
      this._currentVessel.presets = cloneDeep(defaultPresets);
    }
    this.currentVessel?.presets?.filter((preset) => !preset.name);
    if (this.currentVessel?.presets?.length !== 4) {
      if (!this.currentVessel) {
        this.currentVessel = {} as Vessel;
        this.currentVessel.presets = [];
      }
      defaultPresets.filter((preset) =>
        this.currentVessel?.presets?.some((pr) => pr.name === preset.name)
      );
      this.currentVessel?.presets?.push(...cloneDeep(defaultPresets));
      const presetOrder = Object.values(PresetNames);
      this.currentVessel?.presets?.sort(
        (a, b) =>
          presetOrder.indexOf(a.name as PresetNames) - presetOrder.indexOf(b.name as PresetNames)
      );
    }
    cloneDeep(this.currentVessel?.presets)?.some((preset) => {
      if (preset.consumption_rates?.some((rate) => !rate.fuel?.name)) {
        preset.consumption_rates = cloneDeep(this.consumptionRatesMock);
      }
    });
    const fullPreset = cloneDeep(
      this._currentVessel?.presets?.find((preset) => preset.name === PresetNames.Full)
    );
    if (fullPreset) {
      if (!fullPreset.laden_speed) {
        // @ts-ignore
        fullPreset.laden_speed = null;
      }
      if (!fullPreset.ballast_speed) {
        // @ts-ignore
        fullPreset.ballast_speed = null;
      }
      this.fullPresetFormGroup.reset(fullPreset, { emitEvent: false });
      this.fullPresetFormGroup.controls.name.disable({ emitEvent: false });
      if (this._fullPresetSubscription) {
        this._fullPresetSubscription.unsubscribe();
      }
      this._fullPresetSubscription = this.fullPresetFormGroup.valueChanges
        .pipe(
          map((value) => {
            return value;
          }),
          debounceTime(/* this.defaultAutoSave */ 100),
          takeUntil(this._onDestroy$),
          startWith(this.fullPresetFormGroup.value),
          pairwise(),
          filter(([prev, curr]) => {
            return !isEqual(curr, prev);
          }),
          map(([prev, curr]) => {
            console.log('full preset emit change', changedKeys(prev, curr), curr);
            return curr;
          })
        )
        .subscribe((res) => {
          if (res) {
            this.workWithValidationErrors(this.fullPresetFormGroup, PresetNames.Full);
            this.workWithValidationErrors(
              this.ecoPresetFormGroup,
              this.ecoPresetFormGroup.value.name as PresetNames
            );
            this.isFormHasChanges = true;
            this.onChangePreset();
            this.hasChanges.emit({ change: true, estimate: true });
          }
        });
    }
    if (isVesselChanged) {
      const ecaPreset = this._currentVessel?.presets?.find(
        (preset) => preset.name === PresetNames.Eco1
      );
      if (ecaPreset) {
        this.ecoPresetFormGroup.reset(ecaPreset, { emitEvent: false });
      }
    }
  }

  setDefaultDeductibles() {
    const vesselDWT = this.vesselFormGroup.controls.dead_weight.value;
    /*  const deductiblesIsNull = isNullOrZeroNumber(
      this.vesselFormGroup.controls.constants?.controls.deductibles.value || 0
    );
    const deductiblesFieldsAreNull =
      isNullOrZeroNumber(
        this.vesselFormGroup.controls.constants?.controls.bunker_weight.value || 0
      ) &&
      isNullOrZeroNumber(
        this.vesselFormGroup.controls.constants?.controls.permanent_ballast.value || 0
      ) &&
      isNullOrZeroNumber(
        this.vesselFormGroup.controls.constants?.controls.constant_weight.value || 0
      ) &&
      isNullOrZeroNumber(
        this.vesselFormGroup.controls.constants?.controls.fresh_water.value || 0
      ) &&
      isNullOrZeroNumber(this.vesselFormGroup.controls.constants?.controls.other_weight.value || 0); */
    if (vesselDWT /* && deductiblesIsNull && deductiblesFieldsAreNull */) {
      const foundDeductibles = this.deductibles.find((deductible) => {
        return (
          (deductible?.min_dwt || 0) < vesselDWT &&
          (!deductible.max_dwt || deductible.max_dwt > vesselDWT)
        );
      });
      if (foundDeductibles?.deductibles || foundDeductibles?.deductibles === 0) {
        // удаляем deductibles по разбивке, 5 полей
        this.clearDeductibles();
        this.vesselFormGroup.controls.constants?.controls.deductibles.setValue(
          foundDeductibles?.deductibles
        );
      }
    }
  }

  updatePresetsBeforeSave(): void {
    if (this._currentVessel?.presets) {
      const fullPreset = this.fullPresetFormGroup.getRawValue() as Preset;
      /* Object.keys(this.fuelPriceFormGroup.controls).forEach((key) => {
        const fuelPrice = this.fuelPriceFormGroup.controls[key as FuelTypes].value;
        const fuelPriceRate = fullPreset.consumption_rates?.find((rate) => rate.fuel?.name === key);
        if (fuelPriceRate && fuelPrice) {
          fuelPriceRate.price = fuelPrice;
        }
      }); */
      const fullPresetIndex = this._currentVessel.presets.findIndex((preset) => {
        return preset.name === fullPreset.name;
      });
      if (fullPresetIndex >= 0) {
        this._currentVessel?.presets?.splice(fullPresetIndex, 1, fullPreset);
      }
      const ecoPreset: Preset = this.ecoPresetFormGroup.value as Preset;
      const ecoPresetIndex =
        this._currentVessel?.presets?.findIndex((preset) => preset.name === ecoPreset.name) || -1;
      if (ecoPresetIndex >= 0) {
        this._currentVessel?.presets?.splice(ecoPresetIndex, 1, ecoPreset);
      } else {
        this._currentVessel?.presets?.push(ecoPreset);
      }
    }
  }

  visibleConsumptionFg(
    fg: FormGroup<ConsumptionRateFormGroup>,
    hideFuel = true,
    showHsfo = true
  ): boolean {
    const value = fg.value;
    const visibleFuels: string[] = [];
    if (this.vesselFormGroup.value.scrubber) {
      // visibleFuels.push(FuelTypes.HSFO, FuelTypes.LSMGO);
      visibleFuels.push(FuelTypes.LSMGO);
      if (showHsfo || this.highPurityFuelNameFromFirstEvent !== FuelTypes.VLSFO) {
        visibleFuels.push(FuelTypes.HSFO);
      }
    } else {
      visibleFuels.push(FuelTypes.VLSFO, FuelTypes.LSMGO);
    }
    if (this.useUlsfo && !hideFuel) {
      visibleFuels.push(FuelTypes.ULSFO); // ulsfo скрыто в main_engine (ME подпись)
    }
    this.visibleFuels = visibleFuels;
    if (this.highPurityFuelNameFromFirstEvent === FuelTypes.VLSFO) {
      visibleFuels.push(FuelTypes.VLSFO);
    }
    return !!(value.fuel?.name && visibleFuels.includes(value.fuel.name));
  }

  isShowFuels(fg: FormGroup<ConsumptionRateFormGroup>): boolean {
    const value = fg.value;
    const visibleFuels: string[] = [FuelTypes.LSMGO];
    if (this.useUlsfo) {
      visibleFuels.push(FuelTypes.ULSFO);
    } else {
      visibleFuels.push(FuelTypes.VLSFO);
    }
    if (
      this.vesselFormGroup.value.scrubber &&
      this.highPurityFuelNameFromFirstEvent !== FuelTypes.VLSFO
    ) {
      visibleFuels.push(FuelTypes.HSFO);
    }
    return !!(value.fuel?.name && visibleFuels.includes(value.fuel.name));
  }

  // heat => показываем VLSFO инпут
  visibleCargoHeating(fg: FormGroup<ConsumptionRateFormGroup>): boolean {
    const value = fg.value;
    return value.fuel?.name === FuelTypes.VLSFO;
  }

  onChangeScrubber(/* value: CheckboxChangeEvent */): void {
    this.vesselFormGroup.patchValue({ scrubber: !this.vesselFormGroup.value.scrubber });
    const scrubberValue = this.vesselFormGroup.controls.scrubber?.value;
    if (scrubberValue) {
      this.useUlsfo = false;
      this.changeUseULSFO.emit(this.useUlsfo);
      this.changeValidationErrors.emit({
        field: `${FuelTypes.ULSFO}-${this.dealId}`,
        remove: true,
      });
      this.changeValidationErrors.emit({
        field: `${FuelTypes.VLSFO}-${this.dealId}`,
        remove: true,
      });

      // убираем поля, связанные с vlsfo ME cons
      this.changeValidationErrors.emit({
        field: '',
        remove: true,
        removeField: 'vlsfo_main_rate_ballast',
      });
      this.changeValidationErrors.emit({
        field: '',
        remove: true,
        removeField: 'vlsfo_main_rate_laden',
      });
    } else {
      this.changeValidationErrors.emit({ field: `${FuelTypes.HSFO}-${this.dealId}`, remove: true });

      // убираем поля, связанные с hsfo ME cons
      this.changeValidationErrors.emit({
        field: '',
        remove: true,
        removeField: 'hsfo_main_rate_ballast',
      });
      this.changeValidationErrors.emit({
        field: '',
        remove: true,
        removeField: 'hsfo_main_rate_laden',
      });
    }
    // this.vesselFormGroup.patchValue({ scrubber: value.checked });
    this.workWithValidationErrors(this.fullPresetFormGroup, PresetNames.Full);
    this.workWithValidationErrors(
      this.ecoPresetFormGroup,
      this.ecoPresetFormGroup.value.name as PresetNames
    );
    this.changeScrubber.emit(scrubberValue);
    this.cdr.detectChanges();
  }

  onChangeUseULSFO(value: CheckboxChangeEvent): void {
    this.changeUseULSFO.emit(value.checked);
    if (value.checked) {
      const indexExistULSFO = this.fullPresetFormGroup.controls.consumption_rates.value.findIndex(
        (rate) => rate.fuel_id === fuelsMock[3].id
      );
      if (indexExistULSFO === -1) {
        this.fullPresetFormGroup.controls.consumption_rates.insert(
          0,
          this.generateConsumptionRateFormGroup({
            fuel: fuelsMock[3],
            fuel_id: fuelsMock[3].id,
          })
        );
      }
      this.cdr.detectChanges();
    } else {
      this.changeValidationErrors.emit({
        field: `${FuelTypes.ULSFO}-${this.dealId}`,
        remove: true,
      });
    }
  }

  getRateFormGroup(
    formGroup: FormGroup<ConsumptionRateFormGroup>,
    useAux = false
  ): FormGroup<UseRateFormGroup> {
    const fuelName = formGroup.value.fuel?.name;
    if (fuelName === FuelTypes.LSMGO && formGroup.controls.aux_engine_rate && useAux) {
      return formGroup.controls.aux_engine_rate;
    }
    return formGroup.controls.main_engine_rate;
  }

  onChangePreset(): void {
    this.updatePresetsBeforeSave();
    const filteredPresets = this._currentVessel?.presets?.filter(
      (item: Preset) => item.ballast_speed && item.laden_speed
    );
    this.changePreset.emit(filteredPresets);
  }

  getFuelTypeName(fuelName?: string): string {
    // scrubber on and high_purity_fuel_name=LSMGO/HSFO, near ME show HSFO fg
    // scrubber on and high_purity_fuel_name=VLSFO, near ME show VLSFO fg
    const textMe = 'ME';
    if (
      fuelName === FuelTypes.VLSFO ||
      fuelName === FuelTypes.ULSFO ||
      fuelName === FuelTypes.HSFO
    ) {
      return textMe;
    }
    /* if (fuelName === FuelTypes.HSFO) {
      return `${textMe} (${FuelTypes.HSFO})`;
    } */
    if (fuelName === FuelTypes.LSMGO) {
      return `AE (${FuelTypes.LSMGO})`;
    }
    return fuelName || '';
  }

  getFuelTypeNameTooltip(fuelName?: string): string {
    if (
      fuelName === FuelTypes.VLSFO ||
      fuelName === FuelTypes.ULSFO ||
      fuelName === FuelTypes.HSFO
    ) {
      return `Main engine (${FuelTypes.VLSFO}/${FuelTypes.LSMGO}/${FuelTypes.ULSFO}) consumption, MT/D`;
    }
    if (fuelName === FuelTypes.LSMGO) {
      return 'Auxiliary engine consumption, MT/D';
    }
    return '';
  }

  onChangeControl(
    blurEvent: Event,
    consumptionsRatesArrayOfFullPresetFormGroupControls: FormGroup<ConsumptionRateFormGroup>[],
    field: keyof UseRateFormGroup,
    fuelName: string | undefined
  ): void {
    /*  const arrayIdle = consumptionsRatesArrayOfFullPresetFormGroupControls.filter((control) => {
      return (
        control.controls.main_engine_rate.controls[field].value &&
        control.controls.fuel.value?.name !== FuelTypes.LSMGO
      );
    }); */
    const htmlElement = blurEvent?.target as HTMLInputElement;
    let valueHtmlElement: number | null = +htmlElement?.value.replace(/\s/g, '');
    if (!valueHtmlElement) {
      valueHtmlElement = null;
    }
    if (fuelName !== FuelTypes.LSMGO) {
      consumptionsRatesArrayOfFullPresetFormGroupControls.forEach((control) => {
        if (control.controls.fuel.value?.name === fuelName) {
          control.controls.main_engine_rate.controls[field].patchValue(valueHtmlElement);
        } else {
          control.controls.main_engine_rate.controls[field].patchValue(valueHtmlElement, {
            emitEvent: false,
          });
        }
      });
    }
  }

  onChangeSpeedControl(
    blurEvent: Event,
    consumptionsRatesArrayOfFullPresetFormGroupControls: FormGroup<ConsumptionRateFormGroup>[],
    field: keyof UseRateFormGroup,
    fuelName: string | undefined
  ): void {
    const htmlElement = blurEvent?.target as HTMLInputElement;
    const valueHtmlElement = +htmlElement?.value.replace(/\s/g, '');
    // main engine: при внесении данных для VLSFO оно сетится и в HSFO
    if (fuelName === FuelTypes.VLSFO) {
      consumptionsRatesArrayOfFullPresetFormGroupControls.forEach((control) => {
        if (control.controls.fuel.value?.name === FuelTypes.HSFO) {
          control.controls.main_engine_rate.controls[field].patchValue(valueHtmlElement, {
            emitEvent: false,
          });
        }
      });
    }
    // main engine: при внесении данных для HSFO оно сетится и в VLSFO
    if (fuelName === FuelTypes.HSFO) {
      consumptionsRatesArrayOfFullPresetFormGroupControls.forEach((control) => {
        if (control.controls.fuel.value?.name === FuelTypes.VLSFO) {
          control.controls.main_engine_rate.controls[field].patchValue(valueHtmlElement, {
            emitEvent: false,
          });
        }
      });
    }
  }

  onChangeCargoHeatingRateControl(
    blurEvent: Event,
    consumptionsRatesArrayOfFullPresetFormGroupControls: FormGroup<ConsumptionRateFormGroup>[],
    fuelName: string | undefined
  ): void {
    const htmlElement = blurEvent?.target as HTMLInputElement;
    const valueHtmlElement = +htmlElement?.value.replace(/\s/g, '');
    // FULL: проставляем в поле cargo_heating_rate в 3 топливах ULSFO, VLSFO, HSFO + LSMGO
    consumptionsRatesArrayOfFullPresetFormGroupControls.forEach((control) => {
      // if (control.controls.fuel.value?.name !== FuelTypes.LSMGO) {
      control.controls.cargo_heating_rate?.patchValue(valueHtmlElement, {
        emitEvent: false,
      });
      // в интерфейсе показываем VLSFO
      if (control.controls.fuel.value?.name === fuelName) {
        control.controls.cargo_heating_rate?.patchValue(valueHtmlElement);
      } else {
        control.controls.cargo_heating_rate?.patchValue(valueHtmlElement, {
          emitEvent: false,
        });
      }
      // }
    });
  }

  onChangeBallastOrLadenControl(
    blurEvent: Event,
    consumptionsRatesArrayOfEcoPresetFormGroupControls: FormGroup<ConsumptionRateFormGroup>[],
    field: keyof UseRateFormGroup,
    fuelName?: string
  ): void {
    if (fuelName !== FuelTypes.LSMGO) {
      const htmlElement = blurEvent?.target as HTMLInputElement;
      const valueHtmlElement = +htmlElement?.value.replace(/\s/g, '');
      // eco: main_engine_rate ballast&laden set to all 4 fuels
      consumptionsRatesArrayOfEcoPresetFormGroupControls.forEach((control) => {
        control.controls.main_engine_rate.controls[field].patchValue(valueHtmlElement);
      });
    }
  }

  onSelectPreset(event: DropdownChangeEvent): void {
    if (event.value === PresetNames.Eco1) {
      this.changeValidationErrors.emit({ field: '', removeField: PresetNames.Eco2 });
      this.changeValidationErrors.emit({ field: '', removeField: PresetNames.Eco3 });
    }
    if (event.value === PresetNames.Eco2) {
      this.changeValidationErrors.emit({ field: '', removeField: PresetNames.Eco1 });
      this.changeValidationErrors.emit({ field: '', removeField: PresetNames.Eco3 });
    }
    if (event.value === PresetNames.Eco3) {
      this.changeValidationErrors.emit({ field: '', removeField: PresetNames.Eco1 });
      this.changeValidationErrors.emit({ field: '', removeField: PresetNames.Eco2 });
    }

    event.originalEvent?.stopImmediatePropagation();
    event.originalEvent?.preventDefault();
    const needPreset = this._currentVessel?.presets?.find(
      (preset) => preset?.name === event?.value
    );
    if (needPreset) {
      this.ecoPresetFormGroup.reset(needPreset as Preset, { emitEvent: false });
    }
  }

  isFuelConsParserDisabled(): boolean {
    return this.vesselFormGroup.controls?.name.value.length < 3;
  }

  deepSearchVessel(): void {
    this.searchDeepVessel.emit(this.valueForDeepSearch);
  }

  updateVesselFromBase(): void {
    this.requestUpdateVesselFromBase.emit(this.vesselFormGroup.controls.id.value);
  }

  onMouseOver(): void {
    this.valueForDeepSearch = this.vesselAutoCompleteComp?.value;
  }

  resetVesselControl(): void {
    const vessel = this.vesselFormGroup.value;
    if (this._currentVessel?.name && vessel.name !== this._currentVessel.name) {
      this.vesselFormGroup.controls.name.patchValue(this._currentVessel?.name);
    } else if (!this.currentVessel?.name) {
      this.vesselFormGroup.controls.name.reset('');
    }
  }

  getCircleClass(presetName: string): 'green' | 'orange' | '' {
    if (this.selectedPresetNamesInEvents.length) {
      if (
        this.selectedPresetNamesInEvents.every(
          (presetNameInEvent) => presetNameInEvent === presetName
        )
      ) {
        return 'green';
      }
      if (
        this.selectedPresetNamesInEvents.find(
          (presetNameInEvent) => presetNameInEvent === presetName
        )
      ) {
        return 'orange';
      }
    }
    return '';
  }

  onChangeValidationErrors(object: ChangeValidation): void {
    this.changeValidationErrors.emit(object);
  }

  setValidatorsToTCComm(): void {
    this.hireFinanceFormGroup.controls.time_charter_commission.setAsyncValidators(
      ZeroNullValidator(true)
    );
    if (
      this.hireFinanceFormGroup?.controls?.time_charter_commission?.value ||
      this.hireFinanceFormGroup?.controls?.time_charter_commission?.value === 0
    ) {
      this.changeValidationErrors.emit({ field: 'time_charter_commission', remove: true });
    } else {
      this.changeValidationErrors.emit({ field: 'time_charter_commission' });
    }
  }

  workWithValidationErrors(presetFg: FormGroup<PresetFormGroup>, presetName: PresetNames): void {
    const fullBallastSpeedControl = presetFg.controls.ballast_speed;
    const fullLadenSpeedControl = presetFg.controls.laden_speed;

    // LSMGO aux rate (AE, engine)
    const lsmgoFullConsControl = presetFg.controls.consumption_rates.controls.find(
      (rate) => rate.value.fuel?.name === FuelTypes.LSMGO
    );
    const lsmgoFullConsControlAuxRateBallast =
      lsmgoFullConsControl?.controls.aux_engine_rate?.controls.ballast;
    const lsmgoFullConsControlAuxRateLaden =
      lsmgoFullConsControl?.controls.aux_engine_rate?.controls.laden;

    // VLSFO main rate (ME)
    const vlsfoFullConsControl = presetFg.controls.consumption_rates.controls.find(
      (rate) => rate.value.fuel?.name === FuelTypes.VLSFO
    );
    const vlsfoFullConsControlMainRateBallast =
      vlsfoFullConsControl?.controls.main_engine_rate?.controls.ballast;
    const vlsfoFullConsControlMainRateLaden =
      vlsfoFullConsControl?.controls.main_engine_rate?.controls.laden;

    // HSFO main rate (ME)
    const hsfoFullConsControl = presetFg.controls.consumption_rates.controls.find(
      (rate) => rate.value.fuel?.name === FuelTypes.HSFO
    );
    const hsfoFullConsControlMainRateBallast =
      hsfoFullConsControl?.controls.main_engine_rate?.controls.ballast;
    const hsfoFullConsControlMainRateLaden =
      hsfoFullConsControl?.controls.main_engine_rate?.controls.laden;

    if (this.selectedPresetNamesInEvents.includes(presetName)) {
      // добавляем валидаторы и инвалидные поля
      this.addValidator(fullBallastSpeedControl);
      this.addOrRemoveValidationError(fullBallastSpeedControl, `${presetName}-ballast_speed`);
      this.addValidator(fullLadenSpeedControl);
      this.addOrRemoveValidationError(fullLadenSpeedControl, `${presetName}-laden_speed`);

      // LSMGO aux rate (AE, engine)
      if (lsmgoFullConsControlAuxRateBallast) {
        this.addValidator(lsmgoFullConsControlAuxRateBallast);
      }
      if (lsmgoFullConsControlAuxRateBallast) {
        this.addOrRemoveValidationError(
          lsmgoFullConsControlAuxRateBallast,
          `${presetName}-lsmgo_aux_rate_ballast`
        );
      }
      if (lsmgoFullConsControlAuxRateLaden) {
        this.addValidator(lsmgoFullConsControlAuxRateLaden);
      }
      if (lsmgoFullConsControlAuxRateLaden) {
        this.addOrRemoveValidationError(
          lsmgoFullConsControlAuxRateLaden,
          `${presetName}-lsmgo_aux_rate_laden`
        );
      }

      if (this.vesselFormGroup.value.scrubber) {
        // HSFO main rate (ME)
        if (hsfoFullConsControlMainRateBallast) {
          this.addValidator(hsfoFullConsControlMainRateBallast);
        }
        if (hsfoFullConsControlMainRateBallast) {
          this.addOrRemoveValidationError(
            hsfoFullConsControlMainRateBallast,
            `${presetName}-hsfo_main_rate_ballast`
          );
        }
        if (hsfoFullConsControlMainRateLaden) {
          this.addValidator(hsfoFullConsControlMainRateLaden);
        }
        if (hsfoFullConsControlMainRateLaden) {
          this.addOrRemoveValidationError(
            hsfoFullConsControlMainRateLaden,
            `${presetName}-hsfo_main_rate_laden`
          );
        }
      } else {
        // VLSFO main rate (ME)
        if (vlsfoFullConsControlMainRateBallast) {
          this.addValidator(vlsfoFullConsControlMainRateBallast);
        }
        if (vlsfoFullConsControlMainRateBallast) {
          this.addOrRemoveValidationError(
            vlsfoFullConsControlMainRateBallast,
            `${presetName}-vlsfo_main_rate_ballast`
          );
        }
        if (vlsfoFullConsControlMainRateLaden) {
          this.addValidator(vlsfoFullConsControlMainRateLaden);
        }
        if (vlsfoFullConsControlMainRateLaden) {
          this.addOrRemoveValidationError(
            vlsfoFullConsControlMainRateLaden,
            `${presetName}-vlsfo_main_rate_laden`
          );
        }
      }
    } else {
      this.changeValidationErrors.emit({ field: '', removeField: presetName });
      // убираем валидаторы

      this.removeValidator(fullBallastSpeedControl);
      this.removeValidator(fullLadenSpeedControl);
      // LSMGO aux rate (AE, engine)
      if (lsmgoFullConsControlAuxRateBallast) {
        this.removeValidator(lsmgoFullConsControlAuxRateBallast);
      }
      if (lsmgoFullConsControlAuxRateLaden) {
        this.removeValidator(lsmgoFullConsControlAuxRateLaden);
      }

      // if (this.vesselFormGroup.value.scrubber) {
      // HSFO main rate (ME)
      if (hsfoFullConsControlMainRateBallast) {
        this.removeValidator(hsfoFullConsControlMainRateBallast);
      }
      if (hsfoFullConsControlMainRateLaden) {
        this.removeValidator(hsfoFullConsControlMainRateLaden);
      }
      // } else {
      // VLSFO main rate (ME)
      if (vlsfoFullConsControlMainRateBallast) {
        this.removeValidator(vlsfoFullConsControlMainRateBallast);
      }
      if (vlsfoFullConsControlMainRateLaden) {
        this.removeValidator(vlsfoFullConsControlMainRateLaden);
      }
      // }
    }
  }

  addOrRemoveValidationError(control: FormControl<number | null>, field: string): void {
    if (control.value && control.value > 0) {
      this.changeValidationErrors.emit({ field: field, remove: true });
    } else {
      this.changeValidationErrors.emit({ field: field });
    }
  }

  addValidator(control: FormControl<number | null>): void {
    control?.addAsyncValidators(ZeroNullValidator(/* true */));
    control?.updateValueAndValidity({ emitEvent: false });
  }

  removeValidator(control: FormControl<number | null>): void {
    control?.setAsyncValidators(null);
    control?.updateValueAndValidity({ emitEvent: false });
  }

  getInvalidName(string: string | PresetNames): string {
    return this.dealId ? `${string}-${this.dealId}` : '';
  }

  getInvalidNameHire(): string {
    return `Hire rate ${TEXT_ZERO}`;
  }

  makeCO2FormGroup(): FormGroup {
    const fg = new FormGroup({
      co2_price: new FormControl(this.co2Price || null),
    });
    return fg;
  }

  setPresetToEventsDeal(fg: FormGroup<PresetFormGroup>): void {
    this.setPresetToEvents.emit(fg.getRawValue() as Preset);
    const presetName = fg.controls.name.value as PresetNames;
    this.selectedPresetNamesInEvents = [];
    this.selectedPresetNamesInEvents.push(presetName);
    this.workWithValidationErrors(fg, fg.controls.name.value as PresetNames);
  }

  disabledSetPreset(presetName: PresetNames | string): boolean {
    return !this.presets.map((preset) => preset.name).includes(presetName as PresetNames);
  }

  notSelectedSetPreset(presetName: PresetNames | string): boolean {
    return !this.selectedPresetNamesInEvents?.every((value, index, arr) => value === arr[0])
      ? true
      : !this.selectedPresetNamesInEvents.includes(presetName as PresetNames);
  }

  getPresetIcon(fg: FormGroup<PresetFormGroup>): string {
    return this.notSelectedSetPreset(fg.controls.name.value)
      ? PrimeNgIcons.SetPreset
      : PrimeNgIcons.SetPresetSelected;
  }

  handlerPresetInEvent(object: ChangePreset): void {
    if (object.newValue !== object.oldValue) {
      this.selectedPresetNamesInEvents.push(object.newValue);
      const indexToRemove = this.selectedPresetNamesInEvents.indexOf(object.oldValue);
      if (indexToRemove !== -1) {
        this.selectedPresetNamesInEvents.splice(indexToRemove, 1);
      }
    }
    this.workWithValidationErrors(this.fullPresetFormGroup, PresetNames.Full);
    this.workWithValidationErrors(
      this.ecoPresetFormGroup,
      this.ecoPresetFormGroup.value.name as PresetNames
    );
  }

  getSummDeductibles(): number {
    const deductibles = this.vesselFormGroup.controls.constants;
    const bunker = deductibles?.value.bunker_weight || 0;
    const permanent = deductibles?.value.permanent_ballast || 0;
    const constant = deductibles?.value.constant_weight || 0;
    const fresh = deductibles?.value.fresh_water || 0;
    const other = deductibles?.value.other_weight || 0;
    return bunker + permanent + constant + fresh + other;
  }

  onSaveSummDeductibles(): void {
    this.vesselFormGroup.controls.constants?.controls.deductibles.setValue(
      this.getSummDeductibles()
    );
    this.isSaveDeductiblesClicked = true;
    this.overlay?.hide();
  }

  onBlurDeductibles(event: Event): void {
    const currentDeductiblesValue = parseInt((event.target as HTMLInputElement).value);
    if (currentDeductiblesValue !== this.totalDeductiblesValue) {
      this.clearDeductibles();
    }
  }

  onFocusDeductibles(): void {
    this.totalDeductiblesValue =
      this.vesselFormGroup.controls.constants?.controls.deductibles.value || 0;
  }

  onDeductiblesModalClose(): void {
    if (!this.isSaveDeductiblesClicked) {
      this.clearDeductibles(true);
    }
  }

  getDeductiblesFieldsFilledIn(): boolean {
    return !!(
      this.vesselFormGroup.controls.constants?.controls.bunker_weight.value ||
      this.vesselFormGroup.controls.constants?.controls.permanent_ballast.value ||
      this.vesselFormGroup.controls.constants?.controls.constant_weight.value ||
      this.vesselFormGroup.controls.constants?.controls.fresh_water.value ||
      this.vesselFormGroup.controls.constants?.controls.other_weight.value
    );
  }

  getDeductiblesIcon(): string {
    if (this.overlay?.overlayVisible) {
      return PrimeNgIcons.CHEVRON_CIRCLE_UP;
    } else if (this.getDeductiblesFieldsFilledIn()) {
      return PrimeNgIcons.CHECK_CIRCLE;
    }
    return PrimeNgIcons.PLUS_CIRCLE;
  }

  getDeductiblesColor(): boolean {
    return this.getDeductiblesFieldsFilledIn() && !this.overlay?.overlayVisible;
  }

  openDeductiblesModal(event: MouseEvent, el: HTMLElement) {
    const keys: (keyof VesselConstantsFormGroup)[] = [
      'bunker_weight',
      'constant_weight',
      'permanent_ballast',
      'fresh_water',
      'other_weight',
    ];

    keys.forEach((key) => {
      const control = this.vesselFormGroup.controls.constants?.controls[key];
      this.currentDeductibles.set(key, control?.value || 0);
    });

    this.isSaveDeductiblesClicked = false;
    this.overlay?.show(event, el);
  }

  onCalculateHeatingConsumptionChange(value: string): void {
    this.changecargoHeatingRateSwitcher.emit(value === falseText ? false : true);
  }

  onCommissionAffectsBBChange(value: string): void {
    this.hireFinanceFormGroup.controls.commission_affects_bb.patchValue(
      value === falseText ? false : true
    );
  }

  onOpenXBPIModal(): void {
    this.openXBPIModal.emit();
  }

  onHighPurityFuelNameChange(event: string): void {
    this.highPurityFuelNameChange.emit(event);
  }

  setFuelPricesFromXBPI(data: SaveXBPIData): void {
    for (const fuelType of Object.keys(data) as FuelTypes[]) {
      const price = data[fuelType];
      if ((price || price === 0) && this.visibleFuels.includes(fuelType)) {
        this.fuelPriceFormGroup.controls[fuelType].setValue(Math.round(price));
      }
    }
  }

  private clearDeductibles(restore = false): void {
    const controls = [
      this.vesselFormGroup.controls.constants?.controls.bunker_weight,
      this.vesselFormGroup.controls.constants?.controls.constant_weight,
      this.vesselFormGroup.controls.constants?.controls.permanent_ballast,
      this.vesselFormGroup.controls.constants?.controls.fresh_water,
      this.vesselFormGroup.controls.constants?.controls.other_weight,
    ];

    if (restore) {
      this.currentDeductibles.forEach((value, key: keyof VesselConstantsFormGroup) => {
        if (this.vesselFormGroup.controls.constants?.controls[key]) {
          this.vesselFormGroup.controls.constants.controls[key].patchValue(value, {
            emitEvent: false,
          });
        }
      });
    } else {
      controls.forEach((control) => {
        control?.patchValue(0, {
          emitEvent: false,
        });
      });
    }
  }
}
