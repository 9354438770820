import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  FleetVesselCollection,
  PropsDeleteFleetVesselAndFleetVesselCollection,
} from '@estimator/models';

@Component({
  selector: 'estimator-fleet-vessel-collections',
  templateUrl: './fleet-vessel-collections.component.html',
  styleUrls: ['./fleet-vessel-collections.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FleetVesselCollectionsComponent {
  @Input()
  set myCollections(data: FleetVesselCollection[]) {
    this._myCollections = data;
    this.cdr.detectChanges();
  }
  get myCollections(): FleetVesselCollection[] {
    return this._myCollections;
  }
  @Input()
  set myCompanyCollections(data: FleetVesselCollection[]) {
    this._myCompanyCollections = data;
    this.cdr.detectChanges();
  }
  get myCompanyCollections(): FleetVesselCollection[] {
    return this._myCompanyCollections;
  }
  @Input() userCompanyId = 0;
  @Output() addCollection = new EventEmitter<FleetVesselCollection>();
  @Output() updateCollection = new EventEmitter<FleetVesselCollection>();
  @Output() deleteCollection = new EventEmitter<PropsDeleteFleetVesselAndFleetVesselCollection>();

  private _myCollections: FleetVesselCollection[] = [];
  private _myCompanyCollections: FleetVesselCollection[] = [];

  constructor(private readonly cdr: ChangeDetectorRef) {}

  onAddCollection(collection: FleetVesselCollection) {
    this.addCollection.emit(collection);
  }

  onUpdateCollection(collection: FleetVesselCollection) {
    this.updateCollection.emit(collection);
  }

  onDeleteCollection(object: PropsDeleteFleetVesselAndFleetVesselCollection) {
    this.deleteCollection.emit(object);
  }
}
