<!--Confirmation code form - cc-f-->
<form class="flex-container-column gap-2" id="cc-f">
  <div class="p-fluid mb-5">
    <p-message [severity]="NotificationType.Success" [text]="registrationSuccessText"></p-message>
    <p-message
      *ngIf="invalidCodeError"
      [severity]="NotificationType.Error"
      [text]="invalidCodeError"
    ></p-message>
  </div>
  <div class="p-inputgroup mb-5">
    <span
      class="p-inputgroup-addon"
      [ngStyle]="{
        'background-color': PrimeNgColors.Primary,
        'border-color': PrimeNgColors.Primary
      }"
    >
      <i [class]="PrimeNgIcons.AT" [style]="{ color: PrimeNgColors.White }"></i>
    </span>
    <input
      pInputText
      id="email"
      type="email"
      name="email"
      [placeholder]="email"
      formControlName="email"
      disabled="true"
    />
  </div>
  <estimator-confirmation-code-form
    class="confirm-code"
    (sendConfirmCode)="onSendConfirmCode($event)"
    (retryEmail)="onRetryEmail()"
  ></estimator-confirmation-code-form>
</form>
