import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { DialogOpenCloseFolderComponent } from './dialog-open-close-folder.component';

@NgModule({
  declarations: [DialogOpenCloseFolderComponent],
  imports: [CommonModule, ButtonModule],
  exports: [DialogOpenCloseFolderComponent],
})
export class DialogOpenCloseFolderModule {}
