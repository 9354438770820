import { Injectable } from '@angular/core';
import { prepareDataBeforeUpdateCargoCalc } from '@estimator/helpers';
import {
  emptySearchRawVesselsAndPortsResponse,
  NotificationType,
  RawVessel,
  SearchRawVesselsAndPortsResponse,
  Vessel,
} from '@estimator/models';
import { NotificationService, VesselService } from '@estimator/services';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { isEqual } from 'lodash';
import { catchError, finalize, tap, throwError } from 'rxjs';
import { CalculateCargoGroup, DealState, UpdateDeal } from '../deal';
import { XAuthState } from '../x-auth';
import {
  AddVesselToState,
  CreateVessel,
  DeleteVessel,
  GetAllVessels,
  GetRawVesselOverview,
  GetVessel,
  SearchDeepVessels,
  SearchRawVessels,
  SearchVessels,
  SearchVesselsAndPorts,
  UpdateVessel,
  UpdateVesselFromBase,
} from './vessel.actions';

export interface VesselStateModel {
  selectedVessel?: Vessel;
  selectedRawVessel?: RawVessel;
  vessels: Vessel[];
  searchedVessels: Array<Partial<Vessel>>;
  foundParsedVessels: Array<Partial<Vessel>>;
  foundRawVessels: Array<Partial<Vessel>>;
  searchedRawVesselsAndPorts: SearchRawVesselsAndPortsResponse;
  loading: boolean;
}
@State<VesselStateModel>({
  name: 'vessel',
  defaults: {
    vessels: [],
    searchedVessels: [],
    foundParsedVessels: [],
    foundRawVessels: [],
    searchedRawVesselsAndPorts: emptySearchRawVesselsAndPortsResponse,
    loading: false,
  },
})
@Injectable()
export class VesselState {
  @Selector()
  static getIsLoading({ loading }: VesselStateModel): boolean {
    return loading;
  }
  @Selector()
  static getVessels({ vessels }: VesselStateModel): Vessel[] {
    return vessels;
  }
  @Selector()
  static getSearchedVessels({ searchedVessels }: VesselStateModel): Partial<Vessel>[] {
    return searchedVessels;
  }
  @Selector()
  static getFoundRawVessels({ foundRawVessels }: VesselStateModel): Partial<Vessel>[] {
    return foundRawVessels;
  }
  @Selector()
  static getFoundParsedVessels({ foundParsedVessels }: VesselStateModel): Partial<Vessel>[] {
    return foundParsedVessels;
  }
  @Selector()
  static getSearchedRawVesselsAndPorts({
    searchedRawVesselsAndPorts,
  }: VesselStateModel): SearchRawVesselsAndPortsResponse {
    return searchedRawVesselsAndPorts;
  }
  @Selector()
  static getSelectedVessel({ selectedVessel }: VesselStateModel): Vessel | undefined {
    return selectedVessel;
  }
  @Selector()
  static getSelectedRawVessel({ selectedRawVessel }: VesselStateModel): RawVessel | undefined {
    return selectedRawVessel;
  }

  constructor(
    private readonly vesselService: VesselService,
    private readonly store: Store,
    private readonly notificationService: NotificationService
  ) {}

  @Action(GetAllVessels)
  getAllVessels({ patchState }: StateContext<VesselStateModel>) {
    patchState({ loading: true });
    return this.vesselService.getAll().pipe(
      tap((vessels) => {
        patchState({ vessels });
      }),
      catchError((err) => {
        patchState({ loading: false });
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }
  @Action(SearchVessels, { cancelUncompleted: true })
  searchVessels({ patchState }: StateContext<VesselStateModel>, { request }: SearchVessels) {
    patchState({ loading: true });
    return this.vesselService.searchVessel(request).pipe(
      tap(({ parsed_vessels, raw_vessels }) => {
        const vessels = [];
        const foundParsedVessels = [];
        if (parsed_vessels && parsed_vessels.length) {
          vessels.push(...parsed_vessels);
          foundParsedVessels.push(...parsed_vessels);
        }
        if (raw_vessels && raw_vessels.length) {
          vessels.push(...raw_vessels);
        }
        patchState({ searchedVessels: vessels, foundParsedVessels: foundParsedVessels });
      }),
      catchError((err) => {
        patchState({ loading: false });
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }
  @Action(SearchRawVessels, { cancelUncompleted: true })
  searchRawVessels({ patchState }: StateContext<VesselStateModel>, { request }: SearchRawVessels) {
    patchState({ loading: true });
    return this.vesselService.searchRawVessel(request).pipe(
      tap((vessels) => {
        patchState({ foundRawVessels: vessels });
      }),
      catchError((err) => {
        patchState({ loading: false });
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }
  @Action(SearchDeepVessels, { cancelUncompleted: true })
  searchDeepVessels(
    { patchState }: StateContext<VesselStateModel>,
    { request }: SearchDeepVessels
  ) {
    patchState({ loading: true });
    return this.vesselService.searchDeepVessel(request).pipe(
      tap(({ parsed_vessels, raw_vessels }) => {
        const vessels = [];
        if (parsed_vessels && parsed_vessels.length) {
          vessels.push(...parsed_vessels);
        }
        if (raw_vessels && raw_vessels.length) {
          vessels.push(...raw_vessels);
        }
        patchState({ searchedVessels: vessels });
      }),
      catchError((err) => {
        patchState({ loading: false });
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }
  @Action(UpdateVesselFromBase, { cancelUncompleted: true })
  updateVesselFromBase(
    { patchState, dispatch }: StateContext<VesselStateModel>,
    { vesselId, updDeal }: UpdateVesselFromBase
  ) {
    patchState({ loading: true });
    return this.vesselService.updateFuelConsumptionsFromBase(vesselId).pipe(
      tap((vessel) => {
        delete vessel.company;
        delete vessel.company_id;
        delete vessel.owner;
        delete vessel.manager;
        delete vessel.builder;
        delete vessel.classification_society;
        delete vessel.country_name;
        delete vessel.created_at;
        delete vessel.created_by;
        delete vessel.created_by_temporary_user;
        delete vessel.deleted_at;
        delete vessel.updated_at;
        delete vessel.updated_by;
        delete vessel.updated_by_temporary_user;
        updDeal.vessel = vessel;
        const grainBaleCalculationType = this.store.selectSnapshot(
          XAuthState.getSettings
        ).default_grain_bale_calculation_type;
        updDeal.cargoes?.forEach((cargo) => {
          prepareDataBeforeUpdateCargoCalc(cargo, grainBaleCalculationType, updDeal.vessel);
          const vesselDwt = updDeal?.vessel?.dead_weight || 0;
          if (cargo?.cargo_calculator && vesselDwt) {
            cargo.cargo_calculator.dwt = vesselDwt;
          }
        });
        if (updDeal.cargoes?.length) {
          this.store.dispatch(new CalculateCargoGroup(updDeal.cargoes));
        }
        dispatch(new UpdateDeal(updDeal));
      }),
      catchError((err) => {
        patchState({ loading: false });
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }
  @Action(GetVessel)
  getVessel({ patchState }: StateContext<VesselStateModel>, { id }: GetVessel) {
    patchState({ loading: true });
    return this.vesselService.get(id).pipe(
      tap((vessel) => {
        patchState({ selectedVessel: vessel });
      }),
      catchError((err) => {
        patchState({ loading: false });
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }
  @Action(GetRawVesselOverview)
  getRawVesselOverview({ patchState }: StateContext<VesselStateModel>, { id }: GetRawVesselOverview) {
    patchState({ loading: true });
    return this.vesselService.getRawVesselOverview(id).pipe(
      tap((vessel) => {
        patchState({ selectedRawVessel: vessel });
      }),
      catchError((err) => {
        this.notificationService.showNotification(
          `Can't get Vessel overview ${err?.error?.error}`,
          NotificationType.Error
        );
        patchState({ loading: false });
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }
  @Action(CreateVessel)
  createVessel(
    { dispatch, getState, patchState }: StateContext<VesselStateModel>,
    { vessel, updDeal }: CreateVessel
  ) {
    patchState({ loading: true });
    const deductibles = vessel?.constants;
    return this.vesselService.create(vessel).pipe(
      tap((vessel) => {
        const vessels = getState().vessels;
        vessels.push(vessel);
        patchState({ selectedVessel: vessel, vessels: [...vessels] });
        if (updDeal) {
          updDeal.vessel = vessel;
          updDeal.vessel_id = vessel.id;
          if (deductibles) {
            updDeal.vessel.constants = deductibles;
          }
          dispatch(new UpdateDeal(updDeal));
        }
      }),
      catchError((err) => {
        patchState({ loading: false });
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }
  @Action(UpdateVessel)
  updateVessel(
    { dispatch, getState, patchState }: StateContext<VesselStateModel>,
    { vessel, isNeedToUpdateDeal }: UpdateVessel
  ) {
    patchState({ loading: true });
    const deductibles = vessel?.constants;
    return this.vesselService.update(vessel).pipe(
      tap((updVessel) => {
        const vessels = getState().vessels;
        const index = vessels.findIndex((oldVessel) => oldVessel?.id === vessel?.id);
        if (index >= 0 || isNeedToUpdateDeal) {
          const oldVessel = vessels.splice(index, 1, updVessel);
          if (oldVessel?.length || isNeedToUpdateDeal) {
            // let speed: number | null = null;
            if (!isEqual(oldVessel, updVessel)) {
              /*  if (
                updVessel?.ballast_speed &&
                oldVessel[0]?.ballast_speed !== updVessel?.ballast_speed
              ) {
                speed = updVessel.ballast_speed;
              } else if (
                updVessel?.laden_speed &&
                oldVessel[0].laden_speed !== updVessel?.laden_speed
              ) {
                speed = updVessel.laden_speed;
              } else if (updVessel?.low_speed && oldVessel[0].low_speed !== updVessel?.low_speed) {
                speed = updVessel.low_speed;
              } */
              const deal = this.store.selectSnapshot(DealState.getCurrentEditedCopy);
              if (deal) {
                deal.vessel = updVessel;
                deal.vessel_id = updVessel.id;
                if (deductibles) {
                  deal.vessel.constants = deductibles;
                }
                dispatch(new UpdateDeal(deal /* speed */));
              }
            }
          }
        }
        patchState({ selectedVessel: updVessel, vessels: [...vessels] });
      }),
      catchError((err) => {
        patchState({ loading: false });
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }
  @Action(DeleteVessel)
  deleteVessel({ getState, patchState }: StateContext<VesselStateModel>, { id }: DeleteVessel) {
    patchState({ loading: true });
    return this.vesselService.delete(id).pipe(
      tap(() => {
        const { vessels, selectedVessel } = getState();
        const index = vessels.findIndex((oldVessel) => oldVessel?.id === id);
        if (index >= 0) {
          vessels.splice(index, 1);
        }
        patchState({ vessels: [...vessels] });
        if (selectedVessel && selectedVessel?.id === id) {
          patchState({ selectedVessel: undefined });
        }
      }),
      catchError((err) => {
        patchState({ loading: false });
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }
  @Action(AddVesselToState)
  addVesselToState(
    { getState, patchState }: StateContext<VesselStateModel>,
    { vessel }: AddVesselToState
  ) {
    const vessels = getState().vessels;
    patchState({ selectedVessel: vessel, vessels: [...vessels, vessel] });
  }
  @Action(SearchVesselsAndPorts, { cancelUncompleted: true })
  searchVesselsAndPorts(
    { patchState }: StateContext<VesselStateModel>,
    { request }: SearchVesselsAndPorts
  ) {
    patchState({ loading: true });
    return this.vesselService.searchRawVesselsAndPorts(request).pipe(
      tap((res: SearchRawVesselsAndPortsResponse) => {
        patchState({ searchedRawVesselsAndPorts: res });
      }),
      catchError((err) => {
        this.notificationService.showNotification(
          `Search is broken. ${err?.error?.error}`,
          NotificationType.Error
        );
        patchState({ loading: false });
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }
}
