import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  BreakEvenData,
  BreakEvenDialogConfig,
  DEFAULT_BREAK_EVEN_STEP,
  DEFAULT_FIAT_MULTIPLIER
} from '@estimator/models';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { BreakEvenAxesComponent } from '../break-even-axes/break-even-axes.component';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'estimator-break-even-dialog',
  templateUrl: './break-even-dialog.component.html',
  styleUrls: ['./break-even-dialog.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreakEvenDialogComponent implements OnInit {
  @ViewChild('breakEvenAxes') breakEvenAxes!: BreakEvenAxesComponent;
  @Input()
  set data(data: BreakEvenData[]) {
    this._data = data;
  }
  get data(): BreakEvenData[] {
    return this._data;
  }
  @Input() onChangeInput: (rise: number) => void = () => {};
  newDataEvent = new EventEmitter();
  initialArrayFields: string[] = [];
  stepStep = 0.1;
  fg: FormGroup = new FormGroup({
    step: new FormControl(DEFAULT_BREAK_EVEN_STEP),
  });

  private _data: BreakEvenData[] = [];

  constructor(private readonly dialogConfig: DynamicDialogConfig<BreakEvenDialogConfig>) {
    if (this.dialogConfig.data) {
      const {
        onChangeInput,
        data,
        newDataEvent
      } = this.dialogConfig.data;
      
      if (data) {
        this.data = data;
      }

      if (onChangeInput) {
        this.onChangeInput = onChangeInput;
      }

      if (newDataEvent) {
        this.newDataEvent = newDataEvent;
      }
    }
  }

  ngOnInit(): void {
    this.data = this.normalizeDataMapper(this.data);
    this.initialArrayFields = this.getArrayFields();

    this.fg?.valueChanges?.pipe(debounceTime(500)).subscribe((fgValue) => {
      if (fgValue.step && fgValue.step !== 0) {
        this.onChangeInput(fgValue.step);
      }

      if (this.breakEvenAxes) {
        this.breakEvenAxes.data = this.data;
        this.breakEvenAxes.initAxes();
      }
    });

    this.newDataEvent.subscribe(newData => {
      this.data = this.normalizeDataMapper(newData);
      if (this.breakEvenAxes) {
        this.breakEvenAxes.data = this.data;
        this.breakEvenAxes.initAxes();
      }
      this.initialArrayFields = this.getArrayFields();
    })
  }

  private getArrayFields(): string[] {
    return this.data[0]?.cargoes.map(cargo => cargo.name);
  }

  private normalizeDataMapper(breakEvenData: BreakEvenData[]): BreakEvenData[] {
    return breakEvenData.map(beData => {
      beData.hire = beData.hire / DEFAULT_FIAT_MULTIPLIER;
      beData.ntce = beData.ntce / DEFAULT_FIAT_MULTIPLIER;
      beData.result = beData.result / DEFAULT_FIAT_MULTIPLIER;
      beData.step = beData.step / DEFAULT_FIAT_MULTIPLIER;
      beData.cargoes.map((cargo, i) => {
        cargo.price = cargo.price / DEFAULT_FIAT_MULTIPLIER;
        cargo.name = cargo.name || `Cargo ${i+1}`;
        return cargo;
      })
      return beData;
    })
  }

}
