import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  ElementRef,
  QueryList,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'mar-accordion',
  template: `<ng-content></ng-content>`,
  styles: [
    `
      .panel {
        padding: 2.4rem 0;
        border-bottom: 1px solid rgba(255, 255, 255, 0.15);
        cursor: pointer;
        /* transition: 2.5s; */
        &.show {
          .panel-description {
            display: block;
          }
          .panel-title {
            &:after {
              background: url(/assets/icon-minus.svg) no-repeat;
            }
          }
        }
      }
      .panel-title {
        display: flex;
        font-size: 2rem;
        position: relative;
        &:after {
          content: '';
          width: 2.4rem;
          height: 2.4rem;
          position: absolute;
          right: 0;
          background: url(/assets/icon-plus.svg) no-repeat;
        }
      }
      .panel-description {
        margin-top: 2.4rem;
        width: 75%;
        line-height: 150%;
        color: rgba(255, 255, 255, 0.7);
        display: none;
      }
    `,
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccordionComponent implements AfterViewInit {
  @ContentChildren('panel') listPanels?: QueryList<ElementRef<HTMLElement>>;

  ngAfterViewInit(): void {
    if (!this.listPanels) {
      return;
    }
    this.listPanels.forEach((element) => {
      element.nativeElement.addEventListener('click', () => {
        element.nativeElement.classList.toggle('show');
      });
    });
  }
}
