import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BadgeModule, InputModule } from 'packages/@mar/common-site/src';
import { SubscribeFormComponent } from './subscribe-form.component';

@NgModule({
  declarations: [SubscribeFormComponent],
  imports: [CommonModule, RouterModule, BadgeModule, FormsModule, ReactiveFormsModule, InputModule],
  exports: [SubscribeFormComponent],
})
export class SubscribeFormModule {}
