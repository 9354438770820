import { Injectable } from '@angular/core';
import { Currency } from '@estimator/models';
import { CurrencyService } from '@estimator/services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { catchError, finalize, tap, throwError } from 'rxjs';
import { GetAllCurrencies } from './currency.actions';

export interface CurrencyStateModel {
  currencies: Currency[];
  loading: boolean;
}

@State<CurrencyStateModel>({
  name: 'currency',
  defaults: {
    currencies: [],
    loading: false,
  },
})
@Injectable()
export class CurrencyState {
  @Selector()
  static getIsLoading({ loading }: CurrencyStateModel): boolean {
    return loading;
  }
  @Selector()
  static getCurrencies({ currencies }: CurrencyStateModel): Currency[] {
    return currencies;
  }

  constructor(private readonly currencyService: CurrencyService) {}

  @Action(GetAllCurrencies)
  getAllCurrencies({ patchState }: StateContext<CurrencyStateModel>) {
    patchState({ loading: true });
    return this.currencyService.getAll().pipe(
      tap((currencies) => {
        patchState({ currencies });
      }),
      catchError((err) => {
        patchState({ loading: false });
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }
}
