import { AfterViewInit, Component, HostListener, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { compareObjects } from '@estimator/helpers';
import { ICellEditorAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'estimator-select-editor',
  template: `
    <mat-select
      panelClass="ag-grid-select-panel"
      #select
      [formControl]="formControl"
      [compareWith]="compareObjects"
      (selectionChange)="closeEditor()"
    >
      <mat-option *ngFor="let data of dataArray" [value]="data" [disabled]="isDisabled(data)">
        <ng-container *ngIf="visibleKeys.length; else simpleTemplate">
          <ng-container *ngFor="let key of visibleKeys">
            {{ data[key] }}
          </ng-container>
        </ng-container>
        <ng-template #simpleTemplate>{{ data }}</ng-template>
      </mat-option>
    </mat-select>
  `,
  styles: [
    `
      .aggrid-numeric-editor {
        width: 100%;
        height: 100%;
        border: none;
        background: none;
      }
      .cdk-overlay-pane {
        min-width: 0;
      }
      .mat-mdc-option {
        padding: 0 2px !important;
      }
    `,
  ],
})
export class AgGridSelectCellEditorComponent implements ICellEditorAngularComp, AfterViewInit {
  @ViewChild('select') select!: MatSelect;
  value!: any;
  dataArray: any[] = [];
  visibleKeys: string[] = [];
  formControl = new FormControl();
  disabledIds: number[] = [];
  readonly compareObjects = compareObjects;
  private _params: any;

  @HostListener('click', ['$event'])
  stopPropagation(event: MouseEvent): void {
    event.stopImmediatePropagation();
  }

  agInit(params: any): void {
    this._params = params;
    if (params.dataArray) {
      this.dataArray = params.dataArray;
    }
    if (params.visibleKeys) {
      this.visibleKeys = params.visibleKeys;
    }
    if (params.value) {
      this.formControl.setValue(params.value);
    }
    if (params.disabledIds) {
      this.disabledIds = params.disabledIds;
    }
    if (params.closeEditor) {
      this.closeEditor = params.closeEditor;
    }
  }

  getValue(): any {
    const result = this.formControl.value;
    return result;
  }

  ngAfterViewInit() {
    window.setTimeout(() => {
      this.select.open();
    });
  }

  isDisabled(data: any) {
    if (this.disabledIds.length) {
      return this.disabledIds.includes(data.id);
    }
    return false;
  }

  closeEditor(): void {
    return;
  }
}
