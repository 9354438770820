import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from '@estimator/directives';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { PriceAndCurrencyInputModule } from '../price-and-currency-input/price-and-currency-input.module';
import { UserDurationInputModule } from '../user-duration-input/user-duration-input.module';
import { MiscFormComponent } from './misc-form.component';

export const MISC_FORM_IMPORTS = [
  CommonModule,
  ButtonModule,
  DirectivesModule,
  DropdownModule,
  InputNumberModule,
  ReactiveFormsModule,
  UserDurationInputModule,
  PriceAndCurrencyInputModule,
];

@NgModule({
  declarations: [MiscFormComponent],
  imports: [
    CommonModule,
    ButtonModule,
    DirectivesModule,
    DropdownModule,
    InputNumberModule,
    ReactiveFormsModule,
    UserDurationInputModule,
    PriceAndCurrencyInputModule,
  ],
  exports: [MiscFormComponent],
})
export class MiscFormModule {}
