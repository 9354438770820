import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { getLocalTimeZoneOffsetInHours } from '@estimator/helpers';
import {
  DaysDistanceIndicators,
  ID_USER_SETTINGS_FORM_SHOW_CANALS,
  Preset,
  PrimeNgIcons,
  UserTimeSettings,
} from '@estimator/models';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'estimator-days-distance-indicators',
  templateUrl: './days-distance-indicators.component.html',
  styleUrls: ['./days-distance-indicators.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DaysDistanceIndicatorsComponent implements OnInit, OnDestroy {
  @Input()
  set daysDistanceIndicators(daysDistanceIndicators: DaysDistanceIndicators) {
    if (daysDistanceIndicators) {
      this._daysDistanceIndicators = daysDistanceIndicators;
    }
  }
  get daysDistanceIndicators(): DaysDistanceIndicators {
    return this._daysDistanceIndicators;
  }

  @Input()
  set showCanals(value: boolean) {
    this.showCanalsControl.patchValue(!!value, { emitEvent: false });
  }
  @Input()
  set timeSettings(settings: UserTimeSettings) {
    this.timeSettingsControl.setValue(settings, { emitEvent: false });
  }
  @Input() presets: Preset[] = [];
  @Output() changeShowCanals = new EventEmitter<boolean>();
  @Output() changePresets = new EventEmitter<Preset>();
  @Output() createEvent = new EventEmitter<void>();
  showCanalsControl = new FormControl<boolean>(false, { nonNullable: true });
  presetsControl = new FormControl<Preset | null>(null);
  timeSettingValues = Object.values(UserTimeSettings);
  timeSettingsControl = new FormControl<UserTimeSettings>(this.timeSettingValues[0], {
    nonNullable: true,
  });
  readonly UserTimeSettings = UserTimeSettings;
  readonly PrimeNgIcons = PrimeNgIcons;
  // also use in estimator-events-form
  readonly ID_USER_SETTINGS_FORM_SHOW_CANALS = ID_USER_SETTINGS_FORM_SHOW_CANALS;
  private _daysDistanceIndicators = {};
  private _onDestroy$ = new Subject<void>();

  get userTimeZone(): string {
    return `(UTC${
      getLocalTimeZoneOffsetInHours() >= 0 ? '+' : ''
    }${getLocalTimeZoneOffsetInHours()})`;
  }

  ngOnInit(): void {
    this.showCanalsControl.valueChanges.pipe(takeUntil(this._onDestroy$)).subscribe((value) => {
      this.changeShowCanals.emit(value);
    });
    this.presetsControl.valueChanges.pipe(takeUntil(this._onDestroy$)).subscribe((value) => {
      this.changePresets.emit(value as Preset);
    });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  onCreateEvent(): void {
    this.createEvent.emit();
  }
}
