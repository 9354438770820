import { FormGroup } from '@angular/forms';
import { Cargo, CargoForm } from './cargo';
import { DealEvent, EventFormGroup } from './deal-event';
import { ColumnUI } from './ui-items';

export interface DemurrageDespatch {
  cargo: Cargo;
  events: DealEvent[];
}

export interface DemurrageDespatchForm {
  cargo: FormGroup<CargoForm>;
  events: FormGroup<EventFormGroup>[];
}

export enum DemurrageDespatchColumnType {
  Type = 'type',
  Port = 'port',
  Demurrage = 'demurrage',
  Despatch = 'despatch',
  Quantity = 'quantity',
  LDRate = 'ldRate',
  Terms = 'terms',
  Allowed = 'allowed',
  Actual = 'actual',
  Difference = 'difference',
  Result = 'result',
}

export const DEM_DES_COLUMNS: ColumnUI<DemurrageDespatchColumnType>[] = [
  {
    field: DemurrageDespatchColumnType.Type,
    header: '#',
    width: '65px',
  },
  { field: DemurrageDespatchColumnType.Port, header: 'Port', width: '65px' },
  {
    field: DemurrageDespatchColumnType.Demurrage,
    header: 'Demurrage',
    width: '110px',
  },
  { field: DemurrageDespatchColumnType.Despatch, header: 'Despatch', width: '110px' },
  {
    field: DemurrageDespatchColumnType.Quantity,
    header: 'Quantity',
    width: '75px',
  },
  { field: DemurrageDespatchColumnType.LDRate, header: 'LD Rate', width: '90px' },
  {
    field: DemurrageDespatchColumnType.Terms,
    header: 'Terms',
    width: '100px',
  },
  { field: DemurrageDespatchColumnType.Allowed, header: 'Allowed', width: '120px' },
  {
    field: DemurrageDespatchColumnType.Actual,
    header: 'Actual',
    width: '120px',
  },
  { field: DemurrageDespatchColumnType.Difference, header: 'Difference', width: '120px' },
  {
    field: DemurrageDespatchColumnType.Result,
    header: 'Result',
    width: '120px',
  },
];
