export interface BlogPost {
  slug: string;
  title: string;
  titleImage: string;
  preview_text: string;
  body_text: string;
  tags: string[];
  cover: {
    formats?: {
      large?: { url: string };
      medium?: { url: string };
      small?: { url: string };
      thumbnail?: { url: string };
    };
    url: string;
  };
  createdAt: Date;
  updatedAt: Date;
  publish_date?: Date;
  author?: string;
}
