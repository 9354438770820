import { NgModule } from '@angular/core';
import { AccordionModule } from './accordion';
import { BadgeModule } from './badge';
import { ButtonModule } from './button';
import { CheckboxModule } from './checkbox';
import { CookiesModule } from './cookies';
import { DirectivesModule } from './directives';
import { GhostButtonModule } from './ghost-button';
import { InputModule } from './input';
import { LoaderComponent } from './loader';
import { LogoModule } from './logo';
import { SidebarModule } from './sidebar';
import { TextareaModule } from './textarea';

export const COMMON_SITE_MODULES = [
  BadgeModule,
  InputModule,
  ButtonModule,
  LoaderComponent,
  AccordionModule,
  DirectivesModule,
  TextareaModule,
  CheckboxModule,
  SidebarModule,
  LogoModule,
  CookiesModule,
  GhostButtonModule,
];

@NgModule({
  imports: COMMON_SITE_MODULES,
  exports: COMMON_SITE_MODULES,
})
export class CommonSiteModule {}
