import {
  CompanyInformation,
  LoginProps,
  MemberRole,
  ProductType,
  RegisterProps,
  UiSettingsStateModel,
  UserInformation,
} from '@estimator/models';

export class XAuthLogin {
  static readonly type = '[XAUTH] Login';
  constructor(public props: LoginProps) {}
}

export class XAuthLogout {
  static readonly type = '[XAUTH] Logout';
}

export class XAuthRefreshToken {
  static readonly type = '[XAUTH] Refresh token';
  constructor(public props: string) {}
}

export class XAuthRestoreToken {
  static readonly type = '[XAUTH] Restore token from storage';
}

export class XAuthRegister {
  static readonly type = '[XAUTH] Register';
  constructor(public props: RegisterProps) {}
}

export class XAuthConfirmEmail {
  static readonly type = '[XAUTH] Confirm email';
  constructor(public token: string) {}
}

export class XAuthConfirmUser {
  static readonly type = '[XAUTH] Confirm user';
  constructor(public token: string) {}
}

export class XAuthConfirmUserByCode {
  static readonly type = '[XAUTH] Confirm user by code';
  constructor(public token: string, public code: string) {}
}

export class XAuthResetPassword {
  static readonly type = '[XAUTH] Reset users password';
  constructor(public email: string) {}
}

export class XAuthRetryEmail {
  static readonly type = '[XAUTH] Retry email';
  constructor(public token: string) {}
}

export class XAuthUpdatePassword {
  static readonly type = '[XAUTH] Update users password';
  constructor(public password: string, public resetToken?: string) {}
}

export class XAuthOpenAuthForm {
  static readonly type = '[XAUTH] Open auth form';
  constructor(
    public actionsAfter?: any[],
    public isSignUp?: boolean,
    public presetProduct?: ProductType
  ) {}
}

export class XAuthOpenResetPasswordForm {
  static readonly type = '[XAUTH] Open reset password form';
  constructor(public resetToken?: string) {}
}

export class XAuthOpenConfirmInvitation {
  static readonly type = '[XAUTH] Open confirm invitation';
  constructor(public token: string) {}
}

export class XAuthOpenConfirmEmailForm {
  static readonly type = '[XAUTH] Open confirm email form';
  constructor(public error?: string) {}
}

export class XAuthSetTemporaryUserToken {
  static readonly type = '[AUTHENTICATION] Set temporary token';
}

export class XAuthRegistrationRemind {
  static readonly type = '[AUTHENTICATION] Registration remind';
}

export class XAuthLoginRemind {
  static readonly type = '[AUTHENTICATION] Login remind';
}

export class XAuthChangeForbidWindowOpen {
  static readonly type = '[AUTHENTICATION] Change forbid window open';
  constructor(public open: boolean) {}
}

export class XAuthGetCompanyInformation {
  static readonly type = '[XAUTH] Get company information';
}

export class XAuthGetCompanyAvailableServices {
  static readonly type = '[XAUTH] Get company available services';
}

export class XAuthChangeUserBlocked {
  static readonly type = '[XAUTH] Change user blocked';
  constructor(public userEmail: string, public blocked: boolean) {}
}

export class XAuthChangeUserRole {
  static readonly type = '[XAUTH] Change user role';
  constructor(public userEmail: string, public userRole: MemberRole) {}
}

export class XAuthChangeUserServiceTypes {
  static readonly type = '[XAUTH] Change user service types';
  constructor(public userEmail: string, public serviceTypes: ProductType[]) {}
}

export class XAuthChangeCompanyAdmin {
  static readonly type = '[XAUTH] Change company admin';
  constructor(public administratorId: number) {}
}

export class XAuthInviteUser {
  static readonly type = '[XAUTH] Invite user';
  constructor(public userEmail: string) {}
}

export class XAuthCancelInviteUser {
  static readonly type = '[XAUTH] Cancel invite user';
  constructor(public userEmail: string) {}
}

export class XAuthUpdateCompany {
  static readonly type = '[XAUTH] Update company';
  constructor(public companyInformation: CompanyInformation) {}
}

export class XAuthUpdateUserInfo {
  static readonly type = '[XAUTH] Update user info';
  constructor(public userInformation: UserInformation) {}
}

export class XAuthGetMe {
  static readonly type = '[XAUTH] Get me';
}

export class XAuthSetSettings {
  static readonly type = '[XAUTH] Set settings';
  constructor(public settings: UiSettingsStateModel) {}
}

export class XAuthGetCompanyDeductibles {
  static readonly type = '[XAUTH] Get company deductibles';
}

export class XAuthIsRedirectAfterLogin {
  static readonly type = '[XAUTH] XAuthIsRedirectAfterLogin';
  constructor(public needRedirectAfterLogin: boolean) {}
}
