import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { localDateTimeFormat } from '@estimator/helpers';
import {
  NOT_APPLICABLE,
  PortDisbursementAccount,
  PortDisbursementTableDialogConfig,
  PrimeNgIcons,
} from '@estimator/models';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'estimator-port-disbursement-table',
  templateUrl: './port-disbursement-table.component.html',
  styleUrls: ['./port-disbursement-table.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortDisbursementTableComponent {
  @Output() savePortDA = new EventEmitter<number>();
  portDisbursements: PortDisbursementAccount[] = [];
  isFixed = false;
  localDateTimeFormat = localDateTimeFormat;
  readonly NOT_APPLICABLE = NOT_APPLICABLE;
  readonly PrimeNgIcons = PrimeNgIcons;

  constructor(
    private readonly dialogConfig: DynamicDialogConfig<PortDisbursementTableDialogConfig>
  ) {
    if (this.dialogConfig.data) {
      const { portDisbursements, isFixed, savePortDA } = this.dialogConfig.data;
      if (portDisbursements) {
        this.portDisbursements = portDisbursements;
      }
      if (isFixed) {
        this.isFixed = isFixed;
      }
      if (savePortDA) {
        this.savePortDA = savePortDA;
      }
    }
  }

  onSavePortDA(daAmount: number): void {
    this.savePortDA.emit(daAmount);
  }
}
