import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import {
  CopyFolderDialogConfig,
  Deal,
  Folder,
  FolderFilterModel,
  NotificationType,
} from '@estimator/models';
import { FoldersService, NotificationService } from '@estimator/services';
import { CopyFolderDialogComponent } from '@estimator/ui';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { SortModelItem } from 'ag-grid-community';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Subject, catchError, finalize, takeUntil, tap, throwError } from 'rxjs';
import { FilterStartCopiedDeals } from '../deal/deal.actions';
import {
  CopyFolder,
  CreateFolder,
  DeleteFolder,
  GetAllFolders,
  GetDealsByFolderID,
  GetDealsByFolderIDSearch,
  GetFolder,
  GetFoldersBySearch,
  OpenCopyFolderModal,
  SetDealsOrderInFolder,
  SetFolderPinStatus,
  UpdateFolder,
} from './folder.actions';

export interface FolderStateModel {
  selectedFolder?: Folder;
  folders: Folder[];
  activeFolders: Folder[];
  sidebarFolders: Folder[];
  allFoldersCount: number;
  dealsByFolderID: Deal[];
  dealsLightByFolderID: Deal[];
  allDealsByFolderIDCount: number;
  loading: boolean;
  createdFolderFromSidebar: Folder | null;
  copiedAlienFolder?: Folder;
}

@State<FolderStateModel>({
  name: 'folder',
  defaults: {
    folders: [],
    activeFolders: [],
    sidebarFolders: [],
    allFoldersCount: 0,
    dealsByFolderID: [],
    dealsLightByFolderID: [],
    allDealsByFolderIDCount: 0,
    loading: false,
    createdFolderFromSidebar: null,
  },
})
@Injectable()
export class FolderState implements OnDestroy {
  private _onDestroy$ = new Subject<void>();

  @Selector()
  static getIsLoading({ loading }: FolderStateModel): boolean {
    return loading;
  }

  @Selector()
  static getFolders({ folders }: FolderStateModel): Folder[] {
    return folders;
  }

  @Selector()
  static getDealsByFolderID({ dealsByFolderID }: FolderStateModel): Deal[] {
    return dealsByFolderID;
  }

  @Selector()
  static getDealsLightByFolderID({ dealsLightByFolderID }: FolderStateModel): Deal[] {
    return dealsLightByFolderID;
  }

  @Selector()
  static selectedFolder({ selectedFolder }: FolderStateModel): Folder {
    return selectedFolder as Folder;
  }

  @Selector()
  static getSidebarFolders({ sidebarFolders }: FolderStateModel): Folder[] {
    return sidebarFolders;
  }

  @Selector()
  static getCopiedAlienFolder({ copiedAlienFolder }: FolderStateModel): Folder | undefined {
    if (copiedAlienFolder) {
      return copiedAlienFolder;
    }
    return;
  }

  constructor(
    private readonly folderlSrv: FoldersService,
    private readonly notificationService: NotificationService,
    private readonly store: Store,
    private readonly dialogService: DialogService
  ) {}

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  @Action(GetFolder)
  getFolder({ patchState }: StateContext<FolderStateModel>, { id }: GetFolder) {
    patchState({ loading: true });
    return this.folderlSrv.get(id).pipe(
      tap((folder) => {
        patchState({ selectedFolder: folder });
      }),
      catchError((err) => {
        patchState({ loading: false });
        this.notificationService.showNotification(
          `Error when get a folder. ${err?.error?.error}`,
          NotificationType.Error
        );
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }

  @Action(CreateFolder)
  createFolder({ getState, patchState }: StateContext<FolderStateModel>, { folder }: CreateFolder) {
    patchState({ loading: true });
    return this.folderlSrv.create(folder).pipe(
      tap((folder) => {
        const { folders, sidebarFolders } = getState();
        patchState({
          folders: [...folders, folder],
          sidebarFolders: [...sidebarFolders, folder],
          createdFolderFromSidebar: folder,
        });
      }),
      catchError((err) => {
        patchState({ loading: false });
        this.notificationService.showNotification(
          `Error when adding a folder. ${err?.error?.error}`,
          NotificationType.Error
        );
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }

  @Action(UpdateFolder)
  updateFolder({ getState, patchState }: StateContext<FolderStateModel>, { folder }: UpdateFolder) {
    patchState({ loading: true });
    return this.folderlSrv.update(folder).pipe(
      tap((folder) => {
        const { folders } = getState();
        const index = folders.findIndex((oldFolder) => oldFolder?.id === folder.id);
        if (index >= 0) {
          folders.splice(index, 1, folder);
        }
        patchState({ folders: [...folders] });
      }),
      catchError((err) => {
        patchState({ loading: false });
        this.notificationService.showNotification(
          `Error when updating a folder. ${err?.error?.error}`,
          NotificationType.Error
        );
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }

  @Action(DeleteFolder)
  deleteFolder(
    { getState, patchState, dispatch }: StateContext<FolderStateModel>,
    { id }: DeleteFolder
  ) {
    patchState({ loading: true });
    return this.folderlSrv.delete(id).pipe(
      tap(() => {
        const { folders } = getState();
        const index = folders.findIndex((oldFolder) => oldFolder?.id === id);
        if (index >= 0) {
          folders.splice(index, 1);
        }
        patchState({ folders: [...folders] });
        dispatch(new FilterStartCopiedDeals(id));
      }),
      catchError((err) => {
        patchState({ loading: false });
        this.notificationService.showNotification(
          `Error when deleting a folder. ${err?.error?.error}`,
          NotificationType.Error
        );
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }

  @Action(GetAllFolders)
  getAllFolders(
    { patchState }: StateContext<FolderStateModel>,
    { limit, offset, onlyActiveFolders, sortModelItem, filterModel }: GetAllFolders
  ) {
    patchState({ loading: true });
    return this.folderlSrv
      .getAllWithParams(
        limit as number,
        offset as number,
        sortModelItem as SortModelItem,
        filterModel as FolderFilterModel[]
      )
      .pipe(
        tap((res) => {
          if (onlyActiveFolders) {
            patchState({
              activeFolders: res.folders,
              allFoldersCount: res.total_amount_of_folders,
            });
          } else {
            patchState({
              folders: res.folders,
              sidebarFolders: res.folders,
            });
          }
        }),
        catchError((err) => {
          patchState({ loading: false });
          return throwError(err);
        }),
        finalize(() => {
          patchState({ loading: false });
        })
      );
  }

  @Action(GetDealsByFolderID)
  getDealsByFolderID(
    { patchState }: StateContext<FolderStateModel>,
    { id, limit, offset, sortModelItem, filterModel /* type */ }: GetDealsByFolderID
  ) {
    patchState({ loading: true });
    return this.folderlSrv
      .getDealsByFolderID(
        id,
        limit as number,
        offset as number,
        sortModelItem as SortModelItem,
        filterModel as FolderFilterModel[]
        // type as VoyageType
      )
      .pipe(
        tap((res) => {
          patchState({
            dealsByFolderID: res.deals,
            allDealsByFolderIDCount: res.total_amount_of_deals,
          });
        }),
        catchError((err) => {
          patchState({ loading: false });
          return throwError(err);
        }),
        finalize(() => {
          patchState({ loading: false });
        })
      );
  }

  @Action(GetFoldersBySearch, { cancelUncompleted: true })
  getFoldersBySearch(
    { patchState }: StateContext<FolderStateModel>,
    { type, valueSearch, users, byFolderNameOnly }: GetFoldersBySearch
  ) {
    patchState({ loading: true });
    return this.folderlSrv.lightSearch(type, valueSearch, users, byFolderNameOnly).pipe(
      tap((res) => {
        patchState({
          sidebarFolders: res.folders,
        });
      }),
      catchError((err) => {
        patchState({ loading: false });
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }

  @Action(GetDealsByFolderIDSearch)
  getDealsByFolderIDSearch(
    { patchState }: StateContext<FolderStateModel>,
    { folderId, type /* valueSearch, users */ }: GetDealsByFolderIDSearch
  ) {
    patchState({ loading: true });
    return this.folderlSrv.lightSearchByFolderID(folderId, type /* valueSearch, users */).pipe(
      tap((res) => {
        patchState({
          dealsLightByFolderID: res.deals,
        });
      }),
      catchError((err) => {
        patchState({ loading: false });
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }

  @Action(CopyFolder)
  copyFolder(
    { patchState }: StateContext<FolderStateModel>,
    { folderId, copyFolderName, temporary, fixed, ids }: CopyFolder
  ) {
    patchState({ loading: true });
    return this.folderlSrv.copyFolder(folderId, copyFolderName || '', temporary, fixed, ids).pipe(
      tap((folder) => {
        patchState({ loading: false, copiedAlienFolder: folder });
      }),
      catchError((err) => {
        patchState({ loading: false });
        this.notificationService.showNotification(
          `Error when copy a folder. ${err?.error?.error}`,
          NotificationType.Error
        );
        return throwError(err);
      })
    );
  }

  @Action(OpenCopyFolderModal)
  openCopyFolderModal(
    { dispatch }: StateContext<FolderStateModel>,
    { copyFolderId, oldFolderName, typeFolder, dealIds }: OpenCopyFolderModal
  ) {
    const foundFolders = new Subject<Folder[]>();
    const copyFolder = new EventEmitter<string>();
    const searchFolder = new EventEmitter<string>();
    let dialogTitle = `Save folder "${oldFolderName}" as new`;
    if (dealIds) {
      dialogTitle = 'Folder name:';
    }
    const config: DynamicDialogConfig<CopyFolderDialogConfig> = {
      header: dialogTitle,
      data: {
        oldFolderName,
        copyFolder,
        searchFolder,
        foundFolders,
      },
      width: '500px',
    };
    searchFolder
      .pipe(
        tap((res) => {
          this.store
            .dispatch(new GetFoldersBySearch(typeFolder, res, '', true))
            .subscribe(({ folder }) => {
              const folderState = folder as FolderStateModel;
              foundFolders.next(folderState.sidebarFolders);
            });
        })
      )
      .subscribe();
    const copyFolderDialogRef = this.dialogService.open(CopyFolderDialogComponent, config);
    copyFolder
      .pipe(
        tap((newFolderName) => {
          let action;
          let notificationMessage = `Folder "${oldFolderName}" was copied to folder "${newFolderName}"`;
          if (dealIds) {
            notificationMessage = `Deal was copied to folder "${newFolderName}"`;
            action = new CopyFolder(copyFolderId, newFolderName, false, false, dealIds);
          } else {
            action = new CopyFolder(copyFolderId, newFolderName);
          }
          dispatch(action)
            .pipe(takeUntil(this._onDestroy$))
            .subscribe(
              () => {
                copyFolderDialogRef.close();
                this.notificationService.showNotification(
                  notificationMessage,
                  NotificationType.Success
                );
              },
              (err) => {
                this.notificationService.showNotification(
                  `Copy error. ${err?.error?.error}`,
                  NotificationType.Error
                );
              }
            );
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe();
  }

  @Action(SetDealsOrderInFolder, { cancelUncompleted: true })
  setDealsOrderInFolder(
    { patchState }: StateContext<FolderStateModel>,
    { folderId, dealIds }: SetDealsOrderInFolder
  ) {
    patchState({ loading: true });
    return this.folderlSrv.setDealsOrderInFolder(folderId, dealIds).pipe(
      tap(() => {
        patchState({ loading: false });
      }),
      catchError((err) => {
        patchState({ loading: false });
        this.notificationService.showNotification(
          `Error when set an order in a folder. ${err?.error?.error}`,
          NotificationType.Error
        );
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }

  @Action(SetFolderPinStatus, { cancelUncompleted: true })
  SetFolderPinStatus(
    { patchState }: StateContext<FolderStateModel>,
    { folderId, isPinned }: SetFolderPinStatus
  ) {
    patchState({ loading: true });
    return this.folderlSrv.setFolderPinStatus(folderId, isPinned).pipe(
      tap(() => {
        patchState({ loading: false });
      }),
      catchError((err) => {
        patchState({ loading: false });
        const pinOrUnpin = isPinned ? 'pin' : 'unpin';
        this.notificationService.showNotification(
          `Error when ${pinOrUnpin} folder. ${err?.error?.error}`,
          NotificationType.Error
        );
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }
}
