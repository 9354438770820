import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { getFilterEstimationsNavigationLinks } from '@estimator/helpers';
import {
  DealNavigationLink,
  FixFolderEmitter,
  Folder,
  NOT_APPLICABLE,
  SaveFixDialogConfig,
} from '@estimator/models';
import { isNumber } from 'lodash';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'estimator-fix-folder',
  templateUrl: './fix-folder.component.html',
  styleUrls: ['./fix-folder.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FixFolderComponent implements OnDestroy {
  @Output() saveFolder = new EventEmitter<FixFolderEmitter>();
  @Output() searchFolder = new EventEmitter<string>();
  folderControl = new FormControl();
  filteredFolders: Folder[] = [];
  // label?: string;

  estimationsNavigationLinks: DealNavigationLink[] = [];
  selectedDeals: DealNavigationLink[] = [];
  folderName?: string;

  private _onDestroy$ = new Subject<void>();

  constructor(
    private readonly dialogConfig: DynamicDialogConfig<SaveFixDialogConfig>,
    private readonly cdr: ChangeDetectorRef
  ) {
    if (this.dialogConfig.data) {
      const { saveFolder, foundFolders, estimationsNavigationLinks, folderName } =
        this.dialogConfig.data;
      if (estimationsNavigationLinks) {
        this.estimationsNavigationLinks = getFilterEstimationsNavigationLinks(
          estimationsNavigationLinks
        );
        foundFolders?.pipe(takeUntil(this._onDestroy$)).subscribe((folders) => {
          this.filteredFolders = folders;
          this.cdr.detectChanges();
        });
      }
      if (saveFolder) {
        this.saveFolder = saveFolder;
      }
      if (folderName) {
        this.folderName = `Fix ${folderName}`;
      }
    }
  }
  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  onSearchFolder(string: string): void {
    this.searchFolder.emit(string);
  }

  onCopyFolder(string: string): void {
    if (this.selectedDeals?.length) {
      const ids = this.selectedDeals.reduce((ids: number[], deal) => {
        if (isNumber(deal.id)) {
          ids.push(deal.id);
        }
        return ids;
      }, []);
      if (ids.length) {
        this.saveFolder.emit({
          folderId: this.selectedDeals[0]?.folderId || 0,
          folderName: string,
          ids,
        });
      }
    }
  }

  /*   onSaveFolder(): void {
    if (this.folderControl.value) {
      const folderControlValue = this.folderControl.value as Folder | string;
      const checkExistFolder = this.filteredFolders.find(
        (folder) =>
          folder.name.toLowerCase() ===
          (isString(folderControlValue) && !isObject(folderControlValue)
            ? folderControlValue.toLowerCase().trim()
            : folderControlValue.name.toLowerCase())
      );
      this.saveFolder.emit({
        folder: checkExistFolder ? checkExistFolder : this.folderControl.value.trim(),
        newFolder: !checkExistFolder ? true : false,
      });
    }
  } */

  showInitialCheckbox(deal: DealNavigationLink): boolean {
    return !!(deal.vesselName && deal.vesselName !== NOT_APPLICABLE);
  }

  showSelectedVesselCheckbox(deal: DealNavigationLink): boolean {
    return this.selectedDeals?.length ? this.selectedDeals[0]?.vesselId === deal?.vesselId : true;
  }
}
