<div class="flex flex-column gap-2">
  <div class="flex align-items-center gap-2 justify-content-between">
    <div *ngFor="let option of zoneTypesArr" class="flex align-items-center gap-2">
      <p-radioButton
        [inputId]="'shiplist_zone_type_' + option.value"
        [value]="option.value"
        [formControl]="zoneTypeControl"
      ></p-radioButton>
      <label [for]="'shiplist_zone_type_' + option.value" class="text-lg">{{ option.name }}</label>
    </div>
    <p-button
      id="save_filter"
      class="save-filter-button"
      size="small"
      [icon]="PrimeNgIcons.SAVE"
      pTooltip="Save zones and ports"
      (click)="saveFilter()"
    ></p-button>
  </div>
  <!-- zone search -->
  <div>
    <span class="text-lg font-bold">Select zone:</span>
    <p-autoComplete
      [dropdown]="true"
      [showEmptyMessage]="true"
      emptyMessage="No zones found"
      [formControl]="portControl"
      [suggestions]="filteredShiplistZonesArray"
      [forceSelection]="true"
      (completeMethod)="onSearchZone($event)"
      (onSelect)="onSelectZone($event)"
      appendTo="body"
      field="name"
      dataKey="id"
      class="p-inputtext-sm w-full"
      styleClass="w-full"
      inputStyleClass="w-full"
      [inputId]="'shiplist_zone'"
      #autoCompleteZone
    >
    </p-autoComplete>
  </div>

  <!-- port search -->
  <div>
    <span class="text-lg font-bold">Select port:</span>
    <!-- some double in estimator-events-form, vms-agreement-form -->
    <p-autoComplete
      [showEmptyMessage]="true"
      emptyMessage="No ports found"
      [formControl]="portControl"
      [suggestions]="ports"
      [forceSelection]="true"
      [minLength]="THREE_NUMBER"
      (completeMethod)="onSearchPort($event)"
      (onSelect)="onSelectPort($event)"
      appendTo="body"
      optionLabel="name"
      dataKey="id"
      class="p-inputtext-sm w-full"
      styleClass="w-full"
      inputStyleClass="w-full"
      [inputId]="'shiplist_port'"
    >
      <ng-template let-port pTemplate="item">
        <div class="flex content-space-between">
          <span
            ><span class="font-weight-700">{{ port?.name }}</span
            >&nbsp;/&nbsp;{{ port?.code }}&nbsp;&nbsp;</span
          >
          <span class="fi fi-{{ port.alpha_2?.toLowerCase() }}"></span>
        </div>
      </ng-template>
    </p-autoComplete>
  </div>
</div>
