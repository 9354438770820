import { Folder, FolderFilterModel, VoyageType } from '@estimator/models';
import { SortModelItem } from 'ag-grid-community';

export class GetFolder {
  static readonly type = '[FOLDER] Get folder';
  constructor(public id: number) {}
}
export class CreateFolder {
  static readonly type = '[FOLDER] Create folder';
  constructor(public folder: Folder) {}
}

export class UpdateFolder {
  static readonly type = '[FOLDER] Update folder';
  constructor(public folder: Folder) {}
}

export class DeleteFolder {
  static readonly type = '[FOLDER] Delete folder';
  constructor(public id: number) {}
}

export class GetAllFolders {
  static readonly type = '[FOLDER] Get all folders';
  constructor(
    public limit: number,
    public offset: number,
    public onlyActiveFolders: boolean,
    public sortModelItem?: SortModelItem,
    public filterModel?: FolderFilterModel[]
  ) {}
}

export class GetDealsByFolderID {
  static readonly type = '[FOLDER] Get deals by folder id';
  constructor(
    public id: number,
    public limit: number,
    public offset: number,
    public sortModelItem?: SortModelItem,
    public filterModel?: FolderFilterModel[]
  ) /**
   * new field, not implemented in deprecated estimator-estimations-directory
   */
  // public type?: VoyageType
  {}
}

export class GetFoldersBySearch {
  static readonly type = '[FOLDER] Get folders by search';
  constructor(
    public type: VoyageType,
    public valueSearch: string,
    public users: string,
    public byFolderNameOnly?: boolean
  ) {}
}

export class GetDealsByFolderIDSearch {
  static readonly type = '[FOLDER] Get deals by folder ID search';
  constructor(
    public folderId: number,
    public type: VoyageType /*  public valueSearch: string,
    public users: string */
  ) {}
}

export class CopyFolder {
  static readonly type = '[FOLDER] Copy folder';
  constructor(
    public folderId: number,
    public copyFolderName?: string,
    public temporary?: boolean,
    public fixed?: boolean,
    public ids?: number[]
  ) {}
}

export class OpenCopyFolderModal {
  static readonly type = '[FOLDER] Open copy folder modal';
  constructor(
    public copyFolderId: number,
    public oldFolderName: string,
    public typeFolder: VoyageType,
    public dealIds?: number[]
  ) {}
}

export class SetDealsOrderInFolder {
  static readonly type = '[FOLDER] Set deals order in folder';
  constructor(public folderId: number, public dealIds: number[]) {}
}

export class SetFolderPinStatus {
  static readonly type = '[FOLDER] Set folder pin status';
  constructor(public folderId: number, public isPinned: boolean) {}
}
