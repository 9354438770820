import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import {
  CompanySetting,
  CompanySettingFormGroup,
  DomEvents,
  MAX_FINANCE_VALUE,
} from '@estimator/models';
import { cloneDeep } from 'lodash';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'estimator-deductibles-list',
  templateUrl: './deductibles-list.component.html',
  styleUrls: ['./deductibles-list.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeductiblesListComponent implements OnDestroy {
  @Input()
  set deductibles(data: CompanySetting[]) {
    this.deductiblesFormArray.clear({ emitEvent: false });
    if (data.length) {
      data.forEach((deductible) => {
        const fg = this.makeCompanySettingFormGroup(deductible);
        fg?.controls?.deductibles?.valueChanges
          .pipe(takeUntil(this._onDestroy$))
          .subscribe((newDeductibles) => {
            const oldDeductibles = fg.value.deductibles;
            if (oldDeductibles !== newDeductibles && newDeductibles) {
              this.changeDeductibles.emit(
                this.deductiblesFormArray.getRawValue() as CompanySetting[]
              );
            }
          });
        this.deductiblesFormArray.push(fg);
      });
      this._deductibles = cloneDeep(data);
    }
  }
  get deductibles(): CompanySetting[] {
    return this._deductibles;
  }

  @Output() changeDeductibles = new EventEmitter<CompanySetting[]>();

  deductiblesFormArray: FormArray<FormGroup<CompanySettingFormGroup>> = new FormArray<
    FormGroup<CompanySettingFormGroup>
  >([]);
  tabIndex = 200;
  readonly MAX_FINANCE_VALUE = MAX_FINANCE_VALUE;
  private _deductibles: CompanySetting[] = [];
  private _onDestroy$ = new Subject<void>();

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  makeCompanySettingFormGroup(setting?: CompanySetting): FormGroup<CompanySettingFormGroup> {
    const fg = new FormGroup<CompanySettingFormGroup>(
      {
        type: new FormControl({
          value: setting?.type || null,
          disabled: true,
        }),
        deductibles: new FormControl(setting?.deductibles || null),
        min_dwt: new FormControl({
          value: setting?.min_dwt || 0,
          disabled: true,
        }),
        max_dwt: new FormControl({
          value: setting?.max_dwt || null,
          disabled: true,
        }),
      },
      { updateOn: DomEvents.Blur }
    );
    return fg;
  }
}
