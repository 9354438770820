import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonStatusLabel, FULL_PERCENTS, THOUSAND, User } from '@estimator/models';
import {
  Agreement,
  AgreementFormGroup,
  AgreementHirePeriod,
  AgreementHirePeriodFormGroup,
  AgreementType,
  Bunkering,
  BunkeringFormGroup,
  BunkeringPaymentType,
  CVEType,
  cveTypesArr,
  ExchangeRateMode,
  HSOA,
  HSOABunkering,
  HSOABunkeringFormGroup,
  HSOAExpenses,
  HSOAExpensesFormGroup,
  HSOAFormGroup,
  HSOAOffHireFormGroup,
  HSOAOffsetFormGroup,
  HSOAStatus,
  LaytimeEventType,
  LaytimeStatus,
  LaytimeTimeLine,
  LaytimeTimeLineSchedule,
  OffHirePeriod,
  OffsetPeriod,
  PaymentsFormGroup,
  StatusType,
  SystemFolderType,
  TransactionPayment,
  VMSNodeType,
  VMSTransactionStatus,
  VMSTransactionSubType,
  VMSTransactionType,
  VMSTreeNode,
  VSOA,
  VSOAFormGroup,
  VSOATypesFormGroup,
  VSOAUserType,
  VsoaViewType,
} from '@vms-models';
import moment from 'moment';
import { PrimeIcons, TreeNode } from 'primeng/api';
import { getDaysDiff, getDaysNumberDiff } from './date-time-helpers';
import { makeBaseEntityPartFormGroup, prefilledFileTreeModel } from './ui-helpers';
import { notStringValidator } from './validators';

export const getUserFullName = (user?: User) => {
  return user ? `${user.first_name} ${user.last_name}` : 'N/A';
};

export const HANDOVER_MODAL_CLASS = 'handover-modals';

export const isAgreementTopLevel = (type: AgreementType | null | undefined) => {
  return type === AgreementType.TCIN || type === AgreementType.TCT;
};

export const isAgreementSubLevel = (type: AgreementType | null | undefined) => {
  return type === AgreementType.VC || type === AgreementType.TCOUT;
};

export const isAgreement_Own_Operational = (type: AgreementType | null | undefined) => {
  return type === AgreementType.OWN || type === AgreementType.OPERATIONAL;
};

export const isAgreementVR = (type: AgreementType | null | undefined) => {
  return type === AgreementType.VR;
};

export const isAgreementTCOUT = (type: AgreementType | null | undefined) => {
  return type === AgreementType.TCOUT;
};

export const isAgreement_Own_Operational_VR = (type: AgreementType | null | undefined) => {
  return isAgreement_Own_Operational(type) || type === AgreementType.VR;
};

export const isShowTwoNodeFiles = (type: AgreementType | null | undefined): boolean => {
  const types: AgreementType[] = [
    AgreementType.TCIN,
    AgreementType.TCT,
    AgreementType.TCOUT,
    AgreementType.OPERATIONAL,
  ];
  if (type) {
    return types.includes(type);
  }
  return false;
};

export const getSystemFolderTypeForSub = (
  type: AgreementType | null | undefined,
  addCR = false
):
  | SystemFolderType.RECAPS_OR_CERTIFICATES_SUB
  | SystemFolderType.RECAP_OWNER
  | SystemFolderType.RECAP_CHARTERER => {
  return type === AgreementType.VR
    ? addCR
      ? SystemFolderType.RECAP_CHARTERER
      : SystemFolderType.RECAP_OWNER
    : SystemFolderType.RECAPS_OR_CERTIFICATES_SUB;
};

export const mapHsoaStatus = (status: HSOAStatus) => {
  switch (status) {
    case HSOAStatus.Pending:
      return CommonStatusLabel.PENDING;
    case HSOAStatus.Fixed:
      return CommonStatusLabel.FIXED;
    case HSOAStatus.Draft:
    default:
      return CommonStatusLabel.DRAFT;
  }
};

export const mapHsoaTagColor = (status: HSOAStatus) => {
  switch (status) {
    case HSOAStatus.Pending:
      return 'warning';
    case HSOAStatus.Fixed:
      return 'success';
    case HSOAStatus.Draft:
    default:
      return 'info';
  }
};

export const mapVsoaStatus = (status: HSOAStatus) => {
  switch (status) {
    case HSOAStatus.Pending:
      return 'Checking';
    case HSOAStatus.Fixed:
      return 'Finished';
    case HSOAStatus.Draft:
    default:
      return 'Created';
  }
};

export const mapTransactionStatus = (status: VMSTransactionStatus) => {
  switch (status) {
    case VMSTransactionStatus.PAID:
      return 'Paid';
    case VMSTransactionStatus.PENDING:
      return CommonStatusLabel.PENDING;
    case VMSTransactionStatus.PLANNED:
      return 'Planned';
    case VMSTransactionStatus.CONFIRMED:
      return 'Confirmed';
    case VMSTransactionStatus.INVOICED:
      return 'Invoiced';
    case VMSTransactionStatus.DRAFT:
      return CommonStatusLabel.DRAFT;
    default:
      return 'Overdue';
  }
};

export const mapTransactionStatusColor = (status: VMSTransactionStatus) => {
  switch (status) {
    case VMSTransactionStatus.PAID:
      return 'success';
    case VMSTransactionStatus.OVERDUE:
      return 'danger';
    case VMSTransactionStatus.CONFIRMED:
    case VMSTransactionStatus.INVOICED:
      return 'warning';
    case VMSTransactionStatus.DRAFT:
      return 'info';
    case VMSTransactionStatus.PENDING:
    case VMSTransactionStatus.PLANNED:
    default:
      return 'primary';
  }
};

export const getAvailableSubType = (type: VMSTransactionType) => {
  switch (type) {
    case VMSTransactionType.BROKERAGE:
      return [VMSTransactionSubType.COMISSION_ON_TCP, VMSTransactionSubType.COMISSION_ON_VCP];
    case VMSTransactionType.DISBURSEMENTS:
      return [
        VMSTransactionSubType.PDA_AT_DISCHARGE_PORT,
        VMSTransactionSubType.PDA_AT_LOAD_PORT,
        VMSTransactionSubType.PDA_AT_PANAMA_CANAL,
        VMSTransactionSubType.PDA_AT_SUEZ_CANAL,
        VMSTransactionSubType.PDA_AT_TURKISH_STRAITS,
        VMSTransactionSubType.PDA_BUNKERING_PORT,
        VMSTransactionSubType.PDA_OTHERS,
        VMSTransactionSubType.FDA_AT_DISCHARGE_PORT,
        VMSTransactionSubType.FDA_AT_LOAD_PORT,
        VMSTransactionSubType.FDA_AT_PANAMA_CANAL,
        VMSTransactionSubType.FDA_AT_SUEZ_CANAL,
        VMSTransactionSubType.FDA_AT_TURKISH_STRAITS,
        VMSTransactionSubType.FDA_BUNKERING_PORT,
        VMSTransactionSubType.FDA_OTHERS,
        VMSTransactionSubType.PILOTAGE,
        VMSTransactionSubType.TOWAGE,
      ];
    case VMSTransactionType.BUNKERING:
      return [VMSTransactionSubType.BUNKER_SUPPLY];
    case VMSTransactionType.FREIGHT:
      return [
        VMSTransactionSubType.FREIGHT,
        VMSTransactionSubType.VSOA,
        VMSTransactionSubType.OTHER,
      ];
    case VMSTransactionType.HIRE:
      return [
        VMSTransactionSubType.FIFTH,
        VMSTransactionSubType.FIRST,
        VMSTransactionSubType.SECOND,
        VMSTransactionSubType.THIRD,
        VMSTransactionSubType.FOURTH,
        VMSTransactionSubType.SIXTH,
        VMSTransactionSubType.SEVENTH,
        VMSTransactionSubType.EIGHTH,
        VMSTransactionSubType.NINTH,
        VMSTransactionSubType.TENTH,
        VMSTransactionSubType.FINAL,
        VMSTransactionSubType.PRE_FINAL,
      ];
    case VMSTransactionType.INSURANCE:
      return [
        VMSTransactionSubType.PREMIUM_FDD,
        VMSTransactionSubType.PREMIUM_P_I,
        VMSTransactionSubType.LEGAL_FEES,
      ];
    case VMSTransactionType.OTHER_EXPENSES:
      return [
        VMSTransactionSubType.SURVEY,
        VMSTransactionSubType.UWHI_C,
        VMSTransactionSubType.OCEAN_ROUTING,
        VMSTransactionSubType.OTHER,
      ];
    default:
      return [];
  }
};
export const makeHSOAFormGroup = (hsoa?: HSOA): FormGroup<HSOAFormGroup> => {
  const endDate = new Date(hsoa?.end_date || 0);
  const startDate = new Date(hsoa?.start_date || 0);
  const days = getDaysDiff(endDate, startDate);
  const daysNumber = getDaysNumberDiff(endDate, startDate);
  return new FormGroup<HSOAFormGroup>({
    ...makeBaseEntityPartFormGroup(hsoa).controls,
    hire_rate: new FormControl(hsoa?.hire_rate || null, Validators.required),
    start_date: new FormControl(moment(hsoa?.start_date).toDate() || null, Validators.required),
    end_date: new FormControl(moment(hsoa?.end_date).toDate() || null, Validators.required),
    days: new FormControl({ value: days || null, disabled: true }),
    days_number: new FormControl({ value: daysNumber || 0, disabled: false }),
    commission: new FormControl((hsoa?.commission || 0) * FULL_PERCENTS || null),
    cve_rate: new FormControl(hsoa?.cve_rate || null),
    cve_type: new FormControl(hsoa?.cve_type || null),
    exchange_rate: new FormControl(hsoa?.exchange_rate || null),
    currency: new FormControl(hsoa?.currency || null),
    deduct_bunkerings_on_delivery: new FormControl(hsoa?.deduct_bunkerings_on_delivery || false),
    deduct_bunkerings_on_redelivery: new FormControl(
      hsoa?.deduct_bunkerings_on_redelivery || false
    ),
    offhire_periods: new FormArray<FormGroup<HSOAOffHireFormGroup>>([]),
    bunkerings_on_delivery: new FormArray<FormGroup<BunkeringFormGroup>>([]),
    bunkerings_on_redelivery: new FormArray<FormGroup<BunkeringFormGroup>>([]),
    owners_expenses: new FormArray<FormGroup<HSOAExpensesFormGroup>>([]),
    charterers_expenses: new FormArray<FormGroup<HSOAExpensesFormGroup>>([]),
    exchange_rate_commission: new FormControl(
      (hsoa?.exchange_rate_commission || 0) * FULL_PERCENTS || null
    ),
    exchange_rate_mode: new FormControl(hsoa?.exchange_rate_mode || ExchangeRateMode.AUTO),
  });
};

export const makeVSOAFormGroup = (vsoa?: VSOA): FormGroup<VSOAFormGroup> => {
  return new FormGroup<VSOAFormGroup>({
    ...makeBaseEntityPartFormGroup(vsoa).controls,
    date: new FormControl(vsoa?.date ? new Date(vsoa?.date) : new Date(), Validators.required),
    charter_party_date: new FormControl(
      vsoa?.charter_party_date ? new Date(vsoa?.charter_party_date) : new Date(),
      Validators.required
    ),
    bill_of_lading_date: new FormControl(
      vsoa?.bill_of_lading_date ? new Date(vsoa?.bill_of_lading_date) : new Date()
    ),
    company_bank_detail_id: new FormControl(
      vsoa?.company_bank_detail_id || null,
      Validators.required
    ),
    charterers: new FormControl(vsoa?.charterers || null, Validators.required),
    cargo_name: new FormControl(vsoa?.cargo_name || null, Validators.required),
    cargo_dimension: new FormControl(vsoa?.cargo_dimension || null, Validators.required),
    cargo_quantity: new FormControl(vsoa?.cargo_quantity || null, Validators.required),
    loading_port: new FormControl(vsoa?.loading_port || null, Validators.required),
    loading_port_id: new FormControl(vsoa?.loading_port_id || null, Validators.required),
    cargo_price_per_unit: new FormControl(vsoa?.cargo_price_per_unit || null),
    discharging_port: new FormControl(vsoa?.discharging_port || null),
    payment_due_date: new FormControl(
      vsoa?.payment_due_date ? new Date(vsoa?.payment_due_date) : new Date()
    ),
    currentTab: new FormControl(VsoaViewType.CARGO),
    exchange_rate: new FormControl(vsoa?.exchange_rate || null),
    currency: new FormControl(vsoa?.currency || null),
    broker_commission_type: new FormControl(vsoa?.broker_commission_type || null),
    broker_commission: new FormControl((vsoa?.broker_commission || 0) * FULL_PERCENTS || null),
    laytime_commission: new FormControl((vsoa?.laytime_commission || 0) * FULL_PERCENTS || null),
    cargo_lump_sum: new FormControl(vsoa?.cargo_lump_sum || null),
    commission: new FormControl((vsoa?.commission || 0) * FULL_PERCENTS || null),
    user_fields: new FormArray<FormGroup<VSOATypesFormGroup>>([]),
    exchange_rate_commission: new FormControl(
      (vsoa?.exchange_rate_commission || 0) * FULL_PERCENTS || null
    ),
    exchange_rate_mode: new FormControl(vsoa?.exchange_rate_mode || ExchangeRateMode.AUTO),
  });
};

export const makeVSOAUserTypeFormGroup = (
  user_field?: VSOAUserType
): FormGroup<VSOATypesFormGroup> => {
  return new FormGroup<VSOATypesFormGroup>({
    title: new FormControl(user_field?.title || null, Validators.required),
    value: new FormControl(user_field?.value || null, Validators.required),
  });
};

export const makeHSOABunkeringFormGroup = (
  bunkering?: HSOABunkering
): FormGroup<HSOABunkeringFormGroup> => {
  return new FormGroup<HSOABunkeringFormGroup>({
    fuel_id: new FormControl(bunkering?.fuel_id || null, Validators.required),
    fuel_name: new FormControl(bunkering?.fuel_name || null, Validators.required),
    price: new FormControl(bunkering?.price || null, Validators.required),
    quantity: new FormControl(bunkering?.quantity || null, Validators.required),
    amount: new FormControl(bunkering?.amount || null),
  });
};

export const makeHSOAExpensesFormGroup = (
  expenses?: HSOAExpenses
): FormGroup<HSOAExpensesFormGroup> => {
  return new FormGroup<HSOAExpensesFormGroup>({
    type: new FormControl(expenses?.type || null, Validators.required),
    description: new FormControl(expenses?.description || null, Validators.required),
    commission: new FormControl((expenses?.commission || 0) * FULL_PERCENTS || null),
    commission_amount: new FormControl(expenses?.commission_amount || null),
    amount: new FormControl(expenses?.amount || null),
  });
};

export const makeHSOAOffHireFormGroup = (
  offhire?: OffHirePeriod
): FormGroup<HSOAOffHireFormGroup> => {
  const endDate = new Date(offhire?.end || 0);
  const startDate = new Date(offhire?.start || 0);
  const days = getDaysDiff(endDate, startDate);
  const daysNumber = getDaysNumberDiff(endDate, startDate);
  const offsets = offhire?.offset_periods?.map((period) => makeHSOAOffsetFormGroup(period)) || [];
  return new FormGroup<HSOAOffHireFormGroup>({
    hire_rate: new FormControl(offhire?.hire_rate || null, Validators.required),
    hire_debit: new FormControl(offhire?.hire_debit || null),
    start: new FormControl(moment(offhire?.start).toDate() || null, Validators.required),
    end: new FormControl(moment(offhire?.end).toDate() || null, Validators.required),
    offset_periods: new FormArray<FormGroup<HSOAOffsetFormGroup>>(offsets || []),
    arrayOffset: new FormControl(offhire?.offset_periods || []),
    days: new FormControl({ value: days || null, disabled: true }),
    days_number: new FormControl({ value: daysNumber || 0, disabled: false }),
  });
};

export const makeHSOAOffsetFormGroup = (offset?: OffsetPeriod): FormGroup<HSOAOffsetFormGroup> => {
  const endDate = new Date(offset?.end || 0);
  const startDate = new Date(offset?.start || 0);
  const days = getDaysDiff(endDate, startDate);
  const daysNumber = getDaysNumberDiff(endDate, startDate);
  return new FormGroup<HSOAOffsetFormGroup>({
    hire_rate: new FormControl(offset?.hire_rate || null, Validators.required),
    hire_credit: new FormControl(offset?.hire_credit || null),
    commission_debit: new FormControl(offset?.commission_debit || null),
    commission: new FormControl(offset?.commission || null),
    start: new FormControl(moment(offset?.start).toDate() || null, Validators.required),
    end: new FormControl(moment(offset?.end).toDate() || null, Validators.required),
    days: new FormControl({ value: days || null, disabled: true }),
    days_number: new FormControl({ value: daysNumber || 0, disabled: false }),
  });
};

export const makePaymentsFormGroup = (
  payment?: TransactionPayment
): FormGroup<PaymentsFormGroup> => {
  return new FormGroup<PaymentsFormGroup>({
    exchange_rate_amount_to_base: new FormControl(
      payment?.exchange_rate_amount_to_base || null,
      Validators.required
    ),
    cf_transaction_id: new FormControl(payment?.cf_transaction_id || 0),
    id: new FormControl(payment?.id || null),
    currency_id: new FormControl(payment?.currency_id || null, Validators.required),
    payment_date: new FormControl(
      moment(payment?.payment_date).toDate() || null,
      Validators.required
    ),
    amount: new FormControl(payment?.amount || null, Validators.required),
  });
};

export const calculateDuration = (
  startDate?: FormControl,
  endDate?: FormControl,
  daysNumber?: FormControl,
  updateDuration = false
): void => {
  const startDateValue = startDate?.value;
  if (updateDuration) {
    // update duration days field
    const endDateValue = endDate?.value;
    const daysNumberValue = getDaysNumberDiff(endDateValue, startDateValue);
    daysNumber?.patchValue(daysNumberValue);
  } else {
    // update end date
    const daysDur = moment.duration(daysNumber?.value || 0, 'days');
    const minutesDur = daysDur.asMinutes();
    endDate?.patchValue(moment(startDateValue).add(minutesDur, 'minute').toDate());
  }
};

export const makeAgreementFormGroup = (agreement?: Agreement): FormGroup<AgreementFormGroup> => {
  console.log('makeAgreementFormGroup agreement', agreement);
  return new FormGroup<AgreementFormGroup>({
    ...makeBaseEntityPartFormGroup(agreement).controls,
    name: new FormControl(agreement?.name || null, Validators.required),
    agreement_type: new FormControl(agreement?.agreement_type || AgreementType.VC),
    date: new FormControl(
      agreement?.date ? new Date(agreement?.date) : new Date(),
      Validators.required
    ),
    owner: new FormControl(agreement?.owner || null, {
      validators: [Validators.required],
      asyncValidators: [notStringValidator()],
    }),
    owner_id: new FormControl(agreement?.owner_id || null, Validators.required),
    /**
     * For CR(Cargo Relet) = Cargo owner
     */
    charterer_1: new FormControl(agreement?.charterer_1 || null),
    charterer_1_id: new FormControl(agreement?.charterer_1_id || null),
    charterer_2: new FormControl(agreement?.charterer_2 || null),
    charterer_2_id: new FormControl(agreement?.charterer_2_id || null),
    voyage_owner_date: new FormControl(
      agreement?.voyage_owner_date ? new Date(agreement?.voyage_owner_date) : new Date()
    ),
    voyage_owner_description: new FormControl(agreement?.voyage_owner_description || null),
    hire_agreement: new FormControl(null, Validators.required), // for show-invalid
    hire_agreement_id: new FormControl(agreement?.hire_agreement_id || null, Validators.required), // required for AgreementType.VC || AgreementType.TCOUT (default agreement_type = AgreementType.VC)
    description: new FormControl(agreement?.description || null),
    parsed_vessel: new FormControl(agreement?.parsed_vessel || null, Validators.required),
    parsed_vessel_id: new FormControl(
      agreement?.parsed_vessel_id || null /* Validators.required */
    ),
    file_tree: new FormControl(
      agreement?.file_tree || prefilledFileTreeModel(agreement?.agreement_type || AgreementType.VC)
    ),
    /**
     * edit in estimator + edit in vms for bottom_type
     */
    related_deal_id: new FormControl(
      agreement?.related_deal_id || null,
      Validators.required
      /* isTopLevel ? null : Validators.required */
    ),
    /**
     * only for front, dropdown related_deal
     */
    related_deal: new FormControl(
      agreement?.related_deal || null,
      Validators.required
      /* isTopLevel ? null : Validators.required */
    ),
  });
};

export const makeAgreementEditFormGroup = (
  isTopLevel: boolean,
  agreement?: Agreement
): FormGroup<AgreementFormGroup> => {
  return new FormGroup<AgreementFormGroup>({
    ...makeAgreementFormGroup(agreement).controls,
    /*  hire_period_minutes: new FormControl(
      (agreement?.hire_period_minutes || 0) / MINUTES_IN_DAY || null,
      isTopLevel ? Validators.required : null
    ),
    pay_period_minutes: new FormControl(
      (agreement?.pay_period_minutes || 0) / MINUTES_IN_DAY || null,
      isTopLevel ? Validators.required : null
    ), */
    cp_durarion_days: new FormControl(agreement?.cp_durarion_days || 0 || null),
    /*     hire_rate: new FormControl(
      agreement?.hire_rate || null,
      isTopLevel ? Validators.required : null
    ), */
    /*     tc_commission: new FormControl(
      agreement?.tc_commission || null,
      isTopLevel ? Validators.required : null
    ), */
    /*   cve_rate: new FormControl(agreement?.cve_rate || null, isTopLevel ? Validators.required : null),
    cve_type: new FormControl(agreement?.cve_type || CVEType.PerYearProRata), */
    // deprecated
    /*     cve_period_minutes: new FormControl(
      (agreement?.cve_period_minutes || 0) / MINUTES_IN_DAY || null,
      isTopLevel ? Validators.required : null
    ), */
    // deprecated
    /*     is_cve_period_month: new FormControl(
      agreement?.is_cve_period_month || false,
      isTopLevel ? Validators.required : null
    ), */
    delivery_port: new FormControl(
      agreement?.delivery_port || null,
      isTopLevel ? Validators.required : null
    ),
    delivery_port_id: new FormControl(
      agreement?.delivery_port_id || null,
      isTopLevel ? Validators.required : null
    ),
    redelivery_ports: new FormControl(
      agreement?.redelivery_ports || null,
      isTopLevel ? Validators.required : null
    ),
    bunkering_payment_type: new FormControl(
      agreement?.bunkering_payment_type || BunkeringPaymentType.BunkeringPaymentTypeOnDelivery,
      isTopLevel ? Validators.required : null
    ),
    status: new FormControl(agreement?.status || StatusType.DRAFT),
    /**
     * edit in vms-agreement-bunkerings, own fg
     */
    bunkerings: new FormArray<FormGroup<BunkeringFormGroup>>([]),
    pay_on_delivery: new FormControl(agreement?.pay_on_delivery || false),
    voyage_number: new FormControl(
      agreement?.voyage_number || null
      /*  isTopLevel ? null : Validators.required */
    ),
    /**
     * edit only in estimator, not edit in vms
     */
    related_folder_id: new FormControl(agreement?.related_folder_id || null),
    /**
     * values from back, not edit on front
     */
    created_by_company_id: new FormControl(agreement?.created_by_company_id || null),
    operator_ids: new FormControl(agreement?.operator_ids || []),
  });
};

export const makeBunkeringEditFormGroup = (
  agreementId: number,
  bunkering?: Bunkering
): FormGroup<BunkeringFormGroup> => {
  return new FormGroup<BunkeringFormGroup>({
    agreement_id: new FormControl(bunkering?.agreement_id || agreementId, Validators.required),
    fuel_id: new FormControl(bunkering?.fuel_id || null, Validators.required),
    fuel_name: new FormControl(bunkering?.fuel_name || null, Validators.required),
    quantity_on_delivery: new FormControl(
      bunkering?.quantity_on_delivery || null,
      Validators.required
    ),
    price_on_delivery: new FormControl(bunkering?.price_on_delivery || null, Validators.required),
    quantity_on_redelivery: new FormControl(
      bunkering?.quantity_on_redelivery || null,
      Validators.required
    ),
    price_on_redelivery: new FormControl(
      bunkering?.price_on_redelivery || null,
      Validators.required
    ),
  });
};

export const makeHirePeriodEditFormGroup = (
  hirePeriod?: AgreementHirePeriod
): FormGroup<AgreementHirePeriodFormGroup> => {
  return new FormGroup<AgreementHirePeriodFormGroup>({
    hire_period_minutes: new FormControl(
      hirePeriod?.hire_period_minutes || null,
      Validators.required
    ),
    pay_period_minutes: new FormControl(
      hirePeriod?.pay_period_minutes || null,
      Validators.required
    ),
    cve_rate: new FormControl(hirePeriod?.cve_rate || null, Validators.required),
    /*     cve_period_minutes: new FormControl(
      hirePeriod?.cve_period_minutes || null,
      Validators.required
    ), */
    cve_type: new FormControl(hirePeriod?.cve_type || CVEType.PerYearProRata, Validators.required),
    tc_commission: new FormControl(hirePeriod?.tc_commission || 0, Validators.required),
    hire_rate: new FormControl(hirePeriod?.hire_rate || null, Validators.required),
  });
};

export const getDraftSubAgreements = (agreement: Agreement): Agreement[] => {
  const agreements = agreement?.sub_agreements?.filter(
    (agreement) => agreement?.status === StatusType.DRAFT
  );
  return agreements || [];
};

export const fromBytesToKB = (size: number): string => {
  return Math.round(size / THOUSAND) + ' KB';
};

/**
 * + type Demurrage
 */
export const laytimeEventTypeSOF = (type: LaytimeEventType): boolean => {
  const eventTypes = [
    LaytimeEventType.Arrival,
    LaytimeEventType.NOR,
    LaytimeEventType.LoadStart,
    LaytimeEventType.LoadComplete,
    LaytimeEventType.Departure,
    LaytimeEventType.Demurrage,
    LaytimeEventType.LaytimeCommenced,
    LaytimeEventType.LaytimeStopped,
  ];

  return eventTypes.includes(type);
};

export const parseTreeNodeToView = (inputNode: VMSTreeNode): TreeNode => {
  const node: TreeNode = {
    key: inputNode.document_id?.toString() || (Math.random() * 1000000).toLocaleString(),
    label: inputNode.name,
    data: { type: inputNode.type, document_id: inputNode.document_id },
    droppable: inputNode.type !== VMSNodeType.USER_FILE,
    draggable: inputNode.type !== VMSNodeType.SYSTEM_FOLDER,
    icon:
      inputNode.type === VMSNodeType.USER_FILE
        ? `pi-fw ${PrimeIcons.FILE}`
        : `pi-fw ${PrimeIcons.FOLDER}`,
  };

  if (inputNode.children && inputNode.children.length > 0) {
    node.children = inputNode.children.map((child) => parseTreeNodeToView(child));
  }

  return node;
};

export const getCargoNames = (laytimeTimeLine: LaytimeTimeLine): string[] | string => {
  const arr: string[] = [];
  if (laytimeTimeLine.cargoes?.length) {
    for (const canal of laytimeTimeLine.cargoes) {
      arr.push(' ' + canal.name);
    }
  }
  return arr?.length ? arr : '';
};

export const chartererExpenses = [
  'ILOHC',
  'IHC',
  'AWRP',
  'ARMED GUARDS',
  'CREW BONUS',
  'ON-HIRE SURVEY',
  'OFF-HIRE SURVEY',
  'P&I SURVEY',
  'IN LIEU OF HULL CLEANING',
  'STEVEDORE DAMAGE',
  'LEGAL EXPENSES',
  'FUEL ANALYSIS',
];

export const ownersExpenses = [
  'CTM',
  'ON-HIRE SURVEY',
  'OFF-HIRE SURVEY',
  'U/W INSPECTION',
  'PORT EXPENSES',
  'FUEL ANALYSIS',
];

export const getNewDateWithZeroTime = (): Date => {
  const now = new Date();
  const dateWithZeroTime = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  return dateWithZeroTime;
};

export const createScheduleWithZeroTime = (): LaytimeTimeLineSchedule => {
  return {
    arrival_time: getNewDateWithZeroTime(),
    nor: getNewDateWithZeroTime(),
    load_start: getNewDateWithZeroTime(),
    load_complete: getNewDateWithZeroTime(),
    departure_time: getNewDateWithZeroTime(),
    laytime_commenced: getNewDateWithZeroTime(),
    laytime_stopped: getNewDateWithZeroTime(),
  };
};

export const mapLaytimeStatus = (status: LaytimeStatus) => {
  switch (status) {
    case LaytimeStatus.Pending:
      return CommonStatusLabel.PENDING;
    case LaytimeStatus.Fixed:
      return CommonStatusLabel.FIXED;
    case LaytimeStatus.AgreedWithCharterer:
      return 'Agreed with charterer';
    // default === LaytimeStatus.Draft
    default:
      return CommonStatusLabel.DRAFT;
  }
};

/** field from Agreement hire_period_minutes */
export const HIRE_DURATION_DAYS_TEXT = 'Hire duration, days';

/** field from Agreement pay_period_minutes || field hsoaFormGroup.controls.days_number */
export const HIRE_INSTALLMENT_DAYS_TEXT = 'Hire installment, days';

export const getCveTypeValue = (hirePeriod: AgreementHirePeriod): string => {
  return cveTypesArr.find((value) => value.value === hirePeriod.cve_type)?.name || '';
};
