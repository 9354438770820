import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AgGridModule } from 'ag-grid-angular';
import { DisbursementFinanceListComponent } from './disbursement-finance-list.component';

@NgModule({
  declarations: [DisbursementFinanceListComponent],
  imports: [AgGridModule, CommonModule],
  exports: [DisbursementFinanceListComponent],
})
export class DisbursementFinanceListModule {}
