import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AgGridModule } from 'ag-grid-angular';
import { BreakEvenGridComponent } from './break-even-grid.component';

@NgModule({
  declarations: [BreakEvenGridComponent],
  imports: [CommonModule, AgGridModule],
  exports: [BreakEvenGridComponent],
})
export class BreakEvenGridModule {}
