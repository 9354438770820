import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AgGridModule } from 'ag-grid-angular';
import { AgGridSharedModule } from '../ag-grid-shared/ag-grid-shared.module';
import { FleetVesselCollectionComponent } from './fleet-vessel-collection.component';

@NgModule({
  declarations: [FleetVesselCollectionComponent],
  imports: [AgGridModule, CommonModule, AgGridSharedModule],
  exports: [FleetVesselCollectionComponent],
})
export class FleetVesselCollectionModule {}
