<p-table
  [value]="eventsFormArray.controls"
  [resizableColumns]="true"
  class="primeng-form w-100"
  styleClass="p-datatable-sm font-size-12"
  id="itinerary_form"
>
  <ng-template pTemplate="header">
    <tr>
      <th pResizableColumn class="w-auto text-center">Port</th>
      <th pResizableColumn class="w-date-time-input text-center">
        Arrival {{ addTimeSettings() }}
      </th>
      <th pResizableColumn class="w-date-time-input text-center">
        Departure {{ addTimeSettings() }}
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-eventForm let-i="rowIndex">
    <tr class="primeng-form">
      <td style="text-align: left">
        <span
          class="mr-2 fi fi-{{ eventForm.controls?.port?.value?.alpha_2?.toLowerCase() }}"
        ></span>
        <span>{{ getPortTitle(eventForm) }}</span>
      </td>
      <td>
        <p-calendar
          [formControl]="eventForm.controls?.start_time"
          [showOnFocus]="false"
          [showTime]="true"
          [inputId]="'itinerary_form-start_time-' + i"
          styleClass="w-100"
          inputStyleClass="w-100"
          class="p-inputtext-sm w-100"
          [dateFormat]="dateFormat"
          appendTo="body"
          [tabindex]="tabIndex"
        ></p-calendar>
      </td>
      <td>
        <p-calendar
          [formControl]="eventForm.controls?.end_time"
          [showOnFocus]="false"
          [showTime]="true"
          [inputId]="'itinerary_form-end_time-' + i"
          styleClass="w-100"
          inputStyleClass="w-100"
          class="p-inputtext-sm w-100"
          [dateFormat]="dateFormat"
          appendTo="body"
          [tabindex]="tabIndex"
        ></p-calendar>
      </td>
    </tr>
  </ng-template>
</p-table>
