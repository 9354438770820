import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  FleetVessel,
  FleetVesselActiveRequestPlusVesselID,
  FleetVesselCollection,
  NOT_APPLICABLE,
  PropsDeleteFleetVesselAndFleetVesselCollection,
  STANDART_DATE_FORMAT,
  THOUSAND,
  ZERO_STRING,
} from '@estimator/models';
import {
  ColDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ICellRendererParams,
  RowSelectedEvent,
  ValueFormatterParams,
} from 'ag-grid-community';
import { AgGridAutocompleteComponent } from '../ag-grid-shared/ag-grid-autocomplete-editor.component';
import { AgGridColorPickerCellComponent } from '../ag-grid-shared/ag-grid-color-picker-cell.component';
import { AgGridDateEditorComponent } from '../ag-grid-shared/ag-grid-date-editor.component';
import { AgGridSwitchEditorComponent } from '../ag-grid-shared/ag-grid-switch.component';
import { AgGridButtonGroupComponent } from '../ag-grid-shared/button-group.component';

@Component({
  selector: 'estimator-fleet-vessel',
  templateUrl: './fleet-vessel.component.html',
  styleUrls: ['./fleet-vessel.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FleetVesselComponent {
  @Input()
  set fleetVessels(data: FleetVessel[]) {
    this._fleetVessels = data;
  }
  get fleetVessels(): FleetVessel[] {
    return this._fleetVessels;
  }
  @Input()
  set collections(data: FleetVesselCollection[]) {
    this._collections = data;
    this._gridApi?.refreshCells();
  }
  get collections(): FleetVesselCollection[] {
    return this._collections;
  }
  @Output() updateFleetVessel = new EventEmitter<FleetVessel>();
  @Output() updateFleetVesselActive = new EventEmitter<FleetVesselActiveRequestPlusVesselID>();
  @Output() deleteFleetVessel = new EventEmitter<PropsDeleteFleetVesselAndFleetVesselCollection>();
  @Output() positionMap = new EventEmitter<number>();

  private readonly active = 'active';
  private readonly deactivationDate = 'deactivation_date';

  columnDefs: ColDef<FleetVessel>[] = [
    {
      field: 'action',
      headerName: '',
      cellRenderer: 'buttonRenderer',
      cellRendererParams: (params: ICellRendererParams) => {
        const deleteVessel = 'Delete vessel';
        return {
          ...params,
          buttons: [
            {
              title: deleteVessel,
              tooltip: deleteVessel,
              icon: 'delete',
              click: () => this.onDeleteFleetVessel(params),
            },
          ],
        };
      },
      cellClass: 'center-vertical-aligned-cell',
      minWidth: 50,
      width: 50,
    },
    {
      field: 'name',
      headerName: 'Vessel',
      flex: 1,
    },
    {
      field: 'collection_id',
      headerName: 'Fleet',
      flex: 1,
      editable: true,
      cellEditor: 'autoCompleteEditor',
      cellEditorParams: (params: any) => {
        // const value = this.getCollectionItemById(params.value);
        return {
          ...params,
          dataArray: this.collections,
          placeholder: 'Fleets',
          emptyDataArray: 'No fleets found',
          visibleKeys: ['name'],
          resultKey: 'id',
          fieldForExtendedComponent: ['color'],
          closeEditor: this.closeEditor.bind(this),
        };
      },
      cellRenderer: 'colorPickerCellComponent',
      cellRendererParams: (params: ICellRendererParams) => {
        const value = this.getCollectionItemById(params.value);
        return {
          ...params,
          name: value?.name,
          color: value?.color,
        };
      },
      valueFormatter: (params: ValueFormatterParams<FleetVessel>) => {
        const fleetVessel = params.data;
        if (fleetVessel) {
          const fleetVesselCollection = this.collections.find(
            (item) => item.id === fleetVessel.collection_id
          );
          if (fleetVesselCollection?.name) {
            return fleetVesselCollection.name;
          }
        }
        return NOT_APPLICABLE;
      },
    },
    {
      field: this.active,
      headerName: 'Satellite view',
      width: 100,
      editable: true,
      cellEditor: 'switchEditorComponent',
      cellEditorParams: (params: any) => {
        return {
          ...params,
          closeEditor: this.closeEditor.bind(this),
        };
      },
      cellRenderer: 'switchEditorComponent',
      cellRendererParams: (params: ICellRendererParams) => {
        return {
          ...params,
        };
      },
    },
    {
      field: this.deactivationDate,
      headerName: 'Satellite till',
      width: 150,
      editable: true,
      cellEditor: 'dateEditorComponent',
      cellEditorParams: (params: any) => {
        return {
          ...params,
          inline: true,
          closeEditor: this.closeEditor.bind(this),
        };
      },
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value) {
          return 'till' + ' ' + this.datePipe.transform(params.value, STANDART_DATE_FORMAT) || '';
        }
        return '';
      },
    },
  ];
  gridOptions: GridOptions<FleetVessel> = {
    rowModelType: 'clientSide',
    columnDefs: this.columnDefs,
    components: {
      buttonRenderer: AgGridButtonGroupComponent,
      autoCompleteEditor: AgGridAutocompleteComponent,
      colorPickerCellComponent: AgGridColorPickerCellComponent,
      dateEditorComponent: AgGridDateEditorComponent,
      switchEditorComponent: AgGridSwitchEditorComponent,
    },
    getRowId: (params) => {
      return params.data.id ? params.data.id.toString() : ZERO_STRING;
    },
    defaultColDef: {
      editable: false,
      resizable: false,
    },
    suppressContextMenu: true,
    suppressScrollOnNewData: true,
    singleClickEdit: true,
    rowSelection: 'single',
    animateRows: true,
    rowHeight: 35,
    headerHeight: 40,
    autoSizePadding: 0,
    onCellEditingStopped: (event) => {
      const column = event.column.getColId();
      if (event.data) {
        if (
          !this._gridApi?.getEditingCells()?.length &&
          event.newValue !== undefined &&
          event.newValue !== event.oldValue
        ) {
          if (column === this.active || column === this.deactivationDate) {
            this.updateFleetVesselActive.emit({
              value: {
                active: !!event.data.active,
                deactivation_date:
                  new Date(event.data.deactivation_date as string).getTime() / THOUSAND,
              },
              vesselId: event?.data?.id as number,
            });
          } else {
            this.updateFleetVessel.emit(event.data);
          }
        }
      }
    },
    onRowDoubleClicked: (event: RowSelectedEvent<FleetVessel>) => {
      this.positionMap.emit(event?.data?.general_vessel_id || 0);
    },
  };

  private _fleetVessels: FleetVessel[] = [];
  private _collections: FleetVesselCollection[] = [];
  private _gridApi?: GridApi<FleetVessel>;

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this._gridApi?.stopEditing(true);
    }
    if (event.key === 'Enter') {
      this._gridApi?.stopEditing();
    }
  }

  constructor(private readonly datePipe: DatePipe) {}

  onGridReady({ api }: GridReadyEvent): void {
    this._gridApi = api;
    this._gridApi?.refreshCells();
  }

  closeEditor(): void {
    if (this._gridApi) {
      this._gridApi.stopEditing();
    }
  }

  getCollectionItemById(collection_id: number): FleetVesselCollection {
    return this.collections?.find((item) => item?.id === collection_id) as FleetVesselCollection;
  }

  onDeleteFleetVessel(event: ICellRendererParams<FleetVessel>): void {
    const vessel = event.data;
    if (vessel) {
      if (vessel.id) {
        this.deleteFleetVessel.emit({
          id: vessel.id,
          general_vessel_id: vessel?.general_vessel_id as number,
          isDeleteCompanyItem: !!vessel.company_id,
        });
      } else {
        this._gridApi?.applyTransaction({ remove: [event.data as FleetVessel] });
      }
    }
  }
}
