<button
  [ngStyle]="{ 'background-color': currentItem.color || 'var(--white)' }"
  [ngClass]="{ 'increase-width': currentItem[optionLabel]?.length > 2, 'p-disabled': disabled }"
  [disabled]="disabled"
  (click)="switchNext()"
  class="button-switch {{ styleClass }} {{
    currentItem.color === PrimeNgColors.Transparent ? 'transparent' : ''
  }}"
  [id]="id"
  [tabIndex]="tabIndex"
  type="button"
  pTooltip="{{ tooltip ? currentItem.tooltip : '' }}"
>
  {{ currentItem[optionLabel] || currentItem }}
</button>
