export enum SuccessOrErrorCardType {
  Success = 'Success',
  Loading = 'Loading',
  Error = 'Error',
}

export const MINUTES_IN_DAY = 24 * 60;
export const MINUTES_IN_HOUR = 60;
export const SECONDS_IN_HOUR = 3600;

export const REPORT_LIMIT = 2042;
