import { Component } from '@angular/core';
import { ITooltipParams } from 'ag-grid-community';
import { ITooltipAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'estimator-aggrid-basic-tooltip-component',
  template: ` <div *ngIf="textValue" class="custom-tooltip">
    <span>{{ textValue }}</span>
  </div>`,
  styles: [
    `
      :host {
        position: absolute;
        background-color: #fff;
        pointer-events: none;
      }
    `,
  ],
})
export class AgGridBasicTooltipComponent implements ITooltipAngularComp {
  private params!: { textValue: string } & ITooltipParams;
  textValue = '';

  agInit(params: { textValue: string } & ITooltipParams): void {
    this.params = params;
    if (params.textValue) {
      this.textValue = params.textValue;
    }
  }
}
