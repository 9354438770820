import { CommonModule, TitleCasePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule } from 'primeng/table';
import { AgGridSwitchEditorModule } from '../ag-grid-shared/ag-grid-switch.module';
import { UserListComponent } from './user-list.component';

export const UserListModuleImports = [
  CommonModule,
  MultiSelectModule,
  TableModule,
  ReactiveFormsModule,
  DropdownModule,
  InputTextModule,
  AgGridSwitchEditorModule,
  ButtonModule,
];

@NgModule({
  declarations: [UserListComponent],
  imports: UserListModuleImports,
  exports: [UserListComponent],
  providers: [TitleCasePipe],
})
export class UserListModule {}
