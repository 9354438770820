import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ContactForm, IForm, PAGE, PAGES } from '@estimator/landing-models';
import { FormService } from '@estimator/landing-services';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'estimator-subscribe-form',
  template: `
    <div class="container">
      <div class="container-action center" [formGroup]="subscribeForm">
        <mar-badge>Stay updated</mar-badge>
        <p class="header">Subscribe to receive company and product updates in your inbox</p>
        <mar-input
          [ngStyle]="{ 'max-width': '40rem', height: '3rem' }"
          type="button"
          placeholder="Email"
          formControlName="email"
          (buttonClick)="onSubscribeButtonClick()"
          [disabled]="isFormSubmitted"
          [buttonDisabled]="isSubmitButtonDisabled"
          [loading]="isLoading"
          [label]="buttonLabel"
          class="container-action-input"
        ></mar-input>
        <p class="terms">
          <span class="opacity"
            >By subscribing you agree to receive news and marketing offers in accordance with
            our&nbsp;
          </span>
          <a [routerLink]="[PAGE.PRIVACY_POLICY]" class="t-underline no-wrap">{{
            PAGES_DETAILED.PRIVACY_POLICY.name
          }}</a
          >.
        </p>
      </div>
    </div>
  `,
  styles: [
    `
      @import '_variables';
      :host {
        display: block;
        .container-action {
          padding: unset;
          .container-action-input {
            padding-top: $padding-xl;
            padding-bottom: $padding-xl;
          }
        }
      }
    `,
  ],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscribeFormComponent implements OnDestroy {
  get buttonLabel(): string {
    return this.isFormSubmitted ? 'Submitted' : 'Submit';
  }
  get isSubmitButtonDisabled(): boolean {
    return this.isLoading || this.isFormSubmitted || this.subscribeForm.invalid;
  }
  subscribeForm = this.fb.group<IForm<ContactForm>>({
    email: [null, Validators.required],
  });
  isLoading = false;
  isFormSubmitted = false;
  readonly PAGE = PAGE;
  readonly PAGES_DETAILED = PAGES;

  private _onDestroy$ = new Subject<void>();

  constructor(
    private readonly formSrv: FormService,
    private readonly fb: FormBuilder,
    private readonly cdr: ChangeDetectorRef
  ) {}

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  onSubscribeButtonClick(): void {
    if (this.isSubmitButtonDisabled) return;
    this.isLoading = true;
    this.formSrv
      .sendMail(this.subscribeForm.value as ContactForm)
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(() => {
        this.isLoading = false;
        this.isFormSubmitted = true;
        this.subscribeForm.disable();
        this.cdr.detectChanges();
      });
  }
}
