import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { convertDateToUTC, emptyShiplistFilter, emptyShipListTableData } from '@estimator/helpers';
import { NotificationType, ShiplistTableResponse, THOUSAND } from '@estimator/models';
import { NotificationService, ShipListTableService } from '@estimator/services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  ShiplistVesselMail,
  ShiplistVesselMailMessages,
  XlistFilter,
  xlistRoutes,
} from '@xlist-models';
import { clone } from 'lodash';
import { catchError, finalize, Observable, tap, throwError } from 'rxjs';
import {
  CheckShiplistFilterName,
  CreateShiplistFilter,
  CreateShiplistFilterCopy,
  DeleteShiplistFilter,
  GetAllShiplistFilters,
  GetFirstSourceState,
  GetShiplistFilter,
  GetShiplistMessages,
  ResetShiplistFilter,
  UpdateShiplistFilter,
  UpdateShiplistGrid,
} from './shiplist.actions';

export interface ShiplistTableStateModel {
  filters: XlistFilter[];
  tableData: ShiplistTableResponse;
  isNameAvailable: boolean;
  loading?: boolean;
  selectedFilter?: XlistFilter;
  updateGrid?: number;
  messages?: ShiplistVesselMail[];
}

@State<ShiplistTableStateModel>({
  name: 'shiplistTable',
  defaults: {
    filters: [],
    tableData: emptyShipListTableData,
    isNameAvailable: false,
    loading: false,
    updateGrid: 0,
    messages: [],
  },
})
@Injectable()
export class ShiplistState {
  constructor(
    private readonly shipListTableService: ShipListTableService,
    private readonly notificationService: NotificationService,
    private readonly router: Router
  ) {}

  @Selector()
  static getSelectedFilter({ selectedFilter }: ShiplistTableStateModel): XlistFilter {
    return selectedFilter as XlistFilter;
  }

  @Selector()
  static isNameAvailable({ isNameAvailable }: ShiplistTableStateModel): boolean {
    return isNameAvailable;
  }

  @Selector()
  static getFilters({ filters }: ShiplistTableStateModel): XlistFilter[] {
    return filters;
  }

  @Selector()
  static getMessages({ messages }: ShiplistTableStateModel): ShiplistVesselMail[] {
    return messages || [];
  }

  @Selector()
  static isLoading({ loading }: ShiplistTableStateModel): boolean {
    return loading || false;
  }

  @Selector()
  static getFirstSource({ tableData }: ShiplistTableStateModel): ShiplistTableResponse {
    return tableData || emptyShipListTableData;
  }

  @Selector()
  static getUpdateGrid({ updateGrid }: ShiplistTableStateModel): number {
    return updateGrid || 0;
  }

  @Action(GetFirstSourceState)
  getFirstSourceState(
    { patchState }: StateContext<ShiplistTableStateModel>,
    { filter, params }: GetFirstSourceState
  ) {
    patchState({ loading: true });
    const messageDateFrom = new Date();
    let messageDateFromInSeconds = 0;
    if (filter.message_date_last_n_days) {
      messageDateFrom.setDate(messageDateFrom.getDate() - filter?.message_date_last_n_days);
      messageDateFromInSeconds = convertDateToUTC(messageDateFrom) / THOUSAND;
      filter.message_date_from_seconds = messageDateFromInSeconds;
      filter.message_date_to_seconds = convertDateToUTC(new Date()) / THOUSAND;
    }

    const openDateFrom = new Date();
    let openDateFromInSeconds = 0;
    if (filter.open_dates_last_n_days) {
      openDateFrom.setDate(openDateFrom.getDate() - filter?.open_dates_last_n_days);
      openDateFromInSeconds = convertDateToUTC(openDateFrom) / THOUSAND;
      filter.open_dates_from_seconds = openDateFromInSeconds;
      filter.open_dates_to_seconds = convertDateToUTC(new Date()) / THOUSAND;
    }
    return this.shipListTableService.getFirstSource(filter, params).pipe(
      tap((value) => patchState({ tableData: value })),
      catchError((err) => {
        patchState({ loading: false });
        this.notificationService.showNotification(
          `Error when getting shiplist's data. ${err?.error?.error}`,
          NotificationType.Error
        );
        return throwError(err);
      }),
      finalize(() => patchState({ loading: false }))
    );
  }

  @Action(GetAllShiplistFilters)
  getAllShiplistFilters({ patchState }: StateContext<ShiplistTableStateModel>) {
    patchState({ loading: true });
    return this.shipListTableService.getAll().pipe(
      tap((value) => patchState({ filters: value })),
      catchError((err) => {
        patchState({ loading: false });
        this.notificationService.showNotification(
          `Error when getting filters. ${err?.error?.error}`,
          NotificationType.Error
        );
        return throwError(err);
      }),
      finalize(() => patchState({ loading: false }))
    );
  }

  @Action(GetShiplistFilter)
  getShiplistFilter(
    { patchState }: StateContext<ShiplistTableStateModel>,
    { id }: GetShiplistFilter
  ): Observable<XlistFilter> {
    patchState({ loading: true });
    return this.shipListTableService.get(id).pipe(
      tap((value) => patchState({ selectedFilter: value })),
      catchError((err) => {
        patchState({ loading: false });
        this.notificationService.showNotification(
          `Error when getting a filter. ${err?.error?.error}`,
          NotificationType.Error
        );
        return throwError(err);
      }),
      finalize(() => patchState({ loading: false }))
    );
  }

  @Action(CreateShiplistFilter)
  createShiplistFilter(
    { getState, patchState }: StateContext<ShiplistTableStateModel>,
    { filter }: CreateShiplistFilter
  ) {
    patchState({ loading: true });
    return this.shipListTableService
      .create(filter)
      .pipe(
        tap((newFilter) => {
          const { filters } = getState();
          patchState({
            filters: [...filters, newFilter],
            selectedFilter: newFilter,
          });
        }),
        catchError((err) => {
          patchState({ loading: false });
          this.notificationService.showNotification(
            `Error when creating a filter. ${err?.error?.error}`,
            NotificationType.Error
          );
          return throwError(err);
        }),
        finalize(() => patchState({ loading: false }))
      )
      .subscribe((res) => {
        this.router.navigate([xlistRoutes.FILTERS, res.id]);
      });
  }

  @Action(UpdateShiplistFilter)
  updateShiplistFilter(
    { getState, patchState }: StateContext<ShiplistTableStateModel>,
    { filter }: UpdateShiplistFilter
  ) {
    patchState({ loading: true });
    return this.shipListTableService
      .update(filter)
      .pipe(
        tap((updFilter) => {
          const { filters } = getState();
          const index = filters.findIndex((oldFilter) => oldFilter?.id === updFilter.id);
          if (index >= 0) {
            filters.splice(index, 1, updFilter);
          }
          patchState({ filters: [...filters], selectedFilter: updFilter });
        }),
        catchError((err) => {
          patchState({ loading: false });
          this.notificationService.showNotification(
            `Error when updating a filter. ${err?.error?.error}`,
            NotificationType.Error
          );
          return throwError(err);
        }),
        finalize(() => patchState({ loading: false }))
      )
      .subscribe((res) => {
        this.router.navigate([xlistRoutes.FILTERS, res.id]);
      });
  }

  @Action(CreateShiplistFilterCopy)
  createShiplistFilterCopy(
    { getState, patchState }: StateContext<ShiplistTableStateModel>,
    { id }: CreateShiplistFilterCopy
  ): Observable<XlistFilter> {
    patchState({ loading: true });
    return this.shipListTableService.createShiplistFilterCopy(id).pipe(
      tap((copyFilter) => {
        const { filters } = getState();
        patchState({
          filters: [...filters, copyFilter],
          selectedFilter: copyFilter,
        });
      }),
      catchError((err) => {
        patchState({ loading: false });
        this.notificationService.showNotification(
          `Error when creating a filter copy. ${err?.error?.error}`,
          NotificationType.Error
        );
        return throwError(err);
      }),
      finalize(() => patchState({ loading: false }))
    );
  }

  @Action(DeleteShiplistFilter)
  deleteShiplistFilter(
    { getState, patchState }: StateContext<ShiplistTableStateModel>,
    { id }: DeleteShiplistFilter
  ) {
    patchState({ loading: true });
    return this.shipListTableService.delete(id).pipe(
      tap(() => {
        const { filters } = getState();
        const index = filters.findIndex((oldFilter) => oldFilter?.id === id);
        if (index >= 0) {
          filters.splice(index, 1);
        }
        patchState({ filters: [...filters] });
      }),
      catchError((err) => {
        patchState({ loading: false });
        this.notificationService.showNotification(
          `Error when deleting a filter. ${err?.error?.error}`,
          NotificationType.Error
        );
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }

  @Action(CheckShiplistFilterName)
  checkShiplistFilterName(
    { patchState }: StateContext<ShiplistTableStateModel>,
    { name }: CheckShiplistFilterName
  ) {
    patchState({ loading: true });
    return this.shipListTableService.checkShiplistFilterName(name).pipe(
      tap((value) => patchState({ isNameAvailable: value.is_name_available })),
      catchError((err) => {
        patchState({ loading: false, isNameAvailable: false });
        this.notificationService.showNotification(
          `Error when checking a filter name. ${err?.error?.error}`,
          NotificationType.Error
        );
        return throwError(err);
      }),
      finalize(() => patchState({ loading: false }))
    );
  }

  @Action(ResetShiplistFilter)
  resetShiplistFilter({ patchState }: StateContext<ShiplistTableStateModel>) {
    patchState({ selectedFilter: clone(emptyShiplistFilter) });
  }

  @Action(UpdateShiplistGrid)
  updateShiplistGrid({ getState, patchState }: StateContext<ShiplistTableStateModel>) {
    const { updateGrid } = getState();
    patchState({ updateGrid: (updateGrid || 0) + 1 });
  }

  @Action(GetShiplistMessages)
  getShiplistMessages(
    { patchState }: StateContext<ShiplistTableStateModel>,
    { shiplist_item_id }: GetShiplistMessages
  ): Observable<ShiplistVesselMailMessages> {
    patchState({ loading: true });
    return this.shipListTableService.getShiplistMessages(shiplist_item_id).pipe(
      tap((value) => patchState({ messages: value.messages })),
      catchError((err) => {
        patchState({ loading: false });
        this.notificationService.showNotification(
          `Error when getting a messages. ${err?.error?.error}`,
          NotificationType.Error
        );
        return throwError(err);
      }),
      finalize(() => patchState({ loading: false }))
    );
  }
}
