<mgl-map
  [zoom]="[9]"
  [center]="center"
  [style]="style"
  (mapLoad)="onLoad($event)"
  (styleImageMissing)="styleImageMissing()"
>
  <!-- selectedPort info -->
  <mgl-geojson-source [data]="selectedPort" id="selectedPort-source"></mgl-geojson-source>
  <mgl-layer
    (layerClick)="onSelectedPortLayerClick()"
    (layerMouseEnter)="onMouseEnter($event)"
    (layerMouseLeave)="onMouseLeave($event)"
    [layout]="{
      'symbol-sort-key': 9,
      visibility: 'visible',
      'icon-image': 'selectedPort',
      'icon-allow-overlap': true,
      'icon-ignore-placement': true,
      'icon-rotate': {
        type: 'identity',
        property: 'rotation'
      },
      'icon-size': 0.3,
      'text-field': ['get', 'name'],
      'text-font': ['Open Sans Light'],
      'text-offset': [0, 2],
      'text-variable-anchor': ['top'],
      'text-size': 10,
      'text-allow-overlap': true,
      'text-ignore-placement': true
    }"
    id="selectedPort"
    source="selectedPort-source"
    type="symbol"
  >
  </mgl-layer>
  <mgl-popup
    *ngIf="showPortPopup && selectedPortPoint"
    [feature]="selectedPortPoint"
    className="swgt"
  >
    <ng-container *ngIf="selectedPortPoint.properties">
      <p>
        Port name:
        {{ selectedPortPoint.properties[MapFeatureProperties.Name] || NOT_APPLICABLE }}
        <span
          class="ml-5 fi fi-{{
            selectedPortPoint.properties[MapFeatureProperties.Alpha2]?.toLowerCase()
          }}"
        ></span>
      </p>
      <p>
        Time zone name:
        {{ selectedPortPoint.properties[MapFeatureProperties.TimeZoneName] || NOT_APPLICABLE }}
      </p>
      <p>
        Time zone: {{ UserTimeSettings.UTC }}
        {{
          addPlusTimeZone(selectedPortPoint.properties[MapFeatureProperties.TimeZone]) ||
            NOT_APPLICABLE
        }}
      </p>
    </ng-container>
  </mgl-popup>
  <mgl-image id="selectedPort" url="assets/layer-port.png"></mgl-image>

  <!-- fleet positions info -->
  <mgl-geojson-source
    [clusterMaxZoom]="14"
    [clusterRadius]="50"
    [cluster]="true"
    [data]="lastAllFleetVesselPositions"
    id="fleet-source"
  ></mgl-geojson-source>
  <mgl-markers-for-clusters *ngIf="lastAllFleetVesselPositions && isLogged" source="fleet-source">
    <ng-template let-feature mglClusterPoint>
      <div (click)="clickCluster(feature, 'fleet-source')" class="marker-cluster fleet-cluster">
        {{ feature.properties?.point_count }}
      </div>
    </ng-template>
  </mgl-markers-for-clusters>
  <mgl-layer
    (layerClick)="onFleetLayerClick($event)"
    (layerMouseEnter)="onMouseEnter($event)"
    (layerMouseLeave)="onMouseLeave($event)"
    [layout]="{
      'symbol-sort-key': 10,
      'icon-image': 'fleetMarker',
      'icon-allow-overlap': true,
      'icon-ignore-placement': true,
      'icon-size': 0.5,
      'icon-rotate': {
        type: 'identity',
        property: 'courseForMap'
      },
      'text-field': ['get', 'name'],
      'text-font': ['Open Sans Light'],
      'text-offset': [0, 1.5],
      'text-variable-anchor': ['top'],
      'text-size': 10,
      'text-allow-overlap': true,
      'text-ignore-placement': true
    }"
    [paint]="{
      'text-color': '#000000',
      'icon-color': ['get', MapFeatureProperties.IconColor]
    }"
    id="fleet"
    source="fleet-source"
    type="symbol"
  ></mgl-layer>

  <!-- ports positions info -->
  <mgl-geojson-source
    *ngIf="showPorts && isLogged"
    [clusterMaxZoom]="14"
    [clusterRadius]="50"
    [cluster]="true"
    [data]="portsPoints"
    id="port-source"
  ></mgl-geojson-source>
  <mgl-markers-for-clusters *ngIf="showPorts && isLogged" source="port-source">
    <ng-template let-feature mglClusterPoint>
      <div (click)="clickCluster(feature, 'port-source')" class="marker-cluster port-cluster">
        {{ feature.properties?.point_count }}
      </div>
    </ng-template>
  </mgl-markers-for-clusters>
  <mgl-image id="portMarker" url="assets/anchor.png"></mgl-image>
  <mgl-layer
    *ngIf="showPorts && isLogged"
    (layerClick)="onPortLayerClick($event)"
    (layerMouseEnter)="onMouseEnter($event)"
    (layerMouseLeave)="onMouseLeave($event)"
    [layout]="{
      'symbol-sort-key': 10,
      'icon-image': 'portMarker',
      'icon-allow-overlap': true,
      'icon-ignore-placement': true,
      'icon-size': 0.5,
      'text-field': ['get', 'name'],
      'text-font': ['Open Sans Light'],
      'text-offset': [0, 1.5],
      'text-variable-anchor': ['top'],
      'text-size': 10,
      'text-allow-overlap': true,
      'text-ignore-placement': true
    }"
    [paint]="{
      'text-color': '#000000'
    }"
    id="port"
    source="port-source"
    type="symbol"
  ></mgl-layer>

  <!-- history point info -->
  <mgl-geojson-source
    [data]="historyFleetVesselsPositionsPoints"
    id="history-point-source"
  ></mgl-geojson-source>
  <mgl-layer
    (layerMouseEnter)="onMouseEnter($event)"
    (layerMouseLeave)="onMouseLeave($event)"
    [layout]="{
      'icon-size': 0.04,
      'text-field': ['get', 'updated_at'],
      'text-font': ['Open Sans Light'],
      'text-offset': [0, 0.4],
      'text-anchor': 'top',
      'text-size': 9
    }"
    id="history-point-source"
    source="history-point-source"
    type="symbol"
  ></mgl-layer>

  <!-- history route line_string info -->
  <!-- line-progress: от красного к зеленому линия -->
  <mgl-geojson-source
    [data]="historyFleetVesselsPositionsLineString"
    id="history-line-source"
    [lineMetrics]="true"
  ></mgl-geojson-source>
  <mgl-layer
    id="history-line-source"
    source="history-line-source"
    type="line"
    [paint]="{
      'line-width': 3,
      'line-gradient': [
        'interpolate',
        ['linear'],
        ['line-progress'],
        0,
        '#b9b9b9',
        0.25,
        '#8e8e8e',
        0.55,
        '#727272',
        1,
        '#000000'
      ]
    }"
  >
  </mgl-layer>
  <!-- eca zones -->
  <mgl-geojson-source
    *ngIf="showEcaZones && isLogged"
    id="eca_zones"
    [data]="ecaZones"
  ></mgl-geojson-source>
  <mgl-layer
    *ngIf="showEcaZones && isLogged"
    id="eca_zones"
    source="eca_zones"
    type="fill"
    [paint]="{
      'fill-color': '#0080ff',
      'fill-opacity': 0.1
    }"
  >
  </mgl-layer>
  <!-- canal zones -->
  <mgl-geojson-source
    *ngIf="showCanalZones && isLogged"
    id="canal_zones"
    [data]="canalZones"
  ></mgl-geojson-source>
  <mgl-layer
    *ngIf="showCanalZones && isLogged"
    id="canal_zones"
    source="canal_zones"
    type="fill"
    [paint]="{
      'fill-color': '#3FC555',
      'fill-opacity': 0.1
    }"
  >
  </mgl-layer>
</mgl-map>
<estimator-vessel-port-search
  class="map-vessel-port-input"
  *ngIf="isLogged"
  [isLoading]="isLoading"
  [vesselsPorts]="vesselsPorts"
  (searchVesselOrPort)="onSearchVesselOrPort($event)"
  (selectVesselOrPort)="onSelectVesselOrPort($event)"
></estimator-vessel-port-search>
<div *ngIf="isLogged" class="eca-zones flex-container-row">
  <span class="icon-label">ECA Zones</span>
  <p-checkbox [(ngModel)]="showEcaZones" [binary]="true"></p-checkbox>
</div>
<!-- <div *ngIf="isLogged" class="canal-zones flex-container-row">
  <span class="icon-label">Canal Zones</span>
  <p-checkbox [(ngModel)]="showCanalZones" [binary]="true"></p-checkbox>
</div> -->
<div *ngIf="isLogged" class="ports flex-container-row">
  <span class="icon-label">Ports</span>
  <p-checkbox [(ngModel)]="showPorts" [binary]="true"></p-checkbox>
</div>
<!-- <div *ngIf="isLogged" class="display-flex collection-icon-pos map-icon">
  <span class="icon-label">Fleets</span>
  <span
    [ngClass]="{ 'disabled-icon': isFleetVesselLoading }"
    class="collections-icon cursor-pointer collection-image"
    (click)="openCollectionsDialog()"
  ></span>
</div>
<div *ngIf="isLogged" class="display-flex vessel-icon-pos map-icon">
  <span class="icon-label">Vessels</span>
  <mat-icon
    [ngClass]="{ 'disabled-icon': isFleetVesselLoading }"
    [color]="'black'"
    class="vessel-icon cursor-pointer vessel-image"
    (click)="openVesselsDialog()"
    >directions_boat</mat-icon
  >
</div> -->
