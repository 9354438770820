import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { twoMenuTabs } from '@estimator/helpers';
import {
  PrimeNgColors,
  ShipListTableColumnType,
  ShipListVesselResModel,
  STANDART_TIME_FORMAT,
  ZERO_STRING,
} from '@estimator/models';
import {
  ColDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
  IServerSideDatasource,
  ValueFormatterParams,
} from 'ag-grid-community';

/**
 * TableComponent
 *
 * [primeng.org/table#customers](https://primeng.org/table)
 *
 * deprecated
 * */
@Component({
  selector: 'estimator-xpivot-shiplist',
  templateUrl: './ship-list-table.component.html',
  styleUrls: ['./ship-list-table.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShipListTableComponent {
  @Input() getDataSource?: IServerSideDatasource;
  /* @Input({ required: true }) set firstSourceData(value: ShiplistTableResponse) {
    this._firstSourceData = value;
  }
  @Output() updateFirstSourceData: EventEmitter<ShiplistTableParams> = new EventEmitter();
  filterData?: ShiplistTableParams;
  localDateTimeFormat = localDateTimeFormat;
  readonly cols = SHIPLIST_TABLE_COLUMNS;
  readonly ShipListTableColumnType = ShipListTableColumnType;
  readonly stateKey = 'shiplist_table';
  readonly PrimeNgIcons = PrimeNgIcons;
  readonly ShiplistZoneType = ShiplistZoneType;
  private _firstSourceData: ShiplistTableResponse = emptyShipListTableData;

  get firstSourceData(): ShiplistTableResponse {
    return this._firstSourceData;
  }

  loadLazy(event: TableLazyLoadEvent): void {
    console.log('event', event);
    const filterData: ShiplistTableParams = {
      limit: COUNT_FOR_UNIQUE_ROW_ID,
      offset: event.last && event.last > COUNT_FOR_UNIQUE_ROW_ID ? event.last : 0,
    };
    if (event.sortField) {
      filterData.sort_order =
        event.sortOrder === 1 ? ShipListSortingType.ASC : ShipListSortingType.DESC;
      filterData.sort_field = event.sortField as string;
    }
    console.log('filterData', filterData);
    this.filterData = filterData;
    // trigger change detection
    if (event?.forceUpdate) {
      event?.forceUpdate();
    }
    this.updateFirstSourceData.emit(this.filterData as ShiplistTableParams);
  } */
  dateValueFormatter = (params: ValueFormatterParams) => {
    return this.datePipe.transform(params.value, STANDART_TIME_FORMAT) || '';
  };
  columnDefs: ColDef<ShipListVesselResModel>[] = [
    {
      field: ShipListTableColumnType.VESSEL_NAME,
      headerName: 'Name',
    },
    {
      field: ShipListTableColumnType.VESSEL_BUILT_YEAR,
      headerName: 'Built(y)',
    },
    {
      field: ShipListTableColumnType.VESSEL_DEAD_WEIGHT,
      headerName: 'DWT',
    },
    {
      field: ShipListTableColumnType.ETA,
      headerName: 'Open Dates (from)',
      valueFormatter: this.dateValueFormatter,
    },
    {
      field: ShipListTableColumnType.ETD,
      headerName: '(to) ',
      valueFormatter: this.dateValueFormatter,
    },
    /*  {
      field: ShipListTableColumnType.ZONE_NAME,
      headerName: 'Zone',
    }, */
    {
      field: ShipListTableColumnType.PORT_NAME,
      headerName: 'Position',
    },
    {
      field: ShipListTableColumnType.PORT_COUNTRY_NAME,
      headerName: 'Country',
    },
    {
      field: ShipListTableColumnType.MESSAGE_DATE,
      headerName: 'Update',
      valueFormatter: this.dateValueFormatter,
    },
    {
      field: ShipListTableColumnType.VESSEL_MANAGER,
      headerName: 'TC Operator',
    },
    {
      field: ShipListTableColumnType.VESSEL_OWNER,
      headerName: 'Shipowner',
    },
  ];
  gridOptions: GridOptions<ShipListVesselResModel> = {
    rowModelType: 'serverSide',
    serverSideInfiniteScroll: true,
    suppressRowHoverHighlight: true,
    suppressRowClickSelection: true,
    columnDefs: this.columnDefs,
    getRowId: (params) => params.data.id?.toString() || ZERO_STRING,
    onFirstDataRendered: (params) => params.api.sizeColumnsToFit(),
    defaultColDef: {
      filter: false,
      editable: false,
      sortable: true,
      resizable: true,
      suppressMenu: true,
      menuTabs: twoMenuTabs,
    },
    suppressContextMenu: true,
    suppressScrollOnNewData: true,
    singleClickEdit: true,
    rowSelection: 'single',
    animateRows: true,
    rowHeight: 40,
    headerHeight: 40,
    autoSizePadding: 0,
    getRowStyle: () => {
      return { background: PrimeNgColors.LightGrey };
    },
  };
  private _gridApi?: GridApi<ShipListVesselResModel>;

  constructor(private readonly datePipe: DatePipe) {}

  onGridReady({ api }: GridReadyEvent): void {
    this._gridApi = api;
    const datasource = this.getDataSource;
    api.setServerSideDatasource(datasource as IServerSideDatasource);
    api.closeToolPanel();
  }

  refresh(): void {
    this._gridApi?.refreshServerSide();
  }
}
