import { AfterViewInit, Component, HostListener, ViewChild, ViewContainerRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Cargo, MaterialThemeColor, STRING } from '@estimator/models';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { debounceTime } from 'rxjs';

@Component({
  selector: 'estimator-cargo-type-editor',
  template: `
    <input
      type="text"
      [placeholder]="placeholder"
      aria-label="Number"
      matInput
      [formControl]="formControl"
      [matAutocomplete]="portAutoComplete"
      class="aggrid-input"
      #autoCompleteInput
    />
    <mat-icon *ngIf="isLoading" matSuffix class="ag-grid-ac-loader">
      <mat-spinner [color]="MaterialThemeColor.Primary" [diameter]="20"></mat-spinner>
    </mat-icon>
    <mat-autocomplete
      #auto="matAutocomplete"
      panelClass="ag-grid-select-panel"
      #portAutoComplete
      name="portSearch"
      class="ag-grid-autocomplete cargo-autocomplete"
      (click)="stopPropagation($event)"
    >
      <mat-option
        *ngFor="let data of dataArray"
        [value]="data"
        (onSelectionChange)="onOptionSelect(data)"
        class="low-height"
      >
        <p>#{{ data?.internal_order }}</p>
      </mat-option>
      <ng-container *ngIf="dataArray.length < 1 && !isLoading">
        <mat-option [disabled]="true">
          <p>{{ emptyDataArray }}</p>
        </mat-option>
      </ng-container>
      <mat-option [disabled]="true" class="low-height">
        <button class="add-cargo-button" (click)="onAddNewCargo()">
          <mat-icon>add</mat-icon>
          <span>Add new cargo</span>
        </button>
      </mat-option>
    </mat-autocomplete>
  `,
  styles: [
    `
      .ag-grid-ac-loader {
        margin-left: -24px;
      }

      .cdk-overlay-pane {
        min-width: 0;
      }
      .add-cargo-button {
        width: 100%;
        height: 100%;
        border: none;
        background: none;
        cursor: pointer;
        font-size: 10px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
      .ag-grid-autocomplete .cargo-autocomplete {
        .low-height {
          height: 30px !important;
        }
      }
      .mat-option .mat-icon {
        margin-right: 0 !important;
        font-size: 16px !important;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class AgGridCargoTypeComponent implements AfterViewInit, ICellEditorAngularComp {
  @ViewChild('autoCompleteInput', { read: ViewContainerRef }) input!: ViewContainerRef;
  value!: Cargo;
  dataArray: Cargo[] = [];
  formControl = new FormControl();
  isLoading = false;
  filteredDataArray: Cargo[] = [];
  filter?: (req: string) => any;
  placeholder = 'Cargo';
  emptyDataArray = 'Nothing found';
  MaterialThemeColor = MaterialThemeColor;
  private _params: any;

  @HostListener('click', ['$event'])
  stopPropagation(event: MouseEvent): void {
    event.stopImmediatePropagation();
  }

  ngAfterViewInit() {
    window.setTimeout(() => {
      this.input.element.nativeElement.select();
    });
  }

  agInit(params: any): void {
    this._params = params;
    if (params.dataArray) {
      this.dataArray = params.dataArray;
      this.filteredDataArray = params.dataArray;
    }
    if (params.value) {
      this.value = params.value;
      if (this.dataArray.length) {
        const cargo = this.dataArray.find((elem) => elem.id === params.value);
        if (cargo) {
          this.formControl.patchValue(`#${cargo.internal_order || 0}`);
        } else {
          this.formControl.patchValue(params.value);
        }
      }
    }
    if (params.placeholder) {
      this.placeholder = params.placeholder;
    }
    if (params.emptyDataArray) {
      this.emptyDataArray = params.emptyDataArray;
    }
    if (params.filter) {
      this.filter = params.filter;
    }
    if (params.onAddNewCargo) {
      this.onAddNewCargo = params.onAddNewCargo;
    }
    if (params.closeEditor) {
      this.closeEditor = params.closeEditor;
    }
    this.formControl.valueChanges.pipe(debounceTime(500)).subscribe((res) => {
      if (typeof res === STRING && res) {
        if (this.filter) {
          this.dataArray = this.filteredDataArray.filter((value) => {
            return value?.cargo_type?.name.toString().toLowerCase().includes(res.toLowerCase());
          });
        }
      }
    });
  }

  onOptionSelect(value: Cargo): void {
    this.value = value;
    this.formControl.patchValue(`#${value?.internal_order}`);
    this.closeEditor();
  }

  getTooltip(cargo: Cargo): string {
    return `#${cargo.internal_order} ${cargo.cargo_type?.name}`;
  }

  getValue(): any {
    let result;
    if (typeof this.value === 'object') {
      result = this.value.id;
    } else {
      result = this.value;
    }
    return result;
  }

  compareObjects(o1: any, o2: any): boolean {
    return o1?.id === o2?.id;
  }

  onAddNewCargo(): void {
    return;
  }

  closeEditor(): void {
    return;
  }
}
