import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { MessageModule } from 'primeng/message';
import { LoginReminderComponent } from './login-reminder.component';

@NgModule({
  declarations: [LoginReminderComponent],
  imports: [CommonModule, ButtonModule, MessageModule],
  exports: [LoginReminderComponent],
})
export class LoginReminderModule {}
