import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'duration',
})
export class DurationPipe implements PipeTransform {
  /**
   * time in minutes (number of minutes)
   * @param value
   * formatted string (0d 1h 2m)
   * @returns
   */
  transform(
    value: number,
    format?: 'd' | 'dhm',
    limitAfterComma?: number,
    withoutD = false
  ): string {
    const interval = moment.duration(value, 'minutes');
    if (format === 'd') {
      if (limitAfterComma) {
        return `${interval.asDays().toFixed(limitAfterComma)} ${withoutD ? '' : 'd'}`;
      }
      return `${interval.asDays()} d`;
    }
    let result = '';
    if (interval.days()) {
      result += `${interval.days()}d`;
    }
    if (interval.hours()) {
      result += ` ${interval.hours()}h`;
    }
    if (interval.minutes()) {
      result += ` ${interval.minutes()}m`;
    }
    if (interval.seconds()) {
      result += ` ${interval.seconds()}s`;
    }
    return result;
  }
}
