<div>
  <div class="flex-container-column size">
    <div class="mb-10" *ngIf="!saveFolderOrDeleteDeal">
      Current folder: <span class="bold">{{ oldFolder }}</span>
    </div>
    <div class="mb-20" *ngIf="!saveFolderOrDeleteDeal && !notShowNewFolder">
      Opening folder: <span class="bold">{{ newFolder }}</span>
    </div>
  </div>
  <div class="p-fluid flex-container-row">
    <p-button
      class="w-100 padding-right-20"
      id="dialog_save_folder_changes"
      styleClass="p-button-sm h-full"
      [label]="getLabel1()"
      type="button"
      (click)="onSaveExist()"
    >
    </p-button>
    <p-button
      class="w-100"
      id="dialog_dont_save_folder_changes"
      styleClass="p-button-sm h-full"
      [label]="getLabel2()"
      type="button"
      (click)="onDontSaveExist()"
    >
    </p-button>
  </div>
</div>
