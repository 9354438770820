<div class="reminder-wrapper">
  <div class="p-fluid mb-5">
    <p-message [severity]="NotificationType.Error" [text]="reminderText"></p-message>
  </div>
  <div class="p-fluid">
    <p-button
      class="w-100"
      size="small"
      [label]="SIGN_IN"
      type="button"
      (click)="onSignIn()"
    ></p-button>
  </div>
</div>
