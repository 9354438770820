import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from '@estimator/directives';
import { PipeModule } from '@estimator/helpers';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DragDropModule } from 'primeng/dragdrop';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { ButtonSwitchModule } from '../button-switch/button-switch.module';
import { PriceAndCurrencyInputModule } from '../price-and-currency-input/price-and-currency-input.module';
import { UserDurationInputModule } from '../user-duration-input/user-duration-input.module';
import { EventsFormComponent } from './events-form.component';

export const EVENTS_FORM_IMPORTS = [
  CommonModule,
  CheckboxModule,
  AutoCompleteModule,
  CalendarModule,
  DragDropModule,
  DropdownModule,
  InputNumberModule,
  ButtonModule,
  PipeModule,
  TooltipModule,
  ReactiveFormsModule,
  TableModule,
  DirectivesModule,
  UserDurationInputModule,
  PriceAndCurrencyInputModule,
  FormsModule,
  InputSwitchModule,
  ButtonSwitchModule,
];

@NgModule({
  declarations: [EventsFormComponent],
  imports: EVENTS_FORM_IMPORTS,
  exports: [EventsFormComponent],
})
export class EventsFormModule {}
