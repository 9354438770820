import { FeatureCollection } from 'geojson';
import { BaseEntity } from './base-entity';
import { Cargo } from './cargo';
import { Company } from './company';
import { DealEvent } from './deal-event';
import { DealFinance, HireFinance } from './deal-finances';
import { FilterType, Folder } from './folder';
import { FuelConsumption } from './fuel-consumption';
import { Sundry } from './sundry';
import { NavigationLink } from './ui-items';
import { Constants, Vessel } from './vessel';

export interface Deal extends BaseEntity {
  calculate_heating_consumption?: boolean;
  events?: DealEvent[];
  cargoes?: Cargo[];
  cargo_calculator_id?: number;
  company_id?: number;
  company?: Company;
  /**
   * Поле для указания названия файла (для удобства работы оператора)
   */
  file?: string;
  finances?: DealFinance;
  finances_id?: number;
  folder?: Folder;
  folder_id?: number;
  fuel_consumptions?: FuelConsumption[];
  // is_advanced?: boolean;
  is_archived?: boolean;
  name: string;
  sundries?: Sundry[];
  vessel?: Vessel;
  vessel_id?: number;
  /**
   * Получена ли расширенная информация по сделке из базы (используется на клиенте для кэширования)
   */
  requested?: boolean;
  route?: FeatureCollection;
  portsPoints?: FeatureCollection;
  // alternativeRoutes?: FeatureCollection[];
  note?: string;
  use_ulsfo?: boolean;
  voyage_type?: VoyageType;
  folder_index?: number;
  copied_id?: number;
  map_height?: number;
}

export interface DaysDistanceIndicators {
  // блок days
  hireMinutes?: number;
  sailingMinutes?: number;
  loadDischargeMinutes?: number;
  idleMinutes?: number;
  reserveMinutes?: number;
  // блок distance
  distance?: number;
  ecaDistance?: number;
  balDistance?: number;
  ladDistance?: number;
  // Energy Efficiency Operational Indicators
  EEOI?: number;
}

export interface DealNavigationLink extends NavigationLink {
  name?: string;
  vesselName?: string;
  vesselId?: number;
  editing?: boolean;
  folderId?: number;
  is_archived?: boolean;
}

export interface VesselFormUpdateDto {
  vessel: Partial<Vessel>;
  hireFinances: HireFinance;
  co2PriceValue?: number;
}

export interface GetAllDeals {
  deals: Deal[];
}

export interface GetAllDealsWithParams extends GetAllDeals {
  total_amount_of_deals: number;
}

export interface CascadeNavigation {
  name: string;
  subnavs: DealNavigationLink[];
}

export interface FilterStatusDeal {
  name: string;
}

export enum VoyageType {
  Tanker = 'vt_tanker',
  Bulk = 'vt_bulk',
}

export interface DealFormHasChanges {
  change: boolean;
  estimate?: boolean;
  // changeDealName?: string;
  selectVessel?: boolean;
  estimateFinances?: boolean;
  changeDeductibles?: Constants;
  changePort?: boolean;
}

export interface FilterFields {
  is_archived?: string;
  folder_name?: string;
  name_deal?: string;
  vessel?: string;
  vessel_name?: string;
  company_id?: string;
  created_by?: string;
  voyage_type?: string;
}

export type StartFilterModel = {
  [key in keyof FilterFields]: { values: any[]; filterType: FilterType };
};

export interface OpenDealAndNavigate {
  /**
   * important that deal can be full object or only some fields
   */
  deal: Deal;
  navigate: boolean;
  notCheckCanOpen?: boolean;
}

export enum DealAction {
  RENAME = 'rename',
  COPY = 'copy',
  DELETE = 'delete',
  SHOW_MENU = 'show_menu',
  NAVIGATE = 'navigate',
}

export interface BreakEvenCargo {
  cargo_id: number;
  name: string;
  price: number;
}

export interface BreakEvenData {
  cargoes: BreakEvenCargo[];
  hire: number;
  ntce: number;
  result: number;
  step: number;
  [key: string]: any; // For dynamic properties based on cargo names, when draw graph
}

export interface BreakEvenDataResponse {
  break_evens: BreakEvenData[];
}

export interface NavigateDealEvent {
  event: MouseEvent;
  link: DealNavigationLink;
}
