import { Directive, ElementRef, forwardRef, HostListener } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { THOUSAND } from '@estimator/models';

@Directive({
  selector: '[estimatorSecondsToMilliseconds]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SecondsToMillisecondsDirective),
      multi: true,
    },
  ],
})
export class SecondsToMillisecondsDirective implements ControlValueAccessor {

  private onChange: (value: any) => void = () => {};
  private onTouched: () => void = () => {};

  constructor(private el: ElementRef<HTMLInputElement>) {}

  writeValue(value: number): void {
    // Display the value in seconds to the user
    if (value != null) {
      this.el.nativeElement.value = (value / THOUSAND).toString();
    }
  }

  registerOnChange<T>(fn: (value: T) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.el.nativeElement.disabled = isDisabled;
  }

  @HostListener('input', ['$event.target.value'])
  onInput(seconds: string): void {
    const valueInSeconds = Number(seconds);
    if (!isNaN(valueInSeconds)) {
      this.onChange(valueInSeconds * THOUSAND);
    }
  }

  @HostListener('blur')
  onBlur(): void {
    this.onTouched();
  }
}
