import { Fuel } from '@estimator/models';

export const fuelsMock: Fuel[] = [
  {
    id: 1,
    created_at: '2022-11-22T10:01:26.736947Z',
    created_by: 0,
    updated_at: '2022-11-22T10:01:26.736947Z',
    updated_by: 0,
    deleted_at: null,
    deleted_by: null,
    name: 'IFO-180',
    consumption_order: 1,
  },
  {
    id: 2,
    created_at: '2022-11-22T10:01:26.736947Z',
    created_by: 0,
    updated_at: '2022-11-22T10:01:26.736947Z',
    updated_by: 0,
    deleted_at: null,
    deleted_by: null,
    name: 'IFO-380',
    consumption_order: 2,
  },
  {
    id: 3,
    created_at: '2022-11-22T10:01:26.736947Z',
    created_by: 0,
    updated_at: '2022-11-22T10:01:26.736947Z',
    updated_by: 0,
    deleted_at: null,
    deleted_by: null,
    name: 'VLSFO',
    consumption_order: 3,
  },
  {
    id: 4,
    created_at: '2022-11-22T10:01:26.736947Z',
    created_by: 0,
    updated_at: '2022-11-22T10:01:26.736947Z',
    updated_by: 0,
    deleted_at: null,
    deleted_by: null,
    name: 'ULSFO',
    consumption_order: 4,
  },
  {
    id: 5,
    created_at: '2022-11-22T10:01:26.736947Z',
    created_by: 0,
    updated_at: '2022-11-22T10:01:26.736947Z',
    updated_by: 0,
    deleted_at: null,
    deleted_by: null,
    name: 'MDO',
    consumption_order: 5,
  },
  {
    id: 6,
    created_at: '2022-11-22T10:01:26.736947Z',
    created_by: 0,
    updated_at: '2022-11-22T10:01:26.736947Z',
    updated_by: 0,
    deleted_at: null,
    deleted_by: null,
    name: 'LSMGO',
    consumption_order: 6,
  },
  {
    id: 7,
    created_at: '2022-11-22T10:01:26.736947Z',
    created_by: 0,
    updated_at: '2022-11-22T10:01:26.736947Z',
    updated_by: 0,
    deleted_at: null,
    deleted_by: null,
    name: 'LNG',
    consumption_order: 7,
  },
  {
    id: 8,
    created_at: '2022-11-22T10:01:26.736947Z',
    created_by: 0,
    updated_at: '2022-11-22T10:01:26.736947Z',
    updated_by: 0,
    deleted_at: null,
    deleted_by: null,
    name: 'ELECTRO',
    consumption_order: 8,
  },
  {
    id: 9,
    created_at: '2022-11-22T10:01:26.736947Z',
    created_by: 0,
    updated_at: '2022-11-22T10:01:26.736947Z',
    updated_by: 0,
    deleted_at: null,
    deleted_by: null,
    name: 'HSFO',
    consumption_order: 9,
  },
];
