import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AgGridModule } from 'ag-grid-angular';
import { VesselsListComponent } from './vessels-list.component';

@NgModule({
  declarations: [VesselsListComponent],
  imports: [CommonModule, AgGridModule],
  exports: [VesselsListComponent],
})
export class VesselsListModule {}
