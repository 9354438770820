import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BreakEvenDataResponse,
  DEFAULT_BREAK_EVEN_STEP,
  DEFAULT_FIAT_MULTIPLIER,
  Deal,
  DealFinance,
  GetAllDealsWithParams,
} from '@estimator/models';
import { Observable } from 'rxjs';
import { BaseService } from '../base';

@Injectable({
  providedIn: 'root',
})
export class DealsService extends BaseService<Deal> {
  textStartVersion = 'start-version';
  constructor(protected override readonly http: HttpClient) {
    super(http);
    this.setPath('/api/deals');
  }

  updateDeal(deal: Partial<Deal>, updateStartVersion?: boolean): Observable<Deal> {
    const params = updateStartVersion
      ? { params: new HttpParams().set('update_sv', updateStartVersion) }
      : {};
    // delete deal.alternativeRoutes;
    delete deal.portsPoints;
    delete deal.route;
    return this.http.put<Deal>(`${this.path}/${deal.id}`, deal, params);
  }

  copyDeal(id: number, folder_id?: number): Observable<number> {
    const params = folder_id ? { params: new HttpParams().set('folder_id', folder_id) } : {};
    return this.http.post<number>(`${this.path}/${id}/copy`, id, params);
  }

  getAllWithParams(/* limit: number, offset: number */): Observable<GetAllDealsWithParams> {
    /*  let params = new HttpParams();
    params = params.set('limit', limit.toString());
    params = params.set('offset', offset.toString()); */
    return this.http.get<GetAllDealsWithParams>(this.path /* , { params } */);
  }

  updateFolderAtDeal(deal: Partial<Deal>): Observable<void> {
    return this.http.put<void>(`${this.path}/${deal.id}/raw`, {
      folder_id: deal.folder_id,
    });
  }

  // the method uses the same as the updateFolderAtDeal method, only there is an option to pass separate fields to update
  updateFieldDeal(deal: Partial<Deal>, params: Partial<Deal>): Observable<void> {
    return this.http.put<void>(`${this.path}/${deal.id}/raw`, params);
  }

  getDealStartVersion(id: number): Observable<Deal> {
    return this.http.get<Deal>(`${this.path}/${id}/${this.textStartVersion}`);
  }

  saveDealStartVersion(id: number): Observable<Deal> {
    return this.http.post<Deal>(`${this.path}/${id}/${this.textStartVersion}`, id);
  }

  getDealBreakEvenData(
    id: number,
    rise = DEFAULT_BREAK_EVEN_STEP
  ): Observable<BreakEvenDataResponse> {
    return this.http.get<BreakEvenDataResponse>(
      `${this.path}/${id}/break-even?rise=${rise * DEFAULT_FIAT_MULTIPLIER}`
    );
  }

  estimateDealFinances(deal: Partial<Deal>): Observable<DealFinance> {
    return this.http.put<Deal>(`${this.path}/${deal.id}/finances`, deal);
  }
}
