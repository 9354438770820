import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { localeDateFormatPrimeNGInput } from '@estimator/helpers';
import {
  OurPrimeCalendarSelectionMode,
  OurPrimeCalendarSelectionModeType,
} from '@estimator/models';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { Calendar } from 'primeng/calendar';

@Component({
  selector: 'estimator-date-editor',
  template: `
    <p-calendar
      [selectionMode]="selectionMode"
      [inline]="inline"
      [placeholder]="placeholder"
      [(ngModel)]="value"
      (onSelect)="onSelect($event)"
      [dateFormat]="dateFormat"
      [appendTo]="'body'"
      #calendar
      [panelStyleClass]="'ag-custom-component-popup'"
    ></p-calendar>
  `,
  styles: [
    `
      ::ng-deep .p-datepicker table td {
        padding: 0px !important;
      }
    `,
  ],
})
export class AgGridDateEditorComponent implements ICellEditorAngularComp {
  @ViewChild('calendar') calendar!: Calendar;
  @Input() needSelectEmit = false;
  @Input() placeholder = '';
  @Input() selectionMode: OurPrimeCalendarSelectionMode = OurPrimeCalendarSelectionModeType.SINGLE;
  @Input()
  set minDate(minDate: Date | undefined) {
    this._minDate = minDate;
    if (this.calendar) {
      this.calendar.minDate = minDate as Date;
    }
  }
  get minDate(): Date | undefined {
    return this._minDate;
  }
  @Input()
  set maxDate(maxDate: Date | undefined) {
    this._maxDate = maxDate;
    if (this.calendar) {
      this.calendar.maxDate = maxDate as Date;
    }
  }
  get maxDate(): Date | undefined {
    return this._maxDate;
  }
  @Output() selectValue = new EventEmitter<Date[]>();
  value?: string;
  inline = false;
  dateFormat = localeDateFormatPrimeNGInput();
  private _params: any;
  private _minDate?: Date | undefined;
  private _maxDate?: Date | undefined;

  agInit(params: any): void {
    this._params = params;
    if (params.value) {
      this.value = params.value;
    }
    if (params.inline) {
      this.inline = params.inline;
    }
    if (params.closeEditor) {
      this.closeEditor = params.closeEditor;
    }
  }

  isPopup(): boolean {
    return true;
  }

  getValue(): string {
    return this.value || '';
  }

  onSelect(value: Date) {
    if (this.needSelectEmit && this.calendar?.value[0] && this.calendar?.value[1]) {
      this.selectValue.emit(this.calendar?.value);
    }
    this.closeEditor();
  }

  closeEditor(): void {
    return;
  }
}
