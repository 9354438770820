import { EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FeatureCollection, GeoJsonProperties, Polygon } from 'geojson';
import { Subject } from 'rxjs';
import { BaseEntity, BaseModel, BaseModelFormGroup } from './base-entity';
import { GeoZone, ShiplistZoneType } from './geographic';
import { Port } from './port';
import { ColumnUI } from './ui-items';
import { User } from './user';

export interface ShiplistMapDialogConfig {
  shiplistZones: FeatureCollection<Polygon, GeoJsonProperties>;
  shiplistZonesArray: GeoZone[];
  requestShiplistZones: EventEmitter<ShiplistZoneType>;
  searchPort: EventEmitter<string>;
  saveFilterZonesPorts: EventEmitter<ShiplistSelectedZoneOrPort[]>;
  foundShiplistZones: Subject<FeatureCollection<Polygon, GeoJsonProperties>>;
  foundShiplistZonesArray: Subject<GeoZone[]>;
  foundPorts: Subject<Port[]>;
  selectedValues: ShiplistSelectedZoneOrPort[];
  selectedPorts: Port[];
}

export interface ShiplistSelectedZoneOrPort extends BaseModel {
  /**
   * don't use for value=Port, in zones use
   */
  type?: ShiplistZoneType | null;
  is_zone: boolean;
  /**
   * need for estimator-selected-values-list
   */
  checked: boolean;
  /**
   * uses also in GeoZone, ShiplistGeoZoneProperties, Port
   */
  loc_id: number;
  zone_ids: number[];
}

export interface ShiplistGeoZoneProperties extends BaseModel {
  color: string;
  type: ShiplistZoneType;
  selected: boolean;
  /**
   * uses also in GeoZone, ShiplistSelectedZoneOrPort, Port
   */
  loc_id: number;
  zone_ids: number[];
}

export interface ShiplistSelectedZoneOrPortEmitter {
  value: ShiplistSelectedZoneOrPort;
  add: boolean;
  port?: Port;
}

export interface ShipListVesselResModel extends BaseEntity {
  eta?: string;
  etd?: string;
  general_vessel_id?: number;
  message_date?: string;
  port_country_name?: string;
  port_id?: number;
  port_name?: string;
  vessel_built_year?: number;
  vessel_dead_weight?: number;
  vessel_manager?: string;
  /*  open_dates_from_seconds: string;
  open_dates_to_seconds: string; */
  vessel_name?: string;
  vessel_owner?: string;
  // zone_name?: string;
  zone_ids?: number[];
  destination_delta_eta?: number;
  destination_status?: ShipListDestinationStatus;
}

export interface ShiplistFilter extends BaseModel {
  allowed_users?: number[];
  company_id?: number;
  message_date_from_seconds?: number | Date;
  message_date_to_seconds?: number | Date;
  message_date_last_n_days?: number;
  open_dates_from_seconds?: number | Date;
  open_dates_to_seconds?: number | Date;
  open_dates_last_n_days?: number;
  port_ids?: number[];
  ports?: Port[];
  vessel_dead_weight_from?: number;
  vessel_dead_weight_to?: number;
  zone_ids?: number[];
  /**
   * for front only
   */
  filter_location?: ShiplistSelectedZoneOrPort[];
  allowed_users_array?: User[];
}

export interface ShiplistFormGroup extends BaseModelFormGroup {
  allowed_users?: FormControl<number[] | null>;
  company_id?: FormControl<number | null>;
  message_date_from_seconds?: FormControl<number | Date | null>;
  message_date_to_seconds?: FormControl<number | Date | null>;
  message_date_last_n_days?: FormControl<number | null>;
  open_dates_from_seconds?: FormControl<number | Date | null>; // will le open_dates_from_seconds
  open_dates_to_seconds?: FormControl<number | Date | null>; // will be open_dates_to_seconds
  open_dates_last_n_days?: FormControl<number | null>;
  port_ids?: FormControl<number[] | null>;
  vessel_dead_weight_from?: FormControl<number | null>;
  vessel_dead_weight_to?: FormControl<number | null>;
  zone_ids?: FormControl<number[] | null>;
  // filter_open_dates: FormControl<boolean>;
  // filter_updates: FormControl<boolean>;
  /**
   * for front only
   */
  filter_location?: FormControl<ShiplistSelectedZoneOrSelectedPortOrPort>;
  allowed_users_array?: FormControl<User[] | null>;
}

/* export interface ShipListFirstSourceReqBodyModel {
  message_date_last_n_days: number;
  message_date_from_seconds: number;
  message_date_to_seconds: number;
  open_dates_last_n_days: number;
  open_dates_from_seconds: number;
  open_dates_to_seconds: number;
  vessel_dead_weight_from: number;
  vessel_dead_weight_to: number;
  port_ids: number[];
  zone_ids: number[];
  allowed_users: number[];
} */

export interface ShiplistFilterCheckName {
  is_name_available: boolean;
}

export interface ShiplistTableResponse {
  shiplist_items?: ShipListVesselResModel[];
  total_amount_of_shiplist_items?: number;
}

export interface ShiplistTableParams {
  sort_order?: 'asc' | 'desc';
  sort_field?: string;
  limit: number;
  offset: number;
}

export enum ShipListSortingType {
  ASC = 'asc', // like 1, 2, 3, 4, 5, 6 // 1 in prime
  DESC = 'desc', // like 6, 5, 4, 3, 2, 1 // -1 in prime
}

export enum ShipListTableColumnType {
  ETA = /* 'open_dates_from_seconds' */ 'eta',
  ETD = /* 'open_dates_to_seconds' */ 'etd',
  MESSAGE_DATE = 'message_date',
  PORT_COUNTRY_NAME = 'port_country_name',
  PORT_NAME = 'port_name',
  VESSEL_BUILT_YEAR = 'vessel_built_year',
  VESSEL_DEAD_WEIGHT = 'vessel_dead_weight',
  VESSEL_MANAGER = 'vessel_manager',
  VESSEL_NAME = 'vessel_name',
  VESSEL_OWNER = 'vessel_owner',
  ZONE_NAME = 'zone_ids',
  DESTINATION_DELTA_ETA = 'destination_delta_eta',
  DESTINATION_STATUS = 'destination_status',
  DESTINATION_SAILING_TIME = 'destination_sailing_time',
}

export enum ShipListDestinationStatus {
  EARLY = 'EARLY',
  ON_TIME = 'ON_TIME',
  LATE = 'LATE',
}

export const SHIPLIST_TABLE_COLUMNS: ColumnUI<keyof ShipListVesselResModel>[] = [
  { field: ShipListTableColumnType.VESSEL_NAME, width: '100px', header: 'Name' },
  { field: ShipListTableColumnType.VESSEL_BUILT_YEAR, width: '100px', header: 'Built(y)' },
  { field: ShipListTableColumnType.VESSEL_DEAD_WEIGHT, width: '120px', header: 'DWT' },
  { field: ShipListTableColumnType.ETA, width: '100px', header: 'Open Dates (from)' },
  { field: ShipListTableColumnType.ETD, width: '100px', header: '(to)' },
  { field: /* 'zone_name' */ ShipListTableColumnType.ZONE_NAME, width: '100px', header: 'Zone' },
  { field: ShipListTableColumnType.PORT_NAME, width: '100px', header: 'Position' },
  { field: ShipListTableColumnType.PORT_COUNTRY_NAME, width: '120px', header: 'Country' },
  { field: ShipListTableColumnType.MESSAGE_DATE, width: '100px', header: 'Update' },
  { field: ShipListTableColumnType.VESSEL_MANAGER, width: '100px', header: 'TC Operator' },
  { field: ShipListTableColumnType.VESSEL_OWNER, width: '100px', header: 'Shipowner' },
];

export enum ShipListMode {
  Filter,
  Table,
}

export interface ShipListTableParamListSettingModel {
  sort: ShipListSortingType;
  sort_field: string;
}
/* export interface ShipListTableSettingModel {
  filter_value?: / FormGroup<ShiplistFormGroup>['value'] / ShiplistFilter;
  param_list?: ShipListTableParamListSettingModel;
} */

export type ShiplistSelectedZoneOrSelectedPortOrPort = ShiplistSelectedZoneOrPort[] | Port[] | null;
