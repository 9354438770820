import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { BaseEntity, BaseEntityFormGroup, BaseModel, Currency, Port, Vessel } from '@estimator/models';
import { ExchangeRateMode, HSOAStatement, HSOAStatus } from './hsoa';
import { Transaction } from './transaction';

export interface VSOA extends BaseEntity {
  agreement_id?: number;
  bill_of_lading_date?: string | Date | null;
  broker_amount?: number;
  broker_commission?: number;
  broker_commission_type?: BrokerCommissionType;
  cargo_dimension?: BaseModel;
  cargo_lump_sum?: number;
  cargo_name?: string;
  cargo_quantity?: number;
  cargo_price_per_unit?: number;
  cargo_total_price?: number;
  cargo_type?: string;
  cf_transaction?: Transaction;
  cf_transaction_id?: number;
  charter_party_date?: string | Date;
  charterers?: string;
  commission?: number;
  commission_amount?: number;
  currency_id?: number;
  is_base: boolean;
  date?: string | Date;
  discharging_port?: string;
  laytime_demurrage?: number;
  laytime_despatch?: number;
  laytime_id?: number;
  laytime_commission?: number;
  laytimes?: VSOALaytime[];
  less_received_from_charterers?: number;
  loading_port?: Port;
  loading_port_id?: number;
  number?: number;
  paid_statements?: HSOAStatement[];
  payment_due_date?: string | Date;
  status?: HSOAStatus;
  total_due?: number;
  user_fields?: VSOAUserType[];
  vessel_id?: number;
  parsed_vessel?: Vessel;
  currency?: Currency;
  exchange_rate?: number;
  exchange_rate_commission?: number;
  exchange_rate_mode?: ExchangeRateMode;
  total_due_in_currency?: number;
}

export interface VSOALaytime {
  commission_amount?: number;
  demurrage?: number;
  despatch?: number;
  id?: number;
}

export interface VSOAFormGroup extends BaseEntityFormGroup {
  charterers?: FormControl<string | null>;
  date?: FormControl<Date | string | null>;
  bill_of_lading_date?: FormControl<Date | string | null>;
  charter_party_date?: FormControl<Date | string | null>;
  cargo_name?: FormControl<string | null>;
  cargo_dimension?: FormControl<BaseModel | null>;
  cargo_quantity?: FormControl<number | null>;
  loading_port?: FormControl<Port | null>;
  loading_port_id?: FormControl<number | null>;
  discharging_port?: FormControl<string | null>;
  payment_due_date?: FormControl<Date | string | null>;
  currentTab: FormControl<VsoaViewType | null>;
  broker_commission?: FormControl<number | null>;
  laytime_commission?: FormControl<number | null>;
  commission?: FormControl<number | null>;
  exchange_rate?: FormControl<number | null>;
  exchange_rate_mode?: FormControl<ExchangeRateMode | null>;
  exchange_rate_commission?: FormControl<number | null>;
  currency?: FormControl<Currency | null>;
  broker_commission_type?: FormControl<BrokerCommissionType | null>;
  cargo_lump_sum?: FormControl<number | null>;
  cargo_price_per_unit?: FormControl<number | null>;
  user_fields?: FormArray<FormGroup<VSOATypesFormGroup>>;
}

export interface VSOATypesFormGroup {
  title?: FormControl<string | null>;
  value?: FormControl<number | null>;
}

export interface VSOAUserType {
  title?: string;
  value?: number;
}

export enum BrokerCommissionType {
  THERE_OFF = 'THERE_OFF',
  TOTAL = 'TOTAL',
}

export enum VsoaViewType {
  LUMPSUM = 'lumpsum',
  CARGO = 'cargo',
}
