import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  CoordinatesFormat,
  FULL_PERCENTS,
  MAX_NUMBERS_AFTER_DOT,
  MINUTES_IN_HOUR,
  SECONDS_IN_HOUR,
  SidesWorld,
} from '@estimator/models';
import IMask from 'imask';
import { isNumber } from 'lodash';
import { Subject, takeUntil } from 'rxjs';

/* широта = Y = latitude = -90 до 90 n s
долгота = X = longitude = -180 до 180 w e
n e - положительные
s w - отрицательные */

@Component({
  selector: 'estimator-coordinate-input',
  templateUrl: './coordinate-input.component.html',
  styleUrls: ['./coordinate-input.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoordinateInputComponent implements OnInit, OnDestroy {
  @Input()
  set isLatitude(bool: boolean) {
    this._isLatitude = bool;
    setTimeout(() => {
      this.setMask();
    }, 100);
    if (bool) {
      this.sidesWorld = [SidesWorld.N, SidesWorld.S];
      this.fg.get('sideWorld')?.setValue(SidesWorld.N);
    } else {
      this.max = 180;
      this.min = -180;
      this.sidesWorld = [SidesWorld.W, SidesWorld.E];
      this.fg.get('sideWorld')?.setValue(SidesWorld.E);
    }
  }
  get isLatitude() {
    return this._isLatitude;
  }
  @Input()
  set decimalValue(number: number | null) {
    this._decimalValue = number;
    const newNumber = number?.toString().includes('.')
      ? number.toString().replace('.', ',')
      : number?.toString();
    const finalNumber =
      this.type === CoordinatesFormat.DMS || this.type === CoordinatesFormat.DM
        ? (this.decimalValue || 0) < 0
          ? Math.ceil(+this.replaceCommaByDot(newNumber || '')).toString()
          : Math.floor(+this.replaceCommaByDot(newNumber || '')).toString()
        : (newNumber as string);
    this.fg.get('decimal')?.setValue(number || number === 0 ? finalNumber : null);
    // setTimeout(() => {
    // this.setMask();
    // }, 100);
    if ((number || 0) < 0 && this.isLatitude) {
      this.fg.get('sideWorld')?.setValue(SidesWorld.S);
    }
    if ((number || 0) < 0 && !this.isLatitude) {
      this.fg.get('sideWorld')?.setValue(SidesWorld.W);
    }
    this.toggleMinutesAndSeconds(number || 0);

    if (this.type === CoordinatesFormat.DMS) {
      const minutesAndSeconds = this.getDecimalMinutesAndSeconds(this.decimalValue);
      this.fg
        .get('minutes')
        ?.setValue(
          (minutesAndSeconds?.minutes || 0) > this.maxMinutesSeconds
            ? this.maxMinutesSeconds
            : minutesAndSeconds.minutes
        );
      this.fg
        .get('seconds')
        ?.setValue(
          (minutesAndSeconds?.seconds || 0) > this.maxMinutesSeconds
            ? this.maxMinutesSeconds
            : minutesAndSeconds.seconds
        );
    }

    if (this.type === CoordinatesFormat.DM) {
      const minutesAndSeconds = this.getDMMinutes(this.decimalValue);
      this.fg
        .get('minutes')
        ?.setValue(
          (minutesAndSeconds.minutes || 0) > this.maxMinutesSeconds
            ? this.maxMinutesSeconds
            : minutesAndSeconds.minutes
        );
      this.fg
        .get('seconds')
        ?.setValue(
          (minutesAndSeconds.decimals || 0) > this.maxDMDecimals
            ? this.maxDMDecimals
            : minutesAndSeconds.decimals
        );
    }
  }
  get decimalValue() {
    return this._decimalValue;
  }

  @Input()
  set type(type: CoordinatesFormat) {
    this._type = type;
    if (type === CoordinatesFormat.DMS || CoordinatesFormat.DM) {
      setTimeout(() => {
        this.setMinutesOrSecondsMask();
        this.setMinutesOrSecondsMask(false);
      }, 100);
    }
  }
  get type() {
    return this._type;
  }

  fg = new FormGroup({
    sideWorld: new FormControl<string | null>(null),
    decimal: new FormControl<string | null>(null),
    minutes: new FormControl<number | null>(null),
    seconds: new FormControl<number | null>(null),
  });
  sidesWorld: string[] = [];
  max = 90;
  min = -90;
  maxMinutesSeconds = 59;
  maxDMDecimals = 999;

  private _isLatitude = false;
  private _type = CoordinatesFormat.DECIMAL;
  private _decimalValue: number | null = null;
  private _onDestroy$ = new Subject<void>();

  readonly CoordinatesFormat = CoordinatesFormat;

  ngOnInit(): void {
    this.fg.controls.decimal.valueChanges
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((res: any) => {
        const transRes = this.replaceCommaByDot(res);
        this.toggleMinutesAndSeconds(+transRes);
        if (+transRes > this.max) {
          this.fg.controls.decimal.setValue(this.max.toString());
          this.disableMinutesAndSeconds();
        } else if (+transRes < this.min) {
          this.fg.controls.decimal.setValue(this.min.toString());
          this.disableMinutesAndSeconds();
        }
        if (+transRes < 0 && this.isLatitude) {
          this.fg.get('sideWorld')?.patchValue(SidesWorld.S, { emitEvent: false });
        }
        if (+transRes > 0 && this.isLatitude) {
          this.fg.get('sideWorld')?.patchValue(SidesWorld.N, { emitEvent: false });
        }
        if (+transRes < 0 && !this.isLatitude) {
          this.fg.get('sideWorld')?.patchValue(SidesWorld.W, { emitEvent: false });
        }
        if (+transRes > 0 && !this.isLatitude) {
          this.fg.get('sideWorld')?.patchValue(SidesWorld.E, { emitEvent: false });
        }
        /*  if (res === null) {
          res = 0;
        } */
      });
    this.fg.controls.sideWorld.valueChanges
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((res: any) => {
        this.fg.controls.decimal.setValue(
          res === SidesWorld.N || res === SidesWorld.E
            ? this.transformValue(this.fg.value.decimal as string).replace('-', '')
            : '-' + this.transformValue(this.fg.value.decimal as string)
        );
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  setMask() {
    const input = document.querySelector(`#decimal-${this.isLatitude}${this.type}`);
    IMask(input as HTMLElement, {
      mask: 'DEGREE',
      blocks: {
        DEGREE: {
          scale: this.type === CoordinatesFormat.DMS ? 0 : MAX_NUMBERS_AFTER_DOT,
          mask: Number,
          min: this.min,
          max: this.max,
          radix: ',', // fractional delimiter
          mapToRadix: ['.'], // symbols to process as radix
        },
      },
    });
  }

  setMinutesOrSecondsMask(minutes = true) {
    const name = minutes ? 'minutes' : 'seconds';
    const input = document.querySelector(`#${name}-${this.isLatitude}${this.type}`);
    IMask(input as HTMLElement, {
      mask: 'MINUTES',
      blocks: {
        MINUTES: {
          scale: 0,
          mask: Number,
          min: 0,
          max:
            this.type === CoordinatesFormat.DMS || (this.type === CoordinatesFormat.DM && minutes)
              ? this.maxMinutesSeconds
              : this.maxDMDecimals,
        },
      },
    });
  }

  getValue(): number | null {
    if (!this.fg.dirty) {
      return this.decimalValue;
    }
    let dmsOrDmValue = 0;
    if (this.type === CoordinatesFormat.DMS) {
      const decimal = +(+this.replaceCommaByDot(this.fg.value.decimal?.toString() || '0')).toFixed(
        0
      );
      const minutes = +(this.fg.getRawValue()?.minutes || 0) / MINUTES_IN_HOUR;
      const seconds = +(this.fg.getRawValue().seconds || 0) / SECONDS_IN_HOUR;
      const finalMinutes = decimal < 0 ? -minutes : minutes;
      const finalSeconds = decimal < 0 ? -seconds : seconds;
      dmsOrDmValue = +(decimal + finalMinutes + finalSeconds).toFixed(MAX_NUMBERS_AFTER_DOT);
    }
    if (this.type === CoordinatesFormat.DM) {
      const decimal = +(+this.replaceCommaByDot(this.fg.value.decimal?.toString() || '0')).toFixed(
        0
      );
      const minutes = +(this.fg.getRawValue().minutes || 0);
      const decimals = +(this.fg.getRawValue().seconds || 0) / FULL_PERCENTS;
      const finalMinutes = decimal < 0 ? -minutes : minutes;
      const finalDecimals = decimal < 0 ? -decimals : decimals;
      const finalMinAndSec = (+finalMinutes + +finalDecimals) / MINUTES_IN_HOUR;
      dmsOrDmValue = +(decimal + finalMinAndSec).toFixed(MAX_NUMBERS_AFTER_DOT);
    }
    const finalDmsOrDmValue = this.isEmptyFg() ? null : dmsOrDmValue;
    return this.type === CoordinatesFormat.DMS || this.type === CoordinatesFormat.DM
      ? finalDmsOrDmValue
      : this.fg.value.decimal
      ? +this.replaceCommaByDot(this.fg.value.decimal)
      : null;
  }

  isEmptyFg() {
    return (
      this.fg.getRawValue().decimal === '' &&
      this.fg.getRawValue().minutes?.toString() === '' &&
      this.fg.getRawValue().seconds?.toString() === ''
    );
  }

  replaceCommaByDot(value: string): string {
    return value.toString().includes(',') ? value.toString().replace(',', '.') : value;
  }

  transformValue(value: string) {
    return this.type === CoordinatesFormat.DMS
      ? Math.floor(+this.replaceCommaByDot(value)).toString()
      : value;
  }

  getDecimalMinutesAndSeconds(value: number | null) {
    if (isNumber(value) || value === 0) {
      const absValue = Math.abs(value);
      const degree = Math.floor(absValue);
      const minutes =
        absValue && degree ? (absValue % degree) * MINUTES_IN_HOUR : absValue * MINUTES_IN_HOUR;
      const seconds = minutes ? (minutes % Math.floor(minutes)) * MINUTES_IN_HOUR : 0;
      return {
        minutes: minutes || 0,
        seconds: seconds || 0,
      };
    }
    return { minutes: null, seconds: null };
  }

  getDMMinutes(value: number | null) {
    if (isNumber(value) || value === 0) {
      const absValue = Math.abs(value);
      const degree = Math.floor(absValue);
      const minutes = (
        absValue && degree ? (absValue % degree) * MINUTES_IN_HOUR : absValue * MINUTES_IN_HOUR
      ) as number;
      const decimals = minutes % Math.floor(minutes);
      const finalDecimals = decimals ? decimals * FULL_PERCENTS : 0;
      return {
        minutes: minutes || 0,
        decimals: finalDecimals || 0,
      };
    }
    return { minutes: null, decimals: null };
  }

  toggleMinutesAndSeconds(decimal: number) {
    if (this.type !== CoordinatesFormat.DECIMAL) {
      const condition = +decimal === this.max || +decimal === this.min;
      if (condition) {
        this.disableMinutesAndSeconds();
      } else {
        this.fg.get('minutes')?.enable();
        this.fg.get('seconds')?.enable();
      }
    }
  }

  disableMinutesAndSeconds() {
    this.fg.get('minutes')?.setValue(0);
    this.fg.get('minutes')?.disable();
    this.fg.get('seconds')?.setValue(0);
    this.fg.get('seconds')?.disable();
  }
}
