import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material/core';
import {
  FOURTY_NUMBER,
  MaterialThemeColor,
  Port,
  SearchRawVesselsAndPortsResponse,
  SelectVesselOrPort,
  THREE_NUMBER,
  Vessel,
  emptySearchRawVesselsAndPortsResponse,
} from '@estimator/models';
import { isString } from 'lodash';
import { Subject, debounceTime, takeUntil } from 'rxjs';

@Component({
  selector: 'estimator-vessel-port-search',
  templateUrl: './vessel-port-search.component.html',
  styleUrls: ['./vessel-port-search.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VesselPortSearchComponent implements OnInit, OnDestroy {
  @Input()
  set vesselsPorts(data: SearchRawVesselsAndPortsResponse) {
    this._vesselsPorts = data;
  }
  get vesselsPorts(): SearchRawVesselsAndPortsResponse {
    return this._vesselsPorts;
  }
  @Input()
  set isLoading(bool: boolean) {
    this._isLoading = bool;
  }
  get isLoading(): boolean {
    return this._isLoading;
  }

  @Output() searchVesselOrPort = new EventEmitter<string>();
  @Output() selectVesselOrPort = new EventEmitter<SelectVesselOrPort>();

  vesselOrPortSelectControl = new FormControl('');
  MaterialThemeColor = MaterialThemeColor;

  private _vesselsPorts: SearchRawVesselsAndPortsResponse = emptySearchRawVesselsAndPortsResponse;
  private _onDestroy$ = new Subject<void>();
  private _isLoading = false;

  ngOnInit(): void {
    this.vesselOrPortSelectControl.valueChanges
      .pipe(debounceTime(500), takeUntil(this._onDestroy$))
      .subscribe((value) => {
        if (
          value &&
          isString(value) &&
          value?.length >= THREE_NUMBER &&
          value?.length <= FOURTY_NUMBER
        ) {
          this.searchVesselOrPort.emit(value);
        }
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  onVesselOrPortSelect(
    vesselOrPort: Partial<Vessel | Port>,
    vessel: boolean,
    event: MatOptionSelectionChange
  ): void {
    if (event.isUserInput) {
      this.vesselOrPortSelectControl.setValue(vesselOrPort?.name as string);
      this.selectVesselOrPort.emit({
        vesselOrPort: vesselOrPort,
        vessel: vessel,
        fromSearch: true,
      });
    }
  }
}
