import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from '@estimator/directives';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { PriceAndCurrencyInputComponent } from './price-and-currency-input.component';

export const priceAndCurrencyModuleImports = [
  CommonModule,
  DropdownModule,
  InputNumberModule,
  ReactiveFormsModule,
  DirectivesModule,
];

@NgModule({
  declarations: [PriceAndCurrencyInputComponent],
  imports: priceAndCurrencyModuleImports,
  exports: [PriceAndCurrencyInputComponent],
})
export class PriceAndCurrencyInputModule {}
