import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TabViewModule } from 'primeng/tabview';
import { FleetVesselModule } from '../fleet-vessel/fleet-vessel.module';
import { FleetVesselsComponent } from './fleet-vessels.component';

@NgModule({
  declarations: [FleetVesselsComponent],
  imports: [CommonModule, TabViewModule, FleetVesselModule],
  exports: [FleetVesselsComponent],
})
export class FleetVesselsModule {}
