export const platformCurrencyMock = [
  {
    id: 'f474f521-39b6-4450-9b2a-51bb1f093a9f',
    code: 'USD',
    active: true,
    __typename: 'currency',
  },
  {
    id: 'dacd7281-7528-421a-b3b3-8b8e6cd0cab1',
    code: 'EUR',
    active: true,
    __typename: 'currency',
  },
];
