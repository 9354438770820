import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MaterialThemeColor, STRING } from '@estimator/models';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { debounceTime, Observable } from 'rxjs';

@Component({
  selector: 'estimator-autocomplete-editor',
  template: `
    <input
      type="text"
      [placeholder]="placeholder"
      aria-label="Number"
      matInput
      [formControl]="formControl"
      [matAutocomplete]="portAutoComplete"
      class="aggrid-input aggrid-autocomplete-editor"
      (input)="onInput($event)"
      #autoCompleteInput
    />
    <mat-icon *ngIf="isLoading" matSuffix class="ag-grid-ac-loader">
      <mat-spinner [color]="MaterialThemeColor.Primary" [diameter]="20"></mat-spinner>
    </mat-icon>
    <mat-autocomplete
      #auto="matAutocomplete"
      panelClass="ag-grid-select-panel ag-autocomplete"
      #portAutoComplete
      name="portSearch"
      class="ag-grid-autocomplete"
      (click)="stopPropagation($event)"
    >
      @if(async) {
      <mat-option
        *ngFor="let data of dataArray$ | async"
        [value]="data"
        (mouseover)="latestOption(data)"
        (onSelectionChange)="onOptionSelect(data)"
      >
        <div class="option-row" *ngFor="let key of visibleKeys">
          <estimator-color-picker-renderer
            class="estimator-color-picker-renderer"
            *ngIf="fieldForExtendedComponent"
            [color]="data[fieldForExtendedComponent]"
          ></estimator-color-picker-renderer>
          <span>{{ data[key] }}</span>
        </div>
      </mat-option>
      <ng-container *ngIf="(dataArray$ | async)?.length && !isLoading">
        <mat-option [disabled]="true">
          <p>{{ emptyDataArray }}</p>
        </mat-option>
      </ng-container>
      } @else {
      <mat-option
        *ngFor="let data of dataArray"
        [value]="data"
        (onSelectionChange)="onOptionSelect(data)"
      >
        <div class="option-row" *ngFor="let key of visibleKeys">
          <estimator-color-picker-renderer
            class="estimator-color-picker-renderer"
            *ngIf="fieldForExtendedComponent"
            [color]="data[fieldForExtendedComponent]"
          ></estimator-color-picker-renderer>
          <span>{{ data[key] }}</span>
        </div>
      </mat-option>
      <ng-container *ngIf="dataArray.length < 1 && !isLoading">
        <mat-option [disabled]="true">
          <p>{{ emptyDataArray }}</p>
        </mat-option>
      </ng-container>
      }
    </mat-autocomplete>
  `,
  styles: [
    `
      .ag-grid-ac-loader {
        margin-left: -24px;
      }

      .cdk-overlay-pane {
        min-width: 0;
      }

      .aggrid-autocomplete-editor {
        width: 100%;
        height: 100%;
        border: none;
        background: none;
      }

      ::ng-deep .ag-grid-autocomplete {
        .mat-mdc-option .mdc-list-item__primary-text {
          height: 100% !important;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .option-row {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 100%;
            span {
              height: auto;
              line-height: 20px;
            }
          }
        }
      }
      .estimator-color-picker-renderer {
        margin-right: 10px;
      }
    `,
  ],
})
export class AgGridAutocompleteComponent implements AfterViewInit, ICellEditorAngularComp {
  @ViewChild('autoCompleteInput', { read: ViewContainerRef }) input!: ViewContainerRef;
  @Input() dataArray$?: Observable<any[]>; // Accepting options as an input from parent
  @Input() async = false;
  search: any; // Function that emits on input change
  value!: any;
  dataArray: any[] = [];
  visibleKeys: string[] = [];
  fieldForExtendedComponent = '';
  formControl = new FormControl();
  filter?: (req: string) => any;
  isLoading = false;
  filteredDataArray: any[] = [];
  placeholder = 'Type';
  emptyDataArray = 'Nothing found';
  resultKey?: string;
  MaterialThemeColor = MaterialThemeColor;
  private _params: any;

  constructor(private readonly cdr: ChangeDetectorRef) {}

  @HostListener('click', ['$event'])
  stopPropagation(event: MouseEvent): void {
    event.stopImmediatePropagation();
  }

  ngAfterViewInit() {
    window.setTimeout(() => {
      this.input.element.nativeElement.select();
    });
  }

  agInit(params: any): void {
    this._params = params;
    if (params.dataArray) {
      this.dataArray = params.dataArray;
      this.filteredDataArray = params.dataArray;
    }
    if (params.visibleKeys) {
      this.visibleKeys = params.visibleKeys;
    }

    if (params.dataArray$) {
      this.dataArray$ = params.dataArray$;
    }
    if (params.async) {
      this.async = params.async;
    }
    if (params.search) {
      this.search = params.search;
    }
    if (params.fieldForExtendedComponent) {
      this.fieldForExtendedComponent = params.fieldForExtendedComponent;
    }
    if (params.value) {
      this.value = params.value;
      this.formControl.patchValue(params.value[this.visibleKeys[0]]);
    }
    if (params.filter) {
      this.filter = params.filter;
    }
    if (params.placeholder) {
      this.placeholder = params.placeholder;
    }
    if (params.emptyDataArray) {
      this.emptyDataArray = params.emptyDataArray;
    }
    if (params.resultKey) {
      this.resultKey = params.resultKey;
      const value = this.dataArray.find((elem) => elem[params.resultKey] === params.value);
      if (value) {
        this.formControl.patchValue(value[this.visibleKeys[0]]);
      }
    }
    if (params.closeEditor) {
      this.closeEditor = params.closeEditor;
    }
    this.formControl.valueChanges.pipe(debounceTime(500)).subscribe((res) => {
      if (res && typeof res === STRING) {
        if (this.filter) {
          this.filter(res);
        } else {
          this.dataArray = this.filteredDataArray.filter((value) => {
            return this.visibleKeys.some((key) => {
              return value[key].trim().toString().toLowerCase().includes(res.trim().toLowerCase());
            });
          });
          this.cdr.detectChanges();
        }
      }
    });
  }

  onOptionSelect(value: any): void {
    this.value = value;
    this.formControl.patchValue(value[this.visibleKeys[0]]);
    this.closeEditor();
  }

  latestOption(value: any) {
    // Handler for saving latest hovered value, optionSelect doesnt work with stopEditingWhenCellsLoseFocus: true
    this.value = value;
    this.formControl.patchValue(value[this.visibleKeys[0]]);
  }

  getValue(): any {
    if (this.resultKey && typeof this.value === 'object') {
      return this.value[this.resultKey];
    }
    return this.value;
  }

  onInput(value: any) {
    this.search(value.target.value);
  }

  compareObjects(o1: any, o2: any): boolean {
    return o1?.id === o2?.id;
  }

  closeEditor(): void {
    return;
  }
}
