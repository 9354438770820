import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import {
  OurGeometry,
  checkLinkTanker,
  clearCopyAlienLocalStorageKeys,
  emptyFeatureCollection,
  featuresToFeatureCollection,
  getDealsIds,
  getNavigationFromEstimationsNavigationLinks,
  getUserOrSystemDuration,
  getValueOrDefault,
  isCanalEvent,
  isPrimaryEvent,
  isSecondaryEvent,
  isStopEvent,
  makeFeaturePoint,
  makeFeaturePort,
  pointExistedForEvent,
  setImageFieldToFeatures,
  stayOnlyMyFolders,
} from '@estimator/helpers';
import {
  AlternativeRoute,
  BreakEvenData,
  Cargo,
  CargoCalculator,
  CargoTableFreightType,
  CascadeNavigation,
  DIVISOR_FOR_PERCENT,
  DRAFT_FOLDER_ID,
  DaysDistanceIndicators,
  Deal,
  DealEvent,
  DealEventType,
  DealFinance,
  DealNavigationLink,
  EMPTY_LIST_TITLE,
  ESTIMATOR_ROUTE,
  ErrorNotifications,
  FinanceTotals,
  FixFolderEmitter,
  Folder,
  FuelTypeCoef,
  GeoCoordinates,
  IS_COPY_ALIEN_DEAL_KEY,
  IS_COPY_ALIEN_FOLDER_KEY,
  LIST_TITLE,
  MILLION,
  MapFeatureProperties,
  MapTypes,
  NotificationType,
  Port,
  PresetNames,
  SaveFixDialogConfig,
  SaveFolderDialogConfig,
  SaveFolderEmitter,
  SuccessNotifications,
  SummLDRateAndETbyCargoID,
  UiSettingsStateModel,
  VoyageType,
  emptyFinanceTotals,
} from '@estimator/models';
import {
  CargoCalculatorService,
  CargoService,
  DealEventService,
  DealsService,
  NotificationService,
  RouteService,
} from '@estimator/services';
import { FixFolderComponent, SaveFolderComponent } from '@estimator/ui';
import { Action, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import * as turf from '@turf/turf';
import { Properties } from '@turf/turf';
import { Feature, FeatureCollection, GeoJsonProperties, Geometry, LineString } from 'geojson';
import { cloneDeep, isNumber, isString } from 'lodash';
import moment from 'moment';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import {
  Observable,
  Subject,
  catchError,
  debounceTime,
  finalize,
  forkJoin,
  of,
  takeUntil,
  tap,
  throwError,
} from 'rxjs';
import { CopyFolder, CreateFolder, GetFoldersBySearch, SetDealsOrderInFolder } from '../folder';
import { FolderStateModel } from '../folder/folder.state';
import { AddVesselToState } from '../vessel';
import { XAuthState } from '../x-auth/x-auth.state';
import {
  CalculateCargo,
  CalculateCargoGroup,
  ChangeValidationErrors,
  ClearCloseTabName,
  CloseFolderInCurrEstimation,
  CloseFolderInLastEstimation,
  CopyDeal,
  CopyDealToOtherFolder,
  CreateCargo,
  CreateDeal,
  CreateEvent,
  CreateStartVersionDeal,
  DeleteCargo,
  DeleteDeal,
  DeleteEvent,
  EstimateDealFinances,
  FilterStartCopiedDeals,
  GetAllAlternativeRoutes,
  GetAllDeals,
  GetBreakEvenData,
  GetDeal,
  GetLastConstantsForCargo,
  NavigateToDeal,
  OpenFixFolderModal,
  /*   OpenDialogOpenCloseFolder, */
  OpenSaveFolderModal,
  RefreshColorsRoute,
  ReplaceEvent,
  ResetCascadeNavigation,
  ResetNavigatedDeal,
  ResetSelectedDeal,
  ResetValidationErrors,
  RevertDeal,
  SetCascadeNavigation,
  SetChanges,
  SetCurrentEditedCopy,
  SetEstimationsNavigationLinks,
  SetEventsFormInvalidStatus,
  SetSummETwithoutCargoID,
  SetSummLDRateAndETbyCargoID,
  UpdateCargo,
  UpdateCargoInSelectedDeal,
  UpdateDeal,
  UpdateDealCancelUncompletedFalse,
  UpdateEvent,
  UpdateFieldDeal,
  UpdateFolderAtDeal,
  UpdateHireFinanceInDeal,
  UpdateVesselInDeal,
} from './deal.actions';

export interface DealStateModel {
  deals: Deal[];
  allDealsCount: number;
  loading: boolean;
  selectedDeal: Deal | null;
  selectedCargoes: Cargo[];
  loadingCargo: boolean;
  lastUpdatedEvent?: DealEvent;
  navigatedDeal: Deal | null;
  dealsAlreadyRequested: boolean;
  daysDistanceIndicators: DaysDistanceIndicators;
  closeTabName?: string;
  selectDealChanges?: boolean;
  navigation: CascadeNavigation[];
  startCopiedDeals: Deal[];
  estimationsNavigationLinks: DealNavigationLink[];
  currentEditedCopy: Deal | null;
  eventsFormInvalid: boolean;
  selectedPresetNamesInEvents: PresetNames[];
  validationErrors: string[];
  finances: DealFinance | null;
  breakEvenData: BreakEvenData[];
  // for tanker demurrage
  summLDRateAndETbyCargoID: SummLDRateAndETbyCargoID[];
  summETwithoutCargoID: number;
  alternatives: FeatureCollection<Geometry, GeoJsonProperties>[];
}

@State<DealStateModel>({
  name: 'deal',
  defaults: {
    deals: [],
    allDealsCount: 0,
    loading: false,
    selectedDeal: null,
    loadingCargo: false,
    selectedCargoes: [],
    dealsAlreadyRequested: false,
    navigatedDeal: null,
    daysDistanceIndicators: {},
    navigation: [],
    startCopiedDeals: [],
    estimationsNavigationLinks: [],
    currentEditedCopy: null,
    eventsFormInvalid: false,
    selectedPresetNamesInEvents: [],
    validationErrors: [],
    finances: null,
    summLDRateAndETbyCargoID: [],
    summETwithoutCargoID: 0,
    breakEvenData: [],
    alternatives: [],
  },
})
@Injectable()
export class DealState implements NgxsOnInit, OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _stateName = 'Deal';
  @Selector()
  static getIsLoading({ loading }: DealStateModel): boolean {
    return loading;
  }
  @Selector()
  static getDeals({ deals }: DealStateModel): Deal[] {
    return deals;
  }
  @Selector()
  static getSelectedDeal({ selectedDeal }: DealStateModel): Deal | null {
    return selectedDeal;
  }
  @Selector()
  static getSelectedCargoes({ selectedCargoes }: DealStateModel): Cargo[] {
    return selectedCargoes;
  }
  @Selector()
  static getLoadingCargo({ loadingCargo }: DealStateModel): boolean {
    return loadingCargo;
  }
  @Selector()
  static getLastUpdatedEvent({ lastUpdatedEvent }: DealStateModel): DealEvent | undefined {
    return lastUpdatedEvent;
  }
  @Selector()
  static getNavigatedDeal({ navigatedDeal }: DealStateModel): Deal | null {
    return navigatedDeal;
  }
  @Selector()
  static getOpenFolder({ selectedDeal }: DealStateModel): Folder | undefined {
    return selectedDeal?.folder;
  }
  @Selector()
  static getDaysDistanceIndicators({
    daysDistanceIndicators,
  }: DealStateModel): DaysDistanceIndicators {
    return daysDistanceIndicators;
  }
  @Selector()
  static getCloseTabName({ closeTabName }: DealStateModel): string | undefined {
    return closeTabName;
  }
  @Selector()
  static getHasChanges({ selectDealChanges }: DealStateModel): boolean | undefined {
    return selectDealChanges;
  }
  @Selector()
  static getCascadeNavigation({ navigation }: DealStateModel): CascadeNavigation[] {
    return navigation;
  }
  @Selector()
  static getStartCopiedDeals({ startCopiedDeals }: DealStateModel): Deal[] {
    return startCopiedDeals;
  }
  @Selector()
  static getEstimationsNavigationLinks({
    estimationsNavigationLinks,
  }: DealStateModel): DealNavigationLink[] {
    return estimationsNavigationLinks;
  }
  @Selector()
  static getCurrentEditedCopy({ currentEditedCopy }: DealStateModel): Deal | null {
    return currentEditedCopy;
  }
  @Selector()
  static getEventsFormInvalid({ eventsFormInvalid }: DealStateModel): boolean {
    return eventsFormInvalid;
  }
  @Selector()
  static getSelectedPresetNamesInEvents({
    selectedPresetNamesInEvents,
  }: DealStateModel): PresetNames[] {
    return selectedPresetNamesInEvents;
  }
  @Selector()
  static getValidationErrors({ validationErrors }: DealStateModel): string[] {
    return validationErrors;
  }
  @Selector()
  static getFinances({ finances }: DealStateModel): DealFinance | null {
    return finances;
  }
  @Selector()
  static getSummLDRateAndETbyCargoID({
    summLDRateAndETbyCargoID,
  }: DealStateModel): SummLDRateAndETbyCargoID[] {
    return summLDRateAndETbyCargoID;
  }
  @Selector()
  static getSummETwithoutCargoID({ summETwithoutCargoID }: DealStateModel): number {
    return summETwithoutCargoID;
  }
  @Selector()
  static getAlternatives({
    alternatives,
  }: DealStateModel): FeatureCollection<Geometry, GeoJsonProperties>[] {
    return alternatives;
  }

  constructor(
    private readonly dealService: DealsService,
    private readonly cargoService: CargoService,
    private readonly dealEventService: DealEventService,
    private readonly notificationService: NotificationService,
    private readonly cargoCalculatorService: CargoCalculatorService,
    private readonly dialogService: DialogService,
    private readonly store: Store,
    private readonly router: Router,
    private readonly routeService: RouteService
  ) {}

  ngxsOnInit() {
    clearCopyAlienLocalStorageKeys();
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  static generateRouteForStopEvent(
    deal: Deal,
    settings: UiSettingsStateModel,
    selectedPresetNamesInEvents: PresetNames[],
    dispatch: (action: GetAllAlternativeRoutes) => void,
    changePort?: boolean
  ): void {
    deal.route = emptyFeatureCollection();
    deal.portsPoints = emptyFeatureCollection();
    const alternativeRoutes: [] = [];

    const features: Feature[] = [];

    if (changePort) {
      const stops = deal.events?.filter((event) => isStopEvent(event) && event.port) as DealEvent[];
      const action = new GetAllAlternativeRoutes(stops, alternativeRoutes);
      dispatch(action);
    }

    deal.events?.forEach((event, i) => {
      const pointExistedAndNotCanal = pointExistedForEvent(event) && !isCanalEvent(event);
      if (
        (event.port_id || pointExistedAndNotCanal) &&
        !features.some((feature) => {
          const featureGeometry = feature.geometry as OurGeometry;
          return pointExistedAndNotCanal
            ? featureGeometry.coordinates[0] === event?.point?.longitude &&
                featureGeometry.coordinates[1] === event?.point?.latitude
            : feature?.properties &&
                feature?.properties[MapFeatureProperties.Name]?.toLowerCase() ===
                  event?.port?.name?.toLowerCase();
        })
      ) {
        features.push(
          pointExistedAndNotCanal
            ? makeFeaturePoint(event.point as GeoCoordinates, event)
            : makeFeaturePort(event.port as Port)
        );
        deal.portsPoints = featuresToFeatureCollection(features);
      }
      if (event.meta?.route_geojson?.features?.length) {
        event.meta.route_geojson.features.forEach((feature) => {
          if (feature?.properties) {
            const colorText = MapFeatureProperties.RouteColor;
            if (event.meta?.is_laden) {
              feature.properties[colorText] = settings.laden_route_color;
            } else {
              feature.properties[colorText] = settings.ballast_route_color;
            }
          }
        });

        deal.route?.features.push(...event.meta.route_geojson.features);
      }
      if (event.type === DealEventType.EventTypeStop && i > 0) {
        const route: FeatureCollection = emptyFeatureCollection();
        let limit = -1;
        /* let distance = 0;
        let eca_distance = 0; */
        /*  let sailing_time = 0;
        let port_time = 0; */
        for (
          let previousStopEventIndex = i - 1;
          previousStopEventIndex > 0;
          previousStopEventIndex--
        ) {
          if (
            deal.events &&
            deal.events[previousStopEventIndex]?.type === DealEventType.EventTypeStop
          ) {
            break;
          }
          limit = previousStopEventIndex;
        }
        if (limit >= 0) {
          while (limit <= i) {
            if (deal.events) {
              const features = deal.events[limit]?.meta?.route_geojson?.features;
              if (features?.length) {
                route.features.push(...features);
              }
              const previousEvent = deal.events[limit - 1];
              if (
                previousEvent &&
                isCanalEvent(previousEvent) &&
                previousEvent.meta?.distance &&
                !previousEvent.meta?.disbursement &&
                !previousEvent.meta?.extra_minutes_after?.system &&
                !previousEvent.meta?.extra_minutes_after?.user &&
                !previousEvent.meta?.extra_minutes_before?.system &&
                !previousEvent.meta?.extra_minutes_before?.user &&
                !previousEvent.meta?.idle_minutes?.system &&
                !previousEvent.meta?.idle_minutes?.user
              ) {
                /* distance += previousEvent.meta.distance;
                eca_distance += previousEvent.meta.eca_distance || 0; */
                /* sailing_time += getUserOrSystemDuration(previousEvent.meta.sailing_minutes) || 0;
                port_time += getUserOrSystemDuration(previousEvent.meta.port_time) || 0; */
              }
              limit += 1;
            }
          }
        }
        if (!event.meta) {
          event.meta = {};
        }
        if (limit >= 0) {
          event.meta.route_geojson = route;
        }
        /* if (distance > 0) {
          event.meta.distance_with_canals = distance + (event.meta.distance || 0);
          event.meta.eca_distance_with_canals = eca_distance + (event.meta.eca_distance || 0);
        } */
        /*  if (sailing_time > 0) {
          if (!event.meta.sailing_minutes_with_canals) {
            event.meta.sailing_minutes_with_canals = {};
          }
          event.meta.sailing_minutes_with_canals.system =
            sailing_time + (getUserOrSystemDuration(event.meta.sailing_minutes) || 0);
        } */
        /*  if (port_time > 0) {
          if (!event.meta.port_time_with_canals) {
            event.meta.port_time_with_canals = {};
          }
          event.meta.port_time_with_canals.system =
            port_time + (getUserOrSystemDuration(event.meta.port_time) || 0);
        } */
      }

      if (isPrimaryEvent(event) && !isCanalEvent(event) && i !== 0) {
        /* !selectedPresetNamesInEvents.includes(event.meta?.preset?.name as PresetNames) && */
        event.meta?.preset?.name
          ? selectedPresetNamesInEvents.push(event.meta?.preset?.name as PresetNames)
          : null;
      }
    });
    // присваиваем поле для отображения нумерации портов на карте эстиматора
    setImageFieldToFeatures(deal.portsPoints);
  }

  static daysDistanceIndicators(deal: Deal): DaysDistanceIndicators {
    // блок days
    let hireMinutes = 0;
    let sailingMinutes = 0;
    let loadDischargeMinutes = 0;
    let idleMinutes = 0;
    let reserveMinutes = 0;
    // блок distance
    let distance = 0;
    let ecaDistance = 0;
    let balDistance = 0; // без груза
    let ladDistance = 0; // с грузом
    // для EEOI
    let summFuels = 0;
    let cargoXDistance = 0;

    if (deal.finances?.hire_finance?.hire_minutes?.system) {
      hireMinutes = deal.finances?.hire_finance?.hire_minutes?.system;
    }
    deal.events?.forEach((event) => {
      // дни
      if (event.meta?.sailing_minutes) {
        sailingMinutes += getUserOrSystemDuration(event.meta?.sailing_minutes);
      }
      if (
        event.meta?.working_minutes?.user !== undefined &&
        event.meta?.working_minutes?.user !== null &&
        event.meta?.working_minutes?.system !== undefined &&
        event.meta?.working_minutes?.system !== null
      ) {
        loadDischargeMinutes += event?.meta?.working_minutes?.is_changed
          ? +event?.meta?.working_minutes?.user
          : +event?.meta?.working_minutes?.system;
      }
      if (event.meta?.idle_minutes) {
        idleMinutes += getUserOrSystemDuration(event.meta?.idle_minutes);
      }
      if (event.meta?.extra_minutes_before) {
        idleMinutes += getUserOrSystemDuration(event.meta?.extra_minutes_before);
      }
      if (event.meta?.extra_minutes_after) {
        idleMinutes += getUserOrSystemDuration(event.meta?.extra_minutes_after);
      }

      // дистанции
      if (event.meta?.distance) {
        distance += event.meta?.distance;
        if (event.meta?.total_load) {
          cargoXDistance += event.meta?.total_load * event.meta?.distance;
          balDistance += event.meta?.distance;
        } else {
          ladDistance += event.meta?.distance;
        }
      }
      if (event.meta?.eca_distance) {
        ecaDistance += event.meta?.eca_distance;
      }
    });
    // дни
    // if (deal.finances?.hire_finance?.additional_minutes) {
    reserveMinutes =
      getUserOrSystemDuration(deal.finances?.hire_finance?.extra_idle_minutes) +
      getUserOrSystemDuration(deal.finances?.hire_finance?.extra_sailing_laden_minutes);
    // }
    // eeoi
    if (deal.fuel_consumptions) {
      deal.fuel_consumptions.forEach((fuel) => {
        if (fuel?.amount && fuel?.fuel_id) {
          summFuels += fuel?.amount * FuelTypeCoef[fuel?.fuel_id];
        }
      });
    }
    return {
      hireMinutes,
      sailingMinutes,
      loadDischargeMinutes,
      idleMinutes,
      reserveMinutes,
      distance,
      ecaDistance,
      balDistance,
      ladDistance,
      EEOI: summFuels && cargoXDistance ? (summFuels / cargoXDistance) * MILLION : 0, // умножаем на MILLION, чтобы показать значение 10 в минус 6 степени
    };
  }

  static calculateCargoEvents(deal: Deal): void {
    if (deal?.events?.length) {
      deal.events.forEach((event) => {
        if (event.type === DealEventType.EventTypeStop) {
          event.inner_events = deal.events?.filter((innerEvent) => {
            return (
              event.group_id === innerEvent.group_id &&
              (isSecondaryEvent(innerEvent) || !innerEvent.type)
            );
          });
          if (event.inner_events?.length === 1) {
            event.single_inner_event = event.inner_events[0];
          }
          // else if (event.inner_events && event.inner_events.length > 1) {
          // deal.is_advanced = true;
          // }
        }
      });
    }
  }

  @Action(GetAllDeals)
  getAllDeals({ getState, patchState }: StateContext<DealStateModel>) {
    patchState({ loading: true });
    const { deals, dealsAlreadyRequested } = getState();
    if (deals?.length && dealsAlreadyRequested) {
      patchState({ loading: false });
      return of(deals);
    }
    return this.dealService.getAllWithParams().pipe(
      tap((serverDeals) => {
        patchState({
          deals: serverDeals.deals,
          dealsAlreadyRequested: true,
          allDealsCount: serverDeals.total_amount_of_deals,
        });
      }),
      catchError((err) => {
        patchState({ loading: false });
        return throwError(err);
      }),
      debounceTime(500),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }
  @Action(GetDeal)
  getDeal(
    { getState, patchState, dispatch }: StateContext<DealStateModel>,
    { id, force, copyId }: GetDeal
  ) {
    patchState({ loading: true });
    const { deals } = getState();
    const dealIndex = deals.findIndex((deal) => deal.id === +id);
    if (dealIndex >= 0 && !force) {
      const requestedDeal = deals[dealIndex];
      if (requestedDeal && requestedDeal.requested) {
        const selectedPresetNamesInEvents: PresetNames[] = [];
        const uiSettings = this.store.selectSnapshot(XAuthState.getSettings);
        DealState.generateRouteForStopEvent(
          requestedDeal,
          uiSettings,
          selectedPresetNamesInEvents,
          dispatch,
          true
        );
        const daysDistanceIndicators = DealState.daysDistanceIndicators(requestedDeal);
        DealState.calculateCargoEvents(requestedDeal);
        patchState({
          selectedDeal: requestedDeal,
          currentEditedCopy: requestedDeal,
          selectedCargoes: requestedDeal.cargoes?.length ? requestedDeal.cargoes : [],
          loading: false,
          daysDistanceIndicators: daysDistanceIndicators,
          selectedPresetNamesInEvents,
        });
        return of(requestedDeal);
      }
    }
    return this.dealService.get(id).pipe(
      tap((deal) => {
        const uiSettings = this.store.selectSnapshot(XAuthState.getSettings);
        const selectedPresetNamesInEvents: PresetNames[] = [];
        DealState.generateRouteForStopEvent(
          deal,
          uiSettings,
          selectedPresetNamesInEvents,
          dispatch,
          true
        );
        const daysDistanceIndicators = DealState.daysDistanceIndicators(deal);
        deal.requested = true;
        if (dealIndex >= 0) {
          deals.splice(dealIndex, 1, deal);
        } else {
          deals.push(deal);
        }
        if (copyId) {
          deal.copied_id = copyId;
          patchState({ navigatedDeal: deal });
        }
        DealState.calculateCargoEvents(deal);
        patchState({
          selectedDeal: deal,
          currentEditedCopy: deal,
          selectedCargoes: deal.cargoes?.length ? deal.cargoes : [],
          deals: [...deals],
          daysDistanceIndicators: daysDistanceIndicators,
          loading: false,
          selectedPresetNamesInEvents,
        });
      }),
      catchError((err) => {
        patchState({ loading: false });
        return throwError(err);
      }),
      debounceTime(500),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }
  @Action(CreateDeal)
  createVessel(
    { dispatch, getState, patchState }: StateContext<DealStateModel>,
    { deal }: CreateDeal
  ) {
    patchState({ loading: true });
    dispatch(new ResetSelectedDeal());
    return this.dealService.create(deal).pipe(
      tap((deal) => {
        const deals = getState().deals;
        deals.push(deal);
        patchState({
          selectedDeal: deal,
          selectedCargoes: deal.cargoes?.length ? deal.cargoes : [],
          deals: [...deals],
        });
        if (deal.vessel) {
          dispatch(new AddVesselToState(deal.vessel));
        }
        dispatch(new NavigateToDeal(deal));
      }),
      catchError((err) => {
        this.notificationService.showNotification(
          `${this._stateName} ${err?.error?.error}`,
          NotificationType.Error
        );
        patchState({ loading: false });
        return throwError(err);
      }),
      debounceTime(500),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }
  @Action(UpdateFolderAtDeal)
  updateFolderAtDeal({ patchState }: StateContext<DealStateModel>, { deal }: UpdateFolderAtDeal) {
    patchState({ loading: true });
    return this.dealService.updateFolderAtDeal(deal).pipe(
      tap(() => {
        // do nothing
      }),
      catchError((err) => {
        this.notificationService.showNotification(
          `${this._stateName} ${err?.error?.error}`,
          NotificationType.Error
        );
        patchState({ loading: false });
        return throwError(err);
      }),
      debounceTime(500),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }
  @Action(UpdateFieldDeal)
  UpdateFieldDeal({ patchState }: StateContext<DealStateModel>, { deal, params }: UpdateFieldDeal) {
    patchState({ loading: true });
    return this.dealService.updateFieldDeal(deal, params).pipe(
      tap(() => {
        // do nothing
      }),
      catchError((err) => {
        this.notificationService.showNotification(
          `${this._stateName} ${err?.error?.error}`,
          NotificationType.Error
        );
        patchState({ loading: false });
        return throwError(err);
      }),
      debounceTime(500),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }
  @Action(UpdateDeal, { cancelUncompleted: true })
  updateDeal(
    { getState, patchState, dispatch }: StateContext<DealStateModel>,
    {
      deal,
      /* newSpeed, */ revertUpdate,
      updateStartVersion,
      cancelNavigation,
      changePort,
    }: UpdateDeal
  ) {
    return this.commonUpdateDeal(
      getState,
      patchState,
      deal,
      dispatch,
      /*  newSpeed, */
      revertUpdate,
      updateStartVersion,
      cancelNavigation,
      changePort
    );
  }

  @Action(UpdateDealCancelUncompletedFalse, { cancelUncompleted: false })
  updateDealCancelUncompletedFalse(
    { getState, patchState, dispatch }: StateContext<DealStateModel>,
    { deal, /* newSpeed, */ revertUpdate }: UpdateDealCancelUncompletedFalse
  ) {
    return this.commonUpdateDeal(
      getState,
      patchState,
      deal,
      dispatch,
      /*   newSpeed, */
      revertUpdate,
      false,
      false
    );
  }

  commonUpdateDeal(
    getState: () => DealStateModel,
    patchState: (val: Partial<DealStateModel>) => DealStateModel,
    deal: Deal,
    dispatch: (actions: any | any[]) => Observable<void>,
    /*   newSpeed?: number | null, */
    revertUpdate?: boolean,
    updateStartVersion?: boolean,
    cancelNavigation?: boolean,
    changePort?: boolean
  ) {
    patchState({ loading: true });
    /*     if (newSpeed && newSpeed !== null) {
      const events = getState().selectedDeal?.events;
      if (events?.length) {
        const requests: Observable<DealEvent>[] = [];
        events.forEach((event) => {
          if (isPrimaryEvent(event) && event.meta?.sog !== newSpeed && newSpeed !== null) {
            if (!event.meta) {
              event.meta = {};
            }
            event.meta.sog = newSpeed;
            requests.push(this.dealEventService.update(event));
          }
        });
        if (requests.length) {
          return forkJoin(requests)
            .pipe(
              catchError((err) => {
                patchState({ loading: false });
                this.notificationService.showNotification(
                  `${this._stateName} ${err?.error?.error}`,
                  NotificationType.Error
                );
                return throwError(err);
              })
            )
            .subscribe((res) => {
              deal.events = unionBy(deal.events, res, 'id');
              return this.dealService
                .updateDeal(deal, updateStartVersion)
                .pipe(
                  tap((deal) => {
                    this.commonUpdateDealAfterSave(
                      getState,
                      patchState,
                      deal,
                      updateStartVersion as boolean,
                      revertUpdate as boolean
                    );
                  }),
                  catchError((err) => {
                    patchState({ loading: false });
                    this.notificationService.showNotification(
                      `${this._stateName} ${err?.error?.error}`,
                      NotificationType.Error
                    );
                    return throwError(err);
                  }),
                  finalize(() => {
                    patchState({ loading: false });
                  })
                )
                .subscribe();
            });
        }
      }
    } */
    delete deal.folder_index;
    return this.dealService.updateDeal(deal, updateStartVersion).pipe(
      tap((deal) => {
        this.commonUpdateDealAfterSave(
          getState,
          patchState,
          deal,
          dispatch,
          updateStartVersion as boolean,
          revertUpdate as boolean,
          cancelNavigation,
          changePort
        );
      }),
      catchError((err) => {
        /* const selectedCargoes = getState().selectedCargoes;
        if (selectedCargoes?.length) {
          selectedCargoes?.forEach((cargo) => {
            cargo.price = convertCurrencyToUiValue(cargo?.price, cargo?.currency?.fiat_multiplier);
            cargo.lumpsum = convertCurrencyToUiValue(
              cargo?.lumpsum,
              cargo?.currency?.fiat_multiplier
            );
          });
          patchState({ selectedCargoes: selectedCargoes?.length ? selectedCargoes : [] });
        } */
        patchState({ loading: false });
        this.notificationService.showNotification(
          `${this._stateName} ${err?.error?.error}`,
          NotificationType.Error
        );
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }
  commonUpdateDealAfterSave(
    getState: () => DealStateModel,
    patchState: (val: Partial<DealStateModel>) => DealStateModel,
    deal: Deal,
    dispatch: (actions: any | any[]) => Observable<void>,
    updateStartVersion: boolean,
    revertUpdate: boolean,
    cancelNavigation?: boolean,
    changePort?: boolean
  ) {
    const { deals, startCopiedDeals } = getState();
    const uiSettings = this.store.selectSnapshot(XAuthState.getSettings);
    const selectedPresetNamesInEvents: PresetNames[] = [];
    DealState.generateRouteForStopEvent(
      deal,
      uiSettings,
      selectedPresetNamesInEvents,
      dispatch,
      changePort
    );
    DealState.calculateCargoEvents(deal);
    this.processFinances(deal);
    const daysDistanceIndicators = DealState.daysDistanceIndicators(deal);
    const index = deals.findIndex((oldDeal) => oldDeal?.id === deal?.id);
    if (index >= 0) {
      deal.requested = true;
      deals.splice(index, 1, deal);
    }
    if (updateStartVersion) {
      const indexStartCopiedDeals = startCopiedDeals.findIndex(
        (oldDeal) => oldDeal?.id === deal?.id
      );
      if (indexStartCopiedDeals >= 0) {
        startCopiedDeals.splice(indexStartCopiedDeals, 1, cloneDeep(deal));
      }
    }
    if (!revertUpdate) {
      const { selectedDeal } = getState();
      if (selectedDeal && selectedDeal.id === deal.id) {
        selectedDeal.name = deal.name;
        patchState({
          selectedDeal: selectedDeal,
          selectedPresetNamesInEvents: selectedPresetNamesInEvents,
        });
      }
      patchState({
        deals: [...deals],
        selectDealChanges: false,
      });
      if (!cancelNavigation) {
        patchState({
          selectedDeal: deal,
          selectedPresetNamesInEvents: selectedPresetNamesInEvents,
          selectedCargoes: deal.cargoes?.length ? deal.cargoes : [],
          daysDistanceIndicators: daysDistanceIndicators,
          navigatedDeal: deal,
          startCopiedDeals: startCopiedDeals,
          currentEditedCopy: deal,
        });
      }
    }
  }

  @Action(DeleteDeal)
  deleteVessel(
    { getState, patchState }: StateContext<DealStateModel>,
    { id, isDeleteCopy }: DeleteDeal
  ) {
    patchState({ loading: true });
    return this.dealService.delete(id).pipe(
      tap(() => {
        const { deals, selectedDeal, startCopiedDeals } = getState();
        if (isDeleteCopy) {
          patchState({ deals: [], startCopiedDeals: [] });
        } else {
          const index = deals.findIndex((oldDeal) => oldDeal?.id === id);
          if (index >= 0) {
            deals.splice(index, 1);
          }
          const indexCopy = startCopiedDeals.findIndex((oldDeal) => oldDeal?.id === id);
          if (indexCopy >= 0) {
            startCopiedDeals.splice(indexCopy, 1);
          }
          patchState({ deals: [...deals], startCopiedDeals: [...startCopiedDeals] });
        }
        if (selectedDeal && selectedDeal?.id === id) {
          patchState({
            selectedDeal: null,
            selectedPresetNamesInEvents: [],
            currentEditedCopy: null,
            selectedCargoes: [],
            daysDistanceIndicators: {},
            breakEvenData: [],
            alternatives: [],
          });
        }
        /*  if (isArchived) {
          const { estimationsNavigationLinks } = getState();
          const newEstimationsNavigationLinks = estimationsNavigationLinks.filter(
            (link) => link.id !== id
          );
          const newNavigation = getNavigationFromEstimationsNavigationLinks(
            newEstimationsNavigationLinks
          );
          patchState({
            estimationsNavigationLinks: newEstimationsNavigationLinks,
            navigation: newNavigation,
          });
        } */
      }),
      catchError((err) => {
        patchState({ loading: false });
        this.notificationService.showNotification(
          `${this._stateName} ${err?.error?.error}`,
          NotificationType.Error
        );
        return throwError(err);
      }),
      debounceTime(500),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }
  @Action(ResetSelectedDeal)
  resetSelectedDeal({ patchState }: StateContext<DealStateModel>) {
    patchState({
      selectedDeal: null,
      selectedPresetNamesInEvents: [],
      currentEditedCopy: null,
      selectedCargoes: [],
      validationErrors: [],
      daysDistanceIndicators: {},
      finances: null,
      breakEvenData: [],
      alternatives: [],
    });
  }
  @Action(ResetNavigatedDeal)
  resetNavigatedDeal({ patchState }: StateContext<DealStateModel>) {
    patchState({ navigatedDeal: null });
  }
  @Action(ResetCascadeNavigation)
  resetCascadeNavigation({ patchState }: StateContext<DealStateModel>) {
    patchState({ navigation: [] });
  }
  @Action(RefreshColorsRoute)
  refreshColorsRoute({ getState, patchState, dispatch }: StateContext<DealStateModel>) {
    const selectedDeal = getState().selectedDeal;
    if (selectedDeal) {
      const uiSettings = this.store.selectSnapshot(XAuthState.getSettings);
      const selectedPresetNamesInEvents: PresetNames[] = [];
      DealState.generateRouteForStopEvent(
        selectedDeal as Deal,
        uiSettings,
        selectedPresetNamesInEvents,
        dispatch
      );
      patchState({
        selectedDeal: selectedDeal,
        selectedPresetNamesInEvents: selectedPresetNamesInEvents,
      });
    }
  }
  @Action(CreateCargo)
  createCargo(
    { patchState, getState }: StateContext<DealStateModel>,
    { cargo, actualCargoes }: CreateCargo
  ) {
    /* patchState({ loading: true }); */
    patchState({ loadingCargo: true });
    return this.cargoService.create(cargo).pipe(
      tap((newCargo) => {
        let selectedCargoes = actualCargoes;
        if (selectedCargoes) {
          if (!selectedCargoes) {
            selectedCargoes = [];
          }
          selectedCargoes.push(newCargo);
          const { currentEditedCopy, selectedDeal } = getState();
          if (currentEditedCopy) {
            currentEditedCopy.cargoes = selectedCargoes;
          }
          if (selectedDeal) {
            selectedDeal.cargoes = selectedCargoes;
          }
          // if (selectedDeal.is_advanced || forceUpdateDeal) {
          // dispatch(new UpdateDeal(selectedDeal));
          patchState({
            selectedCargoes: selectedCargoes,
            loadingCargo: false,
          });
          // } else {
          // patchState({ selectedDeal: selectedDeal });
          // }
        }
      }),
      catchError((err) => {
        /* patchState({ loading: false }); */
        patchState({ loadingCargo: false });
        this.notificationService.showNotification(
          `${this._stateName}(Cargo) ${err?.error?.error}`,
          NotificationType.Error
        );
        return throwError(err);
      })
    );
  }
  @Action(UpdateCargo)
  updateCargo({ getState, patchState }: StateContext<DealStateModel>, { cargo }: UpdateCargo) {
    return this.cargoService.update(cargo).pipe(
      tap((updCargo) => {
        let selectedCargoes = getState().selectedCargoes;
        if (selectedCargoes) {
          if (!selectedCargoes) {
            selectedCargoes = [];
          }
          const oldCargoIndex = selectedCargoes.findIndex((elem) => {
            return elem.id === updCargo.id;
          });
          if (oldCargoIndex >= 0) {
            selectedCargoes.splice(oldCargoIndex, 1, updCargo);
          }
          patchState({ selectedCargoes: selectedCargoes });
        }
      }),
      catchError((err) => {
        /* patchState({ loading: false }); */
        this.notificationService.showNotification(
          `${this._stateName}(Cargo) ${err?.error?.error}`,
          NotificationType.Error
        );
        return throwError(err);
      })
    );
  }
  @Action(UpdateCargoInSelectedDeal)
  updateCargoInSelectedDeal(
    { patchState }: StateContext<DealStateModel>,
    { cargo, actualCargoes }: UpdateCargoInSelectedDeal
  ) {
    let selectedCargoes = actualCargoes;
    if (selectedCargoes) {
      if (!selectedCargoes) {
        selectedCargoes = [];
      }
      const oldCargoIndex = selectedCargoes.findIndex((elem) => {
        return elem.id === cargo.id;
      });
      if (oldCargoIndex >= 0) {
        /* cargo.price = convertCurrencyToServerValue(cargo?.price, cargo?.currency?.fiat_multiplier);
        cargo.lumpsum = convertCurrencyToServerValue(
          cargo?.lumpsum,
          cargo?.currency?.fiat_multiplier
        ); */
        selectedCargoes.splice(oldCargoIndex, 1, cargo);
      }
      patchState({ selectedCargoes: selectedCargoes });
    }
  }
  @Action(DeleteCargo)
  deleteCargo(
    { patchState }: StateContext<DealStateModel>,
    { cargoId, actualCargoes }: DeleteCargo
  ) {
    patchState({ loadingCargo: true });
    return this.cargoService.delete(cargoId || 0).pipe(
      tap(() => {
        let selectedCargoes = actualCargoes;
        if (selectedCargoes) {
          if (!selectedCargoes) {
            selectedCargoes = [];
          }
          const oldCargoIndex = selectedCargoes.findIndex((elem) => {
            return elem.id === cargoId;
          });
          if (oldCargoIndex >= 0) {
            selectedCargoes.splice(oldCargoIndex, 1);
          }
          selectedCargoes.forEach((cargo, index) => {
            cargo.internal_order = index + 1;
          });
          patchState({ selectedCargoes: selectedCargoes, loadingCargo: false });

          const deal = this.store.selectSnapshot(DealState.getCurrentEditedCopy);
          if (deal) {
            deal.cargoes = selectedCargoes;
            patchState({ currentEditedCopy: deal });
          }
        }
      }),
      catchError((err) => {
        /* patchState({ loading: false }); */
        patchState({ loadingCargo: false });
        this.notificationService.showNotification(
          `${this._stateName}(Cargo) ${err?.error?.error}`,
          NotificationType.Error
        );
        return throwError(err);
      })
    );
  }
  @Action(GetLastConstantsForCargo)
  getLastConstantsForCargo(
    { dispatch, patchState }: StateContext<DealStateModel>,
    { cargo, vessel_id }: GetLastConstantsForCargo
  ) {
    patchState({ loading: true });
    return this.cargoCalculatorService
      .getConstants(vessel_id, cargo.cargo_calculator?.id || 0)
      .pipe(
        tap((constants) => {
          if (cargo.cargo_calculator) {
            Object.assign(cargo.cargo_calculator, constants);
            dispatch(new CalculateCargo(cargo));
            return;
          }
        }),
        catchError((err) => {
          patchState({ loading: false });
          this.notificationService.showNotification(
            `${this._stateName}(Cargo) ${err?.error?.error}`,
            NotificationType.Error
          );
          return throwError(err);
        })
      );
  }

  @Action(CalculateCargo, { cancelUncompleted: true })
  calculateCargo(
    { getState, patchState }: StateContext<DealStateModel>,
    { cargo }: CalculateCargo
  ) {
    return this.commonCalculateCargo(cargo, getState, patchState);
  }

  @Action(CalculateCargoGroup)
  calculateCargoGroup(
    { getState, patchState }: StateContext<DealStateModel>,
    { cargoes }: CalculateCargoGroup
  ) {
    return this.groupCalculateCargo(cargoes, getState, patchState);
  }

  @Action(CreateEvent)
  createEvent({ getState, patchState }: StateContext<DealStateModel>, { event }: CreateEvent) {
    return this.dealEventService.create(event).pipe(
      tap((newEvent) => {
        const selectedDeal = getState().selectedDeal;
        if (selectedDeal) {
          if (!selectedDeal.events) {
            selectedDeal.events = [];
          }
          if (newEvent.order) {
            selectedDeal.events.splice(newEvent.order, 0, newEvent);
          } else {
            selectedDeal.events.push(newEvent);
          }
          DealState.calculateCargoEvents(selectedDeal);
          this.processFinances(selectedDeal);
          patchState({ selectedDeal: { ...selectedDeal } });
          /* dispatch(new UpdateDeal(selectedDeal)); */
        }
      }),
      catchError((err) => {
        patchState({ loading: false });
        this.notificationService.showNotification(
          `${this._stateName}(Event) ${err?.error?.error}`,
          NotificationType.Error
        );
        return throwError(err);
      })
    );
  }
  @Action(UpdateEvent)
  updateEvent(
    { dispatch, getState, patchState }: StateContext<DealStateModel>,
    { event, updateOnlyCanals }: UpdateEvent
  ) {
    patchState({ loading: true });
    if (event.type === DealEventType.EventTypeStop && !updateOnlyCanals) {
      const { selectedDeal } = getState();
      if (selectedDeal?.events?.length) {
        const stateEvent = selectedDeal.events.find((elem) => elem.id === event.id);
        if (
          stateEvent &&
          (stateEvent.port_id !== event.port_id || stateEvent.port?.id !== event.port?.id)
        ) {
          const updateRequests: Observable<DealEvent>[] = [];
          selectedDeal.events.forEach((elem) => {
            if (elem.group_id === stateEvent.group_id) {
              const updEvent = cloneDeep(elem);
              updEvent.port_id = event.port_id;
              if (!updEvent.port) {
                updEvent.port = {};
              }
              updEvent.port.id = event.port?.id;
              updateRequests.push(this.dealEventService.update(updEvent));
            }
          });
          return forkJoin(updateRequests).pipe(
            tap((res) => {
              res.forEach((updEvent) => {
                if (selectedDeal.events) {
                  const oldEventIndex = selectedDeal.events.findIndex((elem) => {
                    return elem.id === updEvent.id;
                  });
                  if (oldEventIndex >= 0) {
                    selectedDeal.events.splice(oldEventIndex, 1, updEvent);
                  }
                }
              });
              dispatch(new UpdateDeal(selectedDeal));
              patchState({ lastUpdatedEvent: event });
            }),
            catchError((err) => {
              patchState({ loading: false });
              this.notificationService.showNotification(
                `${this._stateName}(Event) ${err?.error?.error}`,
                NotificationType.Error
              );
              return throwError(err);
            })
          );
        }
      }
    }
    return this.dealEventService.update(event).pipe(
      tap((updEvent) => {
        const selectedDeal = getState().selectedDeal;
        if (selectedDeal) {
          if (!selectedDeal.events) {
            selectedDeal.events = [];
          }
          const oldEventIndex = selectedDeal.events.findIndex((elem) => {
            return elem.id === updEvent.id;
          });
          if (oldEventIndex >= 0) {
            selectedDeal.events.splice(oldEventIndex, 1, updEvent);
          }
          dispatch(new UpdateDeal(selectedDeal));
          patchState({ lastUpdatedEvent: updEvent });
        }
      }),
      catchError((err) => {
        patchState({ loading: false });
        this.notificationService.showNotification(
          `${this._stateName}(Event) ${err?.error?.error}`,
          NotificationType.Error
        );
        return throwError(err);
      })
    );
  }
  @Action(DeleteEvent)
  deleteEvent({ getState, patchState }: StateContext<DealStateModel>, { event }: DeleteEvent) {
    /* patchState({ loading: true }); */
    if (event.type === DealEventType.EventTypeStop) {
      const { selectedDeal } = getState();
      if (selectedDeal?.events?.length) {
        const deleteRequests: Observable<void>[] = [];
        const deletedId: number[] = [];
        selectedDeal.events.forEach((elem) => {
          if (elem.id && elem.group_id === event.group_id) {
            deleteRequests.push(this.dealEventService.delete(elem.id));
            deletedId.push(elem.id);
          }
        });
        if (deleteRequests.length) {
          return forkJoin(deleteRequests).pipe(
            tap(() => {
              const newEvents = selectedDeal.events?.filter((elem) => {
                if (elem.id) {
                  return !deletedId.includes(elem.id);
                }
                return true;
              });
              selectedDeal.events = newEvents;
              DealState.calculateCargoEvents(selectedDeal);
              this.processFinances(selectedDeal);
              patchState({ selectedDeal: { ...selectedDeal } });
              /* dispatch(new UpdateDeal(selectedDeal)); */
            }),
            catchError((err) => {
              patchState({ loading: false });
              this.notificationService.showNotification(
                `${this._stateName}(Event) ${err?.error?.error}`,
                NotificationType.Error
              );
              return throwError(err);
            })
          );
        }
      }
    }
    return this.dealEventService.delete(event.id || 0).pipe(
      tap(() => {
        const selectedDeal = getState().selectedDeal;
        if (selectedDeal) {
          if (!selectedDeal.events) {
            selectedDeal.events = [];
          }
          const oldEventIndex = selectedDeal.events.findIndex((elem) => {
            return elem.id === event.id;
          });
          if (oldEventIndex >= 0) {
            selectedDeal.events.splice(oldEventIndex, 1);
          }
          DealState.calculateCargoEvents(selectedDeal);
          this.processFinances(selectedDeal);
          patchState({ selectedDeal: { ...selectedDeal } });
        }
      }),
      catchError((err) => {
        patchState({ loading: false });
        this.notificationService.showNotification(
          `${this._stateName}(Event) ${err?.error?.error}`,
          NotificationType.Error
        );
        return throwError(err);
      })
    );
  }

  @Action(ReplaceEvent)
  replaceEvent(
    { dispatch, getState, patchState }: StateContext<DealStateModel>,
    { events }: ReplaceEvent
  ) {
    patchState({ loading: true });
    const { selectedDeal } = getState();
    if (selectedDeal) {
      selectedDeal.events = events;
      dispatch(new UpdateDeal(selectedDeal));
    }
  }

  @Action(NavigateToDeal)
  navigateToDeal(
    { patchState }: StateContext<DealStateModel>,
    { deal, closeTabName }: NavigateToDeal
  ) {
    patchState({ navigatedDeal: deal });
    if (closeTabName) {
      patchState({ closeTabName });
    }
  }

  @Action(ClearCloseTabName)
  clearCloseTabName({ patchState }: StateContext<DealStateModel>) {
    patchState({ closeTabName: '' });
  }

  @Action(CopyDeal)
  copyDeal(
    { dispatch, patchState, getState }: StateContext<DealStateModel>,
    { id, getDeal, folder_id, setOrder }: CopyDeal
  ) {
    patchState({ loading: true });
    return this.dealService.copyDeal(id, folder_id).pipe(
      tap((copyId) => {
        if (getDeal) {
          dispatch(new GetDeal(copyId, true, id)).subscribe(
            () => {
              if (setOrder) {
                const { estimationsNavigationLinks } = getState();
                const dealsIds = getDealsIds(estimationsNavigationLinks);
                if (folder_id && folder_id !== DRAFT_FOLDER_ID) {
                  this.store.dispatch(new SetDealsOrderInFolder(folder_id, dealsIds));
                }
              }
            },
            (err) => {
              this.notificationService.showNotification(
                `${this._stateName} ${err?.error?.error}`,
                NotificationType.Error
              );
            }
          );
        } else {
          patchState({ loading: false });
        }
      }),
      catchError((err) => {
        patchState({ loading: false });
        this.notificationService.showNotification(
          `${this._stateName} ${err?.error?.error}`,
          NotificationType.Error
        );
        return throwError(err);
      })
    );
  }

  @Action(UpdateVesselInDeal)
  updateVesselInDeal(
    { getState, patchState }: StateContext<DealStateModel>,
    { vessel }: UpdateVesselInDeal
  ) {
    const { selectedDeal } = getState();
    if (selectedDeal) {
      selectedDeal.vessel = vessel;
      patchState({ selectedDeal });
    }
  }

  @Action(SetChanges)
  setChanges({ patchState }: StateContext<DealStateModel>, { changes }: SetChanges) {
    patchState({ selectDealChanges: changes });
  }

  @Action(UpdateHireFinanceInDeal)
  updateHireFinanceInDeal(
    { getState, patchState }: StateContext<DealStateModel>,
    { hireFinance }: UpdateHireFinanceInDeal
  ) {
    const { selectedDeal } = getState();
    if (selectedDeal?.finances) {
      selectedDeal.finances.hire_finance = hireFinance;
      patchState({ selectedDeal: { ...selectedDeal } });
    }
  }

  @Action(SetCascadeNavigation)
  setCascadeNavigation(
    { patchState }: StateContext<DealStateModel>,
    { navigation }: SetCascadeNavigation
  ) {
    patchState({ navigation: navigation });
  }

  @Action(CreateStartVersionDeal)
  createStartVersionDeal(
    { getState, patchState }: StateContext<DealStateModel>,
    { id }: CreateStartVersionDeal
  ) {
    const startCopiedDeals = getState().startCopiedDeals;
    if (startCopiedDeals.findIndex((deal) => deal.id === +id) === -1) {
      patchState({ loading: true });
      return this.dealService.saveDealStartVersion(id).pipe(
        tap((deal) => {
          if (startCopiedDeals.findIndex((deal) => deal.id === +id) === -1) {
            startCopiedDeals.push(deal);
          }
          patchState({
            startCopiedDeals: [...startCopiedDeals],
            loading: false,
          });
        }),
        catchError((err) => {
          patchState({ loading: false });
          this.notificationService.showNotification(
            `${this._stateName} ${err?.error?.error}. Error when create start version of deal`,
            NotificationType.Error
          );
          return throwError(err);
        })
      );
    }
    return;
  }

  @Action(RevertDeal)
  revertDeal({ patchState }: StateContext<DealStateModel>, { id }: RevertDeal) {
    patchState({ loading: true });
    return this.dealService.getDealStartVersion(id).pipe(
      tap((deal) => {
        DealState.calculateCargoEvents(deal);
        this.processFinances(deal);
        patchState({
          selectedDeal: deal,
          navigatedDeal: deal,
          currentEditedCopy: deal,
          selectedCargoes: deal.cargoes,
          loading: false,
        });
      }),
      catchError((err) => {
        patchState({ loading: false });
        this.notificationService.showNotification(
          `${this._stateName} ${err?.error?.error}`,
          NotificationType.Error
        );
        return throwError(err);
      })
    );
  }

  @Action(CloseFolderInCurrEstimation)
  closeFolderInCurrEstimation(
    { getState, patchState, dispatch }: StateContext<DealStateModel>,
    { linkToNavigate, navigate, revertUpdate, folderId }: CloseFolderInCurrEstimation
  ) {
    const { currentEditedCopy, estimationsNavigationLinks, selectDealChanges } = getState();
    if (selectDealChanges) {
      dispatch(
        new UpdateDeal(
          /* prepareDealBeforeSave */ currentEditedCopy as Deal,
          /*  null, */
          revertUpdate,
          false
        )
      ).subscribe(
        () => {
          this.commonCloseFolder(
            getState,
            dispatch,
            patchState,
            estimationsNavigationLinks,
            linkToNavigate,
            navigate,
            undefined,
            folderId
          );
        },
        (err) => {
          this.notificationService.showNotification(
            `${this._stateName} ${err?.error?.error}`,
            NotificationType.Error
          );
        }
      );
    } else {
      this.commonCloseFolder(
        getState,
        dispatch,
        patchState,
        estimationsNavigationLinks,
        linkToNavigate,
        navigate,
        undefined,
        folderId
      );
    }
  }

  @Action(CloseFolderInLastEstimation)
  closeFolderInLastEstimation(
    { getState, patchState, dispatch }: StateContext<DealStateModel>,
    {
      linkToNavigate,
      navigate,
      revertUpdate,
      folderId,
      dontUpdateDeals,
    }: CloseFolderInLastEstimation
  ) {
    const { startCopiedDeals, estimationsNavigationLinks, deals } = getState();
    const newStartCopiedDeals = startCopiedDeals.filter((copy) => copy.folder_id !== folderId);
    const newDeals = deals.filter((deal) => deal.folder_id !== folderId);
    patchState({
      deals: newDeals,
      startCopiedDeals: newStartCopiedDeals,
    });
    if (dontUpdateDeals) {
      this.commonCloseFolder(
        getState,
        dispatch,
        patchState,
        estimationsNavigationLinks,
        linkToNavigate,
        navigate,
        undefined,
        folderId
      );
    } else {
      startCopiedDeals.forEach((deal) => {
        dispatch(new UpdateDealCancelUncompletedFalse(deal, /* null, */ revertUpdate)).subscribe(
          () => {
            this.commonCloseFolder(
              getState,
              dispatch,
              patchState,
              estimationsNavigationLinks,
              linkToNavigate,
              navigate,
              undefined,
              folderId
            );
          },
          (err) => {
            this.notificationService.showNotification(
              `${this._stateName} ${err?.error?.error}`,
              NotificationType.Error
            );
          }
        );
      });
    }
  }

  @Action(OpenSaveFolderModal)
  openSaveFolderModal(
    { getState, patchState, dispatch }: StateContext<DealStateModel>,
    {
      linkToNavigate,
      currDealId,
      navigate /* folderId */,
      copyAlienFolder,
      isCopyAlienDeal,
      isCopyAlienFolder,
    }: OpenSaveFolderModal
  ) {
    const foundFolders = new Subject<Folder[]>();
    const saveFolder = new EventEmitter<SaveFolderEmitter>();
    const searchFolder = new EventEmitter<string>();
    const config: DynamicDialogConfig<SaveFolderDialogConfig> = {
      header: 'Save folder',
      data: {
        saveFolder,
        searchFolder,
        foundFolders,
        label: 'SAVE',
        openFolder: copyAlienFolder,
      },
      width: '400px',
    };
    const neededType = linkToNavigate === ESTIMATOR_ROUTE ? VoyageType.Bulk : VoyageType.Tanker;
    searchFolder
      .pipe(
        tap((res) => {
          this.store.dispatch(new GetFoldersBySearch(neededType, res, '', true)).subscribe(
            ({ folder }) => {
              const folderState = folder as FolderStateModel;
              foundFolders.next(
                stayOnlyMyFolders(
                  folderState.sidebarFolders,
                  this.store.selectSnapshot(XAuthState.getUserId)
                )
              );
            },
            (err) => {
              this.notificationService.showNotification(
                ErrorNotifications.ErrorSearchFolders,
                NotificationType.Warning
              );
            }
          );
        })
      )
      .subscribe();
    const saveFolderDialogRef = this.dialogService.open(SaveFolderComponent, config);
    saveFolderDialogRef.onClose.subscribe((res) => {
      if (!res) {
        if (copyAlienFolder || isCopyAlienFolder) {
          localStorage.setItem(IS_COPY_ALIEN_FOLDER_KEY, 'true');
        }
        if (isCopyAlienDeal) {
          localStorage.setItem(IS_COPY_ALIEN_DEAL_KEY, 'true');
        }
      }
    });
    let needFolder: Folder;
    saveFolder
      .pipe(
        tap((saveFolderEmitterValue) => {
          if (saveFolderEmitterValue.newFolder) {
            const newFolder: Folder = {
              name: saveFolderEmitterValue.folder,
              voyage_type: checkLinkTanker(linkToNavigate) ? VoyageType.Tanker : VoyageType.Bulk,
            };
            this.store.dispatch(new CreateFolder(newFolder)).subscribe(
              ({ folder }) => {
                const folderState = folder as FolderStateModel;
                needFolder = stayOnlyMyFolders(
                  folderState.sidebarFolders,
                  this.store.selectSnapshot(XAuthState.getUserId)
                ).find(
                  (folder) =>
                    isString(saveFolderEmitterValue.folder) &&
                    folder.name === saveFolderEmitterValue.folder
                ) as Folder;
                this.commonSaveFolder(
                  getState,
                  dispatch,
                  patchState,
                  needFolder,
                  saveFolderDialogRef,
                  linkToNavigate,
                  currDealId,
                  navigate
                  /*  folderId as number */
                );
              },
              (err) => {
                this.notificationService.showNotification(
                  `${this._stateName} ${err?.error?.error}`,
                  NotificationType.Error
                );
              }
            );
          }
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe(
        (saveFolderEmitterValue) => {
          if (!saveFolderEmitterValue.newFolder) {
            this.commonSaveFolder(
              getState,
              dispatch,
              patchState,
              saveFolderEmitterValue.folder,
              saveFolderDialogRef,
              linkToNavigate,
              currDealId,
              navigate
              /* folderId as number */
            );
          }
        },
        (err) => {
          this.notificationService.showNotification(
            `${this._stateName} ${err?.error?.error}`,
            NotificationType.Error
          );
        }
      );
  }

  @Action(CopyDealToOtherFolder)
  сopyDealToOtherFolder(
    { dispatch }: StateContext<DealStateModel>,
    { linkToNavigate, id, openFolder, notCopyToCurrentFolder, copyDealName }: CopyDealToOtherFolder
  ) {
    const foundFolders = new Subject<Folder[]>();
    const saveFolder = new EventEmitter<SaveFolderEmitter>();
    const searchFolder = new EventEmitter<string>();
    const copyDealNameExist = copyDealName ? '"' + copyDealName + '"' : '';
    const config: DynamicDialogConfig<SaveFolderDialogConfig> = {
      header: `Copy deal ${copyDealNameExist} to folder`,
      data: {
        saveFolder,
        searchFolder,
        foundFolders,
        label: 'COPY',
        openFolder: notCopyToCurrentFolder ? null : (openFolder as Folder),
      },
      width: notCopyToCurrentFolder ? '400px' : '600px',
    };
    const neededType = linkToNavigate === ESTIMATOR_ROUTE ? VoyageType.Bulk : VoyageType.Tanker;
    searchFolder
      .pipe(
        tap((res) => {
          this.store.dispatch(new GetFoldersBySearch(neededType, res, '', true)).subscribe(
            ({ folder }) => {
              const folderState = folder as FolderStateModel;
              foundFolders.next(
                stayOnlyMyFolders(
                  folderState.sidebarFolders,
                  this.store.selectSnapshot(XAuthState.getUserId)
                )
              );
            },
            (err) => {
              this.notificationService.showNotification(
                ErrorNotifications.ErrorSearchFolders,
                NotificationType.Warning
              );
            }
          );
        })
      )
      .subscribe();
    const saveFolderDialogRef = this.dialogService.open(SaveFolderComponent, config);
    let needFolder: Folder;
    saveFolder
      .pipe(
        tap((saveFolderEmitterValue) => {
          if (saveFolderEmitterValue.newFolder) {
            const newFolder: Folder = {
              name: saveFolderEmitterValue.folder,
              voyage_type: checkLinkTanker(linkToNavigate) ? VoyageType.Tanker : VoyageType.Bulk,
            };
            this.store.dispatch(new CreateFolder(newFolder)).subscribe(
              ({ folder }) => {
                const folderState = folder as FolderStateModel;
                needFolder = stayOnlyMyFolders(
                  folderState.sidebarFolders,
                  this.store.selectSnapshot(XAuthState.getUserId)
                ).find(
                  (folder) =>
                    isString(saveFolderEmitterValue.folder) &&
                    folder.name === saveFolderEmitterValue.folder
                ) as Folder;
                const isNavigateToDeal = openFolder?.id === needFolder.id;
                dispatch(new CopyDeal(id, isNavigateToDeal, needFolder.id, true)).subscribe(
                  () => {
                    saveFolderDialogRef.close();
                    this.notificationService.showNotification(
                      SuccessNotifications.CopyCreated,
                      NotificationType.Success
                    );
                  },
                  (err) => {
                    this.notificationService.showNotification(
                      `${this._stateName} ${err?.error?.error}`,
                      NotificationType.Error
                    );
                  }
                );
              },
              (err) => {
                this.notificationService.showNotification(
                  `${this._stateName} ${err?.error?.error}`,
                  NotificationType.Error
                );
              }
            );
          }
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe(
        (saveFolderEmitterValue) => {
          if (!saveFolderEmitterValue.newFolder) {
            const isNavigateToDeal = openFolder?.id === saveFolderEmitterValue.folder.id;
            dispatch(
              new CopyDeal(id, isNavigateToDeal, saveFolderEmitterValue.folder.id, true)
            ).subscribe(
              () => {
                saveFolderDialogRef.close();
                this.notificationService.showNotification(
                  SuccessNotifications.CopyCreated,
                  NotificationType.Success
                );
              },
              (err) => {
                this.notificationService.showNotification(
                  `${this._stateName} ${err?.error?.error}`,
                  NotificationType.Error
                );
              }
            );
          }
        },
        (err) => {
          this.notificationService.showNotification(
            `${this._stateName} ${err?.error?.error}`,
            NotificationType.Error
          );
        }
      );
  }

  @Action(GetAllAlternativeRoutes)
  getAllAlternativeRoutes(
    { patchState }: StateContext<DealStateModel>,
    { events, routes }: GetAllAlternativeRoutes
  ) {
    patchState({ loading: true });
    const updateRequests: Observable<AlternativeRoute[]>[] = [];
    for (let i = 0; i < events.length - 1; i++) {
      // if (events[i].routing_point_id !== events[i + 1].routing_point_id) {
      updateRequests.push(
        this.routeService.getAlternativeRoutes(
          events[i].routing_point_id || 0,
          events[i + 1].routing_point_id || 0,
          events[i + 1].meta?.canals || [],
          events[i + 1].meta?.use_eca || false
        )
      );
      // }
    }

    if (updateRequests?.length) {
      return forkJoin(updateRequests).pipe(
        tap((requestsAlternativeRoutes) => {
          requestsAlternativeRoutes.forEach((arrAlternativeRoutes, index) => {
            arrAlternativeRoutes.forEach((alternativeRoute, i) => {
              alternativeRoute.routes.features.forEach((feature) => {
                if (feature.properties) {
                  feature.properties[MapFeatureProperties.Index] = index;
                  feature.properties[MapFeatureProperties.EventId] = events[index + 1].id;
                  feature.properties[MapFeatureProperties.Start] = events[index].port?.name;
                  feature.properties[MapFeatureProperties.End] = events[index + 1].port?.name;
                  feature.properties[MapFeatureProperties.ExceptedCanals] =
                    alternativeRoute.excepted_canals;
                  feature.properties[MapFeatureProperties.CrossedCanals] =
                    alternativeRoute.crossed_canals || [];
                  feature.properties[MapFeatureProperties.Distance] =
                    alternativeRoute.distance || 0;
                  feature.properties[MapFeatureProperties.Alternative] = true;
                }
                const smoothLine = turf.bezierSpline(feature as Feature<LineString, Properties>, {
                  resolution: 7000,
                  sharpness: 0.15,
                });
                const featureGeometry = feature.geometry as OurGeometry;
                const smoothLineGeometry = smoothLine.geometry;
                featureGeometry.coordinates = smoothLineGeometry.coordinates;
              });
              routes.push({
                features: alternativeRoute.routes.features,
                type: MapTypes.FeatureCollection,
              });
            });
          });
          patchState({ alternatives: routes });
        }),
        catchError((err) => {
          this.notificationService.showNotification(
            `Error when get a alternative route`,
            NotificationType.Error
          );
          patchState({ loading: false });
          return throwError(err);
        }),
        finalize(() => {
          patchState({ loading: false });
        })
      );
    } else {
      patchState({ loading: false, alternatives: [] });
      return;
    }
  }

  commonSaveFolder(
    getState: () => DealStateModel,
    dispatch: (actions: any) => Observable<void>,
    patchState: (val: Partial<DealStateModel>) => DealStateModel,
    newFolderOrSelectedFolder: Folder,
    saveFolderDialogRef: DynamicDialogRef,
    linkToNavigate: string,
    currDealId: number,
    navigate: boolean,
    oldFolderId?: number
  ) {
    clearCopyAlienLocalStorageKeys();
    const { currentEditedCopy, estimationsNavigationLinks, startCopiedDeals, deals } = getState();
    if (currentEditedCopy) {
      const dealIndex = startCopiedDeals.findIndex((deal) => deal.id === currentEditedCopy?.id);
      startCopiedDeals.splice(dealIndex, 1, currentEditedCopy as Deal);
    }
    startCopiedDeals.forEach((deal) => {
      deal.folder_id = newFolderOrSelectedFolder?.id;
      deal.folder = newFolderOrSelectedFolder;
      const action =
        currDealId === deal.id
          ? new UpdateDealCancelUncompletedFalse(/* prepareDealBeforeSave */ deal)
          : new UpdateFolderAtDeal(deal);
      dispatch(action).subscribe(
        () => {
          this.commonCloseFolder(
            getState,
            dispatch,
            patchState,
            estimationsNavigationLinks,
            linkToNavigate,
            navigate,
            saveFolderDialogRef,
            oldFolderId,
            newFolderOrSelectedFolder.id
          );
        },
        (err) => {
          this.notificationService.showNotification(
            `${this._stateName} ${err?.error?.error}`,
            NotificationType.Error
          );
        }
      );
    });
    deals.forEach((deal) => {
      deal.folder_id = newFolderOrSelectedFolder?.id;
      deal.folder = newFolderOrSelectedFolder;
    });
    const dealsIds = getDealsIds(estimationsNavigationLinks);
    if (newFolderOrSelectedFolder?.id && newFolderOrSelectedFolder?.id !== DRAFT_FOLDER_ID) {
      this.store.dispatch(new SetDealsOrderInFolder(newFolderOrSelectedFolder?.id, dealsIds));
    }
    patchState({
      startCopiedDeals: startCopiedDeals,
      deals: deals,
    });
  }

  commonCloseFolder(
    getState: () => DealStateModel,
    dispatch: (actions: any) => Observable<void>,
    patchState: (val: Partial<DealStateModel>) => DealStateModel,
    estimationsNavigationLinks: DealNavigationLink[],
    linkToNavigate: string,
    navigate: boolean,
    closeFolderDialogRef?: DynamicDialogRef,
    oldFolderId?: number,
    newFolderId?: number
  ) {
    if (oldFolderId) {
      const startCopiedDeals = getState().startCopiedDeals.filter(
        (copy) => copy.folder_id !== oldFolderId
      );
      const deals = getState().deals.filter((deal) => deal.folder_id !== oldFolderId);
      patchState({
        deals: deals,
        startCopiedDeals: startCopiedDeals,
      });
    }
    if (navigate) {
      dispatch(new ResetSelectedDeal());
      dispatch(new ResetNavigatedDeal());
      dispatch(new ResetCascadeNavigation());
      patchState({
        deals: [],
        startCopiedDeals: [],
        estimationsNavigationLinks: estimationsNavigationLinks.filter(
          (link) => link.name === LIST_TITLE || link.name === EMPTY_LIST_TITLE
        ),
      });
    } else {
      if (newFolderId) {
        const newEstimationsNavigationLinks = estimationsNavigationLinks.map((link) => {
          link.folderId = newFolderId;
          return link;
        });
        patchState({
          estimationsNavigationLinks: newEstimationsNavigationLinks,
        });
      }
    }
    patchState({
      selectDealChanges: false,
    });
    if (closeFolderDialogRef) {
      // true нужно для подписки saveFolderDialogRef.onClose в action OpenSaveFolderModal
      closeFolderDialogRef.close(true);
    }
    if (navigate) {
      this.router.navigate([linkToNavigate]);
    }
  }

  @Action(SetEstimationsNavigationLinks)
  setEstimationsNavigationLinks(
    { patchState }: StateContext<DealStateModel>,
    { estimationsNavigationLinks, onlyCascade }: SetEstimationsNavigationLinks
  ) {
    const navigation = getNavigationFromEstimationsNavigationLinks(estimationsNavigationLinks);
    if (onlyCascade) {
      patchState({ navigation: navigation });
    } else {
      patchState({
        estimationsNavigationLinks: estimationsNavigationLinks,
        navigation: navigation,
      });
    }
  }

  @Action(SetCurrentEditedCopy)
  setCurrentEditedCopy(
    { patchState }: StateContext<DealStateModel>,
    { deal }: SetCurrentEditedCopy
  ) {
    this.processFinances(deal);
    patchState({ currentEditedCopy: deal });
  }

  @Action(SetEventsFormInvalidStatus)
  setEventsFormInvalidStatus(
    { patchState }: StateContext<DealStateModel>,
    { eventsFormInvalid }: SetEventsFormInvalidStatus
  ) {
    patchState({ eventsFormInvalid: eventsFormInvalid });
  }

  @Action(FilterStartCopiedDeals)
  filterStartCopiedDeals(
    { getState, patchState }: StateContext<DealStateModel>,
    { folderId }: FilterStartCopiedDeals
  ) {
    const { startCopiedDeals } = getState();
    const newStartCopiedDeals = startCopiedDeals.filter((deal) => deal.folder_id !== folderId);
    patchState({ startCopiedDeals: newStartCopiedDeals });
  }

  @Action(ChangeValidationErrors)
  changeValidationErrors(
    { getState, patchState }: StateContext<DealStateModel>,
    { change }: ChangeValidationErrors
  ) {
    let { validationErrors } = getState();
    if (change.removeField) {
      validationErrors = validationErrors.filter(
        (error) => !error.includes(change.removeField || '')
      );
    } else {
      if (change.remove) {
        validationErrors = validationErrors.filter((error) => error !== change.field);
      } else {
        !validationErrors.includes(change.field) && change.field
          ? validationErrors.push(change.field)
          : null;
      }
    }
    patchState({ validationErrors: [...validationErrors] });
  }

  @Action(ResetValidationErrors)
  resetValidationErrors({ patchState }: StateContext<DealStateModel>) {
    patchState({ validationErrors: [] });
  }

  @Action(EstimateDealFinances)
  estimateDealFinances(
    { getState, patchState }: StateContext<DealStateModel>,
    { deal }: EstimateDealFinances
  ) {
    const selectedDeal = cloneDeep(getState().selectedDeal as Deal);
    return this.dealService.estimateDealFinances(deal).pipe(
      tap((res) => {
        if (selectedDeal) {
          DealState.calculateCargoEvents(selectedDeal);
          selectedDeal.finances = res;
          patchState({ finances: res });
        }
      })
    );
  }

  @Action(OpenFixFolderModal)
  openFixFolderModal(
    { getState, patchState, dispatch }: StateContext<DealStateModel>,
    { linkToNavigate, estimationsNavigationLinks, folderName }: OpenFixFolderModal
  ) {
    const foundFolders = new Subject<Folder[]>();
    const saveFolder = new EventEmitter<FixFolderEmitter>();
    const searchFolder = new EventEmitter<string>();
    const config: DynamicDialogConfig<SaveFixDialogConfig> = {
      header: 'Fix folder',
      data: {
        saveFolder,
        searchFolder,
        foundFolders,
        estimationsNavigationLinks,
        folderName,
      },
      width: '400px',
    };
    const neededType = linkToNavigate === ESTIMATOR_ROUTE ? VoyageType.Bulk : VoyageType.Tanker;
    searchFolder
      .pipe(
        tap((res) => {
          this.store.dispatch(new GetFoldersBySearch(neededType, res, '', true)).subscribe(
            ({ folder }) => {
              const folderState = folder as FolderStateModel;
              foundFolders.next(
                stayOnlyMyFolders(
                  folderState.sidebarFolders,
                  this.store.selectSnapshot(XAuthState.getUserId)
                )
              );
            },
            (err) => {
              this.notificationService.showNotification(
                ErrorNotifications.ErrorSearchFolders,
                NotificationType.Warning
              );
            }
          );
        })
      )
      .subscribe();
    const saveFolderDialogRef = this.dialogService.open(FixFolderComponent, config);

    saveFolder
      .pipe(
        tap((folderName) => {
          this.store
            .dispatch(
              new CopyFolder(
                folderName.folderId,
                folderName.folderName,
                false,
                true,
                folderName.ids
              )
            )
            .subscribe(
              ({ folder }) => {
                saveFolderDialogRef.close();
              },
              (err) => {
                this.notificationService.showNotification(
                  `${this._stateName} ${err?.error?.error}`,
                  NotificationType.Error
                );
              }
            );
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe();
  }

  /*   @Action(OpenDialogOpenCloseFolder)
  openDialogOpenCloseFolder(
    { getState, patchState, dispatch }: StateContext<DealStateModel>,
    { linkToNavigate, navigate, revertUpdate, folderId }: OpenDialogOpenCloseFolder
  ) {
    const saveExist = new EventEmitter();
    const dontSaveExist = new EventEmitter();
    const config: DynamicDialogConfig<OpenCloseFolderDialogConfig> = {
      data: {
        saveExist,
        dontSaveExist,
      },
      width: '500px',
    };
    const openCloseFolderDialogRef = this.dialogService.open(
      DialogOpenCloseFolderComponent,
      config
    );
    saveExist
      .pipe(
        tap(() => {
         dispatch(
            new CloseFolderInCurrEstimation(
              linkToNavigate,
              navigate,
              revertUpdate,
              folderId
            )
          );
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe();

    dontSaveExist
      .pipe(
        tap(() => {

          dispatch(
            new CloseFolderInLastEstimation(
              linkToNavigate,
              navigate,
              revertUpdate,
              folderId
            )
          );
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe();
  } */

  @Action(SetSummLDRateAndETbyCargoID)
  setSummLDRateAndETbyCargoID(
    { patchState }: StateContext<DealStateModel>,
    { summLDRateAndETbyCargoID }: SetSummLDRateAndETbyCargoID
  ) {
    patchState({ summLDRateAndETbyCargoID });
  }

  @Action(SetSummETwithoutCargoID)
  setSummETwithoutCargoID(
    { patchState }: StateContext<DealStateModel>,
    { summETwithoutCargoID }: SetSummETwithoutCargoID
  ) {
    patchState({ summETwithoutCargoID });
  }

  @Action(GetBreakEvenData)
  getBreakEvenData(
    { patchState }: StateContext<DealStateModel>,
    { dealId, rise }: GetBreakEvenData
  ) {
    patchState({ loading: true });
    return this.dealService.getDealBreakEvenData(dealId, rise).pipe(
      tap((beData) => {
        patchState({ breakEvenData: beData.break_evens });
      }),
      catchError((err) => {
        patchState({ loading: false });
        this.notificationService.showNotification(
          `${this._stateName} ${err?.error?.error}`,
          NotificationType.Error
        );
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }

  processFinances(deal: Partial<Deal>): void {
    const financeTotals: FinanceTotals = cloneDeep(emptyFinanceTotals);

    let resultWithoutHire = 0;
    let result = 0;
    let ntce = 0;

    if (deal?.cargoes) {
      deal.cargoes.forEach((cargo) => {
        let fullCargoPrice = 0;
        let cargoPriceWithCommissions = 0;
        let commission = 0;
        if (cargo.amount_type === CargoTableFreightType.AmountTypeLumpSum) {
          fullCargoPrice = getValueOrDefault(cargo?.price);
        } else {
          fullCargoPrice = getValueOrDefault(cargo?.price) * getValueOrDefault(cargo?.quantity);
        }
        commission =
          fullCargoPrice * (getValueOrDefault(cargo.voyage_commission) / DIVISOR_FOR_PERCENT);
        cargoPriceWithCommissions = fullCargoPrice - commission;

        if (deal.voyage_type === VoyageType.Tanker) {
          cargoPriceWithCommissions *= getValueOrDefault(cargo.ws_percent) / DIVISOR_FOR_PERCENT;
        }

        financeTotals.freight += cargoPriceWithCommissions;
      });
    }

    // console.log('financeTotals', financeTotals);

    if (deal?.fuel_consumptions) {
      deal.fuel_consumptions.forEach((fuelCons) => {
        let fuelPrice = 0;
        fuelPrice = getValueOrDefault(fuelCons.amount) * getValueOrDefault(fuelCons.price);
        financeTotals.bunker += fuelPrice;
      });
    }

    if (deal?.sundries) {
      deal.sundries.forEach((sundry) => {
        let sundryPrice = 0;
        sundryPrice = getValueOrDefault(sundry.amount);
        financeTotals.sundry_misc += sundryPrice;
      });
    }

    let startDate: Date;
    let endDate: Date;
    let hireMinutes = 0;
    let hireDays = 0;
    let hireBaseAmount = 0;
    let timeCharterCommission = 0;
    let hireWithCommissions = 0;

    if (deal?.events) {
      startDate = deal?.events[0]?.start_time as Date;
      endDate = deal?.events[deal.events.length - 1].end_time as Date;
      deal.events.forEach((event) => {
        let daPrice = 0;
        daPrice = getValueOrDefault(event.meta?.disbursement);
        financeTotals.da += daPrice;
      });

      const endDateMoment = moment(endDate);
      const startDateMoment = moment(startDate);
      hireMinutes = endDateMoment.diff(startDateMoment, 'minutes');
      hireMinutes +=
        getUserOrSystemDuration(deal.finances?.hire_finance?.extra_idle_minutes) +
        getUserOrSystemDuration(deal.finances?.hire_finance?.extra_sailing_laden_minutes);
      // console.log('hireMinutes', hireMinutes);

      hireDays = moment.duration({ minutes: hireMinutes }).asDays();
      // console.log('hireDays', hireDays);

      hireBaseAmount = getValueOrDefault(deal.finances?.hire_finance?.hire_per_day) * hireDays;

      timeCharterCommission =
        getValueOrDefault(deal.finances?.hire_finance?.time_charter_commission) /
        DIVISOR_FOR_PERCENT;

      hireWithCommissions = hireBaseAmount - hireBaseAmount * timeCharterCommission;
      financeTotals.hire = hireWithCommissions;
    }

    resultWithoutHire =
      financeTotals.freight -
      financeTotals.sundry_misc -
      financeTotals.da -
      financeTotals.bunker -
      getValueOrDefault(deal?.finances?.hire_finance?.ballast_bonus);
    result = resultWithoutHire - financeTotals.hire;

    // console.log('result', result);

    if (hireDays) {
      ntce = resultWithoutHire / hireDays;
      // console.log('ntce', ntce);
    }
  }

  commonCalculateCargo(
    cargo: Cargo,
    getState: () => DealStateModel,
    patchState: (val: Partial<DealStateModel>) => DealStateModel
  ): Observable<CargoCalculator> | Observable<null> {
    if (cargo.cargo_calculator) {
      return this.cargoCalculatorService.update(cargo.cargo_calculator).pipe(
        tap((cargoCalculator) => {
          cargo.cargo_calculator = cargoCalculator;
          const { selectedCargoes } = getState();
          if (selectedCargoes) {
            const oldCargoIndex = selectedCargoes?.findIndex(
              (innerCargo) => innerCargo.id === cargo.id
            );
            if (isNumber(oldCargoIndex) && oldCargoIndex !== -1) {
              selectedCargoes?.splice(oldCargoIndex, 1, cargo);
              patchState({ selectedCargoes: selectedCargoes });
            }
          }
        }),
        catchError((err) => {
          patchState({ loading: false });
          this.notificationService.showNotification(
            `${this._stateName} ${err?.error?.error}`,
            NotificationType.Error
          );
          return throwError(err);
        }),
        finalize(() => {
          patchState({ loading: false });
        })
      );
    }
    return of(null);
  }

  groupCalculateCargo(
    cargoes: Cargo[],
    getState: () => DealStateModel,
    patchState: (val: Partial<DealStateModel>) => DealStateModel
  ): Observable<CargoCalculator[]> | Observable<null> {
    const calculators = cargoes.reduce<CargoCalculator[]>((acc, cargo) => {
      if (cargo.cargo_calculator) {
        acc.push(cargo.cargo_calculator);
      }
      return acc;
    }, []);
    if (calculators.length) {
      return this.cargoCalculatorService.updateGroup(calculators).pipe(
        tap((cargoCalculators) => {
          const { selectedCargoes } = getState();
          selectedCargoes.map((cargo) => {
            const calculator = cargoCalculators.find(
              (calc) => calc.id === cargo.cargo_calculator_id
            );
            if (calculator) {
              cargo.cargo_calculator = calculator;
            }
            return cargo;
          });

          if (selectedCargoes) {
            patchState({ selectedCargoes: selectedCargoes });
          }
        }),
        catchError((err) => {
          patchState({ loading: false });
          this.notificationService.showNotification(
            `${this._stateName} ${err?.error?.error}`,
            NotificationType.Error
          );
          return throwError(err);
        }),
        finalize(() => {
          patchState({ loading: false });
        })
      );
    }
    return of(null);
  }
}
