import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AgGridModule } from 'ag-grid-angular';
import { AgGridSharedModule } from '../ag-grid-shared/ag-grid-shared.module';
import { FoldersListComponent } from './folders-list.component';

export const FOLDERS_LIST_IMPORTS = [AgGridModule, AgGridSharedModule, CommonModule];

@NgModule({
  declarations: [FoldersListComponent],
  imports: FOLDERS_LIST_IMPORTS,
  exports: [FoldersListComponent],
})
export class FoldersListModule {}
