<form class="login-form" [formGroup]="loginFormGroup" id="login-form">
  <section class="inputs-section">
    <div class="flex flex-column gap-2">
      <div class="p-inputgroup">
        <span
          class="p-inputgroup-addon"
          [ngStyle]="{
            'background-color': isEmailControlInvalid ? PrimeNgColors.Error : PrimeNgColors.Primary,
            'border-color': isEmailControlInvalid ? PrimeNgColors.Error : PrimeNgColors.Primary
          }"
        >
          <i [class]="PrimeNgIcons.AT" [style]="{ color: PrimeNgColors.White }"></i>
        </span>
        <input
          pInputText
          id="email"
          type="email"
          name="email"
          #email
          [placeholder]="DEFAULT_USER"
          formControlName="email"
          [ngClass]="{
            'ng-invalid ng-dirty': isEmailControlInvalid
          }"
          required
        />
      </div>
      <small id="email-error" [style]="{ color: PrimeNgColors.Error }"
        >&nbsp;
        <span *ngIf="isEmailControlInvalid">{{ emailControlError }}</span>
      </small>
    </div>
    <div class="flex flex-column gap-2">
      <div class="p-inputgroup">
        <button
          pButton
          type="button"
          [icon]="passwordIcon"
          (click)="hidePassword()"
          [ngStyle]="{
            'background-color': isPasswordControlInvalid
              ? PrimeNgColors.Error
              : PrimeNgColors.Primary,
            'border-color': isPasswordControlInvalid ? PrimeNgColors.Error : PrimeNgColors.Primary
          }"
        ></button>
        <input
          pInputText
          [type]="passwordInputType"
          id="password"
          name="password"
          placeholder="Password"
          #password
          formControlName="password"
          [ngClass]="{
            'ng-invalid ng-dirty': isPasswordControlInvalid
          }"
          required
        />
      </div>
      <small id="password-error" [style]="{ color: PrimeNgColors.Error }"
        >&nbsp;
        <span *ngIf="isPasswordControlInvalid">{{ passwordControlError }}</span>
      </small>
    </div>
  </section>
  <section class="flex align-items-center gap-2 justify-content-between">
    <div class="flex align-items-center gap-2">
      <p-checkbox
        [binary]="true"
        (onChange)="detectChanges()"
        [value]="loginFormGroup.controls.rememberLogin.value"
        formControlName="rememberLogin"
        inputId="rememberLogin"
      ></p-checkbox>
      <label for="rememberLogin">Remember me</label>
    </div>
    <p-button
      label="Forgot your password?"
      [link]="true"
      size="small"
      (click)="onForgotPassword()"
    ></p-button>
  </section>
  <section class="p-fluid margin-top-10">
    <p-button
      size="small"
      type="button"
      [label]="SIGN_IN"
      (click)="onSignIn()"
      [disabled]="isLoading"
      [loading]="isLoading"
      id="sign_in_button"
    ></p-button>
  </section>
</form>
