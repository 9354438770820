import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

interface Base<T> {
  readonly path: string;
  setPath(path: string): void;
  create(item: Partial<T>): Observable<T>;
  getAll(id: number): Observable<T[]>;
  get(id: number): Observable<T>;
  update(item: Partial<T>): Observable<T>;
  delete(id: number): Observable<void>;
}

@Injectable({
  providedIn: 'root',
})
export class BaseService<T> implements Base<T> {
  path = '';
  constructor(protected readonly http: HttpClient) {}

  setPath(path: string): void {
    this.path = path;
  }

  getAll(): Observable<T[]> {
    return this.http.get<T[]>(this.path);
  }

  get(id: number): Observable<T> {
    return this.http.get<T>(`${this.path}/${id}`);
  }

  create(item: Partial<T>): Observable<T> {
    return this.http.post<T>(this.path, item);
  }

  update(item: Partial<T>): Observable<T> {
    const id = 'id' as keyof T;
    const idT = item[id];
    return this.http.put<T>(`${this.path}/${idT}`, item);
  }

  delete(id: number): Observable<void> {
    return this.http.delete<void>(`${this.path}/${id}`);
  }
}
