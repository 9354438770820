import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GetUserSubscriptionModelsResponse, UserSubscription } from '@estimator/models';
import { Observable } from 'rxjs';
import { BaseService } from '../base';

@Injectable({
  providedIn: 'root',
})
export class ProductsService extends BaseService<UserSubscription> {
  constructor(protected override readonly http: HttpClient) {
    super(http);
    this.setPath('/api/subscriptions');
  }

  getUserSubscriptionModels(): Observable<GetUserSubscriptionModelsResponse> {
    return this.http.get<GetUserSubscriptionModelsResponse>(`${this.path}/models`);
  }
}
