import { getCurrencySymbol } from '@angular/common';
import { Currency, DEFAULT_FIAT_MULTIPLIER } from '@estimator/models';

export const convertCurrencyToUiValue = (
  value?: number | null,
  fiatMulitplier?: number
): number => {
  return (value || 0) / (fiatMulitplier || DEFAULT_FIAT_MULTIPLIER);
};

export const convertCurrencyToServerValue = (
  value?: number | null,
  fiatMulitplier?: number
): number => {
  return Math.round((value || 0) * (fiatMulitplier || DEFAULT_FIAT_MULTIPLIER));
};

export const getCurrencyShortSymbol = (currency?: Currency | null): string => {
  const code = getCurrencySymbol(currency?.currency_code || '', 'narrow');
  return code;
};
