import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SignUpFormComponent } from './sign-up-form.component';

@NgModule({
  declarations: [SignUpFormComponent],
  imports: [CommonModule, ReactiveFormsModule, InputTextModule, ButtonModule, CheckboxModule, RadioButtonModule, CardModule, SelectButtonModule],
  exports: [SignUpFormComponent],
})
export class SignUpFormModule {}
