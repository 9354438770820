export const platformProductsMock = [
  {
    // @ts-ignore
    id: '73f8375a-7b9d-4acb-94e3-d90fef339e9b',
    meta: {
      shortName: '8217:2012 DMB 9c97e339-2cfd-4b7a-a6ed-9b263592049e',
      fullName: '8217:2012 Distillate DMB 1.00 % DMB 1.00% 8217:2012',
      additional: {
        iso: '8217:2012',
        type: 'Distillate',
        class: 'DMB',
        grade: 'DMB 1.00% 8217:2012',
        maxSulfur: '1.00',
        abbreviation: 'MDO',
        measurementUnit: '%',
      },
    },
    volume_from: 200000,
    volume_to: 250000,
    measure: 'a9be2ce7-3213-403b-ba43-382c89da1559',
    price: 22200,
    final_qty: null,
  },
  {
    // @ts-ignore
    id: '73f8375a-7b9d-4acb-94e3-d90fef339e9c',
    meta: {
      shortName: '8217:2012 DMB 9c97e339-2cfd-4b7a-a6ed-9b263592049e',
      fullName: '8217:2012 Distillate DMB 1.00 % DMB 1.00% 8217:2012',
      additional: {
        iso: '8217:2012',
        type: 'Distillate',
        class: 'DMB',
        grade: 'DMB 1.00% 8217:2012',
        maxSulfur: '1.00',
        abbreviation: 'MDO',
        measurementUnit: '%',
      },
    },
    volume_from: 200000,
    volume_to: 250000,
    measure: 'a9be2ce7-3213-403b-ba43-382c89da1559',
    price: 22200,
    final_qty: null,
  },
  {
    // @ts-ignore
    id: '73f8375a-7b9d-4acb-94e3-d90fef339e9s',
    meta: {
      shortName: '8217:2012 DMB 9c97e339-2cfd-4b7a-a6ed-9b263592049e',
      fullName: '8217:2012 Distillate DMB 1.00 % DMB 1.00% 8217:2012',
      additional: {
        iso: '8217:2012',
        type: 'Distillate',
        class: 'DMB',
        grade: 'DMB 1.00% 8217:2012',
        maxSulfur: '1.00',
        abbreviation: 'MDO',
        measurementUnit: '%',
      },
    },
    volume_from: 200000,
    volume_to: 250000,
    measure: 'a9be2ce7-3213-403b-ba43-382c89da1559',
    price: 22200,
    final_qty: null,
  },
];
