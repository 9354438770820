import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  ZeroNullValidator,
  cubicMetersToFeet,
  feetToCubicMeters,
  getMinValueFromFields,
  makeCargoCalculationFormGroup,
  feetToMeters,
  metersToFeet,
} from '@estimator/helpers';
import { PortSeasonTypes, PortWaterTypes } from '@estimator/mocks';
import {
  CargoCalculator,
  CargoCalculatorForm,
  GrainCalculationType,
  GrainCalculationTypes,
  MAX_FINANCE_VALUE,
  MaterialThemeColor,
  PortSeason,
  PortWater,
  TEXT_CBM,
  TEXT_CFT,
  TEXT_MT,
} from '@estimator/models';
import { isEqual } from 'lodash';
import { Subject, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'estimator-cargo-calculator',
  templateUrl: './cargo-calculator.component.html',
  styleUrls: ['./cargo-calculator.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CargoCalculatorComponent implements OnInit, OnDestroy {
  @Input()
  set cargoCalculator(data: CargoCalculator | null) {
    if (data) {
      this._cargoCalculation = data;
      if (!this.cargoCalculatorForm) {
        this.cargoCalculatorForm = this._makeCargoCalculationFormGroup(data);
        this.setValidators();
      } else {
        this._cargoCalculation = data;
        this.cargoCalculatorForm.patchValue(
          {
            ...data,
            grain_volume_ft: cubicMetersToFeet(data.grain_volume || 0),
            bale_volume_ft: cubicMetersToFeet(data.bale_volume || 0),
            stowage_factor_ft: cubicMetersToFeet(data.stowage_factor || 0),
            load_port_draft_restriction_ft: metersToFeet(data.load_port_draft_restriction || 0),
            summ_max_fields: getMinValueFromFields(data),
          },
          { emitEvent: false }
        );
        this.setValidators();
      }
      this._oldValue = this.cargoCalculatorForm.value as CargoCalculator;
    }
    this.cdr.detectChanges();
  }
  get cargoCalculator(): CargoCalculator | null {
    return this._cargoCalculation;
  }
  @Input()
  set grainBaleCalculationType(value: GrainCalculationType) {
    this.cargoCalculatorForm.controls.grain_bale_calculation_type.patchValue(value, {
      emitEvent: false,
    });
  }
  get grainBaleCalculationType(): GrainCalculationType {
    return this._grainBaleCalculationType;
  }
  @Input() isAdvanced = false;
  @Output() calculate = new EventEmitter<CargoCalculator>();
  @Output() updateCargoQuantity = new EventEmitter<number>();
  @Output() getLastConstants = new EventEmitter<void>();
  readonly MaterialThemeColor = MaterialThemeColor;
  readonly MAX_FINANCE_VALUE = MAX_FINANCE_VALUE;
  readonly TEXT_CBM = TEXT_CBM;
  readonly TEXT_CFT = TEXT_CFT;
  readonly TEXT_MT = TEXT_MT;
  readonly grainCalculationTypes = GrainCalculationTypes;
  private _cargoCalculation: CargoCalculator | null = null;
  private _grainBaleCalculationType: GrainCalculationType =
    GrainCalculationType.GrainCalculationType;
  private _oldValue: CargoCalculator | null = null;
  private _onDestroy$ = new Subject<void>();
  cargoCalculatorForm = this._makeCargoCalculationFormGroup();
  portWaterTypes: PortWater[] = PortWaterTypes;
  portSeasonTypes: PortSeason[] = PortSeasonTypes;

  constructor(private readonly cdr: ChangeDetectorRef) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.code === 'Enter') {
      const target = event.target as HTMLElement;
      target?.blur();
    }
  }

  ngOnInit(): void {
    this.cargoCalculatorForm?.valueChanges
      ?.pipe(
        tap((value) => {
          if (value.grain_volume_ft && value.grain_volume_ft !== this._oldValue?.grain_volume_ft) {
            value.grain_volume = feetToCubicMeters(value.grain_volume_ft || 0);
          }
          if (value.bale_volume_ft && value.bale_volume_ft !== this._oldValue?.bale_volume_ft) {
            value.bale_volume = feetToCubicMeters(value.bale_volume_ft || 0);
          }
          if (
            value.stowage_factor_ft &&
            value.stowage_factor_ft !== this._oldValue?.stowage_factor_ft
          ) {
            value.stowage_factor = feetToCubicMeters(value.stowage_factor_ft || 0);
          }
          if (
            value.load_port_draft_restriction_ft &&
            value.load_port_draft_restriction_ft !== this._oldValue?.load_port_draft_restriction_ft
          ) {
            value.load_port_draft_restriction = feetToMeters(value.load_port_draft_restriction_ft || 0);
          }
          if (
            value.load_port_draft_restriction &&
            value.load_port_draft_restriction !== this._oldValue?.load_port_draft_restriction
          ) {
            value.load_port_draft_restriction_ft = metersToFeet(value.load_port_draft_restriction || 0);
          }
          // если вбиваем хотя 1 из 5 значений разбивки deductibles, то удаляем корневое deductibles
          if (
            (value.bunker_weight && value.bunker_weight !== this._oldValue?.bunker_weight) ||
            (value.permanent_ballast &&
              value.permanent_ballast !== this._oldValue?.permanent_ballast) ||
            (value.constant_weight && value.constant_weight !== this._oldValue?.constant_weight) ||
            (value.fresh_water && value.fresh_water !== this._oldValue?.fresh_water) ||
            (value.other_weight && value.other_weight !== this._oldValue?.other_weight)
          ) {
            delete value.deductibles;
          }
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe((value) => {
        if (value && !isEqual(this._oldValue, value)) {
          this.calculate.emit(value as CargoCalculator);
        }
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  private _makeCargoCalculationFormGroup(data?: CargoCalculator): FormGroup<CargoCalculatorForm> {
    return makeCargoCalculationFormGroup(data, this.grainBaleCalculationType);
  }

  isMinimumQuantity(
    field:
      | 'max_grain_volume_quantity'
      | 'max_bale_volume_quantity'
      | 'max_load_port_quantity'
      | 'max_dwt_quantity'
      | 'max_discharge_port_draft_quantity'
      | 'max_available_tonnage_quantity'
      | 'max_available_tonnage_discharge_quantity'
  ): boolean {
    const {
      max_grain_volume_quantity,
      max_bale_volume_quantity,
      max_load_port_quantity,
      max_dwt_quantity,
      max_discharge_port_draft_quantity,
      max_available_tonnage_quantity,
      max_available_tonnage_discharge_quantity,
    } = this.cargoCalculatorForm.value;
    const arr = [
      max_grain_volume_quantity || 0,
      max_bale_volume_quantity || 0,
      max_load_port_quantity || 0,
      max_dwt_quantity || 0,
      max_discharge_port_draft_quantity || 0,
      max_available_tonnage_quantity || 0,
      max_available_tonnage_discharge_quantity || 0,
    ];
    const minValue = Math.min(...arr.filter((elem) => !!elem));
    return this.cargoCalculatorForm.value[field] === minValue;
  }

  isFieldNotNull(
    field:
      | 'max_grain_volume_quantity'
      | 'max_bale_volume_quantity'
      | 'max_load_port_quantity'
      | 'max_dwt_quantity'
      | 'max_discharge_port_draft_quantity'
      | 'max_available_tonnage_quantity'
      | 'max_available_tonnage_discharge_quantity'
  ): boolean {
    return !!this.cargoCalculatorForm.value[field];
  }

  onCopyQuantity(
    field: /* | 'max_grain_volume_quantity'
      | 'max_bale_volume_quantity'
      | 'max_load_port_quantity'
      | 'max_dwt_quantity'
      | 'max_discharge_port_draft_quantity'
      | 'max_available_tonnage_quantity'
      | 'max_available_tonnage_discharge_quantity'
      | */ 'summ_max_fields'
  ): void {
    const quantity = this.cargoCalculatorForm.get(field)?.value;
    if (quantity) {
      this.updateCargoQuantity.emit(quantity);
    }
  }

  onGetLastConstants(): void {
    this.getLastConstants.emit();
  }

  setValidators(): void {
    this.cargoCalculatorForm.controls.dwt.setAsyncValidators(ZeroNullValidator());
    this.cargoCalculatorForm.controls.vessel_max_draft.setAsyncValidators(ZeroNullValidator());
    this.cargoCalculatorForm.controls.tpc.setAsyncValidators(ZeroNullValidator());
  }

  onDefaultGrainBaleCalculationTypeChange(value: GrainCalculationType): void {
    this.cargoCalculatorForm.controls.grain_bale_calculation_type?.patchValue(value);
  }
}
