import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  FleetVessel,
  FleetVesselActiveRequestPlusVesselID,
  FleetVesselCollection,
  PropsDeleteFleetVesselAndFleetVesselCollection,
} from '@estimator/models';

@Component({
  selector: 'estimator-fleet-vessels',
  templateUrl: './fleet-vessels.component.html',
  styleUrls: ['./fleet-vessels.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FleetVesselsComponent {
  @Input()
  set myVessels(data: FleetVessel[]) {
    this._myVessels = data;
    this.cdr.detectChanges();
  }
  get myVessels(): FleetVessel[] {
    return this._myVessels;
  }
  @Input()
  set myCompanyVessels(data: FleetVessel[]) {
    this._myCompanyVessels = data;
    this.cdr.detectChanges();
  }
  get myCompanyVessels(): FleetVessel[] {
    return this._myCompanyVessels;
  }
  @Input() myCollections: FleetVesselCollection[] = [];
  @Input() myCompanyCollections: FleetVesselCollection[] = [];
  @Output() updateFleetVessel = new EventEmitter<FleetVessel>();
  @Output() updateFleetVesselActive = new EventEmitter<FleetVesselActiveRequestPlusVesselID>();
  @Output() deleteFleetVessel = new EventEmitter<PropsDeleteFleetVesselAndFleetVesselCollection>();
  @Output() positionMap = new EventEmitter<number>();

  private _myVessels: FleetVessel[] = [];
  private _myCompanyVessels: FleetVessel[] = [];

  constructor(private readonly cdr: ChangeDetectorRef) {}

  onUpdateFleetVessel(collection: FleetVessel) {
    this.updateFleetVessel.emit(collection);
  }

  onUpdateFleetVesselActive(activeRequestPlusVesselID: FleetVesselActiveRequestPlusVesselID) {
    this.updateFleetVesselActive.emit(activeRequestPlusVesselID);
  }

  onDeleteFleetVessel(object: PropsDeleteFleetVesselAndFleetVesselCollection) {
    this.deleteFleetVessel.emit(object);
  }

  onPositionMap(generalVesselId: number) {
    this.positionMap.emit(generalVesselId);
  }
}
