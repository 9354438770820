import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material/core';
import {
  Company,
  ConsumptionRate,
  Fuel,
  MaterialThemeColor,
  PrimeNgColors,
  STRING,
  UserGuideType,
  Vessel,
  VesselForm,
  ZERO_STRING,
} from '@estimator/models';
import {
  ColDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ValueFormatterParams,
} from 'ag-grid-community';
import { cloneDeep, isEqual } from 'lodash';
import { Subject, debounceTime, takeUntil } from 'rxjs';
import { AgGridHeaderComponent } from '../ag-grid-shared/ag-grid-header.component';
import { AgGridNumericCellEditorComponent } from '../ag-grid-shared/ag-grid-numeric-editor.component';
import { AgGridSelectCellEditorComponent } from '../ag-grid-shared/ag-grid-select-editor.component';
import { AgGridButtonGroupComponent } from '../ag-grid-shared/button-group.component';

@Component({
  selector: 'estimator-vessel-form',
  templateUrl: './vessel-form.component.html',
  styleUrls: ['./vessel-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VesselFormComponent implements OnInit, OnDestroy {
  @Input()
  set vessel(value: Vessel | null) {
    if (!value) {
      delete this._vessel;
      this.vesselFormGroup.reset({});
      this.vesselSelectControl.reset();
      this.consumptions = [];
    }
    if (value?.id) {
      this._vessel = cloneDeep(value);
      this._includedFuels = [];
      this.vessel?.consumption_rates?.forEach((cons) => {
        if (cons.fuel?.id) {
          this._includedFuels.push(cons.fuel.id);
        }
      });
      if (value.is_raw) {
        value.id = 0;
      }
      this.vesselSelectControl.setValue(value.name || '', { emitEvent: false });
      if (!this._vessel.consumption_rates) {
        this._vessel.consumption_rates = [];
      }
      this.consumptions = this._vessel.consumption_rates;
      this.vesselFormGroup.patchValue(value, { emitEvent: false });
    }
    if (!value?.is_raw) {
      this.vesselFormGroup.patchValue({ is_raw: false }, { emitEvent: false });
    }
  }
  get vessel() {
    return this._vessel || ({} as Vessel);
  }
  @Input() fuels: Fuel[] = [];
  @Input()
  set vessels(data: Partial<Vessel>[]) {
    this._vessels = data;
  }
  get vessels(): Partial<Vessel>[] {
    return this._vessels;
  }
  @Input()
  set isLoading(bool: boolean) {
    this._isLoading = bool;
  }
  get isLoading(): boolean {
    return this._isLoading;
  }
  @Input() isVesselEditor = true;
  @ViewChild('vesselFuelsAgGrid', { read: ElementRef }) vesselFuelsGrid?: ElementRef;
  @Output() searchVessel = new EventEmitter<string>();
  @Output() updateVessel = new EventEmitter<Vessel>();
  @Output() showTooltip = new EventEmitter<string>();
  vesselSelectControl = new FormControl('');
  vesselFormGroup: FormGroup<VesselForm> = new FormGroup<VesselForm>({
    alpha_2: new FormControl<string>('', { nonNullable: true }),
    id: new FormControl<number>(0, { nonNullable: true }),
    created_at: new FormControl<string | Date | null>(null),
    created_by: new FormControl<number | null>(null),
    updated_at: new FormControl<string | Date | null>(null),
    updated_by: new FormControl<number | null>(null),
    deleted_at: new FormControl<string | Date | null>(null),
    deleted_by: new FormControl<number | null>(null),
    // ballast_speed: new FormControl<number>(0, { nonNullable: true }),
    beam: new FormControl<number>(0, { nonNullable: true }),
    build_year: new FormControl<number>(0, { nonNullable: true }),
    builder: new FormControl<string>('', { nonNullable: true }),
    call_sign: new FormControl<string>('', { nonNullable: true }),
    classification_society: new FormControl<string>('', { nonNullable: true }),
    company: new FormControl<Company | null>(null),
    company_id: new FormControl<number>(0, { nonNullable: true }),
    consumption_rates: new FormControl<ConsumptionRate[]>([] as ConsumptionRate[], {
      nonNullable: true,
    }),
    dead_weight: new FormControl<number>(0, { nonNullable: true }),
    draught: new FormControl<number>(0, { nonNullable: true }),
    flag: new FormControl<string>('', { nonNullable: true }),
    general_vessel_id: new FormControl<number>(0, { nonNullable: true }),
    // gross_registered_tonnage: new FormControl<number>(0, { nonNullable: true }),
    bale: new FormControl<number>(0, { nonNullable: true }),
    grain: new FormControl<number>(0, { nonNullable: true }),
    gross_tonnage: new FormControl<number>(0, { nonNullable: true }),
    imo: new FormControl<number>(0, { nonNullable: true }),
    is_raw: new FormControl<boolean>(false, { nonNullable: true }),
    // laden_speed: new FormControl<number>(0, { nonNullable: true }),
    length: new FormControl<number>(0, { nonNullable: true }),
    // low_speed: new FormControl<number>(0, { nonNullable: true }),
    manager: new FormControl<string>('', { nonNullable: true }),
    mmsi: new FormControl<number>(0, { nonNullable: true }),
    name: new FormControl<string>('', { nonNullable: true }),
    owner: new FormControl<string>('', { nonNullable: true }),
    place_of_built: new FormControl<string>('', { nonNullable: true }),
    teu: new FormControl<number>(0, { nonNullable: true }),
    tpc: new FormControl<number>(0, { nonNullable: true }),
    gross_registered_tonnage: new FormControl<number>(0, { nonNullable: true }),
    type: new FormControl<string>('', { nonNullable: true }),
    yard: new FormControl<string>('', { nonNullable: true }),
  });
  consumptions: ConsumptionRate[] = [];
  columnDefs: ColDef<ConsumptionRate>[] = [
    {
      headerComponent: 'buttonRenderer',
      headerComponentParams: (params: any) => {
        return {
          ...params,
          buttons: [
            {
              title: 'Add vessel',
              tooltip: 'Add vessel',
              icon: 'add',
              color: PrimeNgColors.White,
              click: () => this.onAddConsumption(),
            },
          ],
        };
      },
      cellRenderer: 'buttonRenderer',
      cellRendererParams: (params: any) => {
        return {
          ...params,
          buttons: [
            {
              title: 'Delete vessel',
              tooltip: 'Delete vessel',
              icon: 'delete',
              click: () => this.onDeleteConsumption(params.data),
            },
          ],
        };
      },
      cellClass: 'center-aligned-cell',
      minWidth: 36,
      width: 36,
    },
    {
      field: 'fuel',
      headerComponent: 'header',
      headerComponentParams: () => {
        return {
          title: 'Fuel',
          // click: () => this.headerFuelClick(),
        };
      },
      width: 100,
      minWidth: 100,
      flex: 1,
      cellEditor: 'selectEditor',
      cellEditorParams: (params: any) => {
        return {
          ...params,
          dataArray: this.fuels,
          disabledIds: this._includedFuels,
          visibleKeys: ['name'],
          closeEditor: this.closeEditor.bind(this),
        };
      },
      valueFormatter: (params: ValueFormatterParams) => {
        return params.data?.fuel?.name;
      },
      onCellValueChanged: (event) => {
        if (event?.data?.fuel?.id) {
          event.data.fuel_id = event?.data?.fuel?.id;
        }
      },
    },
    {
      field: 'main_engine_rate.ballast',
      headerName: 'Ballast',
      flex: 1,
      cellEditor: 'numericEditor',
      valueFormatter: (params) => params.value || 0,
      onCellValueChanged: (event) => {
        if (event?.data?.main_engine_rate) {
          event.data.main_engine_rate.ballast = event.newValue;
        }
      },
    },
    {
      field: 'main_engine_rate.laden',
      headerName: 'Laden',
      flex: 1,
      cellEditor: 'numericEditor',
      valueFormatter: (params) => params.value || 0,
      onCellValueChanged: (event) => {
        if (event?.data?.main_engine_rate) {
          event.data.main_engine_rate.laden = event.newValue;
        }
      },
    },
    {
      field: 'main_engine_rate.work_on_loading',
      headerName: 'Work on load',
      flex: 1,
      cellEditor: 'numericEditor',
      valueFormatter: (params) => params.value || 0,
      onCellValueChanged: (event) => {
        if (event?.data?.main_engine_rate) {
          event.data.main_engine_rate.work_on_loading = event.newValue;
        }
      },
    },
    {
      field: 'main_engine_rate.work_on_discharge',
      headerName: 'Work on disch',
      flex: 1,
      cellEditor: 'numericEditor',
      valueFormatter: (params) => params.value || 0,
      onCellValueChanged: (event) => {
        if (event?.data?.main_engine_rate) {
          event.data.main_engine_rate.work_on_discharge = event.newValue;
        }
      },
    },
    {
      field: 'main_engine_rate.idle',
      headerName: 'Idle',
      flex: 1,
      cellEditor: 'numericEditor',
      valueFormatter: (params) => params.value || 0,
      onCellValueChanged: (event) => {
        if (event?.data?.main_engine_rate) {
          event.data.main_engine_rate.idle = event.newValue;
        }
      },
    },
    {
      field: 'aux_engine_rate.laden',
      headerName: 'Aux sea',
      flex: 1,
      cellEditor: 'numericEditor',
      valueFormatter: (params) => params.value || 0,
      onCellValueChanged: (event) => {
        if (event?.data?.aux_engine_rate) {
          event.data.aux_engine_rate.laden = event.newValue;
        }
      },
    },
    {
      field: 'aux_engine_rate.idle',
      headerName: 'Aux idle',
      flex: 1,
      cellEditor: 'numericEditor',
      valueFormatter: (params) => params.value || 0,
      onCellValueChanged: (event) => {
        if (event?.data?.aux_engine_rate) {
          event.data.aux_engine_rate.idle = event.newValue;
        }
      },
    },
    {
      field: 'aux_engine_rate.work_on_loading',
      headerName: 'Aux work on load',
      flex: 1,
      cellEditor: 'numericEditor',
      valueFormatter: (params) => params.value || 0,
      onCellValueChanged: (event) => {
        if (event?.data?.aux_engine_rate) {
          event.data.aux_engine_rate.work_on_loading = event.newValue;
        }
      },
    },
  ];
  gridOptions: GridOptions<ConsumptionRate> = {
    rowModelType: 'clientSide',
    columnDefs: this.columnDefs,
    components: {
      buttonRenderer: AgGridButtonGroupComponent,
      header: AgGridHeaderComponent,
      numericEditor: AgGridNumericCellEditorComponent,
      selectEditor: AgGridSelectCellEditorComponent,
    },
    getRowId: (params) => {
      return params.data.id ? params.data.id.toString() : params.data.unique_row_id || ZERO_STRING;
    },
    defaultColDef: {
      editable: true,
      resizable: true,
    },
    suppressContextMenu: true,
    suppressRowClickSelection: true,
    suppressScrollOnNewData: true,
    singleClickEdit: true,
    animateRows: true,
    rowHeight: 29,
    headerHeight: 29,
    autoSizePadding: 0,
    onCellEditingStopped: (event) => {
      if (event.data) {
        if (
          !this._gridApi?.getEditingCells().length &&
          (event.newValue || event.newValue === 0) &&
          event.newValue !== event.oldValue
        ) {
          this.vesselFormGroup.patchValue({ consumption_rates: this.rowData });
        }
      }
    },
  };
  MaterialThemeColor = MaterialThemeColor;
  private _includedFuels: number[] = [];
  private _vessel?: Vessel;
  private _gridApi?: GridApi<ConsumptionRate>;
  private _isLoading = false;
  private _vessels: Partial<Vessel>[] = [];
  private _onDestroy$ = new Subject<void>();

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this._gridApi?.stopEditing(true);
    }
    if (event.key === 'Enter') {
      this._gridApi?.stopEditing();
    }
  }

  get isNotChanged(): boolean {
    return (
      isEqual(this.vessel, this.vesselFormGroup.value) && isEqual(this.consumptions, this.rowData)
    );
  }

  get rowData(): ConsumptionRate[] {
    const consumptions: ConsumptionRate[] = [];
    this._gridApi?.forEachNode((node) => {
      if (node.data) {
        consumptions.push(node.data);
      }
    });
    return consumptions;
  }

  constructor(private readonly cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.vesselSelectControl.valueChanges
      .pipe(debounceTime(500), takeUntil(this._onDestroy$))
      .subscribe((value) => {
        if (value && typeof value === STRING && value !== this.vesselFormGroup.get('name')?.value) {
          this.searchVessel.emit(value);
        }
      });
    this.vesselFormGroup.valueChanges
      .pipe(debounceTime(500), takeUntil(this._onDestroy$))
      .subscribe(() => {
        this.onSaveVessel();
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  onGridReady({ api }: GridReadyEvent) {
    this._gridApi = api;
    this._gridApi.hideOverlay();
  }

  onSaveVessel(): void {
    const vessel = this.vesselFormGroup.value as Vessel;
    vessel.consumption_rates = this.rowData;
    this.updateVessel.emit(vessel);
  }

  onVesselSelect(event: MatOptionSelectionChange<Partial<Vessel>>): void {
    const vessel = event.source.value;
    if (event.isUserInput) {
      if (vessel.is_raw) {
        vessel.id = 0;
      }
      this.vesselFormGroup.reset({}, { emitEvent: false });
      this.vesselFormGroup.patchValue(vessel);
      if (vessel.consumption_rates) {
        this.consumptions = [...vessel.consumption_rates];
      } else {
        this.consumptions = [];
      }
      if (vessel.name) {
        this.vesselSelectControl.setValue(vessel.name, { emitEvent: false });
      }
      this.cdr.detectChanges();
    }
  }

  onAddConsumption(): void {
    let newConsumption: ConsumptionRate = {
      fuel_id: 0,
      fuel: { name: '' },
      main_engine_rate: {},
      aux_engine_rate: {},
      unique_row_id: this.rowData.length.toString(),
    };
    if (this.rowData.length) {
      newConsumption = cloneDeep(this.rowData[this.rowData.length - 1]);
      newConsumption.fuel_id = 0;
      newConsumption.fuel = { name: '' };
      newConsumption.id = 0;
      newConsumption.unique_row_id = this.rowData.length.toString();
    }
    this._gridApi?.applyTransaction({
      add: [newConsumption],
      addIndex: this.rowData.length,
    });
  }

  onDeleteConsumption(consumption: ConsumptionRate): void {
    if (this._gridApi) {
      this._gridApi.applyTransaction({ remove: [consumption] });
      this.vesselFormGroup.patchValue({ consumption_rates: this.rowData });
    }
  }

  closeEditor(): void {
    if (this._gridApi) {
      this._gridApi.stopEditing();
    }
  }

  clickVesselLabel() {
    if (this.vesselSelectControl.value) {
      this.showTooltip.emit(UserGuideType.Vessel1);
    }
  }

  /* headerFuelClick() {
    this.showTooltip.emit(UserGuideType.Fuel2);
  } */
}
