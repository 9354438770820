import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  addPlusTimeZone,
  emptyFeatureCollection,
  errorZoomCluster,
  getPointForMap,
  offsetFlyToVessel,
  onMouseEnter,
  onMouseLeave,
} from '@estimator/helpers';
import {
  MapFeatureProperties,
  NOT_APPLICABLE,
  NotificationType,
  SearchRawVesselsAndPortsResponse,
  SelectVesselOrPort,
  UserTimeSettings,
  Vessel,
  emptySearchRawVesselsAndPortsResponse,
  mglMapCenter,
  mglMapStyleFlat,
  mglMapStyleGlobe,
} from '@estimator/models';
import { NotificationService } from '@estimator/services';
import { Feature, FeatureCollection, GeoJsonProperties, Geometry, Point, Polygon } from 'geojson';
import {
  EventData,
  LngLatLike,
  Map as MapFromMapboxgl,
  MapLayerMouseEvent,
  MapboxEvent,
  MapboxGeoJSONFeature,
} from 'mapbox-gl';

@Component({
  selector: 'estimator-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapComponent {
  @Input()
  set ecaZones(data: FeatureCollection<Polygon>) {
    this._ecaZones = data;
  }
  get ecaZones(): FeatureCollection<Polygon> {
    return this._ecaZones;
  }
  @Input()
  set canalZones(data: FeatureCollection<Polygon>) {
    this._canalZones = data;
  }
  get canalZones(): FeatureCollection<Polygon> {
    return this._canalZones;
  }
  @Input()
  set isLogged(value: boolean) {
    this._isLogged = value;
    if (value) {
      this.style = mglMapStyleFlat;
    } else {
      this.style = mglMapStyleGlobe;
    }
  }
  get isLogged(): boolean {
    return this._isLogged;
  }
  @Input()
  set vesselsPorts(data: SearchRawVesselsAndPortsResponse) {
    this._vesselsPorts = data;
  }
  get vesselsPorts(): SearchRawVesselsAndPortsResponse {
    return this._vesselsPorts;
  }
  @Input()
  set lastAllFleetVesselPositions(data: FeatureCollection | undefined) {
    this._lastAllFleetVesselPositions = data;
  }
  get lastAllFleetVesselPositions(): FeatureCollection | undefined {
    return this._lastAllFleetVesselPositions;
  }
  @Input()
  set portsPoints(data: FeatureCollection | undefined) {
    if (data?.features?.length) {
      this._portsPoints = data;
    } else {
      this._portsPoints = emptyFeatureCollection();
    }
  }
  get portsPoints(): FeatureCollection | undefined {
    return this._portsPoints;
  }
  @Input()
  set historyFleetVesselsPositionsPoints(data: FeatureCollection | undefined) {
    this._historyFleetVesselsPositionsPoints = data;
    if (data?.features?.length) {
      this.jumpToPoint(data, 9);
    }
  }
  get historyFleetVesselsPositionsPoints(): FeatureCollection | undefined {
    return this._historyFleetVesselsPositionsPoints;
  }
  @Input()
  set historyFleetVesselsPositionsLineString(data: FeatureCollection | undefined) {
    this._historyFleetVesselsPositionsLineString = data;
  }
  get historyFleetVesselsPositionsLineString(): FeatureCollection | undefined {
    return this._historyFleetVesselsPositionsLineString;
  }
  @Input()
  set isLoading(bool: boolean) {
    this._isLoading = bool;
  }
  get isLoading(): boolean {
    return this._isLoading;
  }
  @Input()
  set isFleetVesselLoading(bool: boolean) {
    this._isFleetVesselLoading = bool;
  }
  get isFleetVesselLoading(): boolean {
    return this._isFleetVesselLoading;
  }
  @Input()
  set selectedPort(data: FeatureCollection<Geometry, GeoJsonProperties> | undefined) {
    this._selectedPort = data;
    this.jumpToPoint(data as FeatureCollection<Geometry, GeoJsonProperties>, 7);
    this.cdr.detectChanges();
  }
  get selectedPort(): FeatureCollection<Geometry, GeoJsonProperties> | undefined {
    return this._selectedPort;
  }
  @Output() searchVesselOrPort = new EventEmitter<string>();
  @Output() selectVesselOrPort = new EventEmitter<SelectVesselOrPort>();
  @Output() emitOpenCollectionsDialog = new EventEmitter();
  @Output() emitOpenVesselsDialog = new EventEmitter();
  style = mglMapStyleGlobe;
  center: LngLatLike = mglMapCenter;
  selectedPortPoint?: Feature<Point, GeoJsonProperties>;
  showPortPopup = false;
  showEcaZones = true;
  showCanalZones = false;
  showPorts = false;
  onMouseEnter = onMouseEnter;
  onMouseLeave = onMouseLeave;
  addPlusTimeZone = addPlusTimeZone;
  readonly NOT_APPLICABLE = NOT_APPLICABLE;
  readonly UserTimeSettings = UserTimeSettings;
  readonly MapFeatureProperties = MapFeatureProperties;
  private _isLogged = false;
  private _vesselsPorts: SearchRawVesselsAndPortsResponse = emptySearchRawVesselsAndPortsResponse;
  private _lastAllFleetVesselPositions!: FeatureCollection | undefined;
  private _portsPoints: FeatureCollection = emptyFeatureCollection();
  private _ecaZones: FeatureCollection<Polygon> = emptyFeatureCollection();
  private _canalZones: FeatureCollection<Polygon> = emptyFeatureCollection();
  private _historyFleetVesselsPositionsPoints!: FeatureCollection | undefined;
  private _historyFleetVesselsPositionsLineString!: FeatureCollection | undefined;
  private _isLoading = false;
  private _isFleetVesselLoading = false;
  private _selectedPort?: FeatureCollection<Geometry, GeoJsonProperties> | undefined;
  private map?: MapFromMapboxgl;

  constructor(
    private cdr: ChangeDetectorRef,
    private readonly notificationService: NotificationService
  ) {}

  onSearchVesselOrPort(request: string): void {
    this.searchVesselOrPort.emit(request);
  }

  onSelectVesselOrPort(event: SelectVesselOrPort): void {
    this.selectVesselOrPort.emit(event);
  }

  openCollectionsDialog() {
    this.emitOpenCollectionsDialog.emit();
  }

  openVesselsDialog() {
    this.emitOpenVesselsDialog.emit();
  }

  onLoad(event: MapboxEvent<undefined> & EventData) {
    this.map = event.target;
  }

  styleImageMissing(/* event: { id: string } & EventData */) {
    this.map?.loadImage('assets/near_me.png', (error, image) => {
      if (error) {
        this.notificationService.showNotification(
          `Error when load an image to map`,
          NotificationType.Error
        );
      }
      const imageName = 'fleetMarker';
      if (!this.map?.hasImage(imageName)) {
        this.map?.addImage(imageName, image as HTMLImageElement, { sdf: true });
      }
    });
  }

  clickCluster(
    feature: { properties: { cluster_id: number }; geometry: GeoJsonProperties },
    source: string
  ) {
    const portSource: mapboxgl.GeoJSONSource = this.map?.getSource(
      source
    ) as mapboxgl.GeoJSONSource;
    portSource.getClusterExpansionZoom(feature.properties.cluster_id, (error, zoom) => {
      if (error) {
        this.notificationService.showNotification(errorZoomCluster, NotificationType.Error);
      }
      const geometry = feature.geometry;
      this.map?.flyTo({ center: geometry?.[MapFeatureProperties.Coordinates], zoom: zoom + 1 });
    });
  }

  onSelectedPortLayerClick(/* { features }: MapLayerMouseEvent */) {
    this.commonPortClick(this.selectedPort?.features as Feature<Geometry, GeoJsonProperties>[]);
  }

  onPortLayerClick({ features }: MapLayerMouseEvent) {
    const feature = features as MapboxGeoJSONFeature[];
    if (!feature[0]?.properties?.[MapFeatureProperties.Cluster]) {
      this.commonPortClick(feature);
    }
  }

  onFleetLayerClick({ features }: MapLayerMouseEvent) {
    const feature = features as MapboxGeoJSONFeature[];
    if (!feature[0]?.properties?.[MapFeatureProperties.Cluster]) {
      const vessel = features?.length ? (features[0]?.properties as Partial<Vessel>) : {};
      const selectVesselOrPortEvent: SelectVesselOrPort = {
        vesselOrPort: vessel,
        vessel: true,
        fromSearch: false,
      };
      this.selectVesselOrPort.emit(selectVesselOrPortEvent);
    }
  }

  jumpToPoint(data: FeatureCollection<Geometry, GeoJsonProperties>, zoom: number) {
    const geometry = data?.features[0]?.geometry as GeoJsonProperties;
    this.map?.jumpTo({ center: geometry?.[MapFeatureProperties.Coordinates], zoom: zoom });
  }

  jumpToPointVessel(generalVesselId: number) {
    const fleetPosition = this.lastAllFleetVesselPositions?.features?.find(
      (feature) => feature?.properties?.['id'] === generalVesselId
    );
    if (fleetPosition) {
      const geometry = fleetPosition?.geometry as GeoJsonProperties;
      this.map?.flyTo({
        center: geometry?.[MapFeatureProperties.Coordinates],
        speed: 1,
        offset: offsetFlyToVessel,
        zoom: 7,
      });
    } else {
      this.notificationService.showNotification(
        `No point data on this request`,
        NotificationType.Warning
      );
    }
  }

  commonPortClick(feature: Feature<Geometry, GeoJsonProperties>[] | MapboxGeoJSONFeature[]) {
    const point = getPointForMap(feature);
    this.selectedPortPoint = point;
    this.showPortPopup = true;
  }
}
