<div class="p-inputgroup" [formGroup]="dateSwitcherForm">
  <p-inputSwitch
    [formControl]="dateSwitcherForm.controls.switcher || mockFormControl"
    (onChange)="onChangeSwitcher($event)"
  ></p-inputSwitch>
  <label>Range</label>
  <div class="p-inputgroup">
    <ng-container *ngIf="dateSwitcherForm.controls.switcher?.value">
      <p-calendar
        [dateFormat]="dateFormat"
        [formControl]="dateSwitcherForm.controls.dates_from || mockFormControl"
        inputId="dates_from"
      />
      —
      <p-calendar
        [dateFormat]="dateFormat"
        [formControl]="dateSwitcherForm.controls.dates_to || mockFormControl"
        inputId="dates_to"
      />
    </ng-container>

    <ng-container *ngIf="!dateSwitcherForm.controls.switcher?.value">
      <div class="p-inputgroup max-w-10rem">
        Last
        <p-inputNumber
          [formControl]="dateSwitcherForm.controls.updates_from_days || mockFormControl"
          [useGrouping]="false"
          inputId="updates_from"
          mode="decimal"
        />
        of days
      </div>
    </ng-container>
  </div>
</div>
