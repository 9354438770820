import { DecimalPipe } from '@angular/common';
import {
  AfterContentChecked,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import {
  convertCurrencyToUiValue,
  getUserOrSystemDuration,
  isDischargeEvent,
  isLoadEvent,
} from '@estimator/helpers';
import {
  CargoForm,
  CargoRules,
  DEM_DES_COLUMNS,
  DealEvent,
  DemurrageDespatchColumnType,
  DemurrageDespatchForm,
  EventFormGroup,
  LaytimeType,
  MAX_FINANCE_VALUE,
  NOT_APPLICABLE,
  PrimeNgIcons,
  UserInputDuration,
  UserInputDurationType,
  WorkType,
} from '@estimator/models';
import { LaytimeLabel } from '@vms-models';
import moment from 'moment';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { Subject, distinctUntilChanged, takeUntil } from 'rxjs';

@Component({
  selector: 'estimator-demurrage-despatch-form',
  templateUrl: './demurrage-despatch-form.component.html',
  styleUrl: './demurrage-despatch-form.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DemurrageDespatchFormComponent implements OnDestroy, AfterContentChecked {
  @Input()
  set demurrageDespatchFormArray(value: DemurrageDespatchForm[]) {
    this._demurrageDespatchFormArray = value;
    if (this._demurrageDespatchFormArray.length) {
      this._demurrageDespatchFormArray.forEach((form) => {
        const loadCargoEvents = form?.events?.filter((event) =>
          isLoadEvent(event?.getRawValue() as DealEvent)
        );
        const dischargeCargoEvents = form?.events?.filter((event) =>
          isDischargeEvent(event?.getRawValue() as DealEvent)
        );
        form.events.forEach((eventForm) => {
          eventForm.controls.meta.controls.laytime_data.controls.amount.addValidators(
            this.createCargoValidator(loadCargoEvents, dischargeCargoEvents)
          );
          this.validateEventsForm(form.events);
          eventForm.controls.meta.controls.laytime_data.controls.amount.valueChanges
            .pipe(takeUntil(this._onDestroy$), distinctUntilChanged())
            .subscribe((value) => {
              if (loadCargoEvents?.length === 1 && dischargeCargoEvents?.length === 1) {
                const nextEvent = form.events.find((cargoEventForm) => {
                  const eventFormValue = eventForm.getRawValue() as DealEvent;
                  const cargoEventFormValue = cargoEventForm.getRawValue() as DealEvent;
                  return (
                    eventFormValue.id !== cargoEventFormValue.id &&
                    eventFormValue.cargo_id === cargoEventFormValue.cargo_id &&
                    ((isLoadEvent(eventFormValue) && isDischargeEvent(cargoEventFormValue)) ||
                      (isDischargeEvent(eventFormValue) && isLoadEvent(cargoEventFormValue)))
                  );
                });
                if (nextEvent) {
                  nextEvent.controls.meta.controls.laytime_data.controls.amount.patchValue(value, {
                    emitEvent: false,
                  });
                }
              }
              this.validateEventsForm(form.events);
            });
        });
      });
    }
  }
  get demurrageDespatchFormArray(): DemurrageDespatchForm[] {
    return this._demurrageDespatchFormArray;
  }
  @Input()
  set isFixed(value: boolean) {
    this._isFixed = value;
  }
  get isFixed(): boolean {
    return this._isFixed;
  }
  @Input() cargoRules: CargoRules[] = [];

  @Output() deleteDemDes = new EventEmitter<FormGroup<CargoForm>>();

  private _demurrageDespatchFormArray: DemurrageDespatchForm[] = [];
  private _onDestroy$ = new Subject<void>();
  readonly columns = DEM_DES_COLUMNS;
  readonly DemurrageDespatchColumnType = DemurrageDespatchColumnType;
  readonly stateKey = 'demurrage_despatch_form_table';
  readonly NOT_APPLICABLE = NOT_APPLICABLE;
  readonly PrimeNgIcons = PrimeNgIcons;
  readonly workTypeOptions = Object.values(WorkType);
  readonly laytimeTypeOptions = [LaytimeType.Reversible, LaytimeType.NonReversible];
  readonly emptyDuration: UserInputDuration = {};
  readonly MAX_FINANCE_VALUE = MAX_FINANCE_VALUE;
  readonly LaytimeLabel = LaytimeLabel;
  private _isFixed = false;

  constructor(private readonly decimalPipe: DecimalPipe) {}

  ngAfterContentChecked(): void {
    if (this.isFixed) {
      this.demurrageDespatchFormArray.forEach((formArr) => {
        formArr.cargo.disable();
        formArr.events.forEach((eventFg) => {
          eventFg.disable();
        });
      });
    }
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  getEventFormValue(eventForm: FormGroup<EventFormGroup>): DealEvent {
    return eventForm.value as DealEvent;
  }

  getEventForm(eventForm: FormGroup<EventFormGroup>): FormGroup<EventFormGroup> {
    return eventForm as FormGroup<EventFormGroup>;
  }

  getCargoIndex(index: number): string {
    const cargo = this.demurrageDespatchFormArray[index]?.cargo;
    return `#${cargo?.value?.internal_order || NOT_APPLICABLE}`;
  }

  getEventTypeLabel(form: FormGroup<EventFormGroup>): string {
    const event = form.getRawValue() as DealEvent;
    if (isLoadEvent(event)) {
      return 'LD';
    } else if (isDischargeEvent(event)) {
      return 'DS';
    }
    return NOT_APPLICABLE;
  }

  getResult(
    form: DemurrageDespatchForm,
    eventForm: FormGroup<EventFormGroup>,
    eventIndex: number
  ): string {
    const cargo = form?.cargo?.getRawValue();
    if (cargo.laytime_type === LaytimeType.NonReversible) {
      const event = eventForm.getRawValue() as DealEvent;
      const diff = getUserOrSystemDuration(event.meta?.laytime_difference_minutes);
      const diffDays = moment.duration({ minutes: diff }).asDays();
      const demurrage = convertCurrencyToUiValue(event?.meta?.demurrage_rate);
      const despatch = convertCurrencyToUiValue(event?.meta?.despatch_rate);
      let demResult = 0;
      let desResult = 0;
      if (diff > 0) {
        demResult = diffDays * demurrage;
        return `DEM: ${this.decimalPipe.transform(demResult, '.0-2')}`;
      } else if (diff < 0) {
        desResult = diffDays * despatch;
        return `DES: ${this.decimalPipe.transform(desResult, '.0-2')}`;
      }
    } else if (
      cargo.laytime_type === LaytimeType.Reversible &&
      eventIndex === form.events.length - 1
    ) {
      let diff = 0;
      form.events.forEach((eventForm) => {
        diff += getUserOrSystemDuration(eventForm.getRawValue().meta?.laytime_difference_minutes);
      });
      const diffDays = moment.duration({ minutes: diff }).asDays();
      const firstEvent = form.events[0].getRawValue() as DealEvent;
      if (firstEvent) {
        const demurrage = convertCurrencyToUiValue(firstEvent?.meta?.demurrage_rate);
        const despatch = convertCurrencyToUiValue(firstEvent?.meta?.despatch_rate);
        let demResult = 0;
        let desResult = 0;
        if (diff > 0) {
          demResult = diffDays * demurrage;
          return `DEM: ${this.decimalPipe.transform(demResult, '.0-2')}`;
        } else if (diff < 0) {
          desResult = diffDays * despatch;
          return `DES: ${this.decimalPipe.transform(desResult, '.0-2')}`;
        }
      }
    }

    return '';
  }

  getResultTextColor(
    form: DemurrageDespatchForm,
    eventForm: FormGroup<EventFormGroup>,
    eventIndex: number
  ): string {
    const result = this.getResult(form, eventForm, eventIndex).toLowerCase();
    if (result.includes('des') || result.includes('DES')) {
      return 'text-red-500';
    }
    if (result.includes('dem') || result.includes('DEM')) {
      return 'text-green-500';
    }
    return '';
  }

  getTermsCoefficient(form: FormGroup<EventFormGroup>): number {
    const termsId = form.value?.meta?.laytime_data?.terms_id;
    if (termsId) {
      const terms = this.cargoRules.find((term) => term.id === termsId);
      if (terms) {
        return terms.coefficient || 0;
      }
    }
    return 0;
  }

  calculateAllowedOrActual(
    form: FormGroup<EventFormGroup>,
    isAllowed?: boolean
  ): UserInputDuration {
    const event = form.getRawValue() as DealEvent;
    const working_minutes = getUserOrSystemDuration(
      isAllowed ? event.meta?.working_minutes : event.meta?.laytime_data?.working_minutes
    );
    const idle_minutes = getUserOrSystemDuration(
      isAllowed ? event.meta?.idle_minutes : event.meta?.laytime_data?.idle_minutes
    );
    const extra_minutes_after = getUserOrSystemDuration(event.meta?.extra_minutes_after);
    const extra_minutes_before = getUserOrSystemDuration(event.meta?.extra_minutes_before);
    const result: UserInputDuration = {
      system: working_minutes + idle_minutes + extra_minutes_after + extra_minutes_before,
      user: 0,
      is_changed: false,
      units: event.meta?.laytime_difference_minutes?.units || UserInputDurationType.Hours,
    };
    return result;
  }

  onDeleteDemDes(demDesForm: DemurrageDespatchForm): void {
    this.deleteDemDes.emit(demDesForm.cargo);
  }

  onPushDemDesCalculationToEvent(demDesForm: DemurrageDespatchForm): void {
    demDesForm.events.forEach((eventForm) => {
      const laytime = eventForm.getRawValue().meta?.laytime_data;
      if (laytime) {
        eventForm.controls.meta.patchValue(laytime);
      }
    });
  }

  onChangeDemmurage(demurrageValue: number, demDesForm: DemurrageDespatchForm): void {
    if (demDesForm.events.length) {
      demDesForm.events.forEach((eventForm) => {
        const dealEvent = eventForm.getRawValue() as DealEvent;
        if (!dealEvent?.meta?.demurrage_rate) {
          eventForm.controls.meta.controls.demurrage_rate.patchValue(demurrageValue);
        }
        if (!dealEvent?.meta?.despatch_rate) {
          eventForm.controls.meta.controls.despatch_rate.patchValue(demurrageValue / 2);
        }
      });
    }
  }

  onChangeLaytimeType(event: DropdownChangeEvent, demDesForm: DemurrageDespatchForm): void {
    if (demDesForm.events.length) {
      demDesForm.events.forEach((eventForm, index) => {
        if (index) {
          if (event.value === LaytimeType.Reversible) {
            eventForm.controls.meta.controls.demurrage_rate.disable({ emitEvent: false });
            eventForm.controls.meta.controls.despatch_rate.disable({ emitEvent: false });
          } else {
            eventForm.controls.meta.controls.demurrage_rate.enable({ emitEvent: false });
            eventForm.controls.meta.controls.despatch_rate.enable({ emitEvent: false });
          }
        }
      });
    }
  }

  disableDemDesRates(demDesForm: DemurrageDespatchForm, index: number): boolean {
    return !!(index && demDesForm.cargo?.getRawValue()?.laytime_type === LaytimeType.Reversible);
  }

  createCargoValidator(
    loadCargoEvents: FormGroup<EventFormGroup>[],
    dischargeCargoEvents: FormGroup<EventFormGroup>[]
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const loadSumm = loadCargoEvents.reduce((acc, loadCargo) => {
        acc += loadCargo.controls.meta.controls?.laytime_data?.controls?.amount?.value || 0;
        return acc;
      }, 0);
      const discSumm = dischargeCargoEvents.reduce((acc, loadCargo) => {
        acc += loadCargo.controls.meta.controls?.laytime_data?.controls?.amount?.value || 0;
        return acc;
      }, 0);
      return +loadSumm.toFixed(2) !== +discSumm.toFixed(2) ? { cargoError: true } : null;
    };
  }

  validateEventsForm(events: FormGroup<EventFormGroup>[]): void {
    events.forEach((eventFg) => {
      eventFg.controls.meta.controls.laytime_data.controls.amount.updateValueAndValidity();
      eventFg.controls.meta.controls.laytime_data.controls.amount.markAsDirty();
      eventFg.updateValueAndValidity();
    });
  }
}
