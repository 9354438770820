import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ShiplistFilterCheckName,
  ShiplistTableParams,
  ShiplistTableResponse,
  setTableParams,
} from '@estimator/models';
import { ShiplistVesselMailGetData, ShiplistVesselMailMessages, XlistFilter } from '@xlist-models';
import { Observable } from 'rxjs';
import { BaseService } from '../base';

@Injectable({
  providedIn: 'root',
})
export class ShipListTableService extends BaseService<XlistFilter> {
  firstSourcePath = `/api/shiplist`;

  constructor(protected override readonly http: HttpClient) {
    super(http);
    this.setPath('/api/shiplist/filter');
  }

  getFirstSource(
    filter: XlistFilter,
    tableParams?: ShiplistTableParams
  ): Observable<ShiplistTableResponse> {
    let params = new HttpParams();
    if (tableParams) {
      const offset = tableParams?.offset;
      const sort_order = tableParams?.sort_order;
      const sort_field = tableParams?.sort_field;
      const limit = tableParams?.limit;
      params = setTableParams(params, limit, offset, sort_order, sort_field);
    }
    return this.http.post<ShiplistTableResponse>(this.firstSourcePath, filter, {
      params,
    });
  }

  checkShiplistFilterName(name: string): Observable<ShiplistFilterCheckName> {
    return this.http.get<ShiplistFilterCheckName>(`${this.path}/check-name?name=${name}`);
  }

  createShiplistFilterCopy(id: number): Observable<XlistFilter> {
    return this.http.post<XlistFilter>(`${this.path}/${id}/copy`, null);
  }

  getShiplistMessages(
    shiplist_item_id: number
  ): Observable<ShiplistVesselMailMessages> {
    const data: ShiplistVesselMailGetData = {
      shiplist_item_id
    };
    return this.http.post<ShiplistVesselMailMessages>(`${this.firstSourcePath}/messages`, data);
  }
}
