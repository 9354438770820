import { Component } from '@angular/core';
import {
  FilterStatusDeal,
  activeStatus,
  archivedStatus,
  falseText,
  statusesDeals,
  trueText,
} from '@estimator/models';
import { MultiSelectChangeEvent } from 'primeng/multiselect';

@Component({
  selector: 'estimator-aggrid-header-multiselect',
  template: `
    <p-multiSelect
      [options]="filterStatusesDeal"
      [(ngModel)]="selectedFilterStatusesDeal"
      [showHeader]="false"
      appendTo="body"
      [defaultLabel]="status"
      optionLabel="name"
      display="chip"
      (onChange)="onChange($event)"
    >
      <ng-template let-value pTemplate="selectedItems">
        <p class="margin-0">{{ status }}</p>
      </ng-template></p-multiSelect
    >
  `,
  styles: [
    `
      ::ng-deep .p-multiselect {
        box-shadow: none !important;
        border: none;
        background-color: rgba(0, 0, 0, 0);
      }
    `,
  ],
})
export class AgGridHeaderMultiselectComponent {
  params: any;
  filterStatusesDeal?: FilterStatusDeal[] = [];
  selectedFilterStatusesDeal?: FilterStatusDeal[] = [];
  status = 'Status';

  agInit(params: any) {
    this.params = params;
    this.filterStatusesDeal = statusesDeals;
    this.selectedFilterStatusesDeal =
      params.selectedFilterStatusesDeal === trueText
        ? [archivedStatus]
        : params.selectedFilterStatusesDeal === falseText
        ? [activeStatus]
        : this.filterStatusesDeal;
  }

  onChange(event: MultiSelectChangeEvent) {
    this.params.changeValue(event.value);
    if (event?.value?.length === 0) {
      this.selectedFilterStatusesDeal = statusesDeals;
    }
  }

  onParentModelChanged(/* parentModel: any */) {
    // do nothing
  }
}
