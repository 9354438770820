import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from '@estimator/directives';
import { ButtonModule } from 'primeng/button';
import { DragDropModule } from 'primeng/dragdrop';
import { InputTextModule } from 'primeng/inputtext';
import { MenuModule } from 'primeng/menu';
import { ToolbarComponent } from './toolbar.component';

export const TOOLBAR_MODULE_IMPORTS = [
  CommonModule,
  ReactiveFormsModule,
  ButtonModule,
  InputTextModule,
  MenuModule,
  DirectivesModule,
  DragDropModule,
];

@NgModule({
  declarations: [ToolbarComponent],
  imports: TOOLBAR_MODULE_IMPORTS,
  exports: [ToolbarComponent],
})
export class ToolbarModule {}
