import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from '@estimator/directives';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TooltipModule } from 'primeng/tooltip';
import { ButtonSwitchModule } from '../button-switch/button-switch.module';
import { PriceAndCurrencyInputModule } from '../price-and-currency-input/price-and-currency-input.module';
import { VesselAndConsumptionsFormComponent } from './vessel-and-consumptions-form.component';

@NgModule({
  declarations: [VesselAndConsumptionsFormComponent],
  imports: [
    CommonModule,
    AutoCompleteModule,
    CheckboxModule,
    DropdownModule,
    InputNumberModule,
    ReactiveFormsModule,
    ButtonModule,
    TooltipModule,
    DirectivesModule,
    PriceAndCurrencyInputModule,
    FormsModule,
    OverlayPanelModule,
    ButtonSwitchModule,
  ],
  exports: [VesselAndConsumptionsFormComponent],
})
export class VesselAndConsumptionsFormModule {}
