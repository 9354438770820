import { BaseEntity, BaseModel } from './base-entity';

export interface FleetVessel extends BaseModel {
  active?: boolean;
  call_sign?: string;
  class?: string;
  collection_id?: number;
  company_id?: number;
  deactivation_date?: string;
  flag?: string;
  for_company?: boolean;
  general_classification?: string;
  general_vessel_id?: number;
  gross_tonnage?: string;
  imo?: number;
  individual_classification?: string;
  length?: number;
  lifeboats?: number;
  mmsi?: number;
  person_capacity?: number;
  ship_type?: string;
  width?: number;
  // from spire
  built_year?: number;
  deadweight?: number;
  draught?: number;
}

export interface AddVesselToMyOrCompanyFleetProps {
  for_company: boolean;
  parsed_vessel_id?: number;
  raw_vessel_id?: number;
}

export interface FleetVesselPositions {
  fleet_vessel_id: number;
  time_from?: number; // date, in seconds
  time_to?: number; // date, in seconds
}

export interface GetMyFleetAndFleetCompany {
  fleet_vessel_collections: FleetVesselCollection[];
  fleet_vessels: FleetVessel[];
  fleet_vessels_updates: FleetVessel[];
  last_fleet_vessels_positions: FleetVesselPosition[];
}

export interface PropsDeleteFleetVesselAndFleetVesselCollection {
  id: number;
  general_vessel_id?: number;
  isDeleteCompanyItem: boolean;
}

export interface FleetVesselCollection extends BaseModel {
  color?: string;
  company_id?: number; // по идее только для коллекций моих компаний
  for_company?: boolean;
}

export interface FleetVesselActiveRequest {
  active: boolean;
  deactivation_date?: number;
}

export interface FleetVesselActiveRequestPlusVesselID {
  value: FleetVesselActiveRequest;
  vesselId: number;
}

export interface FleetVesselActiveResponse {
  fleet_vessel: FleetVessel;
  fleet_vessel_updates: FleetVessel;
}

export interface FleetVesselPosition extends BaseEntity {
  general_vessel_id?: number;
  latitude?: number;
  longitude?: number;
  course?: number;
  speed?: number;
  matched_port_name?: string;
  matched_port_unlocode?: string;
  navigational_status?: string;
  position_update_timestamp?: string | Date | null;
}
