import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[estimatorHighlightDecimal]',
})
export class HighlightDecimalDirective implements OnChanges {
  @Input() estimatorHighlightDecimal: string | number | null = '0';

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['estimatorHighlightDecimal']) {
      const value = this.estimatorHighlightDecimal;
      this.updateDisplay(value);
    }
  }

  private updateDisplay(value: string | number | null): void {
    if (value === null || value === undefined) {
      this.renderer.setProperty(this.el.nativeElement, 'innerText', '');
      return;
    }

    // Split the value into integer and fractional parts
    const [wholePart, decimalPart] = value.toString().split('.');

    // Create an element for the integer part
    const wholeSpan = this.renderer.createElement('span');
    this.renderer.addClass(wholeSpan, 'whole-number');
    const wholeText = this.renderer.createText(wholePart);
    this.renderer.appendChild(wholeSpan, wholeText);

    // Create an element for point and fractional part
    const dotText = this.renderer.createText('.');
    const decimalSpan = this.renderer.createElement('span');
    this.renderer.addClass(decimalSpan, 'decimal-part');
    const decimalText = this.renderer.createText(decimalPart || '00');
    this.renderer.appendChild(decimalSpan, decimalText);

    // Clear content and add new text
    this.renderer.setProperty(this.el.nativeElement, 'innerHTML', '');
    this.renderer.appendChild(this.el.nativeElement, wholeSpan);
    this.renderer.appendChild(this.el.nativeElement, dotText);
    this.renderer.appendChild(this.el.nativeElement, decimalSpan);
  }
}
