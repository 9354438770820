<!-- PRIME
<p-table
  [columns]="cols"
  [stateKey]="stateKey"
  [tableStyle]="{ 'min-width': '50rem' }"
  [value]="firstSourceData.shiplist_items || []"
  [scrollable]="true"
  scrollHeight="80vh"
  [rows]="100"
  [virtualScrollItemSize]="46"
  [virtualScroll]="true"
  [lazy]="true"
  class="primeng-form"
  dataKey="id"
  stateStorage="local"
  styleClass="p-datatable-sm font-size-12 p-datatable-striped"
  (onLazyLoad)="loadLazy($event)"
>
  <ng-template let-column pTemplate="header">
    <tr>
      <th
        pResizableColumn
        [pSortableColumn]="col.field"
        *ngFor="let col of cols"
        [ngStyle]="{
        width: col?.width,
      }"
        [pTooltip]="col.tooltip"
        tooltipPosition="top"
      >
        <ng-container>{{ col.header }} <p-sortIcon [field]="col.field" /> </ng-container>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-i="rowIndex">
    <tr>
      <td *ngFor="let col of cols">
        <ng-container
          *ngIf="
            col.field !== ShipListTableColumnType.ETA &&
            col.field !== ShipListTableColumnType.ETD &&
            col.field !== ShipListTableColumnType.MESSAGE_DATE
          "
        >
          <span [ngClass]="col.field">{{ rowData[col.field] }}</span></ng-container
        >
        <ng-container
          *ngIf="
            col.field === ShipListTableColumnType.ETA ||
            col.field === ShipListTableColumnType.ETD ||
            col.field === ShipListTableColumnType.MESSAGE_DATE
          "
        >
          {{ localDateTimeFormat(rowData[col.field]) }}
        </ng-container>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="loadingbody" let-columns="columns">
    <tr style="height: 46px">
      <td *ngFor="let col of columns">
        <p-skeleton />
      </td>
    </tr>
  </ng-template>
</p-table>
-->

<!-- AG GRID -->
<div>
  <ag-grid-angular
    [gridOptions]="gridOptions"
    (gridReady)="onGridReady($event)"
    class="ag-theme-material shiplist"
  ></ag-grid-angular>
</div>
