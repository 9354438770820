import { DecimalPipe } from '@angular/common';
import { AfterViewInit, Directive, DoCheck, ElementRef, HostListener, Input } from '@angular/core';
import { floor } from 'lodash';

@Directive({
  selector: '[estimatorDecimals]',
})
export class DecimalDirective implements AfterViewInit, DoCheck {
  @Input() set maxFractionDigits(digits: number) {
    if (digits) {
      this._fractionDigits = digits;
      const newRegexStr = `^\\d*\\.?\\d{0,${digits}}$`;
      this._regex = new RegExp(newRegexStr);
    }
  }
  private _regex = new RegExp(/^\d*\.?\d{0,2}$/g);
  private _withDotRegex = new RegExp(/^\d*\./g);
  private specialKeys: Array<string> = [
    'Backspace',
    'Tab',
    'End',
    'Home',
    '-',
    'ArrowLeft',
    'ArrowRight',
    'Del',
    'Delete',
    'Control',
  ];
  private _fractionDigits = 2;
  isDot = false;
  isTypingZeroAfterDot = false;

  constructor(private el: ElementRef, private readonly decimalPipe: DecimalPipe) {}

  ngAfterViewInit(): void {
    this.ngDoCheck();
  }

  ngDoCheck(): void {
    const input = this.el.nativeElement?.firstChild?.firstChild || this.el.nativeElement;
    const suffix = this.el.nativeElement?.attributes?.suffix?.value || '';
    const inputHasFocus = input === document?.activeElement;
    if (input) {
      const value = suffix
        ? input?.value.replace(suffix, '').replace(/\s/g, '')
        : input?.value.replace(/\s/g, '');
      /* if (value && !String(value).match(this._regex)) {
        const newVal = parseFloat(value.replace(',', '.'));
        input.value = floor(newVal, this._fractionDigits);
      } */
      if (value === '-') return;
      if (value) {
        const newVal = parseFloat(value.replace(',', '.'));
        const needDot = this.isDot ? '.' : '';
        const format = this.isTypingZeroAfterDot ? '1.1-2' : '1.0-2';
        if (!inputHasFocus) {
          // with transform like 111 000
          input.value =
            this.decimalPipe
              .transform(floor(newVal, this._fractionDigits), format, 'fr')
              ?.replace(',', '.') + needDot;
        } else {
          // without transform
          const needZeroAfterDot = this.isTypingZeroAfterDot ? '.0' : '';
          input.value = floor(newVal, this._fractionDigits) + needDot + needZeroAfterDot;
        }
      }
    }
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (
      this.specialKeys.indexOf(event.key) !== -1 ||
      event.ctrlKey ||
      event.metaKey ||
      (isNaN(Number(event.key)) && event.key !== ',' && event.key !== '.')
    ) {
      return;
    }
    const input = this.el.nativeElement?.firstChild?.firstChild || this.el.nativeElement;
    const suffix = this.el.nativeElement?.attributes?.suffix?.value || '';
    const isPrimeInput = input.classList.contains('p-element');
    if (isPrimeInput) {
      if (input.selectionStart < input.selectionEnd) {
        event.preventDefault();
        let value = input.value;
        let eventKey = event.key;
        const start = value.slice(0, input.selectionStart);
        const end = value.slice(input.selectionEnd, value.length);
        eventKey === ',' || eventKey === '.' ? (eventKey = '.') : (eventKey = event.key);
        this.isDot = eventKey === ',' || eventKey === '.' ? true : false;
        value = start + eventKey + end;
        this.isTypingZeroAfterDot = value.endsWith('.0');
        input.value = value;
        const position = eventKey === ',' ? start.length : start.length + 1;
        input.setSelectionRange(position, position);
        return;
      }
    }
    if (input) {
      const current = suffix ? input?.value.replace(suffix, '') : input?.value;
      const position = input.selectionStart;
      const next: string = [
        current.slice(0, position >= 0 ? position : String(current).length),
        event.key == ',' || event.key == '.' ? '.' : event.key,
        current.slice(position >= 0 ? position : String(current).length),
      ].join('');
      this.isDot = event.key == ',' || event.key == '.' ? true : false;
      this.isTypingZeroAfterDot = next.endsWith('.0');
      if (next && !String(next).match(this._regex)) {
        event.preventDefault();
        input.value = next;
        input.setSelectionRange(position + 1, position + 1);
        // return;
      } else {
        if (isPrimeInput) {
          event.preventDefault();
          input.value = next;
          input.setSelectionRange(position + 1, position + 1);
        }
      }
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    const clipboardData = event?.clipboardData?.getData('text').replace(',', '.');
    if (isNaN(Number(clipboardData))) {
      event.preventDefault();
      return;
    }
    const input = this.el.nativeElement?.firstChild?.firstChild || this.el.nativeElement;
    input.value = floor(Number(clipboardData), this._fractionDigits);
  }
}
