<div class="break-even-dialog-wrapper">
  <!--   <div class="display-flex justify-content-end">
    <button mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div> -->
  <div class="display-flex margin-top-10">
    <estimator-break-even-axes
      class="margin-right-30"
      [data]="data"
      [initialArrayFields]="initialArrayFields"
      #breakEvenAxes
    ></estimator-break-even-axes>
    <estimator-break-even-grid
      [data]="data"
      [initialArrayFields]="initialArrayFields"
    ></estimator-break-even-grid>
  </div>
  <form [formGroup]="fg" class="material-form">
    <div class="flex-container-row items-center gap-10 margin-top-10 justify-content-end">
      <label htmlFor="break-even_form-rise" class="font-size-16">Rise</label>
      <p-inputNumber
        [useGrouping]="false"
        [maxFractionDigits]="2"
        [min]="0"
        [step]="0"
        formControlName="step"
        inputId="break-even_form-rise"
        estimatorDecimals
      ></p-inputNumber>
    </div>
  </form>
</div>
