<div class="calculator-wrapper">
  <!--Cargo calculator form #cc-f-->
  <form [formGroup]="cargoCalculatorForm" class="calculator-form primeng-form" id="cc-f">
    <div class="calculator-form-row mb-3">
      <div class="calculator-form-row-section gap-4">
        <div
          class="w-full flex-container-row content-space-between align-items-center vessel-section-title -mb-3"
        >
          <p>Vessel</p>
          <button
            mat-flat-button
            type="button"
            class="constants-button"
            [color]="MaterialThemeColor.Accent"
            (click)="onGetLastConstants()"
            id="cargo_calculator_form-get_last_constants"
            #getLastConstantsButton
          >
            Get last
          </button>
        </div>
        <div class="double-input">
          <!-- <mat-form-field appearance="outline">
            <mat-label>DWT</mat-label>
            <input
              matInput
              type="number"
              name="dwt"
              placeholder="DWT"
              formControlName="dwt"
              [step]="0"
              estimatorDecimals
              id="cc-f-i-8.1"
            />
          </mat-form-field> -->
          <span
            class="p-float-label"
            [ngClass]="{
              'show-invalid': true
            }"
          >
            <p-inputNumber
              [useGrouping]="false"
              [minFractionDigits]="0"
              [maxFractionDigits]="2"
              [min]="0"
              [max]="MAX_FINANCE_VALUE"
              [step]="0"
              formControlName="dwt"
              inputStyleClass="w-full"
              class="height-35"
              inputId="cc-f-i-8.1"
              estimatorDecimals
            ></p-inputNumber>
            <label for="cc-f-i-8.1">DWT</label></span
          >
          <!-- <mat-form-field appearance="outline">
            <mat-label>Summer draft</mat-label>
            <input
              matInput
              type="number"
              name="summer_draft"
              placeholder="Summer draft"
              formControlName="vessel_max_draft"
              [step]="0"
              estimatorDecimals
              id="cc-f-i-8.2"
            />
          </mat-form-field> -->
          <span
            class="p-float-label"
            [ngClass]="{
              'show-invalid': true
            }"
          >
            <p-inputNumber
              [useGrouping]="false"
              [minFractionDigits]="0"
              [maxFractionDigits]="2"
              [min]="0"
              [max]="MAX_FINANCE_VALUE"
              [step]="0"
              formControlName="vessel_max_draft"
              inputStyleClass="w-full"
              class="height-35"
              inputId="cc-f-i-8.2"
              estimatorDecimals
            ></p-inputNumber>
            <label for="cc-f-i-8.2">Summer draft</label></span
          >
        </div>
        <div class="double-input">
          <!-- <mat-form-field appearance="outline">
            <mat-label>TPC</mat-label>
            <input
              matInput
              type="number"
              name="tpc"
              placeholder="TPC"
              formControlName="tpc"
              [step]="0"
              estimatorDecimals
              id="cc-f-i-5"
            />
          </mat-form-field> -->
          <span
            class="p-float-label"
            [ngClass]="{
              'show-invalid': true
            }"
          >
            <p-inputNumber
              [useGrouping]="false"
              [minFractionDigits]="0"
              [maxFractionDigits]="2"
              [min]="0"
              [max]="MAX_FINANCE_VALUE"
              [step]="0"
              formControlName="tpc"
              inputStyleClass="w-full"
              class="height-35"
              inputId="cc-f-i-5"
              estimatorDecimals
            ></p-inputNumber>
            <label for="cc-f-i-5">TPC</label></span
          >
          <!-- <mat-form-field appearance="outline">
            <mat-label>Bunker</mat-label>
            <input
              matInput
              type="number"
              name="bunker_weight"
              placeholder="Bunker"
              formControlName="bunker_weight"
              [step]="0"
              estimatorDecimals
              id="cc-f-i-9"
            />
          </mat-form-field> -->
          <span class="p-float-label">
            <p-inputNumber
              [useGrouping]="false"
              [minFractionDigits]="0"
              [maxFractionDigits]="2"
              [min]="0"
              [max]="MAX_FINANCE_VALUE"
              [step]="0"
              formControlName="bunker_weight"
              inputStyleClass="w-full"
              class="height-35"
              inputId="cc-f-i-9"
              estimatorDecimals
            ></p-inputNumber>
            <label for="cc-f-i-9">Bunker</label></span
          >
        </div>
        <div class="double-input">
          <!--  <mat-form-field appearance="outline">
            <mat-label>Permament ballast</mat-label>
            <input
              matInput
              type="number"
              name="permanent_ballast"
              placeholder="Permament ballast"
              formControlName="permanent_ballast"
              [step]="0"
              estimatorDecimals
              id="cc-f-i-10"
            />
          </mat-form-field> -->
          <span class="p-float-label">
            <p-inputNumber
              [useGrouping]="false"
              [minFractionDigits]="0"
              [maxFractionDigits]="2"
              [min]="0"
              [max]="MAX_FINANCE_VALUE"
              [step]="0"
              formControlName="permanent_ballast"
              inputStyleClass="w-full"
              class="height-35"
              inputId="cc-f-i-10"
              estimatorDecimals
            ></p-inputNumber>
            <label for="cc-f-i-10">Permament ballast</label></span
          >
          <!--  <mat-form-field appearance="outline">
            <mat-label>Fresh water</mat-label>
            <input
              matInput
              type="number"
              name="fresh_water"
              placeholder="Fresh water"
              formControlName="fresh_water"
              [step]="0"
              estimatorDecimals
              id="cc-f-i-11"
            />
          </mat-form-field> -->
          <span class="p-float-label">
            <p-inputNumber
              [useGrouping]="false"
              [minFractionDigits]="0"
              [maxFractionDigits]="2"
              [min]="0"
              [max]="MAX_FINANCE_VALUE"
              [step]="0"
              formControlName="fresh_water"
              inputStyleClass="w-full"
              class="height-35"
              inputId="cc-f-i-11"
              estimatorDecimals
            ></p-inputNumber>
            <label for="cc-f-i-11">Fresh water</label></span
          >
        </div>
        <div class="double-input">
          <!--  <mat-form-field appearance="outline">
            <mat-label>Constants</mat-label>
            <input
              matInput
              type="number"
              name="constant_weight"
              placeholder="Constants"
              formControlName="constant_weight"
              [step]="0"
              estimatorDecimals
              id="cc-f-i-12"
            />
          </mat-form-field> -->
          <span class="p-float-label">
            <p-inputNumber
              [useGrouping]="false"
              [minFractionDigits]="0"
              [maxFractionDigits]="2"
              [min]="0"
              [max]="MAX_FINANCE_VALUE"
              [step]="0"
              formControlName="constant_weight"
              inputStyleClass="w-full"
              class="height-35"
              inputId="cc-f-i-12"
              estimatorDecimals
            ></p-inputNumber>
            <label for="cc-f-i-12">Constants</label></span
          >
          <!--  <mat-form-field appearance="outline">
            <mat-label>Other</mat-label>
            <input
              matInput
              type="number"
              name="other_weight"
              placeholder="Other"
              formControlName="other_weight"
              [step]="0"
              estimatorDecimals
              id="cc-f-i-13"
            />
          </mat-form-field> -->
          <span class="p-float-label">
            <p-inputNumber
              [useGrouping]="false"
              [minFractionDigits]="0"
              [maxFractionDigits]="2"
              [min]="0"
              [max]="MAX_FINANCE_VALUE"
              [step]="0"
              formControlName="other_weight"
              inputStyleClass="w-full"
              class="height-35"
              inputId="cc-f-i-13"
              estimatorDecimals
            ></p-inputNumber>
            <label for="cc-f-i-13">Other</label></span
          >
        </div>
        <!--  <mat-form-field appearance="outline">
          <mat-label>Deductibles</mat-label>
          <input
            matInput
            type="number"
            name="deductibles"
            placeholder="Deductibles"
            formControlName="deductibles"
            [step]="0"
            estimatorDecimals
            id="cc-f-i-14"
          />
        </mat-form-field> -->
        <div class="w-full">
          <span
            class="p-float-label"
            [ngClass]="{
              'show-invalid':
                cargoCalculatorForm.controls.deductibles.errors &&
                cargoCalculatorForm.controls.deductibles.errors['invalid']
            }"
          >
            <p-inputNumber
              [useGrouping]="false"
              [minFractionDigits]="0"
              [maxFractionDigits]="2"
              [min]="0"
              [max]="MAX_FINANCE_VALUE"
              [step]="0"
              formControlName="deductibles"
              inputStyleClass="w-full"
              class="height-35 w-full"
              inputId="cc-f-i-14"
              estimatorDecimals
            ></p-inputNumber>
            <label for="cc-f-i-14">Deductibles</label></span
          >
        </div>
        <!--  <mat-form-field appearance="outline">
          <mat-label>Max quantity</mat-label>
          <input
            matInput
            type="number"
            name="max_dwt_quantity"
            placeholder="Max quantity"
            formControlName="max_dwt_quantity"
            [ngClass]="{
              'quantity-max':
                isFieldNotNull('max_dwt_quantity') && !isMinimumQuantity('max_dwt_quantity'),
              'quantity-min':
                isMinimumQuantity('max_dwt_quantity') && isFieldNotNull('max_dwt_quantity')
            }"
            [step]="0"
            estimatorDecimals
            id="cc-f-i-15"
          /> -->
        <!-- <button
            type="button"
            (click)="onCopyQuantity('max_dwt_quantity')"
            [color]="MaterialThemeColor.Primary"
            matSuffix
            mat-icon-button
            id="max_dwt_quantity_button"
          >
            <mat-icon>input</mat-icon>
          </button> -->
        <!-- </mat-form-field> -->
        <div class="w-full">
          <span class="p-float-label">
            <p-inputNumber
              [useGrouping]="false"
              [minFractionDigits]="0"
              [maxFractionDigits]="2"
              [min]="0"
              [max]="MAX_FINANCE_VALUE"
              [ngClass]="{
                'quantity-max':
                  isFieldNotNull('max_dwt_quantity') && !isMinimumQuantity('max_dwt_quantity'),
                'quantity-min':
                  isMinimumQuantity('max_dwt_quantity') && isFieldNotNull('max_dwt_quantity')
              }"
              [step]="0"
              formControlName="max_dwt_quantity"
              inputStyleClass="w-full"
              class="height-35 w-full"
              inputId="cc-f-i-15"
              estimatorDecimals
            ></p-inputNumber>
            <label for="cc-f-i-15">Max quantity</label></span
          >
        </div>
      </div>
      <div class="calculator-form-row-section gap-4">
        <div
          class="w-full flex-container-row content-space-between align-items-center vessel-section-title -mb-3"
        >
          <p>Volume</p>
          <div class="w-6 flex justify-content-between align-items-center">
            <p>Grain/bale calc</p>
            <estimator-button-switch
              [tooltip]="true"
              [options]="grainCalculationTypes"
              [value]="cargoCalculatorForm.controls.grain_bale_calculation_type.value"
              id="cargo_calculator_form-default_grain_bale_calculation_type"
              (onChange)="onDefaultGrainBaleCalculationTypeChange($event)"
              optionLabel="short_name"
              optionValue="value"
            >
            </estimator-button-switch>
          </div>
        </div>

        <div class="double-input">
          <!--  <mat-form-field appearance="outline">
            <mat-label>Grain (CBM)</mat-label>
            <input
              matInput
              type="number"
              name="grain_volume"
              placeholder="Grain (CBM)"
              formControlName="grain_volume"
              [step]="0"
              estimatorDecimals
              [maxFractionDigits]="3"
              id="cc-f-i-1.1"
            />
          </mat-form-field> -->
          <span class="p-float-label">
            <p-inputNumber
              [useGrouping]="false"
              [minFractionDigits]="0"
              [maxFractionDigits]="2"
              [min]="0"
              [max]="MAX_FINANCE_VALUE"
              [step]="0"
              formControlName="grain_volume"
              inputStyleClass="w-full"
              class="height-35"
              inputId="grain_cbm_imput"
              estimatorDecimals
            ></p-inputNumber>
            <label for="cc-f-i-1.1">Grain ({{ TEXT_CBM }})</label></span
          >
          <!-- <mat-form-field appearance="outline">
            <mat-label>Grain (CFT)</mat-label>
            <input
              matInput
              type="number"
              name="grain_volume_ft"
              placeholder="Grain (CFT)"
              formControlName="grain_volume_ft"
              [step]="0"
              estimatorDecimals
              id="cc-f-i-1.2"
            />
          </mat-form-field> -->
          <span class="p-float-label">
            <p-inputNumber
              [useGrouping]="false"
              [minFractionDigits]="0"
              [maxFractionDigits]="2"
              [min]="0"
              [max]="MAX_FINANCE_VALUE"
              [step]="0"
              formControlName="grain_volume_ft"
              inputStyleClass="w-full"
              class="height-35"
              inputId="grain_cbm_volume"
              estimatorDecimals
            ></p-inputNumber>
            <label for="cc-f-i-1.2">Grain ({{ TEXT_CFT }})</label></span
          >
        </div>
        <div class="double-input">
          <!-- <mat-form-field appearance="outline">
            <mat-label>Bale (CBM)</mat-label>
            <input
              matInput
              type="number"
              name="bale_volume"
              placeholder="Bale (CBM)"
              formControlName="bale_volume"
              [step]="0"
              estimatorDecimals
              [maxFractionDigits]="3"
              id="cc-f-i-1.3"
            />
          </mat-form-field> -->
          <span class="p-float-label">
            <p-inputNumber
              [useGrouping]="false"
              [minFractionDigits]="0"
              [maxFractionDigits]="2"
              [min]="0"
              [max]="MAX_FINANCE_VALUE"
              [step]="0"
              formControlName="bale_volume"
              inputStyleClass="w-full"
              class="height-35"
              inputId="bale_cbm_input"
              estimatorDecimals
            ></p-inputNumber>
            <label for="cc-f-i-1.3">Bale ({{ TEXT_CBM }})</label></span
          >
          <!-- <mat-form-field appearance="outline">
            <mat-label>Bale (CFT)</mat-label>
            <input
              matInput
              type="number"
              name="bale_volume_ft"
              placeholder="Bale (CFT)"
              formControlName="bale_volume_ft"
              [step]="0"
              estimatorDecimals
              id="cc-f-i-1.4"
            />
          </mat-form-field> -->
          <span class="p-float-label">
            <p-inputNumber
              [useGrouping]="false"
              [minFractionDigits]="0"
              [maxFractionDigits]="2"
              [min]="0"
              [max]="MAX_FINANCE_VALUE"
              [step]="0"
              formControlName="bale_volume_ft"
              inputStyleClass="w-full"
              class="height-35"
              inputId="bale_cbm_volume"
              estimatorDecimals
            ></p-inputNumber>
            <label for="cc-f-i-1.4">Bale ({{ TEXT_CFT }})</label></span
          >
        </div>
        <div class="double-input">
          <!-- <mat-form-field appearance="outline">
            <mat-label>Stowage factor (CBM)</mat-label>
            <input
              matInput
              type="number"
              name="stowage_factor"
              placeholder="Stowage factor (CBM)"
              formControlName="stowage_factor"
              [step]="0"
              estimatorDecimals
              [maxFractionDigits]="3"
              id="cc-f-i-2.1"
            />
          </mat-form-field> -->
          <span class="p-float-label">
            <p-inputNumber
              [useGrouping]="false"
              [minFractionDigits]="0"
              [maxFractionDigits]="2"
              [min]="0"
              [max]="MAX_FINANCE_VALUE"
              [step]="0"
              formControlName="stowage_factor"
              inputStyleClass="w-full"
              class="height-35"
              inputId="stowage_factor_cbm_input"
              estimatorDecimals
            ></p-inputNumber>
            <label for="cc-f-i-2.1">Stowage factor ({{ TEXT_CBM }})</label></span
          >
          <!-- <mat-form-field appearance="outline">
            <mat-label>Stowage factor (CFT)</mat-label>
            <input
              matInput
              type="number"
              name="stowage_factor"
              placeholder="Stowage factor (CFT)"
              formControlName="stowage_factor_ft"
              [step]="0"
              estimatorDecimals
              id="cc-f-i-2.2"
            />
          </mat-form-field> -->
          <span class="p-float-label">
            <p-inputNumber
              [useGrouping]="false"
              [minFractionDigits]="0"
              [maxFractionDigits]="2"
              [min]="0"
              [max]="MAX_FINANCE_VALUE"
              [step]="0"
              formControlName="stowage_factor_ft"
              inputStyleClass="w-full"
              class="height-35"
              inputId="stowage_factor_cbm_volume"
              estimatorDecimals
            ></p-inputNumber>
            <label for="cc-f-i-2.2">Stowage factor ({{ TEXT_CFT }})</label></span
          >
        </div>
        <!--  <mat-form-field appearance="outline">
          <mat-label>Max grain quantity (MT)</mat-label>
          <input
            matInput
            type="number"
            name="max_grain_volume_quantity"
            placeholder="Max grain quantity (MT)"
            formControlName="max_grain_volume_quantity"
            [ngClass]="{
              'quantity-max':
                isFieldNotNull('max_grain_volume_quantity') &&
                !isMinimumQuantity('max_grain_volume_quantity'),
              'quantity-min':
                isMinimumQuantity('max_grain_volume_quantity') &&
                isFieldNotNull('max_grain_volume_quantity')
            }"
            [step]="0"
            estimatorDecimals
            id="cc-f-i-3.1"
          /> -->
        <!--  <button
            type="button"
            (click)="onCopyQuantity('max_grain_volume_quantity')"
            [color]="MaterialThemeColor.Primary"
            matSuffix
            mat-icon-button
          >
            <mat-icon>input</mat-icon>
          </button> -->
        <!--   </mat-form-field> -->
        <div class="w-full">
          <span class="p-float-label">
            <p-inputNumber
              [useGrouping]="false"
              [minFractionDigits]="0"
              [maxFractionDigits]="2"
              [min]="0"
              [max]="MAX_FINANCE_VALUE"
              [step]="0"
              formControlName="max_grain_volume_quantity"
              [ngClass]="{
                'quantity-max':
                  isFieldNotNull('max_grain_volume_quantity') &&
                  !isMinimumQuantity('max_grain_volume_quantity'),
                'quantity-min':
                  isMinimumQuantity('max_grain_volume_quantity') &&
                  isFieldNotNull('max_grain_volume_quantity')
              }"
              inputStyleClass="w-full"
              class="height-35 w-full"
              inputId="max_grain_volume_quantity_input"
              estimatorDecimals
            ></p-inputNumber>
            <label for="cc-f-i-3.1">Max grain quantity ({{ TEXT_MT }})</label></span
          >
        </div>
        <!--  <mat-form-field appearance="outline">
          <mat-label>Max bale quantity (MT)</mat-label>
          <input
            matInput
            type="number"
            name="max_bale_volume_quantity"
            placeholder="Max bale quantity (MT)"
            formControlName="max_bale_volume_quantity"
            [ngClass]="{
              'quantity-max':
                isFieldNotNull('max_bale_volume_quantity') &&
                !isMinimumQuantity('max_bale_volume_quantity'),
              'quantity-min':
                isMinimumQuantity('max_bale_volume_quantity') &&
                isFieldNotNull('max_bale_volume_quantity')
            }"
            [step]="0"
            estimatorDecimals
            id="cc-f-i-3.2"
          /> -->
        <!--   <button
            type="button"
            (click)="onCopyQuantity('max_bale_volume_quantity')"
            [color]="MaterialThemeColor.Primary"
            matSuffix
            mat-icon-button
          >
            <mat-icon>input</mat-icon>
          </button> -->
        <!--  </mat-form-field> -->
        <div class="w-full">
          <span class="p-float-label">
            <p-inputNumber
              [useGrouping]="false"
              [minFractionDigits]="0"
              [maxFractionDigits]="2"
              [min]="0"
              [max]="MAX_FINANCE_VALUE"
              [step]="0"
              formControlName="max_bale_volume_quantity"
              [ngClass]="{
                'quantity-max':
                  isFieldNotNull('max_bale_volume_quantity') &&
                  !isMinimumQuantity('max_bale_volume_quantity'),
                'quantity-min':
                  isMinimumQuantity('max_bale_volume_quantity') &&
                  isFieldNotNull('max_bale_volume_quantity')
              }"
              inputStyleClass="w-full"
              class="height-35 w-full"
              inputId="max_bale_volume_quantity_input"
              estimatorDecimals
            ></p-inputNumber>
            <label for="cc-f-i-3.2">Max bale quantity ({{ TEXT_MT }})</label></span
          >
        </div>
        <!-- <mat-form-field appearance="outline">
          <mat-label class="cargo-intake">Cargo intake</mat-label>
          <input
            matInput
            type="number"
            name="summ_max_fields"
            placeholder="Cargo intake"
            formControlName="summ_max_fields"
            [step]="0"
            estimatorDecimals
            id="cc-f-i-3.3"
          />
          <button
            type="button"
            (click)="onCopyQuantity('summ_max_fields')"
            [color]="MaterialThemeColor.Primary"
            matSuffix
            mat-icon-button
          >
            <mat-icon class="cargo-intake cargo-intake-icon">input</mat-icon>
          </button>
        </mat-form-field> -->
        <div class="w-full">
          <span class="p-float-label">
            <p-inputNumber
              [useGrouping]="false"
              [minFractionDigits]="0"
              [maxFractionDigits]="2"
              [min]="0"
              [max]="MAX_FINANCE_VALUE"
              [step]="0"
              formControlName="summ_max_fields"
              inputStyleClass="w-full"
              class="height-35 w-full"
              inputId="cc-f-i-3.3"
              estimatorDecimals
            ></p-inputNumber>
            <span
              class="material-icons absolute cargo-intake-material cursor-pointer"
              (click)="onCopyQuantity('summ_max_fields')"
              >input</span
            >
            <label for="cc-f-i-3.3" class="cargo-intake">Cargo intake</label></span
          >
        </div>
      </div>
    </div>
    <div class="calculator-form-row mb-3">
      <div class="calculator-form-row-section gap-4">
        <p class="no-wrap -mb-3">{{ isAdvanced ? 'Loading port' : 'Port' }}</p>
        <p-dropdown
          [options]="portWaterTypes"
          optionLabel="name"
          optionValue="value"
          formControlName="load_port_water_type"
          class="p-dropdown-sm w-full"
          styleClass="w-full"
        ></p-dropdown>
        <p-dropdown
          [options]="portSeasonTypes"
          optionLabel="name"
          optionValue="value"
          formControlName="load_port_season_type"
          class="p-dropdown-sm w-full"
          styleClass="w-full"
        ></p-dropdown>
        <!--  <mat-form-field *ngIf="isAdvanced" appearance="outline">
          <mat-label>Draft start</mat-label>
          <input
            matInput
            type="number"
            name="load_port_draft_start"
            placeholder="Draft start"
            formControlName="load_port_draft_start"
            [step]="0"
            estimatorDecimals
            id="cc-f-i-4"
          />
        </mat-form-field> -->
        <div class="w-full" *ngIf="isAdvanced">
          <span class="p-float-label">
            <p-inputNumber
              [useGrouping]="false"
              [minFractionDigits]="0"
              [maxFractionDigits]="2"
              [min]="0"
              [max]="MAX_FINANCE_VALUE"
              [step]="0"
              formControlName="load_port_draft_start"
              inputStyleClass="w-full"
              class="height-35 w-full"
              inputId="cc-f-i-4"
              estimatorDecimals
            ></p-inputNumber>
            <label for="cc-f-i-4">Draft start</label></span
          >
        </div>
        <!--  <mat-form-field appearance="outline">
          <mat-label>Draft restriction</mat-label>
          <input
            matInput
            type="number"
            name="load_port_draft_restriction"
            placeholder="Draft restriction"
            formControlName="load_port_draft_restriction"
            [step]="0"
            estimatorDecimals
            id="cc-f-i-6"
          />
        </mat-form-field> -->
        <div class="double-input">
          <span class="p-float-label">
            <p-inputNumber
              [useGrouping]="false"
              [minFractionDigits]="0"
              [maxFractionDigits]="2"
              [min]="0"
              [max]="MAX_FINANCE_VALUE"
              [step]="0"
              formControlName="load_port_draft_restriction"
              inputStyleClass="w-full"
              class="height-35 w-full"
              inputId="cc-f-i-6.1"
              estimatorDecimals
            ></p-inputNumber>
            <label for="cc-f-i-6.1">Draft restriction ({{ TEXT_CBM }})</label></span
          >

          <span class="p-float-label">
            <p-inputNumber
              [useGrouping]="false"
              [minFractionDigits]="0"
              [maxFractionDigits]="2"
              [min]="0"
              [max]="MAX_FINANCE_VALUE"
              [step]="0"
              formControlName="load_port_draft_restriction_ft"
              inputStyleClass="w-full"
              class="height-35 w-full"
              inputId="cc-f-i-6.2"
              estimatorDecimals
            ></p-inputNumber>
            <label for="cc-f-i-6.2">Draft restriction ({{ TEXT_CFT }})</label></span
          >
        </div>
        <!-- <mat-form-field *ngIf="isAdvanced" appearance="outline">
          <mat-label>Max quantity (draft start)</mat-label>
          <input
            matInput
            type="number"
            name="max_load_port_quantity"
            placeholder="Max quantity (draft start)"
            formControlName="max_load_port_quantity"
            [ngClass]="{
              'quantity-max':
                isFieldNotNull('max_load_port_quantity') &&
                !isMinimumQuantity('max_load_port_quantity'),
              'quantity-min':
                isMinimumQuantity('max_load_port_quantity') &&
                isFieldNotNull('max_load_port_quantity')
            }"
            [step]="0"
            estimatorDecimals
            id="cc-f-i-7.1"
          /> -->
        <!--  <button
            type="button"
            (click)="onCopyQuantity('max_load_port_quantity')"
            [color]="MaterialThemeColor.Primary"
            matSuffix
            mat-icon-button
          >
            <mat-icon>input</mat-icon>
          </button> -->
        <!--  </mat-form-field> -->
        <div class="w-full" *ngIf="isAdvanced">
          <span class="p-float-label">
            <p-inputNumber
              [useGrouping]="false"
              [minFractionDigits]="0"
              [maxFractionDigits]="2"
              [min]="0"
              [max]="MAX_FINANCE_VALUE"
              [step]="0"
              formControlName="max_load_port_quantity"
              [ngClass]="{
                'quantity-max':
                  isFieldNotNull('max_load_port_quantity') &&
                  !isMinimumQuantity('max_load_port_quantity'),
                'quantity-min':
                  isMinimumQuantity('max_load_port_quantity') &&
                  isFieldNotNull('max_load_port_quantity')
              }"
              inputStyleClass="w-full"
              class="height-35 w-full"
              inputId="cc-f-i-7.1"
              estimatorDecimals
            ></p-inputNumber>
            <label for="cc-f-i-7.1">Max quantity (draft start)</label></span
          >
        </div>
        <!--  <mat-form-field appearance="outline">
          <mat-label>Max quantity (summer draft)</mat-label>
          <input
            matInput
            type="number"
            name="max_available_tonnage_quantity"
            placeholder="Max quantity (summer draft)"
            formControlName="max_available_tonnage_quantity"
            [ngClass]="{
              'quantity-max':
                isFieldNotNull('max_available_tonnage_quantity') &&
                !isMinimumQuantity('max_available_tonnage_quantity'),
              'quantity-min':
                isMinimumQuantity('max_available_tonnage_quantity') &&
                isFieldNotNull('max_available_tonnage_quantity')
            }"
            [step]="0"
            estimatorDecimals
            id="cc-f-i-7.2"
          /> -->
        <!--  <button
            type="button"
            (click)="onCopyQuantity('max_available_tonnage_quantity')"
            [color]="MaterialThemeColor.Primary"
            matSuffix
            mat-icon-button
            id="max_available_tonnage_quantity_button"
          >
            <mat-icon>input</mat-icon>
          </button> -->
        <!-- </mat-form-field> -->
        <div class="w-full">
          <span class="p-float-label">
            <p-inputNumber
              [useGrouping]="false"
              [minFractionDigits]="0"
              [maxFractionDigits]="2"
              [min]="0"
              [max]="MAX_FINANCE_VALUE"
              [step]="0"
              formControlName="max_available_tonnage_quantity"
              [ngClass]="{
                'quantity-max':
                  isFieldNotNull('max_available_tonnage_quantity') &&
                  !isMinimumQuantity('max_available_tonnage_quantity'),
                'quantity-min':
                  isMinimumQuantity('max_available_tonnage_quantity') &&
                  isFieldNotNull('max_available_tonnage_quantity')
              }"
              inputStyleClass="w-full"
              class="height-35 w-full"
              inputId="cc-f-i-7.2"
              estimatorDecimals
            ></p-inputNumber>
            <label for="cc-f-i-7.2">Max quantity (summer draft)</label></span
          >
        </div>
      </div>
      <div *ngIf="!isAdvanced" class="w-full"></div>
      <div *ngIf="isAdvanced" class="calculator-form-row-section gap-4">
        <p class="no-wrap -mb-3">Discharging port</p>
        <p-dropdown
          [options]="portWaterTypes"
          optionLabel="name"
          optionValue="value"
          formControlName="discharge_port_water_type"
          [itemSize]=""
          class="w-full"
        ></p-dropdown>
        <p-dropdown
          [options]="portSeasonTypes"
          optionLabel="name"
          optionValue="value"
          formControlName="discharge_port_season_type"
          class="p-dropdown-sm w-full"
        ></p-dropdown>
        <div class="double-input">
          <!--  <mat-form-field appearance="outline">
            <mat-label>Consumption per day</mat-label>
            <input
              matInput
              type="number"
              name="consumption_per_day"
              placeholder="Consumption per day"
              formControlName="consumption_per_day"
              [step]="0"
              estimatorDecimals
              id="cc-f-i-16"
            />
          </mat-form-field> -->
          <span class="p-float-label">
            <p-inputNumber
              [useGrouping]="false"
              [minFractionDigits]="0"
              [maxFractionDigits]="2"
              [min]="0"
              [max]="MAX_FINANCE_VALUE"
              [step]="0"
              formControlName="consumption_per_day"
              inputStyleClass="w-full"
              class="height-35"
              inputId="cc-f-i-16"
              estimatorDecimals
            ></p-inputNumber>
            <label for="cc-f-i-16">Consumption per day</label></span
          >
          <!-- <mat-form-field appearance="outline">
            <mat-label>Days</mat-label>
            <input
              matInput
              type="number"
              name="consumption_days"
              placeholder="Days"
              formControlName="consumption_days"
              [step]="0"
              estimatorDecimals
              id="cc-f-i-17"
            />
          </mat-form-field> -->
          <span class="p-float-label">
            <p-inputNumber
              [useGrouping]="false"
              [minFractionDigits]="0"
              [maxFractionDigits]="2"
              [min]="0"
              [max]="MAX_FINANCE_VALUE"
              [step]="0"
              formControlName="consumption_days"
              inputStyleClass="w-full"
              class="height-35"
              inputId="cc-f-i-17"
              estimatorDecimals
            ></p-inputNumber>
            <label for="cc-f-i-17">Days</label></span
          >
        </div>
        <div class="double-input">
          <!-- <mat-form-field appearance="outline">
            <mat-label>Draft restriction</mat-label>
            <input
              matInput
              type="number"
              name="discharge_port_draft_restriction"
              placeholder="Draft restriction"
              formControlName="discharge_port_draft_restriction"
              [step]="0"
              estimatorDecimals
              id="cc-f-i-19"
            />
          </mat-form-field> -->
          <span class="p-float-label">
            <p-inputNumber
              [useGrouping]="false"
              [minFractionDigits]="0"
              [maxFractionDigits]="2"
              [min]="0"
              [max]="MAX_FINANCE_VALUE"
              [step]="0"
              formControlName="discharge_port_draft_restriction"
              inputStyleClass="w-full"
              class="height-35"
              inputId="cc-f-i-19"
              estimatorDecimals
            ></p-inputNumber>
            <label for="cc-f-i-19">Draft restriction</label></span
          >
          <!-- <mat-form-field appearance="outline">
            <mat-label>Total</mat-label>
            <input
              matInput
              type="number"
              name="total_consumption"
              placeholder="Total"
              formControlName="total_consumption"
              [step]="0"
              estimatorDecimals
              id="cc-f-i-18"
            />
          </mat-form-field> -->
          <span class="p-float-label">
            <p-inputNumber
              [useGrouping]="false"
              [minFractionDigits]="0"
              [maxFractionDigits]="2"
              [min]="0"
              [max]="MAX_FINANCE_VALUE"
              [step]="0"
              formControlName="total_consumption"
              inputStyleClass="w-full"
              class="height-35"
              inputId="cc-f-i-18"
              estimatorDecimals
            ></p-inputNumber>
            <label for="cc-f-i-18">Total</label></span
          >
        </div>
        <!--  <mat-form-field appearance="outline">
          <mat-label>Max quantity (disch port) by draft start</mat-label>
          <input
            matInput
            type="number"
            name="max_discharge_port_draft_quantity"
            placeholder="Max quantity (disch port) by draft start"
            formControlName="max_discharge_port_draft_quantity"
            [ngClass]="{
              'quantity-max':
                isFieldNotNull('max_discharge_port_draft_quantity') &&
                !isMinimumQuantity('max_discharge_port_draft_quantity'),
              'quantity-min':
                isMinimumQuantity('max_discharge_port_draft_quantity') &&
                isFieldNotNull('max_discharge_port_draft_quantity')
            }"
            [step]="0"
            estimatorDecimals
            id="cc-f-i-20"
          /> -->
        <!--  <button
            type="button"
            (click)="onCopyQuantity('max_discharge_port_draft_quantity')"
            [color]="MaterialThemeColor.Primary"
            matSuffix
            mat-icon-button
          >
            <mat-icon>input</mat-icon>
          </button> -->
        <!-- </mat-form-field> -->
        <div class="w-full">
          <span class="p-float-label">
            <p-inputNumber
              [useGrouping]="false"
              [minFractionDigits]="0"
              [maxFractionDigits]="2"
              [min]="0"
              [max]="MAX_FINANCE_VALUE"
              [step]="0"
              formControlName="max_discharge_port_draft_quantity"
              [ngClass]="{
                'quantity-max':
                  isFieldNotNull('max_discharge_port_draft_quantity') &&
                  !isMinimumQuantity('max_discharge_port_draft_quantity'),
                'quantity-min':
                  isMinimumQuantity('max_discharge_port_draft_quantity') &&
                  isFieldNotNull('max_discharge_port_draft_quantity')
              }"
              inputStyleClass="w-full"
              class="height-35 w-full"
              inputId="cc-f-i-20"
              estimatorDecimals
            ></p-inputNumber>
            <label for="cc-f-i-20">Max quantity (disch port) by draft start</label></span
          >
        </div>
        <!-- <mat-form-field appearance="outline">
          <mat-label>Max quantity (disch port) by draft restriction</mat-label>
          <input
            matInput
            type="number"
            name="max_available_tonnage_discharge_quantity"
            placeholder="Max quantity (disch port) by draft restriction"
            formControlName="max_available_tonnage_discharge_quantity"
            [ngClass]="{
              'quantity-max':
                isFieldNotNull('max_available_tonnage_discharge_quantity') &&
                !isMinimumQuantity('max_available_tonnage_discharge_quantity'),
              'quantity-min':
                isMinimumQuantity('max_available_tonnage_discharge_quantity') &&
                isFieldNotNull('max_available_tonnage_discharge_quantity')
            }"
            [step]="0"
            estimatorDecimals
            id="cc-f-i-21"
          /> -->
        <!-- <button
            type="button"
            (click)="onCopyQuantity('max_available_tonnage_discharge_quantity')"
            [color]="MaterialThemeColor.Primary"
            matSuffix
            mat-icon-button
          >
            <mat-icon>input</mat-icon>
          </button> -->
        <!-- </mat-form-field> -->
        <div class="w-full">
          <span class="p-float-label">
            <p-inputNumber
              [useGrouping]="false"
              [minFractionDigits]="0"
              [maxFractionDigits]="2"
              [min]="0"
              [max]="MAX_FINANCE_VALUE"
              [step]="0"
              formControlName="max_available_tonnage_discharge_quantity"
              [ngClass]="{
                'quantity-max':
                  isFieldNotNull('max_available_tonnage_discharge_quantity') &&
                  !isMinimumQuantity('max_available_tonnage_discharge_quantity'),
                'quantity-min':
                  isMinimumQuantity('max_available_tonnage_discharge_quantity') &&
                  isFieldNotNull('max_available_tonnage_discharge_quantity')
              }"
              inputStyleClass="w-full"
              class="height-35 w-full"
              inputId="cc-f-i-21"
              estimatorDecimals
            ></p-inputNumber>
            <label for="cc-f-i-21">Max quantity (disch port) by draft restriction</label></span
          >
        </div>
      </div>
    </div>
  </form>
</div>
