import { MemberRole, ProductType } from './authentication';
import { BaseEntity } from './base-entity';
import { Company } from './company';
import { UiSettingsStateModel } from './ui-settings';

export interface User extends BaseEntity {
  email: string;
  password: string;
  company: Company;
  company_id: number;
  confirmed: boolean;
  temporary?: boolean;
  service_types?: ProductType[];
  user_role?: MemberRole;
  with_fuel_consumptions_parser?: boolean;
  /**
   * allow to see fuel prices
   */
  with_xbpi?: boolean;
  first_name: string;
  last_name: string;
  /**
   * front field
   */
  initials?: string;
  name?: string; // only frontend
  settings?: UiSettingsStateModel;
}
