import { EventEmitter } from '@angular/core';
import { BaseEntity } from './base-entity';

export interface PortDisbursementAccount extends BaseEntity {
  cargo_amount?: number;
  cargo_name?: string;
  da_amount?: number;
  dwt?: number;
  general_vessel_id?: number;
  port_id?: number;
  vessel_name?: string;
}

export interface PortDisbursementRequest {
  /**
   * dwt OR general_vessel_id - required (now deprecated, may be soon change it)
   */
  // dwt?: number;
  // general_vessel_id?: number;
  port_id: number;
}

export interface PortDisbursementResponse {
  port_disbursement_accounts: PortDisbursementAccount[];
}

export interface PortDisbursementTableDialogConfig {
  portDisbursements: PortDisbursementAccount[];
  isFixed: boolean;
  savePortDA: EventEmitter<number>;
}
