import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { HEADER_MENU, PAGE, PRODUCTS, Product } from '@estimator/landing-models';
import { Subject, takeUntil } from 'rxjs';
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'mar-header',
  template: ` <div class="header-container">
    <div class="header-wrapper" [class]="currentTheme" #header>
      <header class="container" [class]="currentTheme">
        <div>
          <mar-logo
            [routerLink]="[PAGE.MAINPAGE]"
            [width]="xsBreakPoint ? 84 : 115"
            [currentTheme]="currentTheme"
            class="link"
          ></mar-logo>
        </div>
        <nav #nav>
          <ul class="header-menu">
            <ng-container *ngFor="let item of HEADER_MENU">
              <li *ngIf="item.slug !== PAGE.PRODUCTS; else productsMenu">
                <a [routerLink]="[item.slug]" class="nav-link" (click)="onOpenCloseMenu()">{{
                  item.name
                }}</a>
              </li>
              <ng-template #productsMenu>
                <li class="dropdown" (click)="openSubMenu($event.target)">
                  <a class="nav-link"
                    >Products
                    <svg
                      width="10"
                      height="6"
                      viewBox="0 0 10 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 1L5 5L9 1"
                        stroke-width="1.33333"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </a>
                  <div class="dropdown-content">
                    <ul>
                      <li *ngFor="let product of PRODUCTS">
                        <a
                          [routerLink]="product.disabled ? null : product.url"
                          (click)="onOpenCloseMenu()"
                          [ngClass]="{ 'cursor-default': product.disabled }"
                        >
                          <img
                            src="./assets/icon-{{ product.slug }}-{{
                              currentTheme === 'theme-light' || xsBreakPoint ? 'light' : 'dark'
                            }}.svg"
                          />
                          <div>
                            <p class="f-header">{{ product.name }}</p>
                            <p>{{ product.short_description }}</p>
                            <p *ngIf="product.disabled" class="opacity-5">Coming soon</p>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
              </ng-template>
            </ng-container>

            <ul class="xs-mobile-show">
              <li class="buttons">
                <mar-button
                  [routerLink]="[PAGE.EXTERNAL.ESTIMATOR_REGISTER]"
                  [queryParams]="{ signIn: 'true' }"
                  type="anchor"
                  target="_blank"
                  color="transparent"
                  >Log in</mar-button
                >
                <mar-button (click)="onOpenSidebar()" type="button" target="_blank"
                  >Book a demo</mar-button
                >
              </li>
            </ul>
          </ul>
          <div class="bg-footer"></div>
        </nav>
        <div class="buttons xs-mobile-hide">
          <mar-button
            [dropDownMenu]="[
              { label: 'Bunker Platform', routerLink: [PAGE.EXTERNAL.BUNKER] },
              {
                label: 'Voyage Planner',
                routerLink: [PAGE.EXTERNAL.ESTIMATOR_REGISTER],
                queryParams: { signIn: 'true' }
              }
            ]"
            type="dropdown"
            [color]="currentTheme === 'theme-light' ? 'light white-light' : 'transparent'"
            [size]="xsBreakPoint ? 'small' : 'normal'"
            target="_blank"
            >Log in</mar-button
          >
          <mar-button
            (click)="onOpenSidebar()"
            type="button"
            [border]="true"
            [size]="xsBreakPoint ? 'small' : 'normal'"
            [color]="currentTheme === 'theme-light' ? 'blue' : 'light'"
            >Book a demo</mar-button
          >
        </div>
        <div class="burger-menu" [class]="currentTheme" (click)="onOpenCloseMenu()" #burgerMenu>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </header>
    </div>
  </div>`,
  styles: [
    `
      @import 'variables';
      :host {
        position: relative;
        header {
          position: relative;
          height: 9.6rem;
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          align-items: center;
          justify-content: space-between;
          gap: 2rem;
        }
        .header-container {
          height: 9.6rem;
        }
        .header-wrapper {
          width: 100%;
          position: fixed;
          z-index: 10;
          border-bottom: 1px solid #363c4c;
          background-color: none;
          transition: 0.5s;
          transform: translateY(0);
          backdrop-filter: blur(0.5rem);
          -webkit-backdrop-filter: blur(0.5rem);
          .buttons {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 0.8rem;
          }
          &.show {
            transform: translateY(0) !important;
            background-color: rgba(6, 11, 24, 0.5);
            &.theme-light {
              background-color: rgba(255, 255, 255, 0.1);
            }
          }
          &.on-top {
            transform: translateY(0) !important;
            background-color: unset;
            backdrop-filter: unset;
            -webkit-backdrop-filter: unset;
          }
          &.theme-light {
            background-color: #ffffff50;
            color: $primary;
            border-bottom: 1px solid #060b1826;
          }
        }
        .f-header {
          font-size: 20px;
          font-family: 'Aeonik Pro';
          line-height: 150%;
          color: $primary;
        }
        .header-menu {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 3.2rem;
          position: relative;
          li {
            list-style-type: none;
          }
        }
        .dropdown {
          position: relative;
          z-index: 2;
          .nav-link {
            display: inline-flex;
            align-items: center;
            gap: 0.7rem;
          }
          .dropdown-content {
            position: absolute;
            top: 2rem;
            left: -7rem;
            transform-origin: top left;
            transform: scale3d(0, 0, 1);
            transition: 0.3s;
            &:hover {
              transform: scale3d(1, 1, 1);
            }
            ul {
              width: 36.4rem;
              background-color: $white;
              border-radius: 1.6rem;
              margin-top: 1.6rem;
              padding: 0.6rem;
            }
            a {
              display: grid;
              grid-template-columns: 64px auto;
              align-items: center;
              color: $primary;
              padding: 1.6rem 0;
              transition: 0.3s;
              img {
                width: 32px;
              }
              &:hover {
                background-color: #f0f1f4;
                border-radius: 1.2rem;
              }
              &:hover img {
                transform: scale(1.1);
              }
            }
            img {
              transition: 0.5s;
              justify-self: center;
            }
          }
          &:hover .dropdown-content {
            transform: scale3d(1, 1, 1);
          }
          .theme-light {
            background-color: #ffffff50;
            color: $primary;
          }
        }
        .burger-menu {
          display: none;
          width: 3rem;
          height: 2.5rem;
          position: relative;
          transition: 0.5s ease-in-out;
          cursor: pointer;
          &.theme-light span {
            background: $primary;
          }
        }
        .burger-menu span {
          display: block;
          position: absolute;
          height: 0.3rem;
          width: 100%;
          background: $white;
          border-radius: 0.3rem;
          opacity: 1;
          left: 0;
          transform: rotate(0deg);
          transition: 0.25s ease-in-out;
        }
        .burger-menu span:nth-child(1) {
          top: 0;
        }
        .burger-menu span:nth-child(2),
        .burger-menu span:nth-child(3) {
          top: 0.9rem;
        }
        .burger-menu span:nth-child(4) {
          top: 1.8rem;
        }
        .burger-menu.open span:nth-child(1) {
          top: 0.9rem;
          width: 0%;
          left: 50%;
        }
        .burger-menu.open span:nth-child(2) {
          transform: rotate(45deg);
        }
        .burger-menu.open span:nth-child(3) {
          transform: rotate(-45deg);
        }
        .burger-menu.open span:nth-child(4) {
          top: 0.9rem;
          width: 0%;
          left: 50%;
        }
        .bg-footer {
          visibility: hidden;
          top: 50%;
        }
        @media (max-width: $desktop-width) {
          header {
            height: 5.6rem;
            display: flex;
            backdrop-filter: blur(1rem);
            ul {
              background-color: unset !important;
            }
          }
          .header-container {
            height: 5.6rem;
          }
          .burger-menu {
            display: block;
          }
          .buttons {
            margin-left: auto;
          }
          .open-menu {
            background: #060b18;
            position: fixed;
            .dropdown-content {
              position: relative;
              transform: unset;
              top: 0;
              left: 0;
              height: 0;
              display: none;
              &.open {
                height: unset;
                display: block;
              }
              ul {
                padding: 0;
                margin: 0;
              }
              a {
                color: $white;
                grid-template-columns: 4.8rem auto;
                &:hover {
                  background-color: unset;
                  border-radius: unset;
                }
              }
              .f-header {
                color: $white;
              }
              img {
                justify-self: unset;
              }
            }
          }
          nav {
            position: absolute;
            top: 5.7rem;
            transition: 0.5s;
            width: 100%;
            height: 100vh;
            background: #060b18;
            backdrop-filter: blur(1rem);
            transform: translateX(120rem);
            ul {
              flex-direction: column;
            }
            .nav-link {
              font-size: 2.8rem;
              &:before {
                background: unset;
              }
              &.open img {
                transform: rotate(-180deg);
              }
              img {
                transform: rotate(0);
              }
            }
            .header-menu {
              padding: 2.4rem 4.8rem;
              align-items: start;
              gap: 1.2rem;
            }
          }
          nav.open {
            transform: translateX(-4.8rem);
            .nav-link {
              color: $white;
            }
            .bg-footer {
              visibility: visible;
            }
          }
        }
        @media (max-width: $tablet-width) {
          nav.open {
            transform: translateX(-2.4rem);
          }
          .header-menu {
            padding: 2.4rem !important;
          }
        }
        @media (max-width: $mobile-width) {
          nav.open {
            transform: translateX(-1.2rem);
          }
          .header-menu {
            padding: 1.2rem !important;
          }
        }
        @media (max-width: $xs-mobile-width) {
          header {
            gap: 1.2rem !important;
          }
        }
      }
    `,
  ],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements AfterViewInit, OnDestroy {
  @ViewChild('header') header?: ElementRef;
  @ViewChild('burgerMenu') burgerMenu!: ElementRef;
  @ViewChild('nav') nav?: ElementRef;
  @Input() currentTheme = 'dark';
  @Output() toggleSidebar = new EventEmitter<void>();

  @Input() set currentDirectory(url: string) {
    this._currentDirectory = url;
    this.cdr.detectChanges();
    setTimeout(() => {
      this.header?.nativeElement.classList.add('on-top');
    }, 500);
  }
  get currentDirectory() {
    return this._currentDirectory;
  }

  openedSubMenu!: HTMLAnchorElement;
  xsBreakPoint = false;

  readonly PAGE = PAGE;
  readonly HEADER_MENU: { name: string; slug: string }[] = HEADER_MENU;
  readonly PRODUCTS: Product[] = PRODUCTS;
  private _currentDirectory = '';
  private _onDestroy$ = new Subject<void>();

  constructor(
    private readonly breakpointObserver: BreakpointObserver,
    private readonly cdr: ChangeDetectorRef
  ) {}
  @HostListener('document:wheel', ['$event'])
  onWheel($event: WheelEvent): void {
    this.animateHeader($event.deltaY);
  }

  ngAfterViewInit(): void {
    this.breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.HandsetPortrait])
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((result: BreakpointState) => {
        this.xsBreakPoint = result.matches;
        this.cdr.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  animateHeader(deltaY: number): void {
    if (!this.header) {
      return;
    }
    if (window.scrollY > 24 && deltaY < 0) {
      this.header.nativeElement.classList.add('show');
      this.header.nativeElement.classList.remove('on-top');
    } else if (window.scrollY <= 24) {
      this.header.nativeElement.classList.add('on-top');
      this.header.nativeElement.classList.remove('show');
    } else {
      this.header.nativeElement.classList.remove('on-top');
      this.header.nativeElement.classList.remove('show');
      this.header.nativeElement.style.transform = `translateY(${
        -this.header.nativeElement.offsetHeight / 10
      }rem)`;
    }
  }

  onOpenCloseMenu(): void {
    if (!this.header) {
      return;
    }
    this.burgerMenu.nativeElement.classList.toggle('open');
    this.nav?.nativeElement.classList.toggle('open');
    this.header.nativeElement.classList.toggle('open-menu');
    this.openSubMenu(this.openedSubMenu);
  }

  onOpenSidebar(): void {
    this.toggleSidebar.emit();
  }

  openSubMenu(target: EventTarget | null): void {
    const elem = target as HTMLAnchorElement;
    const dropdownContent = elem?.nextSibling as HTMLDivElement;
    this.openedSubMenu = elem;
    if (dropdownContent) dropdownContent.classList.toggle('open');
    if (elem) elem.classList.toggle('open');
  }
}
