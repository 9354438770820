import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommonSiteModule } from 'packages/@mar/common-site/src';
import { FooterComponent } from '.';

@NgModule({
  declarations: [FooterComponent],
  imports: [CommonModule, RouterModule, FormsModule, ReactiveFormsModule, CommonSiteModule],
  exports: [FooterComponent],
})
export class FooterModule {}
