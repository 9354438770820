import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TooltipModule } from 'primeng/tooltip';
import { RouteMapModule } from '../route-map/route-map.module';
import { ShiplistZonesPortsFilterComponent } from './shiplist-zones-ports-filter.component';

export const SHIPLIST_ZONES_PORTS_FILTER_MODULE_IMPORTS = [
  CommonModule,
  ReactiveFormsModule,
  RouteMapModule,
  RadioButtonModule,
  AutoCompleteModule,
  ButtonModule,
  TooltipModule,
];

@NgModule({
  declarations: [ShiplistZonesPortsFilterComponent],
  imports: [SHIPLIST_ZONES_PORTS_FILTER_MODULE_IMPORTS],
  exports: [ShiplistZonesPortsFilterComponent],
})
export class ShiplistZonesPortsFilterModule {}
