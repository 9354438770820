import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { showTooltip } from '@estimator/helpers';
import {
  MaterialThemeColor,
  OK_TEXT,
  PositionTopLeft,
  PrimeNgIcons,
  UserGuideType,
} from '@estimator/models';
import { BdcWalkService } from 'bdc-walkthrough';

@Component({
  selector: 'estimator-bdc-user-guide',
  templateUrl: './bdc-user-guide.component.html',
  styleUrls: ['./bdc-user-guide.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BdcUserGuideComponent {
  constructor(private bdcWalkService: BdcWalkService) {}

  @Input()
  set posVesselForm(posVesselForm: PositionTopLeft) {
    this._posVesselForm = posVesselForm;
    if (posVesselForm) {
      this.positionVesselForm = {
        top: posVesselForm?.top /* - 20 */,
        left: posVesselForm?.left - 30,
      };
    }
  }
  get posVesselForm(): PositionTopLeft {
    return this._posVesselForm as PositionTopLeft;
  }

  @Input()
  set posVesselFuelsGrid(posVesselFuelsGrid: PositionTopLeft) {
    this._posVesselFuelsGrid = posVesselFuelsGrid;
    if (posVesselFuelsGrid) {
      this.positionVesselFuelsGrid = {
        top: posVesselFuelsGrid?.top,
        left: posVesselFuelsGrid?.left - 30,
      };
    }
  }
  get posVesselFuelsGrid(): PositionTopLeft {
    return this._posVesselFuelsGrid as PositionTopLeft;
  }

  @Input()
  set posDealInput(posDealInput: PositionTopLeft) {
    this._posDealInput = posDealInput;
    if (posDealInput) {
      this.positionDealInput = {
        top: posDealInput?.top /* - 70 */,
        left: posDealInput?.left,
      };
    } else {
      this.positionDealInput = {
        top: 30,
        left: 150,
      };
    }
  }
  get posDealInput(): PositionTopLeft {
    return this._posDealInput as PositionTopLeft;
  }

  @Input()
  set posEventList(posEventList: PositionTopLeft) {
    this._posEventList = posEventList;
    if (posEventList) {
      this.positionEventList = {
        top: posEventList?.top + 20 /* - 50 */,
        left: posEventList?.left + 70,
      };
    }
  }
  get posEventList(): PositionTopLeft {
    return this._posEventList as PositionTopLeft;
  }

  @Input()
  set posCargoList(posCargoList: PositionTopLeft) {
    this._posCargoList = posCargoList;
    if (posCargoList) {
      this.positionCargoList = {
        top: posCargoList?.top /* - 50 */,
        left: posCargoList?.left - 30,
      };
    }
  }
  get posCargoList(): PositionTopLeft {
    return this._posCargoList as PositionTopLeft;
  }

  @Input()
  set posRobList(posRobList: PositionTopLeft) {
    this._posRobList = posRobList;
    if (posRobList) {
      this.positionRobList = {
        top: posRobList?.top /* - 50 */,
        left: posRobList?.left /* + 20 */,
      };
    }
  }
  get posRobList(): PositionTopLeft {
    return this._posRobList as PositionTopLeft;
  }

  @Input()
  set posMiscList(posMiscList: PositionTopLeft) {
    this._posMiscList = posMiscList;
    if (posMiscList) {
      this.positionMiscList = {
        top: posMiscList?.top /* - 50 */,
        left: posMiscList?.left /* + 20 */,
      };
    }
  }
  get posMiscList(): PositionTopLeft {
    return this._posMiscList as PositionTopLeft;
  }

  @Input()
  set posHireInput(posHireInput: PositionTopLeft) {
    this._posHireInput = posHireInput;
    if (posHireInput) {
      this.positionHireInput = {
        top: posHireInput?.top /* - 50 */,
        left: posHireInput?.left - 30,
      };
    }
  }
  get posHireInput(): PositionTopLeft {
    return this._posHireInput as PositionTopLeft;
  }

  @Input()
  set posResult(posResult: PositionTopLeft) {
    this._posResult = posResult;
    if (posResult) {
      this.positionResult = { top: posResult?.top /* - 70 */, left: posResult?.left /* + 20 */ };
    }
  }
  get posResult(): PositionTopLeft {
    return this._posResult as PositionTopLeft;
  }

  @Input()
  set posCargoCalculator(posCargoCalculator: PositionTopLeft) {
    this._posCargoCalculator = posCargoCalculator;
    if (posCargoCalculator) {
      this.positionCargoCalculator = {
        top: posCargoCalculator?.top /* - 50 */,
        left: posCargoCalculator?.left /* + 50 */,
      };
    }
  }
  get posCargoCalculator(): PositionTopLeft {
    return this._posCargoCalculator as PositionTopLeft;
  }

  readonly px = 'px';
  readonly PrimeNgIcons = PrimeNgIcons;
  OK_TEXT = OK_TEXT;
  MaterialThemeColor = MaterialThemeColor;
  positionVesselForm?: PositionTopLeft;
  positionVesselFuelsGrid?: PositionTopLeft;
  positionDealInput?: PositionTopLeft;
  positionEventList?: PositionTopLeft;
  positionCargoList?: PositionTopLeft;
  positionRobList?: PositionTopLeft;
  positionMiscList?: PositionTopLeft;
  positionHireInput?: PositionTopLeft;
  positionResult?: PositionTopLeft;
  positionCargoCalculator?: PositionTopLeft;

  private _posVesselForm?: PositionTopLeft;
  private _posVesselFuelsGrid?: PositionTopLeft;
  private _posDealInput?: PositionTopLeft;
  private _posEventList?: PositionTopLeft;
  private _posCargoList?: PositionTopLeft;
  private _posRobList?: PositionTopLeft;
  private _posMiscList?: PositionTopLeft;
  private _posHireInput?: PositionTopLeft;
  private _posResult?: PositionTopLeft;
  private _posCargoCalculator?: PositionTopLeft;

  getGetBoundingClientRect(elementRef: ElementRef): DOMRect {
    return elementRef?.nativeElement?.getBoundingClientRect();
  }

  showTooltip(userGuideType: string) {
    showTooltip(this.bdcWalkService, userGuideType);
  }

  turnOffTutotial() {
    this.bdcWalkService.setTaskCompleted(UserGuideType.DialogWelcome0);
    this.bdcWalkService.setTaskCompleted(UserGuideType.Vessel1);
    this.bdcWalkService.setTaskCompleted(UserGuideType.Fuel2);
    this.bdcWalkService.setTaskCompleted(UserGuideType.Deal3);
    this.bdcWalkService.setTaskCompleted(UserGuideType.EventList4);
    this.bdcWalkService.setTaskCompleted(UserGuideType.Cargo5);
    this.bdcWalkService.setTaskCompleted(UserGuideType.FastBunkeringList6);
    this.bdcWalkService.setTaskCompleted(UserGuideType.MiscList7);
    this.bdcWalkService.setTaskCompleted(UserGuideType.HireInput8);
    this.bdcWalkService.setTaskCompleted(UserGuideType.Result9);
    this.bdcWalkService.setTaskCompleted(UserGuideType.CargoCalculator10);
    this.bdcWalkService.setTaskCompleted(UserGuideType.DialogMap11);
  }
}
