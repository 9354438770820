<div class="flex flex-column primeng-form" id="events_form_group">
  <p-table
    [value]="visibleControls"
    [columns]="columns"
    [resizableColumns]="true"
    [reorderableColumns]="true"
    (onRowReorder)="replacePorts($event)"
    class="primeng-form"
    styleClass="p-datatable-sm font-size-12"
    stateStorage="local"
    [stateKey]="stateKey"
    id="event_form"
  >
    <ng-template pTemplate="header">
      <tr>
        <th
          pResizableColumn
          pReorderableColumn
          #eventsAction
          *ngFor="let col of columns"
          [ngStyle]="{
            width: col?.width,
            minWidth: col?.minWidth,
            maxWidth: col?.maxWidth,
          }"
          class="text-center"
          [ngClass]="{
            'display-none':
              (col.field === EventTableColumnType.UsingCranes && isTanker) ||
              (col.field === EventTableColumnType.Terms && isTanker) ||
              (col.field === EventTableColumnType.SailingMinutes && isPortWidgetVisible) ||
              (col.field === EventTableColumnType.Distance && isPortWidgetVisible),
            'flex align-items-center justify-content-between px-1':
              col.field === EventTableColumnType.Port
          }"
          [pTooltip]="col.tooltip"
          tooltipPosition="top"
        >
          <ng-container *ngIf="col.field === EventTableColumnType.Port">
            <span>Actions</span>
            <div class="flex align-items-center gap-1">
              <p-inputSwitch
                [formControl]="showCanalsControl"
                class="p-inputswitch-sm"
                [inputId]="ID_USER_SETTINGS_FORM_SHOW_CANALS"
                id="event_form_show_canals"
                estimatorSwitchTabIndex
              ></p-inputSwitch>
              <label
                class="cursor-pointer"
                [for]="ID_USER_SETTINGS_FORM_SHOW_CANALS"
                pTooltip="Canals with data are not hidden"
              >Canals</label
              >
            </div>
          </ng-container>
          <ng-container *ngIf="col.icon; else simpleHeader">
            <span [class]="col.icon" style="width: 0.75rem; height: 0.75rem"></span>
          </ng-container>
          <ng-template #simpleHeader>
            <span [attr.id]="'events_form_group_header_' + col.header.split(' ')[0]"
            >{{ col.header }} {{ addTimeSettings(col.header) }}</span
            >
          </ng-template>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowEventForm let-i="rowIndex">
      <tr [pReorderableRow]="getVisibleControlsIndex(i)">
        <td
          *ngFor="let col of columns"
          [ngStyle]="{
            overflow: col.field === EventTableColumnType.SailingMinutes ? '' : 'visible',
            width: col?.width,
            maxWidth: col?.maxWidth,
          }"
          [ngClass]="{
            'display-none':
              (col.field === EventTableColumnType.UsingCranes && isTanker) ||
              (col.field === EventTableColumnType.Terms && isTanker) ||
              (col.field === EventTableColumnType.SailingMinutes && isPortWidgetVisible) ||
              (col.field === EventTableColumnType.Distance && isPortWidgetVisible),
          }"
        >
          <ng-container *ngIf="col.field === EventTableColumnType.Port">
            <div class="flex gap-1 align-items-center relative">
              <div class="flex gap-1 align-items-center px-1 absolute event-actions">
                <span
                  [ngClass]="{
                    hidden: hideCanalInput(rowEventForm),
                    'pointer-events-none': isFixed
                  }"
                  [class]="PrimeNgIcons.BARS"
                  pReorderableRowHandle
                ></span>
                <ng-container *ngIf="!isFixed">
                  <p-button
                    [ngClass]="{ hidden: hideCanalInput(rowEventForm) }"
                    [icon]="PrimeNgIcons.PLUS"
                    [id]="'events_form_group_add_' + i"
                    (onClick)="onAddEvent(i)"
                    [text]="true"
                    pTooltip="Add point (Ctrl + D)"
                    tooltipPosition="top"
                  ></p-button>
                  <p-button
                    [ngClass]="{ hidden: hideCanalInput(rowEventForm) || i === 0 }"
                    [icon]="PrimeNgIcons.MINUS"
                    [id]="'events_form_group-delete-' + i"
                    (onClick)="onDeleteEvent(i)"
                    [text]="true"
                    size="small"
                    pTooltip="Delete point (Ctrl + G)"
                    tooltipPosition="top"
                  ></p-button>
                  <p-button
                    *ngIf="i"
                    [ngClass]="{ hidden: hideCanalInput(rowEventForm) }"
                    [icon]="PrimeNgIcons.SHARE_ALT"
                    [id]="'events_form_group-show_route-' + i"
                    (onClick)="onShowRoute(rowEventForm)"
                    size="small"
                    [text]="true"
                    pTooltip="Show route/Edit canals"
                    tooltipPosition="top"
                  ></p-button>
                  <p-button
                    *ngIf="!disablePoint(rowEventForm)"
                    [ngClass]="{ hidden: hideCanalInput(rowEventForm) }"
                    [icon]="PrimeNgIcons.MAP_MARKER"
                    [id]="'events_form_group-add-point' + i"
                    (onClick)="onAddPoint(rowEventForm, i)"
                    size="small"
                    [text]="true"
                    pTooltip="Set Lat/lon"
                    tooltipPosition="top"
                  ></p-button
                  >
                </ng-container>
              </div>
              <!-- some double in estimator-shiplist-zones-ports-filter, vms-agreement-form -->
              <p-autoComplete
                *ngIf="!showPoint(rowEventForm)"
                [formControl]="rowEventForm.controls.port"
                [suggestions]="ports"
                [forceSelection]="true"
                [minLength]="3"
                (completeMethod)="onSearchPort($event)"
                (onSelect)="onSelectPort($event, rowEventForm)"
                (onClear)="onClearPort($event, rowEventForm)"
                appendTo="body"
                optionLabel="name"
                dataKey="id"
                class="p-inputtext-sm w-full event-actions-input"
                styleClass="w-full"
                inputStyleClass="w-full text-right"
                [inputId]="EVENTS_FORM_GROUP_PORT + (rowEventForm?.controls?.port?.value?.id || i)"
                [tabindex]="getColumnTabIndex(col)"
                estimatorSwitchTabIndex
              >
                <ng-template let-port pTemplate="item">
                  <div class="flex dropdown-option content-space-between">
                    <span
                    ><span class="font-weight-700">{{ port?.name }}</span
                    >&nbsp;/&nbsp;{{ port?.code }}&nbsp;&nbsp;</span
                    >
                    <span class="fi fi-{{ port.alpha_2?.toLowerCase() }}"></span>
                  </div>
                </ng-template>
              </p-autoComplete>
              <div
                *ngIf="showPoint(rowEventForm)"
                class="font-size-9 w-full event-actions-input mr-1 flex justify-content-end align-items-center port-point"
              >
                <span class="opacity-60">{{ textForPoint(rowEventForm) }} </span>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="col.field === EventTableColumnType.Type">
            <p-dropdown
              [ngClass]="{ 'display-none': hideCanalInput(rowEventForm) }"
              [options]="eventTypeOptions"
              [placeholder]="getEventTypePlaceholder(rowEventForm, i)"
              [formControl]="getTypeControl(rowEventForm)"
              (onChange)="
                onEventTypeChange(
                  $event,
                  rowEventForm,
                  i,
                  rowEventForm?.controls?.single_inner_event?.controls?.cargo_id?.value
                )
              "
              appendTo="body"
              class="p-inputtext-sm hide-dropdown-button w-full"
              styleClass="w-full"
              [inputId]="'events_form_group-type-' + i"
              [tabindex]="getColumnTabIndex(col)"
            >
              <ng-template let-type pTemplate="item">
                {{ getEventType(type, i) | titlecase }}
              </ng-template>
              <ng-template let-type pTemplate="selectedItem">
                {{ getEventType(type, i) | titlecase }}
              </ng-template>
            </p-dropdown>
            <span *ngIf="showWarningSize(rowEventForm)" class="font-size-10">
              Check vessel measurements
            </span>
          </ng-container>
          <ng-container *ngIf="col.field === EventTableColumnType.Cargo">
            <span
              [ngClass]="{
                'show-invalid': true
              }"
            >
              <p-dropdown
                [ngClass]="{
                  hidden:
                    hideCargoInput(rowEventForm) ||
                    hideCanalInput(rowEventForm) ||
                    equalBunkerInput(rowEventForm)
                }"
                placeholder=""
                [options]="cargoes"
                [formControl]="
                  rowEventForm.controls.single_inner_event?.controls?.cargo_id || mockFormControl
                "
                (onChange)="onChangeCargoId($event, rowEventForm)"
                appendTo="body"
                optionLabel="internal_order"
                optionValue="id"
                class="p-inputtext-sm w-full hide-dropdown-button"
                styleClass="w-full"
                [inputId]="'events_form_group-cargo_id-' + i"
                [tabindex]="getColumnTabIndex(col)"
              >
                <ng-template let-selectedCargo pTemplate="selectedItem">
                  {{ getSelectedCargoOrderValue(selectedCargo?.internal_order) }}
                </ng-template>
                <ng-template let-cargo pTemplate="item"> {{ cargo.internal_order }} </ng-template>
              </p-dropdown>
            </span>
          </ng-container>
          <ng-container *ngIf="col.field === EventTableColumnType.Preset">
            <p-dropdown
              (onChange)="
                editedTypeFalse();
                changePortFalse();
                changePresetInEvent($event, rowEventForm.controls.meta.controls.preset?.value?.name)
              "
              [ngClass]="{ hidden: i === 0 || hideCanalInput(rowEventForm) }"
              [options]="presets"
              [formControl]="rowEventForm.controls.meta.controls.preset"
              appendTo="body"
              placeholder="Speed"
              optionLabel="name"
              dataKey="name"
              class="p-inputtext-sm hide-dropdown-button w-full"
              styleClass="w-full"
              [inputId]="'events_form_group-preset-' + i"
              [tabindex]="getColumnTabIndex(col)"
            >
              <ng-template pTemplate="selectedItem">
                {{ getPresetName(rowEventForm.controls.meta.controls.preset.value) }}
              </ng-template>
            </p-dropdown>
          </ng-container>
          <ng-container *ngIf="col.field === EventTableColumnType.WeatherFactor">
            <p-inputNumber
              (ngModelChange)="
                editedTypeFalse();
                changePortFalse();
                onChangeWF(rowEventForm.controls.meta.controls.weather_factor)
              "
              [ngClass]="{ hidden: i === 0 }"
              [useGrouping]="false"
              [minFractionDigits]="0"
              [maxFractionDigits]="2"
              [min]="0"
              [step]="0"
              [formControl]="rowEventForm.controls.meta.controls.weather_factor"
              class="p-inputtext-sm w-full"
              styleClass="w-full"
              inputStyleClass="w-full"
              [inputId]="'events_form_group-weather_factor-' + i"
              suffix="%"
              estimatorDecimals
              [tabindex]="getColumnTabIndex(col)"
            ></p-inputNumber>
          </ng-container>
          <ng-container *ngIf="col.field === EventTableColumnType.Eca">
            <p-checkbox
              [ngClass]="{
                hidden: i === 0 || hideCanalInput(rowEventForm)
              }"
              [formControl]="rowEventForm.controls.meta.controls.use_eca"
              [binary]="true"
              [inputId]="'events_form_group-use_eca-' + i"
              (onChange)="blurCheckbox(ecaCheckBox)"
              class="small-checkbox eca-checkbox"
              #ecaCheckBox
            ></p-checkbox>
          </ng-container>
          <ng-container *ngIf="col.field === EventTableColumnType.Distance">
            <div
              [ngClass]="{ hidden: i === 0, 'shift-distance': i > 0 }"
              class="h-22px m-auto flex-container-column align-items-center"
            >
              <span
                class="h-12px elipsis font-size-9 white-content"
                [id]="'events-form-distance-' + i"
              >
                {{ getDistance(rowEventForm, 'distance') || 0 | number : '.0-2' }}
              </span>
              <span
                class="h-12px elipsis font-size-9 white-content"
                [id]="'events-form-eca-distance-' + i"
              >
                ({{ getDistance(rowEventForm, 'eca_distance') || 0 | number : '.0-2' }})
              </span>
            </div>
          </ng-container>
          <ng-container *ngIf="col.field === EventTableColumnType.Amount">
            <span
              [ngClass]="{
                'show-invalid': true
              }"
            >
              <p-inputNumber
                [ngClass]="{
                  'display-none':
                    hideCargoInput(rowEventForm) ||
                    hideCanalInput(rowEventForm) ||
                    equalBunkerInput(rowEventForm)
                }"
                [useGrouping]="false"
                [minFractionDigits]="0"
                [maxFractionDigits]="2"
                [min]="0"
                [max]="MAX_FINANCE_VALUE"
                [step]="0"
                [formControl]="
                  rowEventForm.controls.single_inner_event?.controls?.meta?.controls?.amount ||
                  mockFormControl
                "
                (ngModelChange)="onChangeAmount(rowEventForm)"
                class="p-inputtext-sm w-full"
                styleClass="w-full"
                inputStyleClass="w-full"
                [inputId]="'events_form_group-amount-' + i"
                placeholder="Quantity"
                estimatorDecimals
                [tabindex]="getColumnTabIndex(col)"
              ></p-inputNumber>
            </span>
            <span *ngIf="equalBunkerInput(rowEventForm)" class="w-full flex gap-2 p-inputgroup">
              <p-inputNumber
                *ngIf="equalBunkerInput(rowEventForm) && useUlsfo"
                (ngModelChange)="editedTypeFalse(); changePortFalse()"
                [useGrouping]="false"
                [minFractionDigits]="0"
                [maxFractionDigits]="2"
                [min]="0"
                [step]="0"
                [formControl]="
                  rowEventForm.controls.single_inner_event?.controls?.meta?.controls?.ulsfo_bunker
                "
                class="p-inputtext-sm w-full bob-input"
                styleClass="w-full"
                inputStyleClass="w-full"
                [inputId]="'events_form_group-ulsfo_bunker-' + i"
                [placeholder]="FuelTypes.ULSFO"
                estimatorDecimals
                [tabindex]="getColumnTabIndex(col)"
              ></p-inputNumber>
              <p-inputNumber
                *ngIf="
                  equalBunkerInput(rowEventForm) &&
                  (!scrubber || highPurityFuelNameFromFirstEvent === FuelTypes.VLSFO)
                "
                (ngModelChange)="editedTypeFalse(); changePortFalse()"
                [useGrouping]="false"
                [minFractionDigits]="0"
                [maxFractionDigits]="2"
                [min]="0"
                [step]="0"
                [formControl]="
                  rowEventForm.controls.single_inner_event?.controls?.meta?.controls
                    ?.residual_bunker
                "
                class="p-inputtext-sm w-full bob-input"
                styleClass="w-full"
                inputStyleClass="w-full"
                [inputId]="'events_form_group-residual_bunker-' + i"
                [placeholder]="FuelTypes.VLSFO"
                estimatorDecimals
                [tabindex]="getColumnTabIndex(col)"
              ></p-inputNumber>
              <p-inputNumber
                *ngIf="equalBunkerInput(rowEventForm) && scrubber"
                (ngModelChange)="editedTypeFalse(); changePortFalse()"
                [useGrouping]="false"
                [minFractionDigits]="0"
                [maxFractionDigits]="2"
                [min]="0"
                [step]="0"
                [formControl]="
                  rowEventForm.controls.single_inner_event?.controls?.meta?.controls?.hsfo_bunker
                "
                class="p-inputtext-sm w-full bob-input"
                styleClass="w-full"
                inputStyleClass="w-full"
                [inputId]="'events_form_group-hsfo_bunker-' + i"
                [placeholder]="FuelTypes.HSFO"
                estimatorDecimals
                [tabindex]="getColumnTabIndex(col)"
              ></p-inputNumber>
              <p-inputNumber
                *ngIf="equalBunkerInput(rowEventForm)"
                (ngModelChange)="editedTypeFalse(); changePortFalse()"
                [useGrouping]="false"
                [minFractionDigits]="0"
                [maxFractionDigits]="2"
                [min]="0"
                [step]="0"
                [formControl]="
                  rowEventForm.controls.single_inner_event?.controls?.meta?.controls
                    ?.distillate_bunker
                "
                class="p-inputtext-sm w-full bob-input"
                styleClass="w-full"
                inputStyleClass="w-full"
                [inputId]="'events_form_group-distillate_bunker-' + i"
                [placeholder]="FuelTypes.LSMGO"
                estimatorDecimals
                [tabindex]="getColumnTabIndex(col)"
              ></p-inputNumber>
            </span>
          </ng-container>
          <ng-container *ngIf="col.field === EventTableColumnType.WorkAmount">
            <span
              class="w-full flex p-inputgroup"
              [ngClass]="{
                'show-invalid': true
              }"
            >
              <p-inputNumber
                (ngModelChange)="editedTypeFalse(); changePortFalse()"
                [ngClass]="{
                  hidden:
                    hideCargoInput(rowEventForm) ||
                    hideCanalInput(rowEventForm) ||
                    equalBunkerInput(rowEventForm)
                }"
                [useGrouping]="false"
                [minFractionDigits]="0"
                [maxFractionDigits]="2"
                [min]="0"
                [max]="MAX_FINANCE_VALUE"
                [step]="0"
                [formControl]="
                  rowEventForm.controls.single_inner_event?.controls?.meta?.controls?.work_amount ||
                  mockFormControl
                "
                class="p-inputtext-sm w-full ld-speed-input"
                styleClass="w-full"
                inputStyleClass="w-full none-border-radius-right border-right-0"
                [inputId]="'events_form_group-work_amount-' + i"
                estimatorDecimals
                [tabindex]="getColumnTabIndex(col)"
              ></p-inputNumber>
              <p-dropdown
                (onChange)="editedTypeFalse(); changePortFalse()"
                [ngClass]="{
                  hidden:
                    hideCargoInput(rowEventForm) ||
                    hideCanalInput(rowEventForm) ||
                    equalBunkerInput(rowEventForm),
                  'red-border':
                    rowEventForm.controls.single_inner_event?.controls?.meta?.controls?.work_amount
                      .errors
                }"
                [options]="workTypeOptions"
                [formControl]="
                  rowEventForm.controls.single_inner_event?.controls?.meta?.controls?.work_type ||
                  mockFormControl
                "
                appendTo="body"
                styleClass="w-full zero-border-radius-left none-border-radius-left border-left-0 remove-inside-border"
                class="p-inputtext-sm hide-dropdown-button w-full ld-speed-dropdown"
                [inputId]="'events_form_group-work_type-' + i"
              >
              </p-dropdown>
            </span>
          </ng-container>
          <ng-container *ngIf="col.field === EventTableColumnType.Terms">
            <span class="flex">
              <p-dropdown
                (onChange)="editedTypeFalse(); changePortFalse()"
                [ngClass]="{
                  hidden:
                    hideCargoInput(rowEventForm) ||
                    hideCanalInput(rowEventForm) ||
                    equalBunkerInput(rowEventForm)
                }"
                [options]="cargoRules"
                [formControl]="
                  rowEventForm.controls.single_inner_event?.controls?.meta?.controls?.terms_id ||
                  mockFormControl
                "
                appendTo="body"
                placeholder="Terms"
                styleClass="w-full"
                class="p-inputtext-sm hide-dropdown-button w-70 max-width-70"
                optionLabel="name"
                optionValue="id"
                [inputId]="'events_form_group-terms_id-' + i"
                [tabindex]="getColumnTabIndex(col)"
              >
                <ng-template let-rule pTemplate="item">
                  {{ rule.name }} {{ rule.coefficient ? (rule.coefficient | number : '.0-2') : '' }}
                </ng-template>
              </p-dropdown>
              <div
                [ngClass]="{
                  hidden:
                    hideCargoInput(rowEventForm) ||
                    hideCanalInput(rowEventForm) ||
                    equalBunkerInput(rowEventForm) ||
                    getTermsCoefficient(rowEventForm) === 0
                }"
                class="w-30 px-1 m-auto"
              >
                <span class="h-12px elipsis font-size-9 white-content">
                  {{ getTermsCoefficient(rowEventForm) | number : '.0-2' }}
                </span>
              </div>
            </span>
          </ng-container>
          <ng-container *ngIf="col.field === EventTableColumnType.UsingCranes">
            <p-checkbox
              [ngClass]="{
                hidden:
                  hideCargoInput(rowEventForm) ||
                  hideCanalInput(rowEventForm) ||
                  equalBunkerInput(rowEventForm)
              }"
              [formControl]="
                rowEventForm.controls.single_inner_event?.controls.meta.controls
                  .using_cranes_on_vessel
              "
              [binary]="true"
              [inputId]="'events_form_group-using_cranes_on_vessel-' + i"
              (onChange)="blurCheckbox(cranesCheckBox)"
              class="small-checkbox using-cranes-checkbox"
              #cranesCheckBox
            ></p-checkbox>
          </ng-container>
          <ng-container *ngIf="col.field === EventTableColumnType.UseFuelType">
            <estimator-button-switch
              [disabled]="isFixed"
              [options]="fuelTypesExtendedMock"
              [ngClass]="{ hidden: hideEventInput(rowEventForm, i) }"
              [tabindex]="getColumnTabIndex(col)"
              [value]="getUseFuelTypeControl(rowEventForm).value"
              [id]="'events_form_group_fuel_type_button_' + i"
              (onChange)="
                editedTypeFalse(); changePortFalse(); onFuelTypeChange($event, rowEventForm)
              "
              optionLabel="short_name"
              optionValue="value"
            >
            </estimator-button-switch>
          </ng-container>
          <!-- <ng-container *ngIf="col.field === EventTableColumnType.UseFuelType">
            <p-dropdown
              (onChange)="editedTypeFalse(); changePortFalse()"
              [ngClass]="{ hidden: hideEventInput(rowEventForm, i) }"
              [options]="fuelTypesMock"
              [formControl]="getUseFuelTypeControl(rowEventForm)"
              appendTo="body"
              placeholder="Fuel type"
              class="p-inputtext-sm hide-dropdown-button w-full"
              styleClass="w-full"
              [inputId]="'events_form_group-preset-' + i"
              [tabindex]="getColumnTabIndex(col)"
            >
              <ng-template pTemplate="selectedItem">
                {{ getUseFuelTypeControl(rowEventForm).value }}
              </ng-template>
            </p-dropdown>
          </ng-container> -->
          <ng-container *ngIf="col.field === EventTableColumnType.ExtraMinutesBefore">
            <estimator-user-duration-input
              (changeValue)="editedTypeFalse(); changePortFalse()"
              [ngClass]="{ hidden: hideEventInput(rowEventForm, i) }"
              [control]="getExtraTimeControl(rowEventForm, 'extra_minutes_before')"
              [id]="'events_form_group-extra_minutes_before-' + i"
              [customTabIndex]="getColumnTabIndex(col)"
            ></estimator-user-duration-input>
          </ng-container>
          <ng-container *ngIf="col.field === EventTableColumnType.ExtraMinutesAfter">
            <estimator-user-duration-input
              (changeValue)="editedTypeFalse(); changePortFalse()"
              [ngClass]="{ hidden: hideEventInput(rowEventForm, i) }"
              [control]="getExtraTimeControl(rowEventForm, 'extra_minutes_after')"
              [id]="'events_form_group-extra_minutes_after-' + i"
              [customTabIndex]="getColumnTabIndex(col)"
            ></estimator-user-duration-input>
          </ng-container>
          <ng-container *ngIf="col.field === EventTableColumnType.Disbursement">
            <!--  <p-inputNumber
              (ngModelChange)="editedTypeFalse()"
              [ngClass]="{ hidden: hideEventInput(rowEventForm, i) }"
              [useGrouping]="false"
              [minFractionDigits]="0"
              [maxFractionDigits]="2"
              [min]="0"
              [formControl]="rowEventForm.controls.meta.controls.disbursement"
              class="p-inputtext-sm da-input w-full"
              styleClass="w-full"
              inputStyleClass="w-full"
              [inputId]="'events_form_group-disbursement-' + i"
              estimatorDecimals
              [tabindex]="getColumnTabIndex(col)"
            ></p-inputNumber> -->
            <div class="relative">
              <estimator-price-and-currency-input
                [ngClass]="{ hidden: hideEventInput(rowEventForm, i) }"
                [id]="'events_form_group-disbursement-' + i"
                [price]="rowEventForm.controls.meta.controls.disbursement.value"
                [priceFormControl]="rowEventForm.controls.meta.controls.disbursement"
                [customTabIndex]="getColumnTabIndex(col)"
                [showCurrency]="false"
                [setNull]="true"
                [max]="MAX_FINANCE_VALUE"
                [placeholderRight]="true"
                placeholder="{{ getDAPlaceholder(rowEventForm) }}"
                invalidName="{{ getDAInvalidName(rowEventForm, i) }}"
                (priceHasChangeAndNotZero)="editedTypeFalse(); changePortFalse()"
              ></estimator-price-and-currency-input>
              <i
                [class]="PrimeNgIcons.INFO_CIRCLE"
                class="absolute port-disbursement cursor-pointer"
                pTooltip="Open port's DA info"
                (click)="onOpenPortDisbursementsModal(rowEventForm)"
                [ngClass]="{
                  hidden: hideEventInput(rowEventForm, i),
                  'pointer-events-none': isFixed || portDaDisabled(rowEventForm)
                }"
                id="port_disbursement_{{ i }}"
              >
              </i>
            </div>
          </ng-container>
          <ng-container *ngIf="col.field === EventTableColumnType.DisbursementCurrency">
            <p-dropdown
              (onChange)="editedTypeFalse(); changePortFalse()"
              [ngClass]="{ hidden: hideEventInput(rowEventForm, i) }"
              [options]="currencies"
              [formControl]="
                rowEventForm.controls.meta.controls.disbursement_currency || mockFormControl
              "
              appendTo="body"
              optionLabel="currency_code"
              styleClass="w-full"
              class="p-inputtext-sm hide-dropdown-button da-dropdown"
              [inputId]="'events_form_group-work_type-' + i"
              [tabindex]="getColumnTabIndex(col)"
            >
            </p-dropdown>
          </ng-container>
          <ng-container *ngIf="col.field === EventTableColumnType.StartTime">
            <p-calendar
              [dateFormat]="dateFormat"
              (onChange)="editedTypeFalse(); changePortFalse()"
              [formControl]="rowEventForm.controls.start_time || mockFormControl"
              [showTime]="true"
              [inputId]="'events_form_group-start_time-' + i"
              [hideOnDateTimeSelect]="false"
              styleClass="w-full"
              inputStyleClass="w-full"
              class="p-inputtext-sm w-full"
              dateFormat="dd.mm.y"
              appendTo="body"
              [tabindex]="getColumnTabIndex(col)"
            ></p-calendar>
          </ng-container>
          <ng-container *ngIf="col.field === EventTableColumnType.SailingMinutes">
            <div
              [ngClass]="{ hidden: i === 0 }"
              class="h-22px m-auto flex align-items-center content-center"
            >
              <span class="h-12px elipsis font-size-9 white-content">
                {{
                  getSailingMinutes(rowEventForm, 'sailing_minutes') || 0 | duration : 'd' : 2 : true
                }}
              </span>
              /
              <span class="h-12px elipsis font-size-9 white-content">
                {{
                  getSailingMinutes(rowEventForm, 'port_time') || 0 | duration : 'd' : 2 : true
                }}
              </span>
            </div>
          </ng-container>
          <ng-container *ngIf="col.field === EventTableColumnType.ResidualRob">
            <span class="w-full flex gap-robs" #robs>
              <p-inputNumber
                *ngIf="useUlsfo"
                (ngModelChange)="editedTypeFalse(); changePortFalse()"
                [useGrouping]="false"
                [minFractionDigits]="0"
                [maxFractionDigits]="2"
                [min]="0"
                [step]="0"
                [formControl]="getRobsControl(rowEventForm, i, 'ulsfo_rob') || mockFormControl"
                [placeholder]="FuelTypes.ULSFO"
                class="p-inputtext-sm w-full bob-input"
                styleClass="w-full"
                inputStyleClass="w-full"
                [inputId]="'events_form_group-ulsfo_rob-' + i"
                estimatorDecimals
                [tabindex]="getColumnTabIndex(col)"
              ></p-inputNumber>
              <p-inputNumber
                *ngIf="!scrubber || highPurityFuelNameFromFirstEvent === FuelTypes.VLSFO"
                (ngModelChange)="editedTypeFalse(); changePortFalse()"
                [useGrouping]="false"
                [minFractionDigits]="0"
                [maxFractionDigits]="2"
                [min]="0"
                [step]="0"
                [formControl]="getRobsControl(rowEventForm, i, 'residual_rob') || mockFormControl"
                [placeholder]="FuelTypes.VLSFO"
                class="p-inputtext-sm w-full bob-input"
                styleClass="w-full"
                inputStyleClass="w-full"
                [inputId]="'events_form_group-residual_rob-' + i"
                estimatorDecimals
                [tabindex]="getColumnTabIndex(col)"
              ></p-inputNumber>
              <p-inputNumber
                *ngIf="scrubber"
                (ngModelChange)="editedTypeFalse(); changePortFalse()"
                [useGrouping]="false"
                [minFractionDigits]="0"
                [maxFractionDigits]="2"
                [min]="0"
                [step]="0"
                [formControl]="getRobsControl(rowEventForm, i, 'hsfo_rob') || mockFormControl"
                [placeholder]="FuelTypes.HSFO"
                class="p-inputtext-sm w-full bob-input"
                styleClass="w-full"
                inputStyleClass="w-full"
                [inputId]="'events_form_group-hsfo_rob-' + i"
                estimatorDecimals
                [tabindex]="getColumnTabIndex(col)"
              ></p-inputNumber>
              <p-inputNumber
                (ngModelChange)="editedTypeFalse(); changePortFalse()"
                [useGrouping]="false"
                [minFractionDigits]="0"
                [maxFractionDigits]="2"
                [min]="0"
                [step]="0"
                [formControl]="getRobsControl(rowEventForm, i, 'distillate_rob') || mockFormControl"
                [placeholder]="FuelTypes.LSMGO"
                class="p-inputtext-sm w-full bob-input"
                styleClass="w-full"
                inputStyleClass="w-full"
                [inputId]="'events_form_group-distillate_rob-' + i"
                estimatorDecimals
                [tabindex]="getColumnTabIndex(col)"
              ></p-inputNumber>
            </span>
          </ng-container>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <div class="flex gap-2 py-1 white-content">
    <div class="flex ml-auto">
      Days ballast/laden&nbsp; <b>{{ ballastTime | duration : 'd' : 2 }}</b> /
      <b>{{ ladenTime | duration : 'd' : 2 }}</b>
    </div>
    <div class="flex" *ngIf="consumptions.length">
      Consumed:&nbsp;
      <div *ngFor="let consumption of consumptions">
        <span *ngIf="consumption.amount">
          {{ consumption.fuel?.name || NOT_APPLICABLE }}:&nbsp;
          <b>{{ consumption.amount | number : '.0-2' }}</b>
        </span>
      </div>
    </div>
  </div>
</div>
