import { ViewportScroller } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { ButtonParams, Fuel, PrimeNgColors, Vessel, ZERO_STRING } from '@estimator/models';
import {
  ColDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
  RowGroupOpenedEvent,
} from 'ag-grid-community';
import { AgGridVesselDetailsComponent } from '../ag-grid-shared/ag-grid-vessel-details/ag-grid-vessel-details.component';
import { AgGridButtonGroupComponent } from '../ag-grid-shared/button-group.component';

@Component({
  selector: 'estimator-vessels-list',
  templateUrl: './vessels-list.component.html',
  styleUrls: ['./vessels-list.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VesselsListComponent {
  @Input() vessels: Vessel[] = [];
  @Input() fuels: Fuel[] = [];
  @Input()
  set isLoading(bool: boolean) {
    this._isLoading = bool;
    if (this._gridApi && bool) {
      this._gridApi.showLoadingOverlay();
    } else if (this._gridApi && !bool) {
      this._gridApi.hideOverlay();
    }
  }
  get isLoading(): boolean {
    return this._isLoading;
  }
  @Output() saveVessel = new EventEmitter<Vessel>();
  @Output() deleteVessel = new EventEmitter<number>();
  gridHeaderActionButtons: ButtonParams[] = [
    {
      title: 'Add vessel',
      tooltip: 'Add vessel',
      icon: 'add',
      color: PrimeNgColors.White,
      click: () => this.onAddVessel(),
    },
  ];
  columnDefs: ColDef<Vessel>[] = [
    {
      headerComponent: 'buttonRenderer',
      headerComponentParams: (params: any) => {
        return {
          ...params,
          buttons: this.gridHeaderActionButtons,
        };
      },
      cellRenderer: 'buttonRenderer',
      cellRendererParams: (params: any) => {
        return {
          ...params,
          buttons: [
            {
              title: 'Delete vessel',
              tooltip: 'Delete vessel',
              icon: 'delete',
              click: () => this.onDeleteVessel(params.data),
            },
          ],
        };
      },
      cellClass: 'center-aligned-cell',
      minWidth: 36,
      width: 36,
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 2,
      cellRenderer: 'agGroupCellRenderer',
    },
    {
      field: 'flag',
      headerName: 'Flag',
      flex: 1,
    },
    {
      field: 'type',
      headerName: 'Type',
      flex: 1,
    },
    {
      field: 'imo',
      headerName: 'IMO',
      flex: 1,
    },
    {
      field: 'mmsi',
      headerName: 'MMSI',
      flex: 1,
    },
    {
      field: 'build_year',
      headerName: 'Build year',
      flex: 1,
    },
    {
      field: 'dead_weight',
      headerName: 'DWT',
      flex: 1,
    },
    {
      field: 'draught',
      headerName: 'Draught',
      flex: 1,
    },
    /* {
      field: 'owner',
      headerName: 'Owner',
      flex: 1,
    }, */
  ];
  gridOptions: GridOptions<Vessel> = {
    rowModelType: 'clientSide',
    columnDefs: this.columnDefs,
    components: {
      buttonRenderer: AgGridButtonGroupComponent,
    },
    getRowId: (params) => params.data?.id?.toString() || ZERO_STRING,
    defaultColDef: {
      editable: false,
      resizable: true,
    },
    suppressContextMenu: true,
    suppressScrollOnNewData: true,
    singleClickEdit: true,
    rowSelection: 'single',
    masterDetail: true,
    detailCellRenderer: AgGridVesselDetailsComponent,
    detailCellRendererParams: (params: any) => {
      return {
        ...params,
        onSaveVessel: this.onSaveVessel.bind(this),
        fuels: this.fuels,
      };
    },
    detailRowAutoHeight: true,
    animateRows: true,
    rowHeight: 40,
    headerHeight: 40,
    autoSizePadding: 0,
    suppressCellFocus: true,
    onRowGroupOpened: (event: RowGroupOpenedEvent) => {
      if (event.node.rowIndex) {
        const agGridViewport = document.querySelector('.ag-body-viewport') as HTMLElement;
        if (event.expanded) {
          agGridViewport.style.paddingBottom = '160px';
          agGridViewport.scrollTo({
            top: event.node.rowTop || 0,
            behavior: 'smooth',
          });
          if (event.node.rowIndex >= this.vessels.length - 7) {
            agGridViewport.style.paddingBottom = '160px';
          }
        }
        agGridViewport.style.paddingBottom = ZERO_STRING;
      }
    },
  };
  private _gridApi?: GridApi<Vessel>;
  private _isLoading = false;
  private _isNotSavedVessel = false;

  constructor(private viewportScroller: ViewportScroller) {}

  onGridReady({ api }: GridReadyEvent): void {
    this._gridApi = api;
  }

  onAddVessel(): void {
    if (this._isNotSavedVessel) {
      return;
    }
    this._isNotSavedVessel = true;
    this._gridApi?.applyTransaction({ add: [{ id: 0, name: '' } as Vessel] });
    const row = this._gridApi?.getRowNode(ZERO_STRING);
    if (row) {
      this._gridApi?.setRowNodeExpanded(row, true);
    }
  }

  onSaveVessel(vessel: Vessel): void {
    this.saveVessel.emit(vessel);
    this._isNotSavedVessel = false;
  }

  onDeleteVessel(vessel: Vessel): void {
    if (vessel?.id) {
      this.deleteVessel.emit(vessel?.id);
      return;
    }
    if (this._gridApi) {
      this._isNotSavedVessel = false;
      this._gridApi.applyTransaction({ remove: [vessel] });
    }
  }
}
