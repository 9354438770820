import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BasicResponse,
  ChangeUserBlocked,
  ChangeUserRole,
  ChangeUserServiceTypes,
  CompanyInformation,
  FullCompanyInformation,
  GetCompanyAvailableServices,
  GetCompanyDeductiblesResponse,
  LoginProps,
  RegisterProps,
  ResponseChangeUser,
  SystemTag,
  TEXT_COMPANY_INVITATION,
  UiSettingsStateModel,
  UserInformation,
  XAuthLoginResponse,
  XAuthRegisterResponse,
} from '@estimator/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class XAuthService {
  readonly path = '/xauth';
  readonly pathConfirmation = 'confirmation';
  readonly pathCompanies = 'companies';
  readonly pathMy = 'my';
  constructor(protected readonly http: HttpClient) {}

  login(props: LoginProps): Observable<XAuthLoginResponse> {
    return this.http.post<XAuthLoginResponse>(`${this.path}/login`, props);
  }

  refreshToken(refresh_token: string): Observable<XAuthLoginResponse> {
    return this.http.post<XAuthLoginResponse>(`${this.path}/refresh`, { refresh_token });
  }

  register(props: RegisterProps): Observable<XAuthRegisterResponse> {
    return this.http.post<XAuthRegisterResponse>(`${this.path}/register`, props);
  }

  retryEmail(email: string): Observable<BasicResponse> {
    return this.http.post<BasicResponse>(`${this.path}/${this.pathConfirmation}/resend`, { email });
  }

  confirmUser(token: string): Observable<XAuthLoginResponse> {
    let headers = new HttpHeaders();
    headers = headers.set('Token', token);
    return this.http.get<XAuthLoginResponse>(`${this.path}/${this.pathConfirmation}/stream`, {
      headers,
    });
  }

  confirmEmail(token: string): Observable<BasicResponse> {
    return this.http.get<BasicResponse>(`${this.path}/${this.pathConfirmation}/url`, {
      params: { token },
    });
  }

  confirmInvitation(token: string): Observable<XAuthLoginResponse> {
    return this.http.get<XAuthLoginResponse>(
      `${this.path}/${this.pathConfirmation}/${TEXT_COMPANY_INVITATION}`,
      { params: { token } }
    );
  }

  confirmUserByCode(token: string, code: string): Observable<BasicResponse> {
    let headers = new HttpHeaders();
    headers = headers.set('Token', token);
    return this.http.post<BasicResponse>(
      `${this.path}/${this.pathConfirmation}/code`,
      { code },
      { headers }
    );
  }

  resetPassword(email: string, system: SystemTag): Observable<BasicResponse> {
    return this.http.post<BasicResponse>(`${this.path}/password/reset`, { email, system });
  }

  updatePassword(password: string, resetToken?: string): Observable<BasicResponse> {
    let headers = new HttpHeaders();
    if (resetToken) {
      headers = headers.set('Reset', resetToken);
    }
    return this.http.post<BasicResponse>(`${this.path}/password/update`, { password }, { headers });
  }

  getFullCompanyInformation(): Observable<FullCompanyInformation> {
    return this.http.get<FullCompanyInformation>(
      `${this.path}/${this.pathCompanies}/${this.pathMy}`
    );
  }

  getCompanyAvailableServices(): Observable<GetCompanyAvailableServices> {
    return this.http.get<GetCompanyAvailableServices>(
      `${this.path}/${this.pathCompanies}/available-service-types`
    );
  }

  changeUserBlocked(object: ChangeUserBlocked): Observable<void> {
    return this.http.post<void>(`${this.path}/${this.pathCompanies}/set-user-blocked`, {
      user_email: object.userEmail,
      blocked: object.blocked,
    });
  }

  changeUserRole(object: ChangeUserRole): Observable<ResponseChangeUser> {
    return this.http.post<ResponseChangeUser>(`${this.path}/${this.pathCompanies}/set-user-role`, {
      user_email: object.userEmail,
      user_role: object.userRole,
    });
  }

  changeUserServiceTypes(object: ChangeUserServiceTypes): Observable<ResponseChangeUser> {
    return this.http.post<ResponseChangeUser>(
      `${this.path}/${this.pathCompanies}/set-user-service-types`,
      {
        user_email: object.userEmail,
        service_types: object.serviceTypes,
      }
    );
  }

  changeCompanyAdmin(administratorId: number): Observable<CompanyInformation> {
    return this.http.post<CompanyInformation>(
      `${this.path}/${this.pathCompanies}/change-administrator`,
      {
        administrator_id: administratorId,
      }
    );
  }

  inviteUser(user_email: string): Observable<void> {
    return this.http.post<void>(`${this.path}/${this.pathCompanies}/invite-user`, { user_email });
  }

  cancelInviteUser(user_email: string): Observable<void> {
    return this.http.post<void>(`${this.path}/${this.pathCompanies}/cancel-user-invitation`, {
      user_email,
    });
  }

  updateCompany(companyInformation: CompanyInformation): Observable<CompanyInformation> {
    return this.http.put<CompanyInformation>(
      `${this.path}/${this.pathCompanies}/${this.pathMy}/information`,
      companyInformation
    );
  }

  updateUserInfo(userInformation: UserInformation): Observable<ResponseChangeUser> {
    return this.http.post<ResponseChangeUser>(
      `${this.path}/${this.pathCompanies}/set-user-info`,
      userInformation
    );
  }

  setSettings(settings: UiSettingsStateModel): Observable<UiSettingsStateModel> {
    return this.http.post<UiSettingsStateModel>(`${this.path}/users/settings`, settings);
  }

  getCompanyDeductibles(): Observable<GetCompanyDeductiblesResponse> {
    return this.http.get<GetCompanyDeductiblesResponse>(
      `${this.path}/user-${this.pathCompanies}/${this.pathMy}`
    );
  }
}
