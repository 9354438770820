import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HireFinanceListComponent } from './hire-finance-list.component';
import { AgGridModule } from 'ag-grid-angular';
import { DurationPipe } from '@estimator/helpers';

@NgModule({
  declarations: [HireFinanceListComponent],
  imports: [AgGridModule, CommonModule],
  exports: [HireFinanceListComponent],
  providers: [DurationPipe],
})
export class HireFinanceListModule {}
