import { FormControl, FormGroup } from '@angular/forms';
import { FeatureCollection } from 'geojson';
import { BaseEntity, BaseEntityFormGroup, BaseModel } from './base-entity';
import { Currency } from './currency';
import { UserInputDuration } from './duration';
import { FuelConsumption } from './fuel-consumption';
import { GeoCoordinates } from './geographic';
import { Port } from './port';
import { ColumnUI, FuelTypes, PrimeNgIcons } from './ui-items';
import { Preset } from './vessel';

export interface LaytimeData {
  terms_id?: number;
  amount?: number;
  use_fuel_type?: string;
  /**
   * amount/ld_rate
   */
  working_minutes?: UserInputDuration;
  /**
   * TT (working time depends from terms)
   */
  idle_minutes?: UserInputDuration;
  // @deprecated
  extra_working_minutes?: UserInputDuration;
  work_amount?: number;
  work_type?: WorkType;
  using_cranes_on_vessel?: boolean;
  load_discharge_rate?: number;
  order?: number;
}

export interface DealMetaInfo {
  route_geojson?: FeatureCollection;
  distance?: number;
  /**
   * Используется на UI для отображения дистанций с выключенным отображением каналов
   */
  distance_with_canals?: number;
  /**
   * Используется на UI для отображения дистанций с выключенным отображением каналов
   */
  eca_distance_with_canals?: number;
  /**
   * Используется на UI для отображения дистанций с выключенным отображением каналов
   */
  sailing_minutes_with_canals?: UserInputDuration;
  /**
   * Используется на UI для отображения дистанций с выключенным отображением каналов
   */
  port_time_with_canals?: UserInputDuration;
  amount?: number;
  disbursement?: number;
  disbursement_currency?: Currency;
  // cargo_id?: number;
  fuel_id?: number;
  fuel_consumptions?: FuelConsumption[];
  /**
   * TT
   */
  extra_minutes_after?: UserInputDuration;
  /**
   * ET
   */
  extra_minutes_before?: UserInputDuration;
  // @deprecated
  extra_working_minutes?: UserInputDuration;
  idle_minutes?: UserInputDuration;
  load_discharge_rate?: number;
  order?: number;
  terms_id?: number;
  canals?: number[];
  current_canal_id?: number;
  current_canal_name?: string;
  internal_canal_sog?: number;
  internal_canal_distance?: number;
  internal_canal_distance_eca?: number;
  internal_canal_route_geojson?: FeatureCollection;
  internal_canal_sailing_minutes?: UserInputDuration;
  internal_canal_sailing_eca_minutes?: UserInputDuration;
  use_eca?: boolean;
  eca_distance?: number;
  port_idle_time?: number;
  port_time?: UserInputDuration;
  sailing_eca_minutes?: UserInputDuration;
  sailing_minutes?: UserInputDuration;
  // sog?: number;
  /*  time_zone?: number;
  time_zone_name?: string; */
  timezone_offset?: number;
  timezone_name?: string;
  weather_factor?: number;
  notice?: string;
  total_bunker?: number;
  total_discharge?: number;
  total_load?: number;
  preset?: Preset;
  work_type?: WorkType;
  work_amount?: number;
  working_minutes?: UserInputDuration;
  is_laden?: boolean;
  // rob
  /**
   * VLSFO
   */
  residual_rob?: number;
  hsfo_rob?: number;
  /**
   * LSMGO
   */
  distillate_rob?: number;
  ulsfo_rob?: number;
  // bunker
  /**
   * VLSFO
   */
  residual_bunker?: number;
  hsfo_bunker?: number;
  /**
   * LSMGO
   */
  distillate_bunker?: number;
  ulsfo_bunker?: number;
  using_cranes_on_vessel?: boolean;
  use_fuel_type?: string;
  /**
   * стоимость 1 дня Demurrage
   */
  demurrage_rate?: number;
  /**
   * стоимость 1 дня Despatch
   */
  despatch_rate?: number;
  /**
   * добавочные минуты, вводимые пользователем, тип UserInput. Работают так же, как и ТТ, ЕТ.
   */
  laytime_difference_minutes?: UserInputDuration;
  laytime_data?: LaytimeData;
  high_purity_fuel_name?: FuelTypes;
  high_purity_sailing_eca_minutes?: UserInputDuration;
}

export interface DealEvent extends BaseEntity {
  alternative_events?: number[];
  deal_id?: number;
  // end_point?: GeoCoordinates;
  end_time?: string | Date | null;
  group_id?: number;
  order?: number;
  parallel_with?: number | BaseModel;
  port?: Port;
  port_id?: number;
  routing_point_id?: number;
  point?: GeoCoordinates;
  start_time?: string | Date | null;
  type?: DealEventType;
  meta?: DealMetaInfo;
  nearest_node_id?: number;
  unique_row_id?: string;
  /**
   * Cargo и bunker ивенты с тем же group_id (используется на клиенте)
   */
  inner_events?: DealEvent[];
  /**
   * Единственный Cargo или bunker ивент с тем же group_id для simple режима (используется на клиенте)
   */
  single_inner_event?: DealEvent;
  cargo_id?: number;
}

export enum DealEventType {
  EventTypeStop = 'stop',
  EventTypeLoad = 'load',
  EventTypeDischarge = 'discharge',
  EventTypeBunker = 'bunker',
  EventTypeCanal = 'enter_canal',
}

export interface UserInputField {
  user?: number | null;
  system?: number;
  is_changed?: boolean;
}

export enum WorkType {
  Days = 'DAYS',
  Hours = 'HOURS',
  AmountPerDay = 'MT/D',
  AmountPerHour = 'MT/H',
}

export interface EventFormGroup extends BaseEntityFormGroup {
  alternative_events: FormControl<number[] | null>;
  deal_id: FormControl<number | null>;
  // end_point: FormControl<GeoCoordinates | null>;
  end_time: FormControl<string | Date | null>;
  group_id: FormControl<number | null>;
  nearest_node_id: FormControl<number | null>;
  order: FormControl<number | null>;
  parallel_with: FormControl<number | BaseModel | null>;
  port: FormControl<Port | string | null>;
  port_id: FormControl<number | null>;
  point: FormControl<GeoCoordinates | null>;
  start_time: FormControl<string | Date | null>;
  type: FormControl<DealEventType | null>;
  unique_row_id: FormControl<string | null>;
  inner_events?: FormControl<DealEvent[] | null>;
  single_inner_event?: FormGroup<EventFormGroup>;
  meta: FormGroup<EventMetaInfoFormGroup>;
  cargo_id: FormControl<number | null>;
  time_stamp?: FormControl<number | null>;
}

export interface EventMetaInfoFormGroup {
  route_geojson?: FormControl<FeatureCollection | null>;
  distance: FormControl<number | null>;
  amount: FormControl<number | null>;
  disbursement: FormControl<number | null>;
  disbursement_currency: FormControl<Currency | null>;
  // cargo_id: FormControl<number | null>;
  fuel_id: FormControl<number | null>;
  fuel_consumptions: FormControl<FuelConsumption[] | null>;
  extra_minutes_after: FormControl<UserInputDuration | null>;
  extra_minutes_before: FormControl<UserInputDuration | null>;
  extra_working_minutes: FormControl<UserInputField | null>;
  idle_minutes: FormControl<UserInputDuration | null>;
  load_discharge_rate: FormControl<number | null>;
  order: FormControl<number | null>;
  terms_id: FormControl<number | null>;
  canals: FormControl<number[] | null>;
  current_canal_id: FormControl<number | null>;
  current_canal_name: FormControl<string | null>;
  internal_canal_sog: FormControl<number | null>;
  internal_canal_distance: FormControl<number | null>;
  internal_canal_distance_eca: FormControl<number | null>;
  internal_canal_route_geojson: FormControl<FeatureCollection | null>;
  internal_canal_sailing_minutes: FormControl<UserInputDuration | null>;
  internal_canal_sailing_eca_minutes: FormControl<UserInputDuration | null>;
  use_eca: FormControl<boolean | null>;
  eca_distance: FormControl<number | null>;
  port_idle_time: FormControl<number | null>;
  port_time: FormControl<UserInputDuration | null>;
  sailing_eca_minutes: FormControl<UserInputDuration | null>;
  sailing_minutes: FormControl<UserInputDuration | null>;
  // sog: FormControl<number | null>;
  timezone_offset: FormControl<number | null>;
  timezone_name: FormControl<string | null>;
  weather_factor: FormControl<number | null>;
  notice: FormControl<string | null>;
  total_bunker: FormControl<number | null>;
  total_discharge: FormControl<number | null>;
  total_load: FormControl<number | null>;
  preset: FormControl<Preset | null>;
  work_type: FormControl<WorkType | null>;
  work_amount: FormControl<number | null>;
  working_minutes: FormControl<UserInputField | null>;
  is_laden: FormControl<boolean | null>;
  // rob
  /**
   * VLSFO
   */
  residual_rob: FormControl<number | null>;
  hsfo_rob: FormControl<number | null>;
  /**
   * LSMGO
   */
  distillate_rob: FormControl<number | null>;
  ulsfo_rob: FormControl<number | null>;
  // bunker
  /**
   * VLSFO
   */
  residual_bunker: FormControl<number | null>;
  hsfo_bunker?: FormControl<number | null>;
  /**
   * LSMGO
   */
  distillate_bunker: FormControl<number | null>;
  ulsfo_bunker: FormControl<number | null>;
  using_cranes_on_vessel?: FormControl<boolean | null>;
  use_fuel_type: FormControl<string | null>;
  demurrage_rate: FormControl<number | null>;
  despatch_rate: FormControl<number | null>;
  laytime_difference_minutes: FormControl<UserInputDuration | null>;
  laytime_data: FormGroup<LaytimeDataForm>;
  high_purity_fuel_name: FormControl<FuelTypes | null>;
  high_purity_sailing_eca_minutes: FormControl<UserInputDuration | null>;
}

export interface LaytimeDataForm {
  terms_id: FormControl<number | null>;
  amount: FormControl<number | null>;
  use_fuel_type: FormControl<string | null>;
  working_minutes: FormControl<UserInputDuration | null>;
  idle_minutes: FormControl<UserInputDuration | null>;
  extra_working_minutes: FormControl<UserInputDuration | null>;
  work_amount: FormControl<number | null>;
  work_type: FormControl<WorkType | null>;
  using_cranes_on_vessel?: FormControl<boolean | null>;
  load_discharge_rate: FormControl<number | null>;
}

export enum EventTableColumnType {
  Port = 'port',
  Type = 'type',
  Cargo = 'cargo_id',
  Preset = 'preset',
  UseFuelType = 'use_fuel_type',
  WeatherFactor = 'weather_factor',
  Eca = 'use_eca',
  Distance = 'distance',
  EcaDistance = 'eca_distance',
  Amount = 'amount',
  WorkAmount = 'work_amount',
  WorkType = 'work_type',
  Terms = 'terms_id',
  ExtraMinutesBefore = 'extra_minutes_before', // TT
  ExtraMinutesAfter = 'extra_minutes_after', // ET
  Disbursement = 'disbursement',
  DisbursementCurrency = 'disbursement_currency',
  StartTime = 'start_time',
  SailingMinutes = 'sailing_minutes',
  PortTime = 'port_time',
  ResidualRob = 'residual_rob',
  DistillateRob = 'distillate_rob',
  UsingCranes = 'using_cranes_on_vessel',
}

export const EVENTS_TABLE_COLUMNS: ColumnUI<EventTableColumnType>[] = [
  { field: EventTableColumnType.Port, header: 'Port', minWidth: '135px' },
  {
    field: EventTableColumnType.Type,
    header: 'Oper',
    width: '50px',
    minWidth: '40px',
    maxWidth: '70px',
  },
  {
    field: EventTableColumnType.Cargo,
    header: '#',
    tooltip: 'Cargo number',
    width: '20px',
    minWidth: '20px',
    maxWidth: '30px',
  },
  {
    field: EventTableColumnType.Preset,
    header: 'Preset',
    icon: PrimeNgIcons.Propeller,
    tooltip: 'Speed profile',
    width: '30px',
    minWidth: '30px',
    maxWidth: '30px',
  },
  {
    field: EventTableColumnType.WeatherFactor,
    header: 'WF',
    icon: PrimeNgIcons.Wind,
    tooltip: 'Weather factor',
    width: '35px',
    minWidth: '35px',
    maxWidth: '35px',
  },
  { field: EventTableColumnType.Eca, header: 'ECA', tooltip: 'ON - use ECA, OFF - reduce ECA' },
  { field: EventTableColumnType.Distance, header: 'Distance', width: '50px' },
  { field: EventTableColumnType.Amount, header: 'Quantity' },
  { field: EventTableColumnType.WorkAmount, header: 'L/D Rate' },
  { field: EventTableColumnType.Terms, header: 'Terms', width: '100px' },
  {
    field: EventTableColumnType.UsingCranes,
    header: 'Cranes',
    tooltip: 'ON - working consumption, OFF - Idle consumption',
  },
  {
    field: EventTableColumnType.UseFuelType,
    header: 'Fuel',
    tooltip: 'Type of fuel due the port operations',
    maxWidth: '25px',
    resizable: false,
  },
  {
    field: EventTableColumnType.ExtraMinutesBefore,
    header: 'TT',
    tooltip: 'Turn time',
    width: '50px',
  },
  {
    field: EventTableColumnType.ExtraMinutesAfter,
    header: 'ET',
    tooltip: 'Extra time',
    width: '50px',
  },
  {
    field: EventTableColumnType.Disbursement,
    header: 'DA',
    tooltip: 'Disbursement costs',
    width: '90px',
  },
  /* { field: EventTableColumnType.DisbursementCurrency, header: 'Currency' }, */
  {
    field: EventTableColumnType.StartTime,
    header: 'Arrival',
    tooltip: 'LT - Port local time, LT (UTC +/-) - user time, UTC - UTC time, set in Settings',
  },
  {
    field: EventTableColumnType.SailingMinutes,
    header: 'Days S/P',
    tooltip: 'Sailing days, port days',
    width: '55px',
  },
  {
    field: EventTableColumnType.ResidualRob,
    header: 'RoB',
    tooltip: 'Remaining on board',
    width: '130px',
  },
];

export interface OptionalId {
  id?: number;
}

export interface SetPoint {
  dealEvent: DealEvent;
  index: number;
}

export interface BunkerSelectDialogData {
  bunkerEvents: DealEvent[];
}
