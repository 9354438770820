import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CompanyBankDetails } from '@estimator/models';
import { BaseService } from '../base';

@Injectable({
  providedIn: 'root',
})
export class BankDetailsService extends BaseService<CompanyBankDetails> {
  constructor(protected override readonly http: HttpClient) {
    super(http);
    this.setPath('/xauth/company-bank-details');
  }
}
