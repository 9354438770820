import { CaptainReport, ReportType } from '@estimator/models';

export const captainReports: CaptainReport[] = [
  {
    arrival_port_id: 1,
    average_rpm: 1000.2,
    average_speed: 45.22,
    comment: 'Comment 1',
    departure_port_id: 2,
    distance_from_last_report: 122,
    etd: '2023-03-03T18:41:19.773619Z',
    finished_date: '2023-03-03T18:41:19.773619Z',
    imo: 435833290,
    latitude: '10.22.11',
    longitude: '08.13.16',
    next_canal_eta: '2023-03-03T18:41:19.773619Z',
    next_port_eta: '2023-03-03T18:41:19.773619Z',
    nor_date: '2023-03-03T18:41:19.773619Z',
    observe_date: '2023-03-03T18:41:19.773619Z',
    pob_date: '2023-03-03T18:41:19.773619Z',
    report_type: ReportType.Arrival,
    vessel_id: 12,
  },
  {
    arrival_port_id: 4,
    average_rpm: 1000.2,
    average_speed: 45.22,
    comment: 'Comment 2',
    departure_port_id: 5,
    distance_from_last_report: 122,
    etd: '2023-03-03T18:41:19.773619Z',
    finished_date: '2023-03-03T18:41:19.773619Z',
    imo: 435833290,
    latitude: '10.22.11',
    longitude: '08.13.16',
    next_canal_eta: '2023-03-03T18:41:19.773619Z',
    next_port_eta: '2023-03-03T18:41:19.773619Z',
    nor_date: '2023-03-03T18:41:19.773619Z',
    observe_date: '2023-03-03T18:41:19.773619Z',
    pob_date: '2023-03-03T18:41:19.773619Z',
    report_type: ReportType.Arrival,
    vessel_id: 12,
  },
  {
    arrival_port_id: 72,
    average_rpm: 1000.2,
    average_speed: 45.22,
    comment: 'Comment 2',
    departure_port_id: 12,
    distance_from_last_report: 122,
    etd: '2023-03-03T18:41:19.773619Z',
    finished_date: '2023-03-03T18:41:19.773619Z',
    imo: 435833290,
    latitude: '10.22.11',
    longitude: '08.13.16',
    next_canal_eta: '2023-03-03T18:41:19.773619Z',
    next_port_eta: '2023-03-03T18:41:19.773619Z',
    nor_date: '2023-03-03T18:41:19.773619Z',
    observe_date: '2023-03-03T18:41:19.773619Z',
    pob_date: '2023-03-03T18:41:19.773619Z',
    report_type: ReportType.Arrival,
    vessel_id: 12,
  },
];
