import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BasicResponse,
  LoginProps,
  LoginResponse,
  RegisterProps,
  RegisterResponse,
  User,
} from '@estimator/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  readonly path = '/api/users';
  constructor(protected readonly http: HttpClient) {}

  login(props: LoginProps): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(`${this.path}/login`, props);
  }

  register(props: RegisterProps): Observable<RegisterResponse> {
    return this.http.post<RegisterResponse>(`${this.path}/register`, props);
  }

  confirmEmail(token: string) {
    return this.http.get(`${this.path}/email/confirm`, { params: { token } });
  }

  confirmUser(token: string): Observable<LoginResponse> {
    let headers = new HttpHeaders();
    headers = headers.set('Token', token);
    return this.http.get<LoginResponse>(`${this.path}/confirmed/stream`, { headers });
  }

  confirmUserByCode(token: string, code: string): Observable<BasicResponse> {
    let headers = new HttpHeaders();
    headers = headers.set('Token', token);
    return this.http.post<BasicResponse>(`${this.path}/email/confirm/code`, { code }, { headers });
  }

  retryEmail(email: string) {
    return this.http.post(`${this.path}/email/retry`, { email });
  }

  refreshToken(refresh_token: string): Observable<LoginResponse> {
    let headers = new HttpHeaders();
    if (refresh_token) {
      headers = headers.set('Refresh', refresh_token);
    }
    return this.http.post<LoginResponse>(
      `${this.path}/token/refresh`,
      {},
      {
        headers,
      }
    );
  }

  resetPassword(email: string): Observable<BasicResponse> {
    return this.http.post<BasicResponse>(`${this.path}/password/reset`, { email });
  }

  updatePassword(password: string, resetToken?: string): Observable<BasicResponse> {
    let headers = new HttpHeaders();
    if (resetToken) {
      headers = headers.set('Reset', resetToken);
    }
    return this.http.post<BasicResponse>(`${this.path}/password/update`, { password }, { headers });
  }

  getTemporaryToken(): Observable<RegisterResponse> {
    return this.http.post<RegisterResponse>(`${this.path}/temporary`, {});
  }

  getMe(): Observable<User> {
    return this.http.get<User>(`${this.path}/me`);
  }
}
