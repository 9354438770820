import { ConsumptionRate, Preset, PresetNames } from '@estimator/models';
import { fuelsMock } from './fuels.mock';

export const platformVesselMock = [
  {
    id: 'c639ac45-5ed9-40db-9843-6afb131bade0',
    meta: {
      name: 'ASTANA',
      mmsi: '436000393',
      countryCode: 'kz',
      imo: '9323091',
      additional: {},
      __typename: 'vessel_meta',
    },
    __typename: 'company_vessel',
  },
  {
    id: 'c653ca77-8a2c-498f-b474-2823ae07cbfb',
    meta: {
      name: 'cy-vessel-1',
      mmsi: 'cy000000001',
      countryCode: 'be',
      imo: null,
      additional: {},
      __typename: 'vessel_meta',
    },
    __typename: 'company_vessel',
  },
  {
    id: '0a813f45-a370-4f75-b56f-2f2db0255f50',
    meta: {
      name: 'BAHAMA MAMA',
      mmsi: '210567000',
      countryCode: 'cy',
      imo: '9441142',
      additional: {
        eni: null,
        teu: '0',
        build: '2009',
        depth: null,
        grain: null,
        owner: 'BALEARIA EUROLINEAS MARITIMAS',
        builder: 'HIJOS DE J. BARRERAS S.A.',
        country: 'cy',
        draught: 5.5,
        insurer: null,
        manager: 'BALEARIA EUROLINEAS MARITIMAS',
        callsign: '5BDW6',
        speed_max: null,
        type_name: 'Passenger/Ro-Ro Cargo Ship',
        liquid_gas: null,
        liquid_oil: null,
        summer_dwt: '3520',
        net_tonnage: null,
        vessel_type: 'Passenger/Ro-Ro Cargo Ship',
        yard_number: '1662',
        gross_tonnage: '20312',
        speed_service: '200',
        length_overall: '155',
        place_of_build: 'VIGO, Spain',
        breadth_extreme: '24',
        breadth_moulded: null,
        financial_owner: null,
        fuel_consumption: null,
        technical_manager: null,
        displacement_summer: null,
        length_b_w_perpendiculars: null,
      },
      __typename: 'vessel_meta',
    },
    __typename: 'company_vessel',
  },
];

export const consumptionRatesMock: ConsumptionRate[] = [
  {
    fuel: fuelsMock[3],
    fuel_id: fuelsMock[3].id,
  },
  {
    fuel: fuelsMock[2],
    fuel_id: fuelsMock[2].id,
  },
  {
    fuel: fuelsMock[8],
    fuel_id: fuelsMock[8].id,
  },
  {
    fuel: fuelsMock[5],
    fuel_id: fuelsMock[5].id,
  },
];
export const fullPresetMock: Preset = {
  name: PresetNames.Full,
  consumption_rates: consumptionRatesMock,
};
export const ecoPreset1Mock: Preset = {
  name: PresetNames.Eco1,
  consumption_rates: consumptionRatesMock,
};
export const ecoPreset2Mock: Preset = {
  name: PresetNames.Eco2,
  consumption_rates: consumptionRatesMock,
};
export const ecoPreset3Mock: Preset = {
  name: PresetNames.Eco3,
  consumption_rates: consumptionRatesMock,
};
