import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipeModule } from '@estimator/helpers';
import { ButtonModule } from 'primeng/button';
import { MessageModule } from 'primeng/message';
import { RegistrationReminderComponent } from './registration-reminder.component';

@NgModule({
  declarations: [RegistrationReminderComponent],
  imports: [CommonModule, PipeModule, ButtonModule, MessageModule],
  exports: [RegistrationReminderComponent],
})
export class RegistrationReminderModule {}
