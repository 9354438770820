<p-table
  [value]="portDisbursements"
  dataKey="id"
  class="primeng-form"
  styleClass="p-datatable-sm font-size-12 p-datatable-striped port-disbursement-table"
>
  <ng-template pTemplate="header">
    <tr>
      <th class="font-bold">Dwt</th>
      <th class="font-bold">Date</th>
      <th class="font-bold">Cargo</th>
      <th class="font-bold">Quantity</th>
      <th class="font-bold">Vessel</th>
      <th class="font-bold">DA</th>
      <th class="font-bold w-4rem">Action</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-portDA>
    <tr>
      <td class="text-left">{{ portDA.dwt }}</td>
      <td class="text-left">{{ localDateTimeFormat(portDA.created_at, false) }}</td>
      <td class="text-left">{{ portDA.cargo_name || NOT_APPLICABLE }}</td>
      <td class="text-left">{{ portDA.cargo_amount }}</td>
      <td class="text-left">{{ portDA.vessel_name || NOT_APPLICABLE }}</td>
      <td class="text-left">{{ portDA.da_amount }}</td>
      <td class="text-left">
        <i
          [class]="PrimeNgIcons.SIGN_IN + ' cursor-pointer'"
          [ngClass]="{ 'pointer-events-none': isFixed }"
          (click)="onSavePortDA(portDA.da_amount)"
        ></i>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="7">No data found.</td>
    </tr>
  </ng-template>
</p-table>
