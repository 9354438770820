import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AgGridModule } from 'ag-grid-angular';
import { AgGridSharedModule } from '../ag-grid-shared/ag-grid-shared.module';
import { DealsListComponent } from './deals-list.component';

@NgModule({
  declarations: [DealsListComponent],
  imports: [AgGridModule, AgGridSharedModule, CommonModule],
  exports: [DealsListComponent],
})
export class DealsListModule {}
