<form [formGroup]="shiplistFormGroup" class="wrapper-filter">
  <h3>Open position</h3>
  <div class="shiplist-form">
    <label>Filter name:</label>
    <input
      formControlName="name"
      [ngClass]="redBorder()"
      [pTooltip]="tooltipContent"
      [tooltipDisabled]="shiplistFormGroup.controls.name.invalid || !!isAvailableName"
      pInputText
      type="text"
      tooltipPosition="right"
    />
    <ng-template #tooltipContent
      ><span *ngIf="redBorder()"
        >This field must have unique name and more than 3 characters</span
      ></ng-template
    >

    <label>Location:</label>
    <div class="relative">
      <p-autoComplete
        (completeMethod)="onSearchPort($event)"
        formControlName="filter_location"
        [multiple]="true"
        [unique]="true"
        styleClass="w-full"
        emptyMessage="No locations found"
        [suggestions]="portsOrZones()"
        optionLabel="name"
      >
        <!-- pTemplate="selectedItem" and pTemplate="item" are equal -->
        <ng-template let-item pTemplate="selectedItem">
          <span class="flex gap-1"
            ><i *ngIf="showAnchor(item)" [class]="PrimeNgIcons.Anchor"></i>
            <span
              class="material-icons cursor-pointer"
              pTooltip="{{ getIconTooltip(item) }}"
              tooltipPosition="top"
              >{{ getIconZone(item) }}</span
            >
            {{ item.name }}
          </span>
        </ng-template>
        <ng-template let-item pTemplate="item">
          <span class="flex gap-1"
            ><i *ngIf="showAnchor(item)" [class]="PrimeNgIcons.Anchor"></i>
            <span
              class="material-icons cursor-pointer"
              pTooltip="{{ getIconTooltip(item) }}"
              tooltipPosition="top"
              >{{ getIconZone(item) }}</span
            >
            {{ item.name }}</span
          >
        </ng-template>
      </p-autoComplete>
      <p-button
        (onClick)="onOpenMap()"
        [icon]="PrimeNgIcons.MAP_MARKER"
        class="absolute right-0 m-1 button-location"
        styleClass="button-location"
        pTooltip="Choose zone"
        size="small"
        tooltipPosition="top"
      />
    </div>

    <label>Open dates:</label>
    <estimator-form-date-switcher
      class="p-inputgroup"
      [controlFrom]="shiplistFormGroup.controls.open_dates_from_seconds"
      [controlTo]="shiplistFormGroup.controls.open_dates_to_seconds"
      [controlDays]="shiplistFormGroup.controls.open_dates_last_n_days"
      (changeValue)="onChangeValueOpenDates($event)"
      #formDateSwitcherOpenDates
    />

    <label>DWT</label>
    <div class="p-inputgroup">
      <p-inputNumber
        [max]="MAX_FINANCE_VALUE"
        [useGrouping]="false"
        [minFractionDigits]="0"
        [maxFractionDigits]="2"
        [min]="0"
        [step]="0"
        formControlName="vessel_dead_weight_from"
        class="p-inputtext-sm w-full"
        styleClass="w-full"
        inputStyleClass="w-full"
        [inputId]="'vessel_dead_weight_from'"
        estimatorDecimals
      ></p-inputNumber>
      —
      <p-inputNumber
        [max]="MAX_FINANCE_VALUE"
        [useGrouping]="false"
        [minFractionDigits]="0"
        [maxFractionDigits]="2"
        [min]="0"
        [step]="0"
        formControlName="vessel_dead_weight_to"
        class="p-inputtext-sm w-full"
        styleClass="w-full"
        inputStyleClass="w-full"
        [inputId]="'vessel_dead_weight_to'"
        estimatorDecimals
      ></p-inputNumber>
    </div>

    <label>Updated:</label>
    <estimator-form-date-switcher
      class="p-inputgroup"
      [controlFrom]="shiplistFormGroup.controls.message_date_from_seconds"
      [controlTo]="shiplistFormGroup.controls.message_date_to_seconds"
      [controlDays]="shiplistFormGroup.controls.message_date_last_n_days"
      (changeValue)="onChangeValueMessageDates($event)"
      #formDateSwitcherMessageDates
    />

    <label>Privacy:</label>
    <div class="flex flex-nowrap gap-2">
      <!-- double in estimator-estimations-home-page -->
      <p-multiSelect
        [options]="usersInMyCompany"
        [filter]="false"
        [showToggleAll]="true"
        [scrollHeight]="'400px'"
        selectedItemsLabel="{0} items selected"
        formControlName="allowed_users_array"
        appendTo="body"
        placeholder="User"
        optionLabel="initials"
        [display]="multiSelectDisplay"
        panelStyleClass="multiselect-user"
        styleClass="w-100"
        pTooltip="Select users to share filters for"
        id="shiplist_users_multiselect"
        #userMultiselect
      >
        <ng-template pTemplate="header">
          <label
            (click)="selectAllUsers($event)"
            class="flex-order-1"
            for="shiplist_select_all_users"
            id="shiplist_select_all_users"
            >Select all</label
          >
        </ng-template>
        <ng-template let-user pTemplate="item">
          <div class="flex flex-column">
            <span class="font-bold"> {{ user.first_name }} {{ user.last_name }} </span>
            <span> {{ user.email }}</span>
          </div>
        </ng-template>
      </p-multiSelect>
    </div>
    <label></label>
    <p-button (click)="onSaveFilter()" [label]="isNewFilter ? 'Create filter' : 'Update filter'" />
  </div>
</form>
