import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { MessageModule } from 'primeng/message';
import { UserConfirmComponent } from './user-confirm.component';

@NgModule({
  declarations: [UserConfirmComponent],
  imports: [CommonModule, ButtonModule, MessageModule],
  exports: [UserConfirmComponent],
})
export class UserConfirmModule {}
