import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  AuthFormDialogConfig,
  ERROR_BASE_TEMPLATE,
  LoginProps,
  NotificationType,
  ProductType,
  RegisterProps,
  SIGN_IN,
  SIGN_UP,
  UserSubscription,
} from '@estimator/models';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Observable, Subject, of, takeUntil } from 'rxjs';

@Component({
  selector: 'estimator-authorization-form',
  templateUrl: './authorization-form.component.html',
  styleUrls: ['./authorization-form.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthorizationFormComponent implements OnInit, OnDestroy {
  @Input() set isLoading(state: boolean) {
    this._isLoading = state;
  }
  get isLoading(): boolean {
    return this._isLoading;
  }
  @Input() signInError = '';
  @Input() signUpError = '';
  @Input() subscriptionModel$: Observable<UserSubscription[]> = of([]);
  @Input() activeIndex = 0;
  @Input() presetProduct?: ProductType;
  @Output() signIn = new EventEmitter<LoginProps>();
  @Output() signUp = new EventEmitter<RegisterProps>();
  @Output() forgotPassword = new EventEmitter<void>();
  subscriptionModels: UserSubscription[] = [];
  SIGN_IN = SIGN_IN;
  SIGN_UP = SIGN_UP;
  readonly NotificationType = NotificationType;
  private _isLoading = false;
  private _onDestroy$ = new Subject<void>();

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly dialogConfig: DynamicDialogConfig<AuthFormDialogConfig>
  ) {
    if (this.dialogConfig.data) {
      const {
        signInEmitter,
        signUpEmitter,
        forgotPasswordEmmiter,
        loadingSubject,
        signInErrorSubject,
        signUpErrorSubject,
        subscriptionModels,
        isSignUp,
        presetProduct
      } = this.dialogConfig.data;
      if (signInEmitter) {
        this.signIn = signInEmitter;
      }
      if (signUpEmitter) {
        this.signUp = signUpEmitter;
      }
      if (forgotPasswordEmmiter) {
        this.forgotPassword = forgotPasswordEmmiter;
      }
      if (subscriptionModels) {
        this.subscriptionModel$ = subscriptionModels;
      }
      if (isSignUp) {
        this.activeIndex = 1;
      }
      if (presetProduct) {
        this.presetProduct = presetProduct
      }
      signInErrorSubject?.pipe(takeUntil(this._onDestroy$)).subscribe((res) => {
        if (res) {
          this.signInError = `${ERROR_BASE_TEMPLATE} ${res}`;
          this.detectChange();
        }
      });
      signUpErrorSubject?.pipe(takeUntil(this._onDestroy$)).subscribe((res) => {
        if (res) {
          this.signUpError = `${ERROR_BASE_TEMPLATE} ${res}`;
          this.detectChange();
        }
      });
      loadingSubject?.pipe(takeUntil(this._onDestroy$)).subscribe((res) => {
        this.isLoading = res;
        this.detectChange();
      });
    }
  }

  ngOnInit(): void {
    this.subscriptionModel$.pipe(takeUntil(this._onDestroy$)).subscribe((models) => {
      this.subscriptionModels = models;
      this.detectChange();
    });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  detectChange(): void {
    this.cdr.detectChanges();
  }

  onSignIn(props: LoginProps): void {
    this.signIn.emit(props);
  }

  onSignUp(props: RegisterProps): void {
    this.signUp.emit(props);
  }

  onForgotPassword(): void {
    this.forgotPassword.emit();
  }
}
