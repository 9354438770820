import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { mglMapAccessToken } from '@estimator/models';
import { BdcWalkModule } from 'bdc-walkthrough';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { DialogMapComponent } from './dialog-map.component';

@NgModule({
  declarations: [DialogMapComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatCheckboxModule,
    MatIconModule,
    MatChipsModule,
    BdcWalkModule,
    NgxMapboxGLModule.withConfig({
      accessToken: mglMapAccessToken,
    }),
  ],
  exports: [DialogMapComponent],
})
export class DialogMapModule {}
