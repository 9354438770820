<form [formGroup]="fg" class="flex-container-row coordinate">
  <span class="flex-container-column content-center margin-right-auto"
    >{{ isLatitude ? 'Latitude' : 'Longtitude' }}:</span
  >
  <input
    type="text"
    class="height-36"
    [style.width]="
      type === CoordinatesFormat.DMS || type === CoordinatesFormat.DM ? '55px' : '160px'
    "
    formControlName="decimal"
    pInputText
    estimatorDecimals
    [id]="'decimal-' + isLatitude + type"
  />
  <span
    *ngIf="type === CoordinatesFormat.DMS || type === CoordinatesFormat.DM"
    class="padding-right-5"
    >°</span
  >
  <input
    *ngIf="type === CoordinatesFormat.DMS || type === CoordinatesFormat.DM"
    type="text"
    class="height-36"
    [style.width]="'55px'"
    formControlName="minutes"
    pInputText
    estimatorDecimals
    [id]="'minutes-' + isLatitude + type"
  />
  <span *ngIf="type === CoordinatesFormat.DMS" class="padding-right-5">'</span>
  <span *ngIf="type === CoordinatesFormat.DM" class="padding-right-5 margin-top-auto">.</span>
  <input
    *ngIf="type === CoordinatesFormat.DMS || type === CoordinatesFormat.DM"
    type="text"
    class="height-36"
    [style.width]="'55px'"
    formControlName="seconds"
    pInputText
    estimatorDecimals
    [id]="'seconds-' + isLatitude + type"
  />
  <span *ngIf="type === CoordinatesFormat.DMS">"</span>
  <span *ngIf="type === CoordinatesFormat.DM">'</span>
  <p-dropdown
    class="margin-left-10"
    formControlName="sideWorld"
    [options]="sidesWorld"
  ></p-dropdown>
</form>
