import { Injectable } from '@angular/core';
import { emptyFeatureCollection } from '@estimator/helpers';
import {
  GREY_MAP_ZONE_COLOR,
  GeoZone,
  MapTypes,
  ShiplistGeoZoneProperties,
  ShiplistZoneType,
} from '@estimator/models';
import { RouteService, ShiplistService } from '@estimator/services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Feature, FeatureCollection, GeoJsonProperties, Polygon } from 'geojson';
import { catchError, finalize, tap, throwError } from 'rxjs';
import { GetCanals, GetEcaZones, GetShiplistZones } from './route.actions';
export interface RouteStateModel {
  canals: GeoZone[];
  includedByDefaultCanals: number[];
  ecaZones: FeatureCollection<Polygon>;
  zonesCollections: FeatureCollection<Polygon>;
  shiplistZones: FeatureCollection<Polygon>;
  shiplistZonesArray: GeoZone[];
  shiplistZonesArrayExcludeRange: GeoZone[];
  canalZones: FeatureCollection<Polygon>;
  loading: boolean;
}

@State<RouteStateModel>({
  name: 'route',
  defaults: {
    canals: [],
    includedByDefaultCanals: [],
    ecaZones: emptyFeatureCollection(),
    zonesCollections: emptyFeatureCollection(),
    shiplistZonesArrayExcludeRange: [],
    shiplistZones: emptyFeatureCollection(),
    shiplistZonesArray: [],
    canalZones: emptyFeatureCollection(),
    loading: false,
  },
})
@Injectable()
export class RouteState {
  @Selector()
  static getIsLoading({ loading }: RouteStateModel): boolean {
    return loading;
  }
  @Selector()
  static getCanals({ canals }: RouteStateModel): GeoZone[] {
    return canals;
  }
  @Selector()
  static getDefaultIncludedCanals({ includedByDefaultCanals }: RouteStateModel): number[] {
    return includedByDefaultCanals;
  }
  @Selector()
  static getEcaZones({ ecaZones }: RouteStateModel): FeatureCollection {
    return ecaZones;
  }
  @Selector()
  static getCanalZones({ canalZones }: RouteStateModel): FeatureCollection {
    return canalZones;
  }
  @Selector()
  static getShiplistZones({ shiplistZones }: RouteStateModel): FeatureCollection {
    return shiplistZones;
  }
  @Selector()
  static getShiplistZonesExcludeRange({
    shiplistZonesArrayExcludeRange,
  }: RouteStateModel): GeoZone[] {
    return shiplistZonesArrayExcludeRange;
  }
  @Selector()
  static getShiplistZonesArray({ shiplistZonesArray }: RouteStateModel): GeoZone[] {
    return shiplistZonesArray;
  }

  constructor(
    private readonly routeService: RouteService,
    private readonly shiplistService: ShiplistService
  ) {}

  @Action(GetCanals)
  getAllCanals({ patchState }: StateContext<RouteStateModel>) {
    patchState({ loading: true });
    return this.routeService.getCanals().pipe(
      tap((canals) => {
        const includedByDefaultCanals: number[] = [];
        const collection: FeatureCollection<Polygon> = emptyFeatureCollection();
        collection.features = [];
        canals.forEach((canal) => {
          if (canal.included_by_default && canal.id) {
            includedByDefaultCanals.push(canal.id);
          }
          if (canal?.geo_json?.features) {
            const newFeature: Feature<Polygon> = {
              type: MapTypes.Feature,
              geometry: canal?.geo_json?.features,
              properties: { name: '' },
            };
            collection.features.push(newFeature);
          }
        });
        patchState({ canals, includedByDefaultCanals, canalZones: collection });
      }),
      catchError((err) => {
        patchState({ loading: false });
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }

  @Action(GetEcaZones)
  getEcaZones({ patchState }: StateContext<RouteStateModel>) {
    patchState({ loading: true });
    return this.routeService.getEcaZones().pipe(
      tap((zones) => {
        const collection: FeatureCollection<Polygon> = emptyFeatureCollection();
        collection.features = [];
        zones.forEach((zone) => {
          if (zone?.geo_json?.features) {
            const newFeature: Feature<Polygon> = {
              type: MapTypes.Feature,
              geometry: zone?.geo_json?.features,
              properties: { name: '' },
            };
            collection.features.push(newFeature);
          }
        });
        patchState({ ecaZones: collection });
      }),
      catchError((err) => {
        patchState({ loading: false });
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }

  @Action(GetShiplistZones)
  getShiplistZones(
    { getState, patchState }: StateContext<RouteStateModel>,
    { typeZone }: GetShiplistZones
  ) {
    patchState({ loading: true });
    return this.shiplistService.getShiplistZones(typeZone).pipe(
      tap((zones) => {
        if (typeZone) {
          // if typeZone === ShiplistZoneType.RANGE, then we use zones from ZONES
          if (typeZone === ShiplistZoneType.RANGE) {
            const { zonesCollections } = getState();
            patchState({ shiplistZones: zonesCollections, shiplistZonesArray: zones });
          } else {
            const collection = this.getCollectionShiplist(zones);
            patchState({ shiplistZones: collection, shiplistZonesArray: zones });
          }
        } else {
          const zonesType = zones.filter((zone) => zone.type === ShiplistZoneType.ZONE);
          const collectionsZonesType = this.getCollectionShiplist(zonesType);
          patchState({
            shiplistZonesArrayExcludeRange: zones,
            shiplistZones: collectionsZonesType,
            zonesCollections: collectionsZonesType,
          });
        }
      }),
      catchError((err) => {
        patchState({ loading: false });
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }

  getCollectionShiplist(zones: GeoZone[]): FeatureCollection<Polygon, GeoJsonProperties> {
    const collection: FeatureCollection<Polygon> = emptyFeatureCollection();
    collection.features = [];
    zones.forEach((zone) => {
      if (zone?.geo_json?.features) {
        const newFeature: Feature<Polygon> = {
          type: MapTypes.Feature,
          geometry: zone?.geo_json?.features,
          properties: this.makePropertiesShiplist(zone),
        };
        collection.features.push(newFeature);
      }
    });
    return collection;
  }

  makePropertiesShiplist(zone: GeoZone): ShiplistGeoZoneProperties {
    return {
      name: zone.name,
      color: GREY_MAP_ZONE_COLOR,
      id: zone.id,
      type: zone.type,
      selected: false,
      loc_id: zone.loc_id,
      zone_ids: zone.zone_ids,
    } as ShiplistGeoZoneProperties;
  }
}
