import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { getIconTooltip, getIconZone, makeBaseEntityPartFormGroup } from '@estimator/helpers';
import {
  FormDateSwitcherFormGroup,
  GeoZone,
  MAX_FINANCE_VALUE,
  MultiSelectDisplayType,
  Port,
  PrimeNgIcons,
  ShipListMode,
  ShiplistFilter,
  ShiplistFormGroup,
  ShiplistSelectedZoneOrPort,
  ShiplistSelectedZoneOrSelectedPortOrPort,
  ShiplistZoneType,
  THREE_NUMBER,
  User,
} from '@estimator/models';
import { uniqBy } from 'lodash';
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete/autocomplete.interface';
import { MultiSelect } from 'primeng/multiselect';
import { Subject, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';
import { FormDateSwitcherComponent } from '../form-date-switcher/form-date-switcher.component';

/** deprecated */
@Component({
  selector: 'estimator-shiplist-filter',
  templateUrl: './ship-list-filter.component.html',
  styleUrls: ['./ship-list-filter.component.scss'],
  // encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShipListFilterComponent implements OnInit, OnDestroy {
  @ViewChild('userMultiselect') userMultiselect?: MultiSelect;
  @ViewChild('formDateSwitcherOpenDates') formDateSwitcherOpenDates?: FormDateSwitcherComponent;
  @ViewChild('formDateSwitcherMessageDates')
  formDateSwitcherMessageDates?: FormDateSwitcherComponent;
  @Input() shiplistZonesExcludeRange: GeoZone[] = [];
  @Input() set usersInMyCompany(value: User[]) {
    this._usersInMyCompany = value;
    this.setUsers();
  }
  @Input() zoneOrPortListNameForFilter = '';
  @Input() set selectedZonesPorts(array: ShiplistSelectedZoneOrPort[] | Port[]) {
    this.shiplistFormGroup.controls.filter_location?.patchValue(array);
  }
  @Input() set selectedZonesPortsFromMap(array: ShiplistSelectedZoneOrPort[] | Port[]) {
    const value = this.shiplistFormGroup.controls.filter_location?.value;
    if (value) {
      this.shiplistFormGroup.controls.filter_location?.patchValue(
        uniqBy([...value, ...array], 'id')
      );
    } else {
      this.shiplistFormGroup.controls.filter_location?.patchValue(array);
    }
  }
  @Input() set ports(array: Port[]) {
    this._ports = array;
  }
  @Input() isAvailableName: boolean = false;
  @Input() set filter(filter: ShiplistFilter) {
    if (filter?.name !== '') {
      this._filter = filter;
      this.shiplistFormGroup.patchValue(filter);
      // update child components inputs
      if (this.formDateSwitcherOpenDates) {
        this.formDateSwitcherOpenDates.controlFrom =
          this.shiplistFormGroup?.controls.open_dates_from_seconds;
        this.formDateSwitcherOpenDates.controlTo =
          this.shiplistFormGroup?.controls.open_dates_to_seconds;
        this.formDateSwitcherOpenDates.controlDays =
          this.shiplistFormGroup?.controls.open_dates_last_n_days;
      }
      if (this.formDateSwitcherMessageDates) {
        this.formDateSwitcherMessageDates.controlFrom =
          this.shiplistFormGroup?.controls.message_date_from_seconds;
        this.formDateSwitcherMessageDates.controlTo =
          this.shiplistFormGroup?.controls.message_date_to_seconds;
        this.formDateSwitcherMessageDates.controlDays =
          this.shiplistFormGroup?.controls.message_date_last_n_days;
      }
      this.oldNameFilter = this.shiplistFormGroup.controls?.name?.value || '';
      this.setUsers();
    } else {
      this.oldNameFilter = '';
      this.shiplistFormGroup.reset();
      if (this.formDateSwitcherMessageDates) {
        this.formDateSwitcherMessageDates?.resetFg();
      }
      if (this.formDateSwitcherOpenDates) {
        this.formDateSwitcherOpenDates?.resetFg();
      }
    }
    this.cdr.detectChanges();
  }
  @Input() mode: ShipListMode = ShipListMode.Table;
  @Input() isNewFilter = false;
  @Output() openMap: EventEmitter<ShiplistSelectedZoneOrSelectedPortOrPort> =
    new EventEmitter<ShiplistSelectedZoneOrSelectedPortOrPort>();
  // @Output() updateFilter: EventEmitter<ShiplistFilter> = new EventEmitter();
  @Output() searchPort: EventEmitter<string> = new EventEmitter();
  @Output() checkName: EventEmitter<string> = new EventEmitter();
  @Output() saveFilter: EventEmitter<void> = new EventEmitter();
  /*   @Output() updateShiplistMode: EventEmitter<ShipListMode.Table | ShipListMode.Filter> =
    new EventEmitter(); */
  shiplistFormGroup = new FormGroup<ShiplistFormGroup>({
    // search: new FormControl<string | null>(null),
    ...makeBaseEntityPartFormGroup().controls,
    name: new FormControl<string>(''),
    allowed_users: /* FormControl<User[]>; */ new FormControl<number[] | null>(null),
    company_id: new FormControl<number | null>(null),
    message_date_from_seconds: new FormControl<number | Date | null>(null),
    message_date_to_seconds: new FormControl<number | Date | null>(null),
    message_date_last_n_days: new FormControl<number | null>(null),
    open_dates_from_seconds: new FormControl<number | Date | null>(null),
    open_dates_to_seconds: new FormControl<number | Date | null>(null),
    open_dates_last_n_days: new FormControl<number | null>(null),
    port_ids: new FormControl<number[] | null>(null),
    vessel_dead_weight_from: new FormControl<number | null>(null),
    vessel_dead_weight_to: new FormControl<number | null>(null),
    zone_ids: new FormControl<number[] | null>(null),
    // filter_open_dates: FormControl<boolean>;
    // filter_updates: new FormControl<boolean | null>(null),
    /**
     * for front only
     */
    filter_location: new FormControl<ShiplistSelectedZoneOrSelectedPortOrPort>(null),
    allowed_users_array: new FormControl<User[] | null>(null),
  });
  maxSelectedLabels = THREE_NUMBER;
  oldNameFilter = '';
  readonly PrimeNgIcons = PrimeNgIcons;
  readonly ShiplistZoneType = ShiplistZoneType;
  readonly MAX_FINANCE_VALUE = MAX_FINANCE_VALUE;
  getIconZone = getIconZone;
  getIconTooltip = getIconTooltip;
  private _filter?: ShiplistFilter;
  private _onDestroy$ = new Subject<void>();
  private _ports: Port[] = [];
  private _usersInMyCompany: User[] = [];
  /*  private _selectedZonesPorts: ShiplistSelectedZoneOrPort[] | Port[] = [];
  private _selectedZonesPortsFromMap: ShiplistSelectedZoneOrPort[] | Port[] = []; */

  /*   get selectedZonesPorts(): ShiplistSelectedZoneOrPort[] | Port[] {
    return this._selectedZonesPorts;
  } */
  get ports(): Port[] {
    return this._ports.map((port) => {
      port.is_zone = false;
      return port;
    });
  }
  get filter(): ShiplistFilter | undefined {
    return this._filter;
  }
  get usersInMyCompany(): User[] {
    return this._usersInMyCompany;
  }
  get multiSelectDisplay(): MultiSelectDisplayType {
    return this.shiplistFormGroup?.controls?.allowed_users_array?.value &&
      this.shiplistFormGroup?.controls?.allowed_users_array?.value?.length > this.maxSelectedLabels
      ? MultiSelectDisplayType.COMMA
      : MultiSelectDisplayType.CHIP;
  }

  constructor(private readonly cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.shiplistFormGroup.controls?.name?.valueChanges
      .pipe(takeUntil(this._onDestroy$), debounceTime(300), distinctUntilChanged())
      .subscribe((value) => {
        if (value && value !== this.oldNameFilter) {
          this.checkName.emit(this.shiplistFormGroup.controls?.name?.value || '');
        }
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  selectAllUsers(event: MouseEvent): void {
    if (this.userMultiselect) {
      this.userMultiselect.onToggleAll(event);
    }
  }

  portsOrZones(): (Port | GeoZone)[] {
    /*  return this.zoneOrPortList.filter((portOrZone) => {
      return portOrZone.name?.includes(this.zoneOrPortListNameForFilter);
    }); */
    let filterShiplistZonesExcludeRange: GeoZone[] = [];
    if (this.zoneOrPortListNameForFilter) {
      filterShiplistZonesExcludeRange = /* clone */ this.shiplistZonesExcludeRange.filter(
        (portOrZone) => {
          return portOrZone.name
            ?.toLowerCase()
            .includes(this.zoneOrPortListNameForFilter.toLowerCase());
        }
      );
    }
    return this.ports.concat(filterShiplistZonesExcludeRange);
  }

  onSearchPort(event: AutoCompleteCompleteEvent): void {
    this.zoneOrPortListNameForFilter = event.query;
    if (event.query.length >= THREE_NUMBER) {
      this.searchPort.emit(event.query);
    }
  }

  onOpenMap(): void {
    this.openMap.emit(this.shiplistFormGroup?.value?.filter_location);
  }

  redBorder(): string {
    return this.shiplistFormGroup.controls.name?.invalid || !this.isAvailableName
      ? 'ng-invalid ng-dirty'
      : '';
  }

  /*   patchFields(filterFields: string[], numbers: number[]): void {
    const result = signal({});
    filterFields.forEach((field) =>
      numbers.forEach((number) => {
        result.set({ ...result(), [field]: number });
      })
    );
    console.log(result());
    //this.form.patchValue(result());
  } */

  getFg(): ShiplistFilter {
    if (this.formDateSwitcherOpenDates) {
      this.onChangeValueOpenDates(this.formDateSwitcherOpenDates?.getFg());
    }
    if (this.formDateSwitcherMessageDates) {
      this.onChangeValueMessageDates(this.formDateSwitcherMessageDates?.getFg());
    }
    return this.shiplistFormGroup.getRawValue() as ShiplistFilter;
  }

  setUsers(): void {
    if (this.filter && this.usersInMyCompany) {
      const selectedUsers = this.usersInMyCompany.reduce((acc: User[], user: User) => {
        if (this.filter?.allowed_users?.includes(user.id || 0)) {
          acc.push(user);
        }
        return acc;
      }, []);
      this.shiplistFormGroup.controls.allowed_users_array?.patchValue(selectedUsers);
    }
  }

  showAnchor(value: ShiplistSelectedZoneOrPort | Port | GeoZone) {
    return !value?.is_zone;
  }

  onChangeValueOpenDates(value: FormGroup<FormDateSwitcherFormGroup>['value']): void {
    if (value?.dates_from) {
      const finalValue = this.convertDateToUTC(value?.dates_from);
      this.shiplistFormGroup.controls.open_dates_from_seconds?.patchValue(finalValue);
    }
    if (value?.dates_to) {
      const finalValue = this.convertDateToUTC(value?.dates_to);
      this.shiplistFormGroup.controls.open_dates_to_seconds?.patchValue(finalValue);
    }
    if (value?.updates_from_days || value?.updates_from_days === 0) {
      this.shiplistFormGroup.controls.open_dates_last_n_days?.patchValue(value?.updates_from_days);
    }
  }

  onChangeValueMessageDates(value: FormGroup<FormDateSwitcherFormGroup>['value']): void {
    if (value?.dates_from) {
      const finalValue = this.convertDateToUTC(value?.dates_from);
      this.shiplistFormGroup.controls.message_date_from_seconds?.patchValue(finalValue);
    }
    if (value?.dates_to) {
      const finalValue = this.convertDateToUTC(value?.dates_to);
      this.shiplistFormGroup.controls.message_date_to_seconds?.patchValue(finalValue);
    }
    if (value?.updates_from_days || value?.updates_from_days === 0) {
      this.shiplistFormGroup.controls.message_date_last_n_days?.patchValue(
        value?.updates_from_days
      );
    }
  }

  onSaveFilter(): void {
    this.saveFilter.emit();
  }

  convertDateToUTC(date: number | Date): number {
    let finalDate = new Date(date).setUTCHours(0, 0, 0, 0);
    finalDate = new Date(finalDate).setDate(new Date(date).getDate());
    finalDate = new Date(finalDate).setMonth(new Date(date).getMonth());
    finalDate = new Date(finalDate).setFullYear(new Date(date).getFullYear());
    return finalDate;
  }

  onClearLocations(): void {
    this.shiplistFormGroup.controls.filter_location?.patchValue([]);
  }
}
