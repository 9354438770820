export interface Social {
  name: string;
  icon: string;
  link: string;
}

export const FACEBOOK: Social = {
  name: 'Facebook',
  icon: 'facebook',
  link: 'https://www.facebook.com/xmarplatform',
};

export const TWITTER: Social = {
  name: 'Twitter',
  icon: 'twitter',
  link: 'https://twitter.com/xmarplatform',
};

export const INSTAGRAM: Social = {
  name: 'Instagram',
  icon: 'instagram',
  link: 'https://www.instagram.com/xmarltd/',
};

export const LINKEDIN: Social = {
  name: 'LinkedIn',
  icon: 'linkedin',
  link: 'https://www.linkedin.com/company/xmarplatform',
};

export const YOUTUBE: Social = {
  name: 'Youtube',
  icon: 'youtube',
  link: 'https://www.youtube.com/channel/UCVib--KCIjEoKoUTT-YkQWg',
};

export const SOCIALS: Social[] = [LINKEDIN];
