import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MaterialThemeColor, PrimeNgColors, PrimeNgIcons } from '@estimator/models';

@Component({
  selector: 'estimator-confirmation-code-form',
  templateUrl: './confirmation-code-form.component.html',
  styleUrls: ['./confirmation-code-form.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationCodeFormComponent implements OnInit {
  @Input() isLoading = false;
  @Output() sendConfirmCode = new EventEmitter<string>();
  @Output() retryEmail = new EventEmitter<void>();
  timer = 60;
  interval?: any;
  isTimerRunning = true;
  confrimCodeFormGroup = new FormGroup({
    confirmCode: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, Validators.minLength(6), Validators.maxLength(6)],
    }),
  });
  MaterialThemeColor = MaterialThemeColor;
  readonly PrimeNgColors = PrimeNgColors;
  readonly PrimeNgIcons = PrimeNgIcons;

  get isConfirmCodeControlInvalid(): boolean {
    return (
      !!this.confrimCodeFormGroup &&
      this.confrimCodeFormGroup.invalid &&
      this.confrimCodeFormGroup.controls.confirmCode.dirty &&
      this.confrimCodeFormGroup.controls.confirmCode.touched
    );
  }

  constructor(private readonly cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this._startTimer();
  }

  onSendConfirmCode(): void {
    this.sendConfirmCode.emit(this.confrimCodeFormGroup.value.confirmCode?.trim());
  }

  onRetryEmail(): void {
    this._startTimer();
    this.retryEmail.emit();
  }

  private _startTimer(): void {
    delete this.interval;
    this.timer = 60;
    this.isTimerRunning = true;
    this.interval = setInterval(() => {
      this.timer -= 1;
      if (this.timer === 0) {
        this.isTimerRunning = false;
        if (this.interval) {
          clearInterval(this.interval);
          delete this.interval;
        }
      }
      this.cdr.detectChanges();
    }, 1000);
  }
}
