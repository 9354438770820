<div class="registration-success-wrapper">
  <div class="success-card">
    <p class="success-icon" *ngIf="isSuccess">
      <mat-icon aria-hidden="false" aria-label="Done" fontIcon="done"></mat-icon>
    </p>
    <p class="success-icon" *ngIf="isError">
      <mat-icon aria-hidden="false" aria-label="Error" fontIcon="error"></mat-icon>
    </p>
    <mat-spinner *ngIf="isLoading" [color]="MaterialThemeColor.Accent"></mat-spinner>
    <p class="text">
      {{ cardText }}
    </p>
    <ng-content *ngIf="isContainContent"></ng-content>
    <mat-card-actions *ngIf="cardLink" class="w-100">
      <a [routerLink]="[cardLink]">
        <button mat-flat-button [color]="MaterialThemeColor.Accent" class="w-100" type="link">
          {{ cardLinkText }}
        </button>
      </a>
    </mat-card-actions>
  </div>
</div>
