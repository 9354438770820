<form [formGroup]="userDurationFormGroup" class="flex-container-row p-inputgroup primeng-form">
  <p-inputNumber
    [useGrouping]="false"
    [minFractionDigits]="0"
    [maxFractionDigits]="2"
    [min]="0"
    [step]="0"
    [formControl]="durationInputControl"
    class="p-inputtext-sm da-input"
    [ngClass]="{ 'none-border-radius-right': !hideDurationType }"
    [inputStyleClass]="hideDurationType ? '' : 'none-border-radius-right border-right-0'"
    [inputId]="id"
    [tabindex]="customTabIndex"
    estimatorDecimals
  ></p-inputNumber>
  <p-dropdown
    *ngIf="!hideDurationType"
    [options]="durationOptions"
    [styleClass]="
      hideDurationType
        ? 'w-full h-full'
        : 'w-full h-full zero-border-radius-left none-border-radius-left border-left-0'
    "
    class="p-inputtext-sm hide-dropdown-button da-dropdown"
    [inputId]="id + '-duration_type'"
    formControlName="units"
    appendTo="body"
  >
  </p-dropdown>
</form>
