import { AfterViewInit, Component, ViewChild, ViewContainerRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import * as moment from 'moment';

@Component({
  selector: 'estimator-time-duration-editor',
  template: `
    <form [formGroup]="durationForm" class="duration-form">
      <div class="duration-form-input">
        <input
          matInput
          type="number"
          formControlName="days"
          estimatorDecimals
          #daysInput
          class="aggrid-input"
        />
        &nbsp;
        <span matSuffix>d</span>
      </div>
      <div class="duration-form-input" *ngIf="!isDayFormat">
        <input matInput type="number" formControlName="hours" class="aggrid-input" />
        &nbsp;
        <span matSuffix>h</span>
      </div>
      <div class="duration-form-input" *ngIf="!isDayFormat">
        <input matInput type="number" formControlName="minutes" class="aggrid-input" />
        &nbsp;
        <span matSuffix>m</span>
      </div>
    </form>
  `,
  styles: [
    `
      .duration-form {
        width: 100%;
        height: 100%;
        border: none;
        background: none;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 2px;
        .duration-form-input {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          input {
            text-align: right;
            width: 100%;
            height: 100%;
            border: none;
            background: none;
          }
        }
      }
    `,
  ],
})
export class AgGridTimeDurationEditorComponent implements AfterViewInit, ICellEditorAngularComp {
  @ViewChild('daysInput', { read: ViewContainerRef }) input!: ViewContainerRef;
  value = 0;
  durationForm = new FormGroup({
    days: new FormControl(),
    hours: new FormControl(),
    minutes: new FormControl(),
  });
  format: 'd' | 'dhm' = 'dhm';
  count = 2;
  private params: any;

  get isDayFormat(): boolean {
    return this.format === 'd';
  }

  ngAfterViewInit() {
    window.setTimeout(() => {
      this.input.element.nativeElement.select();
    });
  }

  agInit(params: any): void {
    this.params = params;
    if (params.value) {
      this.value = params.value;
    }
    const duration = moment.duration(this.value, 'minutes');
    if (params.format === 'd') {
      const days = duration.asDays();
      this.durationForm.patchValue({ days });
      this.format = params.format;
    } else {
      const days = duration.days();
      const hours = duration.hours();
      const minutes = duration.minutes();
      this.durationForm.patchValue({ days, hours, minutes });
    }
  }

  getValue(): number | null {
    const { days, hours, minutes } = this.durationForm.value;
    const result = moment.duration({
      days,
      hours,
      minutes,
    });
    if (days === null && hours === null && minutes === null) {
      return null;
    }
    return Math.round(result.asMinutes());
  }
}
