import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[estimatorMegamenu]',
})
export class MegamenuDirective {
  isFullPanelVisible = false;

  constructor(private readonly el: ElementRef) {}

  @HostListener('click', ['$event'])
  onClick() {
    this.setMegamenuPanelPosition(event as MouseEvent);
  }
  @HostListener('mouseover', ['$event'])
  onMouseOver() {
    this.setMegamenuPanelPosition(event as MouseEvent);
  }

  setMegamenuPanelPosition(event: MouseEvent): void {
    const el = event?.target as HTMLElement;
    const megaMenuPanel = el?.parentNode?.nextSibling as HTMLElement;
    if (megaMenuPanel?.style) {
      megaMenuPanel.setAttribute('style', 'visibility: hidden;');
      this.createobserver(megaMenuPanel);
      setTimeout(() => {
        let topPosition;
        if (this.isFullPanelVisible) {
          topPosition = el.offsetTop;
        } else {
          topPosition = 0;
        }
        megaMenuPanel.setAttribute('style', `top: ${topPosition}px; visibility: unset;`);
      }, 50);
    }
  }

  createobserver(el: HTMLElement): void {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].boundingClientRect.width) {
          el.style.top = '0px';
          this.isFullPanelVisible = entries[0].isIntersecting;
        }
      },
      { threshold: 1.0 }
    );
    observer.observe(el);
  }
}
