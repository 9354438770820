<div>
  <input
    class="p-inputtext-sm w-100"
    styleClass="w-100"
    inputStyleClass="w-100"
    type="text"
    pInputText
    id="copy_folder_dialog_input_name"
    [formControl]="folderControl"
    [placeholder]="placeholderFolder"
  />
  <p *ngIf="filteredFolders?.length" class="mt-1 mb-1">This folder already existed</p>
  <div class="p-fluid mt-4">
    <p-button
      class="w-100"
      size="small"
      [label]="label"
      [disabled]="disabledButton() || disabledBtn || isLoadingSearch"
      type="button"
      (click)="onCopyFolder()"
      id="copy_folder_dialog_confirm_button"
    >
    </p-button>
  </div>
</div>
