import { Injectable } from '@angular/core';
import {
  GroupDataXBPIParsedArray,
  GroupDataXBPIParsedObject,
  Port,
  XBPIParsed,
} from '@estimator/models';
import { XmarBunkerPriceIndexService } from '@estimator/services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { catchError, finalize, tap, throwError } from 'rxjs';
import { GetHistoryData, GetHistoryPorts } from './xbpi-history.actions';

export interface XmarBunkerPriceIndexStateModel {
  parsedXBPI: GroupDataXBPIParsedArray[];
  historyPorts: Port[];
  loading: boolean;
}

@State<XmarBunkerPriceIndexStateModel>({
  name: 'xbpiHistory',
  defaults: {
    parsedXBPI: [],
    historyPorts: [],
    loading: false,
  },
})
@Injectable()
export class XmarBunkerPriceIndexState {
  @Selector()
  static getParsedXBPI({ parsedXBPI }: XmarBunkerPriceIndexStateModel): GroupDataXBPIParsedArray[] {
    return parsedXBPI;
  }
  @Selector()
  static getHistoryPorts({ historyPorts }: XmarBunkerPriceIndexStateModel): Port[] {
    return historyPorts;
  }
  @Selector()
  static getIsLoading({ loading }: XmarBunkerPriceIndexStateModel): boolean {
    return loading;
  }

  constructor(private readonly xBPISrv: XmarBunkerPriceIndexService) {}

  @Action(GetHistoryData)
  getHistoryData(
    { patchState }: StateContext<XmarBunkerPriceIndexStateModel>,
    { data }: GetHistoryData
  ) {
    patchState({ loading: true });
    return this.xBPISrv.getHistoryData(data).pipe(
      tap((res) => {
        const parsedXBPI = this.getTransformXBPIData(res?.bunker_prices);
        patchState({ parsedXBPI });
      }),
      catchError((err) => {
        patchState({ loading: false });
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }

  @Action(GetHistoryPorts)
  getHistoryPorts({ patchState }: StateContext<XmarBunkerPriceIndexStateModel>) {
    patchState({ loading: true });
    return this.xBPISrv.getHistoryPorts().pipe(
      tap((res) => {
        patchState({ historyPorts: res.ports });
      }),
      catchError((err) => {
        patchState({ loading: false });
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }

  // similar to groupedData
  groupByDate(data: XBPIParsed[]): GroupDataXBPIParsedObject {
    const groupedData: GroupDataXBPIParsedObject = {};
    data.forEach((item: XBPIParsed) => {
      if (!groupedData[item.date]) {
        groupedData[item.date] = [];
      }
      groupedData[item.date].push(item);
    });
    return groupedData;
  }

  getTransformXBPIData(data: XBPIParsed[]): GroupDataXBPIParsedArray[] {
    if (data?.length) {
      const groupedData = this.groupByDate(data);
      const dataArray: GroupDataXBPIParsedArray[] = Object.keys(groupedData).map((date) => ({
        date,
        data: groupedData[date],
      }));
      return dataArray;
    }
    return [];
  }
}
