import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { localDateTimeFormat } from '@estimator/helpers';
import {
  AddVesselToMyOrCompanyFleetProps,
  FleetVesselPositions,
  MaterialThemeColor,
  OurPrimeCalendarSelectionMode,
  OurPrimeCalendarSelectionModeType,
  Vessel,
} from '@estimator/models';

@Component({
  selector: 'estimator-vessel-widget',
  templateUrl: './vessel-widget.component.html',
  styleUrls: ['./vessel-widget.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VesselWidgetComponent {
  @Input()
  set vessel(vessel: Partial<Vessel> | undefined) {
    this._vessel = vessel;
  }
  get vessel(): Partial<Vessel> | undefined {
    return this._vessel;
  }
  @Input() isFromSearchVesselFinder = true;
  @Output() addVesselToMyFleetOrCompanyFleet = new EventEmitter<AddVesselToMyOrCompanyFleetProps>();
  @Output() requestHistoryPosition = new EventEmitter<FleetVesselPositions>();
  selectionMode: OurPrimeCalendarSelectionMode = OurPrimeCalendarSelectionModeType.RANGE;
  localDateTimeFormat = localDateTimeFormat;
  readonly MaterialThemeColor = MaterialThemeColor;
  private _vessel?: Partial<Vessel> | undefined;

  addVesselToFleet(vessel: Partial<Vessel> | undefined, forCompany = true) {
    const data: AddVesselToMyOrCompanyFleetProps = {
      for_company: forCompany,
      raw_vessel_id: vessel?.id,
    };
    this.addVesselToMyFleetOrCompanyFleet.emit(data);
  }

  onSelectDate(value: Date[]) {
    const requestPosition = {
      fleet_vessel_id: this.vessel?.fleet_id as number,
      time_from: value[0]?.getTime(),
      time_to: value[1]?.getTime(),
    };
    this.requestHistoryPosition.emit(requestPosition);
  }
}
