<div>
  <p-autoComplete
    [formControl]="folderControl"
    [suggestions]="filteredFolders"
    (completeMethod)="onDealSearchFolder($event)"
    [showEmptyMessage]="true"
    [minLength]="minValueLength"
    [maxlength]="maxValueLength"
    emptyMessage="No folders found"
    appendTo="body"
    field="name"
    dataKey="id"
    class="p-inputtext-sm w-100 h-100"
    styleClass="w-100"
    inputStyleClass="w-100"
    inputId="copy_deal_dialog_input_name"
    [placeholder]="placeholderFolder"
    [tabindex]="1"
  >
  </p-autoComplete>
  <div class="p-fluid mt-4">
    <p-button
      class="w-100"
      size="small"
      [label]="label"
      [disabled]="disabledSaveFolder"
      type="button"
      (click)="onSaveFolder()"
      id="dialog_save_folder_button"
    >
    </p-button>
  </div>
</div>
