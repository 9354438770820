import {
  AfterContentChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import {
  cubicMetersToFeet,
  feetToCubicMeters,
  feetToMeters,
  filterArray,
  getCurrencyShortSymbol,
  getUserOrSystemDuration,
  makeBaseEntityPartFormGroup,
  makeCargoCalculationFormGroup,
  metersToFeet,
  ZeroNullValidator,
} from '@estimator/helpers';
import { defaultUserDuration, PortSeasonTypes, PortWaterTypes } from '@estimator/mocks';
import {
  BaseModel,
  Cargo,
  CARGO_TABLE_COLUMNS,
  cargoesFormTypesMock,
  CargoFinance,
  CargoForm,
  CargoFreightType,
  CargoTableColumnType,
  CargoTableFreightType,
  ChangeAllowedTime,
  ChangeValidation,
  Company,
  Currency,
  DealFormHasChanges,
  DEFAULT_AUTOSAVE,
  DEFAULT_FIAT_MULTIPLIER,
  DomEvents,
  FOURTY_NUMBER,
  FULL_PERCENTS,
  GrainCalculationType,
  LaytimeType,
  LoadPortDraftRestrictionUnitsType,
  LoadPortDraftRestrictionUnitsTypes,
  MAX_FINANCE_VALUE,
  PortSeason,
  PortWater,
  PrimeNgIcons,
  StowageFactorUnitsType,
  StowageFactorUnitsTypes,
  SummLDRateAndETbyCargoID,
  TEXT_CBM,
  THREE_NUMBER,
  UserInputDuration,
  UserInputDurationType,
} from '@estimator/models';
import { LaytimeLabel } from '@vms-models';
import { cloneDeep, isEqual, isObject, isString } from 'lodash';
import { AutoCompleteCompleteEvent, AutoCompleteSelectEvent } from 'primeng/autocomplete';
import { OverlayPanel } from 'primeng/overlaypanel';
import { debounceTime, filter, map, pairwise, startWith, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'estimator-cargoes-form',
  templateUrl: './cargoes-form.component.html',
  styleUrls: ['./cargoes-form.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CargoesFormComponent implements OnDestroy, AfterContentChecked {
  static makeCargoFormGroup(
    cargo?: Cargo,
    showLumpsum?: boolean,
    grainBaleCalculationType?: GrainCalculationType
  ): FormGroup<CargoForm> {
    const cargoFormGroup: FormGroup<CargoForm> = new FormGroup(
      {
        ...makeBaseEntityPartFormGroup(cargo).controls,
        // cargo_calculator: new FormControl<CargoCalculator | null>(cargo?.cargo_calculator || null),
        cargo_calculator: makeCargoCalculationFormGroup(
          cargo?.cargo_calculator,
          grainBaleCalculationType,
          true
        ),
        cargo_type: new FormControl<BaseModel | null>(cargo?.cargo_type || null),
        cargo_type_id: new FormControl<number | null>(cargo?.cargo_type_id || null),
        company: new FormControl<Company | null>(cargo?.company || null, {
          updateOn: DomEvents.Blur,
        }),
        company_id: new FormControl<number | null>(cargo?.company_id || null),
        currency: new FormControl<Currency | null>(cargo?.currency || null),
        currency_id: new FormControl<number | null>(cargo?.currency_id || null),
        deal_id: new FormControl<number | null>(cargo?.deal_id || null),
        dimension: new FormControl<BaseModel | null>(cargo?.dimension || null),
        dimension_id: new FormControl<number | null>(cargo?.dimension_id || null),
        lumpsum: new FormControl<number | null>(cargo?.lumpsum || (showLumpsum ? 0 : null)),
        freight_lumpsum: new FormControl<number | null>(cargo?.freight_lumpsum || null),
        freight_type: new FormControl<CargoFreightType | null>(
          cargo?.freight_type?.id ? cargo.freight_type : null
        ),
        freight_type_id: new FormControl<number | null>(cargo?.freight_type_id || null),
        quantity: new FormControl<number | null>(
          cargo?.quantity || cargo?.quantity === 0 ? cargo?.quantity : null,
          {
            asyncValidators: ZeroNullValidator(/* true */),
          }
        ),
        amount_type: new FormControl<CargoTableFreightType | null>(cargo?.amount_type || null),
        ws_percent: new FormControl<number | null>(cargo?.ws_percent || 0),
        internal_order: new FormControl<number | null>(cargo?.internal_order || null),
        price: new FormControl<number | null>(cargo?.price || 0),
        voyage_commission: new FormControl<number | null>(
          cargo?.voyage_commission || cargo?.voyage_commission === 0
            ? cargo?.voyage_commission
            : null,
          {
            asyncValidators: ZeroNullValidator(true),
          }
        ),
        laytime_type: new FormControl<LaytimeType | null>(cargo?.laytime_type || LaytimeType.Off),
        show_intake: new FormControl<boolean | null>(cargo?.show_intake || false),
        allowed_time: new FormControl<UserInputDuration | null>(
          cargo?.allowed_time || defaultUserDuration
        ),
        demurrage_rate: new FormControl<number | null>(cargo?.demurrage_rate || 0),
        /*  extra_demurrage_time: new FormControl<UserInputDuration | null>(
          cargo?.extra_demurrage_time || defaultUserDuration
        ), */
        description: new FormControl<string | null>(cargo?.description || ''),
      },
      { updateOn: DomEvents.Blur }
    );
    return cargoFormGroup;
  }

  @ViewChild('cargoesAction', { read: ElementRef }) cargoesAction?: ElementRef;
  @ViewChild('overlay') overlay?: OverlayPanel;

  @HostListener('document:keydown', ['$event'])
  addEventHotKey(event: KeyboardEvent): void {
    if (event.ctrlKey && event.key === 'q') {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
      this.cargoFormArray.controls.forEach((control: FormGroup<CargoForm>) => {
        this.onCopyQuantity(control);
      });
    }
  }

  @Input() set cargoes(data: Cargo[]) {
    this._cargoes = cloneDeep(data);
    this.cargoFormArray.clear({ emitEvent: false });
    if (this._cargoes.length) {
      this._cargoes.forEach((cargo, index) => {
        const fg = CargoesFormComponent.makeCargoFormGroup(
          cargo,
          this.showLumpsum(cargo?.amount_type as CargoTableFreightType),
          this.grainBaleCalculationType
        );
        if (this.isFixed) {
          fg.disable();
        }
        this.setValidationErrorsToQuantity(cargo, index);
        const intitialValue = fg.value;
        fg.valueChanges
          .pipe(
            debounceTime(/* this.defaultAutoSave */ 100),
            takeUntil(this._onDestroy$),
            startWith(intitialValue),
            pairwise(),
            filter(([prev, curr]) => {
              this.prevEditedCargoValue = prev as Partial<Cargo>;
              this.currEditedCargoValue = curr as Partial<Cargo>;
              /* if (
                isString(curr.company) &&
                curr?.company?.length >= THREE_NUMBER &&
                !this.companies.some(
                  (company) => isString(curr.company) && company.name === curr.company
                )
              ) {
                this.addCompany.emit({ companyName: curr.company, updatedCargo: curr as Cargo });
              } */
              return (
                !isEqual(curr, prev) &&
                !isString(curr.company_id) &&
                !isString(curr.freight_type) &&
                !isObject(curr.company_id) &&
                !isString(curr.company)
              );
            }),
            map(([prev, curr]) => {
              if (!isEqual(prev.quantity, curr.quantity)) {
                this.changeCargoQuantity.emit(curr as Cargo);
              }
              // console.log('cargo form emit changes', curr, changedKeys(prev, curr));
              return curr;
            })
          )
          .subscribe((cargo) => {
            if (cargo) {
              if (
                isEqual(
                  this.prevEditedCargoValue?.cargo_calculator,
                  this.currEditedCargoValue?.cargo_calculator
                )
              ) {
                if (this.isTanker) {
                  const currAllowedTime = this.currEditedCargoValue?.allowed_time?.user;
                  const changeAllowedTime =
                    this.prevEditedCargoValue?.allowed_time?.user !== currAllowedTime;
                  if (changeAllowedTime && currAllowedTime) {
                    this.changeAllowedTime.emit({
                      cargoId: this.currEditedCargoValue?.id || 0,
                      allowTime: currAllowedTime,
                      allowTimeType:
                        this.currEditedCargoValue?.allowed_time?.units ||
                        UserInputDurationType.Hours,
                    });
                  }
                }

                this.setValidationErrorsToQuantity(cargo as Cargo, index);
                const notChangeCargoName =
                  this.prevEditedCargoValue?.freight_type?.id ===
                  this.currEditedCargoValue?.freight_type?.id;
                const changeFreightRate =
                  this.prevEditedCargoValue?.price !== this.currEditedCargoValue?.price;
                const changeWSPercent =
                  this.prevEditedCargoValue?.ws_percent !== this.currEditedCargoValue?.ws_percent;
                const changeVoyageCommission =
                  this.prevEditedCargoValue?.voyage_commission !==
                  this.currEditedCargoValue?.voyage_commission;
                const changeLumpsum =
                  this.prevEditedCargoValue?.lumpsum !== this.currEditedCargoValue?.lumpsum;
                this.hasChanges.emit({
                  change: true,
                  estimate:
                    notChangeCargoName &&
                    !changeFreightRate &&
                    !changeVoyageCommission &&
                    !changeWSPercent &&
                    !changeLumpsum,
                  estimateFinances:
                    changeFreightRate || changeVoyageCommission || changeWSPercent || changeLumpsum,
                });
              } else {
                if (
                  this.prevEditedCargoValue?.cargo_calculator?.stowage_factor_units_type ===
                  this.currEditedCargoValue?.cargo_calculator?.stowage_factor_units_type
                ) {
                  // заходим сюда, если только не был дернут switcher
                  if (
                    this.prevEditedCargoValue?.cargo_calculator?.stowage_factor !==
                    this.currEditedCargoValue?.cargo_calculator?.stowage_factor
                  ) {
                    // изменяется контрол stowage_factor
                    if (this.currEditedCargoValue?.cargo_calculator?.stowage_factor) {
                      // если открыт контрол stowage_factor и ввели больше 10, то запускаем процесс подмены контрола на stowage_factor_ft
                      if (this.currEditedCargoValue?.cargo_calculator?.stowage_factor > 10) {
                        this.currEditedCargoValue.cargo_calculator.stowage_factor_units_type =
                          StowageFactorUnitsType.StowageFactorCFT;
                        // stowage_factor_ft = stowage_factor_ft
                        this.currEditedCargoValue.cargo_calculator.stowage_factor_ft =
                          this.currEditedCargoValue?.cargo_calculator?.stowage_factor;
                        // stowage_factor = feetToCubicMeters(stowage_factor)
                        this.currEditedCargoValue.cargo_calculator.stowage_factor =
                          feetToCubicMeters(
                            this.currEditedCargoValue?.cargo_calculator?.stowage_factor || 0
                          ) || 0;
                      } else {
                        /*  this.currEditedCargoValue.cargo_calculator.stowage_factor_units_type =
                          StowageFactorUnitsType.StowageFactorCBM; */
                        // stowage_factor_ft = cubicMetersToFeet(stowage_factor)
                        this.currEditedCargoValue.cargo_calculator.stowage_factor_ft =
                          cubicMetersToFeet(
                            this.currEditedCargoValue?.cargo_calculator?.stowage_factor || 0
                          ) || 0;
                      }
                    }
                  }

                  // изменяется контрол stowage_factor_ft
                  if (
                    this.prevEditedCargoValue?.cargo_calculator?.stowage_factor_ft !==
                    this.currEditedCargoValue?.cargo_calculator?.stowage_factor_ft
                  ) {
                    if (this.currEditedCargoValue?.cargo_calculator?.stowage_factor_ft) {
                      // если открыт контрол stowage_factor_ft и ввели меньше или равно 10, то запускаем процесс подмены контрола на stowage_factor
                      if (this.currEditedCargoValue?.cargo_calculator?.stowage_factor_ft <= 10) {
                        this.currEditedCargoValue.cargo_calculator.stowage_factor_units_type =
                          StowageFactorUnitsType.StowageFactorCBM;
                        // stowage_factor = stowage_factor_ft
                        this.currEditedCargoValue.cargo_calculator.stowage_factor =
                          this.currEditedCargoValue?.cargo_calculator?.stowage_factor_ft;
                        // stowage_factor_ft = cubicMetersToFeet(stowage_factor_ft)
                        this.currEditedCargoValue.cargo_calculator.stowage_factor_ft =
                          cubicMetersToFeet(
                            this.currEditedCargoValue?.cargo_calculator?.stowage_factor_ft || 0
                          ) || 0;
                      } else {
                        /* this.currEditedCargoValue.cargo_calculator.stowage_factor_units_type =
                          StowageFactorUnitsType.StowageFactorCFT; */
                        // stowage_factor = cubicMetersToFeet(stowage_factor_ft)
                        this.currEditedCargoValue.cargo_calculator.stowage_factor =
                          feetToCubicMeters(
                            this.currEditedCargoValue?.cargo_calculator?.stowage_factor_ft || 0
                          ) || 0;
                      }
                    }
                  }
                }

                // check for control changes load_port_draft_restriction_units_type
                if (
                  this.prevEditedCargoValue?.cargo_calculator
                    ?.load_port_draft_restriction_units_type ===
                  this.currEditedCargoValue?.cargo_calculator
                    ?.load_port_draft_restriction_units_type
                ) {
                  // enter here, if the user has not switched the type
                  // check the data in the control load_port_draft_restriction_ft
                  if (
                    this.prevEditedCargoValue?.cargo_calculator?.load_port_draft_restriction_ft !==
                    this.currEditedCargoValue?.cargo_calculator?.load_port_draft_restriction_ft
                  ) {
                    // user enters a value into the load_port_draft_restriction_ft control or
                    // completely deletes a record, then recalculate the data
                    if (
                      this.currEditedCargoValue?.cargo_calculator?.load_port_draft_restriction_ft ||
                      this.currEditedCargoValue?.cargo_calculator
                        ?.load_port_draft_restriction_ft === 0
                    ) {
                      this.currEditedCargoValue.cargo_calculator.load_port_draft_restriction =
                        feetToMeters(
                          this.currEditedCargoValue?.cargo_calculator
                            ?.load_port_draft_restriction_ft || 0
                        ) || 0;
                    }
                  }

                  // check the data in the control load_port_draft_restriction
                  if (
                    this.prevEditedCargoValue?.cargo_calculator?.load_port_draft_restriction !==
                    this.currEditedCargoValue?.cargo_calculator?.load_port_draft_restriction
                  ) {
                    // user enters a value into the load_port_draft_restriction_ft control or
                    // completely deletes a record, then recalculate the data
                    if (
                      this.currEditedCargoValue?.cargo_calculator?.load_port_draft_restriction ||
                      this.currEditedCargoValue?.cargo_calculator?.load_port_draft_restriction === 0
                    ) {
                      this.currEditedCargoValue.cargo_calculator.load_port_draft_restriction_ft =
                        metersToFeet(
                          this.currEditedCargoValue?.cargo_calculator
                            ?.load_port_draft_restriction || 0
                        ) || 0;
                    }
                  }
                }

                this.calculate.emit(this.currEditedCargoValue);
              }
            }
          });
        this.cargoFormArray.push(fg);
      });
      this.cargoFormReady.emit();
      this.recalculateInternalOrders();
    }
  }

  get cargoes(): Cargo[] {
    return this._cargoes;
  }

  @Input()
  set freightTypes(data: CargoFreightType[]) {
    this._freighTypes = cloneDeep(data).filter((type) =>
      this.isTanker ? type.is_liquid : !type.is_liquid
    );
    this.filteredFreightTypes = cloneDeep(data).filter((type) =>
      this.isTanker ? type.is_liquid : !type.is_liquid
    );
  }

  get freightTypes(): CargoFreightType[] {
    return this._freighTypes;
  }

  @Input()
  set isTanker(value: boolean) {
    this._isTanker = value;
    this.stateKey = `cargo_form_table-${this.isTanker}`;
  }

  get isTanker(): boolean {
    return this._isTanker;
  }

  @Input()
  set isFixed(value: boolean) {
    this._isFixed = value;
  }

  get isFixed(): boolean {
    return this._isFixed;
  }

  @Input()
  set cargoFinances(cargoFinances: CargoFinance[]) {
    this._cargoFinances = cargoFinances;
  }

  get cargoFinances(): CargoFinance[] {
    return this._cargoFinances;
  }

  @Input() dealId = 0;
  @Input() currencies: Currency[] = [];
  @Input() companies: Company[] = [];
  @Input() loading = false;
  @Input() defaultAutoSave = DEFAULT_AUTOSAVE;
  @Input() grainBaleCalculationType: GrainCalculationType =
    GrainCalculationType.GrainCalculationType;
  @Input() breakEvenArr: { [key: string]: number }[] = [];
  @Input() summLDRateAndETbyCargoID: SummLDRateAndETbyCargoID[] = [];
  @Input() summETwithoutCargoID = 0;
  @Output() addCompany = new EventEmitter<{ companyName: string; updatedCargo: Cargo }>();
  @Output() addCargo = new EventEmitter<void>();
  @Output() deleteCargo = new EventEmitter<number>();
  @Output() searchCompany = new EventEmitter<string>();
  @Output() hasChanges = new EventEmitter<DealFormHasChanges>();
  @Output() changeCargoQuantity = new EventEmitter<Cargo>();
  @Output() cargoFormReady = new EventEmitter<void>();
  @Output() addDemDes = new EventEmitter<FormGroup<CargoForm>>();
  @Output() deleteDemDes = new EventEmitter<FormGroup<CargoForm>>();
  @Output() changeValidationErrors = new EventEmitter<ChangeValidation>();
  @Output() calculate = new EventEmitter<Cargo>();
  @Output() updateCargoQuantity = new EventEmitter<Cargo>();
  @Output() openCargoCalculator = new EventEmitter<Cargo>();
  @Output() changeAllowedTime = new EventEmitter<ChangeAllowedTime>();
  @Output() openCargoDescription = new EventEmitter<number>();
  cargoFormArray = new FormArray<FormGroup<CargoForm>>([]);
  filteredFreightTypes: CargoFreightType[] = [];
  tabIndex = 200;
  stateKey = '';
  prevEditedCargoValue?: Partial<Cargo>;
  currEditedCargoValue?: Partial<Cargo>;
  readonly PrimeNgIcons = PrimeNgIcons;
  readonly columns = CARGO_TABLE_COLUMNS;
  readonly CargoTableColumnType = CargoTableColumnType;
  readonly getCurrencySymbol = getCurrencyShortSymbol;
  readonly cargoesFormTypesMock = cargoesFormTypesMock;
  readonly CargoTableFreightType = CargoTableFreightType;
  readonly LaytimeLabel = LaytimeLabel;
  readonly MAX_FINANCE_VALUE = MAX_FINANCE_VALUE;
  readonly TEXT_CBM = TEXT_CBM;
  readonly portWaterTypes: PortWater[] = PortWaterTypes;
  readonly portSeasonTypes: PortSeason[] = PortSeasonTypes;
  readonly stowageFactorUnitsTypes = StowageFactorUnitsTypes;
  readonly loadPortDraftRestrictionUnitsTypes = LoadPortDraftRestrictionUnitsTypes;
  readonly minValueLength = THREE_NUMBER;
  readonly emptyDuration: UserInputDuration = {};
  private _cargoes: Cargo[] = [];
  private _isTanker = false;
  private _freighTypes: CargoFreightType[] = [];
  private _onDestroy$ = new Subject<void>();
  private _isFixed = false;
  private _cargoFinances: CargoFinance[] = [];
  private _mockFormControl = new FormControl();
  /**
   * Represents the currently active or selected cargo form. Used when p-overlay is called.
   */
  private _currentCargoForm: FormGroup<CargoForm> | null = null;

  /**
   * Gets the description control of the current cargo form.
   * @returns The FormControl for the description field, or _mockFormControl if no current form is set.
   */
  get descriptionControl(): FormControl<string | null> {
    return this._currentCargoForm?.controls.description || this._mockFormControl;
  }

  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterContentChecked(): void {
    if (this.isFixed) {
      this.cargoFormArray.disable();
    }
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  onAddCargo(): void {
    this.addCargo.emit();
  }

  onDeleteCargo(index: number, cargoForm: FormGroup<CargoForm>): void {
    const { id } = this.cargoFormArray.controls[index].value;
    this.deleteCargo.emit(id);
    this.deleteDemDes.emit(cargoForm);
  }

  recalculateInternalOrders(): void {
    this.cargoFormArray.controls.forEach((control, index) => {
      control.controls?.internal_order.patchValue(index + 1);
    });
    this.cdr.detectChanges();
  }

  onSearchCompany(event: AutoCompleteCompleteEvent): void {
    if (event.query?.length >= THREE_NUMBER && event.query?.length <= FOURTY_NUMBER) {
      this.searchCompany.emit(event.query);
    }
  }

  createCompany(cargoForm: FormGroup<CargoForm>): void {
    if (isString(cargoForm.value.company)) {
      this.addCompany.emit({
        companyName: cargoForm.value.company,
        updatedCargo: cargoForm.value as Cargo,
      });
    }
  }

  onSearchFreightType(event?: AutoCompleteCompleteEvent): void {
    if (event?.query) {
      this.filteredFreightTypes = filterArray(this.freightTypes, event?.query);
    }
  }

  onSelectCompany(event: AutoCompleteSelectEvent, cargoForm: FormGroup<CargoForm>): void {
    const company: Company = event.value;
    if (company.id) {
      cargoForm.patchValue({ company_id: company.id });
      cargoForm.patchValue({ company: company });
    }
  }

  onBlurCompany(event: Event, cargoForm: FormGroup<CargoForm>): void {
    const target = event.target as HTMLInputElement;
    if (target.value.length === 0 && cargoForm.value.company_id) {
      cargoForm.patchValue({ company: null, company_id: null });
    }
  }

  prepareCargoesToSave(): Cargo[] {
    const fgValue = this.cargoFormArray.value as Cargo[];
    fgValue.forEach((cargo) => {
      if (!isObject(cargo.freight_type)) {
        cargo.freight_type = null;
        cargo.freight_type_id = null;
      }
      if (!isObject(cargo.company)) {
        cargo.company = null;
        cargo.company_id = null;
      }
    });
    return fgValue;
  }

  getActualCargoes() {
    return this.cargoFormArray.value as Cargo[];
  }

  /*   onAmountTypeChange(event: CargoTableFreightType, cargoForm: FormGroup<CargoForm>): void {
    cargoForm.controls.amount_type.patchValue(event);
  } */

  onTypeChange(event: CargoTableFreightType, cargoForm: FormGroup<CargoForm>): void {
    cargoForm.controls.amount_type.patchValue(event);
    const amountType = event;
    cargoForm.controls.price.reset();
    cargoForm.controls.ws_percent.reset();
    cargoForm.controls.lumpsum.reset();
    if (
      amountType === CargoTableFreightType.AmountTypeRate ||
      amountType === CargoTableFreightType.AmountTypeLumpSum
    ) {
      cargoForm.controls.ws_percent.patchValue(FULL_PERCENTS);
    }
  }

  onChangeQuantity(blurEvent: Event, cargoForm: FormGroup<CargoForm>): void {
    if (this.isTanker) {
      const htmlElement = blurEvent?.target as HTMLInputElement;
      const valueHtmlElement = +htmlElement?.value.replace(/\s/g, '');

      this.setWSPercent(cargoForm, valueHtmlElement);
    }
  }

  // change ws_percent => change price
  onChangeWsPercent(blurEvent: Event, cargoForm: FormGroup<CargoForm>): void {
    if (this.isTanker) {
      const htmlElement = blurEvent?.target as HTMLInputElement;
      const valueHtmlElement = +htmlElement?.value.replace(/\s/g, '');
      /* if (valueHtmlElement) {
        cargoForm.controls.lumpsum.reset();
      } */

      const lumpsum = cargoForm.controls.lumpsum.value;
      const wsPercent = valueHtmlElement;
      const quantity = cargoForm.controls.quantity.value;
      if (lumpsum && wsPercent && quantity) {
        // frt_rate = (lumpsum / ws_percent / quantity) * 100
        const freightRate = (lumpsum / wsPercent / quantity) * FULL_PERCENTS;
        cargoForm.controls.price.patchValue(Math.round(freightRate), {
          emitEvent: false,
        });
      }
    }
  }

  // change price | lumpsum => change ws_percent
  onChangePrice(cargoForm: FormGroup<CargoForm>, field: keyof CargoForm): void {
    if (this.isTanker) {
      // cargoForm.controls[field]?.reset();
      this.setWSPercent(cargoForm, cargoForm.controls.quantity.value);
    }
  }

  setWSPercent(cargoForm: FormGroup<CargoForm>, quantity: number | null): void {
    const lumpsum = cargoForm.controls.lumpsum.value;
    const freightRate = cargoForm.controls.price.value;
    if (lumpsum && freightRate && quantity) {
      // ws_percent = (lumpsum / frt_rate / quantity) * 100
      const wsPercent = (lumpsum / freightRate / quantity) * FULL_PERCENTS;
      cargoForm.controls.ws_percent.patchValue(+wsPercent.toFixed(2), { emitEvent: false });
    }
  }

  showLumpsum(amountType: CargoTableFreightType): boolean {
    return this.isTanker ? amountType === CargoTableFreightType.AmountTypeLumpSum : true;
  }

  onAddOrDeleteDemurrage(cargoForm: FormGroup<CargoForm>): void {
    const formValue = cargoForm.getRawValue() as Cargo;
    if (formValue?.laytime_type === LaytimeType.Off) {
      this.addDemDes.emit(cargoForm);
    } else {
      this.deleteDemDes.emit(cargoForm);
    }
  }

  getDemDesButtonLabel(cargoForm: FormGroup<CargoForm>): string {
    const formValue = cargoForm.getRawValue() as Cargo;
    return formValue?.laytime_type === LaytimeType.Off ? 'Add' : 'Delete';
  }

  setValidationErrorsToQuantity(cargo: Cargo, index: number): void {
    if (cargo.quantity && cargo.quantity >= 0) {
      this.changeValidationErrors.emit({ field: `quantity-${index}`, remove: true });
    } else {
      this.changeValidationErrors.emit({ field: `quantity-${index}` });
    }

    if (cargo.voyage_commission || cargo.voyage_commission === 0) {
      this.changeValidationErrors.emit({ field: `voyage_commission-${index}`, remove: true });
    } else {
      this.changeValidationErrors.emit({ field: `voyage_commission-${index}` });
    }
  }

  /* setBreakEven(value: number, i: number): void {
    this.cargoFormArray.controls[i].patchValue({
      price: +(value * DEFAULT_FIAT_MULTIPLIER).toFixed(2),
    });
  } */

  showAddCargoIcon(index: number): boolean {
    return index === 0;
  }

  onLoadPortSeasonTypeChange(event: string, cargoForm: FormGroup<CargoForm>): void {
    const value = PortSeasonTypes.find((type) => type.name === event)?.value;
    if (value) {
      cargoForm.controls.cargo_calculator.controls.load_port_season_type.patchValue(value);
    }
  }

  openHideIntake(cargoForm: FormGroup<CargoForm>): void {
    const prevValue = cargoForm.controls.show_intake.value;
    cargoForm.controls.show_intake.patchValue(!prevValue, { emitEvent: false });
  }

  getOpenHideIntakeIcon(
    cargoForm: FormGroup<CargoForm>
  ): typeof PrimeNgIcons.CHEVRON_DOWN | typeof PrimeNgIcons.CHEVRON_UP {
    return cargoForm.controls.show_intake.value
      ? PrimeNgIcons.CHEVRON_UP
      : PrimeNgIcons.CHEVRON_DOWN;
  }

  onCopyQuantity(cargoForm: FormGroup<CargoForm>): void {
    const cargo = cargoForm.getRawValue() as Cargo;
    const cargoIntake =
      cargoForm?.controls?.cargo_calculator?.controls?.summ_max_fields?.value || 0;
    if (cargo) {
      cargo.quantity = cargoIntake;
      this.updateCargoQuantity.emit(cargo);
    }
  }

  getStowFactorControl(cargoForm: FormGroup<CargoForm>): FormControl {
    if (
      cargoForm.controls.cargo_calculator?.controls.stowage_factor_units_type.value ===
      StowageFactorUnitsType.StowageFactorCFT
    ) {
      return cargoForm.controls.cargo_calculator.controls.stowage_factor_ft;
    }
    return cargoForm.controls.cargo_calculator.controls.stowage_factor;
  }

  /*   getStowFactorText(cargoForm: FormGroup<CargoForm>): typeof TEXT_CFT | typeof TEXT_CBM {
    if (
      cargoForm.controls.cargo_calculator?.controls.stowage_factor_units_type.value ===
      StowageFactorUnitsType.StowageFactorCFT
    ) {
      return TEXT_CFT;
    }
    return TEXT_CBM;
  } */

  onStowageFactorUnitsTypeChange(
    cargoForm: FormGroup<CargoForm>,
    value: StowageFactorUnitsType
  ): void {
    cargoForm.controls.cargo_calculator.controls.stowage_factor_units_type.patchValue(value);

    // было CFT, был открыт stowage_factor_ft
    if (value === StowageFactorUnitsType.StowageFactorCBM) {
      cargoForm.controls.cargo_calculator.controls.stowage_factor.patchValue(
        cargoForm.controls.cargo_calculator.controls.stowage_factor_ft.value
      );
      const stowageFactorFT =
        cubicMetersToFeet(cargoForm.controls.cargo_calculator.controls.stowage_factor.value || 0) ||
        0;
      cargoForm.controls.cargo_calculator.controls.stowage_factor_ft.patchValue(stowageFactorFT);
    } else {
      // было CBM, был открыт stowage_factor
      cargoForm.controls.cargo_calculator.controls.stowage_factor_ft.patchValue(
        cargoForm.controls.cargo_calculator.controls.stowage_factor.value
      );
      const stowageFactor =
        feetToCubicMeters(
          cargoForm.controls.cargo_calculator.controls.stowage_factor_ft.value || 0
        ) || 0;
      cargoForm.controls.cargo_calculator.controls.stowage_factor.patchValue(stowageFactor);
    }
  }

  // TODO: It is important to consider separating the same code
  //  from the onStowageFactorUnitsTypeChange and onLoadPortDraftRestrictionUnitsTypeChange methods
  onLoadPortDraftRestrictionUnitsTypeChange(
    cargoForm: FormGroup<CargoForm>,
    value: LoadPortDraftRestrictionUnitsType
  ): void {
    cargoForm.controls.cargo_calculator.controls.load_port_draft_restriction_units_type.patchValue(
      value
    );

    if (value === LoadPortDraftRestrictionUnitsType.Meters) {
      cargoForm.controls.cargo_calculator.controls.load_port_draft_restriction.patchValue(
        cargoForm.controls.cargo_calculator.controls.load_port_draft_restriction_ft.value
      );
      const loadPortDraftRestrictionFT =
        metersToFeet(
          cargoForm.controls.cargo_calculator.controls.load_port_draft_restriction.value || 0
        ) || 0;
      cargoForm.controls.cargo_calculator.controls.load_port_draft_restriction_ft.patchValue(
        loadPortDraftRestrictionFT
      );
    } else {
      cargoForm.controls.cargo_calculator.controls.load_port_draft_restriction_ft.patchValue(
        cargoForm.controls.cargo_calculator.controls.load_port_draft_restriction.value
      );
      const loadPortDraftRestriction =
        feetToMeters(
          cargoForm.controls.cargo_calculator.controls.load_port_draft_restriction_ft.value || 0
        ) || 0;
      cargoForm.controls.cargo_calculator.controls.load_port_draft_restriction.patchValue(
        loadPortDraftRestriction
      );
    }
  }

  getDraftRestrictionControl(cargoForm: FormGroup<CargoForm>): FormControl {
    if (
      cargoForm.controls.cargo_calculator.controls.load_port_draft_restriction_units_type.value ===
      LoadPortDraftRestrictionUnitsType.Feets
    ) {
      return cargoForm.controls.cargo_calculator.controls.load_port_draft_restriction_ft;
    }
    return cargoForm.controls.cargo_calculator.controls.load_port_draft_restriction;
  }

  onOpenCargoCalculator(cargoForm: FormGroup<CargoForm>): void {
    this.openCargoCalculator.emit(cargoForm?.getRawValue() as Cargo);
  }

  getDem(cargoForm: FormGroup<CargoForm>): number | string {
    const id = cargoForm.controls.id.value;
    const demurrage = this.cargoFinances.find((item) => item.cargo_id === id);
    return demurrage?.tanker_demurrage || demurrage?.tanker_demurrage === 0
      ? demurrage.tanker_demurrage /
          (demurrage?.currency?.fiat_multiplier || DEFAULT_FIAT_MULTIPLIER)
      : '';
  }

  calculatePlanned(cargoForm: FormGroup<CargoForm>, index: number): UserInputDuration {
    const id = cargoForm.controls.id.value;
    const systemValue =
      this.summLDRateAndETbyCargoID.find((summ) => summ.cargo_id === id)?.value || 0;
    const addSummETwithoutCargoID = index === 0 ? this.summETwithoutCargoID : 0;
    const result: UserInputDuration = {
      system: systemValue + addSummETwithoutCargoID,
      user: 0,
      is_changed: false,
      units: cargoForm.controls.allowed_time.value?.units || UserInputDurationType.Hours,
    };
    return result;
  }

  calculateDifference(cargoForm: FormGroup<CargoForm>, index: number): UserInputDuration {
    const id = cargoForm.controls.id.value;
    const planValue =
      this.summLDRateAndETbyCargoID.find((summ) => summ.cargo_id === id)?.value || 0;
    const addSummETwithoutCargoID = index === 0 ? this.summETwithoutCargoID : 0;
    const allowTime = getUserOrSystemDuration(cargoForm.controls.allowed_time.value);
    const result: UserInputDuration = {
      system: planValue + addSummETwithoutCargoID - allowTime,
      user: 0,
      is_changed: false,
      units: cargoForm.controls.allowed_time.value?.units || UserInputDurationType.Hours,
    };
    return result;
  }

  /**
   * Determines the icon to display based on the cargo form's state.
   */
  getDescriptionIcon(
    cargoForm: FormGroup<CargoForm>
  ):
    | typeof PrimeNgIcons.CHEVRON_CIRCLE_UP
    | typeof PrimeNgIcons.CHECK_CIRCLE
    | typeof PrimeNgIcons.PENCIL {
    if (this.isOverlayVisibleForForm(cargoForm)) {
      return PrimeNgIcons.CHEVRON_CIRCLE_UP;
    }
    if (this.getDescriptionFieldsFilledIn(cargoForm)) {
      return PrimeNgIcons.CHECK_CIRCLE;
    }
    return PrimeNgIcons.PENCIL;
  }

  /**
   * Checks if the description field in the cargo form is filled in.
   */
  getDescriptionFieldsFilledIn(cargoForm: FormGroup<CargoForm>): boolean {
    const description = cargoForm.controls.description.value;
    return !!description;
  }

  /**
   * Determines if the description color should be highlighted.
   */
  getDescriptionColor(cargoForm: FormGroup<CargoForm>): boolean {
    return this.getDescriptionFieldsFilledIn(cargoForm) && !this.isOverlayVisibleForForm(cargoForm);
  }

  /**
   * Checks if the overlay is visible for a specific cargo form.
   */
  isOverlayVisibleForForm(cargoForm: FormGroup<CargoForm>): boolean {
    return !!this.overlay?.overlayVisible && this._currentCargoForm === cargoForm;
  }

  openDescriptionModal(event: Event, cargoForm: FormGroup<CargoForm>): void {
    this.overlay?.show(event);
    this._currentCargoForm = cargoForm;
  }
}
