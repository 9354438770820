import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { MessageModule } from 'primeng/message';
import { ConfirmationCodeFormModule } from '../confirmation-code-form/confirmation-code-form.module';
import { RegistrationSuccessComponent } from './registration-success.component';

@NgModule({
  declarations: [RegistrationSuccessComponent],
  imports: [CommonModule, ConfirmationCodeFormModule, InputTextModule, ButtonModule, MessageModule],
  exports: [RegistrationSuccessComponent],
})
export class RegistrationSuccessModule {}
