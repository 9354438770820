import { Component, Input } from '@angular/core';

@Component({
  selector: 'estimator-color-picker-renderer',
  template: `<div class="display-flex">
    <div [style.backgroundColor]="color" class="cell-color"></div>
    <span *ngIf="name">{{ name }}</span>
  </div> `,
  styles: [
    `
      .cell-color {
        width: 25px;
        height: 25px;
        margin-top: 3px;
        border-radius: 4px;
        margin-right: 10px;
      }
    `,
  ],
})
export class AgGridColorPickerCellComponent {
  params: any;
  @Input() color = '';
  name = '';

  agInit(params: any) {
    this.params = params;
    this.color = params.color;
    this.name = params.name;
  }
}
