<p-table
  [value]="deductiblesFormArray.controls"
  [resizableColumns]="true"
  class="primeng-form w-full"
  styleClass="p-datatable-sm font-size-12"
  id="deductibles-list_form"
>
  <ng-template pTemplate="header">
    <tr>
      <th pResizableColumn class="w-auto text-center">Type</th>
      <th pResizableColumn class="w-auto text-center">Deductibles</th>
      <th pResizableColumn class="w-auto text-center">DWT from</th>
      <th pResizableColumn class="w-auto text-center">DWT to</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-deductiblesForm let-i="rowIndex">
    <tr class="primeng-form">
      <!-- <td>
        <input
          pInputText
          type="text"
          name="type"
          class="p-inputtext-sm w-full"
          [formControl]="deductiblesForm.controls?.type"
          required
          [id]="'deductibles_type_' + i"
          [tabindex]="tabIndex"
        />
      </td> -->
      {{
        deductiblesForm.controls?.type?.value
      }}
      <td>
        <p-inputNumber
          [max]="MAX_FINANCE_VALUE"
          [useGrouping]="false"
          [minFractionDigits]="0"
          [maxFractionDigits]="2"
          [min]="0"
          [step]="0"
          [formControl]="deductiblesForm.controls?.deductibles"
          class="p-inputtext-sm w-full"
          styleClass="w-full"
          inputStyleClass="w-full"
          [inputId]="'deductibles_deductibles_' + i"
          estimatorDecimals
          [tabindex]="tabIndex"
        ></p-inputNumber>
      </td>
      <td>
        <p-inputNumber
          [max]="MAX_FINANCE_VALUE"
          [useGrouping]="false"
          [minFractionDigits]="0"
          [maxFractionDigits]="2"
          [min]="0"
          [step]="0"
          [formControl]="deductiblesForm.controls?.min_dwt"
          class="p-inputtext-sm w-full"
          styleClass="w-full"
          inputStyleClass="w-full"
          [inputId]="'deductibles_min_dwt_' + i"
          estimatorDecimals
          [tabindex]="tabIndex"
        ></p-inputNumber>
      </td>
      <td>
        <p-inputNumber
          [max]="MAX_FINANCE_VALUE"
          [useGrouping]="false"
          [minFractionDigits]="0"
          [maxFractionDigits]="2"
          [min]="0"
          [step]="0"
          [formControl]="deductiblesForm.controls?.max_dwt"
          class="p-inputtext-sm w-full"
          styleClass="w-full"
          inputStyleClass="w-full"
          [inputId]="'deductibles_max_dwt_' + i"
          estimatorDecimals
          [tabindex]="tabIndex"
        ></p-inputNumber>
      </td>
    </tr>
  </ng-template>
</p-table>
