import { Component, Input } from '@angular/core';
import { Port } from '@estimator/models';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'estimator-port-renderer',
  template: ` <span class="{{ flag }}"></span><span>{{ portName }}</span> `,
  styles: [``],
})
export class AgGridPortRendererComponent implements ICellRendererAngularComp {
  @Input() port?: Port;
  portName = '';
  flag = 'mr-2 fi fi-';
  params: any;
  agInit(params: any): void {
    this.params = params;
    if (params.data?.port) {
      this.portName = params.data.port.name;
      this.flag += params.data.port.alpha_2.toLowerCase();
    }
  }

  refresh(/* params: ICellRendererParams */): boolean {
    return true;
  }
}
