import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DirectivesModule } from '@estimator/directives';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { ColorPickerModule } from 'primeng/colorpicker';
import { EventCargoListModule } from '../event-cargo-list/event-cargo-list.module';
import { VesselFormModule } from '../vessel-form/vessel-form.module';
import { AgGridAutocompleteComponent } from './ag-grid-autocomplete-editor.component';
import { AgGridBasicTooltipComponent } from './ag-grid-basic-tooltip.component';
import { AgGridCargoDetailsComponent } from './ag-grid-cargo-details.component';
import { AgGridCargoTypeComponent } from './ag-grid-cargo-type-editor.component';
import { AgGridCheckboxCellComponent } from './ag-grid-checkbox-editor.component';
import { AgGridColorPickerCellComponent } from './ag-grid-color-picker-cell.component';
import { AgGridPickerEditorEditorComponent } from './ag-grid-color-picker-editor.component';
import { AgGridCompanyFilterComponent } from './ag-grid-company-filter.component';
import { DateEditorModule } from './ag-grid-date-editor.module';
import { AgGridDateTimeEditorComponent } from './ag-grid-datetime-editor.component';
import { AgGridEventDetailsComponent } from './ag-grid-event-details.component';
import { AgGridEventGroupRendererComponent } from './ag-grid-event-group-cell-renderer.component';
import { AgGridEventTooltipComponent } from './ag-grid-event-tooltip.component';
import { AgGridHeaderMultiselectModule } from './ag-grid-header-multiselect.module';
import { AgGridHeaderComponent } from './ag-grid-header.component';
import { AgGridNumericCellEditorComponent } from './ag-grid-numeric-editor.component';
import { AgGridPortRendererComponent } from './ag-grid-port-renderer.component';
import { AgGridPortAutocompleteComponent } from './ag-grid-ports-autocomplete.component';
import { AgGridPriceEditorComponent } from './ag-grid-price-with-currency-editor.component';
import { AgGridSelectCellEditorComponent } from './ag-grid-select-editor.component';
import { AgGridSwitchEditorModule } from './ag-grid-switch.module';
import { AgGridTimeDurationEditorComponent } from './ag-grid-time-duration-editor.component';
import { AgGridVesselDetailsComponent } from './ag-grid-vessel-details/ag-grid-vessel-details.component';
import { AgGridButtonGroupComponent } from './button-group.component';
import { InputNumberModule } from 'primeng/inputnumber';

const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
@NgModule({
  declarations: [
    AgGridVesselDetailsComponent,
    AgGridButtonGroupComponent,
    AgGridHeaderComponent,
    AgGridNumericCellEditorComponent,
    AgGridPickerEditorEditorComponent,
    AgGridColorPickerCellComponent,
    AgGridSelectCellEditorComponent,
    AgGridAutocompleteComponent,
    AgGridDateTimeEditorComponent,
    AgGridCargoDetailsComponent,
    AgGridCargoTypeComponent,
    AgGridEventGroupRendererComponent,
    AgGridCheckboxCellComponent,
    AgGridPortRendererComponent,
    AgGridPortAutocompleteComponent,
    AgGridTimeDurationEditorComponent,
    AgGridPriceEditorComponent,
    AgGridEventDetailsComponent,
    AgGridEventTooltipComponent,
    AgGridBasicTooltipComponent,
    AgGridCompanyFilterComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatIconModule,
    MatAutocompleteModule,
    MatInputModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatMomentDateModule,
    VesselFormModule,
    InputNumberModule,
    EventCargoListModule,
    DirectivesModule,
    ColorPickerModule,
    CalendarModule,
    DateEditorModule,
    AgGridHeaderMultiselectModule,
    AgGridSwitchEditorModule,
    AutoCompleteModule,
  ],
  exports: [
    AgGridVesselDetailsComponent,
    AgGridNumericCellEditorComponent,
    AgGridPickerEditorEditorComponent,
    AgGridColorPickerCellComponent,
    AgGridSelectCellEditorComponent,
    AgGridAutocompleteComponent,
    AgGridCargoDetailsComponent,
    AgGridCargoTypeComponent,
    AgGridEventGroupRendererComponent,
    AgGridCheckboxCellComponent,
    AgGridPortRendererComponent,
    AgGridPortAutocompleteComponent,
    AgGridTimeDurationEditorComponent,
    AgGridPriceEditorComponent,
    AgGridEventDetailsComponent,
    AgGridEventTooltipComponent,
    AgGridBasicTooltipComponent,
    DateEditorModule,
    AgGridHeaderMultiselectModule,
    AgGridSwitchEditorModule,
    AgGridCompanyFilterComponent,
  ],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }, DatePipe],
})
export class AgGridSharedModule {}
