<div class="flex-container-column">
  <div class="p-fluid mb-5">
    <p-message
      *ngIf="confirmSuccess"
      [severity]="NotificationType.Success"
      text="User has been confirmed, you can start your work"
    ></p-message>
    <p-message
      *ngIf="responseError"
      [severity]="NotificationType.Error"
      [text]="responseError"
    ></p-message>
  </div>
  <section class="p-fluid mt-5">
    <p-button
      class="w-100"
      styleClass="signin-button"
      size="small"
      label="Begin"
      (click)="onStart()"
    ></p-button>
  </section>
</div>
