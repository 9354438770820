import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipeModule } from '@estimator/helpers';
import { InputNumberModule } from 'primeng/inputnumber';
import { Co2CostsComponent } from './co2-costs.component';

export const CO2_COSTS_IMPORTS = [
  CommonModule,
  FormsModule,
  InputNumberModule,
  PipeModule,
  ReactiveFormsModule,
];

@NgModule({
  declarations: [Co2CostsComponent],
  imports: CO2_COSTS_IMPORTS,
  exports: [Co2CostsComponent],
})
export class Co2CostsModule {}
