<div class="material-form">
  <mat-form-field appearance="outline" class="large-input vessel-port-search-input">
    <mat-label>Vessel/Port</mat-label>
    <input
      matInput
      type="text"
      name="vessel"
      placeholder="Vessel or port name"
      [formControl]="vesselOrPortSelectControl"
      [matAutocomplete]="vesselOrPortAutocomplete"
      id="dr-i-1"
      estimatorSelectValue
    />
    <mat-icon *ngIf="isLoading" matSuffix>
      <mat-spinner [color]="MaterialThemeColor.Primary" [diameter]="20"></mat-spinner>
    </mat-icon>
    <mat-autocomplete
      #vesselOrPortAutocomplete="matAutocomplete"
      class="vessel-search-autocomplete"
    >
      <!-- корабли -->
      <span class="padding-left">Vessels</span>
      <div class="vessel-autocomplete">
        <mat-option
          *ngFor="let vessel of vesselsPorts.raw_vessels"
          [value]="vessel"
          (onSelectionChange)="onVesselOrPortSelect(vessel, true, $event)"
          class="h-full block"
        >
          <div class="option-wrapper">
            <div class="option-row m-0">
              <mat-icon [color]="MaterialThemeColor.Primary" class="vessel-icon"
                >directions_boat</mat-icon
              >
              <div class="vessel-name h-full w-full text-overflow-ellipsis">
                <b>{{ vessel?.name }}</b>
              </div>
              <span class="mr-2 fi fi-{{ vessel?.alpha_2?.toLowerCase() }}"></span>
            </div>
            <p class="m-0">
              IMO:
              {{ vessel?.imo }}
            </p>
            <p class="m-0">
              MMSI:
              {{ vessel?.mmsi }}
            </p>
            <p class="m-0">
              DWT:
              {{ vessel?.dead_weight }}
            </p>
          </div>
        </mat-option>
        <ng-container *ngIf="vesselsPorts.raw_vessels.length < 1 && !isLoading">
          <mat-option [disabled]="true" class="flex">
            <p>No vessels found</p>
          </mat-option>
        </ng-container>
      </div>
      <!-- порты -->
      <span class="padding-left">Ports</span>
      <div class="port-autocomplete">
        <mat-option
          *ngFor="let port of vesselsPorts.ports"
          [value]="port"
          (onSelectionChange)="onVesselOrPortSelect(port, false, $event)"
          class="port-option h-full w-full block text-overflow-ellipsis"
        >
          <div class="option-wrapper">
            <div class="option-row m-0">
              <span class="mr-2 fi flag-search m-0 fi-{{ port?.alpha_2?.toLowerCase() }}"></span>
              <div class="port-name h-full">
                <b>{{ port?.name?.toUpperCase() }}</b>
              </div>
            </div>
            <p class="m-0">{{ port?.code }}</p>
          </div>
        </mat-option>
        <ng-container *ngIf="vesselsPorts.ports.length < 1 && !isLoading">
          <mat-option [disabled]="true" class="flex">
            <p>No ports found</p>
          </mat-option>
        </ng-container>
      </div>
    </mat-autocomplete>
  </mat-form-field>
</div>
