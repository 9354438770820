import { Directive, ElementRef, HostListener, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[estimatorAutosizeInputFont]',
})
export class AutosizeInputFontDirective implements OnInit {
  @HostListener('input', ['$event']) onInput($event: InputEvent): void {
    const el = this.el.nativeElement;
    const insert = $event.inputType === 'insertText' || $event.inputType === 'insertFromPaste';
    const remove =
      $event.inputType === 'deleteContentBackward' || $event.inputType === 'deleteByCut';
    if (this.isOverflowing(el) && insert) {
      while (this.adjustedFontSize > this.minFontSize) {
        this.adjustedFontSize -= 1;
        this.setFontSize(this.adjustedFontSize);
        return;
      }
    } else if (!this.isOverflowing(el) && remove) {
      let fontSize = parseInt(el.style.fontSize);
      while (!this.isOverflowing(el) && fontSize < this.maxFontSize) {
        fontSize += 1;
        this.adjustedFontSize = fontSize;
        this.setFontSize(fontSize);
        return;
      }
    }
  }

  @Input() minFontSize = 7;
  @Input() maxFontSize = 12;
  adjustedFontSize = 12;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    const el = this.el.nativeElement;
    if (!this.isOverflowing(el)) return;
    while (this.isOverflowing(el) && this.adjustedFontSize > this.minFontSize) {
      this.adjustedFontSize -= 1;
      this.setFontSize(this.adjustedFontSize);
    }
  }

  private isOverflowing(element: HTMLInputElement): boolean {
    return element.scrollWidth > element.clientWidth;
  }

  private setFontSize(size: number): void {
    this.renderer.setStyle(this.el.nativeElement, 'font-size', `${size}px`);
  }
}
