import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PortsHistoryResponse, THOUSAND, XBPIRequest, XBPIResponse } from '@estimator/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
// XMAR Bunker Price Index (XBPI)
export class XmarBunkerPriceIndexService {
  private _path = '/api/xbpi';
  constructor(protected readonly http: HttpClient) {}

  getHistoryData(data: XBPIRequest): Observable<XBPIResponse> {
    if (data.date_from_seconds) {
      data.date_from_seconds = Math.floor(data.date_from_seconds / THOUSAND);
    }
    if (data.date_to_seconds) {
      data.date_to_seconds = Math.floor(data.date_to_seconds / THOUSAND);
    }
    return this.http.post<XBPIResponse>(`${this._path}/history`, data);
  }

  getHistoryPorts(): Observable<PortsHistoryResponse> {
    return this.http.get<PortsHistoryResponse>(`${this._path}/ports`);
  }
}
