import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { filterArray, localDateTimeFormat } from '@estimator/helpers';
import {
  DEFAULT_FIAT_MULTIPLIER,
  FuelTypes,
  GroupDataXBPIParsedArray,
  Port,
  SaveXBPIData,
  XBPIParsed,
  XBPITableDialogConfig,
  itemMapStringNumber,
} from '@estimator/models';
import { cloneDeep } from 'lodash';
import {
  AutoComplete,
  AutoCompleteCompleteEvent,
  AutoCompleteSelectEvent,
} from 'primeng/autocomplete';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'estimator-xbpi-table',
  templateUrl: './xbpi-table.component.html',
  styleUrls: ['./xbpi-table.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class XmarBunkerPriceIndexTableComponent implements OnDestroy, AfterViewInit {
  @ViewChild('autoCompletePort') autoCompletePort?: AutoComplete;
  @Output() saveFuelPrices = new EventEmitter<SaveXBPIData>();
  @Output() requestHistoryDataByPortId = new EventEmitter<number>();
  portHistoryControl = new FormControl();
  xbpiParsedData: GroupDataXBPIParsedArray[] = [];
  historyPorts: Port[] = [];
  filteredHistoryPorts: Port[] = [];
  fuelsMap: itemMapStringNumber = new Map();
  isFixed = false;
  localDateTimeFormat = localDateTimeFormat;
  readonly FuelTypes = FuelTypes;

  private _onDestroy$ = new Subject<void>();

  constructor(
    private readonly dialogConfig: DynamicDialogConfig<XBPITableDialogConfig>,
    private readonly cdr: ChangeDetectorRef
  ) {
    if (this.dialogConfig.data) {
      const {
        xbpiParsedData,
        historyPorts,
        fuelsMap,
        isFixed,
        saveFuelPrices,
        requestHistoryDataByPortId,
        foundXBPIData,
      } = this.dialogConfig.data;
      if (xbpiParsedData) {
        this.xbpiParsedData = xbpiParsedData;
      }
      if (historyPorts) {
        this.historyPorts = cloneDeep(historyPorts);
        /* if (this.historyPorts[0]?.id) {
          this.portHistoryControl.setValue(this.historyPorts[0]);
        } */
      }
      if (fuelsMap) {
        this.fuelsMap = fuelsMap;
      }
      if (isFixed) {
        this.isFixed = isFixed;
      }
      if (saveFuelPrices) {
        this.saveFuelPrices = saveFuelPrices;
      }
      if (requestHistoryDataByPortId) {
        this.requestHistoryDataByPortId = requestHistoryDataByPortId;
      }
      foundXBPIData?.pipe(takeUntil(this._onDestroy$)).subscribe((xbpiData) => {
        this.xbpiParsedData = xbpiData;
        this.cdr.detectChanges();
      });
    }
  }
  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  ngAfterViewInit(): void {
    this.autoCompletePort?.inputEL?.nativeElement?.focus();
  }

  onSearchHistoryPort(event?: AutoCompleteCompleteEvent): void {
    if (event?.query) {
      this.filteredHistoryPorts = filterArray(this.historyPorts, event?.query);
    }
  }

  onSaveFuelPrices(
    VLSFO: XBPIParsed | null,
    ULSFO: XBPIParsed | null,
    LSMGO: XBPIParsed | null,
    HSFO: XBPIParsed | null
  ): void {
    const data: SaveXBPIData = {
      [FuelTypes.VLSFO]:
        VLSFO?.price || VLSFO?.price === 0 ? VLSFO?.price * DEFAULT_FIAT_MULTIPLIER : null,
      [FuelTypes.ULSFO]:
        ULSFO?.price || ULSFO?.price === 0 ? ULSFO?.price * DEFAULT_FIAT_MULTIPLIER : null,
      [FuelTypes.LSMGO]:
        LSMGO?.price || LSMGO?.price === 0 ? LSMGO?.price * DEFAULT_FIAT_MULTIPLIER : null,
      [FuelTypes.HSFO]:
        HSFO?.price || HSFO?.price === 0 ? HSFO?.price * DEFAULT_FIAT_MULTIPLIER : null,
    };
    this.saveFuelPrices.emit(data);
  }

  onSelectHistoryPort(event: AutoCompleteSelectEvent): void {
    this.requestHistoryDataByPortId.emit(event.value.id);
  }

  getFuel(data: XBPIParsed[], fuelName: string): XBPIParsed | null {
    return data.find((item) => item.fuel_id === this.fuelsMap.get(fuelName)) || null;
  }
}
