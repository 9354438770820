import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordRestoreComponent } from './password-restore.component';
import { MessageModule } from 'primeng/message';

@NgModule({
  declarations: [PasswordRestoreComponent],
  imports: [CommonModule, ReactiveFormsModule, InputTextModule, ButtonModule, MessageModule],
  exports: [PasswordRestoreComponent],
})
export class PasswordRestoreModule {}
