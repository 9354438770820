export type IForm<T> = {
  [K in keyof T]: any;
};

export interface ContactForm {
  first_name?: string;
  last_name?: string;
  email: string;
  phone?: string;
  company_name?: string;
  how_know?: string;
  message?: string;
  agreed_for_communications?: boolean;
  agreed_for_news?: boolean;
  time_for_demo?: string;
}
