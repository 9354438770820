<ag-grid-angular
  [rowData]="displayedEvents"
  [gridOptions]="gridOptions"
  (gridReady)="onGridReady($event)"
  class="wh-100 event-list ag-theme-material"
></ag-grid-angular>
<div class="flex-container-row content-end gap-2 padding-5">
  <div class="flex-container-row">
    Days ballast/laden&nbsp; <b>{{ ballastTime | duration : 'd' : 2 }}</b
    >/<b>{{ ladenTime | duration : 'd' : 2 }}</b>
  </div>
  <div class="flex-container-row">
    Consumed:&nbsp;
    <div *ngFor="let consumption of consumptions">
      <span *ngIf="consumption.amount"
        >{{ consumption.fuel?.name || NOT_APPLICABLE }}:&nbsp;<b>{{
          consumption.amount | number : '.0-2'
        }}</b>
        &nbsp;</span
      >
    </div>
  </div>
</div>
