import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { SaveFolderComponent } from './save-folder.component';

@NgModule({
  declarations: [SaveFolderComponent],
  imports: [CommonModule, ButtonModule, ReactiveFormsModule, AutoCompleteModule],
  exports: [SaveFolderComponent],
})
export class SaveFolderModule {}
