import { FormControl } from '@angular/forms';
import { BaseModel, Port, User } from '@estimator/models';

export interface XlistFilter extends BaseModel {
  allowed_users?: number[];
  company_id?: number;
  message_date_from_seconds?: number;
  message_date_to_seconds?: number;
  message_date_last_n_days?: number;
  open_dates_from_seconds?: number;
  open_dates_to_seconds?: number;
  open_dates_last_n_days?: number;
  port_ids?: number[];
  vessel_dead_weight_from?: number;
  vessel_dead_weight_to?: number;
  vessel_built_year_from?: number;
  vessel_built_year_to?: number;
  zone_ids?: number[];
  ports?: Port[];
  destination_port_id?: number; // destination_port_id === Port.id
  destination_date_from_seconds?: number;
  destination_date_to_seconds?: number;
  vessel_speed?: number;
  general_vessel_ids?: number[];
  alt_speed_only?: boolean;
}

export interface XlistEtaFilter {
  port: FormControl<Port | null>;
  destination_date_from_seconds: FormControl <Date | null>;
  destination_date_to_seconds: FormControl<Date | null>;
  vessel_speed: FormControl<number | null>;
  alt_speed_only: FormControl<boolean>;
}

export interface XlistFilterFormGroup {
  name: FormControl<string | null>;
  allowed_users: FormControl<number[] | null>;
  company_id: FormControl<number | null>;
  message_date_from_seconds: FormControl<number | Date | null>;
  message_date_to_seconds: FormControl<number | Date | null>;
  message_date_last_n_days: FormControl<number | null>;
  message_switcher: FormControl<boolean | null>;
  message_dates_range: FormControl<(Date | null)[] | null>;
  open_dates_from_seconds: FormControl<number | Date | null>;
  open_dates_to_seconds: FormControl<number | Date | null>;
  open_dates_last_n_days: FormControl<number | null>;
  open_dates_switcher: FormControl<boolean | null>;
  open_dates_range: FormControl<(Date | null)[] | null>;
  port_ids: FormControl<number[] | null>;
  vessel_dead_weight_from: FormControl<number | null>;
  vessel_dead_weight_to: FormControl<number | null>;
  vessel_built_year_from: FormControl<Date | null>;
  vessel_built_year_to: FormControl<Date | null>;
  zone_ids: FormControl<number[] | null>;
  filter_location: FormControl<Port[] | null>;
  allowed_users_array: FormControl<User[] | null>;
}

export interface ShiplistVesselMail {
  message_sender?: string;
  message_date?: Date;
  message?: string;
}

export interface ShiplistVesselMailMessages {
  messages?: ShiplistVesselMail[];
}

export interface ShiplistVesselMailGetData {
  shiplist_item_id: number;
}
