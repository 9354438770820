import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { LoginReminderDialogConfig, NotificationType, SIGN_IN } from '@estimator/models';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'estimator-login-reminder',
  templateUrl: './login-reminder.component.html',
  styleUrls: ['./login-reminder.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginReminderComponent {
  @Output() signIn = new EventEmitter<void>();
  readonly NotificationType = NotificationType;
  readonly SIGN_IN = SIGN_IN;

  get reminderText(): string {
    return 'Please sign in here.';
  }

  constructor(private readonly dialogConfig: DynamicDialogConfig<LoginReminderDialogConfig>) {
    if (this.dialogConfig.data) {
      const { signIn } = this.dialogConfig.data;
      if (signIn) {
        this.signIn = signIn;
      }
    }
  }

  onSignIn(): void {
    this.signIn.emit();
  }
}
