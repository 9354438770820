import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { MatChip } from '@angular/material/chips';
import { emptyFeatureCollection, showTooltip } from '@estimator/helpers';
import {
  GeoZone,
  MapFeatureProperties,
  NOT_APPLICABLE,
  OK_TEXT,
  UserGuideType,
  mglMapCenter,
  mglMapStyleGlobe,
} from '@estimator/models';
import { BdcWalkService } from 'bdc-walkthrough';
import { FeatureCollection, LineString } from 'geojson';
import { LngLatLike } from 'mapbox-gl';

@Component({
  selector: 'estimator-dialog-map',
  templateUrl: './dialog-map.component.html',
  styleUrls: ['./dialog-map.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogMapComponent {
  @Input()
  set route(data: FeatureCollection) {
    this._route = data;
    if (data?.features?.length) {
      const middleIndex = Math.round(data.features.length / 2);
      const middleFeature = data.features[middleIndex];
      if (middleFeature) {
        const line = middleFeature.geometry as LineString;
        this.center = line.coordinates[0] as LngLatLike;
      }
      this.canalsOnRoute = [];
      data.features.forEach((feature) => {
        if (feature.properties) {
          const canals: number[] = feature.properties[MapFeatureProperties.CanalsZones];
          if (canals && canals.length) {
            canals.forEach((canal) => {
              if (!this.canalsOnRoute.includes(canal)) {
                this.canalsOnRoute.push(canal);
              }
            });
          }
        }
      });
    }
    this.cdr.detectChanges();
  }
  get route(): FeatureCollection {
    return this._route;
  }
  @Input() selectedCanals: number[] = [];
  @Input()
  set canals(data: GeoZone[]) {
    data.forEach((canal) => {
      canal.selected = !!(canal.id && this.selectedCanals.includes(canal.id));
    });
    this._canals = data;
    this.cdr.detectChanges();
  }
  get canals(): GeoZone[] {
    return this._canals;
  }
  @Input() useEca = false;
  @Output() updateCanals = new EventEmitter<number>();
  @Output() updateEcaAvoid = new EventEmitter<boolean>();
  center: LngLatLike = mglMapCenter;
  style = mglMapStyleGlobe;
  // onMouseEnter = onMouseEnter;
  // onMouseLeave = onMouseLeave;
  // selectedEdge?: Feature<Point, GeoJsonProperties>;
  canalsOnRoute: number[] = [];
  readonly OK_TEXT = OK_TEXT;
  readonly NOT_APPLICABLE = NOT_APPLICABLE;
  readonly MapFeatureProperties = MapFeatureProperties;
  private _route: FeatureCollection = emptyFeatureCollection();
  private _canals: GeoZone[] = [];

  constructor(private readonly cdr: ChangeDetectorRef, private bdcWalkService: BdcWalkService) {}

  /*   onRouteClick({ features }: MapLayerMouseEvent): void {
    if (features?.length) {
      const feature = features[0] as Feature<LineString, GeoJsonProperties>;
      const x = (feature.geometry.coordinates[0][0] + feature.geometry.coordinates[1][0]) / 2;
      const y = (feature.geometry.coordinates[0][1] + feature.geometry.coordinates[1][1]) / 2;
      const position: Position = [x, y];
      const point: Feature<Point, GeoJsonProperties> = {
        type: MapTypes.Feature,
        properties: feature.properties,
        geometry: {
          type: MapTypes.Point,
          coordinates: position,
        },
      };
      this.selectedEdge = point;
    }
  } */

  onSelectCanal(chip: MatChip): void {
    chip.value.selected = !chip.value.selected;
    if (chip.value?.id) {
      this.updateCanals.emit(chip.value.id);
    }
  }

  onChangeEcaAvoid(checked: boolean): void {
    this.updateEcaAvoid.emit(checked);
  }

  isCanalOnRoute(id: number): boolean {
    return this.canalsOnRoute.includes(id);
  }

  isCanalForcedOnRoute(canal: GeoZone): boolean {
    return this.isCanalOnRoute(canal.id || 0) && this.selectedCanals.includes(canal.id || 0);
  }

  isCanalRestricted(canal: GeoZone): boolean {
    if (canal.id) {
      return this.selectedCanals.includes(canal.id);
    }
    return false;
  }

  showTooltipMap() {
    showTooltip(this.bdcWalkService, UserGuideType.DialogMap11);
  }
}
