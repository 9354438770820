<div class="flex flex-column gap-1">
  <div class="flex justify-content-between gap-2" id="co2_costs_days_container_costs_days">
    <div class="white-space-nowrap font-bold widget-heading">Days:</div>
    <div class="white-space-nowrap white-content" id="co2_costs_days">
      {{ daysDistanceIndicators.hireMinutes || 0 | duration : 'd' : 2 : true }} ({{
        daysDistanceIndicators.sailingMinutes || 0 | duration : 'd' : 2 : true
      }}
      Sea, {{ daysDistanceIndicators.loadDischargeMinutes || 0 | duration : 'd' : 2 : true }} W,
      {{ daysDistanceIndicators.idleMinutes || 0 | duration : 'd' : 2 : true }} Idl,
      {{ daysDistanceIndicators.reserveMinutes || 0 | duration : 'd' : 2 : true }} Res)
    </div>
  </div>
  <div class="flex justify-content-between gap-2" id="co2_costs_distance_container_costs_distance">
    <div class="white-space-nowrap font-bold widget-heading">Distance:</div>
    <div class="white-space-nowrap white-content" id="co2_costs_distance">
      {{ daysDistanceIndicators.distance?.toFixed(2) || 0 }}
      ({{ daysDistanceIndicators.ecaDistance?.toFixed(2) || 0 }} Eca,
      {{ daysDistanceIndicators.balDistance?.toFixed(2) || 0 }} Bal,
      {{ daysDistanceIndicators.ladDistance?.toFixed(2) || 0 }} Lad)
    </div>
  </div>
  <div class="flex justify-content-between gap-2" id="co2_costs_eeoi_co2_container_costs_eeoi_co2">
    <div class="white-space-nowrap font-bold widget-heading">EEOI CO2:</div>
    <div class="white-space-nowrap white-content" id="co2_costs_eeoi_co2">
      {{ co2Costs.ladden + co2Costs.ballast | number : '.0-2' }}t (L
      {{ co2Costs.ladden | number : '.0-2' }} / B {{ co2Costs.ballast | number : '.0-2' }})
    </div>
  </div>
  <div
    class="flex justify-content-between gap-2"
    id="co2_costs_total_co2_container_costs_total_co2"
  >
    <div class="white-space-nowrap font-bold widget-heading">Total CO2:</div>
    <div class="white-space-nowrap white-content" id="co2_costs_total_co2">
      {{ co2Costs.ladden + co2Costs.ballast | number : '.0-2' }}t /
      {{
        (co2Costs.ladden + co2Costs.ballast) * ((finances.co2_price || 0) / DEFAULT_FIAT_MULTIPLIER)
          | currency : 'USD'
      }}
    </div>
  </div>
  <div
    class="flex justify-content-between gap-2"
    id="co2_costs_eua_co2_24_container_costs_eua_co2_24"
  >
    <div class="white-space-nowrap font-bold widget-heading">{{ TEXT_EUA_CO2_24 }}:</div>
    <div class="white-space-nowrap white-content" id="co2_costs_eua_co2_24">
      {{ finances.co2_eua_amount || 0 | number : '.0-2' }}t /
      {{ (finances.co2_total_cost || 0) / DEFAULT_FIAT_MULTIPLIER | currency : 'USD' }}
    </div>
  </div>
  <div
    class="flex justify-content-between gap-2"
    id="co2_costs_eua_freight_impact_container_costs_eua_freight_impact"
  >
    <div class="white-space-nowrap font-bold widget-heading">EUA Freight Impact:</div>
    <div class="white-space-nowrap white-content" id="co2_costs_eua_freight_impact">
      {{
        totalCargoesMT
          ? ((finances.co2_total_cost || 0) / DEFAULT_FIAT_MULTIPLIER / totalCargoesMT
            | currency : 'USD')
          : (0 | currency : 'USD')
      }}
    </div>
  </div>
  <!--   <div class="flex justify-content-between gap-2 align-items-center">
    <div class="white-space-nowrap font-bold">
      <a href="https://tradingeconomics.com/eecxm:ind" target="_blank">Carbon Permits | EECXM:</a>
    </div>
    <form [formGroup]="fg" class="primeng-form">
      <p-inputNumber
        formControlName="co2_price"
        mode="decimal"
        [maxFractionDigits]="2"
        [min]="0"
        [step]="0"
        class="p-inputtext-sm w-100"
        inputStyleClass="w-100"
        inputId="co2_price_input"
        estimatorDecimals
      />
    </form>
  </div> -->
</div>
