import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { BaseModel } from './base-entity';
import { Company } from './company';
import { ConsumptionRate, ConsumptionRateFormGroup } from './fuel-consumption';
import { GeoZone } from './geographic';
import { Port } from './port';
import { PrimeNgColors, SimpleButtonSwitch, falseText, trueText } from './ui-items';

export interface Vessel extends BaseModel {
  alpha_2?: string;
  // ballast_speed?: number;
  beam?: number;
  build_year?: number;
  builder?: string;
  call_sign?: string;
  classification_society?: string;
  company?: Company;
  company_id?: number;
  consumption_rates?: ConsumptionRate[];
  dead_weight?: number;
  draught?: number;
  flag?: string;
  general_vessel_id?: number;
  gross_registered_tonnage?: number;
  bale?: number;
  grain?: number;
  gross_tonnage?: number;
  imo?: number;
  // laden_speed?: number;
  length?: number;
  // low_speed?: number;
  manager?: string;
  mmsi?: number;
  owner?: string;
  place_of_built?: string;
  teu?: number;
  tpc?: number;
  grt?: number;
  type?: string;
  yard?: string;
  is_raw?: boolean;
  speed_over_ground?: number;
  point?: { latitude?: number; longitude?: number };
  time_zone?: number;
  time_zone_name?: string;
  presets?: Preset[];
  constants?: Constants;
  scrubber?: boolean;
  fleet_id?: number;
  impassable_zones?: GeoZone[];
  // from spire
  course?: number;
  matched_port_name?: string;
  matched_port_unlocode?: string;
  navigational_status?: string;
  position_update_timestamp?: string | Date | null;
  country_name?: string;
}

export interface RawVessel {
  bale?: number;
  beam?: number;
  builder?: string;
  built_year?: number;
  cranes?: number;
  dead_weight?: number;
  draught?: number;
  eco_2_ballast_main_engine_consumption?: number;
  eco_2_ballast_speed?: number;
  eco_2_laden_main_engine_consumption?: number;
  eco_2_laden_speed?: number;
  eco_ballast_auxiliary_engine_consumption?: number;
  eco_ballast_main_engine_consumption?: number;
  eco_ballast_speed?: number;
  eco_laden_auxiliary_engine_consumption?: number;
  eco_laden_main_engine_consumption?: number;
  eco_laden_speed?: number;
  flag?: string;
  full_ballast_auxiliary_engine_consumption?: number;
  full_ballast_main_engine_consumption?: number;
  full_ballast_speed?: number;
  full_laden_auxiliary_engine_consumption?: number;
  full_laden_main_engine_consumption?: number;
  full_laden_speed?: number;
  grain?: number;
  hatches?: number;
  holds?: number;
  ice_class_type?: string;
  imo?: number;
  length?: number;
  manager?: string;
  name?: string;
  owner?: string;
  port_idle_auxiliary_engine_consumption?: number;
  port_idle_main_engine_consumption?: number;
  port_working_auxiliary_engine_consumption?: number;
  port_working_main_engine_consumption?: number;
  tpc?: number;
  type?: string;
  scrubber?: string;
  dual_fuel?: string;
  design?: string;
}

export interface VesselForm {
  id: FormControl<number>;
  created_at: FormControl<string | Date | null>;
  created_by: FormControl<number | null>;
  updated_at: FormControl<string | Date | null>;
  updated_by: FormControl<number | null>;
  deleted_at: FormControl<string | Date | null>;
  deleted_by: FormControl<number | null>;
  alpha_2: FormControl<string>;
  // ballast_speed: FormControl<number | null>;
  beam: FormControl<number | null>;
  build_year: FormControl<number | null>;
  builder: FormControl<string | null>;
  call_sign: FormControl<string | null>;
  classification_society: FormControl<string | null>;
  company: FormControl<Company | null>;
  company_id: FormControl<number | null>;
  consumption_rates: FormControl<ConsumptionRate[] | null>;
  dead_weight: FormControl<number | null>;
  draught: FormControl<number | null>;
  flag: FormControl<string | null>;
  general_vessel_id: FormControl<number | null>;
  gross_registered_tonnage: FormControl<number | null>;
  bale: FormControl<number | null>;
  grain: FormControl<number | null>;
  gross_tonnage: FormControl<number | null>;
  imo: FormControl<number | null>;
  is_raw: FormControl<boolean | null>;
  // laden_speed: FormControl<number | null>;
  length: FormControl<number | null>;
  // low_speed: FormControl<number | null>;
  manager: FormControl<string | null>;
  mmsi: FormControl<number | null>;
  name: FormControl<string>;
  owner: FormControl<string | null>;
  place_of_built: FormControl<string | null>;
  teu: FormControl<number | null>;
  tpc: FormControl<number | null>;
  // grt: FormControl<number | null>;
  type: FormControl<string | null>;
  yard: FormControl<string | null>;
  constants?: FormGroup<VesselConstantsFormGroup>;
  scrubber?: FormControl<boolean>;
}

export interface PresetFormGroup {
  name: FormControl<string>;
  laden_speed: FormControl<number | null>;
  ballast_speed: FormControl<number | null>;
  consumption_rates: FormArray<FormGroup<ConsumptionRateFormGroup>>;
}

export interface SearchVesselResponse {
  parsed_vessels: Partial<Vessel>[];
  raw_vessels: Partial<Vessel>[];
}

export interface SearchRawVesselsAndPortsResponse {
  ports: Partial<Port>[];
  raw_vessels: Partial<Vessel>[];
}

export interface Preset extends BaseModel {
  consumption_rates?: ConsumptionRate[];
  laden_speed?: number;
  ballast_speed?: number;
}

export interface Constants {
  /*  extra_work_minutes?: number;
  terms_minutes?: number; */
  /**
   * сумма 5 полей ниже
   */
  deductibles: number;
  bunker_weight: number;
  constant_weight: number;
  fresh_water: number;
  other_weight: number;
  permanent_ballast: number;
}

export interface VesselConstantsFormGroup {
  deductibles: FormControl<number | null>;
  bunker_weight: FormControl<number | null>;
  constant_weight: FormControl<number | null>;
  fresh_water: FormControl<number | null>;
  other_weight: FormControl<number | null>;
  permanent_ballast: FormControl<number | null>;
}

export enum PresetNames {
  Full = 'Full',
  Eco1 = 'Eco 1',
  Eco2 = 'Eco 2',
  Eco3 = 'Eco 3',
}

export interface SelectVesselOrPort {
  vesselOrPort: Partial<Vessel | Port>;
  vessel: boolean;
  fromSearch: boolean;
}

export enum VesselTypes {
  MINIBULK = 'minibulk',
  HANDYSIZE = 'handysize',
  HMAX_ULTRAMAX = 'hmax/ultramax',
  PANAMAX_POSTPANAMAX = 'panamax/postpanamax',
  CAPESIZE = 'capesize',
}

export const CargoHeatingTypes: SimpleButtonSwitch[] = [
  { value: trueText, color: PrimeNgColors.RedColor, short_name: 'ON' },
  { value: falseText, color: PrimeNgColors.BlueColor, short_name: 'OFF' },
];

export interface TonnageMetric {
  value: TonnageMetricsTypes;
  tooltip: string;
  control: TonnageControlTypes;
}

export enum TonnageMetricsTypes {
  GR = 'GR',
  BL = 'BL',
  GT = 'GT',
}

export enum TonnageControlTypes {
  GR = 'grain',
  BL = 'bale',
  GT = 'gross_tonnage',
}

export const TonnageMetrics: TonnageMetric[] = [
  { value: TonnageMetricsTypes.GR, tooltip: 'Grain', control: TonnageControlTypes.GR },
  { value: TonnageMetricsTypes.BL, tooltip: 'Bale', control: TonnageControlTypes.BL },
  { value: TonnageMetricsTypes.GT, tooltip: 'Gross tonnage', control: TonnageControlTypes.GT },
];
