import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DealEvent } from '@estimator/models';
import { BaseService } from '../base';

@Injectable({
  providedIn: 'root',
})
export class DealEventService extends BaseService<DealEvent> {
  constructor(protected override readonly http: HttpClient) {
    super(http);
    this.setPath('/api/events');
  }
}
