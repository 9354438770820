import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { BaseEntity, BaseEntityFormGroup, CommonStatusLabel, Currency } from '@estimator/models';
import { MenuItem } from 'primeng/api';
import { Agreement, CVEType } from './agreement';
import { HSOABunkering, HSOABunkeringFormGroup } from './bunkering';
import { vmsRoutes } from './routes';

export enum HSOAStatus {
  Draft = 'DRAFT',
  Pending = 'PENDING',
  Fixed = 'FIXED',
}

export enum HsoaTableFilterOption {
  ALL = 'All',
  PENDING = CommonStatusLabel.PENDING,
  FIXED = CommonStatusLabel.FIXED,
  WORKING = 'Working',
}

export interface HSOA extends BaseEntity {
  agreement_id?: number;
  balance_due?: number;
  bunkerings_on_delivery?: HSOABunkering[];
  bunkerings_on_redelivery?: HSOABunkering[];
  deduct_bunkerings_on_delivery?: boolean;
  deduct_bunkerings_on_redelivery?: boolean;
  owners_expenses?: HSOAExpenses[];
  charterers_expenses?: HSOAExpenses[];
  commission?: number;
  commission_debit?: number;
  cve_credit?: number;
  cve_rate?: number;
  cve_type?: CVEType;
  end_date?: string | Date;
  start_date?: string | Date;
  hire_credit?: number;
  hire_rate?: number;
  number?: number;
  paid_statements?: HSOAStatement[];
  status?: HSOAStatus;
  total_credit?: number;
  total_debit?: number;
  offhire_periods?: OffHirePeriod[];

  currency_id?: number;
  currency?: Currency;
  exchange_rate?: number;
  total_due_in_currency?: number;
  exchange_rate_commission?: number;
  exchange_rate_mode?: ExchangeRateMode;
}

export interface OffHirePeriod {
  hire_rate?: number;
  start?: string | Date;
  end?: string | Date;
  hire_debit?: number;
  offset_periods?: OffsetPeriod[];
}

export interface OffsetPeriod {
  start?: string | Date | null;
  end?: string | Date | null;
  commission_debit?: number | null;
  commission?: number | null;
  hire_credit?: number | null;
  hire_rate?: number | null;
}

export interface HSOAOffHireFormGroup extends HSOAOffFormGroup {
  hire_debit?: FormControl<number | null>;
  arrayOffset?: FormControl<OffsetPeriod[] | null>;
  offset_periods?: FormArray<FormGroup<HSOAOffsetFormGroup>>;
}

export interface HSOAOffsetFormGroup extends HSOAOffFormGroup {
  commission?: FormControl<number | null>;
  hire_credit?: FormControl<number | null>;
  commission_debit?: FormControl<number | null>;
}

export interface HSOAOffFormGroup {
  hire_rate?: FormControl<number | null>;
  days_number?: FormControl<number | null>;
  days?: FormControl<string | null>;
  start?: FormControl<Date | string | null>;
  end?: FormControl<Date | string | null>;
}

export interface HSOAExpenses {
  amount?: number;
  description?: string;
  commission?: number;
  commission_amount?: number;
  type?: HSOAExpensesType;
}

export interface HSOAExpensesFormGroup {
  amount?: FormControl<number | null>;
  description?: FormControl<string | null>;
  type?: FormControl<HSOAExpensesType | null>;
  commission?: FormControl<number | null>;
  commission_amount?: FormControl<number | null>;
}

export enum HSOAExpensesType {
  DEBIT = 'DEBIT',
  CREDIT = 'CREDIT',
}

export enum ExchangeRateMode {
  MANUAL = 'MANUAL',
  AUTO = 'AUTO',
}

export enum HSOAExpensesArrayType {
  OWNERS = 'OWNERS',
  CHARTERER = 'CHARTERER',
}
export interface HSOAStatement {
  balance?: number;
  description?: string;
  expense_type?: HSOAExpensesType;
  transaction_id?: number;
}

export interface HSOAFormGroup extends BaseEntityFormGroup {
  hire_rate?: FormControl<number | null>;
  start_date?: FormControl<Date | string | null>;
  end_date?: FormControl<Date | string | null>;
  days?: FormControl<string | null>;
  days_number?: FormControl<number | null>;
  commission?: FormControl<number | null>;
  cve_rate?: FormControl<number | null>;
  cve_type?: FormControl<CVEType | null>;
  exchange_rate?: FormControl<number | null>;
  exchange_rate_mode?: FormControl<ExchangeRateMode | null>;
  exchange_rate_commission?: FormControl<number | null>;
  currency?: FormControl<Currency | null>;
  deduct_bunkerings_on_delivery?: FormControl<boolean | null>;
  deduct_bunkerings_on_redelivery?: FormControl<boolean | null>;

  offhire_periods?: FormArray<FormGroup<HSOAOffHireFormGroup>>;
  bunkerings_on_delivery?: FormArray<FormGroup<HSOABunkeringFormGroup>>;
  bunkerings_on_redelivery?: FormArray<FormGroup<HSOABunkeringFormGroup>>;
  owners_expenses?: FormArray<FormGroup<HSOAExpensesFormGroup>>;
  charterers_expenses?: FormArray<FormGroup<HSOAExpensesFormGroup>>;
}

export interface VMSMenuItem extends MenuItem {
  routeId?: (typeof vmsRoutes)[keyof typeof vmsRoutes];
}

export interface SubAgreementSelectEvent {
  agreement?: Agreement;
  route?: (typeof vmsRoutes)[keyof typeof vmsRoutes];
}
