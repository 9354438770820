import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { ERROR_BASE_TEMPLATE, NotificationType, UserConfirmDialogConfig } from '@estimator/models';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Subject } from 'rxjs';

@Component({
  selector: 'estimator-user-confirm',
  templateUrl: './user-confirm.component.html',
  styleUrls: ['./user-confirm.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserConfirmComponent implements OnDestroy {
  @Input() confirmSuccess = false;
  @Input() responseError = '';
  @Output() begin = new EventEmitter<void>();
  readonly NotificationType = NotificationType;
  private _onDestroy$ = new Subject<void>();

  constructor(private readonly dialogConfig: DynamicDialogConfig<UserConfirmDialogConfig>) {
    if (this.dialogConfig.data) {
      const { confirmSuccess, responseError, begin } = this.dialogConfig.data;
      if (begin) {
        this.begin = begin;
      }
      if (responseError) {
        this.responseError = `${ERROR_BASE_TEMPLATE} ${responseError}`;
      }
      if (confirmSuccess) {
        this.confirmSuccess = confirmSuccess;
      }
    }
  }
  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  onStart(): void {
    this.begin.emit();
  }
}
