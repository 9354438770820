import { PAGES } from './routes';

export const HEADER_MENU = [
  // PAGES.PRODUCTS,
  // PAGES.ABOUT,
  PAGES.FEATURES,
  PAGES.BLOG,
  // PAGES.SUSTAINABILITY,
  PAGES.CONTACTS,
];
export const FOOTER_MENU_COMPANY = [PAGES.SUSTAINABILITY, PAGES.ABOUT, PAGES.BLOG];
