import { EventEmitter, Injectable } from '@angular/core';
import {
  ASANA_PROJECT_FEEDBACK_ID,
  FeedbackMessage,
  FeedbackMessageDialogConfig,
  FeedbackMessageRequest,
  NotificationType,
} from '@estimator/models';
import { AsanaService, NotificationService } from '@estimator/services';
import { FeedbackFormComponent } from '@estimator/ui';
import { Action, Store } from '@ngxs/store';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Subject, takeUntil } from 'rxjs';
import { XAuthState } from '../x-auth';
import { OpenFeedbackForm } from './feedback.actions';

/* export interface FeedbackStateModel {}
@State<FeedbackStateModel>({
  name: 'feedback',
  defaults: {},
}) */
@Injectable()
export class FeedbackState {
  private _onDestroy$ = new Subject<void>();

  constructor(
    private readonly dialogService: DialogService,
    private readonly store: Store,
    private readonly asanaSrv: AsanaService,
    private readonly notificationService: NotificationService
  ) {}

  @Action(OpenFeedbackForm)
  openFeedbackForm(/* { dispatch } StateContext<FeedbackStateModel> */) {
    const email = this.store.selectSnapshot(XAuthState.getUserEmail);
    const send = new EventEmitter<FeedbackMessage>();
    const config: DynamicDialogConfig<FeedbackMessageDialogConfig> = {
      header: 'Feedback',
      data: {
        email,
        send,
      },
      width: '400px',
      height: '400px',
    };
    const feedbackFormDialogRef = this.dialogService.open(FeedbackFormComponent, config);
    send.pipe(takeUntil(this._onDestroy$)).subscribe((feedbackMessage) => {
      const request: FeedbackMessageRequest = {
        data: {
          projects: [ASANA_PROJECT_FEEDBACK_ID],
          name: feedbackMessage.email,
          notes: feedbackMessage.message,
        },
      };
      this.asanaSrv.createTaskFromFeedbackForm(request).subscribe(
        () => {
          feedbackFormDialogRef.close();
          this.notificationService.showNotification(
            `We will revert soon`,
            NotificationType.Success
          );
        },
        () => {
          this.notificationService.showNotification(
            `Error when creating a feedback`,
            NotificationType.Error
          );
        }
      );
    });
  }
}
