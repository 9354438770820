import {
  AfterContentChecked,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import {
  changedKeys,
  getCurrencyShortSymbol,
  makeBaseEntityPartFormGroup,
} from '@estimator/helpers';
import {
  Currency,
  DEFAULT_AUTOSAVE,
  DealFormHasChanges,
  DomEvents,
  HireFinance,
  MAX_FINANCE_VALUE,
  MiscFormGroup,
  MiscFormGroupValue,
  PrimeNgIcons,
  Sundry,
  SundryFormGroup,
  SundryType,
  UserInputDuration,
} from '@estimator/models';
import { cloneDeep, isEqual } from 'lodash';
import {
  Subject,
  Subscription,
  debounceTime,
  filter,
  map,
  pairwise,
  startWith,
  takeUntil,
} from 'rxjs';
import { UserDurationInputComponent } from '../user-duration-input/user-duration-input.component';

@Component({
  selector: 'estimator-misc-form',
  templateUrl: './misc-form.component.html',
  styleUrls: ['./misc-form.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MiscFormComponent implements OnDestroy, AfterContentChecked {
  @ViewChild('miscControls', { read: ElementRef }) miscControls?: ElementRef;
  @ViewChild('extraIdle') extraIdle?: UserDurationInputComponent;
  @ViewChild('extraSailing') extraSailing?: UserDurationInputComponent;
  @Input()
  set sundries(data: Sundry[]) {
    this._sundries = cloneDeep(data);
    if (this.sundries.length) {
      this.sundryFormGroup.controls.extras.clear({ emitEvent: false });
      this.sundries.forEach((sundry) => {
        const key = sundry.description;
        if (key) {
          const sundryFg = this.sundryFormGroup.get(key);
          if (sundryFg) {
            sundryFg.patchValue(sundry, { emitEvent: false });
            if (!sundry.amount) {
              sundryFg.patchValue({ amount: 0 }, { emitEvent: false });
            }
          } else {
            const extraFg = this.sundryFormGroup.controls.extras.controls.find(
              (control) => control.controls.description.value === sundry.description
            );
            if (extraFg) {
              extraFg.patchValue(sundry, { emitEvent: false });
              if (!sundry.amount) {
                extraFg.patchValue({ amount: 0 }, { emitEvent: false });
              }
            } else {
              this.sundryFormGroup.controls.extras.push(this.makeSundryFormGroup(sundry));
            }
          }
        }
      });
      this.initialValue = this.sundryFormGroup.value;
    }
    if (!this.sundryFormGroup.controls.extras.length) {
      this.onAddExtraSundry();
      this.onAddExtraSundry();
    }
  }
  get sundries(): Sundry[] {
    return this._sundries;
  }

  @Input()
  set hireFinance({ extra_idle_minutes, extra_sailing_laden_minutes }: HireFinance) {
    if (extra_idle_minutes) {
      this.sundryFormGroup.controls.extra_idle_minutes.patchValue(extra_idle_minutes, {
        emitEvent: false,
      });
      if (this.extraIdle) {
        this.extraIdle.control = this.sundryFormGroup.controls.extra_idle_minutes;
      }
    }
    if (extra_sailing_laden_minutes) {
      this.sundryFormGroup.controls.extra_sailing_laden_minutes.patchValue(
        extra_sailing_laden_minutes,
        {
          emitEvent: false,
        }
      );
      if (this.extraSailing) {
        this.extraSailing.control = this.sundryFormGroup.controls.extra_sailing_laden_minutes;
      }
    }
    this.initialValue = this.sundryFormGroup.value;
    if (this._formChangesSubscription$) {
      this._formChangesSubscription$.unsubscribe();
    }
    this._formChangesSubscription$ = this.sundryFormGroup.valueChanges
      .pipe(
        debounceTime(/* this.defaultAutoSave */ 100),
        takeUntil(this._onDestroy$),
        startWith(this.initialValue),
        pairwise(),
        filter(([prev, curr]) => {
          return (
            /*  prev &&
            prev?.extra_idle_minutes &&
            prev?.extra_sailing_laden_minutes && */
            !isEqual(curr, prev)
          );
        }),
        map(([prev, curr]) => {
          console.log('Misc form has changes', changedKeys(prev, curr));
          this.prevFormValue = prev;
          this.currFormValue = curr;
          return curr;
        })
      )
      .subscribe((res) => {
        if (res) {
          const changeExtraInsurance =
            this.prevFormValue?.[SundryType.ExtraInsurance]?.amount !==
            this.currFormValue?.[SundryType.ExtraInsurance]?.amount;
          const changeMisc =
            this.prevFormValue?.[SundryType.Misc]?.amount !==
            this.currFormValue?.[SundryType.Misc]?.amount;
          const changeExtras = this.prevFormValue?.extras.some((prev, index) => {
            return prev.amount !== this.currFormValue?.extras[index].amount;
          });
          this.hasChanges.emit({
            change: true,
            estimate: !changeExtraInsurance && !changeMisc && !changeExtras,
            estimateFinances: changeExtraInsurance || changeMisc || changeExtras,
          });
        }
      });
  }

  @Input()
  set currencies(data: Currency[]) {
    if (data?.length) {
      this._currencies = data;
      this.defaultCurrency = this._currencies.find((cur) => cur.currency_code === 'USD');
      if (this.defaultCurrency) {
        this.sundryFormGroup.controls.extras.controls.forEach((form) => {
          if (!form.controls.currency.value) {
            form.patchValue(
              {
                currency: this.defaultCurrency,
                currency_id: this.defaultCurrency?.id,
              },
              { emitEvent: false }
            );
          }
        });
      }
    }
  }
  get currencies(): Currency[] {
    return this._currencies;
  }

  @Input()
  set dealId(id: number) {
    this._dealId = id;
    this.sundryFormGroup.controls.extras.controls.forEach((form) => {
      if (!form.controls.deal_id.value) {
        form.patchValue(
          {
            deal_id: this._dealId,
          },
          { emitEvent: false }
        );
      }
    });
  }
  get dealId(): number {
    return this._dealId;
  }
  @Input()
  set isFixed(value: boolean) {
    this._isFixed = value;
  }
  get isFixed(): boolean {
    return this._isFixed;
  }
  @Input() defaultAutoSave = DEFAULT_AUTOSAVE;

  @Output() hasChanges = new EventEmitter<DealFormHasChanges>();
  sundryFormGroup = new FormGroup<MiscFormGroup>(
    {
      [SundryType.ExtraInsurance]: this.makeSundryFormGroup(
        this.sundries?.find((sundry) => sundry.description === SundryType.ExtraInsurance)
      ),
      [SundryType.Misc]: this.makeSundryFormGroup(
        this.sundries?.find((sundry) => sundry.description === SundryType.Misc)
      ),
      extras: new FormArray<FormGroup<SundryFormGroup>>([]),
      extra_idle_minutes: new FormControl<UserInputDuration | null>(null),
      extra_sailing_laden_minutes: new FormControl<UserInputDuration | null>(null),
    },
    { updateOn: DomEvents.Blur }
  );
  prevFormValue?: MiscFormGroupValue;
  currFormValue?: MiscFormGroupValue;
  defaultCurrency?: Currency;
  tabIndex = 300;
  initialValue?: any;
  readonly SundryType = SundryType;
  readonly iterableControls = Object.values(SundryType);
  readonly getCurrencySymbol = getCurrencyShortSymbol;
  readonly PrimeNgIcons = PrimeNgIcons;
  readonly MAX_FINANCE_VALUE = MAX_FINANCE_VALUE;
  private _sundries: Sundry[] = [];
  private _currencies: Currency[] = [];
  private _dealId = 0;
  private _onDestroy$ = new Subject<void>();
  private _formChangesSubscription$: Subscription | null = null;
  private _isFixed = false;

  ngAfterContentChecked(): void {
    if (this.isFixed) {
      this.sundryFormGroup.disable();
    }
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  makeSundryFormGroup(sundry?: Sundry): FormGroup<SundryFormGroup> {
    return new FormGroup<SundryFormGroup>(
      {
        ...makeBaseEntityPartFormGroup(sundry).controls,
        amount: new FormControl<number | null>(sundry?.amount || 0),
        currency: new FormControl<Currency | null>(
          sundry?.currency || this.defaultCurrency || null
        ),
        currency_id: new FormControl<number | null>(
          sundry?.currency_id || this.defaultCurrency?.id || null
        ),
        deal_id: new FormControl<number | null>(sundry?.deal_id || this.dealId),
        description: new FormControl<string | null>(sundry?.description || null),
      },
      { updateOn: DomEvents.Blur }
    );
  }

  onAddExtraSundry(): void {
    this.sundryFormGroup.controls.extras.push(
      this.makeSundryFormGroup({
        description: `Extra ${this.sundryFormGroup.controls.extras.length + 1}`,
        deal_id: this.dealId,
        currency: this.defaultCurrency,
      })
    );
  }
  onDeleteExtraSundry(index: number): void {
    this.sundryFormGroup.controls.extras.removeAt(index);
    this.recalculateOrders();
  }

  recalculateOrders(): void {
    const extras = this.sundryFormGroup.controls.extras;
    extras.controls.forEach((control, index) => {
      control.controls.description.patchValue(`Extra ${index + 1}`);
    });
  }

  prepareMiscToSave(/* multiplication = true */): {
    sundries: Sundry[];
    partOfHireFinance: Partial<HireFinance>;
  } {
    const fgValue = cloneDeep(this.sundryFormGroup.getRawValue());
    const sundries: Sundry[] = [];
    const partOfHireFinance: Partial<HireFinance> = {
      extra_idle_minutes: fgValue.extra_idle_minutes as UserInputDuration,
      extra_sailing_laden_minutes: fgValue.extra_sailing_laden_minutes as UserInputDuration,
    };
    this.iterableControls.forEach((key) => {
      const sundry = fgValue[key];
      sundries.push(sundry as Sundry);
    });
    fgValue.extras.forEach((extra) => {
      sundries.push(extra as Sundry);
    });
    return { sundries, partOfHireFinance };
  }
}
