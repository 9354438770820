import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouteMapModule } from '../route-map/route-map.module';
import { SelectedValuesListComponent } from '../selected-values-list/selected-values-list.component';
import { ShiplistZonesPortsFilterModule } from '../shiplist-zones-ports-filter/shiplist-zones-ports-filter.module';
import { ShiplistMapDialogComponent } from './shiplist-map-dialog.component';

export const SHIPLIST_MAP_MODULE_IMPORTS = [
  CommonModule,
  ReactiveFormsModule,
  RouteMapModule,
  ShiplistZonesPortsFilterModule,
  SelectedValuesListComponent,
];

@NgModule({
  declarations: [ShiplistMapDialogComponent],
  imports: SHIPLIST_MAP_MODULE_IMPORTS,
  exports: [ShiplistMapDialogComponent],
})
export class ShiplistMapDialogModule {}
