import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  PortDisbursementAccount,
  PortDisbursementRequest,
  PortDisbursementResponse,
} from '@estimator/models';
import { Observable } from 'rxjs';
import { BaseService } from '../base';

@Injectable({
  providedIn: 'root',
})
export class PortDisbursementService extends BaseService<PortDisbursementAccount> {
  private _path = '/api/xfinance/pda';
  constructor(protected override readonly http: HttpClient) {
    super(http);
    this.setPath(this._path);
  }

  getPortDisbursements(request: PortDisbursementRequest): Observable<PortDisbursementResponse> {
    let params = new HttpParams();
    params = params.set('port_id', request.port_id);
    // deprecated
    /* if (request.dwt) {
      params = params.set('dwt', request.dwt);
    } 
    if (request.general_vessel_id) {
      params = params.set('general_vessel_id', request.general_vessel_id);
    } */
    return this.http.get<PortDisbursementResponse>(`${this._path}`, { params });
  }
}
