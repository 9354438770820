export const platformMeasureUnitMock = [
  {
    id: '82982447-3ac2-4c76-b237-03731af06e3d',
    name: 'm3',
    description: 'Cubic metres',
    active: true,
    __typename: 'measure_unit_meta',
  },
  {
    id: 'a9be2ce7-3213-403b-ba43-382c89da1559',
    name: 'mt',
    description: 'Metric tonnes',
    active: true,
    __typename: 'measure_unit_meta',
  },
];
