import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from '@estimator/directives';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { TableModule } from 'primeng/table';
import { PriceAndCurrencyInputModule } from '../price-and-currency-input/price-and-currency-input.module';
import { UserDurationInputModule } from '../user-duration-input/user-duration-input.module';
import { DemurrageDespatchFormComponent } from './demurrage-despatch-form.component';

export const DEMURRAGE_DESPATCH_FORM_MODULE_IMPORTS = [
  ButtonModule,
  CommonModule,
  CheckboxModule,
  DropdownModule,
  InputNumberModule,
  PriceAndCurrencyInputModule,
  ReactiveFormsModule,
  TableModule,
  UserDurationInputModule,
  DirectivesModule,
];

@NgModule({
  declarations: [DemurrageDespatchFormComponent],
  imports: DEMURRAGE_DESPATCH_FORM_MODULE_IMPORTS,
  exports: [DemurrageDespatchFormComponent],
})
export class DemurrageDespatchFormModule {}
