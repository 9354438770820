import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { IForm, PAGE, PAGES } from '@estimator/landing-models';
import { CookiesForm } from '../models';

export const GOOGLE_TAG_MANAGER = `<!-- Google Tag Manager -->
<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-TVC3XJ8M');</script>
<!-- End Google Tag Manager -->`;

export const GOOGLE_TAG_MANAGER_NOSCRIPT = `<!-- Google Tag Manager (noscript) -->
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TVC3XJ8M"
height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
<!-- End Google Tag Manager (noscript) -->`;

declare global {
  interface Window {
    dataLayer: any;
  }
}
@Component({
  selector: 'mar-cookies',
  template: `
    <div class="container-dark container-cookies w-100" *ngIf="isVisible" [formGroup]="cookiesForm">
      <div class="cookies-header">
        <p>
          We use cookies to improve user experience and analyze web traffic. For these reasons, we
          may share your site usage with our analytics partners. By clicking “Accept” you consent to
          store on your device all of the technologies described in our
          <a [routerLink]="[PAGE.PRIVACY_POLICY]" class="no-wrap">{{
            PAGES_DETAILED.PRIVACY_POLICY.name
          }}</a>
        </p>
        <div class="display-flex items-center cookies-item margin-left-auto">
          <a (click)="onOpenCookiesSettings()" class="no-wrap">Customize settings</a>
          <mar-button color="blue" (click)="onCookiesFormSubmit()">Accept cookies</mar-button>
        </div>
      </div>
      <div class="cookies-detail direction-column" #cookiesDetail>
        <div class="cookies-item">
          <div>
            <b>Strictly Necessary Cookies. Always ACTIVE.</b>
            <p class="mobile-hide">
              These cookies are necessary for the website to function and cannot be switched off in
              our systems. They are usually only set in response to actions made by you which amount
              to a request for services, such as setting your privacy preferences, logging in or
              filling in forms. You can set your browser to block or alert you about these cookies,
              but some parts of the site will not then work. These cookies do not store any
              personally identifiable information.
            </p>
          </div>
          <mar-checkbox
            [value]="1"
            [disabled]="true"
            formControlName="necessary_cookies"
          ></mar-checkbox>
        </div>
        <div class="cookies-item">
          <div>
            <b>Performance cookies.</b>
            <p class="mobile-hide">
              Performance Cookies. These cookies allow us to count visits and traffic sources so we
              can measure and improve the performance of our site. They help us to know which pages
              are the most and least popular and see how visitors move around the site. All
              information these cookies collect is aggregated and therefore anonymous. If you do not
              allow these cookies we will not know when you have visited our site, and will not be
              able to monitor its performance.
            </p>
          </div>
          <mar-checkbox [value]="1" formControlName="performance_cookies"></mar-checkbox>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      @import '_variables';
      :host {
        .container-cookies {
          position: fixed;
          bottom: 0;
          z-index: 10;
          padding: 2.4rem 4.8rem;
          display: flex;
          flex-direction: column;
          gap: 2.4rem;
          color: $white;
          .cookies-header {
            display: grid;
            grid-template-columns: 9fr 3fr;
            align-items: center;
            justify-content: space-between;
            gap: 2.4rem;
            a {
              text-decoration: underline;
              cursor: pointer;
              color: $white;
            }
          }
          .cookies-item,
          .cookies-detail {
            display: flex;
            gap: 2.4rem;
          }
          .cookies-detail {
            display: none;
            &.show {
              display: flex;
            }
          }
        }
        .container-dark {
          border-top: 1px solid rgba(255, 255, 255, 0.15);
          border-radius: 3.2rem 3.2rem 0 0;
          background: rgba(6, 11, 24, 0.9);
        }
        @media (max-width: $tablet-width) {
          .container-cookies {
            .cookies-header {
              display: flex;
              flex-direction: column;
            }
          }
        }
      }
    `,
  ],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CookiesComponent implements OnInit {
  @ViewChild('cookiesDetail') cookiesDetail?: ElementRef;
  isVisible = true;
  cookiesForm = this.fb.group<IForm<CookiesForm>>({
    necessary_cookies: [{ value: true, disabled: true }, [Validators.required]],
    performance_cookies: [true],
  });

  readonly PAGES_DETAILED = PAGES;
  readonly PAGE = PAGE;

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly fb: FormBuilder
  ) {
    window.dataLayer = window.dataLayer || [];
  }

  ngOnInit(): void {
    const userCookies = localStorage.getItem('cookies');
    if (userCookies) {
      this.isVisible = false;
      this.addGoogleTagMangerInDocument();
    } else {
      window.dataLayer.push(['consent', 'default', { analytics_storage: 'denied' }]);
    }
  }

  onCookiesFormSubmit(): void {
    localStorage.setItem('cookies', JSON.stringify(this.cookiesForm.getRawValue()));
    this.isVisible = false;
    this.addGoogleTagMangerInDocument();
  }

  addGoogleTagMangerInDocument(): void {
    const userCookies = localStorage.getItem('cookies') || '';
    const cookies: CookiesForm = JSON.parse(userCookies);
    window.dataLayer.push([
      'consent',
      'update',
      { analytics_storage: cookies.performance_cookies ? 'granted' : 'denied' },
    ]);

    // this.document.head.insertAdjacentHTML('afterbegin', GOOGLE_TAG_MANAGER);

    // const iframe = this.document.createElement('iframe');
    // iframe.setAttribute('width', '0');
    // iframe.setAttribute('height', '0');
    // iframe.setAttribute('src', 'https://www.googletagmanager.com/ns.html?id=GTM-TVC3XJ8M');
    // iframe.setAttribute('style', 'display:none;visibility:hidden');

    // const noscript = this.document.createElement('noscript');
    // noscript.appendChild(iframe);

    // const commentBefore = this.document.createComment(' Google Tag Manager (noscript) ');
    // const commentAfter = this.document.createComment(' End Google Tag Manager (noscript) ');

    // this.document.body.prepend(commentAfter);
    // this.document.body.prepend(noscript);
    // this.document.body.prepend(commentBefore);
  }

  onOpenCookiesSettings(): void {
    this.cookiesDetail?.nativeElement.classList.toggle('show');
  }
}
