import { UiSettingsStateModel } from '@estimator/models';
import { CargoRulesStateModel } from './lib/cargo-rules';
import { CargoTypeStateModel } from './lib/cargo-types';
import { CompanyStateModel } from './lib/company';
import { CurrencyStateModel } from './lib/currency';
import { DealStateModel } from './lib/deal';
import { DimensionStateModel } from './lib/dimension';
import { FleetVesselStateModel } from './lib/fleet-vessel';
import { FolderStateModel } from './lib/folder';
import { FreightTypeStateModel } from './lib/freight-type';
import { FuelStateModel } from './lib/fuel';
import { PortStateModel } from './lib/port';
import { PortDisbursementStateModel } from './lib/port-disbursement';
import { ProductsStateModel } from './lib/products';
import { RouteStateModel } from './lib/route';
import { ShiplistTableStateModel } from './lib/shiplist';
import { TablesStateModel } from './lib/tables';
import { VesselStateModel } from './lib/vessel';
import { XAuthStateModel } from './lib/x-auth';
import { XmarBunkerPriceIndexStateModel } from './lib/xbpi-history';

export * from './lib/cargo-rules';
export * from './lib/cargo-types';
export * from './lib/company';
export * from './lib/currency';
export * from './lib/deal';
export * from './lib/dimension';
export * from './lib/feedback';
export * from './lib/fleet-vessel';
export * from './lib/folder';
export * from './lib/freight-type';
export * from './lib/fuel';
export * from './lib/port';
export * from './lib/port-disbursement';
export * from './lib/products';
export * from './lib/route';
export * from './lib/shiplist';
export * from './lib/tables';
export * from './lib/ui-settings';
export * from './lib/vessel';
export * from './lib/x-auth';
export * from './lib/xbpi-history';

export interface AppStateModel {
  cargoRules?: CargoRulesStateModel;
  cargoTypes?: CargoTypeStateModel;
  company?: CompanyStateModel;
  currency?: CurrencyStateModel;
  deal?: DealStateModel;
  dimension?: DimensionStateModel;
  fleetVessels?: FleetVesselStateModel;
  folder?: FolderStateModel;
  freightType?: FreightTypeStateModel;
  fuel?: FuelStateModel;
  xbpiHistory?: XmarBunkerPriceIndexStateModel;
  port?: PortStateModel;
  portDisbursement?: PortDisbursementStateModel;
  products?: ProductsStateModel;
  route?: RouteStateModel;
  tablesState?: TablesStateModel;
  uiSettings?: UiSettingsStateModel;
  vessel?: VesselStateModel;
  xauth?: XAuthStateModel;
  shiplistTable?: ShiplistTableStateModel;
}
