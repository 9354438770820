import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  Currency,
  DEFAULT_FIAT_MULTIPLIER,
  DisbursementFinance,
  NOT_APPLICABLE,
  PriceDto,
  ZERO_STRING,
} from '@estimator/models';
import {
  ColDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ValueFormatterParams,
} from 'ag-grid-community';
import { AgGridNumericCellEditorComponent } from '../ag-grid-shared/ag-grid-numeric-editor.component';
import { AgGridPriceEditorComponent } from '../ag-grid-shared/ag-grid-price-with-currency-editor.component';

@Component({
  selector: 'estimator-disbursement-finance-list',
  templateUrl: './disbursement-finance-list.component.html',
  styleUrls: ['./disbursement-finance-list.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisbursementFinanceListComponent {
  @Input() finances: DisbursementFinance[] = [];
  @Input() currencies: Currency[] = [];
  @Output() updateFinances = new EventEmitter<DisbursementFinance[]>();
  columnDefs: ColDef<DisbursementFinance>[] = [
    {
      field: 'port.name',
      headerName: 'Port',
      flex: 1,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      flex: 1,
    },
    {
      field: 'price',
      headerName: 'Price',
      editable: true,
      cellEditor: 'priceEditor',
      cellEditorParams: (params: any) => {
        const finance: DisbursementFinance = params.data;
        params.value = { price: finance.price, currency: finance.currency };
        return {
          ...params,
          currencies: this.currencies,
          placeholder: 'Currency',
          emptyDataArray: 'No currency found',
        };
      },
      valueFormatter: (params: ValueFormatterParams<DisbursementFinance>) => {
        if (params.data) {
          const finance: DisbursementFinance = params.data;
          if (finance.currency) {
            return `${
              (finance.price || 0) / (finance.currency?.fiat_multiplier || DEFAULT_FIAT_MULTIPLIER)
            } ${finance.currency.currency_code}`;
          }
        }
        return NOT_APPLICABLE;
      },
      onCellValueChanged: (event) => {
        const finance: DisbursementFinance = event.data;
        if (event.newValue) {
          const priceDto: PriceDto = event.newValue;
          finance.price = priceDto.price;
          if (priceDto.currency) {
            finance.currency = priceDto.currency;
          }
          if (finance.currency?.id) {
            finance.currency_id = finance.currency.id;
          }
        }
      },
    },
  ];

  gridOptions: GridOptions<DisbursementFinance> = {
    rowModelType: 'clientSide',
    columnDefs: this.columnDefs,
    components: {
      numericEditor: AgGridNumericCellEditorComponent,
      priceEditor: AgGridPriceEditorComponent,
    },
    getRowId: (params) => params.data?.id?.toString() || ZERO_STRING,
    defaultColDef: {
      editable: false,
      resizable: true,
    },
    suppressContextMenu: true,
    suppressScrollOnNewData: true,
    singleClickEdit: true,
    rowSelection: 'single',
    animateRows: true,
    rowHeight: 40,
    headerHeight: 40,
    autoSizePadding: 0,
    domLayout: 'autoHeight',
    onCellEditingStopped: (event) => {
      if (event.data) {
        if (
          !this._gridApi?.getEditingCells().length &&
          event.newValue &&
          event.newValue !== event.oldValue
        ) {
          this.updateFinances.emit(this.rowData);
        }
      }
    },
  };
  private _gridApi?: GridApi<DisbursementFinance>;

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this._gridApi?.stopEditing(true);
    }
    if (event.key === 'Enter') {
      this._gridApi?.stopEditing();
    }
  }

  get rowData(): DisbursementFinance[] {
    const consumptions: DisbursementFinance[] = [];
    this._gridApi?.forEachNode((node) => {
      if (node.data) {
        consumptions.push(node.data);
      }
    });
    return consumptions;
  }

  onGridReady({ api }: GridReadyEvent): void {
    this._gridApi = api;
  }
}
