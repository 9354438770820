<form [formGroup]="feedbackFormGroup">
  <section class="inputs-section">
    <div class="flex-container-column gap-2">
      <div class="p-inputgroup">
        <span
          class="p-inputgroup-addon"
          [ngStyle]="{
            'background-color': isEmailControlInvalid ? PrimeNgColors.Error : PrimeNgColors.Primary,
            'border-color': isEmailControlInvalid ? PrimeNgColors.Error : PrimeNgColors.Primary
          }"
        >
          <i [class]="PrimeNgIcons.AT" [style]="{ color: PrimeNgColors.White }"></i>
        </span>
        <input
          pInputText
          id="email"
          type="email"
          name="email"
          #email
          [placeholder]="DEFAULT_USER"
          formControlName="email"
          [ngClass]="{
            'ng-invalid ng-dirty': isEmailControlInvalid
          }"
          required
        />
      </div>
      <small id="email-error" [style]="{ color: PrimeNgColors.Error }" class="flex-container-row"
        >&nbsp;
        <span *ngIf="isEmailControlInvalid">{{ emailControlError }}</span>
      </small>
    </div>
    <div class="flex-container-column gap-2 margin-top-10">
      <textarea
        pInputTextarea
        class="font-size-12 textarea-feedback"
        inputId="estimator-form_notes"
        formControlName="message"
      ></textarea>
    </div>
  </section>
  <div class="p-fluid margin-top-20">
    <p-button
      class="w-100"
      size="small"
      label="Send"
      [disabled]="feedbackFormGroup.invalid"
      [ngClass]="{ 'pointer-events-none': feedbackFormGroup.invalid }"
      type="button"
      (click)="onSendMessage()"
    >
    </p-button>
  </div>
</form>
