import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Themes } from '@estimator/models';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  switchTheme(theme: Themes): void {
    const themeLink = this.document.getElementById('app-theme') as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = theme + '.css'; // theme = bundle name из project.json
    }
  }
}
