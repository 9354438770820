import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AgGridSharedModule } from './ag-grid-shared/ag-grid-shared.module';
import { AuthorizationFormModule } from './authorization-form/authorization-form.module';
import { BdcUserGuideModule } from './bdc-user-guide/bdc-user-guide.module';
import { BreakEvenAxesModule } from './break-even-axes/break-even-axes.module';
import { BreakEvenDialogModule } from './break-even-dialog/break-even-dialog.module';
import { BreakEvenGridModule } from './break-even-grid/break-even-grid.module';
import { BunkerFinanceListModule } from './bunker-finance-list/bunker-finance-list.module';
import { BunkerSelectDialogModule } from './bunker-select-dialog/bunker-select-dialog.module';
import { ButtonSwitchModule } from './button-switch/button-switch.module';
import { CargoCalculatorTabsModule } from './cargo-calculator-tabs/cargo-calculator-tabs.module';
import { CargoCalculatorModule } from './cargo-calculator/cargo-calculator.module';
import { CargoFinanceListModule } from './cargo-finance-list/cargo-finance-list.module';
import { CargoListModule } from './cargo-list/cargo-list.module';
import { CargoesFormModule } from './cargoes-form/cargoes-form.module';
import { Co2CostsModule } from './co2-costs/co2-costs.module';
import { ConfirmationCodeFormModule } from './confirmation-code-form/confirmation-code-form.module';
import { CoordinateDialogModule } from './coordinate-dialog/coordinate-dialog.module';
import { CoordinateInputModule } from './coordinate-input/coordinate-input.module';
import { CopyFolderDialogModule } from './copy-folder-dialog/copy-folder-dialog.module';
import { DaysDistanceIndicatorsModule } from './days-distance-indicators/days-distance-indicators.module';
import { DealDatePlannerModule } from './deal-date-planner/deal-date-planner.module';
import { DealsListModule } from './deals-list/deals-list.module';
import { DeductiblesListModule } from './deductibles-list/deductibles-list.module';
import { DemurrageDespatchFormModule } from './demurrage-despatch-form/demurrage-despatch-form.module';
import { DialogMapModule } from './dialog-map/dialog-map.module';
import { DialogOpenCloseFolderModule } from './dialog-open-close-folder/dialog-open-close-folder.module';
import { DisbursementFinanceListModule } from './disbursement-finance-list/disbursement-finance-list.module';
import { DrawerModule } from './drawer/drawer.module';
import { EventListModule } from './event-list/event-list.module';
import { EventsFormModule } from './events-form/events-form.module';
import { FastBunkeringListModule } from './fast-bunkering-list/fast-bunkering-list.module';
import { FeedbackFormModule } from './feedback-form/feedback-form.module';
import { FixFolderModule } from './fix-folder/fix-folder.module';
import { FleetVesselCollectionModule } from './fleet-vessel-collection/fleet-vessel-collection.module';
import { FleetVesselCollectionsModule } from './fleet-vessel-collections/fleet-vessel-collections.module';
import { FleetVesselModule } from './fleet-vessel/fleet-vessel.module';
import { FleetVesselsModule } from './fleet-vessels/fleet-vessels.module';
import { FoldersListModule } from './folders-list/folders-list.module';
import { FormDateSwitcherModule } from './form-date-switcher/form-date-switcher.module';
import { HireFinanceListModule } from './hire-finance-list/hire-finance-list.module';
import { ItineraryDateModule } from './itinerary-date/itinerary-date.module';
import { LoginFormModule } from './login-form/login-form.module';
import { LoginReminderModule } from './login-reminder/login-reminder.module';
import { MapModule } from './map/map.module';
import { MiscFormModule } from './misc-form/misc-form.module';
import { MiscListModule } from './misc-list/misc-list.module';
import { PasswordForgotModule } from './password-forgot/password-forgot.module';
import { PasswordRestoreModule } from './password-restore/password-restore.module';
import { PhoneNumberInputModule } from './phone-number-input/phone-number-input.module';
import { PortDisbursementTableModule } from './port-disbursement-table/port-disbursement-table.module';
import { PriceAndCurrencyInputModule } from './price-and-currency-input/price-and-currency-input.module';
import { RegistrationReminderModule } from './registration-reminder/registration-reminder.module';
import { RegistrationSuccessModule } from './registration-success/registration-success.module';
import { RouteMapModule } from './route-map/route-map.module';
import { SaveFolderModule } from './save-folder/save-folder.module';
import { ShipListFilterModule } from './ship-list-filter/ship-list-filter.module';
import { ShipListTableModule } from './ship-list-table/ship-list-table.module';
import { ShiplistMapDialogModule } from './shiplist-map-dialog';
import { ShiplistZonesPortsFilterModule } from './shiplist-zones-ports-filter';
import { SignUpFormModule } from './sign-up-form/sign-up-form.module';
import { SimpleCardModule } from './simple-card/simple-card.module';
import { SuccessOrErrorCardModule } from './success-or-error-card/success-or-error-card.module';
import { ToolbarModule } from './toolbar/toolbar.module';
import { UserConfirmInvitationModule } from './user-confirm-invitation/user-confirm-invitation.module';
import { UserConfirmModule } from './user-confirm/user-confirm.module';
import { UserListModule } from './user-list/user-list.module';
import { VesselAndConsumptionsFormModule } from './vessel-and-consumptions-form/vessel-and-consumptions-form.module';
import { VesselFormModule } from './vessel-form/vessel-form.module';
import { VesselPortSearchModule } from './vessel-port-search/vessel-port-search.module';
import { VesselWidgetModule } from './vessel-widget/vessel-widget.module';
import { VesselsListModule } from './vessels-list/vessels-list.module';
import { XmarBunkerPriceIndexTableModule } from './xbpi-table/xbpi-table.module';

@NgModule({
  imports: [
    AgGridSharedModule,
    AuthorizationFormModule,
    CommonModule,
    ConfirmationCodeFormModule,
    DealsListModule,
    FoldersListModule,
    DrawerModule,
    LoginFormModule,
    DaysDistanceIndicatorsModule,
    PhoneNumberInputModule,
    SignUpFormModule,
    SuccessOrErrorCardModule,
    PasswordForgotModule,
    PasswordRestoreModule,
    SimpleCardModule,
    VesselPortSearchModule,
    VesselsListModule,
    VesselWidgetModule,
    VesselFormModule,
    EventListModule,
    RegistrationSuccessModule,
    CargoListModule,
    FleetVesselCollectionModule,
    FleetVesselCollectionsModule,
    FleetVesselsModule,
    FleetVesselModule,
    DialogMapModule,
    FastBunkeringListModule,
    BunkerFinanceListModule,
    BreakEvenGridModule,
    BreakEvenAxesModule,
    BdcUserGuideModule,
    BreakEvenDialogModule,
    CoordinateDialogModule,
    CoordinateInputModule,
    CargoFinanceListModule,
    DisbursementFinanceListModule,
    HireFinanceListModule,
    CargoCalculatorModule,
    RegistrationReminderModule,
    LoginReminderModule,
    FeedbackFormModule,
    SaveFolderModule,
    ShiplistMapDialogModule,
    ShiplistZonesPortsFilterModule,
    ShipListTableModule,
    ShipListFilterModule,
    FormDateSwitcherModule,
    FixFolderModule,
    XmarBunkerPriceIndexTableModule,
    PortDisbursementTableModule,
    CopyFolderDialogModule,
    DialogOpenCloseFolderModule,
    MiscListModule,
    CargoCalculatorTabsModule,
    DealDatePlannerModule,
    MapModule,
    UserConfirmModule,
    UserConfirmInvitationModule,
    VesselAndConsumptionsFormModule,
    RouteMapModule,
    EventsFormModule,
    CargoesFormModule,
    MiscFormModule,
    ItineraryDateModule,
    UserListModule,
    DeductiblesListModule,
    PriceAndCurrencyInputModule,
    Co2CostsModule,
    DemurrageDespatchFormModule,
    BunkerSelectDialogModule,
    ToolbarModule,
  ],
  exports: [
    AgGridSharedModule,
    AuthorizationFormModule,
    ConfirmationCodeFormModule,
    DealsListModule,
    FoldersListModule,
    DrawerModule,
    LoginFormModule,
    DaysDistanceIndicatorsModule,
    PhoneNumberInputModule,
    SignUpFormModule,
    SuccessOrErrorCardModule,
    PasswordForgotModule,
    PasswordRestoreModule,
    SimpleCardModule,
    VesselPortSearchModule,
    VesselsListModule,
    VesselWidgetModule,
    VesselFormModule,
    EventListModule,
    RegistrationSuccessModule,
    CargoListModule,
    FleetVesselCollectionModule,
    FleetVesselCollectionsModule,
    FleetVesselsModule,
    FleetVesselModule,
    DialogMapModule,
    FastBunkeringListModule,
    BunkerFinanceListModule,
    BreakEvenGridModule,
    BreakEvenAxesModule,
    BdcUserGuideModule,
    BreakEvenDialogModule,
    CoordinateDialogModule,
    CoordinateInputModule,
    CargoFinanceListModule,
    DisbursementFinanceListModule,
    HireFinanceListModule,
    CargoCalculatorModule,
    RegistrationReminderModule,
    LoginReminderModule,
    FeedbackFormModule,
    SaveFolderModule,
    ShiplistMapDialogModule,
    ShiplistZonesPortsFilterModule,
    ShipListTableModule,
    ShipListFilterModule,
    FormDateSwitcherModule,
    FixFolderModule,
    XmarBunkerPriceIndexTableModule,
    PortDisbursementTableModule,
    CopyFolderDialogModule,
    DialogOpenCloseFolderModule,
    MiscListModule,
    CargoCalculatorTabsModule,
    DealDatePlannerModule,
    MapModule,
    VesselAndConsumptionsFormModule,
    RouteMapModule,
    EventsFormModule,
    CargoesFormModule,
    MiscFormModule,
    ItineraryDateModule,
    UserListModule,
    DeductiblesListModule,
    PriceAndCurrencyInputModule,
    Co2CostsModule,
    DemurrageDespatchFormModule,
    ButtonSwitchModule,
    BunkerSelectDialogModule,
    ToolbarModule,
  ],
})
export class UiModule {}
