import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from '@estimator/directives';
import { PipeModule } from '@estimator/helpers';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TooltipModule } from 'primeng/tooltip';
import { DaysDistanceIndicatorsComponent } from './days-distance-indicators.component';

@NgModule({
  declarations: [DaysDistanceIndicatorsComponent],
  imports: [
    ButtonModule,
    CommonModule,
    InputSwitchModule,
    PipeModule,
    ReactiveFormsModule,
    TooltipModule,
    DropdownModule,
    DirectivesModule,
  ],
  exports: [DaysDistanceIndicatorsComponent],
})
export class DaysDistanceIndicatorsModule {}
