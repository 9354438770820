import { Injectable } from '@angular/core';
import { TABLES_STATE_KEY } from '@estimator/models';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ColumnState } from 'ag-grid-community';
import { GetAllTablesState, ResetTableState, SetTableState } from './tables.actions';

export interface TablesStateModel {
  tablesState: Map<string, ColumnState[]>;
  loading: boolean;
}

@State<TablesStateModel>({
  name: 'tablesState',
  defaults: {
    tablesState: new Map(),
    loading: false,
  },
})
@Injectable()
export class TablesState {
  @Selector()
  static getIsLoading({ loading }: TablesStateModel): boolean {
    return loading;
  }
  @Selector()
  static getTableState({ tablesState }: TablesStateModel): Map<string, ColumnState[]> {
    return tablesState;
  }

  @Action(GetAllTablesState)
  getAllTablesState({ patchState }: StateContext<TablesStateModel>) {
    const unparsedTablesState = localStorage.getItem(TABLES_STATE_KEY);
    if (unparsedTablesState != null) {
      const objectState: { [key: string]: ColumnState[] } = JSON.parse(unparsedTablesState);
      const tablesState: Map<string, ColumnState[]> = new Map(Object.entries(objectState));
      if (tablesState.size) {
        patchState({ tablesState });
      }
    }
  }

  @Action(SetTableState)
  setTableState(
    { getState, patchState }: StateContext<TablesStateModel>,
    { tableKey, tableState }: SetTableState
  ) {
    const state = getState().tablesState;
    state.set(tableKey, tableState);
    patchState({ tablesState: state });
    localStorage.setItem(TABLES_STATE_KEY, JSON.stringify(Object.fromEntries(state)));
  }

  @Action(ResetTableState)
  resetTableState(
    { getState, patchState }: StateContext<TablesStateModel>,
    { tableKey }: ResetTableState
  ) {
    const state = getState().tablesState;
    state.delete(tableKey);
    patchState({ tablesState: state });
    localStorage.removeItem(tableKey);
  }
}
