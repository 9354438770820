import { FormControl } from '@angular/forms';
import { GrainCalculationType, UserTimeSettings } from './ui-items';
import { TonnageMetricsTypes } from './vessel';

export interface UiSettingsStateModel {
  default_cranes_on_vessel?: boolean;
  default_port_time_type?: UserTimeSettings;
  default_weather_factor?: number;
  default_time_charter_commission?: number;
  default_voyage_commission?: number;
  default_extra_insurance?: number;
  default_extra_cost?: number;
  default_rule_id?: number;
  default_grain_bale_calculation_type?: GrainCalculationType;
  /** only front, now hide on front, use like = DEFAULT_AUTOSAVE */
  autoSave?: number | null;
  // showDateTimeTable?: boolean;
  // background_color?: string;
  laden_route_color?: string;
  ballast_route_color?: string;
  show_canals?: boolean;
  color_scheme?: Themes;
  default_result_bar_position?: ResultBarPosition;
  default_map_position?: MapPosition;
}

export interface UiSettingsFormGroup {
  default_cranes_on_vessel?: FormControl<boolean | null>;
  default_port_time_type?: FormControl<UserTimeSettings | null>;
  default_weather_factor?: FormControl<number | null>;
  default_time_charter_commission?: FormControl<number | null>;
  default_voyage_commission?: FormControl<number | null>;
  default_extra_insurance?: FormControl<number | null>;
  default_extra_cost?: FormControl<number | null>;
  default_rule_id?: FormControl<number | null>;
  default_grain_bale_calculation_type?: FormControl<GrainCalculationType | null>;
  /** only front, now hide on front, use like = DEFAULT_AUTOSAVE */
  autoSave?: FormControl<number | null>;
  laden_route_color?: FormControl<string | null>;
  ballast_route_color?: FormControl<string | null>;
  show_canals?: FormControl<boolean | null>;
  color_scheme?: FormControl<Themes | null>;
  default_result_bar_position?: FormControl<ResultBarPosition | null>;
  default_map_position?: FormControl<MapPosition | null>;
  vessel_capacity?: FormControl<TonnageMetricsTypes | null>;
  map_distance_widget?: FormControl<boolean | null>;
  autosave_delay_seconds?: FormControl<number | null>;
}

export enum Themes {
  DARK = 'dark-theme',
  LIGHT = 'light-theme',
}

export enum ResultBarPosition {
  BOTTOM = 'bottom',
  STICKY = 'sticky',
  TOP = 'top',
  MAP_RIGHT = 'map-right',
  MAP_LEFT = 'map-left',
}

export enum MapPosition {
  LEFT_RIGHT = 'left-right',
  RIGHT_LEFT = 'right-left',
}
