<p-tabView class="fleet-vessels-tab-view sticky">
  <p-tabPanel>
    <ng-template pTemplate="header">
      <div>
        <span>My vessels</span>
      </div>
    </ng-template>
    <estimator-fleet-vessel
      [fleetVessels]="myVessels"
      [collections]="myCollections"
      (updateFleetVessel)="onUpdateFleetVessel($event)"
      (updateFleetVesselActive)="onUpdateFleetVesselActive($event)"
      (deleteFleetVessel)="onDeleteFleetVessel($event)"
      (positionMap)="onPositionMap($event)"
    >
    </estimator-fleet-vessel>
  </p-tabPanel>
  <p-tabPanel>
    <ng-template pTemplate="header">
      <div>
        <span>My company vessels</span>
      </div>
    </ng-template>
    <estimator-fleet-vessel
      [fleetVessels]="myCompanyVessels"
      [collections]="myCompanyCollections"
      (updateFleetVessel)="onUpdateFleetVessel($event)"
      (updateFleetVesselActive)="onUpdateFleetVesselActive($event)"
      (deleteFleetVessel)="onDeleteFleetVessel($event)"
      (positionMap)="onPositionMap($event)"
    >
    </estimator-fleet-vessel>
  </p-tabPanel>
</p-tabView>
