import { DecimalPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { getColorFromVariablesSCSS } from '@estimator/helpers';
import { BreakEvenCargo, BreakEvenData } from '@estimator/models';
import {
  ColDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
  RowClassParams,
  ValueFormatterParams,
} from 'ag-grid-community';

@Component({
  selector: 'estimator-break-even-grid',
  templateUrl: './break-even-grid.component.html',
  styleUrls: ['./break-even-grid.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreakEvenGridComponent {
  @Input()
  set data(data: BreakEvenData[]) {
    this._data = data;
  }
  get data(): BreakEvenData[] {
    return this._data;
  }
  private _data: BreakEvenData[] = [];

  @Input()
  set initialArrayFields(initialArrayFields: string[]) {
    this._initialArrayFields = initialArrayFields;
    if (initialArrayFields?.length) {
      this.columnDefs = this.getColumnDefs();
    }
  }
  get initialArrayFields(): string[] {
    return this._initialArrayFields;
  }
  private _initialArrayFields: string[] = [];
  private _gridApi!: GridApi;
  columnDefs: ColDef[] = [];

  constructor(private readonly decimalPipe: DecimalPipe) {}

  gridOptions: GridOptions = {
    defaultColDef: {
      editable: false,
      resizable: true,
      suppressMenu: true,
      flex: 1,
    },
    rowHeight: 20,
    suppressContextMenu: true,
    domLayout: 'autoHeight',
    getRowStyle: (params: RowClassParams) => {
      if (params.rowIndex >= 0 && params.rowIndex <= 9) {
        return { background: `${getColorFromVariablesSCSS('successRGB').split(')'[0])} 0.1)` };
      }
      if (params.rowIndex >= 11 && params.rowIndex <= 20) {
        return { background: `${getColorFromVariablesSCSS('warningRGB').split(')'[0])} 0.1)` };
      }
      return { background: 'inherit' };
    },
  };

  onGridReady({ api }: GridReadyEvent) {
    this._gridApi = api;
  }

  getColumnDefs() {
    let columnDefs: ColDef[] = [];
    this.initialArrayFields.forEach((item) => {
      columnDefs.push({
        field: item,
        headerName: item,
        valueFormatter: (data: ValueFormatterParams) => {
          const cargoes: BreakEvenCargo[] = data.data.cargoes;
          const price = cargoes.find((cargo) => cargo.name === item)?.price || 0;
          return `${this.decimalPipe.transform(price, '0.0-2')}`;
        },

        width: 100,
      });
    });
    columnDefs = [
      ...columnDefs,
      {
        field: 'result',
        headerName: 'Result',
        valueFormatter: ({ value }: ValueFormatterParams) =>
          `${this.decimalPipe.transform(value || 0, '0.0-2')}`,
        width: 100,
      },
      {
        field: 'ntce',
        headerName: 'NTCE',
        valueFormatter: ({ value }: ValueFormatterParams) =>
          `${this.decimalPipe.transform(value || 0, '0.0-2')}`,
        width: 100,
      },
    ];
    return columnDefs;
  }
}
