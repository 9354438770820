<mat-card class="bordered-card">
  <!--Vessel form #vf-->
  <form class="vessel-form material-form" [formGroup]="vesselFormGroup" id="vf">
    <div class="flex-container-row gap-10">
      <div class="flex-container-column gap-20">
        <div class="flex-container-row gap-2">
          <mat-form-field *ngIf="!isVesselEditor" appearance="outline" class="large-input">
            <mat-label (click)="clickVesselLabel()" class="cursor-pointer"
              >Vessel, IMO, MMSI</mat-label
            >
            <input
              matInput
              type="text"
              name="vessel"
              placeholder="Vessel name"
              [formControl]="vesselSelectControl"
              [matAutocomplete]="vesselAutocomplete"
              id="vf-i-1"
              required
              estimatorSelectValue
            />
            <mat-icon *ngIf="isLoading" matSuffix>
              <mat-spinner [color]="MaterialThemeColor.Primary" [diameter]="20"></mat-spinner>
            </mat-icon>
            <mat-error>Error: Vessel name is required</mat-error>
            <mat-autocomplete #vesselAutocomplete="matAutocomplete" class="vessel-autocomplete">
              <mat-option
                *ngFor="let vessel of vessels"
                [value]="vessel"
                (onSelectionChange)="onVesselSelect($event)"
                [ngClass]="{ 'clear-vessel': !vessel.is_raw }"
              >
                <div class="option-wrapper">
                  <div class="option-row">
                    <mat-icon [color]="MaterialThemeColor.Primary" class="vessel-icon"
                      >directions_boat</mat-icon
                    >
                    <div class="vessel-name">
                      <b>{{ vessel?.name }}</b>
                    </div>
                  </div>
                  <p>IMO: {{ vessel?.imo }}</p>
                  <p>DWT: {{ vessel?.dead_weight }}</p>
                </div>
              </mat-option>
              <ng-container *ngIf="vessels.length < 1 && !isLoading">
                <mat-option [disabled]="true">
                  <p>No vessels found</p>
                </mat-option>
              </ng-container>
            </mat-autocomplete>
          </mat-form-field>
          <mat-form-field *ngIf="isVesselEditor" appearance="outline">
            <mat-label>Vessel</mat-label>
            <input
              type="text"
              name="vessel"
              placeholder="Vessel name"
              formControlName="name"
              id="vf-i-1_1"
              matInput
              estimatorSelectValue
              required
            />
            <mat-error>Error: Vessel name is required</mat-error>
          </mat-form-field>
          <mat-form-field *ngIf="isVesselEditor" appearance="outline">
            <mat-label>IMO</mat-label>
            <input
              type="number"
              name="imo"
              placeholder="IMO"
              formControlName="imo"
              id="vf-i-1_2"
              matInput
              estimatorSelectValue
              required
            />
            <mat-error>Error: IMO is required</mat-error>
          </mat-form-field>
          <mat-form-field *ngIf="isVesselEditor" appearance="outline">
            <mat-label>MMSI</mat-label>
            <input
              type="number"
              name="mmsi"
              placeholder="MMSI"
              formControlName="mmsi"
              id="vf-i-1_3"
              matInput
              estimatorSelectValue
              required
            />
            <mat-error>Error: MMSI is required</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Ballast</mat-label>
            <input
              type="number"
              name="ballast"
              placeholder="Ballast"
              formControlName="ballast_speed"
              id="vf-i-2"
              matInput
              estimatorSelectValue
            />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Laden</mat-label>
            <input
              type="number"
              name="laden"
              placeholder="Laden"
              formControlName="laden_speed"
              id="vf-i-3"
              matInput
              estimatorSelectValue
            />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Low</mat-label>
            <input
              type="number"
              name="low"
              placeholder="Low"
              formControlName="low_speed"
              id="vf-i-4"
              matInput
              estimatorSelectValue
            />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Flag</mat-label>
            <input
              type="text"
              name="flag"
              placeholder="Flag"
              formControlName="flag"
              id="vf-i-5"
              matInput
              estimatorSelectValue
            />
          </mat-form-field>
          <mat-form-field appearance="outline" class="medium-input">
            <mat-label>Type</mat-label>
            <input
              type="text"
              name="type"
              placeholder="Type"
              formControlName="type"
              id="vf-i-6"
              matInput
              estimatorSelectValue
            />
          </mat-form-field>
        </div>
        <div class="flex-container-row gap-2">
          <mat-form-field appearance="outline">
            <mat-label>DWT</mat-label>
            <input
              type="number"
              name="dwt"
              placeholder="Dead weight"
              formControlName="dead_weight"
              id="vf-i-7"
              matInput
              estimatorSelectValue
            />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>GT</mat-label>
            <input
              type="number"
              name="gt"
              placeholder="Gross tonage"
              formControlName="gross_tonnage"
              id="vf-i-8"
              matInput
              estimatorSelectValue
            />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Beam</mat-label>
            <input
              type="number"
              name="beam"
              placeholder="Beam"
              formControlName="beam"
              id="vf-i-9"
              matInput
              estimatorSelectValue
            />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>LOA</mat-label>
            <input
              type="number"
              name="loa"
              placeholder="LOA"
              formControlName="length"
              id="vf-i-10"
              estimatorSelectValue
              matInput
            />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>TPC</mat-label>
            <input
              type="number"
              name="tpc"
              placeholder="TPC"
              formControlName="tpc"
              id="vf-i-11"
              estimatorSelectValue
              matInput
            />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Built</mat-label>
            <input
              type="number"
              name="built"
              placeholder="Build year"
              formControlName="build_year"
              id="vf-i-12"
              estimatorSelectValue
              matInput
            />
          </mat-form-field>
          <mat-form-field appearance="outline" class="medium-input">
            <mat-label>Country built</mat-label>
            <input
              type="text"
              name="country_built"
              placeholder="Country built"
              formControlName="place_of_built"
              id="vf-i-13"
              estimatorSelectValue
              matInput
            />
          </mat-form-field>
        </div>
        <div *ngIf="isVesselEditor" class="flex-container-row">
          <button
            mat-flat-button
            [color]="MaterialThemeColor.Accent"
            type="button"
            [disabled]="vesselFormGroup.invalid || isLoading || isNotChanged"
            (click)="onSaveVessel()"
            id="vf-b-1"
          >
            <mat-icon *ngIf="isLoading">
              <mat-spinner [color]="MaterialThemeColor.Primary" [diameter]="20"></mat-spinner>
            </mat-icon>
            Save vessel
          </button>
        </div>
      </div>
      <div class="w-100 flex-container-column fuels-grid-wrapper ag-grid-wrapper-border">
        <ag-grid-angular
          #vesselFuelsAgGrid
          [rowData]="consumptions"
          [gridOptions]="gridOptions"
          (gridReady)="onGridReady($event)"
          class="fuels-grid wh-100 ag-theme-material"
          id="vf-gr-1"
        ></ag-grid-angular>
      </div>
    </div>
  </form>
</mat-card>
