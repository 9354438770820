import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { ConfirmationCodeFormComponent } from './confirmation-code-form.component';

@NgModule({
  declarations: [ConfirmationCodeFormComponent],
  imports: [CommonModule, ReactiveFormsModule, InputTextModule, ButtonModule],
  exports: [ConfirmationCodeFormComponent],
})
export class ConfirmationCodeFormModule {}
