import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'estimator-phone-number-input',
  templateUrl: './phone-number-input.component.html',
  styleUrls: ['./phone-number-input.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhoneNumberInputComponent implements OnInit, OnDestroy {
  @Input()
  set countryCodes(codes: string[]) {
    this._countryCodes = codes;
    this.countryCodesData = codes;
  }
  get countryCodes(): string[] {
    return this.countryCodesData;
  }
  @Input() control: FormControl = new FormControl('');
  @Input() required = false;
  countryCodesData: string[] = ['7', '8', '9'];
  private _countryCodes: string[] = ['7', '8', '9'];
  private _onDestroy$ = new Subject<void>();

  phoneNumberFormGroup = new FormGroup({
    number: new FormControl('', this.required ? [Validators.required] : null),
  });

  ngOnInit() {
    this.phoneNumberFormGroup.valueChanges.pipe(takeUntil(this._onDestroy$)).subscribe((value) => {
      this.control.setValue(`${value.number}`);
      this.countryCodesData = this._countryCodes.filter((code) =>
        code.toLowerCase().includes(value.number?.toLowerCase() || '')
      );
    });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
