import { Cargo, CompanySearchRequest } from '@estimator/models';

export class CreateCompany {
  static readonly type = '[COMPANY] Create company';
  constructor(public name: string, public updatedCargo?: Cargo, public actualCargoes?: Cargo[]) {}
}
export class FindCompany {
  static readonly type = '[COMPANY] Find company';
  constructor(public params: CompanySearchRequest) {}
}

export class GetUsersByCompanyId {
  static readonly type = '[COMPANY] Get users by company id';
  constructor(public companyId: number) {}
}
