import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { getIconTooltip, getIconZone } from '@estimator/helpers';
import { PrimeNgIcons, ShiplistSelectedZoneOrPort } from '@estimator/models';
import { CheckboxModule } from 'primeng/checkbox';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'estimator-selected-values-list',
  imports: [CommonModule, FormsModule, CheckboxModule, TooltipModule],
  standalone: true,
  template: `
    <ng-content></ng-content>
    <div *ngIf="values?.length" class="container overflow-auto pl-2 pr-2 pb-2">
      <div *ngFor="let option of values" class="pt-2 flex gap-2">
        <p-checkbox
          [(ngModel)]="option.checked"
          [binary]="true"
          [inputId]="'checkbox' + option.id + option.is_zone"
          (onChange)="onChange(option)"
        />
        <i *ngIf="!option.is_zone" [class]="PrimeNgIcons.Anchor"></i>
        <span
          class="material-icons cursor-pointer"
          pTooltip="{{ getIconTooltip(option) }}"
          tooltipPosition="top"
          >{{ getIconZone(option) }}</span
        >
        <label class="cursor-pointer" [for]="'checkbox' + option.id + option.is_zone"
          >{{ option.name }}
        </label>
      </div>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
        .container {
          border: 1px solid var(--input-border);
          border-radius: 6px;
          flex: 15;
        }
      }
    `,
  ],
})
export class SelectedValuesListComponent {
  @Input() values: ShiplistSelectedZoneOrPort[] = [];
  @Output() removeValue = new EventEmitter<ShiplistSelectedZoneOrPort>();
  readonly PrimeNgIcons = PrimeNgIcons;
  getIconZone = getIconZone;
  getIconTooltip = getIconTooltip;

  onChange(option: ShiplistSelectedZoneOrPort): void {
    this.removeValue.emit(option);
  }
}
