export const vmsRoutes = {
  LAYTIME: 'laytime',
  CREATE: 'create',
  HANDOVER: 'handover',
  REPORT: 'report',
  CASHFLOW: 'cashflow',
  HSOA: 'hsoa',
  HSOA_OUT: 'hsoa-out',
  VSOA: 'vsoa',
  NEW: 'new',
};
