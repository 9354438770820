import { Injectable } from '@angular/core';
import { BaseModel, CargoRules } from '@estimator/models';
import { CargoRulesService } from '@estimator/services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { catchError, finalize, tap, throwError } from 'rxjs';
import { GetAllCargoRules } from './cargo-rules.actions';

export interface CargoRulesStateModel {
  cargoRules: CargoRules[];
  loading: boolean;
}

@State<CargoRulesStateModel>({
  name: 'cargoRules',
  defaults: {
    cargoRules: [],
    loading: false,
  },
})
@Injectable()
export class CargoRulesState {
  @Selector()
  static getIsLoading({ loading }: CargoRulesStateModel): boolean {
    return loading;
  }
  @Selector()
  static getCargoRules({ cargoRules }: CargoRulesStateModel): BaseModel[] {
    return cargoRules;
  }

  constructor(private readonly cargoRulesService: CargoRulesService) {}

  @Action(GetAllCargoRules)
  getAllCargoRules({ patchState }: StateContext<CargoRulesStateModel>) {
    patchState({ loading: true });
    return this.cargoRulesService.getAll().pipe(
      tap((cargoRules) => {
        patchState({ cargoRules });
      }),
      catchError((err) => {
        patchState({ loading: false });
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }
}
