<!-- welcome dialog -->
<bdc-walk-dialog #dialogWelcome name="dialogWelcome" id="walk_through_dialog">
  <h1 class="dialog-welcome">Welcome to our estimator!</h1>
  <mat-dialog-content>
    <div class="dialog-welcome-button display-flex">
      <button
        (click)="turnOffTutotial(); dialogWelcome.close()"
        mat-raised-button
        [color]="MaterialThemeColor.Primary"
        id="walk_through_dialog-skip_button"
      >
        Skip
      </button>
      <button (click)="dialogWelcome.close()" mat-raised-button [color]="MaterialThemeColor.Accent">
        Let's start
      </button>
    </div>
  </mat-dialog-content>
</bdc-walk-dialog>
<!-- Порядок
1 - vessel info
2 - vessel fuels
3 - deal input
4 - event list
5 - cargoes list
6 - fast bunkering list
7 - misc list
8 - hire input
9 - result
10 - cargo calculator -->
<!-- vessel info -->
<div
  class="absolute"
  [ngStyle]="{
    top: this.positionVesselForm?.top + px,
    left: this.positionVesselForm?.left + px
  }"
  [bdcWalkTriggerFor]="vessel1"
></div>
<bdc-walk-popup
  #vessel1
  name="vessel1"
  header="Vessel"
  [yPosition]="'above'"
  [mustCompleted]="{ dialogWelcome: true }"
  [showButton]="true"
  [buttonText]="OK_TEXT"
  [showCloseButton]="false"
  [arrow]="false"
>
  <div>- You can find a vessel by NAME, IMO, MMSI</div>
  <div>- Set ballast, laden and low (eco 1/2/3) speed</div>
  <div>
    - Set consumption rates for ME and AE (main and auxiliary engines) voyage and port operations
  </div>
  <div>- Set bunker price</div>
</bdc-walk-popup>
<!-- vessel fuels -->
<div
  class="absolute"
  [ngStyle]="{
    top: this.positionVesselFuelsGrid?.top + px,
    left: this.positionVesselFuelsGrid?.left + px
  }"
  [bdcWalkTriggerFor]="fuel2"
></div>
<bdc-walk-popup
  #fuel2
  name="fuel2"
  header="Fuels"
  [mustCompleted]="{ vessel1: true }"
  [showButton]="true"
  [buttonText]="OK_TEXT"
  [showCloseButton]="false"
  [arrow]="false"
>
  You need to set vessel consumption (mt/day) for different speed and work. <br />
  Ballast - vessel without cargo <br />
  Laden - vessel with cargo <br />
  Low - for economy speed <br />
  Work - at loading or discharge operations <br />
  Idle - for stop, no operations
  <!-- <img src="assets/vessel-fuels.jpg" alt="vessel-fuels" class="vessel-fuels" /> -->
</bdc-walk-popup>
<!-- deal input -->
<div
  class="absolute"
  [ngStyle]="{
    top: this.positionDealInput?.top + px,
    left: this.positionDealInput?.left + px
  }"
  [bdcWalkTriggerFor]="deal3"
></div>
<bdc-walk-popup
  #deal3
  name="deal3"
  header="Deal name"
  [yPosition]="'above'"
  [mustCompleted]="{ fuel2: true }"
  [showButton]="true"
  [buttonText]="OK_TEXT"
  [showCloseButton]="false"
  [arrow]="false"
>
  Name (Draft) your calculation
</bdc-walk-popup>
<!-- event list -->
<!-- *ngIf="posEventList" -->
<div
  class="absolute"
  [ngStyle]="{
    top: this.positionEventList?.top + px,
    left: this.positionEventList?.left + px
  }"
  [bdcWalkTriggerFor]="eventList4"
></div>
<bdc-walk-popup
  #eventList4
  name="eventList4"
  header="Event list"
  [yPosition]="'above'"
  [mustCompleted]="{ deal3: true }"
  [showButton]="true"
  [buttonText]="OK_TEXT"
  [showCloseButton]="false"
  [arrow]="false"
>
  <div class="display-flex">
    <div>
      <span>You can add route ports and operations in a port</span>
      <div>
        <i [class]="PrimeNgIcons.BARS" class="padding-top-5"> </i>
        <span>&nbsp; You can drag port to change sequence</span>
      </div>
      <div>
        <i [class]="PrimeNgIcons.PLUS" class="padding-top-5"> </i>
        <span>&nbsp; You can add port</span>
      </div>
      <div>
        <i [class]="PrimeNgIcons.SHARE_ALT" class="padding-top-5"> </i>
        <span>&nbsp; Here you can see the route and edit Canals</span>
      </div>
    </div>
    <!-- <img src="assets/event-list.jpg" alt="event-list" class="event-list" /> -->
  </div>
</bdc-walk-popup>
<!-- cargoes list -->
<!-- *ngIf="posCargoList" -->
<div
  class="absolute"
  [ngStyle]="{
    top: this.positionCargoList?.top + px,
    left: this.positionCargoList?.left + px
  }"
  [bdcWalkTriggerFor]="cargo5"
></div>
<bdc-walk-popup
  #cargo5
  name="cargo5"
  header="Cargoes"
  [mustCompleted]="{ eventList4: true }"
  [showButton]="true"
  [buttonText]="OK_TEXT"
  [showCloseButton]="false"
  [arrow]="false"
>
  You can add and edit cargoes, price for cargo and voyage commission
</bdc-walk-popup>
<!-- fast bunkering list -->
<div
  *ngIf="posRobList"
  class="absolute"
  [ngStyle]="{
    top: this.positionRobList?.top + px,
    left: this.positionRobList?.left + px
  }"
  [bdcWalkTriggerFor]="fastBunkeringList6"
></div>
<bdc-walk-popup
  #fastBunkeringList6
  name="fastBunkeringList6"
  header="Here you can add RoB fuels for bunker planning"
  [yPosition]="'above'"
  [mustCompleted]="{ cargo5: true }"
  [showButton]="true"
  [buttonText]="OK_TEXT"
  [showCloseButton]="false"
  [arrow]="false"
>
  You can set RoB for fuel grades
</bdc-walk-popup>
<!-- misc list -->
<div
  *ngIf="posMiscList"
  class="absolute"
  [ngStyle]="{
    top: this.positionMiscList?.top + px,
    left: this.positionMiscList?.left + px
  }"
  [bdcWalkTriggerFor]="miscList7"
></div>
<bdc-walk-popup
  #miscList7
  name="miscList7"
  header="Miscellaneous list"
  [yPosition]="'above'"
  [mustCompleted]="{ fastBunkeringList6: true }"
  [showButton]="true"
  [buttonText]="OK_TEXT"
  [showCloseButton]="false"
  [arrow]="false"
>
  You can add miscellaneous/sundry expenses
</bdc-walk-popup>
<!-- hire input -->
<div
  *ngIf="posHireInput"
  class="absolute"
  [ngStyle]="{
    top: this.positionHireInput?.top + px,
    left: this.positionHireInput?.left + px
  }"
  [bdcWalkTriggerFor]="hireInput8"
></div>
<bdc-walk-popup
  #hireInput8
  name="hireInput8"
  header="Hire rate and commission"
  [mustCompleted]="{ miscList7: true }"
  [showButton]="true"
  [buttonText]="OK_TEXT"
  [showCloseButton]="false"
  [arrow]="false"
>
  You need to set vessel daily price with commissions for correct calculation
</bdc-walk-popup>
<!-- result -->
<div
  *ngIf="posResult"
  class="absolute"
  [ngStyle]="{
    top: this.positionResult?.top + px,
    left: this.positionResult?.left + px
  }"
  [bdcWalkTriggerFor]="result9"
></div>
<bdc-walk-popup
  #result9
  name="result9"
  header="Break even"
  [mustCompleted]="{ hireInput8: true }"
  [showButton]="true"
  [buttonText]="OK_TEXT"
  [showCloseButton]="false"
  [arrow]="false"
>
  You can see break even items by clicking here
</bdc-walk-popup>
<!-- cargo calculator -->
<div
  *ngIf="posCargoCalculator"
  class="absolute"
  [ngStyle]="{
    top: this.positionCargoCalculator?.top + px,
    left: this.positionCargoCalculator?.left + px
  }"
  [bdcWalkTriggerFor]="cargoCalculator10"
></div>
<bdc-walk-popup
  #cargoCalculator10
  name="cargoCalculator10"
  header="Cargo calculator"
  [mustCompleted]="{ result9: true }"
  [showButton]="true"
  [buttonText]="OK_TEXT"
  [showCloseButton]="false"
  [arrow]="false"
>
  Open to see the intake calculations
</bdc-walk-popup>
