import { CommonModule, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipeModule } from '@estimator/helpers';
import { AgGridModule } from 'ag-grid-angular';
import { EventListComponent } from './event-list.component';

@NgModule({
  declarations: [EventListComponent],
  exports: [EventListComponent],
  imports: [CommonModule, AgGridModule, PipeModule],
  providers: [DecimalPipe],
})
export class EventListModule {}
