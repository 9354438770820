import { DatePipe, DecimalPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  DurationPipe,
  getDatePipeTimeZoneFormat,
  getEventTimezoneOffset,
  getUserOrSystemDuration,
  isCanalEvent,
  isCargoEvent,
  isDealEventTypeCellChanged,
  isPrimaryEvent,
  isSecondaryEvent,
} from '@estimator/helpers';
import {
  BaseModel,
  ButtonParams,
  CARGO_EVENT_ROW_HEIGHT,
  Cargo,
  CargoRules,
  Currency,
  DEFAULT_FIAT_MULTIPLIER,
  DealEvent,
  DealEventType,
  Fuel,
  FuelConsumption,
  GeoZone,
  MapFeatureProperties,
  NOT_APPLICABLE,
  OPACITY_5,
  Port,
  Preset,
  PriceDto,
  PrimeNgColors,
  STANDART_TIME_FORMAT,
  STOP_EVENT_ROW_HEIGHT,
  UserGuideType,
  UserTimeSettings,
  WorkType,
  ZERO_STRING,
} from '@estimator/models';
import {
  CellClassParams,
  CellClickedEvent,
  ColDef,
  ColumnApi,
  ColumnMovedEvent,
  ColumnResizedEvent,
  ColumnState,
  EditableCallbackParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ICellEditorParams,
  ICellRendererParams,
  ITooltipParams,
  RowDragEvent,
  SideBarDef,
  ValueFormatterParams,
} from 'ag-grid-community';
import { cloneDeep, isEqual } from 'lodash';
import moment from 'moment';
import { AgGridAutocompleteComponent } from '../ag-grid-shared/ag-grid-autocomplete-editor.component';
import { AgGridBasicTooltipComponent } from '../ag-grid-shared/ag-grid-basic-tooltip.component';
import { AgGridCargoDetailsComponent } from '../ag-grid-shared/ag-grid-cargo-details.component';
import { AgGridCargoTypeComponent } from '../ag-grid-shared/ag-grid-cargo-type-editor.component';
import { AgGridCheckboxCellComponent } from '../ag-grid-shared/ag-grid-checkbox-editor.component';
import { AgGridDateTimeEditorComponent } from '../ag-grid-shared/ag-grid-datetime-editor.component';
import { AgGridEventDetailsComponent } from '../ag-grid-shared/ag-grid-event-details.component';
import { AgGridEventGroupRendererComponent } from '../ag-grid-shared/ag-grid-event-group-cell-renderer.component';
import { AgGridEventTooltipComponent } from '../ag-grid-shared/ag-grid-event-tooltip.component';
import { AgGridHeaderComponent } from '../ag-grid-shared/ag-grid-header.component';
import { AgGridNumericCellEditorComponent } from '../ag-grid-shared/ag-grid-numeric-editor.component';
import { AgGridPortRendererComponent } from '../ag-grid-shared/ag-grid-port-renderer.component';
import { AgGridPortAutocompleteComponent } from '../ag-grid-shared/ag-grid-ports-autocomplete.component';
import { AgGridPriceEditorComponent } from '../ag-grid-shared/ag-grid-price-with-currency-editor.component';
import { AgGridSelectCellEditorComponent } from '../ag-grid-shared/ag-grid-select-editor.component';
import { AgGridTimeDurationEditorComponent } from '../ag-grid-shared/ag-grid-time-duration-editor.component';
import { AgGridButtonGroupComponent } from '../ag-grid-shared/button-group.component';

@Component({
  selector: 'estimator-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventListComponent {
  @Input()
  set events(data: DealEvent[]) {
    if (data) {
      this._events = cloneDeep(data);
      this.displayedEvents = this._events.filter(isPrimaryEvent);
      this._inputEvents = cloneDeep(this.displayedEvents);
      if (this._gridApi) {
        this._gridApi.setRowData(this._events);
        this.setExpandedRows();
        this._gridApi.refreshClientSideRowModel('aggregate');
        this._gridApi.redrawRows();
      }
      this.ballastTime = 0;
      this.ladenTime = 0;
      this._events.forEach((event) => {
        if (event.meta?.is_laden) {
          this.ladenTime += getUserOrSystemDuration(event.meta?.sailing_minutes) || 0;
        } else {
          this.ballastTime += getUserOrSystemDuration(event.meta?.sailing_minutes) || 0;
        }
      });
    }
  }
  get events(): DealEvent[] {
    return this._events;
  }
  @Input()
  set isLoading(bool: boolean) {
    this._isLoading = bool;
    if (this._gridApi && bool) {
      this._gridApi.showLoadingOverlay();
    } else if (this._gridApi && !bool) {
      this._gridApi.hideOverlay();
    }
  }
  get isLoading(): boolean {
    return this._isLoading;
  }
  @Input()
  set ports(data: Port[]) {
    this._ports = data;
    const editors = this._gridApi?.getCellEditorInstances();
    if (editors?.length) {
      const autoComplete = editors[0];
      if (autoComplete instanceof AgGridPortAutocompleteComponent) {
        autoComplete.ports = this._ports;
      }
    }
  }
  get ports(): Port[] {
    return this._ports;
  }
  @Input()
  set isSearchPortLoading(bool: boolean) {
    this._isSearchPortLoading = bool;
    const editors = this._gridApi?.getCellEditorInstances();
    if (editors?.length) {
      const autoComplete = editors[0];
      if (autoComplete instanceof AgGridAutocompleteComponent) {
        autoComplete.isLoading = this._isSearchPortLoading;
      }
    }
  }
  get isSearchPortLoading(): boolean {
    return this._isSearchPortLoading;
  }
  @Input()
  set cargoes(data: Cargo[]) {
    this._cargoes = data;
    if (this._gridApi) {
      this._gridApi.redrawRows();
    }
  }
  get cargoes(): Cargo[] {
    return this._cargoes;
  }
  @Input()
  set isAdvancedMode(value: boolean) {
    this._isAdvancedMode = value;
    this._gridApi?.setColumnDefs(this.advancedColDefs);
    this.setExpandedRows();
  }
  @Input()
  set timeSettings(settings: UserTimeSettings) {
    this._timeSettings = settings;
    this._gridApi?.refreshCells();
  }
  get timeSettings(): UserTimeSettings {
    return this._timeSettings;
  }
  @Input() cargoRules: CargoRules[] = [];
  @Input() fuels: Fuel[] = [];
  @Input() currencies: Currency[] = [];
  @Input() canals: GeoZone[] = [];
  @Input() tableState: ColumnState[] = [];
  @Input() cargoTableState: ColumnState[] = [];
  @Input() presets: Preset[] = [];
  @Input() consumptions: FuelConsumption[] = [];
  @Output() searchPort = new EventEmitter<string>();
  @Output() updateEvent = new EventEmitter<DealEvent>();
  @Output() deleteEvent = new EventEmitter<DealEvent>();
  @Output() addCargoEvent = new EventEmitter<{ stopEvent: DealEvent; type?: DealEventType }>();
  @Output() openRouteMap = new EventEmitter<DealEvent>();
  @Output() addCargo = new EventEmitter<DealEvent>();
  @Output() addStopEvent = new EventEmitter<void>();
  @Output() replaceStopEvent = new EventEmitter<DealEvent[]>();
  @Output() showTooltip = new EventEmitter<string>();
  @Output() saveTableState = new EventEmitter<ColumnState[]>();
  @Output() saveCargoTableState = new EventEmitter<ColumnState[]>();
  @Output() resetTableState = new EventEmitter<void>();
  displayedEvents: DealEvent[] = [];
  dragableIndex: number | null = null;
  ballastTime = 0;
  ladenTime = 0;
  sideBar: SideBarDef = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        minWidth: 225,
        maxWidth: 225,
        width: 225,
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivotMode: true,
        },
      },
    ],
    position: 'right',
    defaultToolPanel: 'filters',
  };
  gridHeaderActionButtons: ButtonParams[] = [
    {
      title: 'Add event',
      tooltip: 'Add event',
      icon: 'add',
      color: PrimeNgColors.White,
      click: () => this.onAddEvent(),
    },
    {
      title: 'Reset columns',
      tooltip: 'Reset columns',
      icon: 'restart_alt',
      color: PrimeNgColors.White,
      click: () => this.onResetTableState(),
    },
  ];
  columnDefs: ColDef<DealEvent>[] = [
    {
      headerName: 'Actions',
      headerComponent: 'buttonRenderer',
      headerComponentParams: (params: any) => {
        return {
          ...params,
          buttons: this.gridHeaderActionButtons,
        };
      },
      editable: false,
      cellRendererSelector: (params: ICellRendererParams) => {
        if (params.node.group) {
          return { component: 'agGroupCellRenderer' };
        }
        return {
          component: 'buttonRenderer',
        };
      },
      cellRendererParams: (params: ICellRendererParams<DealEvent>) => {
        const isExpanded = params.node.expanded;
        const id = params.value;
        if (id) {
          const dealEvent = this.events.find((event) => event.port_id == id);
          if (dealEvent) {
            params.value = dealEvent?.port?.name || NOT_APPLICABLE;
          }
        }
        const buttons = [];
        const stopEvent = params.data;
        if (stopEvent?.type === DealEventType.EventTypeStop) {
          const stopButtons = [
            {
              title: 'Delete event',
              tooltip: 'Delete event',
              icon: 'delete_forever',
              click: () => this.onDeleteEvent(params.data),
            },
          ];
          if (this._isAdvancedMode) {
            stopButtons.push({
              title: 'Expand',
              tooltip: 'Expand',
              icon: isExpanded ? 'expand_less' : 'expand_more',
              click: () => this.openDetails(params),
            });
          }
          buttons.push(...stopButtons);
        }
        if (stopEvent?.meta?.route_geojson) {
          buttons.push({
            title: 'View route',
            tooltip: 'View route',
            icon: 'route',
            click: () => this.onOpenRouteMap(stopEvent),
          });
        }
        return {
          ...params,
          buttons,
        };
      },
      cellClass: 'center-vertical-aligned-cell',
      minWidth: 70,
      width: 95,
      rowDrag: (params) => params.data?.type === DealEventType.EventTypeStop,
    },
    {
      field: 'port',
      headerComponent: 'header',
      headerComponentParams: () => {
        return {
          title: 'Port',
          click: () => this.headerPortClick(),
        };
      },
      flex: 1,
      editable: (params) => params.data?.type === DealEventType.EventTypeStop,
      cellEditor: 'portEditor',
      cellEditorParams: (params: any) => {
        return {
          ...params,
          ports: this.ports,
          filter: this.onSearchPort.bind(this),
          closeEditor: this.closeEditor.bind(this),
        };
      },
      cellRendererSelector: (params) => {
        const event: DealEvent | undefined = params.data;
        if (event?.type === DealEventType.EventTypeStop) {
          return { component: 'portRenderer' };
        }
        return {};
      },
      valueFormatter: (params) => {
        const event: DealEvent | undefined = params.data;
        return event?.meta?.current_canal_name || '';
      },
      minWidth: 130,
      width: 130,
    },
    {
      field: 'route',
      headerName: 'Operations, route',
      cellRendererSelector: (params) => {
        if (params.data?.type === DealEventType.EventTypeStop) {
          return { component: 'eventDetailsRenderer' };
        }
        return {};
      },
      cellRendererParams: (params: any) => {
        const dealEvent: DealEvent = params.data;
        const canalsOnRoute = this.getCanals(params.data);
        return {
          ...params,
          total_bunker: dealEvent.meta?.total_bunker,
          total_discharge: dealEvent.meta?.total_discharge,
          total_load: dealEvent.meta?.total_load,
          canalsOnRoute,
        };
      },
      valueFormatter: () => 'Canal enter',
      editable: false,
      onCellClicked: (params) => {
        this.openDetails(params);
      },
      tooltipField: 'type',
      toolPanelClass: 'event-tooltip',
      tooltipComponent: 'eventTooltip',
      tooltipComponentParams: (params: ITooltipParams<DealEvent>) => {
        if (params.data?.type === DealEventType.EventTypeStop) {
          const cargoEvents = params.data.inner_events;
          const canalsOnRoute = this.getCanals(params.data);
          return {
            ...params,
            cargoEvents,
            canalsOnRoute,
            terms: this.cargoRules,
            fuelTypes: this.fuels,
          };
        }
        return params;
      },
      flex: 2,
      width: 560,
    },
    {
      field: 'meta.use_eca',
      headerName: 'ECA',
      cellRendererSelector: (params) => {
        const dealEvent = params.data;
        if (dealEvent && !isCanalEvent(dealEvent)) {
          return { component: 'checkboxEditor' };
        }
        return {};
      },
      valueFormatter: (params) => {
        const dealEvent = params.data;
        if (dealEvent && !isCanalEvent(dealEvent)) {
          return !params.value as unknown as string;
        }
        return '';
      },
      editable: false,
      onCellValueChanged: (params) => {
        const editableEvent = params.data;
        this.updateEvent.emit(editableEvent);
      },
      flex: 1,
      width: 50,
    },
    {
      field: 'meta.weather_factor',
      headerName: 'WF, %',
      editable: (params) => {
        const dealEvent = params.data;
        return !!(dealEvent && !isCanalEvent(dealEvent));
      },
      cellEditor: 'numericEditor',
      flex: 1,
      width: 50,
      valueFormatter: (params) => {
        const dealEvent = params.data;
        if (dealEvent && !isCanalEvent(dealEvent)) {
          return params.value;
        }
        return '';
      },
    },
    {
      field: 'meta.preset',
      headerName: 'Speed',
      editable: (params) => {
        const dealEvent = params.data;
        return !!(dealEvent && !isCanalEvent(dealEvent));
      },
      cellEditorSelector: (params) => {
        const dealEvent = params.data;
        if (dealEvent && !isCanalEvent(dealEvent)) {
          return { component: 'selectEditor' };
        }
        return {};
      },
      cellEditorParams: (params: any) => {
        return {
          ...params,
          visibleKeys: ['name'],
          dataArray: this.presets,
          closeEditor: this.closeEditor.bind(this),
        };
      },
      valueFormatter: (params) => {
        const dealEvent = params.data;
        if (dealEvent && !isCanalEvent(dealEvent)) {
          const preset = params.data?.meta?.preset;
          if (preset?.name) {
            return preset.name;
          }
          return NOT_APPLICABLE;
        }
        return '';
      },
      flex: 1,
      width: 50,
    },
    {
      field: 'meta.distance',
      headerName: 'Distance, nm',
      cellEditor: 'numericEditor',
      editable: false,
      cellClass: OPACITY_5,
      valueFormatter: (params) => {
        return this.decimalPipe.transform(params.value || 0, '0.0-2') || '0';
      },
      flex: 1,
      width: 70,
    },
    {
      field: 'meta.disbursement',
      headerName: 'DA',
      cellEditor: 'priceEditor',
      cellEditorParams: (params: any) => {
        const event: DealEvent = params.data;
        params.value = {
          price: event.meta?.disbursement,
          currency: event.meta?.disbursement_currency,
        };
        return {
          ...params,
          currencies: this.currencies,
          placeholder: 'Currency',
          emptyDataArray: 'No currency found',
        };
      },
      valueFormatter: (params: ValueFormatterParams<DealEvent>) => {
        if (params.data) {
          const event: DealEvent = params.data;
          if (event.meta?.disbursement && typeof event.meta.disbursement === 'number') {
            return `${
              (event.meta.disbursement || 0) /
              (event.meta.disbursement_currency?.fiat_multiplier || DEFAULT_FIAT_MULTIPLIER)
            } ${event.meta?.disbursement_currency?.currency_code}`;
          }
        }
        return NOT_APPLICABLE;
      },
      onCellValueChanged: (params) => {
        const event: DealEvent = params.data;
        if (params.newValue) {
          const priceDto: PriceDto = params.newValue;
          if (!event.meta) {
            event.meta = {};
          }
          event.meta.disbursement = priceDto.price;
          if (priceDto.currency) {
            event.meta.disbursement_currency = priceDto.currency;
          }
          this._gridApi?.refreshCells();
        }
      },
      width: 90,
    },
    {
      field: 'meta.sailing_minutes',
      headerName: 'Sailing (ECA)',
      editable: false,
      cellClass: OPACITY_5,
      valueFormatter: (params) => {
        const event = params.data;
        if (event?.meta?.sailing_minutes) {
          return `${this.durationPipe.transform(
            getUserOrSystemDuration(event.meta?.sailing_minutes) || 0,
            'd',
            2
          )} (${this.durationPipe.transform(
            getUserOrSystemDuration(event.meta?.sailing_eca_minutes) || 0,
            'd',
            2
          )})`;
        }
        return NOT_APPLICABLE;
      },
      flex: 1,
      width: 125,
    },
    {
      field: 'start_time',
      headerName: 'Arrival',
      cellEditor: 'dateEditor',
      cellEditorParams: (params: ICellEditorParams<DealEvent>) => {
        const dealEvent = params.data;
        return {
          ...params,
          timeSettings: this.timeSettings,
          timeZoneOffset: getEventTimezoneOffset(dealEvent),
        };
      },
      valueFormatter: (params) => {
        if (params.value) {
          const dealEvent = params.data;
          return this.dateCellFormatter(params.value, getEventTimezoneOffset(dealEvent));
        }
        return '';
      },
      flex: 1,
      editable: (params) => {
        return params.node.rowIndex === 0;
      },
      cellClass: (params) => {
        return params.node.rowIndex === 0 ? '' : OPACITY_5;
      },
      onCellValueChanged: (event) => {
        if (event.newValue && event.newValue !== event.oldValue) {
          this.updateEvent.emit(event.data);
        }
      },
      width: 110,
      hide: true,
    },
    {
      field: 'meta.port_time',
      headerName: 'Time in port (point)',
      flex: 1,
      editable: (params) => {
        const dealEvent: DealEvent | undefined = params.data;
        if (dealEvent) {
          return isCanalEvent(dealEvent);
        }
        return false;
      },
      cellClass: (params) => {
        const dealEvent: DealEvent | undefined = params.data;
        return dealEvent && isCanalEvent(dealEvent) ? '' : OPACITY_5;
      },
      cellEditor: 'durationEditor',
      cellEditorParams: { format: 'd' },
      valueFormatter: (params) => {
        if (params.data) {
          const event: DealEvent = params.data;
          return this.durationPipe.transform(
            getUserOrSystemDuration(event.meta?.port_time) || 0,
            'd',
            2
          );
        }
        return '0 d';
      },
      width: 65,
    },
    {
      field: 'end_time',
      headerName: 'Departure',
      cellEditor: 'dateEditor',
      valueFormatter: (params) => {
        if (params.value) {
          const dealEvent = params.data;
          return this.dateCellFormatter(params.value, getEventTimezoneOffset(dealEvent));
        }
        return '';
      },
      flex: 1,
      editable: false,
      cellClass: OPACITY_5,
      width: 110,
      hide: true,
    },
  ];
  simpleColumnDefs: ColDef<DealEvent>[] = [
    {
      field: 'single_inner_event.type',
      headerName: 'Type',
      flex: 1,
      editable: (params) => !!params.data && !isCanalEvent(params.data),
      cellEditor: 'selectEditor',
      cellEditorParams: (params: any) => {
        return {
          ...params,
          dataArray: [
            DealEventType.EventTypeLoad,
            DealEventType.EventTypeDischarge,
            DealEventType.EventTypeBunker,
          ],
          closeEditor: this.closeEditor.bind(this),
        };
      },
      onCellValueChanged: (params) => {
        if (isDealEventTypeCellChanged(params)) {
          if (params.data.single_inner_event?.meta) {
            delete params.data.single_inner_event.meta.terms_id;
            delete params.data.single_inner_event.meta.amount;
            delete params.data.single_inner_event.meta.load_discharge_rate;
          }
        }
      },
      valueFormatter: (params) => this.defaultAdditionalInfoFormatter(params),
    },
    {
      field: 'single_inner_event.cargo_id',
      headerName: 'Cargo',
      flex: 1,
      editable: (params) => this.enableEditAdditionalInfo(params),
      cellEditorSelector: (params: any) => {
        const event: DealEvent | undefined = params.data.single_inner_event;
        if (event && isCargoEvent(event)) {
          return {
            component: 'cargoEditor',
            params: {
              ...params,
              dataArray: this.cargoes,
              placeholder: 'Cargo order',
              emptyDataArray: 'No cargo found',
              onAddNewCargo: this.onAddCargo.bind(this, event),
              closeEditor: this.closeEditor.bind(this),
            },
          };
        }
        if (event?.meta?.fuel_id) {
          params.value = event.meta.fuel_id;
        }
        return {
          component: 'selectEditor',
          params: {
            ...params,
            dataArray: this.fuels,
            visibleKeys: ['name'],
            closeEditor: this.closeEditor.bind(this),
          },
        };
      },
      valueFormatter: (params: ValueFormatterParams<DealEvent>) => {
        const dealEvent: DealEvent | undefined = params.data?.single_inner_event;
        let result = '';
        if (dealEvent && isCargoEvent(dealEvent)) {
          result = NOT_APPLICABLE;
          const cargo = this._cargoes.find((cargo) => {
            return cargo.id === dealEvent?.cargo_id;
          });
          if (cargo) {
            result = `#${cargo.internal_order?.toString() || NOT_APPLICABLE}`;
          }
        } else if (dealEvent?.type === DealEventType.EventTypeBunker) {
          result = NOT_APPLICABLE;
          const fuel = this.fuels.find((fuel) => {
            return fuel.id === dealEvent.meta?.fuel_id;
          });
          if (fuel?.id) {
            result = `${fuel.name || NOT_APPLICABLE}`;
          }
        }
        return result;
      },
    },
    {
      field: 'single_inner_event.meta.amount',
      headerName: 'Quantity',
      editable: (params) => this.enableEditAdditionalInfo(params),
      cellEditor: 'numericEditor',
      valueFormatter: (params) => {
        const dealEvent: DealEvent | undefined = params.data?.single_inner_event;
        if (dealEvent && isSecondaryEvent(dealEvent)) {
          return this.decimalPipe.transform(params.value) || ZERO_STRING;
        }
        return '';
      },
      flex: 1,
    },
    /* {
      field: 'single_inner_event.meta.load_discharge_rate',
      headerName: 'L/D rate',
      editable: (params) => this.enableEditAdditionalInfo(params),
      cellClass: (params) => this.getCargoBaseCellClass(params, true),
      cellEditor: 'numericEditor',
      valueFormatter: (params) => {
        const dealEvent: DealEvent | undefined = params.data?.single_inner_event;
        if (dealEvent && isSecondaryEvent(dealEvent)) {
          return this.decimalPipe.transform(params.value) || ZERO_STRING;
        }
        return '';
      },
      flex: 1,
    }, */
    {
      field: 'single_inner_event.meta.extra_minutes_before',
      headerName: 'ET',
      editable: (params) => this.enableEditAdditionalInfo(params),
      cellEditor: 'durationEditor',
      cellEditorParams: { format: 'd' },
      valueFormatter: (params) => {
        const dealEvent: DealEvent | undefined = params.data?.single_inner_event;
        if (dealEvent && isSecondaryEvent(dealEvent)) {
          if (params.value) {
            return this.durationPipe.transform(params.value, 'd', 2);
          }
          return '0 d';
        }
        return '';
      },
      flex: 1,
    },
    {
      field: 'single_inner_event.meta.work_amount',
      headerName: 'LDSpeed',
      editable: (params) => this.enableEditAdditionalInfo(params),
      cellEditor: 'numericEditor',
      valueFormatter: (params) => {
        const dealEvent: DealEvent | undefined = params.data?.single_inner_event;
        if (dealEvent && isSecondaryEvent(dealEvent)) {
          return this.decimalPipe.transform(params.value) || ZERO_STRING;
        }
        return '';
      },
      flex: 1,
    },
    {
      field: 'single_inner_event.meta.work_type',
      headerName: 'DIM',
      flex: 1,
      editable: (params) => this.enableEditAdditionalInfo(params),
      cellClass: (params) => this.getCargoBaseCellClass(params, true),
      cellEditor: 'selectEditor',
      cellEditorParams: (params: any) => {
        return {
          ...params,
          dataArray: this.workTypeOptions,
          closeEditor: this.closeEditor.bind(this),
        };
      },
      valueFormatter: (params: ValueFormatterParams<DealEvent>) => {
        const dealEvent = params.data?.single_inner_event;
        if (dealEvent && isSecondaryEvent(dealEvent)) {
          return dealEvent.meta?.work_type || NOT_APPLICABLE;
        }
        return '';
      },
    },
    {
      field: 'single_inner_event.meta.terms_id',
      headerName: 'Terms',
      flex: 1,
      editable: (params) => this.enableEditAdditionalInfo(params),
      cellClass: (params) => this.getCargoBaseCellClass(params, true),
      cellEditor: 'autoCompleteEditor',
      cellEditorParams: (params: any) => {
        return {
          ...params,
          dataArray: this.cargoRules,
          placeholder: 'Terms',
          emptyDataArray: 'No terms found',
          visibleKeys: ['name'],
          resultKey: 'id',
          closeEditor: this.closeEditor.bind(this),
        };
      },
      valueFormatter: (params: ValueFormatterParams<DealEvent>) => {
        const dealEvent = params.data?.single_inner_event;
        if (dealEvent && isSecondaryEvent(dealEvent)) {
          const term = this.cargoRules.find((term) => term.id === dealEvent.meta?.terms_id);
          if (term?.name) {
            return term.name;
          }
          return NOT_APPLICABLE;
        }
        return '';
      },
    },
    {
      field: 'single_inner_event.meta.working_minutes.user',
      headerName: 'Working time',
      editable: (params) => this.enableEditAdditionalInfo(params),
      cellEditor: 'durationEditor',
      cellClass: (params) => {
        const event: DealEvent | undefined = params.data?.single_inner_event;
        if (event?.meta?.working_minutes?.is_changed) {
          return 'user-changed-cell';
        }
        return '';
      },
      tooltipField: 'single_inner_event.meta.working_minutes.is_changed',
      tooltipComponent: 'simpleTooltip',
      tooltipComponentParams: (params: { textValue: string } & ITooltipParams) => {
        return {
          ...params,
          textValue: params.data?.single_inner_event?.meta?.working_minutes?.is_changed
            ? 'Manual changed'
            : '',
        };
      },
      cellEditorParams: (params: any) => {
        const event: DealEvent | undefined = params.data?.single_inner_event;
        params.value =
          (event?.meta?.working_minutes?.is_changed
            ? event?.meta.working_minutes.user
            : event?.meta?.working_minutes?.system) || 0;
        return {
          ...params,
          format: 'd',
        };
      },
      valueFormatter: (params) => {
        if (params.data) {
          const dealEvent: DealEvent | undefined = params.data?.single_inner_event;
          if (dealEvent && isSecondaryEvent(dealEvent)) {
            return this.durationPipe.transform(
              (dealEvent?.meta?.working_minutes?.is_changed
                ? dealEvent?.meta.working_minutes.user
                : dealEvent?.meta?.working_minutes?.system) || 0,
              'd',
              2
            );
          }
        }
        return '';
      },
      flex: 1,
      onCellValueChanged: (event) => {
        const dealEvent: DealEvent | undefined = event.data?.single_inner_event;
        if (
          event.newValue !== event.oldValue &&
          event.newValue !== dealEvent?.meta?.working_minutes?.system
        ) {
          if (dealEvent?.meta?.working_minutes) {
            if (event.newValue || event.newValue === 0) {
              dealEvent.meta.working_minutes.user = event.newValue;
              dealEvent.meta.working_minutes.is_changed = true;
            } else {
              dealEvent.meta.working_minutes.user = 0;
              dealEvent.meta.working_minutes.is_changed = false;
            }
          }
        }
      },
    },
    {
      field: 'single_inner_event.meta.idle_minutes',
      headerName: 'TT',
      editable: (params) => this.enableEditAdditionalInfo(params),
      cellClass: (params) => {
        const event: DealEvent | undefined = params.data?.single_inner_event;
        if (event) {
          return isSecondaryEvent(event) && !event.meta?.working_minutes?.is_changed
            ? ''
            : OPACITY_5;
        }
        return OPACITY_5;
      },
      cellEditor: 'durationEditor',
      cellEditorParams: { format: 'd' },
      valueFormatter: (params) => {
        const dealEvent: DealEvent | undefined = params.data?.single_inner_event;
        if (dealEvent && isSecondaryEvent(dealEvent)) {
          if (params.value) {
            return this.durationPipe.transform(params.value, 'd', 2);
          }
          return '0 d';
        }
        return '';
      },
      flex: 1,
    },
    {
      field: 'single_inner_event.meta.extra_minutes_after',
      headerName: 'Extra time after',
      editable: (params) => this.enableEditAdditionalInfo(params),
      cellEditor: 'durationEditor',
      cellEditorParams: { format: 'd' },
      valueFormatter: (params) => {
        const dealEvent: DealEvent | undefined = params.data?.single_inner_event;
        if (dealEvent && isSecondaryEvent(dealEvent)) {
          if (params.value) {
            return this.durationPipe.transform(params.value, 'd', 2);
          }
          return '0 d';
        }
        return '';
      },
      flex: 1,
      hide: true,
    },
    {
      field: 'single_inner_event.parallel_with',
      headerName: 'Parallel with',
      editable: (params) => {
        const currentEvent: DealEvent | undefined = params.data?.single_inner_event;
        if (currentEvent && isSecondaryEvent(currentEvent)) {
          return !this.events.some((event) => event.parallel_with === currentEvent.id);
        }
        return false;
      },
      cellClass: (params) => {
        const currentEvent: DealEvent | undefined = params.data?.single_inner_event;
        if (currentEvent) {
          return this.events.some((event) => event.parallel_with === currentEvent.id)
            ? OPACITY_5
            : '';
        }
        return '';
      },
      cellEditor: 'selectEditor',
      cellEditorParams: (params: any) => {
        const currentEvent: DealEvent | undefined = params.data?.single_inner_event;
        let dataArray: DealEvent[] = [];
        if (currentEvent) {
          dataArray = this.events
            .filter((event) => event.id !== currentEvent.id && !event.parallel_with)
            .map((event) => this.eventToBaseModel(event));
        }
        return {
          ...params,
          dataArray,
          visibleKeys: ['name'],
          closeEditor: this.closeEditor.bind(this),
        };
      },
      onCellValueChanged: (event) => {
        const dealEvent = event.data?.single_inner_event;
        if (dealEvent && dealEvent.parallel_with) {
          if (isNaN(+dealEvent.parallel_with)) {
            const base = dealEvent.parallel_with as BaseModel;
            dealEvent.parallel_with = base.id;
          }
        }
      },
      valueFormatter: (params) => {
        const currentEvent: DealEvent | undefined = params.data?.single_inner_event;
        if (currentEvent && isSecondaryEvent(currentEvent)) {
          if (currentEvent?.parallel_with) {
            const parallelEvent = this.events.find(
              (elem) => elem.id === currentEvent.parallel_with
            );
            if (parallelEvent) {
              return this.eventToBaseModel(parallelEvent).name;
            }
          }
          return 'No';
        }
        return '';
      },
      flex: 1,
      hide: true,
    },
  ];
  gridOptions: GridOptions<DealEvent> = {
    rowModelType: 'clientSide',
    columnDefs: this.advancedColDefs,
    components: {
      buttonRenderer: AgGridButtonGroupComponent,
      header: AgGridHeaderComponent,
      autoCompleteEditor: AgGridAutocompleteComponent,
      durationEditor: AgGridTimeDurationEditorComponent,
      numericEditor: AgGridNumericCellEditorComponent,
      dateEditor: AgGridDateTimeEditorComponent,
      eventGroupRenderer: AgGridEventGroupRendererComponent,
      portRenderer: AgGridPortRendererComponent,
      portEditor: AgGridPortAutocompleteComponent,
      priceEditor: AgGridPriceEditorComponent,
      eventDetailsRenderer: AgGridEventDetailsComponent,
      eventTooltip: AgGridEventTooltipComponent,
      selectEditor: AgGridSelectCellEditorComponent,
      cargoEditor: AgGridCargoTypeComponent,
      simpleTooltip: AgGridBasicTooltipComponent,
      checkboxEditor: AgGridCheckboxCellComponent,
    },
    tooltipShowDelay: 0,
    tooltipMouseTrack: true,
    getRowId: (params) => {
      return params.data.id ? params.data.id.toString() : params.data.unique_row_id || ZERO_STRING;
    },
    defaultColDef: {
      editable: true,
      resizable: true,
    },
    domLayout: 'autoHeight',
    suppressContextMenu: true,
    suppressScrollOnNewData: true,
    singleClickEdit: true,
    rowSelection: 'single',
    animateRows: false,
    suppressAnimationFrame: true,
    suppressColumnMoveAnimation: true,
    rowHeight: STOP_EVENT_ROW_HEIGHT,
    headerHeight: STOP_EVENT_ROW_HEIGHT,
    autoSizePadding: 0,
    masterDetail: true,
    sideBar: this.sideBar,
    getRowHeight: (params) => {
      if (!params.node.detail) {
        return;
      }
      const stopEvent: DealEvent | undefined = params.data;
      const cargoEvents = stopEvent?.inner_events;
      if (cargoEvents?.length) {
        return cargoEvents.length * CARGO_EVENT_ROW_HEIGHT + CARGO_EVENT_ROW_HEIGHT;
      }
      return CARGO_EVENT_ROW_HEIGHT * 3;
    },
    getRowStyle: () => {
      return { background: 'rgba(8, 26, 81, 0.1)' };
    },
    detailCellRenderer: AgGridCargoDetailsComponent,
    detailCellRendererParams: (params: any) => {
      const stopEvent: DealEvent = params.data;
      const cargoEvents: DealEvent[] = stopEvent.inner_events || [];
      const height =
        (cargoEvents.length * CARGO_EVENT_ROW_HEIGHT || CARGO_EVENT_ROW_HEIGHT) +
        CARGO_EVENT_ROW_HEIGHT;
      if (params.node.detail) {
        params.node.rowHeight = height;
      }
      return {
        ...params,
        cargoEvents,
        cargoes: this.cargoes,
        cargoRules: this.cargoRules,
        fuels: this.fuels,
        tableState: this.cargoTableState,
        onAddCargoEvent: this.onAddCargoEvent.bind(this),
        onAddCargo: this.onAddCargo.bind(this),
        onDeleteEvent: this.onDeleteEvent.bind(this),
        onUpdateEvent: this.onUpdateEvent.bind(this),
        onSaveTableState: this.onSaveCargoTableState.bind(this),
      };
    },
    onCellEditingStopped: (event) => {
      const columnId = event.column.getColId();
      const isInnerEvent = columnId.includes('single_inner_event');
      const dealEvent = isInnerEvent ? event.data?.single_inner_event : event.data;
      if (columnId === 'single_inner_event.type' && !dealEvent) {
        const type: DealEventType = event.newValue;
        const stopEvent = event.data;
        if (type && stopEvent) {
          this.addCargoEvent.emit({ stopEvent, type });
          return;
        }
      }
      if (dealEvent) {
        if (dealEvent?.port?.id) {
          dealEvent.port_id = dealEvent?.port?.id;
        }
        if (columnId === 'single_inner_event.cargo_id') {
          if (!dealEvent.meta) {
            dealEvent.meta = {};
          }
          if (dealEvent.type === DealEventType.EventTypeBunker) {
            const fuel: Fuel = event.newValue;
            if (fuel && fuel.id) {
              dealEvent.meta.fuel_id = fuel.id;
            }
          }
          if (isCargoEvent(dealEvent)) {
            const cargo = this.cargoes.find((el) => el.id === dealEvent?.cargo_id);
            if (cargo && cargo.quantity) {
              dealEvent.meta.amount = cargo.quantity;
            }
          }
        }
        if (!dealEvent.meta) {
          dealEvent.meta = {};
        }
        if (!this._gridApi?.getEditingCells().length) {
          if (event.newValue !== event.oldValue) {
            this.updateEvent.emit(dealEvent);
          } else if (event.rowIndex !== null) {
            const editableEvent = isInnerEvent
              ? this.rowData[event.rowIndex].single_inner_event
              : this.rowData[event.rowIndex];
            const inputEvent = isInnerEvent
              ? this._inputEvents[event.rowIndex].single_inner_event
              : this._inputEvents[event.rowIndex];
            if (!isEqual(editableEvent, inputEvent)) {
              this.updateEvent.emit(editableEvent);
            }
          }
        }
      }
    },
    onRowDragEnter: (event) => {
      if (!this.dragableIndex) {
        this.dragableIndex = event.overIndex;
      }
    },
    onRowDragLeave: (event) => this.replaceEvents(event),
    onRowDragEnd: (event) => this.replaceEvents(event),
    rowDragManaged: true,
    tabToNextCell: (params) => {
      if (
        params.previousCellPosition &&
        params.nextCellPosition?.rowIndex !== params.previousCellPosition?.rowIndex
      ) {
        const editableInnerEvent =
          this.rowData[params.previousCellPosition.rowIndex].single_inner_event;
        const inputInnerEvent =
          this._inputEvents[params.previousCellPosition.rowIndex].single_inner_event;
        const editableEvent = this.rowData[params.previousCellPosition.rowIndex];
        const inputEvent = this._inputEvents[params.previousCellPosition.rowIndex];
        if (!isEqual(editableEvent, inputEvent)) {
          this.updateEvent.emit(editableEvent);
        }
        if (!isEqual(editableInnerEvent, inputInnerEvent)) {
          this.updateEvent.emit(editableInnerEvent);
        }
      }
      return params.nextCellPosition || null;
    },
    onColumnResized: (event) => this.changeTableState(event),
    onColumnMoved: (event) => this.changeTableState(event),
  };
  readonly workTypeOptions = Object.values(WorkType);
  readonly NOT_APPLICABLE = NOT_APPLICABLE;
  private _isLoading = false;
  private _isSearchPortLoading = false;
  private _gridApi?: GridApi<DealEvent>;
  private _gridColumnApi?: ColumnApi;
  private _events: DealEvent[] = [];
  private _ports: Port[] = [];
  private _cargoes: Cargo[] = [];
  private _inputEvents: DealEvent[] = [];
  private _isAdvancedMode = false;
  private _timeSettings: UserTimeSettings = UserTimeSettings.PortLocalTime;

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this._gridApi?.stopEditing(true);
    }
    if (event.key === 'Enter') {
      this._gridApi?.stopEditing();
    }
  }

  get rowData(): DealEvent[] {
    const events: DealEvent[] = [];
    this._gridApi?.forEachNode((node) => {
      if (node.data) {
        events.push(node.data);
      }
    });
    return events;
  }

  get advancedColDefs(): ColDef<DealEvent>[] {
    return this._isAdvancedMode ? this.columnDefs : [...this.columnDefs, ...this.simpleColumnDefs];
  }

  constructor(
    private readonly datePipe: DatePipe,
    private readonly durationPipe: DurationPipe,
    private readonly decimalPipe: DecimalPipe
  ) {}

  getCargoEvents(stopEvent?: DealEvent): DealEvent[] {
    const cargoEvents: DealEvent[] = [];
    if (stopEvent) {
      this.events.forEach((event) => {
        if (
          event.group_id === stopEvent.group_id &&
          (isCargoEvent(event) || event.type === DealEventType.EventTypeBunker)
        ) {
          cargoEvents.push(event);
        }
      });
    }
    return cargoEvents;
  }

  getCanals(dealEvent?: DealEvent): string[] {
    const canalsOnRoute: string[] = [];
    dealEvent?.meta?.route_geojson?.features?.forEach((feature) => {
      if (feature?.properties) {
        const canals: number[] = feature.properties[MapFeatureProperties.CanalsZones];
        if (canals) {
          canals.forEach((id) => {
            const finded = this.canals.find((canal) => canal.id === id);
            if (finded && finded.name && !canalsOnRoute.includes(finded.name)) {
              canalsOnRoute.push(finded.name);
            }
          });
        }
      }
    });
    return canalsOnRoute;
  }

  onGridReady({ api, columnApi }: GridReadyEvent): void {
    this._gridApi = api;
    this._gridColumnApi = columnApi;
    this._gridApi.setFocusedCell(0, 'route');
    if (this.tableState.length) {
      this._gridColumnApi.applyColumnState({ state: this.tableState, applyOrder: true });
    }
  }

  onAddEvent(): void {
    this.addStopEvent.emit();
  }

  onUpdateEvent(event?: DealEvent): void {
    if (event) {
      this.updateEvent.emit(event);
    }
  }

  onDeleteEvent(event?: DealEvent): void {
    if (event) {
      this.deleteEvent.emit(event);
    }
  }

  openDetails(params: ICellRendererParams<DealEvent> | CellClickedEvent<DealEvent>): void {
    params.node.setExpanded(!params.node.expanded);
    this._gridApi?.redrawRows();
  }

  onSearchPort(request: string): void {
    this.searchPort.emit(request);
  }
  onAddCargoEvent(event: { stopEvent: DealEvent }): void {
    this.addCargoEvent.emit(event);
  }

  onOpenRouteMap(stopEvent: DealEvent): void {
    this.openRouteMap.emit(stopEvent);
  }

  onAddCargo(event: DealEvent): void {
    this.addCargo.emit(event);
  }

  closeEditor(): void {
    if (this._gridApi) {
      this._gridApi.stopEditing();
    }
  }

  replaceEvents(event: RowDragEvent<DealEvent>): void {
    const draggedEvent: DealEvent | undefined = event.node.data;
    if (draggedEvent && this.dragableIndex !== null && this.dragableIndex !== event.overIndex) {
      const newOrderEvents: DealEvent[] = [];
      this.rowData.forEach((elem) => {
        if (event.overIndex === 0 && elem.id === draggedEvent.id) {
          const startTime = this.events[0].start_time;
          elem.start_time = startTime;
        }
        const sameGroupIdEvents = this.events.filter((el) => el.group_id === elem.group_id);
        newOrderEvents.push(...sameGroupIdEvents);
      });
      if (!isEqual(newOrderEvents, this.events)) {
        this.replaceStopEvent.emit(newOrderEvents);
      }
    }
    this.dragableIndex = null;
  }

  headerPortClick(): void {
    this.showTooltip.emit(UserGuideType.EventList4);
  }

  changeTableState(event: ColumnResizedEvent<DealEvent> | ColumnMovedEvent<DealEvent>): void {
    const state = event.columnApi.getColumnState();
    if (!isEqual(state, this.tableState)) {
      this.saveTableState.emit(state);
    }
  }

  onSaveCargoTableState(state: ColumnState[]): void {
    this.saveCargoTableState.emit(state);
  }

  onResetTableState(): void {
    this._gridColumnApi?.resetColumnState();
    this.resetTableState.emit();
  }

  getCargoBaseCellClass(params: CellClassParams, isInnerEvent = false): string {
    const event: DealEvent | undefined = isInnerEvent
      ? params.data?.single_inner_event
      : params.data;
    if (event) {
      return isSecondaryEvent(event) ? '' : OPACITY_5;
    }
    return OPACITY_5;
  }

  eventToBaseModel(event: DealEvent): BaseModel {
    if (isCargoEvent(event)) {
      const cargo = this.cargoes.find((elem) => elem.id === event?.cargo_id);
      return {
        id: event.id,
        name: `${event.type} #${cargo?.internal_order || NOT_APPLICABLE}`,
      };
    }
    const fuel = this.fuels.find((elem) => elem.id === event.meta?.fuel_id);
    return {
      id: event.id,
      name: `${event.type} #${fuel?.name || NOT_APPLICABLE}`,
    };
  }

  setExpandedRows(): void {
    this._gridApi?.forEachNode((node) => {
      if (this._isAdvancedMode) {
        const stopEvent: DealEvent | undefined = node.data;
        if (stopEvent) {
          const cargoEvents = stopEvent.inner_events;
          if (cargoEvents?.length) {
            node.setExpanded(true);
          }
        }
      } else {
        node.setExpanded(false);
      }
    });
  }

  enableEditAdditionalInfo(params: EditableCallbackParams<DealEvent>): boolean {
    const dealEvent: DealEvent | undefined = params.data?.single_inner_event;
    if (dealEvent) {
      return isSecondaryEvent(dealEvent);
    }
    return false;
  }

  defaultAdditionalInfoFormatter(params: ValueFormatterParams<DealEvent>): string {
    const dealEvent: DealEvent | undefined = params.data?.single_inner_event;
    if (dealEvent && isSecondaryEvent(dealEvent)) {
      return params.value;
    }
    return '';
  }

  dateCellFormatter(date: string, timeZoneOffset?: number): string {
    return (
      this.datePipe.transform(
        moment(date).toISOString(),
        STANDART_TIME_FORMAT,
        getDatePipeTimeZoneFormat(this.timeSettings, timeZoneOffset)
      ) || ''
    );
  }
}
