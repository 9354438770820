<form
  [formGroup]="vesselFormGroup"
  class="primeng-form w-full flex flex-column gap-3 estimator-section px-1 pt-3 pb-2"
  id="estimator_vessel_form"
>
  <section class="flex gap-1">
    <div class="flex gap-1 col-5 p-0">
      <span class="p-float-label w-full">
        <p-autoComplete
          formControlName="name"
          [suggestions]="vessels"
          [tabindex]="tabIndex"
          [minLength]="3"
          (completeMethod)="onSearchVessel($event)"
          (onSelect)="onSelectVessel($event)"
          (onBlur)="onBlurVessel($event)"
          class="p-inputtext-sm vessel-autocomplete"
          inputStyleClass="w-full"
          styleClass="w-full"
          inputId="vessel-name"
          #vesselAutoComplete
        >
          <ng-template let-vessel pTemplate="vessel" styleClass="op">
            <div class="option-wrapper" [ngClass]="{ 'clear-vessel': !vessel.is_raw }">
              <div class="option-row">
                <span class="material-icons vessel-icon">directions_boat</span>
                <div class="vessel-name">
                  <b>{{ vessel?.name }}</b>
                </div>
              </div>
              <span>IMO: {{ vessel?.imo }}</span>
              <span>DWT: {{ vessel?.dead_weight }}</span>
            </div>
          </ng-template>
        </p-autoComplete>
        <label htmlFor="vessel-name">Vessel name</label>
      </span>
      <ng-container *ngIf="isFuelConsParser">
        <p-button
          [disabled]="isFuelConsParserDisabled() || isFixed"
          (click)="deepSearchVessel()"
          (mouseover)="onMouseOver()"
          pTooltip="Deep search"
          tooltipPosition="top"
          [icon]="PrimeNgIcons.SEARCH_PLUS"
          id="vessel-search_button"
          size="small"
          class="button-vessel"
          styleClass="primary-color"
        ></p-button>
        <p-button
          [disabled]="
            !this.vesselFormGroup.controls.name.value ||
            !!this.vesselFormGroup.controls.is_raw.value ||
            isFixed
          "
          (click)="updateVesselFromBase()"
          pTooltip="Update vessel"
          tooltipPosition="top"
          [icon]="PrimeNgIcons.REFRESH"
          id="vessel-update_button"
          size="small"
          class="button-vessel"
          styleClass="primary-color"
        ></p-button>
      </ng-container>
    </div>
    <div class="flex-basis-20">
      <span class="p-float-label">
        <p-inputNumber
          [useGrouping]="false"
          [minFractionDigits]="0"
          [maxFractionDigits]="2"
          [min]="0"
          [max]="MAX_FINANCE_VALUE"
          [step]="0"
          [tabindex]="tabIndex"
          formControlName="dead_weight"
          class="p-inputtext-sm"
          inputStyleClass="w-full"
          inputId="vessel-dead_weight"
          estimatorDecimals
        ></p-inputNumber>
        <label htmlFor="vessel-dead_weight">DWT</label>
      </span>
    </div>
    <div class="flex col-5 p-0 gap-1">
      <span class="p-float-label flex-basis-50">
        <p-inputNumber
          [useGrouping]="false"
          [min]="0"
          [max]="MAX_FINANCE_VALUE"
          [step]="0"
          [tabindex]="tabIndex"
          formControlName="build_year"
          class="p-inputtext-sm"
          inputStyleClass="w-full"
          inputId="vessel-build_year"
        ></p-inputNumber>
        <label htmlFor="vessel-build_year">Build</label>
      </span>
      <p-button
        class="w-full flex-basis-50"
        styleClass="w-full p-button-small"
        size="small"
        label="Save"
        type="button"
        [disabled]="isSaveVesselDisabled"
        [severity]="isSaveVesselDisabled ? 'secondary' : 'primary'"
        (click)="onSaveVesselToBase()"
        [loading]="isLoading"
        [tabindex]="tabIndex"
        pTooltip="Save vessel to company"
        tooltipPosition="top"
        id="vessel_and_consumption_form_save_vessel"
      ></p-button>
    </div>
  </section>
  <section class="flex -mt-3">
    <div class="flex justify-content-between col-5 p-0 gap-1 align-items-end">
      <div>
        <label class="small-label">
          <span
            class="cursor-pointer"
            *ngFor="let metric of tonnageMetrics; let last = last"
            [pTooltip]="metric?.tooltip"
            tooltipPosition="top"
            (click)="selectTonnageMetric(metric)"
            [ngClass]="{'selected': metric.value === selectedTonnageType}"
          >
            {{ metric.value }}<ng-container *ngIf="!last"><span class="separate"> / </span></ng-container>
          </span>
        </label>
        <span>
          <p-inputNumber
            [useGrouping]="false"
            [minFractionDigits]="0"
            [maxFractionDigits]="2"
            [min]="0"
            [max]="MAX_FINANCE_VALUE"
            [step]="0"
            [tabindex]="tabIndex"
            [formControl]="getTonnageControl()"
            class="p-inputtext-sm"
            inputStyleClass="w-full"
            [inputId]="'vessel-' + selectedTonnageType.toLowerCase()"
            [placeholder]="selectedTonnageType"
            estimatorDecimals
          ></p-inputNumber>
        </span>
      </div>
      <div>
        <span class="p-float-label">
          <p-inputNumber
            [useGrouping]="false"
            [minFractionDigits]="0"
            [maxFractionDigits]="2"
            [min]="0"
            [max]="MAX_VALUE"
            [step]="0"
            [tabindex]="tabIndex"
            formControlName="length"
            class="p-inputtext-sm"
            inputStyleClass="w-full"
            inputId="vessel-length"
            estimatorDecimals
          ></p-inputNumber>
          <label htmlFor="vessel-length">LOA</label>
        </span>
      </div>
      <div>
        <span class="p-float-label">
          <p-inputNumber
            [useGrouping]="false"
            [minFractionDigits]="0"
            [maxFractionDigits]="2"
            [min]="0"
            [max]="MAX_FINANCE_VALUE"
            [step]="0"
            [tabindex]="tabIndex"
            formControlName="beam"
            class="p-inputtext-sm"
            inputStyleClass="w-full"
            inputId="vessel-beam"
            estimatorDecimals
          ></p-inputNumber>
          <label htmlFor="vessel-beam">Beam</label>
        </span>
      </div>
    </div>
    <div class="flex col-2 px-1 py-0 relative align-items-end">
      <span class="p-float-label position-relative" #triggerEl>
        <p-inputNumber
          [useGrouping]="false"
          [minFractionDigits]="0"
          [maxFractionDigits]="2"
          [min]="0"
          [max]="MAX_FINANCE_VALUE"
          [step]="0"
          [tabindex]="tabIndex"
          [formControl]="
            vesselFormGroup.controls.constants?.controls?.deductibles || mockFormControl
          "
          (onBlur)="onBlurDeductibles($event)"
          (onFocus)="onFocusDeductibles()"
          class="p-inputtext-sm"
          inputStyleClass="w-full"
          inputId="vessel-deductibles"
          estimatorDecimals
        ></p-inputNumber>
        <label
          class="pointer-events-auto cursor-pointer"
          htmlFor="vessel-deductibles"
          pTooltip="Sum of ship constants"
          tooltipPosition="top"
          >Deduct</label
        >
      </span>
      <p-button
        [icon]="getDeductiblesIcon()"
        [ngClass]="{
          'deductibles-filled': getDeductiblesColor()
        }"
        [disabled]="isFixed"
        styleClass="p-button-small "
        class="border-round-sm deductibles position-absolute right-0 w-20px h-20px"
        [id]="'vessel-deductibles_icon'"
        size="small"
        [text]="true"
        (click)="openDeductiblesModal($event, triggerEl)"
      >
      </p-button>
      <p-overlayPanel #overlay styleClass="primeng-form" (onHide)="onDeductiblesModalClose()">
        <section class="flex gap-2">
          <!-- 1 колонка -->
          <div class="flex flex-column justify-content-between gap-3">
            <div>
              <span class="p-float-label">
                <p-inputNumber
                  [useGrouping]="false"
                  [minFractionDigits]="0"
                  [maxFractionDigits]="2"
                  [min]="0"
                  [max]="MAX_FINANCE_VALUE"
                  [step]="0"
                  [tabindex]="tabIndex"
                  [formControl]="
                    vesselFormGroup.controls.constants?.controls?.bunker_weight || mockFormControl
                  "
                  class="p-inputtext-sm"
                  inputStyleClass="w-full"
                  inputId="vessel-bunker"
                  estimatorDecimals
                ></p-inputNumber>
                <label htmlFor="vessel-bunker">Bunker</label>
              </span>
            </div>
            <div>
              <span class="p-float-label">
                <p-inputNumber
                  [useGrouping]="false"
                  [minFractionDigits]="0"
                  [maxFractionDigits]="2"
                  [min]="0"
                  [max]="MAX_FINANCE_VALUE"
                  [step]="0"
                  [tabindex]="tabIndex"
                  [formControl]="
                    vesselFormGroup.controls.constants?.controls?.permanent_ballast ||
                    mockFormControl
                  "
                  class="p-inputtext-sm"
                  inputStyleClass="w-full"
                  inputId="vessel-permanent_ballast"
                  estimatorDecimals
                ></p-inputNumber>
                <label htmlFor="vessel-permanent_ballast">Permanent ballast</label>
              </span>
            </div>
            <div>
              <span class="p-float-label">
                <p-inputNumber
                  [useGrouping]="false"
                  [minFractionDigits]="0"
                  [maxFractionDigits]="2"
                  [min]="0"
                  [max]="MAX_FINANCE_VALUE"
                  [step]="0"
                  [tabindex]="tabIndex"
                  [formControl]="
                    vesselFormGroup.controls.constants?.controls?.constant_weight || mockFormControl
                  "
                  class="p-inputtext-sm"
                  inputStyleClass="w-full"
                  inputId="vessel-constant_weight"
                  estimatorDecimals
                ></p-inputNumber>
                <label htmlFor="vessel-constant_weight">Constants</label>
              </span>
            </div>
          </div>
          <!-- 2 колонка -->
          <div class="flex flex-column justify-content-end gap-3">
            <div>
              <span class="p-float-label">
                <p-inputNumber
                  [useGrouping]="false"
                  [minFractionDigits]="0"
                  [maxFractionDigits]="2"
                  [min]="0"
                  [max]="MAX_FINANCE_VALUE"
                  [step]="0"
                  [tabindex]="tabIndex"
                  [formControl]="
                    vesselFormGroup.controls.constants?.controls?.fresh_water || mockFormControl
                  "
                  class="p-inputtext-sm"
                  inputStyleClass="w-full"
                  inputId="vessel-fresh_water"
                  estimatorDecimals
                ></p-inputNumber>
                <label htmlFor="vessel-fresh_water">Fresh water</label>
              </span>
            </div>
            <div>
              <span class="p-float-label">
                <p-inputNumber
                  [useGrouping]="false"
                  [minFractionDigits]="0"
                  [maxFractionDigits]="2"
                  [min]="0"
                  [max]="MAX_FINANCE_VALUE"
                  [step]="0"
                  [tabindex]="tabIndex"
                  [formControl]="
                    vesselFormGroup.controls.constants?.controls?.other_weight || mockFormControl
                  "
                  class="p-inputtext-sm"
                  inputStyleClass="w-full"
                  inputId="vessel-other_weight"
                  estimatorDecimals
                ></p-inputNumber>
                <label htmlFor="vessel-other_weight">Other weight</label>
              </span>
            </div>
          </div>
        </section>
        <div class="flex flex-column justify-content-center align-items-center gap-2 mt-2">
          <span class="font-bold">Sum: {{ getSummDeductibles() }}</span>
          <p-button
            styleClass="primary-color"
            label="Save"
            type="button"
            [icon]="PrimeNgIcons.SAVE"
            (click)="onSaveSummDeductibles()"
            [tabindex]="tabIndex"
            size="small"
          ></p-button>
        </div>
      </p-overlayPanel>
    </div>
    <div class="flex flex-row justify-content-between align-items-end col-5 p-0 gap-1">
      <div>
        <span class="p-float-label">
          <p-inputNumber
            [useGrouping]="false"
            [minFractionDigits]="0"
            [maxFractionDigits]="2"
            [min]="0"
            [max]="MAX_VALUE"
            [step]="0"
            [tabindex]="tabIndex"
            formControlName="draught"
            class="p-inputtext-sm"
            inputStyleClass="w-full"
            inputId="vessel-draught"
            estimatorDecimals
          ></p-inputNumber>
          <label htmlFor="vessel-draught">Draft</label>
        </span>
      </div>
      <div>
        <span class="p-float-label">
          <p-inputNumber
            [useGrouping]="false"
            [minFractionDigits]="0"
            [maxFractionDigits]="2"
            [min]="0"
            [max]="MAX_VALUE"
            [step]="0"
            [tabindex]="tabIndex"
            formControlName="tpc"
            class="p-inputtext-sm"
            inputStyleClass="w-full"
            inputId="vessel-tpc"
            estimatorDecimals
          ></p-inputNumber>
          <label htmlFor="vessel-tpc">TPC</label>
        </span>
      </div>
      <div
        *ngIf="vesselFormGroup.controls.scrubber?.value"
        pTooltip="Fuel type for scrubber in 0.1 Sulphur ECA (In China ECA ME always use HSFO)"
        tooltipPosition="left"
      >
        <!--  <span class="p-float-label">
          <p-inputNumber
            [useGrouping]="false"
            [minFractionDigits]="0"
            [maxFractionDigits]="2"
            [min]="0"
            [max]="MAX_FINANCE_VALUE"
            [step]="0"
            [tabindex]="tabIndex"
            formControlName="gross_registered_tonnage"
            class="p-inputtext-sm"
            inputStyleClass="w-full"
            inputId="vessel-grt"
            estimatorDecimals
          ></p-inputNumber>
          <label htmlFor="vessel-grt">GRT</label>
        </span> -->
        <estimator-button-switch
          [disabled]="isFixed"
          class="scrubber-fuel"
          [options]="scrubberFuels"
          [tabindex]="tabIndex"
          [value]="highPurityFuelNameFromFirstEvent"
          id="high_purity_fuel_name"
          (onChange)="onHighPurityFuelNameChange($event)"
          optionLabel="short_name"
          optionValue="value"
        >
        </estimator-button-switch>
      </div>
      <div class="flex align-items-center gap-1" pTooltip="Scrubber" tooltipPosition="left">
        <!-- <p-checkbox
          class="small-checkbox"
          [binary]="true"
          [tabindex]="tabIndex"
          (onChange)="onChangeScrubber($event)"
          formControlName="scrubber"
          inputId="vessel_scrubber"
        ></p-checkbox>
        <label class="small-label" for="vessel_scrubber">Scrubber</label> -->
        <p-button
          class="scrubber-button"
          styleClass="scrubber-button p-button-small border-round-sm"
          severity="secondary"
          size="small"
          label="S"
          type="button"
          [tabindex]="tabIndex"
          (click)="onChangeScrubber()"
          [disabled]="isFixed"
          [ngClass]="{ 'scrubber-button-color': vesselFormGroup.controls.scrubber?.value }"
        ></p-button>
      </div>
    </div>
  </section>
  <section class="grid grid-nogutter w-full">
    <form [formGroup]="fullPresetFormGroup" class="col-5 flex flex-column gap-1" #vesselFuelForm>
      <span class="p-float-label flex button-addon">
        <p-dropdown
          [options]="currentVessel?.presets || []"
          [formControl]="fullPresetFormGroup.controls.name"
          [tabindex]="tabIndex"
          optionValue="name"
          optionLabel="name"
          class="w-full flex p-inputtext-sm hide-dropdown-button"
          styleClass="w-full"
          inputId="vessel-full_preset-name"
        ></p-dropdown>
        <p-button
          [disabled]="disabledSetPreset(fullPresetFormGroup.controls.name.value) || isFixed"
          (click)="setPresetToEventsDeal(fullPresetFormGroup)"
          pTooltip="Set full preset to events"
          tooltipPosition="top"
          [icon]="getPresetIcon(fullPresetFormGroup)"
          id="vessel-set-full-preset_button"
          size="small"
          class="button-preset"
          styleClass="primary-color"
          [ngClass]="{
            'button-preset-selected': !notSelectedSetPreset(fullPresetFormGroup.controls.name.value)
          }"
        ></p-button>
        <label htmlFor="vessel-full_preset-name" class="left-2">Speed consumption</label>
      </span>
      <div class="flex gap-1 mt-2">
        <span class="p-float-label" [ngClass]="{ 'show-invalid': true }">
          <p-inputNumber
            [useGrouping]="false"
            [minFractionDigits]="0"
            [maxFractionDigits]="2"
            [min]="0"
            [max]="MAX_VALUE"
            [step]="0"
            [tabindex]="tabIndex"
            formControlName="ballast_speed"
            class="p-inputtext-sm"
            inputStyleClass="w-full"
            inputId="vessel-full_preset-ballast_speed"
            estimatorDecimals
          ></p-inputNumber>
          <label htmlFor="vessel-full_preset-ballast_speed">Ballast</label>
        </span>
        <span class="p-float-label" [ngClass]="{ 'show-invalid': true }">
          <p-inputNumber
            [useGrouping]="false"
            [minFractionDigits]="0"
            [maxFractionDigits]="2"
            [min]="0"
            [max]="MAX_VALUE"
            [step]="0"
            [tabindex]="tabIndex"
            formControlName="laden_speed"
            class="p-inputtext-sm"
            inputStyleClass="w-full"
            inputId="vessel-full_preset-laden_speed"
            estimatorDecimals
          ></p-inputNumber>
          <label htmlFor="vessel-full_preset-laden_speed">Laden</label>
        </span>
      </div>
      <ng-container
        *ngFor="
          let consumptionFormGroup of getConsumptionsRatesArray(fullPresetFormGroup).controls;
          let i = index
        "
      >
        <form
          class="flex gap-1"
          [ngClass]="{ 'show-invalid': true }"
          [formGroup]="getRateFormGroup(consumptionFormGroup, true)"
          *ngIf="visibleConsumptionFg(consumptionFormGroup, true, false)"
        >
          <p-inputNumber
            [useGrouping]="false"
            [minFractionDigits]="0"
            [maxFractionDigits]="2"
            [min]="0"
            [max]="MAX_VALUE"
            [step]="0"
            [tabindex]="tabIndex"
            formControlName="ballast"
            class="p-inputtext-sm"
            inputStyleClass="w-full"
            [inputId]="'vessel-full_preset-ballast-' + i"
            (onBlur)="
              onChangeSpeedControl(
                $event,
                getConsumptionsRatesArray(fullPresetFormGroup).controls,
                'ballast',
                consumptionFormGroup.controls.fuel.value?.name
              )
            "
            estimatorDecimals
          ></p-inputNumber>
          <p-inputNumber
            [useGrouping]="false"
            [minFractionDigits]="0"
            [maxFractionDigits]="2"
            [min]="0"
            [max]="MAX_VALUE"
            [step]="0"
            [tabindex]="tabIndex"
            formControlName="laden"
            class="p-inputtext-sm"
            inputStyleClass="w-full"
            [inputId]="'vessel-full_preset-laden-' + i"
            (onBlur)="
              onChangeSpeedControl(
                $event,
                getConsumptionsRatesArray(fullPresetFormGroup).controls,
                'laden',
                consumptionFormGroup.controls.fuel.value?.name
              )
            "
            estimatorDecimals
          ></p-inputNumber>
        </form>
      </ng-container>
    </form>
    <div class="col-2 flex flex-column gap-1 align-self-end">
      <div class="flex flex-column gap-1 mt-2">
        <label
          class="small-label consumption-label"
          pTooltip="kn (nm per hour)"
          tooltipPosition="top"
          >Speed</label
        >
        <ng-container
          *ngFor="
            let consumptionFormGroup of getConsumptionsRatesArray(fullPresetFormGroup).controls;
            let i = index
          "
        >
          <label
            *ngIf="visibleConsumptionFg(consumptionFormGroup, true, false)"
            class="small-label consumption-label"
            [pTooltip]="getFuelTypeNameTooltip(consumptionFormGroup.controls.fuel.value?.name)"
            tooltipPosition="top"
            >{{ getFuelTypeName(consumptionFormGroup.controls.fuel.value?.name) || NOT_APPLICABLE }}
          </label>
        </ng-container>
      </div>
    </div>
    <form [formGroup]="ecoPresetFormGroup" class="col-5 flex flex-column gap-1">
      <span class="p-float-label flex button-addon">
        <p-dropdown
          [options]="ecoPresets"
          [formControl]="ecoPresetFormGroup.controls.name"
          [tabindex]="tabIndex"
          (onChange)="onSelectPreset($event)"
          optionValue="name"
          optionLabel="name"
          class="w-full p-inputtext-sm hide-dropdown-button"
          styleClass="w-full"
          inputId="vessel_eco_preset_name"
        >
        </p-dropdown>
        <p-button
          [disabled]="disabledSetPreset(ecoPresetFormGroup.controls.name.value) || isFixed"
          (click)="setPresetToEventsDeal(ecoPresetFormGroup)"
          pTooltip="Set eco preset to events"
          tooltipPosition="top"
          [icon]="getPresetIcon(ecoPresetFormGroup)"
          id="vessel-set-eco-preset_button"
          size="small"
          class="button-preset"
          [severity]="
            disabledSetPreset(ecoPresetFormGroup.controls.name.value) ? 'secondary' : 'primary'
          "
          [ngClass]="{
            'button-preset-selected': !notSelectedSetPreset(ecoPresetFormGroup.controls.name.value)
          }"
        ></p-button>
        <label htmlFor="vessel-eco_preset-name" class="left-2">Eco speed consumption</label>
      </span>
      <div class="flex gap-1 mt-2">
        <span class="p-float-label" [ngClass]="{ 'show-invalid': true }">
          <p-inputNumber
            [useGrouping]="false"
            [minFractionDigits]="0"
            [maxFractionDigits]="2"
            [min]="0"
            [max]="MAX_VALUE"
            [step]="0"
            [tabindex]="tabIndex"
            formControlName="ballast_speed"
            class="p-inputtext-sm"
            inputStyleClass="w-full"
            inputId="vessel-eco_preset-ballast_speed"
            estimatorDecimals
          ></p-inputNumber>
          <label htmlFor="vessel-eco_preset-ballast_speed">Ballast</label>
        </span>
        <span class="p-float-label" [ngClass]="{ 'show-invalid': true }">
          <p-inputNumber
            [useGrouping]="false"
            [minFractionDigits]="0"
            [maxFractionDigits]="2"
            [min]="0"
            [max]="MAX_VALUE"
            [step]="0"
            [tabindex]="tabIndex"
            formControlName="laden_speed"
            class="p-inputtext-sm"
            inputStyleClass="w-full"
            inputId="vessel-eco_preset-laden_speed"
            estimatorDecimals
          ></p-inputNumber>
          <label htmlFor="vessel-eco_preset-laden_speed">Laden</label>
        </span>
      </div>
      <ng-container
        *ngFor="
          let consumptionFormGroup of getConsumptionsRatesArray(ecoPresetFormGroup).controls;
          let i = index
        "
        [formGroup]="consumptionFormGroup"
      >
        <form
          class="flex gap-1"
          [ngClass]="{ 'show-invalid': true }"
          [formGroup]="getRateFormGroup(consumptionFormGroup, true)"
          *ngIf="visibleConsumptionFg(consumptionFormGroup, true, false)"
        >
          <!-- right from ME-text/AE(LSMGO)-text in interface -->
          <p-inputNumber
            [useGrouping]="false"
            [minFractionDigits]="0"
            [maxFractionDigits]="2"
            [min]="0"
            [max]="MAX_VALUE"
            [step]="0"
            [tabindex]="tabIndex"
            formControlName="ballast"
            class="p-inputtext-sm"
            inputStyleClass="w-full"
            [inputId]="'vessel-eco_preset-ballast-' + i"
            (onBlur)="
              onChangeBallastOrLadenControl(
                $event,
                getConsumptionsRatesArray(ecoPresetFormGroup).controls,
                'ballast',
                consumptionFormGroup?.controls?.fuel?.value?.name
              )
            "
            estimatorDecimals
          ></p-inputNumber>
          <p-inputNumber
            [useGrouping]="false"
            [minFractionDigits]="0"
            [maxFractionDigits]="2"
            [min]="0"
            [max]="MAX_VALUE"
            [step]="0"
            [tabindex]="tabIndex"
            formControlName="laden"
            inputStyleClass="w-full"
            class="p-inputtext-sm"
            [inputId]="'vessel-eco_preset-laden-' + i"
            (onBlur)="
              onChangeBallastOrLadenControl(
                $event,
                getConsumptionsRatesArray(ecoPresetFormGroup).controls,
                'laden',
                consumptionFormGroup?.controls?.fuel?.value?.name
              )
            "
            estimatorDecimals
          ></p-inputNumber>
        </form>
      </ng-container>
    </form>
    <ng-container
      *ngFor="
        let consumptionFormGroup of getConsumptionsRatesArray(fullPresetFormGroup).controls;
        let i = index
      "
    >
      <ng-container *ngIf="isTanker && visibleCargoHeating(consumptionFormGroup)">
        <form class="flex gap-1 col-5" [formGroup]="consumptionFormGroup">
          <div class="flex gap-1 mt-1">
            <div class="flex-1" style="visibility: hidden">
              <div class="flex-container-column">
                <label>заглушка</label>
              </div>
            </div>
            <div class="flex-1">
              <p-inputNumber
                [useGrouping]="false"
                [minFractionDigits]="0"
                [maxFractionDigits]="2"
                [min]="0"
                [tabindex]="tabIndex"
                inputStyleClass="w-full"
                formControlName="cargo_heating_rate"
                class="p-inputtext-sm"
                [inputId]="'vessel-full_preset-cargo_heating_rate-' + i"
                (onBlur)="
                  onChangeCargoHeatingRateControl(
                    $event,
                    getConsumptionsRatesArray(fullPresetFormGroup).controls,
                    consumptionFormGroup.controls.fuel.value?.name
                  )
                "
                estimatorDecimals
              ></p-inputNumber>
            </div>
          </div>
        </form>
        <div class="col-2">
          <div class="flex-container-column h-full content-center">
            <label class="small-label" pTooltip="Cargo heating consumption" tooltipPosition="top"
              >Heat
              <!-- ({{ FuelTypes.LSMGO }}) --></label
            >
          </div>
        </div>
        <div class="flex align-items-end">
          <estimator-button-switch
            [disabled]="isFixed"
            [options]="cargoHeatingTypes"
            [tabindex]="tabIndex"
            [value]="cargoHeating.toString()"
            [id]="'vessel-full_preset-cargo_heating_rate_switcher-' + i"
            (onChange)="onCalculateHeatingConsumptionChange($event)"
            optionLabel="short_name"
            optionValue="value"
          >
          </estimator-button-switch>
        </div>
      </ng-container>
    </ng-container>
  </section>
  <hr class="hr-line m-0 w-full -mt-2" />
  <section class="flex -mt-2">
    <div class="col-5 p-0 flex flex-column gap-2">
      <label class="small-label port-consumptions-label pb-1">Port consumption</label>
      <ng-container
        *ngFor="
          let consumptionFormGroup of getConsumptionsRatesArray(fullPresetFormGroup).controls;
          let i = index;
          let isLast = last
        "
      >
        <form
          class="flex gap-1"
          [formGroup]="getRateFormGroup(consumptionFormGroup, true)"
          *ngIf="visibleConsumptionFg(consumptionFormGroup, false)"
        >
          <span class="p-float-label">
            <p-inputNumber
              [style.visibility]="isShowFuels(consumptionFormGroup) ? 'inherit' : 'hidden'"
              [useGrouping]="false"
              [minFractionDigits]="0"
              [maxFractionDigits]="2"
              [min]="0"
              [max]="MAX_VALUE"
              [step]="0"
              [tabindex]="tabIndex"
              formControlName="idle"
              class="p-inputtext-sm"
              inputStyleClass="w-full"
              [inputId]="'vessel-full-preset-port-consumption-idle-' + i"
              (onBlur)="
                onChangeControl(
                  $event,
                  getConsumptionsRatesArray(fullPresetFormGroup).controls,
                  'idle',
                  consumptionFormGroup.controls.fuel.value?.name
                )
              "
              estimatorDecimals
            ></p-inputNumber>
            <label
              *ngIf="
                isShowFuels(consumptionFormGroup) &&
                consumptionFormGroup.controls.fuel.value?.name !== FuelTypes.LSMGO
              "
              >{{ isTanker ? 'Load/Idle' : 'Idle' }}</label
            >
            <label *ngIf="consumptionFormGroup.controls.fuel.value?.name === FuelTypes.LSMGO"
              >+AE idle</label
            >
          </span>
          <span class="p-float-label">
            <p-inputNumber
              [style.visibility]="isShowFuels(consumptionFormGroup) ? 'inherit' : 'hidden'"
              [useGrouping]="false"
              [minFractionDigits]="0"
              [maxFractionDigits]="2"
              [min]="0"
              [max]="MAX_VALUE"
              [step]="0"
              [tabindex]="tabIndex"
              formControlName="work_on_loading"
              class="p-inputtext-sm"
              inputStyleClass="w-full"
              [inputId]="'vessel-full-preset-port-consumption-work_on_loading-' + i"
              (onBlur)="
                onChangeControl(
                  $event,
                  getConsumptionsRatesArray(fullPresetFormGroup).controls,
                  'work_on_loading',
                  consumptionFormGroup.controls.fuel.value?.name
                )
              "
              estimatorDecimals
            ></p-inputNumber>
            <label
              *ngIf="
                isShowFuels(consumptionFormGroup) &&
                consumptionFormGroup.controls.fuel.value?.name !== FuelTypes.LSMGO
              "
              >{{ isTanker ? 'Discharge' : 'Work' }}</label
            >
            <label *ngIf="consumptionFormGroup.controls.fuel.value?.name === FuelTypes.LSMGO"
              >+AE work</label
            >
          </span>
        </form>
      </ng-container>
    </div>
    <div class="col-2 p-0 flex flex-column gap-2 align-self-end">
      <label
        class="small-label consumption-label"
        *ngIf="
          useUlsfo &&
          (!vesselFormGroup.value.scrubber || highPurityFuelNameFromFirstEvent === FuelTypes.VLSFO)
        "
        >{{ FuelTypes.ULSFO }}</label
      >
      <label
        class="small-label consumption-label"
        *ngIf="
          !vesselFormGroup.value.scrubber || highPurityFuelNameFromFirstEvent === FuelTypes.VLSFO
        "
        >{{ FuelTypes.VLSFO }}
      </label>
      <label class="small-label consumption-label" *ngIf="vesselFormGroup.value.scrubber">
        {{ FuelTypes.HSFO }}</label
      >
      <label class="small-label consumption-label">{{ FuelTypes.LSMGO }}</label>
    </div>
    <div class="col-5 p-0 flex flex-column gap-2">
      <label class="small-label bunker-price-label pb-1"
        ><a
          *ngIf="isXBPIFuelAccess"
          class="small-label bunker-price-label text-primary"
          (click)="onOpenXBPIModal()"
          >Bunker price</a
        ><ng-container *ngIf="!isXBPIFuelAccess">Bunker price</ng-container></label
      >
      <!-- <div class="flex gap-2 relative" *ngIf="vesselFormGroup.controls.scrubber?.value">
        <label class="small-label bunker-price-label pb-1">Scrubber in ECA</label>
        <estimator-button-switch
          class="absolute scrubber-fuel"
          [options]="scrubberFuels"
          [tabindex]="tabIndex"
          [value]="highPurityFuelNameFromFirstEvent"
          id="high_purity_fuel_name"
          (onChange)="onHighPurityFuelNameChange($event)"
          optionLabel="short_name"
          optionValue="value"
        >
        </estimator-button-switch>
      </div> -->
      <estimator-price-and-currency-input
        class="flex w-6 padding-right-125"
        *ngIf="
          useUlsfo &&
          (!vesselFormGroup.value.scrubber || highPurityFuelNameFromFirstEvent === FuelTypes.VLSFO)
        "
        [currencies]="currencies"
        [price]="fuelPriceFormGroup.controls.ULSFO.value"
        [priceFormControl]="fuelPriceFormGroup.controls.ULSFO"
        [id]="'vessel-full_preset-price-ulsfo'"
        [customTabIndex]="tabIndex"
        [showCurrency]="false"
        [disabled]="isFixed"
        [invalidName]="getInvalidName(FuelTypes.ULSFO)"
        [max]="MAX_FINANCE_VALUE"
        (changeValidationErrors)="onChangeValidationErrors($event)"
      ></estimator-price-and-currency-input>
      <div
        class="flex gap-1"
        *ngIf="
          !vesselFormGroup.value.scrubber || highPurityFuelNameFromFirstEvent === FuelTypes.VLSFO
        "
      >
        <estimator-price-and-currency-input
          class="flex-1"
          [currencies]="currencies"
          [price]="fuelPriceFormGroup.controls.VLSFO.value"
          [priceFormControl]="fuelPriceFormGroup.controls.VLSFO"
          [id]="'vessel-full_preset-price-vlsfo'"
          [customTabIndex]="tabIndex"
          [showCurrency]="false"
          [disabled]="isFixed"
          [invalidName]="getInvalidName(FuelTypes.VLSFO)"
          [max]="MAX_FINANCE_VALUE"
          (changeValidationErrors)="onChangeValidationErrors($event)"
        ></estimator-price-and-currency-input>
        <div
          class="flex align-items-center flex-1"
          pTooltip="Instead of MGO in (S)ECA"
          tooltipPosition="top"
          [style.visibility]="vesselFormGroup.controls.scrubber?.value ? 'hidden' : 'inherit'"
        >
          <p-checkbox
            class="small-checkbox mr-1"
            [(ngModel)]="useUlsfo"
            [ngModelOptions]="{ standalone: true }"
            (onChange)="onChangeUseULSFO($event)"
            [binary]="true"
            [tabindex]="tabIndexIncrease"
            [disabled]="isFixed"
            inputId="vessel-use-ulsfo"
          ></p-checkbox>
          <label
            class="small-label white-space-nowrap"
            for="vessel-use-ulsfo"
            id="vessel_use_ulsfo_label"
            >Use ULSFO</label
          >
        </div>
      </div>
      <estimator-price-and-currency-input
        class="flex w-6 padding-right-125"
        *ngIf="vesselFormGroup.value.scrubber"
        [currencies]="currencies"
        [price]="fuelPriceFormGroup.controls.HSFO.value"
        [priceFormControl]="fuelPriceFormGroup.controls.HSFO"
        [id]="'vessel-full_preset-price-hsfo'"
        [customTabIndex]="tabIndex"
        [showCurrency]="false"
        [disabled]="isFixed"
        [invalidName]="getInvalidName(FuelTypes.HSFO)"
        [max]="MAX_FINANCE_VALUE"
        (changeValidationErrors)="onChangeValidationErrors($event)"
      ></estimator-price-and-currency-input>
      <div class="flex gap-1">
        <estimator-price-and-currency-input
          class="flex-1"
          [currencies]="currencies"
          [price]="fuelPriceFormGroup.controls.LSMGO.value"
          [priceFormControl]="fuelPriceFormGroup.controls.LSMGO"
          [id]="'vessel-full_preset-price-lsmgo'"
          [customTabIndex]="tabIndex"
          [showCurrency]="false"
          [disabled]="isFixed"
          [invalidName]="getInvalidName(FuelTypes.LSMGO)"
          [max]="MAX_FINANCE_VALUE"
          (changeValidationErrors)="onChangeValidationErrors($event)"
        ></estimator-price-and-currency-input>
        <estimator-price-and-currency-input
          class="flex-1"
          [currencies]="currencies"
          [price]="fgCO2.controls.co2_price.value"
          [priceFormControl]="fgCO2.controls.co2_price"
          [id]="'vessel-co2_price'"
          [customTabIndex]="tabIndexIncrease"
          [showCurrency]="false"
          [priceLabel]="'Carbon price'"
          [setNull]="true"
          [disabled]="isFixed"
          [max]="MAX_FINANCE_VALUE"
        ></estimator-price-and-currency-input>
      </div>
    </div>
  </section>
  <hr class="hr-line m-0 w-full -mt-2" />
  <section>
    <form [formGroup]="hireFinanceFormGroup" class="flex gap-1 justify-content-between">
      <div class="col-5 p-0 flex gap-1">
        <span class="p-float-label" #hireRate>
          <estimator-price-and-currency-input
            id="hire-finance-hire_per_day"
            [price]="hireFinanceFormGroup.controls.hire_per_day.value"
            [priceFormControl]="hireFinanceFormGroup.controls.hire_per_day"
            [customTabIndex]="tabIndexIncrease"
            [showCurrency]="false"
            class="p-inputtext-sm"
            priceLabel="Hire rate"
            [setNull]="true"
            [disabled]="isFixed"
            [invalidName]="getInvalidName(getInvalidNameHire())"
            [max]="MAX_FINANCE_VALUE"
            (changeValidationErrors)="onChangeValidationErrors($event)"
          ></estimator-price-and-currency-input>
        </span>
        <span class="p-float-label" [ngClass]="{ 'show-invalid': true }">
          <p-inputNumber
            [useGrouping]="false"
            [minFractionDigits]="0"
            [maxFractionDigits]="2"
            [min]="0"
            [max]="100"
            [step]="0"
            [tabindex]="tabIndexIncrease"
            formControlName="time_charter_commission"
            inputStyleClass="w-full"
            class="p-inputtext-sm w-full total-commission-input"
            inputId="hire-finance-time_charter_commission"
            estimatorDecimals
          ></p-inputNumber>
          <label htmlFor="hire-finance-time_charter_commission">TC comm, %</label>
        </span>
      </div>
      <div class="col-5 flex p-0 button-addon">
        <span class="p-float-label w-6">
          <estimator-price-and-currency-input
            id="hire-finance-ballast_bonus"
            [price]="hireFinanceFormGroup.controls.ballast_bonus.value"
            [priceFormControl]="hireFinanceFormGroup.controls.ballast_bonus"
            [customTabIndex]="tabIndexIncrease"
            [showCurrency]="false"
            [disabled]="isFixed"
            [max]="MAX_FINANCE_VALUE"
            inputStyleClass="w-full"
            class="p-inputtext-sm"
            priceLabel="Ballast bonus"
          ></estimator-price-and-currency-input>
        </span>
        <span class="p-float-label w-6">
          <!-- <p-dropdown
            [options]="ballastBonusTypes"
            [formControl]="hireFinanceFormGroup.controls.commission_affects_bb"
            appendTo="body"
            optionLabel="label"
            optionValue="value"
            placeholder="Ballast bonus type"
            class="p-inputtext-sm hide-dropdown-button w-full none-border-radius-left"
            styleClass="w-full"
            inputId="hire-finance-commission-affects-bb"
            [tabindex]="tabIndexIncrease"
          >
          </p-dropdown> -->
          <estimator-button-switch
            [disabled]="isFixed"
            [options]="ballastBonusTypes"
            [tabindex]="tabIndexIncrease"
            [value]="hireFinanceFormGroup.controls.commission_affects_bb.value?.toString()"
            id="hire-finance-commission-affects-bb"
            (onChange)="onCommissionAffectsBBChange($event)"
            styleClass="w-full"
            optionLabel="short_name"
            optionValue="value"
          >
          </estimator-button-switch>
        </span>
      </div>
    </form>
  </section>
</form>
