<div>
  <p-autoComplete
    [formControl]="portHistoryControl"
    [suggestions]="filteredHistoryPorts"
    [showEmptyMessage]="true"
    (completeMethod)="onSearchHistoryPort($event)"
    (onSelect)="onSelectHistoryPort($event)"
    emptyMessage="No ports found"
    appendTo="body"
    optionLabel="name"
    dataKey="id"
    class="p-inputtext-sm xbpi-port"
    inputStyleClass="history-port-input font-bold"
    placeholder="Port"
    inputId="xbpi_table_history_port"
    #autoCompletePort
  >
  </p-autoComplete>
  <p-table
    [value]="xbpiParsedData"
    dataKey="id"
    class="primeng-form"
    styleClass="p-datatable-sm font-size-12 p-datatable-striped"
  >
    <ng-template pTemplate="header">
      <tr>
        <!-- for padding-left -->
        <!--  <th></th> -->
        <th class="font-bold">Updated</th>
        <th class="font-bold">{{ FuelTypes.HSFO }}</th>
        <th class="font-bold">{{ FuelTypes.VLSFO }}</th>
        <th class="font-bold">{{ FuelTypes.ULSFO }}</th>
        <th class="font-bold">{{ FuelTypes.LSMGO }}</th>
        <th class="font-bold w-4rem">Action</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-xbpi>
      <tr>
        <!-- for padding-left -->
        <!--   <td></td> -->
        <td class="text-left">{{ localDateTimeFormat(xbpi.date, false) }}</td>
        <td class="text-left">{{ getFuel(xbpi.data, FuelTypes.HSFO)?.price }}</td>
        <td class="text-left">{{ getFuel(xbpi.data, FuelTypes.VLSFO)?.price }}</td>
        <td class="text-left">{{ getFuel(xbpi.data, FuelTypes.ULSFO)?.price }}</td>
        <td class="text-left">{{ getFuel(xbpi.data, FuelTypes.LSMGO)?.price }}</td>
        <td class="text-left">
          <span
            class="material-icons cursor-pointer"
            [ngClass]="{ 'pointer-events-none': isFixed }"
            (click)="
              onSaveFuelPrices(
                getFuel(xbpi.data, FuelTypes.VLSFO),
                getFuel(xbpi.data, FuelTypes.ULSFO),
                getFuel(xbpi.data, FuelTypes.LSMGO),
                getFuel(xbpi.data, FuelTypes.HSFO)
              )
            "
            >input</span
          >
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="6">No data found.</td>
      </tr>
    </ng-template>
  </p-table>
</div>
