import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'estimator-ag-grid-event-details',
  template: `
    <div class="event-details-renderer">
      <div
        class="event-details-renderer-row"
        [ngClass]="{ 'h-50': canalsOnRoute?.length }"
        *ngIf="total_load || total_discharge || total_bunker; else emptyTemplate"
      >
        <div class="details-element" *ngIf="total_load">
          <mat-icon class="load material-icons-outlined">file_download</mat-icon
          ><span>{{ total_load | number }}</span>
        </div>
        <div class="details-element" *ngIf="total_discharge">
          <mat-icon class="discharge material-icons-outlined">file_upload</mat-icon
          ><span>{{ total_discharge | number }}</span>
        </div>
        <div class="details-element" *ngIf="total_bunker">
          <mat-icon class="bunker material-icons-outlined">local_gas_station</mat-icon
          ><span>{{ total_bunker | number }}</span>
        </div>
      </div>
      <ng-template #emptyTemplate>
        <div class="event-details-renderer-row" [ngClass]="{ 'h-50': canalsOnRoute?.length }">
          No operations
        </div>
      </ng-template>
      <!-- <div class="event-details-renderer-row canals-row" *ngIf="canalsOnRoute?.length">
        Pass:<span *ngFor="let canal of canalsOnRoute; let i = index" class="canal-name"
          >{{ canal
          }}<ng-template *ngIf="i !== (canalsOnRoute?.length || 0) - 1">,</ng-template></span
        >
      </div> -->
    </div>
  `,
  styleUrls: ['./ag-grid-event-details.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgGridEventDetailsComponent implements ICellRendererAngularComp {
  total_bunker?: number;
  total_discharge?: number;
  total_load?: number;
  canalsOnRoute?: string[];
  get canals(): string {
    if (this.canalsOnRoute?.length) {
      return `Canals: ${this.canalsOnRoute?.join(', ')}`;
    }
    return '';
  }
  agInit(params: any): void {
    if (params.total_bunker) {
      this.total_bunker = params.total_bunker;
    }
    if (params.total_discharge) {
      this.total_discharge = params.total_discharge;
    }
    if (params.total_load) {
      this.total_load = params.total_load;
    }
    if (params.canalsOnRoute) {
      this.canalsOnRoute = params.canalsOnRoute;
    }
  }
  refresh(/* params: ICellRendererParams<any, any> */): boolean {
    return false;
  }
}
