<p-tabView [(activeIndex)]="activeIndex">
  <p-tabPanel>
    <ng-template pTemplate="header">
      <div class="nav-link">
        <i class="pi pi-sign-in"></i>
        <span>{{ SIGN_IN }}</span>
      </div>
    </ng-template>
    <div *ngIf="signInError" class="p-fluid mb-5">
      <p-message [severity]="NotificationType.Error" [text]="signInError"></p-message>
    </div>
    <estimator-login-form
      [isLoading]="isLoading"
      (signIn)="onSignIn($event)"
      (forgotPassword)="onForgotPassword()"
      class="pt-10"
    ></estimator-login-form>
  </p-tabPanel>
  <p-tabPanel>
    <ng-template pTemplate="header">
      <div class="nav-link">
        <i class="pi pi-user-plus"></i>
        <span>{{ SIGN_UP }}</span>
      </div>
    </ng-template>
    <div *ngIf="signUpError" class="p-fluid mb-5">
      <p-message [severity]="NotificationType.Error" [text]="signUpError"></p-message>
    </div>
    <estimator-sign-up-form
      [isLoading]="isLoading"
      [subscriptionModels]="subscriptionModels"
      [presetProduct]="presetProduct || null"
      (signUp)="onSignUp($event)"
      class="pt-10"
    ></estimator-sign-up-form>
  </p-tabPanel>
</p-tabView>
