import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'estimator-switch-editor',
  template: `
    <p-inputSwitch
      [disabled]="disabled"
      [(ngModel)]="value"
      (onChange)="onChange()"
    ></p-inputSwitch>
  `,
  styles: [
    `
      ::ng-deep .p-inputswitch-slider {
        height: 112%;
        width: 102%;
      }
    `,
  ],
})
export class AgGridSwitchEditorComponent implements ICellEditorAngularComp {
  @Output() changeValue = new EventEmitter<boolean>();
  @Input() value = false;
  @Input()
  set disabled(boolean: boolean) {
    this._disabled = boolean;
  }
  get disabled(): boolean {
    return this._disabled;
  }
  private _params: any;
  private _disabled = false;

  agInit(params: any): void {
    this._params = params;
    if (params.value) {
      this.value = params.value;
    }
    if (params.closeEditor) {
      this.closeEditor = params.closeEditor;
    }
    if (params.disabled) {
      this.disabled = params.disabled;
    }
  }

  afterGuiAttached() {
    this.value = !this.value;
    this.closeEditor();
  }

  getValue(): boolean {
    return this.value;
  }

  isPopup(): boolean {
    return true;
  }

  // методы свои, не из ICellEditorAngularComp
  onChange() {
    this.closeEditor();
    this.changeValue.emit(this.value);
  }

  closeEditor(): void {
    return;
  }
}
