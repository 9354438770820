import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  getDatePipeTimeZoneFormat,
  getLocalTimeZoneOffsetInHours,
  isLocalTimeSetting,
} from '@estimator/helpers';
import { DATE_INPUT_TIME_FORMAT, UTC_TIME_FORMAT, UserTimeSettings } from '@estimator/models';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import moment from 'moment';

@Component({
  selector: 'estimator-datetime-editor',
  template: `
    <input
      matInput
      [(ngModel)]="value"
      type="datetime-local"
      #inputDate
      class="aggrid-datetime-editor"
    />
  `,
  styles: [
    `
      .aggrid-datetime-editor {
        width: 100%;
        height: 100%;
        border: none;
        background: none;
        font-size: 12px;
        font-family: 'Rubik', sans-serif;
        letter-spacing: normal;
      }
      .cdk-overlay-pane {
        min-width: 0;
      }
    `,
  ],
})
export class AgGridDateTimeEditorComponent implements ICellEditorAngularComp, AfterViewInit {
  @ViewChild('inputDate') dateInput!: any;
  value?: string;
  timeSettings: UserTimeSettings = UserTimeSettings.PortLocalTime;
  timeZoneOffset = 0;
  formControl = new FormControl(new Date());
  private _params: any;

  constructor(private readonly datePipe: DatePipe) {}

  ngAfterViewInit() {
    if (this.dateInput) {
      this.dateInput.nativeElement.click();
    }
  }

  agInit(params: any): void {
    this._params = params;
    if (params.timeSettings) {
      this.timeSettings = params.timeSettings;
    }
    if (params.timeZoneOffset) {
      this.timeZoneOffset = params.timeZoneOffset;
    }
    if (params.value) {
      if (isLocalTimeSetting(this.timeSettings)) {
        if (this.timeSettings === UserTimeSettings.LocalTime) {
          this.timeZoneOffset = getLocalTimeZoneOffsetInHours();
        }
        this.value =
          this.datePipe.transform(
            moment(params.value).toISOString(),
            DATE_INPUT_TIME_FORMAT,
            getDatePipeTimeZoneFormat(this.timeSettings, this.timeZoneOffset)
          ) || '';
      } else {
        this.value =
          this.datePipe.transform(
            moment(params.value).toISOString(),
            DATE_INPUT_TIME_FORMAT,
            UTC_TIME_FORMAT
          ) || '';
      }
    }
  }

  getValue(): any {
    let result;
    if (this.value) {
      result = moment.utc(this.value);
      if (isLocalTimeSetting(this.timeSettings)) {
        result.add(-this.timeZoneOffset, 'hours');
      }
      if (result) {
        return result.toISOString().replace('.000', '');
      }
    }
  }
}
