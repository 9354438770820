import {
  AddVesselToMyOrCompanyFleetProps,
  FleetVessel,
  FleetVesselActiveRequest,
  FleetVesselCollection,
  FleetVesselPositions,
} from '@estimator/models';

export class GetMyFleetVesselAndCollections {
  static readonly type = '[FLEET VESSEL] Get my fleet vessel';
}

export class GetMyCompanyFleetVesselAndCollections {
  static readonly type = '[FLEET VESSEL] Get my compnany fleet vessel';
}

export class AddVesselToMyFleetOrCompanyFleet {
  static readonly type = '[FLEET VESSEL] Add vessel to my fleet or company fleet';
  constructor(public props: AddVesselToMyOrCompanyFleetProps) {}
}

export class UpdateFleetVessel {
  static readonly type = '[FLEET VESSEL] Update fleet vessel';
  constructor(public vessel: FleetVessel) {}
}

export class UpdateFleetVesselActive {
  static readonly type = '[FLEET VESSEL] Update fleet vessel active';
  constructor(public activeRequest: FleetVesselActiveRequest, public vesselId: number) {}
}

export class DeleteFleetVessel {
  static readonly type = '[FLEET VESSEL] Delete fleet vessel';
  constructor(
    public id: number,
    public general_vessel_id: number,
    public isDeleteCompanyVessel = true
  ) {}
}

export class GetFleetVesselPositions {
  static readonly type = '[FLEET VESSEL] Get fleet vessel positions';
  constructor(public props: FleetVesselPositions) {}
}

// crud коллекции
export class CreateFleetVesselCollection {
  static readonly type = '[FLEET VESSEL] Create fleet vessel collection';
  constructor(public collection: FleetVesselCollection) {}
}

export class UpdateFleetVesselCollection {
  static readonly type = '[FLEET VESSEL] Update fleet vessel collection';
  constructor(public collection: FleetVesselCollection) {}
}

export class DeleteFleetVesselCollection {
  static readonly type = '[FLEET VESSEL] Delete fleet vessel collection';
  constructor(public id: number, public isDeleteCompanyCollection = true) {}
}
