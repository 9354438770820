import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AllUsersByCompanyIDResponse,
  Company,
  CompanySearchRequest,
  CompanySearchResponse,
} from '@estimator/models';
import { Observable } from 'rxjs';
import { BaseService } from '../base';

@Injectable({
  providedIn: 'root',
})
export class CompanyService extends BaseService<Company> {
  constructor(protected override readonly http: HttpClient) {
    super(http);
    this.setPath('/api/companies');
  }

  findCompany(request: CompanySearchRequest): Observable<CompanySearchResponse> {
    const { value, limit, offset } = request;
    let params = new HttpParams();
    params = params.set('value', value);
    if (limit) {
      params = params.set('limit', limit);
    }
    if (offset) {
      params = params.set('offset', offset);
    }
    return this.http.get<CompanySearchResponse>(`${this.path}/find`, { params });
  }

  getUsersByCompanyId(companyId: number): Observable<AllUsersByCompanyIDResponse> {
    return this.http.get<AllUsersByCompanyIDResponse>(`${this.path}/${companyId}/users`);
  }
}
