import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  canOpenOrCreateDeal,
  checkLinkTanker,
  initCascadeNavigation,
  isArchivedText,
  prepareCascadeNavigation,
  textCompanyIdField,
  textCreatedBy,
  textFilterParams,
  textNameDeal,
  textVesselName,
  textVoyageType,
  twoMenuTabs,
} from '@estimator/helpers';
import {
  ButtonParams,
  CascadeNavigation,
  Company,
  DETAIL_ID_START_DRAG,
  DRAFT_FOLDER_ID,
  DRAG_DROP_IN_DEALS_LIST,
  DROP_EFFECT_COPY,
  Deal,
  DealNavigationLink,
  ESTIMATOR_ROUTE,
  ESTIMATOR_TANKER_ROUTE,
  FOLDERS_FILTER_MODEL_KEY_BULK,
  FOLDERS_FILTER_MODEL_KEY_TANKER,
  Folder,
  LIMIT_SERVER_SIDE,
  OpenAllDeals,
  OpenDealAndNavigate,
  PrimeNgColors,
  RequestChangeFolder,
  RequestDataSourceDeals,
  RequestDeleteDeal,
  RequestUpdateFolder,
  STANDART_TIME_FORMAT,
  VoyageType,
  ZERO_STRING,
  itemMap,
} from '@estimator/models';
import {
  ColDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ICellRendererParams,
  IServerSideDatasource,
  RowNode,
  RowSelectedEvent,
  SetFilterValuesFuncParams,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community';
import { cloneDeep } from 'lodash';
import { AgGridButtonGroupComponent } from '../ag-grid-shared/button-group.component';
import { DealsListComponent } from '../deals-list/deals-list.component';

@Component({
  selector: 'estimator-folders-list',
  templateUrl: './folders-list.component.html',
  styleUrls: ['./folders-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FoldersListComponent {
  @Input() getDataSource?: IServerSideDatasource;
  @Input() getDataSourceDeals?: IServerSideDatasource;
  @Input()
  set folders(data: Deal[]) {
    if (data && data.length) {
      this._folders = cloneDeep(data);
    }
  }
  get folders(): Deal[] {
    return this._folders;
  }
  @Input()
  set deals(data: Deal[]) {
    if (data && data.length) {
      this._deals = data;
    }
  }
  get deals(): Deal[] {
    return this._deals;
  }
  @Input()
  set companies(data: Company[]) {
    this._companies = data;
  }
  get companies(): Company[] {
    return this._companies;
  }
  @Input() usersInMyCompanyMap?: itemMap;
  @Input()
  set isLoading(bool: boolean) {
    this._isLoading = bool;
    if (this._gridApi && bool) {
      // this._gridApi.showLoadingOverlay();
    } else if (this._gridApi && !bool) {
      // this._gridApi.hideOverlay();
    }
  }
  get isLoading(): boolean {
    return this._isLoading;
  }
  @Input() activeLink?: string;
  @Input() estimationsNavigationLinks: DealNavigationLink[] = [];
  @Input() userId?: number;
  @Output() addDeal = new EventEmitter<number>();
  @Output() openDeal = new EventEmitter<OpenDealAndNavigate>();
  @Output() updateFolderAtDeal = new EventEmitter<RequestChangeFolder>();
  @Output() deleteDeal = new EventEmitter<RequestDeleteDeal>();
  @Output() deleteFolder = new EventEmitter<number>();
  @Output() updateFolder = new EventEmitter<RequestUpdateFolder>();
  @Output() showNotificationNoDeleteFolder = new EventEmitter();
  @Output() showNotificationFolderExist = new EventEmitter();
  @Output() showNotificationDropOnFolderName = new EventEmitter();
  @Output() showNotificationCloseFolder = new EventEmitter();
  @Output() closeDialog = new EventEmitter();
  // @Output() openAllDealsNotOpen = new EventEmitter();
  @Output() openAllDeals = new EventEmitter<OpenAllDeals>();
  @Output() requestDataSourceDeals = new EventEmitter<RequestDataSourceDeals>();
  @Output() setNavigation = new EventEmitter<CascadeNavigation[]>();
  // @Output() findCompany = new EventEmitter<RequestCompanyGrid>();
  dateValueFormatter = (params: ValueFormatterParams) => {
    const folder: Folder = params.data;
    if (params.value && this.notDraftFolder(folder)) {
      return this.datePipe.transform(params.value, STANDART_TIME_FORMAT) || '';
    }
    return '';
  };
  columnDefs: ColDef<Folder>[] = [
    {
      cellRenderer: 'buttonRenderer',
      cellRendererParams: (params: any) => {
        const folder: Folder = params.data;
        const buttons: ButtonParams[] = [];
        if (folder?.total_deals_amount === 0 && this.notDraftFolder(folder)) {
          buttons.push({
            title: 'Delete folder',
            tooltip: 'Delete folder',
            icon: 'delete',
            click: () => this.onDeleteFolder(folder),
          });
        }
        if (this.notDraftFolder(folder)) {
          buttons.push({
            title: 'Open all deals',
            tooltip: 'Open all deals',
            icon: 'list',
            click: () =>
              this.onOpenAllDeals(params.data.id, params.data.total_deals_amount, params.data),
          });
        }
        return {
          buttons,
        };
      },
      cellClass: 'center-aligned-cell',
      width: 100,
      field: isArchivedText,
      headerName: 'Status',
      flex: 1,
      suppressMenu: false,
      filter: false, // 'agSetColumnFilter',
      /*  floatingFilter: true,
      filterParams: {
        refreshValuesOnOpen: true,
        values: (params: SetFilterValuesFuncParams) => {
          params.success([trueText, falseText].map(String));
        },
        valueFormatter: ({ value }: ValueFormatterParams) =>
          value === trueText ? archivedStatusName : activeStatusName,
      }, */
    },
    {
      field: 'agGroupCellRenderer',
      headerName: '',
      valueGetter: (/* params: ValueGetterParams */) => {
        return '';
      },
      cellRendererSelector: ({ data }: ICellRendererParams) => {
        const folder: Folder = data;
        if (folder?.total_deals_amount === 0) {
          return;
        }
        return { component: 'agGroupCellRenderer' };
      },
      maxWidth: 50,
      minWidth: 50,
    },
    {
      field: 'name',
      headerName: 'Folder',
      editable: ({ data }) => {
        const folder = data as Folder;
        return this.notDraftFolder(folder);
      },
      flex: 1,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      valueGetter: (params: ValueGetterParams) => {
        return params.data?.name || '';
      },
      sortable: true,
      onCellValueChanged: (event) => {
        if (
          event.newValue &&
          event.newValue !== event.oldValue &&
          !this.folders.some((folder) => folder.name === event.newValue)
        ) {
          this.updateFolder.emit({ folder: event.data, oldValue: event.oldValue });
        } else {
          this.showNotificationFolderExist.emit();
          this.revertNameRowData(event.data.id as number, event.oldValue);
        }
      },
    },
    {
      field: 'total_deals_amount',
      headerName: 'Total deals',
      flex: 1,
      filter: false,
    },
    {
      field: textNameDeal,
      headerName: 'File',
      flex: 1,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      filterParams: textFilterParams,
      valueFormatter: (/* params */) => {
        return '';
      },
    },
    {
      field: textVesselName,
      headerName: 'Vessel',
      flex: 1,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      filterParams: textFilterParams,
    },
    {
      field: textCompanyIdField,
      headerName: 'Client',
      flex: 1,
      /* filter: 'companyFilterComponent',
      filterParams: {
        onSearchCompany: this.onSearchCompanyUpMaster.bind(this),
      }, */
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      filterParams: textFilterParams,
      valueFormatter: (/* params */) => {
        return '';
      },
    },
    {
      field: 'created_at',
      headerName: 'Created at',
      flex: 1,
      valueFormatter: this.dateValueFormatter,
      /* filter: 'agDateColumnFilter',
      filterParams: {
        comparator: agGridMomentDateComparator,
      }, */
      filter: false,
      sortable: true,
    },
    {
      field: 'updated_at',
      headerName: 'Updated at',
      flex: 1,
      valueFormatter: this.dateValueFormatter,
      /* filter: 'agDateColumnFilter',
      filterParams: {
        comparator: agGridMomentDateComparator,
      }, */
      filter: false,
      sortable: true,
    },
    {
      field: textCreatedBy,
      headerName: 'Created by',
      flex: 1,
      valueFormatter: ({ data }: ValueFormatterParams) => {
        const folder: Folder = data;
        if (this.notDraftFolder(folder)) {
          return this.usersInMyCompanyMap?.get(folder?.created_by as number) ?? 'User not exist';
        }
        return '';
      },
      filter: 'agSetColumnFilter',
      floatingFilter: true,
      filterParams: {
        defaultToNothingSelected: true,
        refreshValuesOnOpen: true,
        values: (params: SetFilterValuesFuncParams) => {
          params.success(
            (this.usersInMyCompanyMap ? Array.from(this.usersInMyCompanyMap.keys()) : []).map(
              String
            )
          );
        },
        valueFormatter: ({ value }: ValueFormatterParams) =>
          this.usersInMyCompanyMap?.get(+value) ?? '',
      },
      sortable: true,
    },
    // колонка для фильтрации по этому типу, чтоб попадала в filterModel, скрыта
    {
      field: textVoyageType,
      hide: true,
      filter: 'agSetColumnFilter',
      filterParams: {
        values: (params: SetFilterValuesFuncParams) => {
          params.success([VoyageType.Bulk, VoyageType.Tanker].map(String));
        },
      },
    },
  ];
  gridOptions: GridOptions<Folder> = {
    rowModelType: 'serverSide',
    serverSideInfiniteScroll: true,
    suppressRowHoverHighlight: true,
    suppressRowClickSelection: true,
    columnDefs: this.columnDefs,
    components: {
      buttonRenderer: AgGridButtonGroupComponent,
    },
    getRowId: (params) => params.data.id?.toString() || ZERO_STRING,
    onRowDoubleClicked: (event: RowSelectedEvent<Folder>) => {
      if (event.data) {
        const folder: Folder = event.data;
        const folderId = folder.id as number;
        const node = this.getRowNode(folderId);
        if (node.expanded) {
          this.expandOrCollapse(folderId, false);
        } else {
          this.expandOrCollapse(folderId);
        }
      }
    },
    defaultColDef: {
      editable: false,
      sortable: false,
      resizable: true,
      suppressMenu: true,
      menuTabs: twoMenuTabs,
    },
    masterDetail: true,
    getRowHeight: (params) => {
      const isDetailRow = params.node?.detail;
      if (!isDetailRow) {
        return undefined;
      }
      const increaseHeight = 50;
      const maxHeight = 600;
      const detailPanelHeight =
        (params?.data?.total_deals_amount || 1) * increaseHeight + increaseHeight;
      return detailPanelHeight > maxHeight ? maxHeight : detailPanelHeight;
    },
    floatingFiltersHeight: 40,
    detailCellRenderer: DealsListComponent,
    detailCellRendererParams: (params: any) => {
      const dataRow: Folder = params.data;
      return {
        ...params,
        serverSide: true,
        requestFolderId: dataRow.id,
        onAddDeal: this.onAddDeal.bind(this),
        onOpenDeal: this.onOpenDeal.bind(this),
        onDeleteDeal: this.onDeleteDeal.bind(this),
        onRequestDataSource: this.onRequestDataSource.bind(this),
        onChangeFolder: this.onChangeFolder.bind(this),
        // onSearchCompanyUp: this.onSearchCompanyUp.bind(this),
      };
    },
    suppressContextMenu: true,
    suppressScrollOnNewData: true,
    singleClickEdit: true,
    rowSelection: 'single',
    animateRows: true,
    rowHeight: 40,
    headerHeight: 40,
    autoSizePadding: 0,
    getRowStyle: (/* params: RowClassParams */) => {
      return { background: PrimeNgColors.LightGrey };
    },
  };
  dragFolderName?: string;
  private readonly _detailText = 'detail_';
  private _isLoading = false;
  private _gridApi?: GridApi<Folder>;
  private _folders: Folder[] = [];
  private _deals: Deal[] = [];
  private _companies: Company[] = [];

  constructor(private readonly datePipe: DatePipe) {}

  get neededLink() {
    return checkLinkTanker(this.activeLink as string) ? ESTIMATOR_TANKER_ROUTE : ESTIMATOR_ROUTE;
  }

  get neededLocalKey() {
    return checkLinkTanker(this.activeLink as string)
      ? FOLDERS_FILTER_MODEL_KEY_TANKER
      : FOLDERS_FILTER_MODEL_KEY_BULK;
  }

  onGridReady({ api }: GridReadyEvent): void {
    this._gridApi = api;
    if (localStorage.getItem(this.neededLocalKey)) {
      this._gridApi?.setFilterModel(
        JSON.parse(localStorage.getItem(this.neededLocalKey) as string)
      );
    }
    const datasource = this.getDataSource;
    api.setServerSideDatasource(datasource as IServerSideDatasource);
    api.closeToolPanel();
  }

  onAddDeal(folderId: number): void {
    this.addDeal.emit(folderId);
  }

  onOpenDeal(deal: Deal): void {
    this.openDeal.emit({
      deal: deal,
      navigate: true,
      notCheckCanOpen: deal.created_by !== this.userId,
    });
  }

  onDeleteDeal(requestDeleteDeal: RequestDeleteDeal): void {
    if (requestDeleteDeal?.deleteId) {
      this.deleteDeal.emit(requestDeleteDeal);
    }
  }

  onDeleteFolder(folder: Folder): void {
    if (folder.id && !folder.total_deals_amount) {
      this.deleteFolder.emit(folder.id);
    } else {
      this.showNotificationNoDeleteFolder.emit();
    }
  }

  onRequestDataSource(object: RequestDataSourceDeals) {
    this.requestDataSourceDeals.emit(object);
    this.setRowDataToDetailGrid(object.rowId);
  }

  onChangeFolder(object: RequestChangeFolder) {
    this.updateFolderAtDeal.emit(object);
  }

  /*   onSearchCompanyUp(value: string, rowId: string) {
    this.findCompany.emit({ value: value, rowId: rowId });
  } */

  /*   onSearchCompanyUpMaster(value: string, rowId: string) {
    this.findCompany.emit({ value: value, rowId: rowId, parentGrid: true });
  } */

  refreshServerSide() {
    this._gridApi?.refreshServerSide();
  }

  refreshServerSideDetailGrid(rowId: string, force: boolean, requestFolderId: number) {
    const rowNode = this.getRowNode(requestFolderId as number);
    if (rowNode?.expanded || force) {
      this._gridApi?.getDetailGridInfo(rowId)?.api?.refreshServerSide();
    }
  }

  expandOrCollapse(requestFolderId: number, expand = true) {
    const node = this.getRowNode(requestFolderId);
    if (node && node?.data?.total_deals_amount) {
      this._gridApi?.setRowNodeExpanded(node, expand);
    }
  }

  updateRowDataTotalDealsAmount(requestFolderId: number, decrease = true) {
    const node = this.getRowNode(requestFolderId);
    if (node.data?.total_deals_amount || node.data?.total_deals_amount === 0) {
      const decreaseOrIncrease = decrease
        ? node.data?.total_deals_amount - 1
        : node.data?.total_deals_amount + 1;
      node.data.total_deals_amount = decreaseOrIncrease;
      node.setData(node?.data as Folder);
    }
  }

  revertNameRowData(requestFolderId: number, oldValue: string) {
    const node = this.getRowNode(requestFolderId);
    if (node.data) {
      node.data.name = oldValue;
      node.setData(node?.data as Folder);
    }
  }

  setRowDataToDetailGrid(rowId: string) {
    const datasource = this.getDataSourceDeals;
    this._gridApi
      ?.getDetailGridInfo(rowId)
      ?.api?.setServerSideDatasource(datasource as IServerSideDatasource);
  }

  /*   setCompanyFilter(rowId: string) {
    const companyIdFilter = this._gridApi
      ?.getDetailGridInfo(rowId)
      ?.api?.getFilterInstance(textCompanyIdField) as AgGridCompanyFilterComponent;
    this.setValuesToAgGridCompanyFilterComponent(companyIdFilter);
  } */

  /*   setCompanyFilterMaster() {
    const companyIdFilter = this._gridApi?.getFilterInstance(
      textCompanyIdField
    ) as AgGridCompanyFilterComponent;
    this.setValuesToAgGridCompanyFilterComponent(companyIdFilter);
  } */

  /* setValuesToAgGridCompanyFilterComponent(comp: AgGridCompanyFilterComponent) {
    if (comp) {
      comp.companies = this.companies;
      comp.detectChanges();
    }
  } */

  gridDragOver(event: DragEvent) {
    const target = event.target as HTMLElement;
    this.dragFolderName = target?.textContent as string;
    const dragSupported = event.dataTransfer?.types?.length;
    if (dragSupported) {
      event.dataTransfer.dropEffect = DROP_EFFECT_COPY;
      event.preventDefault();
    }
  }

  gridDrop(event: DragEvent) {
    const dropInDealsList = localStorage.getItem(DRAG_DROP_IN_DEALS_LIST);
    if (dropInDealsList) {
      // если drop произошёл в deals-list, то выходим
      return;
    } else {
      event.preventDefault();
      const jsonData = event.dataTransfer?.getData('application/json') as string;
      const data = JSON.parse(jsonData);
      // if data missing or data has no it, do nothing
      if (!data || data.id == null) {
        return;
      }

      const dragFolderId = this.folders?.find((folder) => folder?.name === this.dragFolderName)?.id;

      const object: RequestChangeFolder = {
        deal: data,
        requestFolderId: dragFolderId as number,
        requestRowId: this._detailText + dragFolderId,
        previousFolderId: data.folder_id,
        previousRowId: localStorage.getItem(DETAIL_ID_START_DRAG) as string,
        requestFolderExpanded: false,
      };
      if (object.requestFolderId && object.previousFolderId !== object.requestFolderId) {
        this.onChangeFolder(object);
      } else {
        this.showNotificationDropOnFolderName.emit();
      }
    }
  }

  onOpenAllDeals(rowId: number, totalDealsAmount: number, folder: Folder) {
    const detailGridApi = this._gridApi?.getDetailGridInfo(this._detailText + rowId);
    const canOpen = this.canOpenOrCreateDeal(rowId);
    if (canOpen) {
      if (detailGridApi && detailGridApi?.api?.getColumnDefs()?.length) {
        const navigation: CascadeNavigation[] = [];
        initCascadeNavigation(
          navigation,
          totalDealsAmount > LIMIT_SERVER_SIDE ? LIMIT_SERVER_SIDE : totalDealsAmount
        );
        detailGridApi?.api?.forEachNode((node, index) => {
          const deal: Deal = node.data;
          prepareCascadeNavigation(index, deal, this.neededLink, navigation);
          this.openDeal.emit({ deal: deal, navigate: false });
        });
        this.setNavigation.emit(navigation);
        this.closeDialog.emit();
      } else {
        this.openAllDeals.emit({
          rowId: rowId,
          totalDealsAmount: totalDealsAmount,
        });
      }
    } else {
      // this.showNotificationCloseFolder.emit();
      this.openAllDeals.emit({
        rowId: rowId,
        totalDealsAmount: totalDealsAmount,
        folder: folder,
      });
    }
  }

  canOpenOrCreateDeal(folderId: number) {
    return canOpenOrCreateDeal(this.estimationsNavigationLinks, folderId);
  }

  getRowNode(folderId: number) {
    return this._gridApi?.getRowNode(folderId.toString()) as RowNode<Folder>;
  }

  notDraftFolder(folder: Folder) {
    return folder.id !== DRAFT_FOLDER_ID;
  }
}
