import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { localDateTimeFormat } from '@estimator/helpers';
import { BunkerSelectDialogData, DealEvent } from '@estimator/models';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'estimator-bunker-select-dialog',
  templateUrl: './bunker-select-dialog.component.html',
  styleUrl: './bunker-select-dialog.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BunkerSelectDialogComponent {
  bunkerEvents: DealEvent[] = [];
  selectedEventControl = new FormControl<DealEvent | null>(null);
  localDateTimeFormat = localDateTimeFormat;
  constructor(
    private readonly dialogConfig: DynamicDialogConfig<BunkerSelectDialogData>,
    private readonly dialogRef: DynamicDialogRef
  ) {
    if (this.dialogConfig.data?.bunkerEvents?.length) {
      this.bunkerEvents = this.dialogConfig.data.bunkerEvents;
      this.selectedEventControl.patchValue(this.bunkerEvents[0]);
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSelect(): void {
    this.dialogRef.close(this.selectedEventControl.value);
  }
}
