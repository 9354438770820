import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { CopyFolderDialogComponent } from './copy-folder-dialog.component';

@NgModule({
  declarations: [CopyFolderDialogComponent],
  imports: [CommonModule, ButtonModule, InputTextModule, ReactiveFormsModule],
  exports: [CopyFolderDialogComponent],
})
export class CopyFolderDialogModule {}
