<form class="forgot-form" [formGroup]="resetPasswordFormGroup">
  <div class="p-fluid mb-5">
    <p-message
      *ngIf="restoreSuccess"
      [severity]="NotificationType.Success"
      text="Please, check your email for letter with instructions"
    ></p-message>
    <p-message
      *ngIf="responseError"
      [severity]="NotificationType.Error"
      [text]="responseError"
    ></p-message>
  </div>
  <div class="flex-container-column gap-2">
    <div class="p-inputgroup">
      <span
        class="p-inputgroup-addon"
        [ngStyle]="{
          'background-color': isEmailControlInvalid ? PrimeNgColors.Error : PrimeNgColors.Primary,
          'border-color': isEmailControlInvalid ? PrimeNgColors.Error : PrimeNgColors.Primary
        }"
      >
        <i [class]="PrimeNgIcons.AT" [style]="{ color: PrimeNgColors.White }"></i>
      </span>
      <input
        pInputText
        id="restore-email"
        type="email"
        name="email"
        #email
        [placeholder]="DEFAULT_USER"
        formControlName="email"
        [ngClass]="{
          'ng-invalid ng-dirty': isEmailControlInvalid
        }"
        required
      />
    </div>
    <small id="restore-email-help"></small>
    <small id="email-error"
      >&nbsp;
      <span *ngIf="isEmailControlInvalid" [style]="{ color: PrimeNgColors.Error }">{{
        FormValidationErrors.EmailPattern
      }}</span>
    </small>
  </div>
  <div class="p-fluid mt-5">
    <p-button
      size="small"
      type="button"
      label="Restore password"
      (click)="onEmailSubmit()"
      [disabled]="isLoading || resetPasswordFormGroup.invalid || restoreSuccess"
      [loading]="isLoading"
    ></p-button>
  </div>
</form>
