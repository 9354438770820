import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { mglMapAccessToken } from '@estimator/models';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { CheckboxModule } from 'primeng/checkbox';
import { VesselPortSearchModule } from '../vessel-port-search/vessel-port-search.module';
import { MapComponent } from './map.component';

@NgModule({
  declarations: [MapComponent],
  imports: [
    CommonModule,
    VesselPortSearchModule,
    NgxMapboxGLModule.withConfig({
      accessToken: mglMapAccessToken,
    }),
    MatIconModule,
    CheckboxModule,
    FormsModule,
  ],
  exports: [MapComponent],
})
export class MapModule {}
