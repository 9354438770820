import { Injectable } from '@angular/core';
import { featuresToFeatureCollection, makeFeaturePort } from '@estimator/helpers';
import { Port } from '@estimator/models';
import { PortService } from '@estimator/services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Feature, FeatureCollection } from 'geojson';
import { catchError, finalize, tap, throwError } from 'rxjs';
import { GetAllPorts, GetPort, SearchPort } from './port.actions';

export interface PortStateModel {
  selectedPort?: Port;
  ports: Port[];
  searchedPorts: Port[];
  loading: boolean;
}
@State<PortStateModel>({
  name: 'port',
  defaults: {
    ports: [],
    searchedPorts: [],
    loading: false,
  },
})
@Injectable()
export class PortState {
  @Selector()
  static getIsLoading({ loading }: PortStateModel): boolean {
    return loading || false;
  }
  @Selector()
  static getPorts({ ports }: PortStateModel): Port[] {
    return ports;
  }
  @Selector()
  static getPortsPoints({ ports }: PortStateModel): FeatureCollection {
    const features: Feature[] = [];
    for (const port of ports) {
      features.push(makeFeaturePort(port));
    }
    return featuresToFeatureCollection(features);
  }
  @Selector()
  static getSearchedPorts({ searchedPorts }: PortStateModel): Port[] {
    return searchedPorts;
  }
  @Selector()
  static getSelectedPort({ selectedPort }: PortStateModel): Port | undefined {
    return selectedPort;
  }
  constructor(private readonly portService: PortService) {}
  @Action(GetAllPorts)
  getAllPorts({ getState, patchState }: StateContext<PortStateModel>) {
    const state = getState();
    if (state?.ports?.length > 0) return null;
    patchState({ loading: true });
    return this.portService.getAll().pipe(
      tap((ports) => {
        patchState({ ports });
      }),
      catchError((err) => {
        patchState({ loading: false });
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }
  @Action(GetPort)
  getPort({ patchState }: StateContext<PortStateModel>, { id }: GetPort) {
    patchState({ loading: true });
    return this.portService.get(id).pipe(
      tap((port) => {
        patchState({ selectedPort: port });
      }),
      catchError((err) => {
        patchState({ loading: false });
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }
  @Action(SearchPort, { cancelUncompleted: true })
  searchPort({ patchState }: StateContext<PortStateModel>, { request }: SearchPort) {
    patchState({ loading: true });
    return this.portService.searchPort(request).pipe(
      tap((ports) => {
        patchState({ searchedPorts: ports });
      }),
      catchError((err) => {
        patchState({ loading: false });
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }
}
