import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SimpleCardComponent } from './simple-card.component';
import { MatCardModule } from '@angular/material/card';

@NgModule({
  declarations: [SimpleCardComponent],
  imports: [CommonModule, MatCardModule],
  exports: [SimpleCardComponent],
})
export class SimpleCardModule {}
