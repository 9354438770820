import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  addPlusTimeZone,
  emptyFeatureCollection,
  getColorFromVariablesSCSS,
  getPointForMap,
  onMouseEnter,
  onMouseLeave,
} from '@estimator/helpers';
import {
  AlternativeRouteItem,
  AlternativeRouteItemSave,
  BLUE_MAP_ZONE_COLOR,
  COASTER_RESTR_CANAL_ID,
  DEFAULT_MAP_ZOOM,
  GREY_MAP_ZONE_COLOR,
  GeoZone,
  ID_USER_SETTINGS_FORM_CHANGE_THEME,
  MapFeatureProperties,
  NOT_APPLICABLE,
  PrimeNgIcons,
  ShiplistGeoZoneProperties,
  ShiplistSelectedZoneOrPort,
  ShiplistSelectedZoneOrPortEmitter,
  ShiplistZoneType,
  Themes,
  UserTimeSettings,
  mglMapCenter,
  mglMapStyleFlat,
  mglMapStyleFlatDark,
} from '@estimator/models';
import * as turf from '@turf/turf';
import { AllGeoJSON } from '@turf/turf';
import { Feature, FeatureCollection, GeoJsonProperties, Point } from 'geojson';
import {
  EventData,
  GeoJSONSource,
  LngLatLike,
  Map as MapFromMapboxgl,
  MapLayerMouseEvent,
  MapboxEvent,
  MapboxGeoJSONFeature,
} from 'mapbox-gl';
import { Subject, takeUntil } from 'rxjs';
import { Polygon } from 'typeorm';

@Component({
  selector: 'estimator-route-map',
  templateUrl: './route-map.component.html',
  styleUrls: ['./route-map.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RouteMapComponent implements OnInit, OnDestroy {
  @Input()
  set route(data: FeatureCollection | undefined) {
    this.clearAlternativeDivAndData();
    if (data) {
      this._route = data;
      if (data?.features?.length) {
        // const middleIndex = Math.round(data.features.length / 2);
        // const middleFeature = data.features[middleIndex];
        const totalCosts: number =
          data.features
            .map((feature) => {
              return feature?.properties?.['cost'];
            })
            .reduce((a, b) => a + b, 0) ?? 0;
        this.zoom = totalCosts > 4000 ? 1 : totalCosts > 1000 ? 2.5 : DEFAULT_MAP_ZOOM;
        this.map?.setZoom(this.zoom);
        if (totalCosts) {
          // const line = middleFeature.geometry as LineString;
          const getCenterRoute = turf.center(data as AllGeoJSON);
          this.center = getCenterRoute.geometry.coordinates as LngLatLike; // line.coordinates[0] as LngLatLike;
        }
      }
      this.map?.resize();
      this.cdr.detectChanges();
    }
  }
  get route(): FeatureCollection {
    return this._route;
  }
  @Input()
  set portsPoints(data: FeatureCollection | undefined) {
    if (data?.features?.length) {
      this._portsPoints = data;
      data?.features.forEach((feature, index) => {
        const image = this.createImage(index + 1);
        this.arrayImages.push(image);
      });
      if (data.features?.length === 1) {
        const geometry = data.features[0].geometry as Point;
        this.center = geometry.coordinates as LngLatLike;
      }
    } else {
      this._portsPoints = emptyFeatureCollection();
    }
  }
  get portsPoints(): FeatureCollection | undefined {
    return this._portsPoints;
  }
  @Input()
  set alternativeRoutes(data: FeatureCollection[] | undefined) {
    if (data?.length) {
      this.upLayers();
      this._alternativeRoutes = data;
    } else {
      this._alternativeRoutes = [];
    }
  }
  get alternativeRoutes(): FeatureCollection[] | undefined {
    return this._alternativeRoutes;
  }
  @Input()
  set useUlsfo(useUlsfo: boolean) {
    this._useUlsfo = useUlsfo;
    this.map?.resize();
  }
  get useUlsfo(): boolean {
    return this._useUlsfo;
  }
  @Input()
  set theme(theme: Themes) {
    const isDark = theme === Themes.DARK;
    this.changeThemeControl.patchValue(isDark ? true : false, { emitEvent: false });
    if (isDark) {
      this.style = mglMapStyleFlatDark;
    } else {
      this.style = mglMapStyleFlat;
    }
  }
  get theme(): Themes {
    return this._theme;
  }
  @Input()
  set isFixed(value: boolean) {
    this._isFixed = value;
  }
  get isFixed(): boolean {
    return this._isFixed;
  }
  @Input() ecaZones: FeatureCollection<Polygon> = emptyFeatureCollection();
  @Input() shiplistZones: FeatureCollection<Polygon> = emptyFeatureCollection();
  @Input() showSettings = true;
  @Input() selectedShiplistZoneType = ShiplistZoneType.ZONE;
  @Input() canals: GeoZone[] = [];
  @Input() isShowZoomPanel = false; // zoom panel buttons are deprecated
  @Input() showPortWidget = false;
  @Output() changeTheme = new EventEmitter<Themes>();
  @Output() selectZone = new EventEmitter<ShiplistSelectedZoneOrPortEmitter>();
  @Output() mapReady = new EventEmitter();
  @Output() showNotification = new EventEmitter();
  @Output() saveAlternativeRoute = new EventEmitter<AlternativeRouteItemSave>();
  @Output() showPortWidgetChange = new EventEmitter<boolean>();
  arrayImages: { image: HTMLImageElement; indexPort: number }[] = [];
  center: LngLatLike = mglMapCenter;
  style = mglMapStyleFlat;
  selectedPortPoint?: Feature<Point, GeoJsonProperties>;
  showPortPopup = false;
  showShiplistZoneName = false;
  topPositionForShiplistZoneName = '';
  leftPositionForShiplistZoneName = '';
  shiplistZoneName = '';

  showAlternative = false;
  showAlternativeModal = false;
  topPositionForAlternativeRoutes = '';
  leftPositionForAlternativeRoutes = '';
  alternativeRoutesArr: AlternativeRouteItem[] = [];
  alternativeRoutesGroupedData: AlternativeRouteItem[][] = [];
  onMouseEnter = onMouseEnter;
  onMouseLeave = onMouseLeave;
  addPlusTimeZone = addPlusTimeZone;
  // selectedEdge?: Feature<Point, GeoJsonProperties>;
  showEcaZones = true;
  zoom = DEFAULT_MAP_ZOOM;
  changeThemeControl = new FormControl<boolean>(false, { nonNullable: true });
  ngModelDefault = '';
  readonly NOT_APPLICABLE = NOT_APPLICABLE;
  readonly UserTimeSettings = UserTimeSettings;
  readonly MapFeatureProperties = MapFeatureProperties;
  readonly PrimeNgIcons = PrimeNgIcons;
  readonly Themes = Themes;
  readonly ID_USER_SETTINGS_FORM_CHANGE_THEME = ID_USER_SETTINGS_FORM_CHANGE_THEME;
  private map?: MapFromMapboxgl;
  private _route: FeatureCollection = emptyFeatureCollection();
  private _portsPoints: FeatureCollection = emptyFeatureCollection();
  private _alternativeRoutes: FeatureCollection[] = [];
  private _useUlsfo = false;
  private _theme = Themes.LIGHT;
  private _isFixed = false;
  private _onDestroy$ = new Subject<void>();

  constructor(private readonly cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.changeThemeControl.valueChanges.pipe(takeUntil(this._onDestroy$)).subscribe((value) => {
      this.changeTheme.emit(value ? Themes.DARK : Themes.LIGHT);
    });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  onLoad(event: MapboxEvent<undefined> & EventData): void {
    this.map = event.target;
    this.map?.resize();
    this.upLayers();
    this.mapReady.emit();
  }

  onPortWidgetChange(value: boolean): void {
    this.showPortWidget = value;
    this.showPortWidgetChange.emit(this.showPortWidget);
  }

  /*   onRouteClick({ features }: MapLayerMouseEvent): void {
    if (features?.length) {
      const feature = features[0] as Feature<LineString, GeoJsonProperties>;
      const x = (feature.geometry.coordinates[0][0] + feature.geometry.coordinates[1][0]) / 2;
      const y = (feature.geometry.coordinates[0][1] + feature.geometry.coordinates[1][1]) / 2;
      const position: Position = [x, y];
      const point: Feature<Point, GeoJsonProperties> = {
        type: MapTypes.Feature,
        properties: feature.properties,
        geometry: {
          type: MapTypes.Point,
          coordinates: position,
        },
      };
      this.selectedEdge = point;
    }
  } */

  onPortLayerClick({ features }: MapLayerMouseEvent): void {
    const feature = features as MapboxGeoJSONFeature[];
    if (!feature[0]?.properties?.[MapFeatureProperties.Cluster]) {
      const point = getPointForMap(feature);
      this.selectedPortPoint = point;
      this.showPortPopup = true;
    }
  }

  createImage(indexPort: number) {
    const svg = `<svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="26" cy="26" r="25.5" fill="lightseagreen" stroke="black"/>
    <style>
        .text { font-size: 30px; fill: white; text-transform: uppercase;}
      </style>
    <text x="18" y="35" class="text">${indexPort}</text>
    </svg>`;
    const blob = new Blob([svg], { type: 'image/svg+xml' });
    let url = '';
    if (window.URL.createObjectURL) {
      url = window.URL.createObjectURL(blob);
    }
    const image = document.createElement('img');
    image.addEventListener('load', () => URL.revokeObjectURL(url), { once: true });
    image.src = url;
    return { image: image as HTMLImageElement, indexPort: indexPort };
  }

  styleImageMissing(event: { id: string } & EventData): void {
    const needImage = this.arrayImages?.find((item) => item?.indexPort?.toString() === event?.id);
    if (needImage && needImage?.image?.width && needImage?.image?.height) {
      this.map?.addImage(event?.id, needImage?.image);
    }
  }

  changeZoom(zoom: number): void {
    const currentZoom = this.map?.getZoom() || 0;
    this.map?.setZoom(currentZoom + zoom);
  }

  resize(): void {
    this.map?.resize();
    this.cdr?.detectChanges();
  }

  getLabelTheme(): string {
    return this.changeThemeControl.value ? 'Light' : 'Dark';
  }

  getColorText(): string {
    return getColorFromVariablesSCSS('primeng-table-color');
  }

  onMouseMoveShipList(evt: MapLayerMouseEvent): void {
    this.onMouseEnter(evt);
    this.showShiplistZoneName = true;
    this.topPositionForShiplistZoneName = evt.point.y + 'px';
    this.leftPositionForShiplistZoneName = evt.point.x + 'px';
    this.shiplistZoneName = evt?.features ? evt?.features[0]?.properties?.['name'] : '';

    const chooseId = evt?.features ? evt?.features[0]?.properties?.['id'] : 0;
    // highlight hover zone, unhighlight previous highlight not selected zones
    this.shiplistZones.features = this.shiplistZones.features.map((feature) => {
      if (feature?.properties && !feature.properties['selected']) {
        if (feature?.properties['id'] === chooseId) {
          feature.properties['color'] = '#6581AE'; // dark blue
        } else {
          feature.properties['color'] = GREY_MAP_ZONE_COLOR;
        }
      }
      return feature;
    });
    this.setShiplistZonesSource();
  }

  onMouseClickShipList({ features }: MapLayerMouseEvent): void {
    if (this.selectedShiplistZoneType === ShiplistZoneType.RANGE) {
      this.showNotification.emit();
    } else {
      const featuresArr = features as MapboxGeoJSONFeature[];
      const properties = featuresArr[0]?.properties as ShiplistGeoZoneProperties;
      const chooseId = featuresArr ? properties?.id : 0;
      const chooseName: string = featuresArr ? properties?.name : '';
      const type: ShiplistZoneType = featuresArr ? properties?.type : ShiplistZoneType.ZONE;
      const selected: ShiplistSelectedZoneOrPort = {
        id: chooseId,
        name: chooseName,
        type: type,
        is_zone: true,
        checked: true,
        loc_id: properties.loc_id,
        zone_ids: properties.zone_ids,
      };
      // select/unselect zone
      this.shiplistZones.features = this.shiplistZones.features.map((feature) => {
        if (feature?.properties) {
          if (feature?.properties['id'] === chooseId) {
            if (!feature.properties['selected']) {
              feature.properties['color'] = BLUE_MAP_ZONE_COLOR; // blue
              feature.properties['selected'] = true;
              this.selectZone.emit({ value: selected, add: true });
            } else {
              feature.properties['color'] = GREY_MAP_ZONE_COLOR;
              feature.properties['selected'] = false;
              this.selectZone.emit({ value: selected, add: false });
            }
          }
        }
        return feature;
      });
      this.setShiplistZonesSource();
    }
  }

  onMouseClickRoute(evt: MapLayerMouseEvent): void {
    this.topPositionForAlternativeRoutes = evt.point.y + 'px';
    this.leftPositionForAlternativeRoutes = evt.point.x + 'px';
    if (evt?.features) {
      const properties = evt?.features[0]?.properties as AlternativeRouteItem;
      this.alternativeRoutesArr.push({
        index: properties?.[MapFeatureProperties.Index],
        eventId: properties?.[MapFeatureProperties.EventId],
        start: properties?.[MapFeatureProperties.Start],
        end: properties?.[MapFeatureProperties.End],
        excepted_canals: properties?.[MapFeatureProperties.ExceptedCanals],
        crossed_canals: properties?.[MapFeatureProperties.CrossedCanals] || [],
        distance: properties?.[MapFeatureProperties.Distance] || 0,
        alternative: properties?.[MapFeatureProperties.Alternative] || false,
      });

      const clickFeaturesFromMainRoute = this.alternativeRoutesArr.find(
        (route) => !route.alternative
      );
      if (!clickFeaturesFromMainRoute) {
        this.alternativeRoutesGroupedData = this.filterAlternativeRoutes(
          this.groupedData(this.alternativeRoutesArr)
        );
        this.showAlternativeModal = true;
      } else {
        this.showAlternative = !this.showAlternative;
        setTimeout(() => {
          this.upLayers();
        }, 100);
      }
    }
  }

  // similar to groupByDate
  groupedData(data: AlternativeRouteItem[]): AlternativeRouteItem[][] {
    const groupdata = data.reduce((acc: AlternativeRouteItem[][], item: AlternativeRouteItem) => {
      if (!acc[item.index]) {
        acc[item.index] = [];
      }
      acc[item.index].push(item);
      return acc;
    }, []);
    return groupdata;
  }

  filterAlternativeRoutes(routes: AlternativeRouteItem[][]): AlternativeRouteItem[][] {
    return routes.filter((route) => route[0]?.index || route[0]?.index === 0);
  }

  clearAlternativeDivAndData(): void {
    this.showAlternativeModal = false;
    this.alternativeRoutesArr = [];
  }

  mapChange(): void {
    this.upLayers();
    this.showShiplistZoneName = false;
    // remove hover highlight on map
    this.shiplistZones.features = this.shiplistZones.features.map((feature) => {
      if (feature?.properties) {
        if (!feature.properties['selected']) {
          feature.properties['color'] = GREY_MAP_ZONE_COLOR;
          feature.properties['selected'] = false;
        }
      }
      return feature;
    });
    this.setShiplistZonesSource();
  }

  changeSelectedZone(option: ShiplistSelectedZoneOrPort, selected: boolean): void {
    this.shiplistZones.features = this.shiplistZones.features.map((feature) => {
      if (feature?.properties) {
        if (
          feature?.properties['id'] === option.id ||
          option.zone_ids?.includes(feature.properties['loc_id'])
        ) {
          feature.properties['color'] = selected ? BLUE_MAP_ZONE_COLOR : GREY_MAP_ZONE_COLOR;
          feature.properties['selected'] = selected ? true : false;
        }
      }
      return feature;
    });
    this.setShiplistZonesSource();
  }

  multipleHighlightZones(ids: number[], zoneIds: number[]): void {
    this.shiplistZones.features = this.shiplistZones.features.map((feature) => {
      if (feature?.properties) {
        if (
          ids.includes(feature?.properties['id']) ||
          zoneIds.includes(feature.properties['loc_id'])
        ) {
          feature.properties['color'] = BLUE_MAP_ZONE_COLOR;
          feature.properties['selected'] = true;
        } else {
          feature.properties['color'] = GREY_MAP_ZONE_COLOR;
          feature.properties['selected'] = false;
        }
      }
      return feature;
    });
    this.setShiplistZonesSource();
  }

  setShiplistZonesSource(): void {
    const source = this.map?.getSource('shiplist_zones') as GeoJSONSource;
    source?.setData(this.shiplistZones);
    this.cdr.detectChanges();
  }

  setPortPoints(portsPoints: FeatureCollection): void {
    this.portsPoints = portsPoints;
    const source = this.map?.getSource('port-source') as GeoJSONSource;
    source?.setData(this.portsPoints);
    this.cdr.detectChanges();
  }

  onClickRoute(route: AlternativeRouteItem): void {
    const value: AlternativeRouteItemSave = {
      id: route.eventId,
      excepted_canals: JSON.parse(route.excepted_canals.toString()),
    };
    this.saveAlternativeRoute.emit(value);
    this.showAlternative = false;
    this.clearAlternativeDivAndData();
  }

  getCrossedCanals(route: AlternativeRouteItem): string[] | string {
    const arr: string[] = [];
    for (const canal of this.canals) {
      if (
        JSON.parse(route.crossed_canals?.toString()).includes(canal.id) &&
        canal.id !== COASTER_RESTR_CANAL_ID
      ) {
        arr.push(' ' + canal.short_name);
      }
    }
    return arr?.length ? arr + ':' : '';
  }

  round(number: number): number {
    return Math.floor(number);
  }

  getCrossedCanalsTitle(route: AlternativeRouteItem): string[] | string {
    const arr: string[] = [];
    for (const canal of this.canals) {
      if (
        JSON.parse(route.crossed_canals?.toString()).includes(canal.id) &&
        canal.id !== COASTER_RESTR_CANAL_ID
      ) {
        arr.push(' ' + canal.name);
      }
    }
    return arr?.length ? arr : '';
  }

  upLayers(): void {
    this.map?.moveLayer('route');
    this.map?.moveLayer('port');
  }

  /*   getExceptedCanals(route: AlternativeRouteItem): string[] | string {
    const arr: string[] = [];
    for (const canal of this.canals) {
      if (JSON.parse(route.excepted_canals?.toString()).includes(canal.id)) {
        arr.push(canal.name);
      }
    }
    return arr?.length ? arr : NOT_APPLICABLE;
  } */
}
