import { Injectable } from '@angular/core';
import {
  featuresToFeatureCollection,
  makeFeature,
  makeFeatureHistoryLineString,
  makeFeatureHistoryPoint,
  mapFleetVessels,
  removeCompanyCollections,
  vesselWasUpdated,
} from '@estimator/helpers';
import {
  ErrorNotifications,
  FOR_COMPANY_FIELD,
  FleetVessel,
  FleetVesselCollection,
  FleetVesselPosition,
  NotificationType,
  itemMap,
} from '@estimator/models';
import { FleetVesselService, NotificationService } from '@estimator/services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Feature, FeatureCollection } from 'geojson';
import { catchError, finalize, tap, throwError } from 'rxjs';
import {
  AddVesselToMyFleetOrCompanyFleet,
  CreateFleetVesselCollection,
  DeleteFleetVessel,
  DeleteFleetVesselCollection,
  GetFleetVesselPositions,
  GetMyCompanyFleetVesselAndCollections,
  GetMyFleetVesselAndCollections,
  UpdateFleetVessel,
  UpdateFleetVesselActive,
  UpdateFleetVesselCollection,
} from './fleet-vessel.actions';

export interface FleetVesselStateModel {
  myFleetVessels: FleetVessel[];
  myCompanyFleetVessels: FleetVessel[];
  myFleetVesselCollections: FleetVesselCollection[];
  myCompanyFleetVesselCollections: FleetVesselCollection[];
  myLastFleetVesselsPositions: FleetVesselPosition[];
  myFleetVesselsMap: itemMap;
  historyFleetVesselsPositions: FleetVesselPosition[];
  loading: boolean;
}
@State<FleetVesselStateModel>({
  name: 'fleetVessels',
  defaults: {
    myFleetVessels: [],
    myCompanyFleetVessels: [],
    myFleetVesselCollections: [],
    myCompanyFleetVesselCollections: [],
    myLastFleetVesselsPositions: [],
    myFleetVesselsMap: new Map(),
    historyFleetVesselsPositions: [],
    loading: false,
  },
})
@Injectable()
export class FleetVesselState {
  @Selector()
  static getIsLoading({ loading }: FleetVesselStateModel): boolean {
    return loading;
  }
  @Selector()
  static getMyFleetVessels({ myFleetVessels }: FleetVesselStateModel): FleetVessel[] {
    return removeCompanyCollections(myFleetVessels, FOR_COMPANY_FIELD);
  }

  @Selector()
  static getMyCompanyFleetVessels({ myCompanyFleetVessels }: FleetVesselStateModel): FleetVessel[] {
    return myCompanyFleetVessels;
  }

  @Selector()
  static getMyFleetVesselCollections({
    myFleetVesselCollections,
  }: FleetVesselStateModel): FleetVesselCollection[] {
    return removeCompanyCollections(myFleetVesselCollections, FOR_COMPANY_FIELD);
  }

  @Selector()
  static getMyCompanyFleetVesselCollections({
    myCompanyFleetVesselCollections,
  }: FleetVesselStateModel): FleetVesselCollection[] {
    return myCompanyFleetVesselCollections;
  }

  @Selector()
  static getLastAllFleetVesselPositions({
    myLastFleetVesselsPositions,
    myFleetVessels,
    myFleetVesselCollections,
  }: FleetVesselStateModel): FeatureCollection {
    const features: Feature[] = [];
    for (const position of myLastFleetVesselsPositions) {
      const findFleet = myFleetVessels.find(
        (item) => item?.general_vessel_id === position?.general_vessel_id
      ) as FleetVessel;
      const findCollection = myFleetVesselCollections.find(
        (item) => item?.id === findFleet?.collection_id
      ) as FleetVesselCollection;
      features.push(
        makeFeature(
          position,
          findFleet,
          findCollection ? (findCollection?.color as string) : 'black'
        )
      );
    }
    return featuresToFeatureCollection(features);
  }

  @Selector()
  static getHistoryFleetVesselsPositionsPoints({
    historyFleetVesselsPositions,
  }: FleetVesselStateModel): FeatureCollection {
    const features: Feature[] = [];
    for (const position of historyFleetVesselsPositions) {
      features.push(makeFeatureHistoryPoint(position));
    }
    return featuresToFeatureCollection(features);
  }

  @Selector()
  static getHistoryFleetVesselsPositionsLineString({
    historyFleetVesselsPositions,
  }: FleetVesselStateModel): FeatureCollection {
    const lineStringData = makeFeatureHistoryLineString(historyFleetVesselsPositions);
    return featuresToFeatureCollection(lineStringData);
  }

  constructor(
    private readonly fleetVesselSrv: FleetVesselService,
    private readonly notificationService: NotificationService
  ) {}

  @Action(GetMyFleetVesselAndCollections)
  getMyFleetVesselAndCollections({
    patchState,
  }: StateContext<FleetVesselStateModel>): GetMyFleetVesselAndCollections {
    patchState({ loading: true });
    return this.fleetVesselSrv.getMyFleetAndCollections().pipe(
      tap((res) => {
        const myFleetVesselsMap = new Map(res.fleet_vessels.map(mapFleetVessels));
        patchState({
          myFleetVessels: res.fleet_vessels,
          myFleetVesselCollections: res.fleet_vessel_collections,
          myLastFleetVesselsPositions: res.last_fleet_vessels_positions,
          myFleetVesselsMap,
        });
      }),
      catchError((err) => {
        patchState({ loading: false });
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }

  @Action(GetMyCompanyFleetVesselAndCollections)
  getMyCompanyFleetVesselAndCollections({
    patchState,
  }: StateContext<FleetVesselStateModel>): GetMyCompanyFleetVesselAndCollections {
    patchState({ loading: true });
    return this.fleetVesselSrv.getMyFleetCompanyAndCollections().pipe(
      tap((res) => {
        patchState({
          myCompanyFleetVessels: res.fleet_vessels,
          myCompanyFleetVesselCollections: res.fleet_vessel_collections,
        });
      }),
      catchError((err) => {
        patchState({ loading: false });
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }

  @Action(AddVesselToMyFleetOrCompanyFleet)
  addVesselToMyFleetOrCompanyFleet(
    { getState, patchState }: StateContext<FleetVesselStateModel>,
    { props }: AddVesselToMyFleetOrCompanyFleet
  ) {
    patchState({ loading: true });
    return this.fleetVesselSrv.addVesselToMyFleetOrCompanyFleet(props).pipe(
      tap((fleetVessel) => {
        if (props.for_company) {
          const myCompanyFleetVessels = getState().myCompanyFleetVessels;
          myCompanyFleetVessels.push(fleetVessel);
          patchState({ myCompanyFleetVessels: [...myCompanyFleetVessels] });
        } else {
          const myFleetVessels = getState().myFleetVessels;
          myFleetVessels.push(fleetVessel);
          patchState({ myFleetVessels: [...myFleetVessels] });
        }
        const myOrCompanyFleet = props.for_company ? 'company' : 'my';
        this.notificationService.showNotification(
          `Vessel ${fleetVessel.name} was added to ${myOrCompanyFleet} fleet`,
          NotificationType.Success
        );
      }),
      catchError((err) => {
        patchState({ loading: false });
        this.notificationService.showNotification(
          `Error when adding a vessel. ${err?.error?.error}`,
          NotificationType.Error
        );
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }

  @Action(UpdateFleetVessel)
  updateFleetVessel(
    { getState, patchState }: StateContext<FleetVesselStateModel>,
    { vessel }: UpdateFleetVessel
  ) {
    patchState({ loading: true });
    return this.fleetVesselSrv.updateVessel(vessel).pipe(
      tap((updVessel) => {
        const isUpdateCompanyVessel = updVessel.for_company;
        const myOrCompanyVessels = isUpdateCompanyVessel
          ? getState().myCompanyFleetVessels
          : getState().myFleetVessels;
        const index = myOrCompanyVessels.findIndex((oldVessel) => oldVessel?.id === vessel?.id);
        if (index >= 0) {
          myOrCompanyVessels.splice(index, 1, updVessel);
        }
        if (isUpdateCompanyVessel) {
          patchState({ myCompanyFleetVessels: [...myOrCompanyVessels] });
        } else {
          patchState({ myFleetVessels: [...myOrCompanyVessels] });
        }
        this.notificationService.showNotification(
          vesselWasUpdated(updVessel?.name as string),
          NotificationType.Success
        );
      }),
      catchError((err) => {
        this.notificationService.showNotification(
          `${ErrorNotifications.ErrorUpdatingVessel}. ${err.error.error}`,
          NotificationType.Error
        );
        patchState({ loading: false });
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }

  @Action(DeleteFleetVessel)
  deleteFleetVessel(
    { getState, patchState }: StateContext<FleetVesselStateModel>,
    { id, general_vessel_id, isDeleteCompanyVessel }: DeleteFleetVessel
  ) {
    patchState({ loading: true });
    return this.fleetVesselSrv.deleteFleetVessel(id).pipe(
      tap(() => {
        // удаление из массивов кораблей
        const myOrCompanyVessels = isDeleteCompanyVessel
          ? getState().myCompanyFleetVessels
          : getState().myFleetVessels;
        const index = myOrCompanyVessels.findIndex((oldVessel) => oldVessel?.id === id);
        // удаление из массива позиций
        const myLastFleetVesselsPositions = getState().myLastFleetVesselsPositions;
        const indexForPositions = myLastFleetVesselsPositions.findIndex(
          (oldVessel) => oldVessel?.general_vessel_id === general_vessel_id
        );
        if (index >= 0) {
          myOrCompanyVessels.splice(index, 1);
          myLastFleetVesselsPositions.splice(indexForPositions, 1);
        }
        if (isDeleteCompanyVessel) {
          patchState({ myCompanyFleetVessels: [...myOrCompanyVessels] });
        } else {
          patchState({ myFleetVessels: [...myOrCompanyVessels] });
        }
        this.notificationService.showNotification(`Vessel was deleted`, NotificationType.Success);
      }),
      catchError((err) => {
        this.notificationService.showNotification(
          `Error when deleting a vessel. ${err?.error?.error}`,
          NotificationType.Error
        );
        patchState({ loading: false });
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }

  @Action(GetFleetVesselPositions)
  getFleetVesselPositions(
    { patchState }: StateContext<FleetVesselStateModel>,
    { props }: GetFleetVesselPositions
  ) {
    patchState({ loading: true });
    return this.fleetVesselSrv.getFleetPositions(props).pipe(
      tap((res) => {
        patchState({ historyFleetVesselsPositions: res });
      }),
      catchError((err) => {
        this.notificationService.showNotification(
          `Error when requesting a positions. ${err?.error?.error}`,
          NotificationType.Error
        );
        patchState({ loading: false });
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }

  @Action(UpdateFleetVesselActive)
  updateFleetVesselActive(
    { getState, patchState }: StateContext<FleetVesselStateModel>,
    { activeRequest, vesselId }: UpdateFleetVesselActive
  ) {
    patchState({ loading: true });
    return this.fleetVesselSrv.updateFleetVesselActive(activeRequest, vesselId).pipe(
      tap((fleetVesselActiveResponse) => {
        const isUpdateCompanyVessel = fleetVesselActiveResponse?.fleet_vessel?.for_company;
        const myOrCompanyVessels = isUpdateCompanyVessel
          ? getState().myCompanyFleetVessels
          : getState().myFleetVessels;
        const index = myOrCompanyVessels.findIndex((oldVessel) => oldVessel?.id === vesselId);
        if (index >= 0) {
          myOrCompanyVessels.splice(index, 1, fleetVesselActiveResponse?.fleet_vessel);
        }
        if (isUpdateCompanyVessel) {
          patchState({ myCompanyFleetVessels: [...myOrCompanyVessels] });
        } else {
          patchState({ myFleetVessels: [...myOrCompanyVessels] });
        }
        this.notificationService.showNotification(
          vesselWasUpdated(fleetVesselActiveResponse?.fleet_vessel?.name as string),
          NotificationType.Success
        );
      }),
      catchError((err) => {
        this.notificationService.showNotification(
          `${ErrorNotifications.ErrorUpdatingVessel}. ${err.error.error}`,
          NotificationType.Error
        );
        patchState({ loading: false });
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }

  @Action(CreateFleetVesselCollection)
  createFleetVesselCollection(
    { getState, patchState }: StateContext<FleetVesselStateModel>,
    { collection }: CreateFleetVesselCollection
  ) {
    patchState({ loading: true });
    return this.fleetVesselSrv.createFleetVesselCollection(collection).pipe(
      tap((newCollection) => {
        const isUpdateCompanyCollection = newCollection.for_company;
        const myOrCompanyCollections = isUpdateCompanyCollection
          ? getState().myCompanyFleetVesselCollections
          : getState().myFleetVesselCollections;
        myOrCompanyCollections.push(newCollection);
        if (isUpdateCompanyCollection) {
          patchState({ myCompanyFleetVesselCollections: [...myOrCompanyCollections] });
        } else {
          patchState({ myFleetVesselCollections: [...myOrCompanyCollections] });
        }
        this.notificationService.showNotification(
          `Fleet ${newCollection?.name} was added`,
          NotificationType.Success
        );
      }),
      catchError((err) => {
        this.notificationService.showNotification(
          `Error when adding a fleet. ${err?.error?.error}`,
          NotificationType.Error
        );
        patchState({ loading: false });
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }

  @Action(UpdateFleetVesselCollection)
  updateFleetVesselCollection(
    { getState, patchState }: StateContext<FleetVesselStateModel>,
    { collection }: UpdateFleetVesselCollection
  ) {
    patchState({ loading: true });
    return this.fleetVesselSrv.updateFleetVesselCollection(collection).pipe(
      tap((updCollection) => {
        const isUpdateCompanyCollection = updCollection.for_company;
        const myOrCompanyCollections = isUpdateCompanyCollection
          ? getState().myCompanyFleetVesselCollections
          : getState().myFleetVesselCollections;
        const index = myOrCompanyCollections.findIndex(
          (oldCollection) => oldCollection?.id === collection?.id
        );
        if (index >= 0) {
          myOrCompanyCollections.splice(index, 1, updCollection);
        }
        if (isUpdateCompanyCollection) {
          patchState({ myCompanyFleetVesselCollections: [...myOrCompanyCollections] });
        } else {
          patchState({ myFleetVesselCollections: [...myOrCompanyCollections] });
        }
        this.notificationService.showNotification(
          `Fleet ${updCollection?.name} was updated`,
          NotificationType.Success
        );
      }),
      catchError((err) => {
        this.notificationService.showNotification(
          `Error when updating a fleet. ${err?.error?.error}`,
          NotificationType.Error
        );
        patchState({ loading: false });
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }

  @Action(DeleteFleetVesselCollection)
  deleteFleetVesselCollection(
    { getState, patchState }: StateContext<FleetVesselStateModel>,
    { id, isDeleteCompanyCollection }: DeleteFleetVesselCollection
  ) {
    patchState({ loading: true });
    return this.fleetVesselSrv.deleteFleetVesselCollection(id).pipe(
      tap(() => {
        const myOrCompanyCollections = isDeleteCompanyCollection
          ? getState().myCompanyFleetVesselCollections
          : getState().myFleetVesselCollections;
        const index = myOrCompanyCollections.findIndex((oldVessel) => oldVessel?.id === id);
        if (index >= 0) {
          myOrCompanyCollections.splice(index, 1);
        }
        if (isDeleteCompanyCollection) {
          patchState({ myCompanyFleetVesselCollections: [...myOrCompanyCollections] });
        } else {
          patchState({ myFleetVesselCollections: [...myOrCompanyCollections] });
        }
        this.notificationService.showNotification(`Fleet was deleted`, NotificationType.Success);
      }),
      catchError((err) => {
        this.notificationService.showNotification(
          `Error when deleting a fleet. ${err?.error?.error}`,
          NotificationType.Error
        );
        patchState({ loading: false });
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }
}
