import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BlogPost } from '@estimator/landing-models';
import { from, Observable } from 'rxjs';
import { API_URL } from '../api.token';

@Injectable({
  providedIn: 'root',
})
export class PostService {
  apiUrl = inject(API_URL);
  path = this.apiUrl + '/api/posts';

  constructor(protected readonly http: HttpClient) {}
  getAll(params: HttpParams): Observable<{ data: BlogPost[] }> {
    let strapiParams = new HttpParams().set('sort', 'publishedAt:desc');
    if (params.get('limit')) {
      strapiParams = strapiParams.set('pagination[limit]', params.get('limit') || 25);
    }
    return from(this.http.get<{ data: BlogPost[] }>(this.path, { params: strapiParams }));
  }

  get(slug: string): Observable<{ data: BlogPost }> {
    return from(this.http.get<{ data: BlogPost }>(`${this.path}/${slug}`));
  }
}
