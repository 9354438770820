import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { GhostButtonComponent } from './ghost-button.component';

@NgModule({
  declarations: [GhostButtonComponent],
  imports: [CommonModule, RouterModule],
  exports: [GhostButtonComponent],
})
export class GhostButtonModule {}
