import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { MessageModule } from 'primeng/message';
import { PasswordForgotComponent } from './password-forgot.component';

@NgModule({
  declarations: [PasswordForgotComponent],
  imports: [CommonModule, ReactiveFormsModule, InputTextModule, ButtonModule, MessageModule],
  exports: [PasswordForgotComponent],
})
export class PasswordForgotModule {}
