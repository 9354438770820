import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  RawVessel,
  SearchRawVesselsAndPortsResponse,
  SearchVesselResponse,
  Vessel,
} from '@estimator/models';
import { Observable } from 'rxjs';
import { BaseService } from '../base';

@Injectable({
  providedIn: 'root',
})
export class VesselService extends BaseService<Vessel> {
  constructor(protected override readonly http: HttpClient) {
    super(http);
    this.setPath('/api/parsed-vessels');
  }
  searchVessel(value: string): Observable<SearchVesselResponse> {
    return this.http.get<SearchVesselResponse>(`/api/search/vessels`, { params: { value } });
  }

  searchRawVessel(value: string): Observable<Partial<Vessel>[]> {
    return this.http.get<Partial<Vessel>[]>(`/api/raw-vessels/find`, { params: { value } });
  }

  getRawVesselOverview(id: number): Observable<RawVessel> {
    return this.http.get<RawVessel>(`/api/raw-vessels/${id}/overview`);
  }

  searchDeepVessel(value: string): Observable<SearchVesselResponse> {
    return this.http.get<SearchVesselResponse>(`/api/search/vessels/deep`, {
      params: { value },
    });
  }

  searchRawVesselsAndPorts(value: string): Observable<SearchRawVesselsAndPortsResponse> {
    return this.http.get<SearchRawVesselsAndPortsResponse>(`/api/search/map`, {
      params: { value },
    });
  }

  updateFuelConsumptionsFromBase(id: number): Observable<Vessel> {
    return this.http.get<Vessel>(`${this.path}/${id}/fuel-consumptions`);
  }
}
