import { Themes } from '@estimator/models';

/* export class SetUiSettigns {
  static readonly type = '[UI-SETTINGS] Set UI settings';
  constructor(public settings: UiSettingsStateModel) {}
}

export class RestoreSettings {
  static readonly type = '[UI-SETTINGS] Restore settings';
} */

export class ChangeTheme {
  static readonly type = '[UI-SETTINGS] Change theme';
  constructor(public theme: Themes) {}
}
