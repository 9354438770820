import { EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';

export interface FeedbackMessage {
  email: string;
  message: string;
}

export interface FeedbackMessageForm {
  email: FormControl<string>;
  message: FormControl<string>;
}

export interface FeedbackMessageDialogConfig {
  email: string;
  send: EventEmitter<FeedbackMessage>;
}

export interface FeedbackMessageRequest {
  data: {
    projects: string[];
    name: string;
    notes: string;
  };
}

export const ASANA_CONTACTS_PROJECT_ID = '1205630665962437';
export const ASANA_PROJECT_FEEDBACK_ID = '1205766947861926';
export const ASANA_PROJECT_API_URL = 'https://app.asana.com/api/1.0';
export const ASANA_PROJECT_ACCESS_TOKEN = '1/1203003791739512:006c3257dd18838632aa65fd3b3272ac';
