import { EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { itemMapStringNumber } from './base-entity';
import { Port } from './port';
import { FuelTypes } from './ui-items';

export interface XBPIRequest {
  /**
   * default - 30 days before
   */
  date_from_seconds?: number;
  /**
   * default - if no exists, so condition only date >= date_from
   */
  date_to_seconds?: number;
  /**
   * empty => 200 request from back, which fuels are selected => there will be
   */
  fuel_ids?: number[];
  port_id: number;
  /**
   * true = last 5 rows with data, false need = from and to choose
   */
  recent_only?: boolean;
}

export interface XBPIResponse {
  bunker_prices: XBPIParsed[];
}

export interface PortsHistoryResponse {
  ports: Port[];
}

export interface XBPIParsed {
  // code: string;
  /**
   * when included to Database
   */
  // created_at?: string | Date | null;
  /**
   * date of fuel price
   */
  date: string /* | Date | null */;
  fuel_id: number;
  // id: number;
  // port_id: number;
  // price_from: number;
  price: number;
  // price_to: number;
  unit: XBPIParsedCodePriceUnit;
}

export enum XBPIParsedCodePriceUnit {
  MT = '$/mt',
  MT_OIL = '$/mt (Oil)',
  MT_LNG = '$/mt (LNG)',
  BARREL = '$/barrel',
  GJ = '$/GJ',
  MMBTU = '$/MMBtu',
  FEU = '$/FEU',
  EUR = 'Eur/MWh',
}

export interface XBPITableDialogConfig {
  xbpiParsedData: GroupDataXBPIParsedArray[];
  historyPorts: Port[];
  fuelsMap: itemMapStringNumber;
  isFixed: boolean;
  saveFuelPrices: EventEmitter<SaveXBPIData>;
  requestHistoryDataByPortId: EventEmitter<number>;
  foundXBPIData: Subject<GroupDataXBPIParsedArray[]>;
}

export interface GroupDataXBPIParsedObject {
  [key: string]: XBPIParsed[];
}

export interface GroupDataXBPIParsedArray {
  date: string;
  data: XBPIParsed[];
}

export type SaveXBPIData = { [key in FuelTypes]: number | null };
