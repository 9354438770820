import { ShiplistTableParams } from '@estimator/models';
import { XlistFilter } from '@xlist-models';

export class GetFirstSourceState {
  static readonly type = '[SHIPLIST] FirstSource state';
  constructor(public filter: XlistFilter, public params?: ShiplistTableParams) {}
}

export class GetAllShiplistFilters {
  static readonly type = '[SHIPLIST] Get all shiplist filters';
}

export class GetShiplistFilter {
  static readonly type = '[SHIPLIST] Get shiplist filter';
  constructor(public id: number) {}
}

export class CreateShiplistFilter {
  static readonly type = '[SHIPLIST] Create shiplist filter';
  constructor(public filter: XlistFilter) {}
}

export class UpdateShiplistFilter {
  static readonly type = '[SHIPLIST] Update shiplist filter';
  constructor(public filter: XlistFilter) {}
}

export class CreateShiplistFilterCopy {
  static readonly type = '[SHIPLIST] Create shiplist filter copy';
  constructor(public id: number) {}
}

export class DeleteShiplistFilter {
  static readonly type = '[SHIPLIST] Delete filter';
  constructor(public id: number) {}
}

export class CheckShiplistFilterName {
  static readonly type = '[SHIPLIST] Check shiplist filter name';
  constructor(public name: string) {}
}

export class ResetShiplistFilter {
  static readonly type = '[SHIPLIST] Reset shiplist filter';
}

export class UpdateShiplistGrid {
  static readonly type = '[SHIPLIST] Update shiplist grid';
}

export class GetShiplistMessages {
  static readonly type = '[SHIPLIST] Get shiplist messages by ID';
  constructor(
    public shiplist_item_id: number
  ) {}
}
