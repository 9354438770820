import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AgChartsAngularModule } from 'ag-charts-angular';
import { BreakEvenAxesComponent } from './break-even-axes.component';

@NgModule({
  declarations: [BreakEvenAxesComponent],
  imports: [CommonModule, AgChartsAngularModule],
  exports: [BreakEvenAxesComponent],
})
export class BreakEvenAxesModule {}
