import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { getDataFolderOrDeal, getDealsIds } from '@estimator/helpers';
import {
  DealAction,
  DealNavigationLink,
  Folder,
  FOLDER_NAME_MAX_LENGTH,
  FolderDealActionOptions,
  FolderDealOptions,
  NavigateDealEvent,
  NOT_APPLICABLE,
  PrimeNgIcons,
} from '@estimator/models';
import { MenuItem } from 'primeng/api';
import { Menu } from 'primeng/menu';

@Component({
  selector: 'estimator-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrl: './toolbar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ToolbarComponent {
  @HostListener('window:resize') onResize(): void {
    const el = this.dealList?.nativeElement;
    if (el) {
      this.isShowDropdownDeals = el?.clientWidth < el?.scrollWidth;
    }
  }

  @ViewChild('folderNameInput')
  folderNameInput?: ElementRef<HTMLInputElement>;
  @ViewChild('dealNameInput') dealNameInput?: ElementRef<HTMLInputElement>;
  @ViewChild('folderName') folderName?: ElementRef<HTMLDivElement>;
  @ViewChild('folderMenu') folderMenu?: Menu;
  @ViewChild('dealMenu') dealMenu?: Menu;
  @ViewChild('dropdownDealsMenu') dropdownDealsMenu?: Menu;
  @ViewChild('dealList') dealList?: ElementRef<HTMLDivElement>;

  @Input() isTanker = false;
  @Input() activeLink: string | null = null;

  @Input() set estimationsList(dealList: DealNavigationLink[]) {
    this._estimationsList = dealList;
    setTimeout(() => {
      this.onResize();
    });
  }

  get estimationsList(): DealNavigationLink[] {
    return this._estimationsList;
  }

  @Input() set openFolder(value: Folder | null | undefined) {
    this._openFolder = value;
    this.folderNameControl.patchValue(value?.name || '', { emitEvent: false });
    this.isSaving = false;
  }

  get openFolder(): Folder | null | undefined {
    return this._openFolder;
  }
  @Input() isLoading: boolean | null = false;
  @Input() isFixed = false;
  @Input() isEventsFormInvalid = false;
  @Input() showFolderActions = false;
  @Input() openFolderNotDraft = false;
  @Input() eventsFormInvalid = false;
  @Input() hideDealsAndFolderMenu = false;

  @Output() openSideBar = new EventEmitter();
  @Output() addNewFolder = new EventEmitter();
  @Output() addNewDeal = new EventEmitter();
  @Output() saveFolder = new EventEmitter();
  @Output() fixFolder = new EventEmitter();
  @Output() fixDeal = new EventEmitter();
  @Output() closeFolderInCurrentEstimation = new EventEmitter();
  @Output() closeFolderInLastEstimation = new EventEmitter();
  @Output() changeDealName = new EventEmitter<string>();
  @Output() copyDeal = new EventEmitter<DealNavigationLink>();
  @Output() deleteDeal = new EventEmitter<DealNavigationLink>();
  @Output() saveOrderDealList = new EventEmitter<number[]>();
  @Output() changeFolder = new EventEmitter<Folder>();
  @Output() folderDealAction = new EventEmitter<FolderDealActionOptions>();
  @Output() navigate = new EventEmitter<NavigateDealEvent>();

  get unvisibleDealsList(): MenuItem[] {
    const result: DealNavigationLink[] = [];
    this.unvisibleDealItemList.sort().forEach((dealId) => {
      result.push(this.estimationsList[dealId]);
    });
    return result as MenuItem[];
  }

  get isDisabled(): boolean {
    return this.isLoading || this.isFixed;
  }

  folderMenuItems: MenuItem[] = [
    {
      label: 'Close in last saved estimation',
      icon: PrimeNgIcons.TIMES_CIRCLE,
      command: () => this.closeFolderInLastEstimation.emit(),
      id: 'toolbar_close_folder_ever_button',
    },
    {
      label: 'Close in current estimation',
      icon: PrimeNgIcons.TIMES,
      command: () => this.closeFolderInCurrentEstimation.emit(),
      id: 'toolbar_close_folder_current_button',
    },
    {
      label: 'Copy',
      icon: PrimeNgIcons.COPY,
      command: () => this.folderDealAction.emit({ action: FolderDealOptions.FAST_COPY_FOLDER }),
      id: 'toolbar_copy_folder',
    },
    {
      label: 'Save as New',
      icon: PrimeNgIcons.SAVE,
      command: () => this.onCopyFolder(),
      id: 'toolbar_save_as_new_folder',
    },
  ];
  dealMenuItems: MenuItem[] = [
    {
      label: 'Rename',
      icon: PrimeNgIcons.PENCIL,
      id: 'toolbar_rename_deal_button',
      command: () => this.onChangeDealName(this.selectedDeal),
    },
    {
      label: 'Copy to Another Folder',
      icon: PrimeNgIcons.COPY,
      id: 'toolbar_copy_deal_to_another_folder_button',
      command: () =>
        this.folderDealAction.emit({
          action: FolderDealOptions.COPY_DEAL,
          options: getDataFolderOrDeal(this.selectedDeal, this.openFolder),
        }),
    },
    {
      label: 'Save to New Folder',
      icon: PrimeNgIcons.SAVE,
      id: 'toolbar_save_deal_to_new_folder_button',
      command: () =>
        this.folderDealAction.emit({
          action: FolderDealOptions.COPY_DEAL_TO_NEW_FOLDER,
          options: getDataFolderOrDeal(this.selectedDeal, this.openFolder),
        }),
    },
  ];
  unvisibleDealItemList: number[] = [];
  isEditingFolderName = false;
  editingDeal: DealNavigationLink | null = null;
  folderNameControl = new FormControl('');
  dealNameControl = new FormControl();
  DealAction = DealAction;
  selectedDeal: DealNavigationLink | null = null;
  dragIndex: number | null = null;
  dropIndex: number | null = null;
  isShowDropdownDeals = false;
  isSaving = false;

  readonly NOT_APPLICABLE = NOT_APPLICABLE;
  readonly PrimeNgIcons = PrimeNgIcons;
  readonly FOLDER_NAME_MAX_LENGTH = FOLDER_NAME_MAX_LENGTH;
  private _estimationsList: DealNavigationLink[] = [];
  private _openFolder: Folder | null | undefined = null;

  constructor(private readonly renderer: Renderer2, private readonly router: Router) {}

  onChangeFolderName(): void {
    if (this.isEditingFolderName && this.openFolder) {
      if (!this.folderNameControl?.value) {
        this.folderNameControl.patchValue(this.openFolder.name);
        this.isEditingFolderName = false;
        return;
      }
      if (this.folderNameControl.value !== this.openFolder.name) {
        this.isSaving = true;

        this.changeFolder.emit({
          ...this.openFolder,
          name: this.folderNameControl.value,
        });
      }
      this.isEditingFolderName = false;
    } else {
      this.isEditingFolderName = true;
      const folderNameClientWidth = this.folderName?.nativeElement?.clientWidth;
      this.renderer.setStyle(
        this.folderNameInput?.nativeElement,
        'width',
        folderNameClientWidth + 'px'
      );
      setTimeout(() => {
        this.folderNameInput?.nativeElement?.focus();
      }, 0);
    }
  }

  onCopyFolder() {
    this.folderDealAction.emit({ action: FolderDealOptions.COPY_FOLDER });
  }

  onChangeDealName(deal?: DealNavigationLink | null): void {
    if (this.isFixed) return;
    if (deal?.id) {
      this.editingDeal = deal;
      this.dealNameControl.patchValue(deal.name, {
        emitEvent: false,
      });
      setTimeout(() => {
        this.dealNameInput?.nativeElement?.focus();
      });
    } else {
      const newValue = this.dealNameControl.value;
      if (newValue !== this.editingDeal?.name) {
        const deal = this.estimationsList.find((deal) => deal.id === this.editingDeal?.id);
        deal ? (deal.name = newValue) : null;
        this.changeDealName.emit(newValue);
      }
      this.editingDeal = null;
    }
  }

  onShowMenu(event: MouseEvent, deal?: DealNavigationLink): void {
    this.folderMenu?.hide();
    const el = event.target as HTMLElement;
    let offsetTopAddon = -4;
    let offsetLeftAddon = 0;
    if (deal) {
      this.onBlurMenu();
      const elementId = this.estimationsList.findIndex((item) => item.id === deal.id);
      const dealItem = document.getElementById('deal_navigation_link_' + elementId);
      this.renderer.addClass(dealItem, 'hovered');
      offsetTopAddon = 4;
      offsetLeftAddon = 4;
    }
    setTimeout(() => {
      const folderMenuOverlay = document.querySelector('.p-menu-overlay') as HTMLElement;
      const folderMenuPosition = folderMenuOverlay.getBoundingClientRect();
      const menuContainer = el?.getBoundingClientRect();
      const elementPositionX = menuContainer.x + menuContainer.width;
      let offsetLeft = elementPositionX - folderMenuOverlay?.offsetWidth + offsetLeftAddon;
      if (folderMenuPosition.x < folderMenuPosition.width) {
        offsetLeft = 0;
      }
      const offsetTop = menuContainer.y + menuContainer.height + offsetTopAddon;
      this.renderer.setStyle(folderMenuOverlay, 'left', offsetLeft + 'px');
      this.renderer.setStyle(folderMenuOverlay, 'top', offsetTop + 'px');
    });
    if (deal) {
      this.dealMenu?.show(event);
    } else {
      this.folderMenu?.show(event);
    }
  }

  onBlurMenu(): void {
    const dealItemHovered = document.querySelector('.deal-item.hovered');
    dealItemHovered?.classList.remove('hovered');
  }

  onShowAllDeals($event: MouseEvent): void {
    this.unvisibleDealItemList = [];
    const allDeals: NodeListOf<HTMLElement> = document.querySelectorAll('.deal-item');
    allDeals.forEach((deal) => {
      this.intersectionObserver(deal);
    });
    this.dropdownDealsMenu?.toggle($event);
  }

  intersectionObserver(elem: HTMLElement): void {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (!entry.isIntersecting) {
            this.unvisibleDealItemList.push(+elem.id.split('_').slice(-1)[0]);
            observer.disconnect();
          }
        });
      },
      {
        threshold: 0.5,
      }
    );
    observer.observe(elem);
  }

  onSelectDealInDropDown(deal: DealNavigationLink, event?: MouseEvent): void {
    const indexInDealList = this.estimationsList.findIndex((item) => item.id === deal.id);
    const selectedDealEl = document.getElementById('deal_navigation_link_' + indexInDealList);
    if (selectedDealEl) {
      this.onSelectDeal(DealAction.NAVIGATE, deal, event);
    }
    setTimeout(() => {
      selectedDealEl?.scrollIntoView({ block: 'start', inline: 'start' });
    });
  }

  onSelectDeal(action: DealAction, deal: DealNavigationLink, event?: MouseEvent): void {
    this.selectedDeal = deal;
    switch (action) {
      case DealAction.NAVIGATE:
        // this.router.navigate([deal?.link]);
        if (event) {
          this.navigate.emit({ event: event, link: deal });
        }
        break;
      case DealAction.RENAME:
        this.onChangeDealName(deal);
        break;
      case DealAction.COPY:
        this.onCopyDeal(deal);
        break;
      case DealAction.DELETE:
        this.onDeleteDeal(deal);
        break;
      case DealAction.SHOW_MENU:
        if (event) this.onShowMenu(event, deal);
        break;
      default:
        break;
    }
  }

  isDealSelected(deal: DealNavigationLink): boolean {
    if (deal?.id) {
      return this.router.url.includes(deal.id.toString());
    }
    return false;
  }

  onOpenSideBar(): void {
    this.openSideBar.emit();
  }

  onAddNewFolder(): void {
    this.addNewFolder.emit();
  }

  onAddNewDeal(): void {
    this.addNewDeal.emit();
  }

  onSaveFolder(): void {
    this.saveFolder.emit();
  }

  onFixFolder(): void {
    this.fixFolder.emit();
  }

  onFixDeal(): void {
    this.fixDeal.emit();
  }

  onDeleteDeal(deal: DealNavigationLink): void {
    this.deleteDeal.emit(deal);
  }

  onCopyDeal(deal: DealNavigationLink): void {
    this.copyDeal.emit(deal);
  }

  onDragStart(index: number): void {
    this.dragIndex = index;
  }

  onDragEnd(index: number): void {
    this.dropIndex = index;
    if (this.dragIndex) {
      const replacedEvent = this._estimationsList.splice(this.dragIndex, 1);
      if (replacedEvent?.length) {
        this._estimationsList.splice(this.dropIndex, 0, replacedEvent[0]);
        this.onSaveOrderList();
      }
    }
  }

  onSaveOrderList(): void {
    if (this.openFolderNotDraft) {
      const dealsIds = getDealsIds(this._estimationsList);
      this.saveOrderDealList.emit(dealsIds);
    }
  }

  onShowRemarks(): void {
    const remarks = this.estimationsList.find((deal) => !deal.id && deal.folderId);
    this.router.navigate([remarks?.link]);
  }

  isVesselSetted(deal: DealNavigationLink): boolean {
    return deal.vesselName !== NOT_APPLICABLE;
  }

  getField(deal: DealNavigationLink, field: keyof DealNavigationLink): string {
    return deal[field]?.toString() || '';
  }
}
