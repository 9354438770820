import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'estimator-ag-grid-event-group',
  template: ` <span>{{ params?.value }}</span> `,
})
export class AgGridEventGroupRendererComponent implements ICellRendererAngularComp {
  params?: ICellRendererParams;
  color?: string;
  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  refresh(/* params: ICellRendererParams */) {
    return false;
  }
}
