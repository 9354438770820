import { BaseEntity } from './base-entity';
import { Currency } from './currency';
import { UserInputDuration } from './duration';
import { Fuel } from './fuel';
import { Port } from './port';
import { PrimeNgColors, SimpleButtonSwitch, falseText, trueText } from './ui-items';

export interface BaseFinance extends BaseEntity {
  amount?: number;
  currency?: Currency;
  currency_id?: number;
  deal_finance_id?: number;
  price?: number;
  quantity?: number;
}

export interface BunkerFinance extends BaseFinance {
  fuel?: Fuel;
  fuel_id?: number;
}

export interface CargoFinance extends BaseFinance {
  cargo_id?: number;
  address_commission?: number;
  broker_commission?: number;
  voyage_commission?: number;
  tanker_demurrage?: number;
}

export interface DisbursementFinance extends BaseFinance {
  port?: Port;
  port_id?: number;
}

export interface HireFinance extends BaseFinance {
  address_commission?: number;
  // additional_minutes?: number;
  broker_commission?: number;
  hire_minutes?: UserInputDuration;
  hire_per_day?: number;
  extra_idle_minutes?: UserInputDuration;
  extra_sailing_laden_minutes?: UserInputDuration;
  time_charter_commission?: number;
  ballast_bonus?: number;
  commission_affects_bb?: boolean;
}

export interface DealFinance extends BaseEntity {
  bunker_finances?: BunkerFinance[];
  cargo_finances?: CargoFinance[];
  currency?: Currency;
  currency_id?: number;
  disbursement_finances?: DisbursementFinance[];
  hire_finance?: HireFinance;
  result?: number;
  sundry_misc?: number;
  tce?: number;
  /**
   * gross tce
   */
  gtce?: number;
  /**
   * net tce
   */
  ntce?: number;
  co2_eua_amount?: number;
  co2_price?: number;
  co2_total_cost?: number;
}

export interface FinanceTotals {
  freight: number;
  hire: number; // aka running_costs
  bunker: number;
  da: number;
  sundry_misc: number;
  co2_total_cost: number;
  ballast_bonus: number;
  time_charter_commission: number;
}

export enum BallastBonusTypesAlias {
  /**
   * поле commission_affects_bb => true
   */
  GROSS = 'GROSS',
  /**
   * поле commission_affects_bb => false
   */
  NET = 'NET',
}

export enum BallastBonusTypesAliasShort {
  /**
   * поле commission_affects_bb => true
   */
  GROSS = 'GR',
  /**
   * поле commission_affects_bb => false
   */
  NET = 'NET',
}

export const BallastBonusTypes: SimpleButtonSwitch[] = [
  {
    value: trueText,
    color: PrimeNgColors.Transparent,
    short_name: BallastBonusTypesAlias.GROSS,
  },
  { value: falseText, color: PrimeNgColors.Transparent, short_name: BallastBonusTypesAlias.NET },
];
