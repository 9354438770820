import { CommonModule, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { NgModule } from '@angular/core';
import { AutosizeInputFontDirective } from './autosize-input-font.directive';
import { DecimalDirective } from './decimals.directive';
import { HighlightDecimalDirective } from './highlight-decimal.directive';
import { MegamenuDirective } from './megamenu.directive';
import { SelectValueDirective } from './select-value.directive';
import { SwitchTabIndexDirective } from './switch-tab-index.directive';
import { ZeroDecimalDirective } from './zero-decimals.directive';

registerLocaleData(localeFr);

export const DIRECTIVES_LIST = [
  DecimalDirective,
  SelectValueDirective,
  ZeroDecimalDirective,
  SwitchTabIndexDirective,
  MegamenuDirective,
  AutosizeInputFontDirective,
  HighlightDecimalDirective,
];
@NgModule({
  declarations: DIRECTIVES_LIST,
  imports: [CommonModule],
  exports: DIRECTIVES_LIST,
})
export class DirectivesModule {}
