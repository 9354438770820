import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  DEFAULT_USER,
  FormValidationErrors,
  InputTypes,
  LoginForm,
  LoginProps,
  PrimeNgColors,
  PrimeNgIcons,
  SIGN_IN,
} from '@estimator/models';

@Component({
  selector: 'estimator-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginFormComponent {
  @Input()
  set isLoading(state: boolean) {
    if (state) {
      this.loginFormGroup.disable();
    } else {
      this.loginFormGroup.enable();
    }
    this._isLoading = state;
    this.detectChanges();
  }
  get isLoading(): boolean {
    return this._isLoading;
  }
  @Output() signIn = new EventEmitter<LoginProps>();
  @Output() forgotPassword = new EventEmitter<void>();
  loginFormGroup: FormGroup<LoginForm> = new FormGroup<LoginForm>({
    email: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, Validators.email],
    }),
    password: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
    rememberLogin: new FormControl<boolean>(true, { nonNullable: true }),
  });
  isPasswordHidden = true;
  readonly SIGN_IN = SIGN_IN;
  readonly DEFAULT_USER = DEFAULT_USER;
  readonly PrimeNgColors = PrimeNgColors;
  readonly PrimeNgIcons = PrimeNgIcons;
  private _isLoading = false;

  get passwordInputType(): string {
    return this.isPasswordHidden ? InputTypes.Password : InputTypes.Text;
  }

  get passwordIcon(): string {
    return this.isPasswordHidden ? PrimeNgIcons.EYE : PrimeNgIcons.EYE_SLASH;
  }

  get emailControlError(): string | null {
    const errors = this.loginFormGroup.controls.email.errors;
    if (errors) {
      if (errors['email']) {
        return FormValidationErrors.EmailPattern;
      }
      if (errors['required']) {
        return FormValidationErrors.EmailRequired;
      }
    }
    return null;
  }

  get isEmailControlInvalid(): boolean {
    return (
      !!this.emailControlError &&
      this.loginFormGroup.controls.email.dirty &&
      this.loginFormGroup.controls.email.touched
    );
  }

  get passwordControlError(): string | null {
    const errors = this.loginFormGroup.controls.password.errors;
    if (errors) {
      if (errors['required']) {
        return FormValidationErrors.PasswordRequired;
      }
    }
    return null;
  }

  get isPasswordControlInvalid(): boolean {
    return (
      !!this.passwordControlError &&
      this.loginFormGroup.controls.password.dirty &&
      this.loginFormGroup.controls.password.touched
    );
  }

  constructor(private readonly cdr: ChangeDetectorRef) {}

  onSignIn(): void {
    if (this.loginFormGroup.invalid) {
      this.loginFormGroup.markAllAsTouched();
      this.loginFormGroup.updateValueAndValidity();
      this.detectChanges();
      return;
    }
    this.signIn.emit(this.loginFormGroup.value as LoginProps);
  }

  onForgotPassword(): void {
    this.forgotPassword.emit();
  }

  hidePassword(): void {
    this.isPasswordHidden = !this.isPasswordHidden;
    this.detectChanges();
  }

  detectChanges(): void {
    this.cdr.detectChanges();
  }
}
