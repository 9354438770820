import { TitleCasePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { removeUnderlining, userTimeZone } from '@estimator/helpers';
import {
  ChangeUserBlocked,
  ChangeUserRole,
  ChangeUserServiceTypes,
  DomEvents,
  Member,
  MemberRole,
  NOT_APPLICABLE,
  PrimeNgIcons,
  ProductType,
  UserInformation,
  UserListFormGroup,
} from '@estimator/models';
import { cloneDeep } from 'lodash';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { MultiSelectChangeEvent } from 'primeng/multiselect';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'estimator-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserListComponent implements OnDestroy {
  @Input()
  set members(data: Member[]) {
    this.membersFormArray.clear({ emitEvent: false });
    if (data.length) {
      data.forEach((member) => {
        const fg = this.makeUserFormGroup(member);
        const userEmail = fg.getRawValue().user_email;
        fg.controls.user_first_name.valueChanges
          .pipe(takeUntil(this._onDestroy$))
          .subscribe((newFirstName) => {
            const oldFirstName = fg.value.user_first_name;
            if (oldFirstName !== newFirstName && newFirstName && userEmail) {
              const value: UserInformation = {
                user_email: userEmail,
                new_user_first_name: newFirstName,
              };
              this.setUserInfo.emit(value);
            }
          });
        fg.controls.user_last_name.valueChanges
          .pipe(takeUntil(this._onDestroy$))
          .subscribe((newOldName) => {
            const oldLastName = fg.value.user_last_name;
            if (oldLastName !== newOldName && newOldName && userEmail) {
              const value: UserInformation = {
                user_email: userEmail,
                new_user_last_name: newOldName,
              };
              this.setUserInfo.emit(value);
            }
          });
        /* fg.controls.user_email.valueChanges.pipe(takeUntil(this._onDestroy$)).subscribe((newEmail) => {
          if (fg.valid) {
            const oldEmail = fg.value.user_email;
            if (oldEmail !== newEmail && newEmail && userEmail) {
              const value: UserInformation = {
                user_email: userEmail,
                new_user_last_name: newEmail,
              };
              this.setUserInfo.emit(value);
            }
          }
        }); */
        this.membersFormArray.push(fg);
      });
      this._members = cloneDeep(data);
    }
  }
  get members(): Member[] {
    return this._members;
  }
  @Input() availableServices: string[] = [];
  @Input() isLoading = false;
  @Output() changeBlocked = new EventEmitter<ChangeUserBlocked>();
  @Output() changeUserRole = new EventEmitter<ChangeUserRole>();
  @Output() changeUserServiceTypes = new EventEmitter<ChangeUserServiceTypes>();
  @Output() resendInviteUser = new EventEmitter<string>();
  @Output() cancelInviteUser = new EventEmitter<string>();
  @Output() setUserInfo = new EventEmitter<UserInformation>();

  membersFormArray: FormArray<FormGroup<UserListFormGroup>> = new FormArray<
    FormGroup<UserListFormGroup>
  >([]);
  tabIndex = 200;
  memberRoles: MemberRole[] = Object.values(MemberRole).filter(
    (item) => item !== MemberRole.SystemAdministrator
  );
  readonly NOT_APPLICABLE = NOT_APPLICABLE;
  readonly PrimeNgIcons = PrimeNgIcons;
  removeUnderlining = removeUnderlining;
  private _members: Member[] = [];
  private _onDestroy$ = new Subject<void>();

  get userTimeZone(): string {
    return userTimeZone();
  }

  constructor(private readonly titleCasePipe: TitleCasePipe) {}

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  makeUserFormGroup(member?: Member): FormGroup<UserListFormGroup> {
    const fg = new FormGroup<UserListFormGroup>(
      {
        user_first_name: new FormControl({
          value: member?.user_first_name || null,
          disabled:
            /* this.equalSystemAdminOrCompanyAdminOrMe(member) || !!member?.invite_user || !!member?.blocked */ false,
        }),
        user_last_name: new FormControl({
          value: member?.user_last_name || null,
          disabled:
            /* this.equalSystemAdminOrCompanyAdminOrMe(member) || !!member?.invite_user || !!member?.blocked */ false,
        }),
        user_email: new FormControl(
          {
            value: member?.user_email || null,
            disabled: true /* this.equalMe(member) */,
          }
          // [Validators.required, Validators.email]
        ),
        service_types: new FormControl({
          value: member?.service_types || [],
          disabled:
            this.equalSystemAdminOrCompanyAdminOrMe(member) ||
            !!member?.invite_user ||
            !!member?.blocked,
        }),
        role: new FormControl({
          value: member?.role || null,
          disabled:
            this.equalSystemAdminOrCompanyAdminOrMe(member) ||
            !!member?.invite_user ||
            !!member?.blocked,
        }),
        blocked: new FormControl(member?.blocked || null),
        invite_user: new FormControl(member?.invite_user || null),
        is_admin: new FormControl(member?.is_admin || null),
        is_me: new FormControl(member?.is_me || null),
      },
      { updateOn: DomEvents.Blur }
    );
    return fg;
  }

  onChangeBlocked(boolean: boolean, userEmail: string): void {
    this.changeBlocked.emit({ userEmail: userEmail, blocked: boolean });
  }

  onResendInvitation(userEmail: string): void {
    this.resendInviteUser.emit(userEmail);
  }

  onCancelInvitation(userEmail: string): void {
    this.cancelInviteUser.emit(userEmail);
  }

  disabledEdit(userForm: FormGroup<UserListFormGroup>): boolean {
    const userFormRaw = userForm.getRawValue() as Member;
    return this.equalSystemAdminOrCompanyAdminOrMe(userFormRaw);
  }

  equalSystemAdmin(member?: Member): boolean {
    return (
      member?.role === MemberRole.SystemAdministrator /* ||
      member?.role === MemberRole.CompanySuperUser */
    );
  }

  equelCompanyAdmin(member?: Member): boolean {
    return !!member?.is_admin;
  }

  equalMe(member?: Member): boolean {
    return !!member?.is_me;
  }

  equalSystemAdminOrCompanyAdminOrMe(member?: Member): boolean {
    return this.equalSystemAdmin(member) || this.equelCompanyAdmin(member) || this.equalMe(member);
  }

  getRolePlaceholder(role: MemberRole): string {
    if (role === MemberRole.SystemAdministrator) {
      return this.titleCasePipe.transform(MemberRole.CompanySuperUser.replace(/_/g, ' '));
    }
    return 'Role';
  }

  hideSystemAdmin(role: MemberRole): MemberRole {
    if (role === MemberRole.SystemAdministrator) {
      return MemberRole.CompanySuperUser;
    }
    return role;
  }

  onRoleChange(event: DropdownChangeEvent, userForm: FormGroup<UserListFormGroup>): void {
    const rawValueFg = userForm.getRawValue();
    const objectChangeUserRole: ChangeUserRole = {
      userEmail: rawValueFg.user_email as string,
      userRole: event.value as MemberRole,
    };
    this.changeUserRole.emit(objectChangeUserRole);
  }

  onChangeServices(event: MultiSelectChangeEvent, userForm: FormGroup<UserListFormGroup>): void {
    const rawValueFg = userForm.getRawValue();
    const objectChangeUserServiceTypes: ChangeUserServiceTypes = {
      userEmail: rawValueFg.user_email as string,
      serviceTypes: event.value as ProductType[],
    };
    this.changeUserServiceTypes.emit(objectChangeUserServiceTypes);
  }
}
