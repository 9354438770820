import {
  DealEvent,
  FleetVessel,
  FleetVesselPosition,
  GeoCoordinates,
  MapTypes,
  Port,
  ROUTE_POINT_TEXT,
} from '@estimator/models';
import { Feature, FeatureCollection } from 'geojson';

export const removeCompanyCollections = <T>(arr: T[], field: string): T[] => {
  return arr.filter((item: T) => {
    const value = item as any;
    return !value[field];
  });
};

export const mapFleetVessels = (item: {
  general_vessel_id?: number;
  name?: string;
}): [number | undefined, string | undefined] => [item.general_vessel_id, item.name];

export const makeFeature = (
  itemPos: FleetVesselPosition,
  fleet: FleetVessel,
  iconColor: string
): Feature => {
  const fixStraightIcon = 45;
  return {
    type: MapTypes.Feature,
    geometry: {
      type: MapTypes.Point,
      coordinates: [itemPos?.longitude as number, itemPos?.latitude as number],
    },
    properties: {
      id: fleet.general_vessel_id,
      fleet_id: fleet.id,
      name: fleet.name,
      courseForMap: (itemPos?.course as number) - fixStraightIcon,
      course: itemPos?.course,
      call_sign: fleet.call_sign,
      length: fleet.length,
      flag: fleet.flag,
      build_year: fleet.built_year,
      dead_weight: fleet.deadweight,
      draught: fleet.draught,
      speed_over_ground: itemPos?.speed,
      matched_port_name: itemPos?.matched_port_name,
      matched_port_unlocode: itemPos?.matched_port_unlocode,
      navigational_status: itemPos?.navigational_status,
      position_update_timestamp: itemPos?.position_update_timestamp,
      iconColor: iconColor,
    },
  };
};

export const makeFeatureHistoryPoint = (itemPos: FleetVesselPosition): Feature => {
  return {
    type: MapTypes.Feature,
    geometry: {
      type: MapTypes.Point,
      coordinates: [itemPos?.longitude as number, itemPos?.latitude as number],
    },
    properties: {
      updated_at: new Intl.DateTimeFormat(undefined, {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      }).format(new Date(itemPos?.updated_at as string)),
    },
  };
};

export const makeFeatureHistoryLineString = (itemPositions: FleetVesselPosition[]): Feature[] => {
  const coordinates: [number, number][] = [];
  for (const itemPosition of itemPositions) {
    const value: [number, number] = [
      itemPosition?.longitude as number,
      itemPosition?.latitude as number,
    ];
    coordinates.push(value);
  }
  return [
    {
      type: MapTypes.Feature,
      geometry: {
        type: MapTypes.LineString,
        coordinates: coordinates,
      },
      properties: {},
    },
  ];
};

export const makeFeaturePort = (port: Port): Feature => {
  return {
    type: MapTypes.Feature,
    geometry: {
      type: MapTypes.Point,
      coordinates: [port?.point?.longitude as number, port?.point?.latitude as number],
    },
    properties: {
      id: port?.id,
      name: port?.name?.toUpperCase(),
      alpha_2: port?.alpha_2,
      time_zone_name: port?.time_zone_name,
      time_zone: port?.time_zone,
    },
  };
};

export const makeFeaturePoint = (point: GeoCoordinates, event: DealEvent): Feature => {
  return {
    type: MapTypes.Feature,
    geometry: {
      type: MapTypes.Point,
      coordinates: [point?.longitude as number, point?.latitude as number],
    },
    properties: {
      name: `${ROUTE_POINT_TEXT} ${point?.latitude.toFixed(2)}/${point?.longitude.toFixed(2)}`,
      time_zone_name: event?.meta?.timezone_name,
      time_zone: event?.meta?.timezone_offset,
    },
  };
};

export const featuresToFeatureCollection = (features: Feature[]): FeatureCollection => {
  return { type: MapTypes.FeatureCollection as const, features: features };
};
