import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CalendarModule } from 'primeng/calendar';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { FormDateSwitcherComponent } from './form-date-switcher.component';

export const FORM_DATE_SWITCHER_MODULE_IMPORTS = [
  CommonModule,
  InputSwitchModule,
  CalendarModule,
  ReactiveFormsModule,
  InputNumberModule,
];

@NgModule({
  declarations: [FormDateSwitcherComponent],
  imports: FORM_DATE_SWITCHER_MODULE_IMPORTS,
  exports: [FormDateSwitcherComponent],
})
export class FormDateSwitcherModule {}
