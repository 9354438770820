import { FormControl } from '@angular/forms';

export interface BaseEntity {
  id?: number;
  created_at?: string | Date | null;
  created_by?: number | null;
  updated_at?: string | Date | null;
  updated_by?: number | null;
  deleted_at?: string | Date | null;
  deleted_by?: number | null;
  created_by_temporary_user?: boolean;
  updated_by_temporary_user?: boolean;
}

export interface BaseModel extends BaseEntity {
  name: string;
}

export interface BaseEntityFormGroup {
  id: FormControl<number>;
  created_at: FormControl<string | Date | null>;
  created_by: FormControl<number | null>;
  updated_at: FormControl<string | Date | null>;
  updated_by: FormControl<number | null>;
  deleted_at: FormControl<string | Date | null>;
  deleted_by: FormControl<number | null>;
  created_by_temporary_user?: FormControl<boolean | null>;
  updated_by_temporary_user?: FormControl<boolean | null>;
}

export interface BaseModelFormGroup extends BaseEntityFormGroup {
  name: FormControl<string | null>;
}

export type itemMap = Map<number | undefined, string | undefined>;

export type itemMapStringNumber = Map<string | undefined, number | undefined>;
