import { Injectable } from '@angular/core';
import { mapStringNumber } from '@estimator/helpers';
import { Fuel, itemMapStringNumber } from '@estimator/models';
import { FuelService } from '@estimator/services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { catchError, finalize, tap, throwError } from 'rxjs';
import { GetAllFuels } from './fuel.actions';

export interface FuelStateModel {
  fuels: Fuel[];
  fuelsMap: itemMapStringNumber;
  loading: boolean;
}

@State<FuelStateModel>({
  name: 'fuel',
  defaults: {
    fuels: [],
    fuelsMap: new Map(),
    loading: false,
  },
})
@Injectable()
export class FuelState {
  @Selector()
  static getIsLoading({ loading }: FuelStateModel): boolean {
    return loading;
  }
  @Selector()
  static getFuels({ fuels }: FuelStateModel): Fuel[] {
    return fuels;
  }
  @Selector()
  static getFuelsMap({ fuelsMap }: FuelStateModel): itemMapStringNumber {
    return fuelsMap;
  }

  constructor(private readonly fuelService: FuelService) {}

  @Action(GetAllFuels)
  getAllVessels({ patchState }: StateContext<FuelStateModel>) {
    patchState({ loading: true });
    return this.fuelService.getAll().pipe(
      tap((fuels) => {
        const fuelsMap = new Map(fuels.map(mapStringNumber));
        patchState({ fuels, fuelsMap });
      }),
      catchError((err) => {
        patchState({ loading: false });
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }
}
