import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  EIGHTY_NUMBER,
  Folder,
  NotificationType,
  SaveFolderDialogConfig,
  SaveFolderEmitter,
  THREE_NUMBER,
  WarningNotifications,
} from '@estimator/models';
import { NotificationService } from '@estimator/services';
import { isObject, isString } from 'lodash';
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'estimator-save-folder',
  templateUrl: './save-folder.component.html',
  styleUrls: ['./save-folder.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SaveFolderComponent implements OnDestroy {
  @Output() saveFolder = new EventEmitter<SaveFolderEmitter>();
  @Output() searchFolder = new EventEmitter<string>();
  folderControl = new FormControl();
  filteredFolders: Folder[] = [];
  label?: string;
  // disabled = false;
  searchValue = '';
  minValueLength = THREE_NUMBER;
  maxValueLength = EIGHTY_NUMBER;
  private _onDestroy$ = new Subject<void>();

  get placeholderFolder(): string {
    return `Folder name must be at least ${this.minValueLength} characters`;
  }

  get disabledSaveFolder(): boolean {
    return this.folderControl?.value
      ? this.folderControl?.value?.length < this.minValueLength
      : true;
  }

  constructor(
    private readonly dialogConfig: DynamicDialogConfig<SaveFolderDialogConfig>,
    private readonly cdr: ChangeDetectorRef,
    private readonly notificationService: NotificationService
  ) {
    if (this.dialogConfig.data) {
      const { saveFolder, searchFolder, foundFolders, label, openFolder } = this.dialogConfig.data;
      if (saveFolder) {
        this.saveFolder = saveFolder;
      }
      if (searchFolder) {
        this.searchFolder = searchFolder;
      }
      foundFolders?.pipe(takeUntil(this._onDestroy$)).subscribe((folders) => {
        this.filteredFolders = folders;
        // .filter((folder) =>
        //  folder.name.toLowerCase().includes(this.searchValue.toLowerCase())
        //  );
        this.cdr.detectChanges();
      });
      if (label) {
        this.label = label;
      }
      if (openFolder) {
        if (openFolder) {
          this.folderControl.setValue(openFolder);
          this.filteredFolders = [openFolder];
        }
      }
    }
  }
  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
    this.searchValue = '';
  }

  onDealSearchFolder(event: AutoCompleteCompleteEvent): void {
    const valueSearchLength = event.query.length;
    if (
      event.query &&
      valueSearchLength >= this.minValueLength &&
      valueSearchLength <= this.maxValueLength
    ) {
      /* this.filteredFolders = this.folders.filter((folder) =>
        folder.name.toLowerCase().includes(event.query.toLowerCase())
      ); */
      this.searchValue = event.query;
      this.searchFolder.emit(event.query.trim());
    }
    if (valueSearchLength > this.maxValueLength) {
      this.notificationService.showNotification(
        WarningNotifications.MaxLimitSearchReach,
        NotificationType.Warning
      );
    }
  }

  onSaveFolder(): void {
    if (this.folderControl.value) {
      const folderControlValue = this.folderControl.value as Folder | string;
      const checkExistFolder = this.filteredFolders.find(
        (folder) =>
          folder.name.toLowerCase() ===
          (isString(folderControlValue) && !isObject(folderControlValue)
            ? folderControlValue.toLowerCase().trim()
            : folderControlValue.name.toLowerCase())
      );
      this.saveFolder.emit({
        folder: checkExistFolder ? checkExistFolder : this.folderControl.value.trim(),
        newFolder: !checkExistFolder ? true : false,
      });
    }
  }
}
