<p-table
  [value]="membersFormArray.controls"
  [resizableColumns]="true"
  class="primeng-form w-full user-list"
  styleClass="p-datatable-sm font-size-12"
  id="user-list_form"
>
  <ng-template pTemplate="header">
    <tr>
      <th pResizableColumn class="w-auto text-center">First name</th>
      <th pResizableColumn class="w-auto text-center">Last name</th>
      <th pResizableColumn class="w-auto text-center">Email</th>
      <th pResizableColumn class="w-auto text-center">Products</th>
      <th pResizableColumn class="w-auto text-center">Role</th>
      <th pResizableColumn class="w-auto text-center">Block user</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-userForm let-i="rowIndex">
    <tr class="primeng-form">
      <td>
        <input
          pInputText
          type="text"
          name="user_first_name"
          class="w-full h-3rem"
          [formControl]="userForm.controls?.user_first_name"
          required
          id="user_first_name"
          [tabindex]="tabIndex"
        />
      </td>
      <td>
        <input
          pInputText
          type="text"
          name="user_last_name"
          class="w-full h-3rem"
          [formControl]="userForm.controls?.user_last_name"
          required
          id="user_last_name"
          [tabindex]="tabIndex"
        />
      </td>
      <td style="text-align: left">
        <!--  <span>{{ userForm.controls?.user_email?.value || NOT_APPLICABLE }}</span> -->
        <span class="p-input-icon-left w-full">
          <i *ngIf="userForm.controls?.is_admin.value" [class]="PrimeNgIcons.SHIELD"></i>
          <input
            [ngClass]="{ 'admin-padding': userForm.controls?.is_admin.value }"
            pInputText
            type="text"
            name="user_email"
            class="w-full h-3rem"
            placeholder="Email"
            [formControl]="userForm.controls?.user_email"
            required
            id="user_email"
            [tabindex]="tabIndex"
          />
        </span>
      </td>
      <td class="width-250">
        <p-multiSelect
          [disabled]="isLoading"
          [options]="availableServices"
          [formControl]="userForm?.controls?.service_types"
          [showHeader]="false"
          [inputId]="'user-list_form-' + i"
          [tabindex]="tabIndex"
          appendTo="body"
          placeholder="Choose products"
          display="chip"
          class="hide-multiselect-button"
          styleClass="width-250"
          (onChange)="onChangeServices($event, userForm)"
        >
        </p-multiSelect>
      </td>
      <td class="width-250">
        <p-dropdown
          [disabled]="isLoading"
          [options]="memberRoles"
          [placeholder]="getRolePlaceholder(userForm?.controls?.role?.value)"
          [formControl]="userForm?.controls?.role"
          [inputId]="'user-list_form-' + i"
          [tabindex]="tabIndex"
          [scrollHeight]="'300px'"
          appendTo="body"
          class="hide-dropdown-button w-100"
          styleClass="width-250 h-3rem"
          inputStyleClass="w-100"
          (onChange)="onRoleChange($event, userForm)"
        >
          <ng-template let-role pTemplate="item"
            >{{ removeUnderlining(role) | titlecase }}
          </ng-template>
          <ng-template pTemplate="selectedItem"
            >{{ removeUnderlining(hideSystemAdmin(userForm?.controls?.role?.value)) | titlecase }}
          </ng-template>
        </p-dropdown>
      </td>
      <td class="width-250">
        <div class="flex-container-row" *ngIf="userForm?.controls?.invite_user?.value">
          <span class="margin-top-15">Pending...</span>
          <p-button
            class="send-invitation-button margin-left-10"
            styleClass="h-3rem"
            size="small"
            type="button"
            label="Resend invitation"
            (click)="onResendInvitation(userForm?.controls?.user_email?.value)"
            [disabled]="isLoading"
            [loading]="isLoading"
          ></p-button>
          <div class="warning-container">
            <p-button
              class="send-invitation-button margin-left-10"
              styleClass="h-3rem warning"
              size="small"
              type="button"
              label="Cancel invitation"
              (click)="onCancelInvitation(userForm?.controls?.user_email?.value)"
              [disabled]="isLoading"
              [loading]="isLoading"
            ></p-button>
          </div>
        </div>
        <estimator-switch-editor
          *ngIf="!userForm?.controls?.invite_user?.value"
          [disabled]="isLoading || disabledEdit(userForm)"
          [value]="userForm?.controls?.blocked?.value"
          (changeValue)="onChangeBlocked($event, userForm?.controls?.user_email?.value)"
        ></estimator-switch-editor>
      </td>
    </tr>
  </ng-template>
</p-table>
