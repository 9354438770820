import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { API_URL } from '@estimator/landing-services';
import { LandingUiModule } from '@estimator/landing-ui';
import { CommonSiteModule } from '@mar/common-site';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    CommonModule,
    CommonSiteModule,
    LandingUiModule,
    HttpClientModule,
  ],
  providers: [{ provide: API_URL, useValue: environment.STRAPI_DOMAIN }],
  bootstrap: [AppComponent],
})
export class AppModule {}
