import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { TabViewModule } from 'primeng/tabview';
import { CoordinateInputModule } from '../coordinate-input/coordinate-input.module';
import { CoordinateDialogComponent } from './coordinate-dialog.component';

@NgModule({
  declarations: [CoordinateDialogComponent],
  imports: [TabViewModule, ButtonModule, CoordinateInputModule],
  exports: [CoordinateDialogComponent],
})
export class CoordinateDialogModule {}
