export const BUNKER_FEATURES = [
  {
    title: 'Bunker purchasing',
    description:
      'Simplify your bunker fuel sourcing with access to over 1,500 suppliers in 500 ports around the world. XMAR connects you to multiple physical suppliers for every enquiry, ensuring competitive pricing and smooth transactions for your fuel needs.',
    benefits: [
      { title: 'Real-time competitive offers from global suppliers.', icon: 'world-wide' },
      { title: 'Dedicated live chat support for every order.', icon: 'live-chat' },
      { title: 'End-to-end purchasing, from sourcing to delivery.', icon: 'vessel' },
    ],
  },
  {
    title: 'Transparent finance options',
    description:
      'XMAR provides clear, flexible finance options to help you manage cash flow and payment schedules. After a credit check, you’ll receive a line of credit, with flexible payment terms to suit your needs.',
    benefits: [
      { title: 'Choose 7, 15, or 30-day payment terms for each order.', icon: 'calculator' },
      {
        title: 'Track offers, orders, deliveries, and payments in one platform.',
        icon: 'bunker-trading-platform-light',
      },
      {
        title: 'Increase transparency of your transactions, from confirmed orders to due dates.',
        icon: 'dollar',
      },
    ],
  },
  {
    title: 'Bunker price index',
    description:
      'Stay informed with XMAR’s live market price index, giving you real-time access to bunker prices across the top 100 ports worldwide. This allows you to make data-driven fuel purchasing decisions for better cost control.',
    benefits: [
      { title: 'View market prices updated daily.', icon: 'price-chart' },
      { title: 'Access price data across 100 major global ports.', icon: 'uspeh' },
      {
        title: 'Make data driven purchase decisions.',
        icon: 'shopping-cart',
      },
    ],
  },
  {
    title: 'Voyage planning',
    description:
      'Optimize your fleet’s efficiency with our route planning and fuel consumption tools. XMAR’s voyage planning features are designed to help reduce operational costs and boost profits by minimizing fuel usage.',
    benefits: [
      { title: 'Route optimization to minimize fuel consumption.', icon: 'map-point' },
      { title: 'Forecast fuel needs for upcoming voyages.', icon: 'forecast' },
      { title: 'Improve operational efficiency to cut costs.', icon: 'improvement' },
    ],
  },
  {
    title: 'Vessel watch via email integration',
    description:
      'XMAR’s vessel watch feature offers real-time monitoring of open vessels by translating emails into actionable insights. This feature keeps you informed and helps you identify opportunities for your company.',
    benefits: [
      { title: 'Translate email data into a list of open vessels.', icon: 'email' },
      { title: 'Monitor vessel availability with real-time updates.', icon: 'clock' },
      { title: 'Search and filter to stay ahead of the market.', icon: 'search' },
    ],
  },
];
