import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { mglMapAccessToken } from '@estimator/models';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { CheckboxModule } from 'primeng/checkbox';
import { InputSwitchModule } from 'primeng/inputswitch';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RouteMapComponent } from './route-map.component';

@NgModule({
  declarations: [RouteMapComponent],
  imports: [
    CommonModule,
    NgxMapboxGLModule.withConfig({
      accessToken: mglMapAccessToken,
    }),
    CheckboxModule,
    FormsModule,
    MatIconModule,
    InputSwitchModule,
    ReactiveFormsModule,
    RadioButtonModule,
  ],
  exports: [RouteMapComponent],
})
export class RouteMapModule {}
