import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { searchText, textVoyageType, usersText } from '@estimator/helpers';
import {
  Folder,
  FolderFilterModel,
  GetAllDeals,
  GetAllDealsWithParams,
  GetAllFolders,
  GetAllFoldersWithParams,
  VoyageType,
  setLimitAndOffsetToParams,
  setSortModelToParams,
  trueText,
} from '@estimator/models';
import { SortModelItem } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { BaseService } from '../base';

@Injectable({
  providedIn: 'root',
})
export class FoldersService extends BaseService<Folder> {
  private _filtersText = 'filters';
  private _dealsText = 'deals';
  private _searchLightText = 'search/light';
  constructor(protected override readonly http: HttpClient) {
    super(http);
    this.setPath('/api/folders');
  }

  getAllWithParams(
    limit: number,
    offset: number,
    sortModelItem: SortModelItem,
    filterModel: FolderFilterModel[]
  ): Observable<GetAllFoldersWithParams> {
    let params = new HttpParams();
    params = setLimitAndOffsetToParams(params, limit, offset);
    if (sortModelItem) {
      params = setSortModelToParams(params, sortModelItem);
    }
    if (filterModel) {
      for (const filter of filterModel) {
        params = params.append(this._filtersText, JSON.stringify(filter));
      }
    }
    return this.http.get<GetAllFoldersWithParams>(this.path, { params });
  }

  getDealsByFolderID(
    id: number,
    limit: number,
    offset: number,
    sortModelItem: SortModelItem,
    filterModel: FolderFilterModel[]
    // type: VoyageType
  ): Observable<GetAllDealsWithParams> {
    let params = new HttpParams();
    params = setLimitAndOffsetToParams(params, limit, offset);
    if (sortModelItem) {
      params = setSortModelToParams(params, sortModelItem);
    }
    /* if (type) {
      params = params.set(textVoyageType, type);
    } */
    if (filterModel) {
      for (const filter of filterModel) {
        params = params.append(this._filtersText, JSON.stringify(filter));
      }
    }
    return this.http.get<GetAllDealsWithParams>(`${this.path}/${id}/${this._dealsText}`, {
      params,
    });
  }

  lightSearch(
    type: VoyageType,
    valueSearch: string,
    users: string,
    byFolderNameOnly?: boolean
  ): Observable<GetAllFolders> {
    let params = new HttpParams();
    params = params.set(textVoyageType, type); // обязательное поле
    if (valueSearch) {
      params = params.set(searchText, valueSearch); // поиск по полям folder_name/deal_name/vessel_name/company_name
    }
    // список emails через запятую (test1@gmail.com, test2@gmail.com - должны быть из одной компании, иначе будет ошибка)
    if (users.length) {
      params = params.set(usersText, users);
    }
    if (byFolderNameOnly) {
      params = params.set('by_folder_name_only', trueText);
    }
    return this.http.get<GetAllFolders>(`${this.path}/${this._searchLightText}`, { params });
  }

  // метод аналогичен lightSearch, но внутри папки
  lightSearchByFolderID(
    folderId: number,
    type: VoyageType
    /*    valueSearch: string,
    users: string */
  ): Observable<GetAllDeals> {
    let params = new HttpParams();
    params = params.set(textVoyageType, type); // обязательное поле
    /*  if (valueSearch) {
      params = params.set(searchText, valueSearch); // поиск по полям folder_name/deal_name/vessel_name/company_name
    }
    // список emails через запятую (test1@gmail.com, test2@gmail.com - должны быть из одной компании, иначе будет ошибка)
    if (users.length) {
      params = params.set(usersText, users);
    } */
    return this.http.get<GetAllDeals>(
      `${this.path}/${folderId}/${this._dealsText}/${this._searchLightText}`,
      { params }
    );
  }

  copyFolder(
    folderId: number,
    copyFolderName?: string,
    temporary?: boolean,
    fixed?: boolean,
    ids?: number[]
  ): Observable<Folder> {
    let params = new HttpParams();
    if (copyFolderName) {
      params = params.set('name', copyFolderName);
    }
    if (temporary) {
      params = params.set('mark_temporary', temporary);
    }
    if (fixed) {
      params = params.set('mark_fixed', fixed);
    }
    if (ids?.length) {
      for (const id of ids) {
        params = params.append('id', JSON.stringify(id));
      }
    }
    return this.http.post<Folder>(`${this.path}/${folderId}/copy`, null, { params });
  }

  setDealsOrderInFolder(folderId: number, dealIds: number[]): Observable<void> {
    const body = { deal_ids: dealIds };
    return this.http.put<void>(`${this.path}/${folderId}/set-order`, body);
  }

  setFolderPinStatus(folderId: number, isPinned: boolean): Observable<void> {
    let params = new HttpParams();
    params = params.set('is_pinned', isPinned);
    return this.http.put<void>(`${this.path}/${folderId}/set-is-pinned`, null, { params });
  }
}
