<form
  [formGroup]="priceAndCurrencyFormGroup"
  class="flex-container-row p-inputgroup primeng-form"
  [ngClass]="{ 'p-float-label': priceLabel, 'show-invalid': invalidName }"
>
  <p-inputNumber
    [useGrouping]="false"
    [minFractionDigits]="0"
    [maxFractionDigits]="2"
    [min]="min"
    [max]="max"
    [step]="0"
    formControlName="price"
    class="p-inputtext-sm"
    [inputStyleClass]="
      showCurrency || borderRightNone
        ? 'none-border-radius-right border-right-0'
        : placeholderRight
        ? 'placeholder-right'
        : ''
    "
    [inputId]="id"
    [tabindex]="customTabIndex"
    [format]="false"
    [placeholder]="placeholder"
    estimatorDecimals
  ></p-inputNumber>
  <label class="color" *ngIf="priceLabel" [htmlFor]="id">{{ priceLabel }}</label>
  <p-dropdown
    *ngIf="showCurrency"
    [options]="currencies"
    styleClass="w-100 zero-border-radius-left none-border-radius-left border-left-0"
    class="p-inputtext-sm hide-dropdown-button da-dropdown"
    [inputId]="id + '-currency'"
    formControlName="currency"
    optionLabel="currency_code"
    appendTo="body"
  >
  </p-dropdown>
</form>
