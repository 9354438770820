import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CardSkewDirective } from './card-skew.directive';
import { TextScrollDirective } from './text-scroll.directive';

@NgModule({
  declarations: [TextScrollDirective, CardSkewDirective],
  imports: [CommonModule],
  exports: [TextScrollDirective, CardSkewDirective],
})
export class DirectivesModule {}
