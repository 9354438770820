import { BaseEntity, BaseModel } from './base-entity';
import { GeoCoordinates } from './geographic';

export interface Port extends BaseEntity {
  code?: string;
  country?: string;
  alpha_2?: string;
  iso_3?: string;
  iso_3_op?: string;
  last_checked?: string;
  latitude?: number;
  location_precision?: string;
  longitude?: number;
  maximum_depth?: number;
  maximum_length?: number;
  name?: string;
  point?: GeoCoordinates;
  port_size?: BaseModel;
  port_size_id?: number;
  port_type?: BaseModel;
  port_type_id?: number;
  remarks?: string;
  source?: string;
  time_zone?: number;
  time_zone_name?: string;
  is_eca?: boolean;
  /**
   * for front only, use in shiplist filter zone/port array
   */
  is_zone?: boolean;
  /**
   * uses also in GeoZone, ShiplistSelectedZoneOrPort, ShiplistGeoZoneProperties
   */
  loc_id?: number;
  zone_ids?: number[];
  routing_point_id?: number;
  /**
   * use for show in selectedItem(autocomplete) like City, Country
   */
  name_country?: string;
}

export interface TestPort extends Port {
  distance1?: string;
  distance2?: string;
}

export interface PortWidgetData {
  port_name: string;
  status: PortStatus;
  port_time: number;
  distance: number;
  eca_distance: number;
  sailing_minutes: number;
  type: string;
}

export enum PortStatus {
  ECA = 'eca',
  DEFAULT = '',
}

export const STATUS_CLASSES = {
  [PortStatus.ECA]: 'status-eca',
  [PortStatus.DEFAULT]: 'status-default',
};
