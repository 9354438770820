import { DealEvent, DealEventType } from '@estimator/models';
import { ColumnMenuTab, NewValueParams } from 'ag-grid-community';
import * as moment from 'moment';

export const agGridMomentDateComparator = (filterDate: Date, cellDate: string | Date): number => {
  const momentFilter = moment.utc(filterDate);
  const momentCell = moment.utc(cellDate);
  momentFilter.set({ hour: 0, minute: 0, second: 0 });
  momentCell.set({ hour: 0, minute: 0, second: 0 });
  if (momentFilter.isBefore(momentCell)) {
    return 1;
  }
  if (momentFilter.isAfter(momentCell)) {
    return -1;
  }
  return 0;
};

export const isDealEventTypeCellChanged = (params: NewValueParams<DealEvent>): boolean => {
  return (
    params.oldValue &&
    params.newValue &&
    params.newValue !== params.oldValue &&
    ((params.newValue === DealEventType.EventTypeBunker &&
      (params.oldValue === DealEventType.EventTypeLoad ||
        params.oldValue === DealEventType.EventTypeDischarge)) ||
      ((params.newValue === DealEventType.EventTypeLoad ||
        params.newValue === DealEventType.EventTypeDischarge) &&
        params.oldValue === DealEventType.EventTypeBunker))
  );
};

export const filterOptions = ['contains', 'equals', 'notEqual', 'startsWith', 'endsWith'];

export const textFilterParams = {
  applyMiniFilterWhileTyping: true,
  filterOptions: filterOptions,
  suppressAndOrCondition: true,
};

export const threeMenuTabs: ColumnMenuTab[] = ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'];
export const twoMenuTabs: ColumnMenuTab[] = ['generalMenuTab', 'columnsMenuTab'];

export const textCompanyIdField = 'company_id';
export const textNameDeal = 'name_deal';
export const textVesselName = 'vessel_name';
export const textVessel = 'vessel';
export const textCreatedBy = 'created_by';
export const textFolderName = 'folder_name';
export const textVoyageType = 'voyage_type';
export const isArchivedText = 'is_archived';

export const searchText = 'search';
export const usersText = 'users';
