import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { DurationPipe } from '@estimator/helpers';
import { NotificationType, RegistrationReminderDialogConfig, SIGN_UP } from '@estimator/models';
import * as moment from 'moment';
import { Duration } from 'moment';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'estimator-registration-reminder',
  templateUrl: './registration-reminder.component.html',
  styleUrls: ['./registration-reminder.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegistrationReminderComponent implements OnInit {
  @Input() restTime: Duration = moment.duration(0);
  @Output() signUp = new EventEmitter<void>();

  get timeInMinutes(): number {
    return this.restTime.asMinutes();
  }
  readonly NotificationType = NotificationType;
  SIGN_UP = SIGN_UP;

  get reminderText(): string {
    return `You have ${this.durationPipe.transform(
      this.timeInMinutes
    )} left to register. Your transactions will be deleted after this time.`;
  }

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly durationPipe: DurationPipe,
    private readonly dialogConfig: DynamicDialogConfig<RegistrationReminderDialogConfig>
  ) {
    if (this.dialogConfig.data) {
      const { restTime, signUp } = this.dialogConfig.data;
      if (restTime) {
        this.restTime = restTime;
      }
      if (signUp) {
        this.signUp = signUp;
      }
    }
  }

  ngOnInit(): void {
    setInterval(() => {
      this.restTime = moment.duration(this.restTime.asSeconds() - 1, 'seconds');
      this.cdr.detectChanges();
    }, 1000);
  }

  onSignUp(): void {
    this.signUp.emit();
  }
}
