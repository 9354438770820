import { EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Duration } from 'moment';
import { Observable, Subject } from 'rxjs';
import { BaseModel } from './base-entity';
import { Currency } from './currency';
import { UiSettingsStateModel } from './ui-settings';
import { VesselTypes } from './vessel';

export const TOKEN_KEY = 'token';
export const ACCESS_TOKEN_KEY = 'accessToken';
export const REFRESH_TOKEN_KEY = 'refreshToken';
export const USER_CONFIRMATION_KEY = 'userConfirmation';
export const CONFIRM_PATH = 'confirm';
export const USER_CONFIRMATION_INVITE_KEY = 'userConfirmationInvite';
export const INVITATION_PATH = 'invitation';

export interface LoginProps {
  email: string;
  password: string;
  rememberLogin?: boolean;
}

export interface LoginForm {
  email: FormControl<string>;
  password: FormControl<string>;
  rememberLogin: FormControl<boolean>;
}

export interface LoginResponse {
  refresh_token: string;
  token: string;
}

export interface XAuthLoginResponse {
  access_token: string;
  refresh_token: string;
}

export interface TokenEntity {
  id: number;
  company_id: number;
  company_uuid?: string;
  email: string;
  exp: number;
  temporary?: boolean;
  service_types?: ProductType[];
  iss?: string; // issuer (кто выпустил токен)
  settings?: UiSettingsStateModel;
  user_platform_role?: string; // потом enum, вероятно
  user_role?: MemberRole;
  user_uuid?: string;
  with_fuel_consumptions_parser?: boolean; // есть доступ к парсеру axsmarine для глубокого поиска судов
  /**
   * allow to see fuel prices
   */
  with_xbpi?: boolean;
}

export interface RegisterProps {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  company_name: string;
  subscription_model_id: number;
  temporary_user_id?: number;
}

export interface RegisterResponse {
  token: string;
}

export interface XAuthRegisterResponse {
  wait_confirmation_token: string;
}

export interface BasicResponse {
  success: boolean;
}

export interface RegisterForm {
  first_name: FormControl<string>;
  last_name: FormControl<string>;
  email: FormControl<string>;
  password: FormControl<string>;
  passwordConfirm: FormControl<string>;
  company_name: FormControl<string>;
  rulesAgreed: FormControl<boolean>;
  subscription_model_id: FormControl<number | null>;
}

export interface AuthFormDialogConfig {
  forgotPasswordEmmiter: EventEmitter<void>;
  signInEmitter: EventEmitter<LoginProps>;
  signUpEmitter: EventEmitter<RegisterProps>;
  signInErrorSubject: Subject<string>;
  signUpErrorSubject: Subject<string>;
  loadingSubject: Subject<boolean>;
  subscriptionModels?: Observable<UserSubscription[]>;
  isSignUp?: boolean;
  presetProduct?: ProductType;
}

export interface RegistrationSuccessDialogConfig {
  email: string;
  retryEmailErrorSubject: Subject<string>;
  retryEmail: EventEmitter<void>;
  sendConfirmCode: EventEmitter<string>;
}

export interface PasswordForgotAndRestoreDialogConfig {
  isLoadingSubject: Subject<boolean>;
  responseErrorSubject: Subject<string>;
  restoreSuccessSubject: Subject<boolean>;
  restorePassword: EventEmitter<string>;
  signIn?: EventEmitter<void>;
}

export interface RegistrationReminderDialogConfig {
  restTime: Duration;
  signUp: EventEmitter<void>;
}

export interface LoginReminderDialogConfig {
  signIn: EventEmitter<void>;
}

export interface UserConfirmDialogConfig {
  confirmSuccess: boolean;
  responseError: string;
  begin: EventEmitter<void>;
}

export interface UserConfirmInvitationDialogConfig {
  confirm: EventEmitter<void>;
}

export enum ProductType {
  Tanker = 'TANKER',
  Bulk = 'BULK',
}

export interface UserService extends BaseModel {
  type?: ProductType;
}

export interface GetUserSubscriptionModelsResponse {
  subscription_models: UserSubscription[];
}

export interface UserSubscription extends BaseModel {
  currency?: Currency;
  currency_id?: number;
  price?: number;
  services?: UserService[];
}

export interface CompanyInformation {
  administrator_id?: number; // 1 на компанию, тут лежит user_id из members
  company_name?: string;
  company_payment_address?: string;
  company_legal_address?: string;
  company_settings?: CompanySetting[];
}

// deductibles
export interface CompanySetting {
  id?: number;
  company_id?: number;
  type?: VesselTypes;
  deductibles?: number;
  min_dwt?: number;
  max_dwt?: number;
}

export interface CompanySettingFormGroup {
  id?: FormControl<number | null>;
  company_id?: FormControl<number | null>;
  type?: FormControl<VesselTypes | null>;
  deductibles?: FormControl<number | null>;
  min_dwt?: FormControl<number | null>;
  max_dwt?: FormControl<number | null>;
}

export interface FullCompanyInformation extends CompanyInformation {
  members?: Member[];
  invited_users?: Member[];
}

export interface Member {
  user_first_name?: string;
  user_last_name?: string;
  user_email?: string;
  service_types?: ProductType[];
  /**
   * может быть только 1 роль
   */
  role?: MemberRole;
  blocked?: boolean;
  /**
   * для фронта поле, чтобы отличать массив members и invited_users
   */
  invite_user?: boolean;
  /**
   * есть только у invited_users
   */
  id?: number;
  /**
   * есть только у members
   */
  user_id?: number;
  /**
   * для фронта поле, чтобы подсветить админа компании
   */
  is_admin?: boolean;
  /**
   * для фронта поле, чтобы подсветить себя самого
   */
  is_me?: boolean;
}

export enum MemberRole {
  CompanyCommonUser = 'company_common_user',
  CompanySuperUser = 'company_super_user',
  CompanyShipowner = 'company_shipowner',
  CompanySupplier = 'company_supplier',
  SystemAdministrator = 'system_administrator', // system_administrator - есть в базе, но на интерфейсе такого показываем как company_super_user
  CompanySuperOperator = 'company_super_operator',
  CompanyOperator = 'company_operator',
  ControllingOperator = 'controlling_operator',
}

// fg на основании модели Member
export interface UserListFormGroup {
  user_first_name: FormControl<string | null>;
  user_last_name: FormControl<string | null>;
  user_email: FormControl<string | null>;
  service_types: FormControl<ProductType[] | null>;
  role: FormControl<MemberRole | null>;
  blocked: FormControl<boolean | null>;
  invite_user?: FormControl<boolean | null>;
  is_admin?: FormControl<boolean | null>;
  is_me?: FormControl<boolean | null>;
}

export interface ChangeUserBlocked {
  userEmail: string;
  blocked: boolean;
}

export interface ChangeUserRole {
  userEmail: string;
  userRole: MemberRole;
}

export interface ChangeUserServiceTypes {
  userEmail: string;
  serviceTypes: ProductType[];
}

export interface ResponseChangeUser {
  member: Member;
}

export interface GetCompanyAvailableServices {
  service_types: ProductType[];
}

export interface UserInformation {
  /**
   * текущая почта, обязательное поле
   */
  user_email: string;
  /**
   * новая почта
   */
  // new_user_email?: string;
  /**
   * новое имя
   */
  new_user_first_name?: string;
  /**
   * новая фамилия
   */
  new_user_last_name?: string;
}

export interface GetCompanyDeductiblesResponse {
  name: string;
  settings: CompanySetting[];
}
