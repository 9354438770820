import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'mar-badge',
  template: `
    <div
      class="badge f-header t-uppercase {{ color }} {{ type }} {{ 'background-' + background }}"
      [ngClass]="{ square: type === 'trademark' || type === 'square' }"
    >
      <ng-content></ng-content>
    </div>
  `,
  styles: [
    `
      @import 'variables';
      :host {
        .badge {
          display: inline-flex;
          padding: 0.8rem 1.6rem;
          border: 1px solid $white;
          white-space: nowrap;
          &.rounded {
            border-radius: 2rem;
          }
          &.square {
            border-radius: 1rem;
            padding: 0.6rem;
            font-size: 1.6rem;
          }
        }
        .green {
          border: 1px solid $green;
        }
        .blue {
          border: 1px solid $blue;
        }
        .primary {
          border: 1px solid $primary;
        }
        .background-white {
          background-color: $white;
          border-color: $white;
          color: $primary;
        }
        .background-primary {
          background-color: $primary;
          color: $white;
        }
        .trademark {
          background-image: url(/assets/icon-x-mar.svg);
          background-repeat: no-repeat;
          background-position: 6px center;
          &:before {
            content: '';
            padding-left: 1.8rem;
          }
          &:after {
            content: 'tm';
            font-size: 0.7rem;
            padding: 2px 0 0 2px;
          }
        }
      }
    `,
  ],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeComponent {
  @Input() color?: 'blue' | 'green' | 'primary' = 'blue';
  @Input() type?: 'square' | 'rounded' | 'trademark' = 'rounded';
  @Input() background?: 'primary' | 'white' | '' = '';
}
