import { FormControl } from '@angular/forms';
import { BaseEntity, BaseModel, CompanySetting, Currency, Port, Vessel } from '@estimator/models';

export interface Beneficiary extends BaseModel {
  administrator_id?: number;
  bank_details?: any[];
  country?: string;
  email?: string;
  flexible_fields?: any[];
  is_temporary?: boolean;
  legal_address?: string;
  payment_address?: string;
  risk_grade?: string;
  settings?: CompanySetting[];
  type?: any[];
  uuid?: string;
  with_xbpi?: boolean;
  with_fuel_consumptions_parser?: boolean;
}

export interface Transaction extends BaseEntity {
  agreement_id?: number;
  amount?: number;
  base_currency_amount?: number;
  beneficiary?: Beneficiary;
  beneficiary_id?: number;
  cargo_id?: number;
  currency?: Currency;
  creation_source?: TransactionCreationSource;
  currency_id?: number;
  base_currency?: Currency;
  base_currency_id?: number;
  description?: string;
  event_id?: number;
  exchange_rate_amount_to_base?: number;
  exchange_rate_amount_to_paid?: number;
  market_exchange_rate?: number;
  hire_agreement_id?: number;
  invoice_id?: number;
  parsed_vessel?: Vessel;
  parsed_vessel_id?: number;
  payer?: Beneficiary;
  payer_id?: number;
  payment_date?: Date;
  payment_due_date?: Date;
  period_end?: Date;
  period_start?: Date;
  port?: Port;
  port_id?: number;
  price_per_unit?: number;
  quantity?: number;
  share?: number;
  status?: VMSTransactionStatus;
  subtype?: VMSTransactionSubType;
  time_offset?: number;
  type?: VMSTransactionType;
  unit?: BaseModel;
  unit_id?: number;
  payments?: TransactionPayment[];
}

export interface TransactionPayment {
  id?: number | null;
  cf_transaction_id?: number | null;
  currency_id?: number | null;
  exchange_rate_amount_to_base?: number | null;
  amount?: number | null;
  payment_date?: Date | string | null;
}

export interface PaymentsFormGroup {
  amount?: FormControl<number | null>;
  id?: FormControl<number | null>;
  exchange_rate_amount_to_base?: FormControl<number | null>;
  currency_id?: FormControl<number | null>;
  payment_date?: FormControl<Date | string | null>;
  cf_transaction_id?: FormControl<number | null>;
}

export enum TransactionCreationSource {
  MANUAL = 'MANUAL',
  HSOA = 'HSOA',
  VSOA = 'VSOA',
}

export enum VMSTransactionType {
  NONE = '',
  HIRE = 'HIRE',
  BUNKERING = 'BUNKERING',
  FREIGHT = 'FREIGHT',
  DISBURSEMENTS = 'DISBURSEMENTS',
  OTHER_EXPENSES = 'OTHER EXPENSES',
  INSURANCE = 'INSURANCE',
  BROKERAGE = 'BROKERAGE',
}

export enum VMSTransactionSubType {
  NONE = '',
  BUNKER_SUPPLY = 'BUNKER SUPPLY',
  PDA_AT_LOAD_PORT = 'PDA AT LOAD PORT',
  PDA_AT_DISCHARGE_PORT = 'PDA AT DISCHARGE PORT',
  PDA_AT_SUEZ_CANAL = 'PDA AT SUEZ CANAL',
  PDA_AT_TURKISH_STRAITS = 'PDA AT TURKISH STRAITS',
  PDA_AT_PANAMA_CANAL = 'PDA AT PANAMA CANAL',
  PDA_BUNKERING_PORT = 'PDA BUNKERING PORT',
  PDA_OTHERS = 'PDA OTHERS',
  FDA_AT_LOAD_PORT = 'FDA AT LOAD PORT',
  FDA_AT_DISCHARGE_PORT = 'FDA AT DISCHARGE PORT',
  FDA_AT_SUEZ_CANAL = 'FDA AT SUEZ CANAL',
  FDA_AT_TURKISH_STRAITS = 'FDA AT TURKISH STRAITS',
  FDA_AT_PANAMA_CANAL = 'FDA AT PANAMA CANAL',
  FDA_BUNKERING_PORT = 'FDA BUNKERING PORT',
  FDA_OTHERS = 'FDA OTHERS',
  PILOTAGE = 'PILOTAGE',
  TOWAGE = 'TOWAGE',
  FIRST = '1ST',
  SECOND = '2ND',
  THIRD = '3RD',
  FOURTH = '4TH',
  FIFTH = '5TH',
  SIXTH = '6TH',
  SEVENTH = '7TH',
  EIGHTH = '8TH',
  NINTH = '9TH',
  TENTH = '10TH',
  PRE_FINAL = 'PRE-FINAL',
  FINAL = 'FINAL',
  FREIGHT = 'FREIGHT',
  VSOA = 'VSOA',
  OTHER = 'OTHER',
  SURVEY = 'SURVEY',
  UWHI_C = 'UWHI/C',
  OCEAN_ROUTING = 'OCEAN ROUTING',
  PREMIUM_FDD = 'PREMIUM FDD',
  PREMIUM_P_I = 'PREMIUM P&I',
  LEGAL_FEES = 'LEGAL FEES',
  COMISSION_ON_TCP = 'COMISSION ON TCP',
  COMISSION_ON_VCP = 'COMISSION ON VCP',
}

export enum VMSTransactionStatus {
  DRAFT = 'DRAFT',
  PENDING = 'PENDING',
  CONFIRMED = 'CONFIRMED',
  INVOICED = 'INVOICED',
  PAID = 'PAID',
  PLANNED = 'PLANNED',
  OVERDUE = 'OVERDUE',
}

export enum SubAgreementType {
  HSOA = 'HSOA',
  VSOA = 'VSOA',
}
