import { DealEventType } from '@estimator/models';

/**
 * slug like value
 */
export const cargoEvents: CargoEvent[] = [
  { name: 'Loading', slug: DealEventType.EventTypeLoad },
  { name: 'Discharging', slug: DealEventType.EventTypeDischarge },
];

export interface CargoEvent {
  name: string;
  slug: string;
}
