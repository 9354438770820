export interface PortWater {
  name: string;
  short_name: string;
  value: WaterType;
  information?: string;
}

export enum WaterType {
  SALT = 'salt',
  BRACKISH = 'brackish',
  FRESH = 'fresh',
  TROPICAL = 'tropical',
}
