import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'mar-sidebar',
  template: ` <div class="modal-overlay" *ngIf="show" (click)="onClose()"></div>
    <div class="sidebar container-white column" [ngClass]="{ show: show }">
      <mar-ghost-button class="button-close" icon="close" iconPosition="right" (click)="onClose()"
        >Close</mar-ghost-button
      >
      <ng-content></ng-content>
    </div>`,
  styles: [
    `
      @import 'variables';
      .sidebar {
        position: fixed;
        right: -52rem;
        bottom: 0;
        z-index: 15;
        max-width: 52rem;
        width: 100%;
        height: 100%;
        justify-content: space-between;
        background-color: #fff;
        border-radius: 2.4rem 0px 0px 2.4rem;
        padding: 3.2rem 4.8rem 3.2rem 4.8rem;
        transition: 0.3s;
        .button-close {
          align-self: end;
        }
        &.show {
          right: 0;
        }
        .sidebar-content {
          gap: 3.2rem;
        }
        @media (max-width: $mobile-width) {
          border-radius: unset;
        }
      }
    `,
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent {
  @Input() set show(value: boolean) {
    this._show = value;
  }
  get show(): boolean {
    return this._show;
  }
  @Output() onCloseSidebar = new EventEmitter<void>();

  private _show = false;

  onClose() {
    this.show = false;
    this.onCloseSidebar.emit();
  }
}
