import { ChangeDetectorRef, Component } from '@angular/core';
import {
  Company,
  FOURTY_NUMBER,
  FilterType,
  FolderFilterType,
  THREE_NUMBER,
} from '@estimator/models';
import { AgFilterComponent } from 'ag-grid-angular';
import { GridApi } from 'ag-grid-community';
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';

@Component({
  template: `
    <p-autoComplete
      [(ngModel)]="selectedCompany"
      [suggestions]="companies"
      (completeMethod)="onDealSearchCompany($event)"
      (onSelect)="onSelectCompany()"
      (onClear)="onUnselectCompany()"
      [showEmptyMessage]="true"
      [maxlength]="40"
      [showClear]="true"
      emptyMessage="No companies found"
      appendTo="body"
      field="name"
      dataKey="id"
      placeholder="Client"
    >
    </p-autoComplete>
  `,
})
// компонент для поиска компаний(клиентов) в фильтре грида, можно переделать в мульти, сейчас не используется
export class AgGridCompanyFilterComponent implements AgFilterComponent {
  companies: Company[] = [];
  selectedCompany?: Company;
  masterGridApi?: GridApi;

  constructor(private cdr: ChangeDetectorRef) {}

  agInit(params: any) {
    this.masterGridApi = params.api;
    if (params.onSearchCompany) {
      this.onSearchCompany = params.onSearchCompany;
    }
  }

  isFilterActive(): boolean {
    return !!this.selectedCompany;
  }

  doesFilterPass(/* params: IDoesFilterPassParams */): boolean {
    return true;
  }

  setModel(/* model: any */) {
    //
  }

  getModel(): any {
    const value = this.selectedCompany?.id;
    if (value) {
      return {
        filterType: FilterType.TEXT,
        type: FolderFilterType.EQUALS.toLowerCase(),
        filter: value?.toString(),
      };
    }
    return null;
  }

  onDealSearchCompany(event: AutoCompleteCompleteEvent): void {
    if (event.query?.length >= THREE_NUMBER && event.query?.length <= FOURTY_NUMBER) {
      this.onSearchCompany(event.query);
    }
  }

  onSearchCompany(value: string): void {
    return;
  }

  onSelectCompany(/* event: AutoCompleteSelectEvent */): void {
    this.masterGridApi?.onFilterChanged();
  }

  onUnselectCompany(/* event: Event | undefined */) {
    this.masterGridApi?.onFilterChanged();
  }

  detectChanges() {
    this.cdr.detectChanges();
  }
}
