import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { BdcWalkModule } from 'bdc-walkthrough';
import { BdcUserGuideComponent } from './bdc-user-guide.component';

@NgModule({
  declarations: [BdcUserGuideComponent],
  imports: [CommonModule, BdcWalkModule, MatDialogModule, MatButtonModule, MatIconModule],
  exports: [BdcUserGuideComponent],
})
export class BdcUserGuideModule {}
