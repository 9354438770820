import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TabViewModule } from 'primeng/tabview';
import { LoginFormModule } from '../login-form/login-form.module';
import { SignUpFormModule } from '../sign-up-form/sign-up-form.module';
import { AuthorizationFormComponent } from './authorization-form.component';
import { MessageModule } from 'primeng/message';

@NgModule({
  declarations: [AuthorizationFormComponent],
  imports: [CommonModule, LoginFormModule, SignUpFormModule, TabViewModule, MessageModule],
  exports: [AuthorizationFormComponent],
})
export class AuthorizationFormModule {}
