<div class="route-map-wrapper w-full h-full">
  <mgl-map
    [zoom]="[zoom]"
    [center]="center"
    [style]="style"
    (mapClick)="clearAlternativeDivAndData()"
    (mapLoad)="onLoad($event)"
    (mapDragStart)="mapChange()"
    (zoomEvt)="mapChange()"
    (mapMouseOut)="mapChange()"
    (styleImageMissing)="styleImageMissing($event)"
    class="w-full h-full"
  >
    <mgl-geojson-source id="route" [data]="route"></mgl-geojson-source>
    <mgl-layer
      id="route"
      type="line"
      source="route"
      [layout]="{
        'line-join': 'round',
        'line-cap': 'round'
      }"
      [paint]="{
        'line-width': 4,
        'line-color': ['get', MapFeatureProperties.RouteColor],
      }"
      (layerClick)="onMouseClickRoute($event)"
      (layerMouseEnter)="onMouseEnter($event)"
      (layerMouseLeave)="onMouseLeave($event)"
    >
      <!--       <mgl-popup *ngIf="selectedEdge" [feature]="selectedEdge" className="swgt">
        <p *ngIf="selectedEdge.properties">
          Edge: {{ selectedEdge.properties[MapFeatureProperties.Edge] || NOT_APPLICABLE }}
        </p>
        <p *ngIf="selectedEdge.properties">
          Cost: {{ this.selectedEdge.properties[MapFeatureProperties.Cost] || NOT_APPLICABLE }}
        </p>
        <p *ngIf="this.selectedEdge.properties">
          Start:
          {{ this.selectedEdge.properties[MapFeatureProperties.StartPoint] || NOT_APPLICABLE }}
        </p>
        <p *ngIf="this.selectedEdge.properties">
          End: {{ this.selectedEdge.properties[MapFeatureProperties.EndPoint] || NOT_APPLICABLE }}
        </p>
        <p *ngIf="this.selectedEdge.properties">
          Seq: {{ this.selectedEdge.properties[MapFeatureProperties.Seq] || NOT_APPLICABLE }}
        </p>
      </mgl-popup> -->
    </mgl-layer>

    <!-- alternativeRoutes -->
    @if (showAlternative) {
      <ng-container *ngFor="let route of alternativeRoutes; let i = index">
        <mgl-geojson-source [id]="'alternativeRoutes' + i" [data]="route"></mgl-geojson-source>
        <mgl-layer
          [id]="'alternativeRoutes' + i"
          type="line"
          [source]="'alternativeRoutes' + i"
          [layout]="{
            'line-join': 'round',
            'line-cap': 'round',
          }"
          [paint]="{
            'line-width': 4,
            'line-opacity': 0.35,
            'line-color': '#5EC5CD',
          }"
          (layerClick)="onMouseClickRoute($event)"
          (layerMouseEnter)="onMouseEnter($event)"
          (layerMouseLeave)="onMouseLeave($event)"
        >
        </mgl-layer>
      </ng-container>
    }

    <!-- eca zones -->
    <mgl-geojson-source *ngIf="showEcaZones" id="eca_zones" [data]="ecaZones"></mgl-geojson-source>
    <mgl-layer
      *ngIf="showEcaZones"
      id="eca_zones"
      source="eca_zones"
      type="fill"
      [paint]="{
        'fill-color': '#0080ff',
        'fill-opacity': 0.1
      }"
    >
    </mgl-layer>
    <!-- shiplist zones -->
    <mgl-geojson-source id="shiplist_zones" [data]="shiplistZones"></mgl-geojson-source>
    <mgl-layer
      id="shiplist_zones"
      source="shiplist_zones"
      type="fill"
      class="cursor-pointer"
      [paint]="{
        'fill-color': ['get', 'color'],
        'fill-opacity': 0.5,
        'fill-outline-color': 'white'
      }"
      (layerMouseMove)="onMouseMoveShipList($event)"
      (layerMouseLeave)="onMouseLeave($event)"
      (layerClick)="onMouseClickShipList($event)"
    >
    </mgl-layer>
    <!-- ports positions info -->
    <mgl-geojson-source [data]="portsPoints" id="port-source"></mgl-geojson-source>
    <mgl-layer
      (layerClick)="onPortLayerClick($event)"
      (layerMouseEnter)="onMouseEnter($event)"
      (layerMouseLeave)="onMouseLeave($event)"
      [layout]="{
        'symbol-sort-key': 10,
        'icon-image': ['get', MapFeatureProperties.IconImage],
        'icon-allow-overlap': true,
        'icon-ignore-placement': true,
        'icon-size': 0.5,
        'text-field': ['get', 'name'],
        'text-font': ['Open Sans Light'],
        'text-offset': [0, 1.5],
        'text-variable-anchor': ['top'],
        'text-size': 12,
        'text-allow-overlap': true,
        'text-ignore-placement': true
      }"
      [paint]="{
        'text-color': getColorText(),
      }"
      id="port"
      source="port-source"
      type="symbol"
    ></mgl-layer>
    <mgl-popup
      *ngIf="showPortPopup && selectedPortPoint"
      [feature]="selectedPortPoint"
      className="map-port-popup"
    >
      <ng-container *ngIf="selectedPortPoint.properties">
        <p>
          Port name:
          {{ selectedPortPoint.properties[MapFeatureProperties.Name] || NOT_APPLICABLE }}
          <span
            class="ml-5 fi fi-{{
              selectedPortPoint.properties[MapFeatureProperties.Alpha2]?.toLowerCase()
            }}"
          ></span>
        </p>
        <p>
          Time zone name:
          {{ selectedPortPoint.properties[MapFeatureProperties.TimeZoneName] || NOT_APPLICABLE }}
        </p>
        <p>
          Time zone: {{ UserTimeSettings.UTC }}
          {{
            addPlusTimeZone(selectedPortPoint.properties[MapFeatureProperties.TimeZone]) ||
            NOT_APPLICABLE
          }}
        </p>
      </ng-container>
    </mgl-popup>
  </mgl-map>
  <ng-container *ngIf="showSettings">
    <div class="flex justify-content-center gap-3">
      <div class="tools-panel position-relative">
        <div class="flex-container-row items-center gap-2">
          <label class="small-label">ECA Zones</label>
          <div class="primeng-form background-color-checkbox">
            <p-checkbox
              [(ngModel)]="showEcaZones"
              [binary]="true"
              class="small-checkbox"
            ></p-checkbox>
          </div>
        </div>
      </div>
      <div class="tools-panel position-relative">
        <div class="flex-container-row items-center gap-2">
          <label class="small-label cursor-pointer" [for]="ID_USER_SETTINGS_FORM_CHANGE_THEME"
          >{{ getLabelTheme() }} mode</label
          >
          <p-inputSwitch
            [formControl]="changeThemeControl"
            class="p-inputswitch-sm flex theme-switch"
            [inputId]="ID_USER_SETTINGS_FORM_CHANGE_THEME"
            [ngClass]="{ light: !changeThemeControl.value, dark: changeThemeControl.value }"
          ></p-inputSwitch>
        </div>
      </div>
      <div class="tools-panel position-relative">
        <div class="flex-container-row items-center gap-2">
          <label class="small-label">Ports widget</label>
          <div class="primeng-form background-color-checkbox">
            <p-checkbox
              class="small-checkbox"
              [(ngModel)]="showPortWidget"
              [binary]="true"
              (ngModelChange)="onPortWidgetChange($event)"
            ></p-checkbox>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <div *ngIf="isShowZoomPanel" class="zoom-panel position-absolute flex-container-column">
    <i
      id="zoom-plus"
      class="cursor-pointer color-blue"
      [class]="PrimeNgIcons.PLUS_CIRCLE"
      (click)="changeZoom(1)"
    ></i>
    <i
      id="zoom-minus"
      class="cursor-pointer color-blue mt-10"
      [class]="PrimeNgIcons.MINUS_CIRCLE"
      (click)="changeZoom(-1)"
    >
    </i>
  </div>
</div>
<div
  *ngIf="showShiplistZoneName"
  class="absolute shiplist-tooltip modal-window"
  [style.top]="topPositionForShiplistZoneName"
  [style.left]="leftPositionForShiplistZoneName"
>
  {{ shiplistZoneName }}
</div>

<div
  *ngIf="showAlternativeModal"
  class="absolute z-1 text-xs alternative-tooltip modal-window"
  [style.top]="topPositionForAlternativeRoutes"
  [style.left]="leftPositionForAlternativeRoutes"
>
  <div *ngFor="let routes of alternativeRoutesGroupedData" class="p-0 m-2">
    <!-- #{{ routes[0].index + 1 }} -->
    <div class="mb-2">{{ routes[0].start }} — {{ routes[0].end }}</div>
    <ng-container *ngFor="let route of routes">
      <div class="my-1 flex align-items-center">
        <p-radioButton
          name="route"
          [ngClass]="{ 'radio-alternative-disabled': isFixed }"
          [disabled]="isFixed"
          [value]="route.excepted_canals + ' ' + route.index"
          [(ngModel)]="ngModelDefault"
          [inputId]="'route' + route.index"
          (onClick)="onClickRoute(route)"
        />
        <label
          [for]="'route' + route.index"
          class="ml-2"
          [ngClass]="{ 'cursor-pointer': !isFixed }"
        >
          <span [title]="getCrossedCanalsTitle(route)">{{ getCrossedCanals(route) }}</span>
          <span [ngClass]="{ 'ml-1': getCrossedCanals(route) }"
          >{{ round(route?.distance || 0) }} NM</span
          >
        </label>
      </div>
    </ng-container>
  </div>
</div>
