export const PRODUCTS_PAGES = {
  DRY: 'dry-voyage-estimator',
  TANKER: 'tanker-voyage-estimator',
  BUNKER: 'bunker-trading-platform',
};

export const EXTERNAL_URLS = {
  ESTIMATOR_REGISTER: '/estimator/dashboard',
  DRY: '/estimator/estimator',
  BUNKER: '/platform',
};

export const PAGES = {
  MAIN: {
    name: 'Main',
    slug: '/',
  },
  ABOUT: {
    name: 'About',
    slug: 'about',
  },
  SUSTAINABILITY: {
    name: 'Sustainability',
    slug: 'sustainability',
  },
  CONTACTS: {
    name: 'Contacts',
    slug: 'contacts',
  },
  PRODUCTS: {
    name: 'Products',
    slug: 'products',
  },
  PRIVACY_POLICY: {
    name: 'Privacy policy',
    slug: 'privacy-policy',
  },
  TERMS_AND_CONDITIONS: {
    name: 'Terms and conditions',
    slug: 'terms-and-conditions',
  },
  BLOG: {
    name: 'Blog',
    slug: 'blog',
  },
  DETAILED: {
    name: 'Detailed',
    slug: ':slug',
  },
  MAINPAGE_BUNKER: {
    name: 'Bunker Procurement',
    slug: 'bunker-procurement',
  },
  FEATURES: {
    name: 'Features',
    slug: 'features',
  },
};

export const PAGE = {
  MAINPAGE: PAGES.MAIN.slug,
  PRODUCTS: PAGES.PRODUCTS.slug,
  PRODUCT: PRODUCTS_PAGES,
  ABOUT: PAGES.ABOUT.slug,
  BLOG: PAGES.BLOG.slug,
  SUSTAINABILITY: PAGES.SUSTAINABILITY.slug,
  CONTACTS: PAGES.CONTACTS.slug,
  PRIVACY_POLICY: PAGES.PRIVACY_POLICY.slug,
  TERMS_AND_CONDITIONS: PAGES.TERMS_AND_CONDITIONS.slug,
  EXTERNAL: EXTERNAL_URLS,
  MAINPAGE_BUNKER: PAGES.MAINPAGE_BUNKER.slug,
  FEATURES: PAGES.FEATURES.slug,
};
