import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { DirectivesModule } from '@estimator/directives';
import { AgGridModule } from 'ag-grid-angular';
import { InputNumberModule } from 'primeng/inputnumber';
import { BreakEvenAxesModule } from '../break-even-axes/break-even-axes.module';
import { BreakEvenGridModule } from '../break-even-grid/break-even-grid.module';
import { BreakEvenDialogComponent } from './break-even-dialog.component';

@NgModule({
  declarations: [BreakEvenDialogComponent],
  imports: [
    CommonModule,
    AgGridModule,
    BreakEvenGridModule,
    BreakEvenAxesModule,
    ReactiveFormsModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    InputNumberModule,
    DirectivesModule,
  ],
  exports: [BreakEvenDialogComponent],
})
export class BreakEvenDialogModule {}
