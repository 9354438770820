import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ChildActivationStart, Router } from '@angular/router';
import { ThemeService } from '@estimator/landing-services';
import { HeaderComponent } from '@estimator/landing-ui';
import { XAuthState } from '@estimator/store';
import { Select } from '@ngxs/store';
import { Observable, Subject, filter, takeUntil } from 'rxjs';

@Component({
  selector: 'estimator-root',
  template: `
    <mar-header
      [currentTheme]="currentTheme"
      [currentDirectory]="currentDirectory"
      (toggleSidebar)="onToggleSidebar()"
      #marHeader
    ></mar-header>
    <router-outlet></router-outlet>
    <mar-footer
      [currentDirectory]="currentDirectory"
      [showContactUs]="showSidebar"
      (toggleSidebar)="onToggleSidebar()"
    ></mar-footer>
  `,
  styles: [
    `
      :host {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
      }
    `,
  ],
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('marHeader') marHeader!: HeaderComponent;
  @Select(XAuthState.isLogged) isLogged$?: Observable<boolean>;
  @Select(XAuthState.getUserEmail) userEmail$?: Observable<string>;
  currentTheme = '';
  currentDirectory = '';
  showSidebar = false;
  private _onDestroy$ = new Subject<void>();

  constructor(private readonly themeSrv: ThemeService, private readonly router: Router) {}

  ngOnInit(): void {
    this.themeSrv
      .getTheme()
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((res) => {
        this.currentTheme = res;
      });
    this.router.events
      .pipe(takeUntil(this._onDestroy$))
      .pipe(filter((event) => event instanceof ChildActivationStart))
      .subscribe((res: any) => {
        if (res?.snapshot?.routeConfig?.path) {
          this.currentDirectory = res?.snapshot?.routeConfig?.path;
          this.marHeader.animateHeader(-1);
        }
      });
    this.themeSrv
      .isSidebarVisible()
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((res: boolean) => {
        this.showSidebar = res;
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  onToggleSidebar(): void {
    this.themeSrv.toggleSidebar();
  }
}
