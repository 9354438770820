import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { PrimeNgColors } from '@estimator/models';
import { isString } from 'lodash';

@Component({
  selector: 'estimator-button-switch',
  templateUrl: './button-switch.component.html',
  styleUrl: './button-switch.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonSwitchComponent {
  @Input() tooltip = false;
  @Input() optionLabel = 'name';
  @Input() optionValue = '';
  @Input() disabled = false;
  @Input() tabIndex = 0;
  @Input() id = 'switch_button';
  @Input() styleClass = '';
  @Input() set value(value: any) {
    if (isString(value) && this.optionValue) {
      this._value =
        this.options.find((item) => item[this.optionValue].toLowerCase() === value.toLowerCase()) ||
        this.options[0];
      return;
    }
    this._value = value;
  }
  get value(): any {
    return this._value || this.options[0];
  }
  @Input() set options(options: any[]) {
    if (options.length > 0) {
      this._options = options;
    } else {
      this._options = [];
    }
  }
  get options(): any[] {
    return this._options;
  }
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

  get currentItem(): any {
    return this.options.find((item) => item === this.value) || this.options[0];
  }

  readonly PrimeNgColors = PrimeNgColors;

  private _options: any[] = [];
  private _value: any = '';

  switchNext(): void {
    const currentIndex = this.options.findIndex((item) => item === this.value);
    const nextIndex = currentIndex + 1;
    this.value = this.options[nextIndex] || this.options[0];
    this.onChange.emit(this.optionValue ? this.value[this.optionValue] : this.value);
  }
}
