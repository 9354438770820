import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[estimatorSelectValue]',
})
export class SelectValueDirective {
  @HostListener('focus')
  onFocus(): void {
    this.el.nativeElement.select();
  }
  constructor(private el: ElementRef) {}
}
