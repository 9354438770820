<div>
  <div class="vessel-widget-header">
    <div class="display-flex margin-left-12">
      <span class="mr-2 fi fi-{{ vessel?.alpha_2?.toLowerCase() }}"></span>
      <h4 class="margin-0">{{ vessel?.name }}</h4>
      <h5 class="vessel-type">{{ vessel?.type }}</h5>
    </div>
    <button mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <hr class="hr" />
  <div class="info-container display-flex">
    <div>
      <p>Deadweight: {{ vessel?.dead_weight }}</p>
      <p>Callsign: {{ vessel?.call_sign }}</p>
      <p>Length/Beam: {{ vessel?.length }} / {{ vessel?.beam }}</p>
      <p>Manager: {{ vessel?.manager }}</p>
      <p>Flag: {{ vessel?.flag }}</p>
      <p>Course/Speed: {{ vessel?.course }} / {{ vessel?.speed_over_ground }}</p>
    </div>
    <div class="margin-left-20">
      <p>Draft: {{ vessel?.draught }}</p>
      <p>Built: {{ vessel?.build_year }}</p>
      <ng-container *ngIf="!isFromSearchVesselFinder">
        <p>Port name: {{ vessel?.matched_port_name }}</p>
        <p>Port unlocode: {{ vessel?.matched_port_unlocode }}</p>
        <p>Navigational status: {{ vessel?.navigational_status }}</p>
        <p>
          Position update time:
          {{ localDateTimeFormat(vessel?.position_update_timestamp || '') }}
        </p>
      </ng-container>
    </div>
  </div>
</div>

<div *ngIf="!isFromSearchVesselFinder" class="display-flex buttons-container width-300">
  <estimator-date-editor
    [needSelectEmit]="true"
    [placeholder]="'track from'"
    [selectionMode]="selectionMode"
    (selectValue)="onSelectDate($event)"
  ></estimator-date-editor>
</div>

<div *ngIf="isFromSearchVesselFinder" class="display-flex buttons-container">
  <button
    mat-flat-button
    [color]="MaterialThemeColor.Accent"
    type="button"
    (click)="addVesselToFleet(vessel, false)"
  >
    Add to my fleet
  </button>

  <button
    mat-flat-button
    [color]="MaterialThemeColor.Accent"
    type="button"
    (click)="addVesselToFleet(vessel)"
  >
    Add to company fleet
  </button>
</div>
