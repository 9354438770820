import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GhostButtonModule } from '../ghost-button';
import { SidebarComponent } from './sidebar.component';

@NgModule({
  declarations: [SidebarComponent],
  imports: [CommonModule, GhostButtonModule],
  exports: [SidebarComponent],
})
export class SidebarModule {}
