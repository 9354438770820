import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { BaseModel, BaseModelFormGroup, CargoRules, Port, VoyageType } from '@estimator/models';
import { CargoEvent } from './cargo';

export interface LaytimeTimeLineEvent {
  id?: number;
  duration?: number;
  start_time?: Date;
  end_time?: Date;
  type?: string;
  percent_to_count?: number;
  is_on_demurrage?: boolean;
  is_terms_conflict?: boolean;
  comment?: string;
}

export interface LaytimeTimeLineEventFormGroup {
  id?: FormControl<number | null>;
  duration?: FormControl<number | string | null>;
  start_time?: FormControl<Date | null | string>;
  end_time?: FormControl<Date | null | string>;
  /*  event_start_time?: FormControl<Date | null>;
  event_end_time?: FormControl<Date | null>; */
  type?: FormControl<LaytimeEventType | null>;
  percent_to_count?: FormControl<number | null>;
  is_on_demurrage?: FormControl<boolean | null>;
  is_terms_conflict?: FormControl<boolean | null>;
}

export interface LaytimeTimeLineSchedule {
  arrival_time?: Date | null;
  departure_time?: Date | null;
  load_complete?: Date | null;
  load_start?: Date | null;
  nor?: Date | null;
  laytime_commenced?: Date | null;
  laytime_stopped?: Date | null;
}

/* export interface LaytimeCargo {
  allowed_time?: number;
  departure_time?: Date;
  load_complete?: Date;
  load_start?: Date;
  nor?: Date;
} */

export interface LaytimeTimeLineCargo {
  allowed_time?: number | null;
  ld_rate?: number | null;
  name?: string | null;
  quantity?: number | null;
  // type?: string | null;
}

export interface LaytimeTimeLine extends Port {
  allowed_time?: number;
  cargoes?: LaytimeTimeLineCargo[];
  demurrage_rate?: number;
  despatch_rate?: number;
  id?: number;
  laytime_id?: number | null;
  port?: Port | null;
  port_id?: number;
  port_timezone?: string;
  /**
   * field slug in cargoEvents
   */
  operation_type?: string;
  schedule?: LaytimeTimeLineSchedule;
  term_id?: number;
  used_time?: number;
  time_exceed?: number;
  time_saved?: number;
  events?: LaytimeTimeLineEvent[];
  /**
   * only front, use for create-new-laytime when time-lines[0] already exists
   */
  new?: boolean;
  /**
   * only front, use in vms-laytimes-list
   */
  status?: LaytimeStatus;
}

export interface Laytime extends BaseModel /* HSOA */ {
  agreement_id?: number;
  allowed_time?: number;
  allowed_users?: number[];
  company_id?: number;
  currency_id?: number;
  demurrage?: number;
  // demurrage_rate?: number;
  despatch?: number;
  // despatch_rate?: number;
  status?: LaytimeStatus;
  time_lines?: LaytimeTimeLine[];
  time_on_demurrage?: number;
  time_on_despatch?: number;
  total_time?: number;
  vessel_id?: number;
  voyage_type?: VoyageType;
  // term_id?: number;
}

export enum LaytimeStatus {
  Draft = 'DRAFT',
  Pending = 'PENDING',
  Fixed = 'FIXED',
  AgreedWithCharterer = 'AGREED_WITH_CHARTERER',
}

export enum LaytimeLabelStatus {
  Draft = `Set to created`,
  Pending = `Set to checking`,
  Fixed = `Set to finished`,
  AgreedWithCharterer = `Set to agreed`,
}

export interface LaytimeList extends Laytime {
  laytime?: Laytime;
}

export interface LaytimeByAgreementList extends Laytime {
  laytime?: Laytime[];
}

// similar to LaytimeTimeLineCargo, need ?
/* export interface CargoLaytime {
  type?: string | null;
  quantity?: number | null;
  ld_rate?: number | null;
  allowed?: number | null;
} */

export interface CargoLaytimeFormGroup {
  name?: FormControl<string | null>;
  quantity?: FormControl<number | null>;
  ld_rate?: FormControl<number | null>;
  allowed_time?: FormControl<number | null>;
}

export enum LaytimeEventType {
  // all with 0 duration
  Arrival = 'ARRIVAL',
  NOR = 'NOR',
  LoadStart = 'LOAD_START',
  LoadComplete = 'LOAD_COMPLETE',
  Departure = 'DEPARTURE',
  Demurrage = 'DEMURRAGE',
  LaytimeCommenced = 'LAYTIME_COMMENCED',
  LaytimeStopped = 'LAYTIME_STOPPED',
  /*   Gap = 'GAP', */
  // user can choose
  Work = 'WORK',
  Waiting = 'WAITING',
  DemurrageException = 'DEMURRAGE_EXCEPTION',
  LaytimeException = 'LAYTIME_EXCEPTION',
}

export interface LaytimeFormGroup extends BaseModelFormGroup {
  port?: FormControl<Port | null>;
  port_id?: FormControl<number | string | null>;
  demurrage_rate?: FormControl<number | null>;
  despatch_rate?: FormControl<number | null>;
  terms?: FormControl<CargoRules | null>;
  // dates, fields from LaytimeTimeLineSchedule
  arrival_time?: FormControl<Date | null>;
  nor?: FormControl<Date | null>;
  load_start?: FormControl<Date | null>;
  load_complete?: FormControl<Date | null>;
  departure_time?: FormControl<Date | null>;
  laytime_commenced?: FormControl<Date | null>;
  laytime_stopped?: FormControl<Date | null>;
  //
  /**
   * field slug in cargoEvents
   */
  operation_type?: FormControl<CargoEvent | null>;
  cargo_allowed_time_switcher?: FormControl<boolean | null>;
  cargoes?: FormArray<FormGroup<CargoLaytimeFormGroup>>;
}

export enum LaytimeLabel {
  Demurrage = 'Demurrage',
  Despatch = 'Despatch',
}
