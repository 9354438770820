<mat-form-field
  appearance="outline"
  [formGroup]="phoneNumberFormGroup"
  class="phone-input-wrapper"
>
  <mat-label>Phone Number</mat-label>
  <input type="phone"
    class="code-input"
    matInput
    formControlName="number"
    [matAutocomplete]="countryCodeAutoComplete"
    [required]="required"
  >
  <mat-autocomplete #countryCodeAutoComplete="matAutocomplete">
    <mat-option *ngFor="let code of countryCodes" [value]="code">
      +{{code}}
    </mat-option>
  </mat-autocomplete>
  <span class="prefix" matPrefix>+&nbsp;</span>
</mat-form-field>
