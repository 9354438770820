import { Injectable } from '@angular/core';
import { mapUsers } from '@estimator/helpers';
import { Cargo, Company, User, itemMap } from '@estimator/models';
import { CompanyService } from '@estimator/services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { catchError, finalize, tap, throwError } from 'rxjs';
import { UpdateCargoInSelectedDeal } from '../deal';
import { CreateCompany, FindCompany, GetUsersByCompanyId } from './company.actions';

export interface CompanyStateModel {
  foundСompanies: Company[];
  usersInMyCompany: User[];
  usersInMyCompanyMap: itemMap;
  loading: boolean;
}

@State<CompanyStateModel>({
  name: 'company',
  defaults: {
    foundСompanies: [],
    usersInMyCompany: [],
    usersInMyCompanyMap: new Map(),
    loading: false,
  },
})
@Injectable()
export class CompanyState {
  @Selector()
  static getIsLoading({ loading }: CompanyStateModel): boolean {
    return loading;
  }
  @Selector()
  static getFoundCompanies({ foundСompanies }: CompanyStateModel): Company[] {
    return foundСompanies;
  }
  @Selector()
  static getUsersInMyCompany({ usersInMyCompany }: CompanyStateModel): User[] {
    const enrichData = usersInMyCompany.map((user) => {
      if (user?.first_name && user?.last_name) {
        user.initials = user.first_name[0] + user.last_name[0];
      }
      return user;
    });
    return enrichData;
  }
  @Selector()
  static getUsersInMyCompanyMap({ usersInMyCompanyMap }: CompanyStateModel): itemMap {
    return usersInMyCompanyMap;
  }

  constructor(private readonly companyService: CompanyService) {}

  @Action(CreateCompany)
  createCompany(
    { getState, dispatch, patchState }: StateContext<CompanyStateModel>,
    { name, updatedCargo, actualCargoes }: CreateCompany
  ) {
    patchState({ loading: true });
    return this.companyService.create({ name }).pipe(
      tap((company) => {
        const { foundСompanies } = getState();
        patchState({ foundСompanies: [...foundСompanies, company] });
        if (updatedCargo) {
          updatedCargo.company_id = company.id;
          updatedCargo.company = company;
          dispatch(new UpdateCargoInSelectedDeal(updatedCargo, actualCargoes as Cargo[]));
        }
      }),
      catchError((err) => {
        patchState({ loading: false });
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }

  @Action(FindCompany)
  findCompany({ patchState }: StateContext<CompanyStateModel>, { params }: FindCompany) {
    patchState({ loading: true });
    return this.companyService.findCompany(params).pipe(
      tap(({ companies }) => {
        patchState({ foundСompanies: companies });
      }),
      catchError((err) => {
        patchState({ loading: false });
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      })
    );
  }

  @Action(GetUsersByCompanyId)
  getUsersByCompanyId(
    { patchState }: StateContext<CompanyStateModel>,
    { companyId }: GetUsersByCompanyId
  ) {
    patchState({ loading: true });
    // company id=0 not valid
    if (companyId) {
      return this.companyService.getUsersByCompanyId(companyId).pipe(
        tap(({ users }) => {
          const usersInMyCompanyMap = new Map(users.map(mapUsers));
          patchState({ usersInMyCompany: users, usersInMyCompanyMap });
        }),
        catchError((err) => {
          patchState({ loading: false });
          return throwError(err);
        }),
        finalize(() => {
          patchState({ loading: false });
        })
      );
    }
    return;
  }
}
