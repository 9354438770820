<main class="application-wrapper">
  <p-menu [model]="navigationList" [popup]="true" styleClass="main-menu-overlay" #menu></p-menu>
  <section class="main-menu flex-container-row content-end">
    <h1 class="main-title">XMar</h1>
    <p-button
      [icon]="isLogged ? PrimeNgIcons.XMar : PrimeNgIcons.XMarWhite"
      [style]="{ backgroundColor: isLogged ? PrimeNgColors.White : PrimeNgColors.Black }"
      [rounded]="true"
      [text]="true"
      [raised]="true"
      (click)="menu.toggle($event)"
      styleClass="w-2rem h-2rem"
      id="drawer_menu_button"
    ></p-button>
  </section>
  <section class="drawer-container">
    <router-outlet></router-outlet>
  </section>
</main>
